import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Adminprops from '../../VelidateWebsite/Adminprops'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import Sidebar from '../includes/Sidebar'
import Facilites from './Facilites';
import Notfound from '../includes/Notfound';
import Loading from '../includes/Loading';

const cookies = new Cookies();


export default function Editfacilities() {
  const Facilities = (props) => {

    const params = useParams()
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(true)
    const adminProps = Adminprops()
    const [staffList, setStaffList] = useState([])
    const admin = cookies.get('admin')
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {errorModal, capitalizeFirstLetter, AdminBasics, pagePermissions, formLoading, successModal, updatTrail, warningModal} = CommonFunc()
    const [refres, setRefresh] = useState("No")
    const [list, setList] = useState(Facilites)
    const [importId, setImportId] = useState("")
    const [selectedCourses, setSelectedCourses] = useState([])
    const [coursesList, setCoursesList] = useState([])
    var a = 0
    useEffect(() => {
        if(a == 0){
            if(admin.id === params.id){
                window.location.href="/404"
            }else{
                get_staff_list();
            }
            a++
        }
    }, [])

    const get_staff = () => {
        
        fetch(apiurl+"api/staff_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    staffdata: params.id,
                    fromfacilities: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setName(response.staff.name)
                    setLoading(false)
                    updatTrail("Visited <strong>"+response.staff.name+"</strong> Permission Page", "Staff", "2")
                    setCoursesList(response.courses)
                    setTimeout(function(){
                        window.$("#courses").unbind().select2()
                    }, 500) 

                    if(response.staff.salescourses !== null){
                        var selCourse = response.staff.salescourses;
                        console.log(selCourse['salescourses'])
                        if(selCourse['salescourses'] !== undefined){
                            setSelectedCourses(selCourse['salescourses'])
                        }
                    }
                    check_permissions(JSON.parse(response.staff.permissions))
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const coursePermissionsCheck = (permissions_list, pagename , permission) => {
        var permissionArray = permissions_list
        
          if(permissionArray !== null && typeof permissionArray !== "undefined" && permissionArray !== "none"){
            permissionArray = permissionArray.filter(function(item){
                if(item.page_name == pagename){
                  return item 
                }
            })
            if(permissionArray.length > 0){
              var filtered = permissionArray[0].staff_facilities.filter(function(item){
                  if(item.name == permission){
                    return item 
                  }
              })
              // console.log(filtered)
            }
            
            return ((typeof filtered !== "undefined" && filtered.length > 0) ? filtered[0]['value'] : false)
            
          }else{
            return false
          }
          
      }

    const check_permissions = (x) => {
        var permissions = list
        // console.log(x)
        for(var i=0;i<=permissions.length;i++){            
            // coursePermissionsCheck(x, "Staff", "show_in_sidebar")
            
            for(var j=0;j<permissions[i]['staff_facilities'].length;j++){
                // console.log(x[i]['staff_facilities'][j])
                permissions[i]['staff_facilities'][j]['value'] = coursePermissionsCheck(x, permissions[i]['page_name'], permissions[i]['staff_facilities'][j]['name'])
                // if(x[i]['staff_facilities'][j]['value'] === true){
                //     permissions[i]['staff_facilities'][j]['value'] = true;
                // }else{
                //     permissions[i]['staff_facilities'][j]['value'] = false;
                // }
            }
        }
        
        
        // update_permissions(permissions)
        setList(permissions)
        
        setRefresh(refres == "No" ? "Yes" : "No")
    }

    const change_permission = (page, option) => {
        var permissions = list
        if(permissions[page]['staff_facilities'][option]['value'] == true){
            permissions[page]['staff_facilities'][option]['value'] = false
        }else{
            permissions[page]['staff_facilities'][option]['value'] = true
        }
        // update_permissions(permissions)
        setList(permissions)
        setRefresh(refres == "No" ? "Yes" : "No")
    }

    const update_permissions = (permissions) => {
        warningModal("Loading..", "Facilities updating please wait...")
        fetch(apiurl+"api/update_permissions", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    id: params.id,
                    page_url: window.location.href,
                    permissions: JSON.stringify(permissions),
                    selectedcourses: window.$("#courses").val()
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const get_staff_list = () => {
        setLoading(true)
        fetch(apiurl+"api/staff_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setStaffList(response.staff)
                    get_staff()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const headings = {
        "Assets+view_file_manager": "View Assets",
        "Assets+show_create_files_button": "Add Assets",
        "Assets+edit_folders": "Edit Assets",
        "Assets+remove_folders": "Delete From Assets",
        "Assets+info_folders": "View Info Of File",
        "Assets+url_document": "Copy URL",
        "Assets+view_storage": "View Storage Information",
        "Assets+bulk_selection": "Other Information",
    };

    const importPermission = (e) => {
        
        formLoading(e.target)
        e.preventDefault();

        fetch(apiurl+"api/importpermission", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    id: params.id,
                    iid: importId,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", "Permission Imported Successfully")
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    return(

        pagePermissions(props.pagepermissions, "Staff", "facility_staff") ?
        <>
        {
            !loading &&
            <div className='col-md-4' style={{position:"relative"}}>
                <div className='box no-border'>
                    <form action='' method='POST' onSubmit={(e) => importPermission(e)} >
                        <div className='box-body'>
                            <label>Import Permissions</label>
                            <select className='form-control' id='select2' onChange={(e) => setImportId(e.target.value)} required >
                            <option value={""}>Select Staff Member</option>
                                {
                                    staffList.length > 0 &&
                                    staffList.map((item, index) => (
                                        item.id !== params.id &&
                                        <option value={item.id} key={"option"+index} >
                                            {item.name} {item.email} ({item.role})
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='box-footer'>
                            <button className='btn btn-primary btn-flat btn-block'>Import</button>
                        </div>
                    </form>
                </div>
            </div>
        }
        
            <div className='col-md-12' style={{position:"relative"}}>
                {
                    loading ? 
                    <>
                        <br /><br /><br />
                        <Loading />
                        <br /><br /><br />
                    </>
                    :
                    list.map((item, index) => (
                        <div className='box no-border' key={"itemlist"+index}>
                            <div className='box-header'> 
                                <h3 className='box-title'>
                                    Manage <strong>{item.page_name}</strong> Authorities of <strong>{name}</strong>
                                </h3>
                            </div>
                            <div className='box-body'>
                                {
                                    item.staff_facilities.map((item2, index2) => (
                                        <>
                                            {
                                                headings[item.page_name + "+" + item2.name] !== undefined &&
                                                    <div className='box-header no-left-padding' style={{width:"100%", borderTop:"2px solid #58617a", marginTop:"20px"}} key={"bt"+index2} >
                                                        <h3 className='box-title'>
                                                            {headings[item.page_name + "+" + item2.name]}
                                                        </h3>
                                                    </div>
                                            }

                                            <button onClick={() => change_permission(index, index2)} className={(item2.value == true) ? 'btn btn-flat my-btns btn-primary' : 'btn btn-flat my-btns'} title={capitalizeFirstLetter(item2.name.replace(/_/g, " "))} key={"btn"+index2}>
                                                {item2.name.replace(/_/g, " ")}
                                            </button>
                                        </>
                                    ))
                                }
                            </div>
                            
                            {
                                item.page_name === "Sales_Page" &&
                                <div className='box-body'>
                                    <label>Select course(s) to be shown</label>
                                    <select className='form-control' id="courses" defaultValue={selectedCourses} multiple data-placeholder='Select Courses' >
                                        {
                                            coursesList.length > 0 &&
                                            coursesList.map((item, index) => {
                                                return(
                                                    <option value={item.otherid} key={"ca"+index}>{item.name}</option>
                                                )
                                            })
                                        }
                                        
                                    </select>
                                </div>
                                    
                            }
                        </div>
                        ))
                    }

                <button style={{width:"100%", position:"fixed", bottom:"20px", right:"15px", maxWidth:"200px"}} className='btn btn-danger btn-flat btn-block'
                onClick={() => update_permissions(list)}
                >
                    <i className='fa fa-save'></i> Update Permissions
                </button>
            </div>
      </>
      :
      <Notfound />
    )
  }
  return (
    <>
      <Sidebar page={Facilities} permission="Staff"/>
    </>
  )
}
