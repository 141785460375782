import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../../includes/Common';
import Cookies from 'universal-cookie';
import apiurl from '../../../Global';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../includes/Loading';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const cookies = new Cookies();
export default function Studentcart(props) {
  
    const navigate = useNavigate()
    const params = useParams()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
    
    const [loading, setLoading] = useState(false)
    const [cartList, setCartList] = useState([])
    const [courses, setCourses] = useState([])
    const [selectedCourse, setSelectedCourse] = useState("")
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState("")
    const [listingPrice, setListingPrice] = useState("")
    const [listingPriceTemp, setListingPriceTemp] = useState("")
    const [itemTab, setItemTab] = useState(false)
    

    const getStudentCart = () => {  
        if(!loading){
            setLoading(true)
            fetch(apiurl+"api/getStudentCart", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    userid: params.userid,
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
                })
                .then(response => response.json()) 
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        setCartList(response.cart)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }


    const removeStudentCart = (item) => {
        if(window.confirm("Are you sure?")){
            fetch(apiurl+"api/removeStudentCart", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    userid: params.userid,
                    cartid: item.id,
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
                })
                .then(response => response.json()) 
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        getStudentCart()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
        }
    }

    const getCourses = () => {
        fetch(apiurl+"api/getCoursesName", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                accecible: 1,
                page_url: window.location.href,
                staff: AdminBasics()
            })
            })
            .then(response => response.json()) 
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCourses(response.courses)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
    }
    
    const changePlan = (v) => {
        setSelectedPlan(v)
        var price = v.split("-")
        setListingPriceTemp(price[1])
    }

    const changeCourse = (v) => {
        setSelectedCourse(v)
        if(!loading){
            setLoading(true)
            fetch(apiurl+"api/getPlanNamePrice", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    courseid: v
                })
                })
                .then(response => response.json()) 
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        setPlans(response.plans)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setLoading(false)
                })
        }
    }

    const addToStudentCart = (e) => {    
        e.preventDefault()
        formLoading(e.target)
        fetch(apiurl+"api/addToStudentCart", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                accecible: 1,
                page_url: window.location.href,
                staff: AdminBasics(),
                userid: params.userid,
                courseid: selectedCourse,
                planid: selectedPlan,
                listingPrice: listingPrice
            })
            })
            .then(response => response.json()) 
            .then(response => {
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getStudentCart()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
    }

    useEffect(() => {
        getStudentCart()
        getCourses()
    }, [])

  return (
    <>
    <br />
        {
            itemTab &&
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Add New Cart
                        </h3>
                        {
                            loading &&
                            <span className='label bg-yellow pull-right'  >
                                Loading...
                            </span>
                        }
                        {
                            !loading &&
                            <span className='label bg-red pull-right  cursor-pointer' onClick={() => setItemTab(false)} >
                                <i className='fa fa-times'></i>
                            </span>
                        }
                    </div>
                    <form action='' method='POST' onSubmit={(e) => addToStudentCart(e)} >
                        <div className='box-body'>
                            <div className='col-md-4 no-left-padding'>
                                <label>Select Course/Package</label>
                                <select className='form-control' required value={selectedCourse} onChange={(e) => changeCourse(e.target.value)} >
                                    <option value={""}>Select Course</option>
                                    {
                                        courses.length > 0 &&
                                        courses.map((item, index) => {
                                            return(
                                                <option key={"op"+index} value={item.otherid}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <label>Select Plan</label>
                                <select className='form-control' required value={selectedPlan} onChange={(e) => changePlan(e.target.value)} >
                                    <option value={""}>Select Plan</option>
                                    {
                                        plans.length > 0 &&
                                        plans.map((item, index) => {
                                            return(
                                                <option key={"pl"+index} value={item.id + "-" + item.selling_price}>{item.plan_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <label>Listing Price</label>
                                <sapn className="pull-right"><strong>Current Price:</strong> {listingPriceTemp}</sapn>
                                <input type='number' step={0.01} className='form-control' placeholder='e.g 100' value={listingPrice} onChange={(e) => setListingPrice(e.target.value)} />
                            </div>
                        </div>
                        <div className='box-footer'>
                            <p className='text-red'><strong>NOTE: Leave Listing price Blank to Actual Price</strong></p>
                            <button className='btn btn-primary btn-flat btn-block'>
                                Add to cart
                            </button>
                        </div>
                    </form>    
                </div>    
            </div>
        }
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Student Cart
                    </h3>
                    {
                        loading &&
                        <span className='label bg-yellow pull-right'  >
                            Loading...
                        </span>
                    }
                    {
                        !loading &&
                        <span className='label bg-green pull-right cursor-pointer' onClick={() => setItemTab(true)} >
                            Add New Item?
                        </span>
                    }                   
                </div>
                <div className='box-body'>
                    <table id="studenttable" className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                <th style={{width:"80px"}} >S.No</th>
                                <th>Course Name</th>
                                <th>Plane Name</th>
                                <th>Listing Price</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cartList.length > 0 &&
                                cartList.map((item, index) => {
                                    return(
                                        <tr key={"index"+index}>
                                            <td style={{width:"80px"}} >
                                                {index+1}
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{item.plan_name}</td>
                                            <td>{item.selling_price}</td>
                                            <td>{item.created_date}</td>
                                            <td>
                                                <span className='badge bg-red' onClick={() => removeStudentCart(item)} >
                                                    <i className='fa fa-trash'></i>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
}
