import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import Sidebar from '../includes/Sidebar';
import CommonFunc from '../includes/Common';
import apiurl from '../../Global';
import axios from 'axios';
const cookies = new Cookies();

export default function Processes() {
  const Processespage = (props) => {
    
    const [loading, setLoading] = useState(false)
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, warningModal, formLoading, pagePermissions} = CommonFunc()


  const [liveCLassesList, setLiveClassesList] = useState([])
  const [liveCLassesListLoading, setLiveClassesListLoading] = useState(true)
  const getLiveClassList = () => {
      setLiveClassesListLoading(true)
      fetch(apiurl+"api/getLiveClassList", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
            waiting: true
          })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status === "OK"){
                  setLiveClassesList(response.list)
                  getNewEncryptionsList()
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              
          })
          .catch(err => {
              // console.log(err);
          });   
  }
  
  const [encryptionList, setEncryptionList] = useState([])
  const [remaining, setRemaining] = useState(0)
  const [notFound, setNotFound] = useState([])
  
  const getNewEncryptionsList = () => {
    fetch(apiurl+"api/newEncryptionsList", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({})
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setEncryptionList(response.files)
                setRemaining(response.remaining)
                setNotFound(response.notFound)
                setRemovalList()
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLiveClassesListLoading(false)
            
        })
        .catch(err => {
            // console.log(err);
        });   
  }

  const [removal, setRemoval] = useState("")
  const setRemovalList = () => {
    fetch(apiurl + "api/getRemovalStatus", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            page_url: window.location.href,
            staff: AdminBasics(),
            accecible: 1,
        })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                if(response.message === "Files Fetched"){
                    setRemoval(response)
                }else{
                    
                }
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            // console.log(err);
        });  
  }

  
  const endClass = (item, type) => {
    warningModal("Loading", "Ending class please wait...");
    fetch(apiurl+"api/endClass", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            id:item.id,
            page_url: window.location.href,
            staff: AdminBasics(),
            accecible: 1,
            type: type
        })
        })
        .then(response => response.json())
        .then(response => {
            getLiveClassList()
            successModal("Success", "Class Ended Successfully")
        })
        .catch(err => {
            // console.log(err);
        });  
    }

    const [duplicateLoading, setDuplicateLoading] = useState(false)
    const [duplicateList, setDuplicateList] = useState([])
    const [colorGroups, setColorGroups] = useState([])

    const getDuplicateList = () => {
        if(!duplicateLoading){
            setDuplicateLoading(true)
            fetch(apiurl+"api/duplicatedVideos", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
                })
                .then(response => response.json())
                .then(response => {

                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", "List Fetched")
                        setDuplicateList(response.list)
                        setColorGroups(response.groupsWithHex)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setDuplicateLoading(false)

                })
                .catch(err => {
                    // console.log(err);
                }); 
        }
    }
  

  useEffect(() => {
    getLiveClassList()
  }, [])

    return(
        <>
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>Live Classes</h3>
                        {
                            liveCLassesListLoading &&
                            <span className='pull-right'>{'Fetching...'}</span>
                        }
                    </div>
                    <div className='box-body'>
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>Class Name</th>
                                    <th>Started By</th>
                                    <th>Started On</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    liveCLassesList.length > 0 &&
                                    liveCLassesList.map((item, index) => {
                                        return(
                                            <tr key={"list" + index}>
                                                <td style={{width:"80px"}} >{index + 1}</td>
                                                <td>
                                                    <strong>{item.classdata.title}</strong><br />
                                                    <small>
                                                        {
                                                            item.path.length > 0 &&
                                                            item.path.map((item2, index2) => {
                                                                return(
                                                                    index2 === 0 ?
                                                                    item2.name + ": "
                                                                    :
                                                                    <a href={'/admin/assets/' + item2.main_parent + "/" + item2.p_id} target='_BLANK' key={"path"+index2}>{item2.name}/ </a>
                                                                )
                                                            })
                                                        }
                                                    </small>
                                                </td>
                                                <td>
                                                    <strong>
                                                        <span className="text-muted">By <a href={'/admin/staff?id='+item.staff.id} title='View Profile' target='_BLANK'>{item.staff.name}</a></span>
                                                    </strong><br />
                                                    <small>{item.user} ({(item.user === "zoom@escholars.in" ? "500" : "100")})</small>
                                                </td>
                                                <td>{item.date}</td>
                                                <td>
                                                    { 
                                                        item.status === "Waiting" &&
                                                        <span className='label bg-yellow' >Waiting</span>
                                                    }
                                                    { 
                                                        item.status === "Active" &&
                                                        <span className='label bg-green' >Active</span>
                                                    }
                                                </td>
                                                <td>
                                                    { 
                                                        item.status === "Active" &&
                                                        <span className='label bg-red cursor-pointer' onClick={() => endClass(item, "End")}>End Class?</span>
                                                    }
                                                    { 
                                                        item.status === "Waiting" &&
                                                        <span className='label bg-yellow cursor-pointer' onClick={() => endClass(item, "Free")}>Free This User?</span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>New Encoded Encryptions</h3>
                        {
                            liveCLassesListLoading &&
                            <span className='pull-right'>{'Fetching...'}</span>
                        }
                        <span className='pull-right' >{remaining}</span>
                    </div>
                    <div className='box-body'>
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>File Name</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    encryptionList.length > 0 &&
                                    encryptionList.map((item, index) => {
                                        return(
                                            <tr key={"enc" + index}>
                                                <td style={{width:"80px"}} >{index+1}</td>
                                                <td>
                                                    <a href={'assets/' + item.main_parent + '/' + item.p_id } target='_BLANK'>
                                                        {item.name}
                                                    </a>
                                                </td>
                                                <td>
                                                
                                                    { 
                                                        item.type === "Recording" &&
                                                        <span className='label bg-red cursor-pointer'>Recording</span>
                                                    }
                                                    { 
                                                        item.type === "Video" &&
                                                        <span className='label bg-yellow cursor-pointer'>Video</span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>


            {
                notFound.length > 0 &&
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>Source Files Not Found</h3>
                        <span className='pull-right' >{notFound.length}</span>
                    </div>
                    <div className='box-body'>
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>File Name</th>
                                    <th>Encode Type</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    notFound.length > 0 &&
                                    notFound.map((item, index) => {
                                        return(
                                            <tr key={"enc" + index}>
                                                <td style={{width:"80px"}} >{index+1}</td>
                                                <td>
                                                    <a href={'assets/' + item.main_parent + '/' + item.p_id } target='_BLANK'>
                                                        {item.name}
                                                    </a>
                                                </td>
                                                <td>
                                                    { 
                                                        item.newsrc === null &&
                                                        <span className='label bg-red cursor-pointer'>OLD</span>
                                                    }
                                                    { 
                                                        item.newsrc !== null &&
                                                        <span className='label bg-green cursor-pointer'>New</span>
                                                    }
                                                </td>
                                                <td>
                                                
                                                    { 
                                                        item.type === "Recording" &&
                                                        <span className='label bg-red cursor-pointer'>Recording</span>
                                                    }
                                                    { 
                                                        item.type === "Video" &&
                                                        <span className='label bg-yellow cursor-pointer'>Video</span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {
                (removal !== "" ) &&
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>Removing From <strong>{removal.course.name}</strong></h3>
                        <span className='pull-right label bg-blue'>{removal.count}</span>
                    </div>
                </div>
            }
                
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>Duplicate Videos (<strong>{(duplicateList.length > 0) ? (duplicateList.length/2) : 0}</strong>) </h3>
                        {
                            !duplicateLoading ?
                                <span className='pull-right label bg-blue cursor-pointer' onClick={() => getDuplicateList()}>Fetch List?</span>
                            :
                                <span className='pull-right label bg-yellow cursor-pointer' >Loading Please Wait...</span>
                        }
                    </div>
                    <div className='box-body'>
                        
                    <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>File Name</th>
                                    <th>Video Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    duplicateList.length > 0 &&
                                    duplicateList.map((item, index) => {
                                        
                                        var color = colorGroups[item.filename] !== undefined ? "#"+colorGroups[item.filename] : "#000000"
                                        return(
                                            <tr key={"enc" + index}>
                                                <td style={{width:"80px"}} >{index+1}</td>
                                                <td>
                                                    <a href={'assets/' + item.main_parent + '/' + item.p_id } target='_BLANK'>
                                                        {item.name}
                                                    </a>
                                                </td>
                                                <td>
                                                    {/* colorGroups */}
                                                    <span className='label' style={{ backgroundColor: color }} >
                                                        {item.filename}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
  }
  return (
    <>
      <Sidebar page={Processespage} permission="Global"/>
    </>
  )
}
