import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
import { CSVLink, CSVDownload } from "react-csv";
const cookies = new Cookies();


export default function NotificationStaff() {
  const MyNotificationpage = (props) => {
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url} = CommonFunc()
    const [loading, setLoading] = useState(false)
    const [myNotifications, setMyNotifications] = useState([])
    const [segment, setSegment] = useState(0)
    let getToken = cookies.get('token')
    
    const getMyNotifications = () => {
        
        setLoading(true)
        fetch(apiurl+"api/getNotifications", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setMyNotifications(response.notification)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
            });
    }

    var a = 0
    useEffect(() => {
        if(a === 0){
            getMyNotifications()
            a++
        }
    }, [])
    
    return(
        // (pagePermissions(props.pagepermissions, "Assets", "download_permission")) ?
        <>
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            My Notifications
                        </h3>
                    </div>
                    
                    {
                        loading &&
                        <div className="box-footer with-border">
                            <br /><br /><br />
                            <Loading />
                            <br /><br /><br />
                        </div>    
                    }
                    {
                        !loading &&
                        typeof myNotifications[segment] !== "undefined" &&
                        myNotifications[segment].length > 0 &&
                        myNotifications[segment].map((item, index) => {
                            moment.locale('en');
                            var dt = item.updated_at;
                            var ct = item.created_at;
                            return(
                                <div className="box-footer with-border" key={"notification" + index}>
                                    <div className="user-block">
                                        <img className="img-circle" src={ (item.read === null) ? "https://cdn.cdnbox.org/rejarvis/bell.png" : "https://cdn.cdnbox.org/rejarvis/bell (1).png"} alt="Bell" />
                                        <span className="username"><a href={item.redirect_url} target='_blank'>{item.title}</a></span>
                                        <span className="description">Date :- {moment(ct).format("DD-MM-YYYY HH:mm:ss")}</span>
                                    </div>
                                </div>
                                )
                            })
                    }

                    <div className='box-footer'>
                        <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                            {
                                myNotifications.length > 0 && myNotifications.length <= 10 &&
                                myNotifications.map((item, index) => {
                                    const active = index === segment ? " btn-primary" : ""
                                    return(
                                        <button className={'btn btn-flat'+active} key={"key"+index} style={{marginLeft:"5px"}} onClick={() => {
                                            setMyNotifications(index)
                                            document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} >
                                            {index+1}
                                        </button>
                                    )
                                })
                            }
                            {
                                myNotifications.length > 10 &&
                                <div style={{width:"200px", float:"right"}}>
                                    <label>Last Page: {myNotifications.length}</label>
                                    <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                        setMyNotifications(e.target.value - 1)
                                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                                        }} />
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
            

            </>

        // :

        // <Notfound />

    )
  }
  return (
    <>
        {
            
            <Sidebar page={MyNotificationpage} permission="Global"/>
            
        }
    </>
  )
}
