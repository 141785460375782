import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import { useParams } from 'react-router-dom';
import Adminprops from '../../VelidateWebsite/Adminprops';
import CommonFunc from '../includes/Common';
import apiurl from '../../Global';
const cookies = new Cookies();


export default function Preview() {

  const params = useParams()
  const [encrypted, setEncrypted] = useState("")
  let admin = cookies.get('admin')
  const { updatTrail} = CommonFunc()

  const Prev = () => {
    return(
    <div className='col-md-12'>
      <div className='box no-border'>
        <div className='box-header'>
          <h3 className='box-title'>Preview</h3>
        </div>
        <div className='box-body'>
          <iframe src={'/pdfview/web/viewer.html?src='+encrypted+'&e='+window.crypt("Omesh@558", admin['email'])+'&c='+window.crypt("Omesh@558", (admin['contact'] === null) ? ' ' : admin['contact'])} style={{width:"100%", height:"calc(100vh - 200px)",border:0}}></iframe>
        </div>
      </div>
    </div>
    )
  }

  var a = 0
  useEffect(() => {
    if(a === 0){
      setEncrypted(window.crypt("Omesh@558", params.id));
      updatTrail("<strong>"+admin['name']+"</strong> Viewed pdf ", "Assets", "2")
      // updatTrail("<strong>"+admin['name']+"</strong> Viewed pdf <a href='"+apiurl+"api/viewPdf/"+params.id+"' target='_BLANK'>View PDF</a>", "Assets", "2")
      a++
    }
  }, [])


  return (
    <Sidebar page={Prev} permission="Global"/>
  )
}
