import React from 'react'

export default function Footer() {
  return (
    <>
        
{/* /.content-wrapper */}
  <footer className="main-footer">
    <div className="pull-right hidden-xs">
    </div>
    <strong>Copyright &copy; <a>Escholar</a>.</strong> All rights
    reserved.
  </footer>

    </>
  )
}
