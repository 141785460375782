import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../../includes/Common';
import Cookies from 'universal-cookie';
import apiurl from '../../../Global';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

const cookies = new Cookies();

export default function Myorders(props) {
  
  const navigate = useNavigate()
  const params = useParams()
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
  
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const [purchaseInfo, setPurchaseInfo] = useState(null)

    const [course, setCourses] = useState([])
    const [expired, setExpired] = useState([])
    const [coursetab, setCourseTab] = useState("tab0")
    
    const [filemanagerData, setFilemanagerData] = useState(null)
    const [filemanagerLoading, setFilemanagerLoading] = useState(false)
    const [pathList, setPathList] = useState([])
    const [activeCourse, setActiveCourse] = useState(null)
    const [activeCourseType, setActiveCourseType] = useState("Content")


    const getCourseContent = (purchaseid, course_id, pid) => {
      setFilemanagerLoading(true)
      fetch(apiurl+"api/filemanagerdata", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+props.dummyToken
        },
        "body": JSON.stringify({
          purchaseid: purchaseid,
          courseid: course_id,
          pid: pid,
          userid: params.userid,
        })
        })
        .then(response => response.json()) 
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setFilemanagerData(response.data)
                setPathList(response.pathlist)
                setFilemanagerLoading(false)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
                setFilemanagerLoading(false)
            }
        })
        .catch(err => {
            console.log(err)
            setFilemanagerLoading(false)
        });
    }

    const getPurchaseInfo = (purchaseid, type) => {
      setLoading(true)
      setCourseTab("tab0")
      setActiveCourseType(type)
      fetch(apiurl+"api/purchaseinfo", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+props.dummyToken
          },
          "body": JSON.stringify({
            purchaseid: purchaseid,
          })
          })
          .then(response => response.json()) 
          .then(response => {
              if(typeof response.status !== "undefined" && response.status === "OK"){
                  setPurchaseInfo(response.data)
                  setLoading(false)
                  if(response.data.coursesList !== undefined && response.data.coursesList !== null && response.data.coursesList.length > 0){
                      getCourseContent(purchaseid, response.data.coursesList[0].course_id, 0)
                      setActiveCourse(response.data.coursesList[0].course_id)
                  }
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  // alert(response.message)
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
                  setLoading(false)
              }
          })
          .catch(err => {
              console.log(err)
              setLoading(false)
          });
    }

  const getUserData = () => {
    fetch(apiurl+"api/user/myOrders", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+props.dummyToken
        },
        "body": JSON.stringify({
            uid: params.userid,
            from: "admin"
        })
        })
        .then(response => response.json()) 
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setCourses(response.courses)
                setExpired(response.expired)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            console.log(err)
        });
  } 
  const openFolder = (item) => {
    if(purchaseInfo !== null) {
      if(item.activeCourseType ==="Folder"){
        getCourseContent(purchaseInfo.purchase_id, activeCourse, item.id)
        document.getElementById('coursedata').scrollIntoView(true);
      }
    }
  }

  var a = 0  
  useEffect(() => {
    if(a === 0)    {
      getUserData()
      a++
    }
  }, [])

  return (
    <>
    {
      !loading ?
      <>
      {
        purchaseInfo === null &&
      <div className='col-md-12'>
          <br />
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
                Active Courses/Packages
              </h3>
            </div>
            <div className='box-body' style={{overflow:"auto"}} >
              <table id="studenttable" className="table table-bordered table-hover datatable">
                <thead>
                    <tr>
                        <th style={{width:"80px"}} >S.No</th>
                        <th>Course/Package</th>
                        <th>Instructor</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Expiring On</th>
                        <th>Purchase ID</th>
                        <th>Payment ID</th>
                        <th>Puchased On</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    course.length > 0 &&
                    course.map((item, index) => {
                      const courseData =  JSON.parse(item.information)
                      moment.locale('en');
                      var dt = item.created_at;
                      
                      return(
                        <tr key={"data"+index} >
                            <td style={{width:"80px"}} >{index+1}</td>
                            <td>
                              {/* <Link to={"/admin/course/"+courseData.course.otherid} target="_blank" > */}
                              <a onClick={() => getPurchaseInfo(item.purchase_id, "Content")} >
                                <strong>
                                  {courseData.course.name}&nbsp;
                                  {
                                    item.books !== null &&
                                    <i className='fa fa-book'></i>
                                  }
                                  {
                                    courseData.price.deduct !== undefined && courseData.price.deduct > 0 &&
                                    <span style={{color:"#EFCD5A"}} >
                                      &nbsp;
                                      <i className='fa fa-database' ></i>
                                    </span>
                                  }
                                </strong><br />
                                <small>{courseData.course.subheading}</small>
                                {
                                  item.firstInteract !== undefined &&
                                  <>
                                    <small><strong>First Interaction: </strong>{item.firstInteract}</small>
                                  </>
                                }
                              </a>
                              {/* </Link> */}
                            </td>
                            <td>{(courseData.course.coursedata !== null) ? courseData.course.coursedata.instructor : ""}</td>
                            <td>{courseData.course.coursetype}</td>
                            
                            <td>
                              {
                                courseData.price.deduct !== undefined && courseData.price.deduct > 0 && courseData.price.final === 0 && 
                                "By Coins"
                              }
                              {
                                courseData.price.deduct !== undefined && courseData.price.deduct < 1 && courseData.price.final === 0 ?
                                "Free" : "Paid"
                              }
                            </td>
                            <td>{item.exp}</td>
                            
                            {/* <td onClick={() => getPurchaseInfo(item.purchase_id, "Info")} > */}
                            <td >
                              <Link to={'/admin/order-info/'+item.purchase_id+'/'+params.userid} target='_blank'>
                                {item.prefixid}
                              </Link>
                            </td>
                            <td >
                                {item.payment_id}
                            </td>
                            <td>
                              {moment(dt).format("D-M-Y HH:mm:ss")}
                            </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
                In-Active Courses/Packages
              </h3>
            </div>
            <div className='box-body' style={{overflow:"auto"}} >
              <table id="studenttable" className="table table-bordered table-hover datatable">
                <thead>
                    <tr>
                        <th style={{width:"80px"}} >S.No</th>
                        <th>Course name</th>
                        <th>Instructor</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Expiring On</th>
                        <th>Purchase ID</th>
                        <th>Payment ID</th>
                        <th>Puchased On</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    expired.length > 0 &&
                    expired.map((item, index) => {
                      const courseData =  JSON.parse(item.information)
                      moment.locale('en');
                      var dt = item.created_at;
                      
                      return(
                        <tr key={"data"+index} >
                            <td style={{width:"80px"}} >{index+1}</td>
                            <td>
                              {/* <Link to={"/admin/course/"+courseData.course.otherid} target="_blank" > */}
                              <a onClick={() => getPurchaseInfo(item.purchase_id, "Content")} >
                                <strong>
                                  {courseData.course.name}&nbsp;
                                  {
                                    item.books !== null &&
                                    <i className='fa fa-book'></i>
                                  }
                                  {
                                    courseData.price.deduct !== undefined && courseData.price.deduct > 0 &&
                                    <span style={{color:"#EFCD5A"}} >
                                      &nbsp;
                                      <i className='fa fa-database' ></i>
                                    </span>
                                  }
                                </strong><br />
                                <small>{courseData.course.subheading}</small>
                                {
                                  item.firstInteract !== undefined &&
                                  <>
                                    <small><strong>First Interaction: </strong>{item.firstInteract}</small>
                                  </>
                                }
                              </a>
                              {/* </Link> */}
                            </td>
                            <td>{(courseData.course.coursedata !== null) ? courseData.course.coursedata.instructor : ""}</td>
                            <td>{courseData.course.coursetype}</td>
                            <td>
                              {
                                courseData.price.deduct !== undefined && courseData.price.deduct > 0 && courseData.price.final === 0 && 
                                "By Coins"
                              }
                              {
                                courseData.price.deduct !== undefined && courseData.price.deduct < 1 && courseData.price.final === 0 ?
                                "Free" : "Paid"
                              }
                            </td>
                            <td>{item.exp}</td>
                            
                            {/* <td onClick={() => getPurchaseInfo(item.purchase_id, "Info")} > */}
                            <td >
                              <Link to={'/admin/order-info/'+item.purchase_id+'/'+params.userid} target='_blank'>
                                {item.prefixid}
                              </Link>
                            </td>
                            <td >
                                {item.payment_id}
                            </td>
                            <td>
                              {moment(dt).format("D-M-Y HH:mm:ss")}
                            </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>
}

{
        purchaseInfo !== null &&
        <>
        {
           activeCourseType ==="Info" &&
           <>
        <div className='col-md-12'>
            <br />
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className='box-title'>
                  Order Summary of #{purchaseInfo.prefixid} 
                  
                </h3>
                  <span className='pull-right btn btn-flat btn-primary' onClick={() => setPurchaseInfo(null)} >
                    <i className='fa fa-times'></i>
                  </span>
              </div>
            </div>
        </div>
        <div className='col-md-12 no-left-padding no-right-padding'>
        <div className='col-md-4'>
          <div className='box no-border collapsed-box'>
            <div className='box-header'>
              <h3 className='box-title'>
                Basic Order Information
              </h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className='box-body'>
              <ul className="list-group list-group-unbordered">
                <li className="list-group-item">
                    <b>Name</b> <a className='pull-right'>{purchaseInfo['userdata']['name']}</a>
                </li>
                <li className="list-group-item">
                    <b>Email</b> <a className='pull-right'>{purchaseInfo['userdata']['email']}</a>
                </li>
                <li className="list-group-item">
                    <b>Contact</b> <a className='pull-right'>{purchaseInfo['userdata']['contact']}</a>
                </li>
                <li className="list-group-item">
                    <b>Student ID</b> <a className='pull-right'>{purchaseInfo['uid']}</a>
                </li>
                <li className="list-group-item">
                    <b>Order Date</b> <a className='pull-right'>{purchaseInfo['processdata'][0]['orderDate']}</a>
                </li>
                <li className="list-group-item">
                    <b>Item Type</b> <a className="pull-right">{(purchaseInfo.information.course.coursetype !== "") ? purchaseInfo.information.course.coursetype : ""}</a>
                </li>
                <li className="list-group-item">
                    <b>Books Available?</b> <a className="pull-right">{(purchaseInfo.information.course.books === 1) ? "Yes" : "No"}</a>
                </li>
                <li className="list-group-item">
                    <b>Order ID</b> <a className="pull-right">{purchaseInfo.prefixid}</a>
                </li>
                <li className="list-group-item">
                    <b>Payment ID</b> <a className="pull-right">{purchaseInfo.payment_id}</a>
                </li>
                <li className="list-group-item">
                    <b>Cart ID</b> <a className="pull-right">{purchaseInfo.cartid}</a>
                </li>
                <li className="list-group-item">
                    <b>Consignment Status</b> <a className="pull-right" >{purchaseInfo.process.toUpperCase()}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className='col-md-4'>
          <div className='box no-border collapsed-box'>
            <div className='box-header'>
              <h3 className='box-title'>
                Selected Plan Information
              </h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className='box-body'>
              <ul className="list-group list-group-unbordered">
                <li className="list-group-item">
                    <b>Plan Name</b> <a className="pull-right">{purchaseInfo.information.plan.plan_name}</a>
                </li>
                <li className="list-group-item">
                    <b>Listing Price</b> <a className="pull-right">{purchaseInfo.information.plan.listing_price}</a>
                </li>
                <li className="list-group-item">
                    <b>Selling Price</b> <a className="pull-right">{purchaseInfo.information.plan.selling_price}</a>
                </li>
                <li className="list-group-item">
                    <b>Discount</b> <a className="pull-right">{purchaseInfo.information.plan.discount} ({purchaseInfo.information.plan.off}% OFF)</a>
                </li>
                <li className="list-group-item">
                    <b>GST(%)</b> <a className="pull-right">{purchaseInfo.information.plan.gst}%</a>
                </li>
                <li className="list-group-item">
                    <b>Expired in Days </b> <a className="pull-right">{purchaseInfo.information.plan.days}</a>
                </li>
                <li className="list-group-item">
                    <b>Exact On Exact Date </b> <a className="pull-right">{purchaseInfo.information.plan.exactdate}</a>
                </li>
                <li className="list-group-item">
                    <b>Total Hours </b> <a className="pull-right">{purchaseInfo.information.plan.hours}</a>
                </li>
                <li className="list-group-item">
                    <b>Expiry Info </b> <a className="pull-right">{purchaseInfo.information.plan.tag}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box no-border collapsed-box'>
            <div className='box-header'>
              <h3 className='box-title'>
              Payment Summary
              </h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className='box-body'>
              <ul className="list-group list-group-unbordered">
                <li className="list-group-item">
                    <b>Listing Price</b> <a className="pull-right">{purchaseInfo.information.price.price}</a>
                </li>
                <li className="list-group-item">
                    <b>Discount</b> <a className="pull-right">{purchaseInfo.information.price.discount}</a>
                </li>
                <li className="list-group-item">
                    <b>Additional Discount</b> <a className="pull-right">{purchaseInfo.information.price.additional}</a>
                </li>
                <li className="list-group-item">
                    <b>GST</b> <a className="pull-right">{purchaseInfo.information.price.gst} ({purchaseInfo.information.price.gstper}%)</a>
                </li>
                <li className="list-group-item">
                    <b>Paid Amount </b> <a className="pull-right">{purchaseInfo.information.price.final}</a>
                </li>
                <li className="list-group-item">
                    <b>Used Coins </b> <a className="pull-right">{purchaseInfo.information.price.deduct}</a>
                </li>
                <li className="list-group-item">
                    <b>Earned CB Coins </b> <a className="pull-right">{purchaseInfo.information.price.cb}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
        </>
      }
        <div className='col-md-12 no-left-padding no-right-padding'>
          {
             activeCourseType ==="Info" &&
          <div className='col-md-4'>
            <div className='box no-border collapsed-box'>
              <div className='box-header'>
                <h3 className='box-title'>
                Shipping Address
                </h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                      <b>Name</b> <a className="pull-right">{purchaseInfo.address.name}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Email</b> <a className="pull-right" href={'mailto:'+purchaseInfo.address.email}>{purchaseInfo.address.email}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Contact</b> <a className="pull-right" href={'tel:'+purchaseInfo.address.contact}>{purchaseInfo.address.contact}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Address</b> <a className="pull-right">{purchaseInfo.address.address}</a>
                  </li>
                  <li className="list-group-item">
                      <b>City</b> <a className="pull-right">{purchaseInfo.address.city}</a>
                  </li>
                  <li className="list-group-item">
                      <b>State</b> <a className="pull-right">{purchaseInfo.address.state}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Pincode</b> <a className="pull-right">{purchaseInfo.address.pincode}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Landmark</b> <a className="pull-right">{purchaseInfo.address.landmark}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      }

      {
        typeof purchaseInfo.information.price.promocode !== "undefined" && purchaseInfo.information.price.promocode !== null && purchaseInfo.information.price.promocode.status === "OK" && activeCourseType ==="Info" &&
        
          <div className='col-md-4'>
            <div className='box no-border collapsed-box'>
              <div className='box-header'>
                <h3 className='box-title'>
                Promocode Used
                </h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                        <b>Promocode</b> <a className="pull-right">#{purchaseInfo.information.price.promocode.code}</a>
                    </li>
                    <li className="list-group-item">
                        <b>Percentage Off</b> <a className="pull-right">{purchaseInfo.information.price.promocode.off}</a>
                    </li>
                    <li className="list-group-item">
                        <b>Discount Received</b> <a className="pull-right">{purchaseInfo.information.price.promocode.promodiscount}</a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
      }
      
      {
        typeof purchaseInfo.information.price.promolinkarray !== "undefined" && 
        purchaseInfo.information.price.promolinkarray !== null &&
        purchaseInfo.information.price.promolinkarray !== "" &&
        typeof purchaseInfo.information.price.promolinkarray.title !== "undefined" && activeCourseType ==="Info" &&
          <div className='col-md-4'>
            <div className='box no-border collapsed-box'>
              <div className='box-header'>
                <h3 className='box-title'>
                Promocode Used
                </h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                      <b>Link Title</b> <a className="pull-right">{purchaseInfo.information.price.promolinkarray.title}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Discount Received</b> <a className="pull-right">{purchaseInfo.information.price.promolinkarray.discount}</a>
                  </li>
                  <li className="list-group-item">
                      <b>Link Code</b> <a className="pull-right">{purchaseInfo.information.price.promolinkarray.codeid}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      }
      {
        purchaseInfo.packagecontent !== null && purchaseInfo.packagecontent.length > 0 &&  activeCourseType ==="Info" &&
        <div className='col-md-4'>
            <div className='box no-border collapsed-box'>
              <div className='box-header'>
                <h3 className='box-title'>
                Package Content
                </h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                  
                {
                  purchaseInfo.packagecontent.map((item, index) => {
                      return(
                          <li className="list-group-item" key={"also"+index}>
                              <b>{item.name}</b> <a className="pull-right">#{item.courseid}</a>
                          </li>
                          )
                      })
                  }
                  

                </ul>
              </div>
            </div>
          </div>
      }

      {
        purchaseInfo.together.length > 1 && activeCourseType ==="Info" &&
        <div className='col-md-4'>
            <div className='box no-border collapsed-box'>
              <div className='box-header'>
                <h3 className='box-title'>
                Package Content
                </h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                  
                {
                  purchaseInfo.together.map((item, index) => {
                      return(
                          <li className="list-group-item" key={"also"+index}>
                              <b>{item.info.course.name}</b> <a className="pull-right">#{item.orderid}</a>
                          </li>
                          )
                      })
                  }
                  

                </ul>
              </div>
            </div>
          </div>
      }
      {
          purchaseInfo.gst !== null && activeCourseType ==="Info" &&
          <div className='col-md-4'>
            <div className='box no-border collapsed-box'>
              <div className='box-header'>
                <h3 className='box-title'>
                Gst Information
                </h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                  <li className="list-group-item">
                      <b>Company Name</b> <a className="pull-right">{purchaseInfo.gst.company}</a>
                  </li>
                  <li className="list-group-item">
                      <b>GST No</b> <a className="pull-right" >{purchaseInfo.gst.gst}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      }
      {
        purchaseInfo.reference !== null && purchaseInfo.consignment !== null && activeCourseType ==="Info" &&
        <div className='col-md-4'>
          <div className='box no-border collapsed-box'>
            <div className='box-header'>
              <h3 className='box-title'>
              Consignment Information
              </h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" style={{backgroundColor:"transparent"}} data-widget="collapse"><i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className='box-body'>
              <ul className="list-group list-group-unbordered">
                <li className="list-group-item">
                    <b>Reffrence No</b> <a className="pull-right">{purchaseInfo.reference}</a>
                </li>
                <li className="list-group-item">
                    <b>Name</b> <a className="pull-right">{purchaseInfo.consignment.consignments[0]['destination_details'].name}</a>
                </li>
                <li className="list-group-item">
                    <b>Contact</b> <a className="pull-right" href={'tel:'+purchaseInfo.consignment.consignments[0]['destination_details'].phone}>{purchaseInfo.consignment.consignments[0]['destination_details'].phone}</a>
                </li>
                <li className="list-group-item">
                    <b>Address</b> <a className="pull-right">{purchaseInfo.consignment.consignments[0]['destination_details'].address_line_1}</a>
                </li>
                <li className="list-group-item">
                    <b>City</b> <a className="pull-right">{purchaseInfo.consignment.consignments[0]['destination_details'].city}</a>
                </li>
                <li className="list-group-item">
                    <b>State</b> <a className="pull-right">{purchaseInfo.consignment.consignments[0]['destination_details'].state}</a>
                </li>
                <li className="list-group-item">
                    <b>Pincode</b> <a className="pull-right">{purchaseInfo.consignment.consignments[0]['destination_details'].pincode}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }

      {
        purchaseInfo.coursesList !== undefined && purchaseInfo.coursesList !== null && purchaseInfo.coursesList.length > 0 && activeCourseType ==="Content" &&
        <div className='col-md-12' id='coursedata'>
          <br />
          <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
              {
                purchaseInfo.coursesList.map((item, index) => {
                  return(
                      <li key={"tab"+index} className={coursetab === "tab"+index ? "active" : ""} onClick={() => {
                        setCourseTab("tab"+index)
                        getCourseContent(purchaseInfo.purchase_id, item.course_id, 0)
                      }} ><a href={"#tab"+index} data-toggle="tab">{item.course_information.name}</a></li>
                     )
                  })
                }
                <li className='pull-right'>
                  <span className='pull-right btn btn-flat btn-primary' onClick={() => setPurchaseInfo(null)} >
                      <i className='fa fa-times'></i>
                    </span>
                </li>
              </ul>
              <div className="tab-content">
                
              {
                purchaseInfo.coursesList.map((item, index) => {
                  return(
                    <div className={"tab-pane "+ ((coursetab === "tab"+index) ? 'active' : '')} id={"tab"+index} key={"tabbody"+index}>
                      <br />
                      <div className='col-md-12'>
                      <div className="attachment-block clearfix">
                        <img className="attachment-img" src={(item.course_information.thumbnail === null || item.course_information.thumbnail === "") ? process.env.PUBLIC_URL+'/assets/assets/images/no-image.png' : assets_url + item.course_information.thumbnail} alt="Attachment Image" />

                        <div className="attachment-pushed">
                          <h4 className="attachment-heading">
                            <a href={"/admin/course/"+item.courseid} target='_blank' >
                              {item.course_information.name}
                            </a>
                            
                              <small className={"label pull-right " +((item.expired === null) ? "bg-green" : "bg-red")}>{item.percentage}% Completed</small>
                            </h4>

                          <div className="attachment-text">
                            <strong>Course ID:</strong> { item.course_id } 
                            <br />
                            {
                              item.expiredate !== null && item.expiredate !== "" &&
                              <>
                              <strong>Expiring/Expired On:</strong> { item.expiredate }<br />
                              </>
                            }
                            {
                              item.information.expirehour !== null && item.information.expirehour !== "" &&
                              <>
                                <strong>Remaining Watching Hour(s):</strong> {item.expirehour}/{ item.information.expirehour }<br />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      </div>
                      {
                        pathList.length > 0 &&
                        <div className='col-md-12'>
                          <div className='box no-border'>
                            <div className='box-body breadCrums' style={{
                              padding:"10px"
                            }}>
                              <ul >
                                <li key={"path"+index} onClick={() => openFolder({"type":"Folder", id:"0"})}>
                                    <a>
                                      Root: &nbsp;
                                    </a>
                                  </li>
                                {
                                  pathList.map((item, index) => {
                                    return(
                                     <li key={"path"+index} onClick={() => openFolder(item)} >
                                        <a>
                                          &nbsp;{item.name}/ 
                                        </a>
                                     </li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      }
                      <div className='col-md-12'>
                        <div className='box no-border'>
                          <div className='box-body'>
                            {
                              filemanagerLoading ? 
                              <>
                                <br /><br /><br />
                                <Loading />
                                <br /><br /><br />
                              </>
                              :
                              <table id="filmanagertable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>File name</th>
                                        <th>Web Time Spent</th>
                                        <th>Android Time Spent</th>
                                        <th>IOS Time Spent</th>
                                        <th>Total Time Spent</th>
                                        <th>Status</th>
                                        {/* <th>Action</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        filemanagerData.length > 0 &&
                                        filemanagerData.map((item, index) => {

                                            let img = "folder"
                                            if(item.type == "Document"){
                                                img = "pdf"
                                            }else if(item.type == "Question"){
                                                img = "answer"
                                            }else if(item.type == "Quiz"){
                                                img = "answer"
                                            }else if(item.type == "Video"){
                                                img = "play-button"
                                            }else if(item.type == "Recording"){
                                                img = "live"
                                            }else if(item.type == "Image"){
                                                img = "photo"
                                            }else if(item.type == "Youtube"){
                                                img = "youtube"
                                            }else if(item.type == "Live"){
                                                img = "voice"
                                            }else if(item.type == "Assignment"){
                                                img = "list"
                                            }
                                            
                                          return(

                                            <tr key={"index"+index} >
                                              <td>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} style={{width:"30px", width:"30px", objectFit:"contain"}} />
                                              </td>
                                              <td className='cursor-pointer' onClick={() => openFolder(item)}>
                                                {
                                                  item.activeCourseType ==="Folder" ?
                                                    <a >
                                                    {item.name}
                                                    </a>
                                                  :
                                                  item.name
                                                }
                                              </td>
                                              <td>
                                                { item.duration !== null ? item.duration.webduration : "-" }
                                              </td>
                                              <td>{ item.duration !== null ? item.duration.appduration : "-" }</td>
                                              <td>{ item.duration !== null ? item.duration.iosduration : "-" }</td>
                                              <td>{ item.duration !== null ? item.duration.total : "-" }</td>
                                              <td>
                                                  { 
                                                    item.status ?
                                                      <small className='label bg-green'>
                                                        <i className='fa fa-check'></i>  
                                                      </small>
                                                    :
                                                    <small className='label bg-red'>
                                                      <i className='fa fa-times'></i>  
                                                    </small>
                                                  }
                                              </td>
                                              {/* <td>{item.name}</td> */}
                                              {/* <td></td> */}
                                            </tr>
                                            
                                            )
                                          })
                                        }
                                    </tbody>
                                </table>
                              }
                            </div>
                        </div>
                        
                      </div>
                    </div>
                  )
                })
              }
              </div>
            </div>
        </div>
      }

        </div>
        </>
}
      </>
      :
      <div className='col-md-12'>
        <br />
        <div className='box no-border'>
          <div className='box-body'>
            <br /><br /><br />
              <Loading />
            <br /><br /><br />
          </div>
        </div>
      </div>
}
    </>
  )
}
