import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link,  useParams } from 'react-router-dom';
// import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loading from '../includes/Loading';
import Notfound from '../includes/Notfound';
import Basiccourseinfo from './Basiccourseinfo';
import Price from './Price';
import Seo from './Seo';
import Recyclebin from './Recyclebin';
import Filtercourse from './Filtercourse';
import Activity from './Activity';
import Filefeedback from './Filefeedback';
import Assignmentslist from './Assignmentslist';
import Attempts from './Attempts';
import Enrolled from './Enrolled';
import Mailcontent from './Mailcontent';
import Upsale from './Upsale';
import Resultbanners from './Resultbanners';
import Linkpromocodes from './Linkpromocodes';
import Expirycourse from './Expirycourse';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Singlechat from '../Chat/Singlechat';
import Relatedproducts from './Relatedproducts';
import Notifyme from './Notifyme';
import Bookstab from './Bookstab';
import Changelog from './Changelog';

const cookies = new Cookies();

export default function Course() {
    const mainparams = useParams()
    const courseindex = "course"+mainparams.id;
    
  const CoursePage = (props) => {
    // const navigate = useNavigate()
    // const admin = cookies.get('admin')
    const params = useParams()
    let getToken = cookies.get('token')
    const [course, setCourse] = useState("")
    const [loading, setLoading] = useState(true)
    const [tab, setTab] = useState("seo")
    const [loadingImg, setImgLoading] = useState(false)
    const {AdminBasics, successModal, errorModal, pagePermissions, warningModal} = CommonFunc()
    const [courseImage, setCourseImage] = useState(process.env.PUBLIC_URL+'/assets/assets/images/no-image.png')
    const [coursedata, setCoursedata] = useState("")
    const [pointsData, setPointsData] = useState("")

    const [customeRange, setCustomeRange] = useState(false)
    const [daysFilter, setDaysFilter] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [chartLoading, setChartLoading] = useState(true)

    const setCourseData = (data) => {
        setCourse(data)
        if(data.thumbnail !== "" && data.thumbnail !== null){
            setCourseImage(base_config.assets_url+data.thumbnail)
        }
        setCoursedata((data.coursedata !== "" && data.coursedata !== null) ? JSON.parse(data.coursedata) : "")
    }
    const [rightHeight, setRightHeight] = useState("634px")

    const courseBasics = () => {
        setLoading(true)
        fetch(apiurl+"api/course_basics", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: params.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCourseData(response.course)
                    setPointsData({"included" : response.includes, "why" : response.why, "faq" : response.faq, "plans" : response.plans, "activeplans" : response.activeplans, "coursecontent" : response.coursecontent})
                    setRightHeight(($("#leftCourse").innerHeight())+"px")
                    courseCharts()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
    }

    
    const courseCharts = () => {
        setChartLoading(true)
        fetch(apiurl+"api/course_charts", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: params.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    startDate: startDate,
                    endDate: endDate
                })
            })
            .then(response => response.json())
            .then(response => {
                setChartLoading(false)
                setRightHeight(($("#leftCourse").innerHeight())+"px")

                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setDaysFilter(response.days)
                    setTimeout(function(){
                        console.log(response.cityChart)
                        window.amchart("mapChart", response.cityChart)
                        window.ambarchart("ambarchart", response.dailyTraffic)
                        window.ambarchart("dailyRevenue", response.dailyRevenue)
                        // window.radiusChart("radiusChart", response.dailyTraffic)
                    }, 100)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                
            });
    }

    const changeCourseImage = (e, col, type) => {
        // setCourseImage()
        setImgLoading(true)
        const formData = new FormData();
        formData.append('course_image', (type == "add") ? e.target.files[0] : null)
        formData.append('courseid', params.id);
        formData.append('col', col);
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/change_course_image", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
        })
        .then(response => response.json())
        .then(response => { 
            $(e.target).val("")
            $(e.target).find(".loaderbody").fadeOut()
            if(typeof response.status !== "undefined" && response.status === "OK"){
                successModal("Success", response.message)
                setCourseData(response.course)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setImgLoading(false)
        })
        .catch(err => {
            // console.log(err);
        });
    }

    const checkActiveTab = () => {
        if(pagePermissions(props.pagepermissions, courseindex, "basic_information")){
            setTab("basic_information")
        }else if(pagePermissions(props.pagepermissions, courseindex, "price_variations")){
            setTab("price")
        }else if(pagePermissions(props.pagepermissions, courseindex, "link_promocodes")){
            setTab("link_promocodes")
        }else if(pagePermissions(props.pagepermissions, courseindex, "upsale")){
            setTab("upsale")
        }else if(pagePermissions(props.pagepermissions, courseindex, "seo")){
            setTab("seo")
        }else if(pagePermissions(props.pagepermissions, courseindex, "live_classes")){
            setTab("liveclasses")
        }else if(pagePermissions(props.pagepermissions, courseindex, "package_content")){
            setTab("subcourses")
        }else if(pagePermissions(props.pagepermissions, courseindex, "course_content")){
            setTab("coursecontent")
        }else if(pagePermissions(props.pagepermissions, courseindex, "mail_notification")){
            setTab("mail")
        }else if(pagePermissions(props.pagepermissions, courseindex, "assignments_tab")){
            setTab("assignments_tab")
        }else if(pagePermissions(props.pagepermissions, courseindex, "test_attempts_tab")){
            setTab("test_attempts_tab")
        }else if(pagePermissions(props.pagepermissions, courseindex, "files_feedback")){
            setTab("feedback")
        }else if(pagePermissions(props.pagepermissions, courseindex, "expire_notification")){
            setTab("expire_notification")
        }else if(pagePermissions(props.pagepermissions, courseindex, "books_tab")){
            setTab("books_tab")
        }
    }
    
    const filterChart = (e) => {
        e.preventDefault()
        courseCharts()
    }
    const [packageContent, setPackageContent] = useState([])
    const [positionTab, setPositionTab] = useState(false)
    const [positionTabLoading, setPositionTabLoading] = useState(false)

    

const rePositionAll = (array,) => {
    fetch(apiurl+"api/reposition_package_content", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                page_url: window.location.href,
                staff: AdminBasics(),
                position_list: array,
                courseid: params.id,
            })
        })
        .then(response => response.json())
        .then(response => {
        })
        .catch(err => { });
}


const callSorting = () => {
  window.$( ".droppable-area1" ).sortable({
          stop: function(event, ui) {
              var array = [];
              window.$(event.target).find(".dragHandle").each(function (){
                  var a = $(this).attr("data-bind");
                  array.push(a);
              });

              rePositionAll(array)
      },
      connectWith: ".connected-sortable",
      stack: '.connected-sortable ul',
      handle: ".dragHandle",
  }).disableSelection();
  
}

    const getPackageContent = () => {
        
        setPositionTabLoading(true)
        fetch(apiurl+"api/package_content", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: params.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setPackageContent(response.list)
                    setTimeout(function(){
                        callSorting()
                    },1000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setPositionTabLoading(false)
            })
            .catch(err => {
                
            });
    }


    const cloneThisProject = (cid) => {
        warningModal("Loading...", "Cloning Course Please Wait...")
        
        fetch(apiurl+"api/cloneCourse", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: cid,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    setTimeout(function(){
                        window.open("/admin/course/"+response.newcourseid)
                    }, 1000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
    }

    const removeThisCourseNow = () => {
        warningModal("Loading...", "Removing This Course/Package Please Wait...")
        
        fetch(apiurl+"api/removeThisCourse", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: params.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    setTimeout(function(){
                        window.location.href="/admin/manage-courses";
                    }, 1000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
    }

    const removeThisCourse = () => {
        
        confirmAlert({
            title: 'Are You Sure?',
            message: "You want to remove this Course/Package? YOU WON'T BE ABLE TO REVERT THIS BACK.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeThisCourseNow()
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }

    var a = 0;
    useEffect(() => {
        if(a === 0){
            checkActiveTab()
            courseBasics();
            a++
        }
    }, [])

    return(
        <>
        {
            loading ?
            <div style={{position:"absolute", top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.5)", zIndex:9}}>
                <Loading />
            </div>
            :
            course !== "" ?
                <>
                    <div className="col-md-4" >
                        <div className="box box-primary no-border">
                            <div className='box-body'>
                                <strong className='text-red'> Width 1600PX : Height 400PX || Aspect Ration 4:1 </strong>
                            </div>
                            <div className="box-body box-profile" id='leftCourse'>
                                <div className='courseCoverCon'>
                                    {
                                        <img src={(course.cover === null) ? process.env.PUBLIC_URL+'/assets/assets/images/cover.png' : base_config.assets_url + course.cover} />
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, courseindex, "basic_information") &&
                                        <>
                                            <input type={"file"} className='form-control' id="courseCover" accept=".png,.jpg,.jpeg,.gif,.svg" onChange={(e) => changeCourseImage(e, 'cover', "add")} />
                                            <div className='floatingOptions' onClick={() => (pagePermissions(props.pagepermissions, courseindex, "basic_information")) && window.$('#courseCover').click()} >
                                                <i className='fa fa-edit'></i>
                                            </div>
                                            <div className='floatingOptions' style={{top:"10px",bottom:"auto"}} onClick={(e) => (pagePermissions(props.pagepermissions, courseindex, "basic_information")) && changeCourseImage(e, "cover", "remove")}  >
                                                <i className='fa fa-times'></i>
                                            </div>
                                        </>
                                    }
                                    {
                                        loadingImg &&
                                        <div style={{position:"absolute", top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.5)", zIndex:9}}>
                                            <Loading />
                                        </div>
                                    }
                                </div>
                                <br />
                                <div className='box-body'>
                                    <strong className='text-red'> Width 600PX : Height 400PX || Aspect Ration 3:2 </strong>
                                </div>
                                <div className='courseImageCon' onClick={() => (pagePermissions(props.pagepermissions, courseindex, "basic_information")) && window.$('#courseImage').click()} onChange={(e) => changeCourseImage(e, 'thumbnail', "add")}>
                                    
                                    <img src={courseImage} alt="Course Photo" className='containImage cursor-pointer' title='Change Image'/>
                                    {
                                        pagePermissions(props.pagepermissions, courseindex, "basic_information") &&
                                        <input type={"file"} id="courseImage" className='form-control' accept=".png,.jpg,.jpeg,.gif,.svg" />
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, courseindex, "basic_information") &&
                                        <div className='changeImage'>
                                            <i className='fa fa-pencil'></i>
                                            &nbsp;
                                            Click to Change
                                        </div>
                                    }

                                    {
                                        loadingImg &&
                                        <div style={{position:"absolute", top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.5)", zIndex:9}}>
                                            <Loading />
                                        </div>
                                    }

                                </div>
                                <h3 className="profile-username text-center">
                                    {course.name}
                                </h3>
                                <p className="text-muted text-center">
                                    {(coursedata !== "") ? coursedata.subheading : ""}
                                </p>

                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Instructor</b> <a className="pull-right">{(coursedata !== "") ? coursedata.instructor : ""}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Language</b> <a className="pull-right">{(coursedata !== "") ? coursedata.language : ""}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Course Contain</b> <a className="pull-right">{(coursedata !== "") ? coursedata.contain : ""}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>This Course Contains</b> <a className="pull-right">{ pointsData.included } Points</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Why Choose This Course Has </b> <a className="pull-right">{ pointsData.why } Points</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>FAQ's Available</b> <a className="pull-right">{ pointsData.faq }</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Plans Available</b> <a className="pull-right">{ pointsData.plans }</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Course ID</b> <a className="pull-right">{ course.otherid }</a>
                                    </li>
                                    
                                    {
                                        pagePermissions(props.pagepermissions, "Courses", "clone_course")  && 
                                        <li className="list-group-item">
                                            <b>Clone This Course/Package</b> 
                                            <a className="pull-right" onClick={() => cloneThisProject(course.otherid)} >
                                                <span className='badge bg-blue' style={{padding:"10px",display:'inline-block'}} >
                                                    Clone?
                                                </span>
                                            </a>
                                        </li>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, "Courses", "remove_course")  && 
                                        <li className="list-group-item">
                                            <button className='btn btn-danger btn-flat btn-block' onClick={() => removeThisCourse()} >
                                                Remove This Course?
                                            </button>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        {/* <div className='box no-border'> */}
                            {/* <div className='box-header'>
                                <h3 className='box-title'>
                                    Course Activity
                                </h3>
                            </div> */}
                            <div id='rightCourse' style={{height: rightHeight, overflow:"auto", backgroundColor:"transparent"}}>
                                {
                                    chartLoading ?
                                    <Loading />
                                    :
                                    <>
                                    {
                                    pagePermissions(props.pagepermissions, courseindex, "daily_traffic_chart") &&
                                        <div className='col-md-12'>
                                            <div className='box no-border'>
                                                <div className='box-header'>
                                                    <h3 className='box-title'>
                                                        Daily Traffic (Clicks)
                                                    </h3>
                                                    {
                                                        daysFilter !== "" &&
                                                        <div className='pull-right'>
                                                            <form action='' method='POST' style={{display:"flex"}} onSubmit={(e) => filterChart(e) } >
                                                                <select className='form-control' required onChange={(e) => {
                                                                    var val = e.target.value
                                                                    setCustomeRange((val === "custome") ? true : false)
                                                                    if(val !== "custome" && val !== ""){
                                                                        setStartDate(daysFilter[val]['start'])
                                                                        setEndDate(daysFilter[val]['end'])
                                                                    }else{
                                                                        setStartDate("")
                                                                        setEndDate("")
                                                                    }
                                                                }}>
                                                                    <option value={''}>Select Duration</option>
                                                                    <option value={'seven'}>Seven Days</option>
                                                                    <option value={'month'}>1 Month</option>
                                                                    <option value={'monthsix'}>6 Months</option>
                                                                    <option value={'custome'}>Custome Range</option>
                                                                </select>&nbsp;
                                                                {
                                                                    customeRange &&
                                                                    <>
                                                                        <input type={"date"} className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />&nbsp;
                                                                        <input type={"date"} className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />&nbsp;
                                                                    </>
                                                                }
                                                                <button className='btn btn-primary btn-flat'>
                                                                    <i className='fa fa-filter'></i>
                                                                </button>
                                                            </form>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='box-body' style={{position:"relative"}}>
                                                    <div className='hideChartLogo'></div>
                                                    <div id="ambarchart" style={{ width: "100%", height: "500px" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                    pagePermissions(props.pagepermissions, courseindex, "daily_revenue_chart") &&
                                        <div className='col-md-12'>
                                            <div className='box no-border'>
                                                <div className='box-header'>
                                                    <h3 className='box-title'>
                                                        Daily Revenue (Units Sold)
                                                    </h3>
                                                    {
                                                        daysFilter !== "" &&
                                                        <div className='pull-right'>
                                                            <form action='' method='POST' style={{display:"flex"}} onSubmit={(e) => filterChart(e) } >
                                                                <select className='form-control' required onChange={(e) => {
                                                                    var val = e.target.value
                                                                    setCustomeRange((val === "custome") ? true : false)
                                                                    if(val !== "custome" && val !== ""){
                                                                        setStartDate(daysFilter[val]['start'])
                                                                        setEndDate(daysFilter[val]['end'])
                                                                    }else{
                                                                        setStartDate("")
                                                                        setEndDate("")
                                                                    }
                                                                }}>
                                                                    <option value={''}>Select Duration</option>
                                                                    <option value={'seven'}>Seven Days</option>
                                                                    <option value={'month'}>1 Month</option>
                                                                    <option value={'monthsix'}>6 Months</option>
                                                                    <option value={'custome'}>Custome Range</option>
                                                                </select>&nbsp;
                                                                {
                                                                    customeRange &&
                                                                    <>
                                                                        <input type={"date"} className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />&nbsp;
                                                                        <input type={"date"} className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />&nbsp;
                                                                    </>
                                                                }
                                                                <button className='btn btn-primary btn-flat'>
                                                                    <i className='fa fa-filter'></i>
                                                                </button>
                                                            </form>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='box-body' style={{position:"relative"}}>
                                                    <div className='hideChartLogo'></div>
                                                    <div id="dailyRevenue" style={{ width: "100%", height: "500px" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                        {/* <div className='col-md-4'>
                                            <div className='box no-border'>
                                                <div className='box-header'>
                                                    <h3 className='box-title'>
                                                        Active/Inactive Users
                                                    </h3>
                                                </div>
                                                <div className='box-body'>
                                                    <div id="radiusChart" style={{ width: "100%", height: "500px" }}></div>
                                                </div>
                                            </div>
                                        </div> */}
                                    {
                                    pagePermissions(props.pagepermissions, courseindex, "india_chart") &&
                                        <div className='col-md-12'>
                                            <div className='box no-border'>
                                                <div className='box-header'>
                                                    <h3 className='box-title'>
                                                        Daily Clicks (State Wise)
                                                    </h3>
                                                </div>
                                                <div className='box-body' style={{position:"relative"}}>
                                                    <div className='hideChartLogo'></div>
                                                    <div id="mapChart" style={{ width: "100%", height: "500px" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </>
                                }
                                
                                {
                                    !pagePermissions(props.pagepermissions, courseindex, "daily_traffic_chart") && !pagePermissions(props.pagepermissions, courseindex, "daily_revenue_chart") && !pagePermissions(props.pagepermissions, courseindex, "india_chart") &&
                                    <div className='col-md-12'>
                                        <div className='box no-border'>
                                            <div className='box-body' style={{position:"relative"}}>
                                                <div className='no-files'>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/nofiles.png'} />
                                                    <p className='no-files-heading'>
                                                        <strong>No Data Available!</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="nav-tabs-custom">
                            <ul className="nav nav-tabs">

                                {
                                    pagePermissions(props.pagepermissions, courseindex, "basic_information") &&
                                    <li className={tab === "basic_information" ? 'active' : ''} onClick={() => setTab("basic_information")}><a href="#basic_information"  data-toggle="tab" aria-expanded="false">Basic Information</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "price_variations") &&
                                    <li className={tab === "price" ? 'active' : ''} onClick={() => setTab("price")}><a href="#price"  data-toggle="tab" aria-expanded="false">Price & Variations</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "link_promocodes") &&
                                    <li className={tab === "link_promocodes" ? 'active' : ''} onClick={() => setTab("link_promocodes")}><a href="#link_promocodes"  data-toggle="tab" aria-expanded="false">Link Promocodes</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "upsale") &&
                                    <li className={tab === "upsale" ? 'active' : ''} onClick={() => setTab("upsale")}><a href="#upsale"  data-toggle="tab" aria-expanded="false">Upsale</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "seo") &&
                                    <li className={tab === "seo" ? 'active' : ''} onClick={() => setTab("seo")}><a href="#seo"  data-toggle="tab" aria-expanded="false">SEO</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "package_content") && course.coursetype === "Package" &&
                                    <li className={tab === "subcourses" ? 'active' : ''} onClick={() => setTab("subcourses")}><a href="#subcourses"  data-toggle="tab" aria-expanded="false">Package Content</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "course_content") && (course.coursetype === "Course") &&
                                    <li className={tab === "coursecontent" ? 'active' : ''} ><Link to={"/admin/course-content/"+params.id+"/0"} target="_blank" >Course Content</Link></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "mail_notification") &&
                                    <li className={tab === "mail" ? 'active' : ''} onClick={() => setTab("mail")}><a href="#mail"  data-toggle="tab" aria-expanded="false">Content Notifications</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "expire_notification") &&
                                    <li className={tab === "expire_notification" ? 'active' : ''} onClick={() => setTab("expire_notification")}><a href="#expire_notification"  data-toggle="tab" aria-expanded="false">Expiry Notification</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "files_feedback") && (course.coursetype === "Course") &&
                                    <li className={tab === "feedback" ? 'active' : ''} onClick={() => setTab("feedback")}><a href="#feedback"  data-toggle="tab" aria-expanded="false">Files Feedback(s)</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "assignments_tab") && (course.coursetype === "Course") &&
                                    <li className={tab === "assignments_tab" ? 'active' : ''} onClick={() => setTab("assignments_tab")}><a href="#assignments_tab"  data-toggle="tab" aria-expanded="false">Assignment(s)</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "test_attempts_tab") && (course.coursetype === "Course") &&
                                    <li className={tab === "test_attempts_tab" ? 'active' : ''} onClick={() => setTab("test_attempts_tab")}><a href="#test_attempts_tab"  data-toggle="tab" aria-expanded="false">Test Attempt(s)</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "enrolled_tab") &&
                                    <li className={tab === "enrolled_tab" ? 'active' : ''} onClick={() => setTab("enrolled_tab")}><a href="#enrolled_tab"  data-toggle="tab" aria-expanded="false">Enrolled Students</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "course_chats_tab") &&
                                    <li className={tab === "course_chats_tab" ? 'active' : ''} onClick={() => setTab("course_chats_tab")}><a href="#course_chats_tab"  data-toggle="tab" aria-expanded="false">Chat & Doubts</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "banners_tab") &&
                                    <li className={tab === "banners_tab" ? 'active' : ''} onClick={() => setTab("banners_tab")}><a href="#banners_tab"  data-toggle="tab" aria-expanded="false">Result Banners</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "related_products_tab") &&
                                    <li className={tab === "related_products_tab" ? 'active' : ''} onClick={() => setTab("related_products_tab")}><a href="#related_products_tab"  data-toggle="tab" aria-expanded="false">Related Products</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "notifyme_tab") &&
                                    <li className={tab === "notifyme_tab" ? 'active' : ''} onClick={() => setTab("notifyme_tab")}><a href="#notifyme_tab"  data-toggle="tab" aria-expanded="false">Notify Me Requests</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "books_tab") &&
                                    <li className={tab === "books_tab" ? 'active' : ''} onClick={() => setTab("books_tab")}><a href="#books_tab"  data-toggle="tab" aria-expanded="false">Select Books</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "recycle_bin") &&
                                    <li className={tab === "recycle_bin" ? 'active' : ''} onClick={() => setTab("recycle_bin")}><a href="#recycle_bin"  data-toggle="tab" aria-expanded="false">Recycle Bin</a></li>
                                }
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "recycle_bin") &&
                                    <li className={tab === "logs_tab" ? 'active' : ''} onClick={() => setTab("logs_tab")}><a href="#logs_tab"  data-toggle="tab" aria-expanded="false">Change Logs</a></li>
                                }
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="activity">
                                    {
                                        tab === "basic_information" && pagePermissions(props.pagepermissions, courseindex, "basic_information") &&
                                        <Basiccourseinfo course = {course} basics={courseBasics} pointsData={pointsData} />
                                    }
                                    {
                                        tab === "price" && pagePermissions(props.pagepermissions, courseindex, "price_variations") &&
                                        <Price course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "link_promocodes" && pagePermissions(props.pagepermissions, courseindex, "link_promocodes") &&
                                        <Linkpromocodes course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "upsale" && pagePermissions(props.pagepermissions, courseindex, "upsale") &&
                                        <Upsale course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "seo" && pagePermissions(props.pagepermissions, courseindex, "seo") &&
                                        <Seo course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "recycle_bin" && pagePermissions(props.pagepermissions, courseindex, "recycle_bin") &&
                                        <Recyclebin course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "feedback" && pagePermissions(props.pagepermissions, courseindex, "files_feedback") &&
                                        <Filefeedback course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "mail" && pagePermissions(props.pagepermissions, courseindex, "mail_notification") &&
                                        <Mailcontent course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "expire_notification" && pagePermissions(props.pagepermissions, courseindex, "expire_notification") &&
                                        <Expirycourse course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "assignments_tab" && pagePermissions(props.pagepermissions, courseindex, "assignments_tab") &&
                                        <Assignmentslist course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "enrolled_tab" && pagePermissions(props.pagepermissions, courseindex, "enrolled_tab") &&
                                        <Enrolled course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "test_attempts_tab" && pagePermissions(props.pagepermissions, courseindex, "test_attempts_tab") &&
                                        <Attempts course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "books_tab" && pagePermissions(props.pagepermissions, courseindex, "books_tab") &&
                                        <>
                                            <br />
                                            <Bookstab pagepermissions={props.pagepermissions} allcourse={false} course={course} />
                                        </>
                                    }
                                    {
                                        tab === "course_chats_tab" && pagePermissions(props.pagepermissions, courseindex, "course_chats_tab") &&
                                        <>
                                            <br />
                                            <Singlechat pagepermissions={props.pagepermissions} allcourse={false} course={course} />
                                        </>
                                    }
                                    {
                                        tab === "banners_tab" && pagePermissions(props.pagepermissions, courseindex, "banners_tab") &&
                                        <Resultbanners course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "related_products_tab" && pagePermissions(props.pagepermissions, courseindex, "related_products_tab") &&
                                        <Relatedproducts course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "notifyme_tab" && pagePermissions(props.pagepermissions, courseindex, "notifyme_tab") &&
                                        <Notifyme course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "logs_tab" && pagePermissions(props.pagepermissions, courseindex, "logs_tab") &&
                                        <Changelog course = {course} basics={courseBasics} pagepermissions={props.pagepermissions} />
                                    }
                                    {
                                        tab === "subcourses" && pagePermissions(props.pagepermissions, courseindex, "package_content") &&
                                        <>
                                            <br />
                                            {
                                                !positionTab &&
                                                    <div className='col-md-12'>
                                                        <button className='btn btn-primary btn-flat pull-right' style={{marginBottom:"20px"}} onClick={() => {
                                                            setPositionTab(true)
                                                            getPackageContent()
                                                        }} >
                                                            Change Position
                                                        </button>
                                                    </div>
                                            }
                                            {
                                                !positionTab ?
                                                    <Filtercourse type={"package"} course = {course} basics={courseBasics}  pagepermissions={props.pagepermissions} />
                                                :
                                                    <div className='col-md-12'>
                                                        <div className='box no-border'>
                                                            <div className='box-header'>
                                                                <h3 className='box-title'>
                                                                    Change Position
                                                                </h3>
                                                                <span className='pull-right cursor-pointer' onClick={(e) => setPositionTab(false)} >
                                                                    <i className='fa fa-times'></i>
                                                                </span>
                                                            </div>
                                                            {
                                                                positionTabLoading ?
                                                                <div className='col-md-12'>
                                                                    <br /><br /><br />
                                                                    <Loading />
                                                                    <br /><br /><br />
                                                                </div>
                                                                :
                                                                <div className='box-body'>
                                                                {
                                                                    packageContent.length > 0 ?
                                                                    <table className='table table-bordered table-hover'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className='text-center' style={{width:"50px"}}>Drag</th>
                                                                                <th>Course Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody  className='connected-sortable droppable-area1'>
                                                                            {
                                                                                packageContent.map((item, index) => (

                                                                                    <tr key={"postion"+index} className="draggable-item" data-bind={item.id}>
                                                                                        <td className='text-center text-center dragHandle' data-bind={item.id}>
                                                                                            <i className='fa fa-arrows-v'></i>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                                                                                                <img src={item.icon} style={{width:"50px", objectFit:"contain", borderRadius:"5px"}} />
                                                                                                {item.title}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    
                                                                                ))
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    <p className='text-center'>
                                                                        No Course Selected
                                                                    </p>
                                                                }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                            <br /><br />
                                        </>
                                    }
                                    {
                                        (tab === "liveclasses") &&
                                        <>
                                            <br /><br />
                                            <div className='col-md-12'>
                                                <div className='box no-border'>
                                                    <div className='box-header'>
                                                        <h3 className='box-title' style={{width:"100%",textAlign:"center"}}>
                                                            Under Progress
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <br /><br />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </>
            :
                <Notfound />
            }
        </>
    )
  }
  return (
    <>
      <Sidebar page={CoursePage} permission={courseindex} />
    </>
  )
}
