import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../../includes/Common';
import Cookies from 'universal-cookie';
import apiurl from '../../../Global';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

const cookies = new Cookies();

export default function Purchase_History(props) {
  
  const navigate = useNavigate()
  const params = useParams()
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
  
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const [purchaseInfo, setPurchaseInfo] = useState(null)

  const getUserData = () => {
    fetch(apiurl+"api/user/getPurchaseHistory", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+props.dummyToken
        },
        "body": JSON.stringify({
            userid: params.userid,
        })
        })
        .then(response => response.json()) 
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setPurchaseInfo(response.history)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            console.log(err)
        });
  } 
  
  var a = 0  
  useEffect(() => {
    if(a === 0)    {
      getUserData()
      a++
    }
  }, [])

  return (
    <>
    {
      !loading ?
      <>
      <br />
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            My Purchase History
                        </h3>
                    </div>
                    <div className='box-body'>
                        <table id="filmanagertable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Payment ID</th>
                                    <th>Summary</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    purchaseInfo !== null && purchaseInfo.length > 0 &&
                                    purchaseInfo.map((item, index) => {
                                        return(
                                            <tr key={"key"+index}>
                                                <td>{index+1}</td>
                                                <td dangerouslySetInnerHTML={{__html: item.name}}></td>
                                                <td>{item.payment_id}</td>
                                                <td>
                                                    {
                                                        item.summary.paidamnt > 0 &&
                                                        <>
                                                            <strong>Amount Paid:</strong> {item.summary.paidamnt}
                                                        </>
                                                    }
                                                    {
                                                        item.summary.coinused > 0 &&
                                                        <>
                                                            <br />
                                                            <strong>Coin Used: </strong>{item.summary.coinused}
                                                        </>
                                                    }
                                                    {
                                                        (item.summary.paidamnt < 1 && item.summary.coinused < 1) &&
                                                        "Free"
                                                    }
                                                </td>
                                                <td>{item.created_at}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
      </>
      :
      <div className='col-md-12'>
        <br />
        <div className='box no-border'>
          <div className='box-body'>
            <br /><br /><br />
              <Loading />
            <br /><br /><br />
          </div>
        </div>
      </div>
}
    </>
  )
}
