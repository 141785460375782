import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import CommonFunc from '../includes/Common';
import { useParams } from 'react-router-dom';
import apiurl from '../../Global';
import Loading from '../includes/Loading';
import Sidebar from '../includes/Sidebar';
const cookies = new Cookies();

export default function Usage() {
  const Usagepage = (props) => {
    const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const params = useParams()
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
  const [refresh, setRefresh] = useState("No")
  const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState("all")
    const [data, setData] = useState(null)
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
  const getDashboard = () => {
    setLoading(true)
    
    fetch(apiurl + "api/usage", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                start:start,
                end:end
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setData(response.data)
                setLoading(false)
                callcharts(response.data, "all")
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            
        })
  }

  const changeTab = (tab) => {
    setTab(tab)
    callcharts(data, tab)
  }
  const callcharts = (data, tab) => {
    $(".DailyActiveStudents").html('<div class="hideChartLogo"></div> <div id="DailyActiveStudents" style="width:100%;height:500px"></div>')
    $(".all_time_graph").html('<div class="hideChartLogo"></div> <div id="all_time_graph" style="width:100%;height:500px"></div>')
    $(".courses_graph").html('<div class="hideChartLogo"></div> <div id="courses_graph" style="width:100%;height:500px"></div>')
    setTimeout(function(){
        window.ambarchart("DailyActiveStudents", data[tab].dailyactivegraph)
        window.ambarchart("all_time_graph", data[tab].time_graph)
        window.ambarchart("courses_graph", data[tab].courses_graph, "mylable")
    }, 500)
  }

  var a = 0
  useEffect(() => {
    if(a === 0) {
      getDashboard()
      a++
    }
  },[])
    return(
        <>
        <div className='col-md-12 no-padding'>
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className={tab === "all" ? 'active' : ''} onClick={() => changeTab("all")}><a >All</a></li>
                            <li className={tab === "Web" ? 'active' : ''} onClick={() => changeTab("Web")}><a >Web</a></li>
                            <li className={tab === "Android" ? 'active' : ''} onClick={() => changeTab("Android")}><a >Android</a></li>
                            <li className={tab === "IOS" ? 'active' : ''} onClick={() => changeTab("IOS")}><a >IOS</a></li>
                            
                            <li className={"pull-right"} >
                                <button className='btn btn-primary btn-flat' onClick={() => getDashboard()} >
                                    <i className='fa fa-filter'></i>
                                </button>
                            </li>
                            <li className={"pull-right"} >
                                <input type='date' className='form-control' value={end} onChange={(e) => setEnd(e.target.value)} />
                            </li>
                            <li className={"pull-right"} >
                                <label>End:</label>
                            </li>

                            <li className={"pull-right"}  style={{marginRight:"10px"}}>
                                <input type='date' className='form-control' value={start} onChange={(e) => setStart(e.target.value)} />
                            </li>
                            <li className={"pull-right"} >
                                <label>Start:</label>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {
            (loading || data === null) &&
            <div className='col-md-12 no-padding'>
                <div className="col-md-12">
                    <div className='box no-border'>
                        <div className='box-body' style={{padding:"100px 0", position:"relative"}}>
                            <Loading />
                        </div>
                    </div>
                </div>    
            </div>
        }
        {
            
            (!loading && data !== null && data[tab] !== undefined) &&
        <div className='col-md-12 no-padding info-box-flex'>
            {
                (pagePermissions(props.pagepermissions, "Usage_Page", "active_students")) &&
                <div className="col-md-4">
                    <div className="info-box bg-primary">
                        <span className="info-box-icon"><i className="fa fa-users"></i></span>

                        <div className="info-box-content">
                        <span className="info-box-text">Active Students</span>
                        <span className="info-box-number">{data[tab]['active_students']}</span>

                        {/* <div className="progress">
                            <div className="progress-bar" style={{width:"70%"}}></div>
                        </div> */}
                            {/* <span className="progress-description">
                                Today's Active Student
                            </span> */}
                        </div>
                    </div>
                </div>
            }
            {
                (pagePermissions(props.pagepermissions, "Usage_Page", "time_spent")) &&
            <div className="col-md-4">
                <div className="info-box bg-primary">
                    <span className="info-box-icon"><i className="fa fa-clock-o"></i></span>

                    <div className="info-box-content">
                    <span className="info-box-text">Time Spent</span>
                    <span className="info-box-number">{data[tab]['time_spent']}</span>

                    {/* <div className="progress">
                        <div className="progress-bar" style={{width:"70%"}}></div>
                    </div> */}
                        {/* <span className="progress-description">
                            Today's Time Spent
                        </span> */}
                    </div>
                </div>
            </div>
            
        }
        {
            (pagePermissions(props.pagepermissions, "Usage_Page", "most_used_course")) &&
            <div className="col-md-4">
                <div className="info-box bg-primary">
                    <span className="info-box-icon"><i className="fa fa-book"></i></span>

                    <div className="info-box-content">
                    <span className="info-box-text">Most Used Course</span>
                    <span className="info-box-number">{data[tab]['most_used_course_name']}</span>

                    {/* <div className="progress">
                        <div className="progress-bar" style={{width:"70%"}}></div>
                    </div> */}
                        {/* <span className="progress-description">
                            70% Increase in 30 Days
                        </span> */}
                    </div>
                </div>
            </div>
            }
            {
                (pagePermissions(props.pagepermissions, "Usage_Page", "active_students_graph")) &&
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Daily Active Students
                            </h3>
                        </div>
                        <div className='box-body DailyActiveStudents' style={{position:"relative"}}>
                            <div className='hideChartLogo'></div>
                            <div id="DailyActiveStudents" style={{ width: "100%", height: "500px" }}></div>
                        </div>
                    </div>
                </div>
            }
            {
                (pagePermissions(props.pagepermissions, "Usage_Page", "time_spent_graph")) &&
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Daily Time Spent (in minutes)
                        </h3>
                    </div>
                    <div className='box-body all_time_graph' style={{position:"relative"}}>
                        <div className='hideChartLogo'></div>
                        <div id="all_time_graph" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
            }
            {
                (pagePermissions(props.pagepermissions, "Usage_Page", "courses_graph")) &&
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Daily Most Used Course
                        </h3>
                    </div>
                    <div className='box-body courses_graph' style={{position:"relative"}}>
                        <div className='hideChartLogo'></div>
                        <div id="courses_graph" style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </div>
            }
        </div>
        }
        </>
    )
  }
  return (
    <>
      <Sidebar page={Usagepage} permission="Usage_Page"/>
    </>
  )
}
