import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import CommonFunc from '../includes/Common';
import { useParams } from 'react-router-dom';
import apiurl from '../../Global';
import Loading from '../includes/Loading';
import Sidebar from '../includes/Sidebar';
const cookies = new Cookies();

export default function Liveusage() {
  const LiveUsagepage = (props) => {
    const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const params = useParams()
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
  const [refresh, setRefresh] = useState("No")
  const [loading, setLoading] = useState(false)

  const [tab, setTab] = useState('Scheduled')

    const [data, setData] = useState(null)
    const [filetypes, setFiletypes] = useState([])
    
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    
  const changeTab = (tab) => {
    setTab(tab)
    // callcharts(data, tab)
  }
  const getDashboard = () => {
    setLoading(true)
    
    fetch(apiurl + "api/getScheduledGraphs", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                start:start,
                end:end,
                tab:tab
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setData(response.data)
                setFiletypes(response.filetypes)
                setLoading(false)
                callcharts(response.data, response.filetypes)
                // console.log(response.data)
                // callcharts(response.data, "all")
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            
        })
  }
  
  const callcharts = (data, filetypes) => {
    for(var i = 0;i < filetypes.length; i++){
        window.$("."+filetypes[i] + "_graph").html('<div class="hideChartLogo"></div> <div id="'+filetypes[i] + "_graph"+'" style="width:100%;height:500px"></div>')
    }
    
    setTimeout(function(){
        for(var i = 0;i < filetypes.length; i++){
            window.ambarchart(filetypes[i] + "_graph", data[filetypes[i]], "mylable")
        }
    }, 500)
  }

  var a = 0
  useEffect(() => {
    if(a === 0) {
      getDashboard()
      a++
    }
  },[])
    return(
        <>
            <div className='col-md-12 no-padding'>
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className="nav-tabs-custom">
                            <ul className="nav nav-tabs">
                                
                                <li className={tab === "Scheduled" ? 'active' : ''} onClick={() => changeTab("Scheduled")}><a >Scheduled/Live Items</a></li>
                                {/* <li className={tab === "Non Scheduled" ? 'active' : ''} onClick={() => changeTab("Non Scheduled")}><a >Normal Items</a></li> */}

                                <li className={"pull-right"} >
                                    <button className='btn btn-primary btn-flat' onClick={() => getDashboard()} >
                                        <i className='fa fa-filter'></i>
                                    </button>
                                </li>
                                <li className={"pull-right"} >
                                    <input type='date' className='form-control' value={end} onChange={(e) => setEnd(e.target.value)} />
                                </li>
                                <li className={"pull-right"} >
                                    <label>End:</label>
                                </li>

                                <li className={"pull-right"}  style={{marginRight:"10px"}}>
                                    <input type='date' className='form-control' value={start} onChange={(e) => setStart(e.target.value)} />
                                </li>
                                <li className={"pull-right"} >
                                    <label>Start:</label>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading &&
                <div className='col-md-12' >
                    <div className='box no-border'>
                        <div className='box-body' style={{position:"relative"}}>
                            <br /><br /><br /><br /><br /><br /><br />
                            <Loading />
                            <br /><br /><br /><br /><br /><br /><br />
                        </div>
                    </div>
                </div>
            }
            {
                !loading && filetypes.length > 0 &&
                filetypes.map((item, index) => {
                    return(
                        
                        (pagePermissions(props.pagepermissions, "Daily_Live_Content", item+"_Graph")) &&
                        <div className='col-md-12' key={"s"+index}>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        {tab} {(item === "Live" ? "Live Classes" : item+'(s)' )}
                                    </h3>
                                </div>
                                <div className={'box-body ' + item + '_graph'} style={{position:"relative"}}>
                                    <div className='hideChartLogo'></div>
                                    <div id={item + "_graph"} style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                        
                        )
                    })
            }
        </>
    )
  }
  return (
    <>
      <Sidebar page={LiveUsagepage} permission="Daily_Live_Content"/>
    </>
  )
}
