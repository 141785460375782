import React, { useState, useEffect, useCallback, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import FeatherIcon from 'feather-icons-react';
import { TbPinnedOff, TbPinned } from "react-icons/tb";
import { FcClearFilters, FcGoogle } from "react-icons/fc";
import CommonFunc from '../includes/Common';
import Adminprops from '../../VelidateWebsite/Adminprops';
import apiurl from '../../Global';
import Cookies from 'universal-cookie';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Dropzone, {useDropzone} from 'react-dropzone'
import moment from 'moment';
import Loading from '../includes/Loading';
import axios, {CancelToken, isCancel} from 'axios';
import useDrivePicker from 'react-google-drive-picker'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Video from './Video';
import Liveclassimport from './Liveclassimport';
import Assignment from './Assignment';


const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
const cookies = new Cookies();

export default function Assets() {
    
    
    const[queue, setQueue] = useState([])
    const cancelFileUpload = useRef(null)
    const[uploading, setUploading] = useState(false)

    const[selectedFiles, setSelectedFiles] = useState([]) 
    const[selectionType, setSelectionType] = useState([]) 
    const [highlighter, setHighlighter] = useState("")
    
    const query = useQuery();
    var additional_string = ""
    // const importing = ( typeof query.get("importing") !== "undefined" && query.get("importing") !== null) ? "?importing="+query.get("importing") : "";
    var importing = ( typeof query.get("importType") !== "undefined" && query.get("importType") !== null) ? "?importType="+query.get("importType") : "";
    importing += ( typeof query.get("courseid") !== "undefined" && query.get("courseid") !== null) ? "&courseid="+query.get("courseid") : "";
    importing += ( typeof query.get("folderid") !== "undefined" && query.get("folderid") !== null) ? "&folderid="+query.get("folderid") : "";
    importing += ( typeof query.get("aboveBelow") !== "undefined" && query.get("aboveBelow") !== null) ? "&aboveBelow="+query.get("aboveBelow") : "";
    importing += ( typeof query.get("importPosition") !== "undefined" && query.get("importPosition") !== null) ? "&importPosition="+query.get("importPosition") : "";
    importing += ( typeof query.get("quizid") !== "undefined" && query.get("quizid") !== null) ? "&quizid="+query.get("quizid") : "";
    importing += ( typeof query.get("compalsary") !== "undefined" && query.get("compalsary") !== null) ? "&compalsary="+query.get("compalsary") : "";

    const importType = query.get("importType");
    const courseid = query.get("courseid");
    const folderid = query.get("folderid");
    const aboveBelow = query.get("aboveBelow");
    const importPosition = query.get("importPosition");
    const quizid = query.get("quizid");
    const compalsary = query.get("compalsary");
    var b = 1;
    useEffect(() => {
        if(b == 1){
            b++;
        }
    }, [])
    // storageInfo
    
const Assets_Liabrary = (props) => {
    
    

    const params = useParams()
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail} = CommonFunc()
    const [changeFilterTab, setFilterTab] = useState(false)
    const [filter, setFilter] = useState(
        {
            "created_from" : "",
            "created_to" : "",
            "updated_from" : "",
            "updated_to" : "",
            "size_from" : "",
            "size_to" : "",
            "video_encryped" : "",
            "subject" : "",
            "question_type" : [],
            "correct_marks" : "",
            "negetive_marks" : "",
            "answer_type" : "",
            "class_date" : "",
            "class_type" : "",
            "marks_from" : "",
            "marks_to" : "",
            "availability" : "",
            "start_from" : "",
            "start_to" : "",
            "end_from" : "",
            "end_to" : "",
            "name_search" : "",
            "subject" : "",
            "topic" : "",
            "tags" : "",
        }
    )

    const[addNew, setAddnew] = useState("");
    
    // const[aboveBelow, setAboveBelow] = useState("");
    const[aboveBelowPosition, setAboveBelowPosition] = useState("");
    
    const[p_id, setPid] = useState((typeof params.p_id) !== "undefined" ? params.p_id : 0);
    const[main_id, setMainId] = useState((typeof params.id) !== "undefined" ? params.id : 0);
    
    const[recent_list, seRecent_List] = useState([]);
    const navigate = useNavigate()
    const [addLiveClass, setAddLiveClass] = useState(false)


    const [addAssignment, setAddAssignment] = useState(false)
    const [assignmentType, setAssignmentType] = useState("Add")
    const [assignmentId, setAssignmentId] = useState([])

    const changeAddTab = (x, abvblw, pos) => {
        
        if(x !== "Live"){
            setAboveBelowPosition(pos)
            setAddnew(x)
        }

        if(x === "Question"){
            navigate("/admin/importquestions/"+params.id+"/"+params.p_id+importing)
        }else if(x === "Live"){
            setAddLiveClass(true)
            window.$("#LiveClassPop").fadeIn()
        }else if(x === "Assignment"){
            setAssignmentType("Add")
            setAssignmentId([])
            setAddAssignment(true)
        }
    }

    

    const[pathList, setPathList] = useState([])
    const[folderList, setFolderList] = useState([])
    const[filesList, setFilesList] = useState([])
    const[pinnedList, setPinnedList] = useState([])
    const[loading, setLoading] = useState(true)
    const[searchType, setSearchType] = useState("folder")
    
    const[previewLoading, setPreviewLoading] = useState(false)
    const[preview, setPreview] = useState("")


    const [pinnedDataList, setpinneDataList] = useState([])
    const fetchRecent = () => {
        fetch(apiurl+"api/recent_files", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    seRecent_List(response.recent)
                    setpinneDataList(response.pinned)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }


    
    const [segment, setSegment] = useState(0)
    const [addedQuestions, setAddedQuestions] = useState([])
    const [importQueueList, setImportQueueList] = useState([])

    const getChildList = (pid, mainid, searchType) => {
        setLoading(true)
        
        fetch(apiurl+"api/child_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    p_id: pid,
                    main_id: mainid,
                    fileTypes: "Folder,"+importType,
                    searchType:searchType,
                    importing:quizid,
                    from:"Import",
                    page_url: window.location.href,
                    filter_list: JSON.stringify(filter),
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setFilesList(response.files)
                    setPathList(response.path)
                    setPinnedList(response.pinned)
                    setAddedQuestions(response.quizIds)

                    setTimeout(function(){
                        callCheckFunction([])
                        window.$('.importBody').scrollTop(-40);
                    }, 1000)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
                // window.$("#staff_table").DataTable("destroy")
                // setTimeout(function(){
                //     window.$("#staff_table").DataTable({
                //         searching: false, 
                //         paging: false, 
                //         info: false,
                //         order: [],
                //         "columns":[
                //             {
                //                 "sortable": false
                //             },
                //             {
                //                 "sortable": true
                //             },
                //             {
                //                 "sortable": true
                //             },
                //             {
                //                 "sortable": true
                //             },
                //             {
                //                 "sortable": true    
                //             },
                //             {
                //                 "sortable": false
                //             }
                //         ]
                //     })
                // }, 1000)
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const[name, setName] = useState("")
    const[importurl, setURL] = useState("")
    const[file, setFile] = useState("")

    const callCheckFunction = (X) => {
        
        window.$(".mark_checkbox").unbind().on("click", function(){
            var check = X
            if($(this).prop("checked")){
                check.push($(this).val());
            }else{
                const index = check.indexOf($(this).val());
                if (index > -1) { 
                    check.splice(index, 1);
                }
            }
            setImportQueueList(check)
            
            window.$(".importCount").text(check.length)
            // window.$(".mark_checkbox:checked").each(function () {
            //     check.push($(this).val());
            // });
            // window.$(".importCount").text(check.length)
        });
    }


    const[refresh, setRefresh] = useState("No")
    
    const uploadQueue = () => {
        var mylist = queue
        // console.log(mylist)
        for(var j = 0;j<mylist.length;j++){
            // if(!uploading && !mylist[j].status){
            if(!mylist[j].status && mylist[j].uploading == ""){
                
                setUploading(true)
                const formData = new FormData();
                formData.append('p_id', mylist[j].p_id);
                formData.append('name', mylist[j].name);
                formData.append('main_id', 6);
                formData.append('type', mylist[j].type);
                if(mylist[j].type == "Document" || mylist[j].type == "Video" || mylist[j].type == "Image") {
                    formData.append('file', mylist[j].file);
                }
                if(typeof mylist[j].fileURL !== "undefined" && mylist[j].fileURL !== "") {
                    formData.append('fileURL', mylist[j].fileURL);
                    formData.append('fullName', mylist[j].fullName);
                }
                
                
                if(typeof mylist[j].youtubeID !== "undefined" && mylist[j].youtubeID !== "") {
                    formData.append('youtubeID', mylist[j].youtubeID);
                    formData.append('youtubeEmbedUrl', mylist[j].youtubeEmbedUrl);
                    formData.append('youtubeDuration', mylist[j].youtubeDuration);
                }
                

                formData.append('staff', JSON.stringify(AdminBasics()));
                formData.append('accecible', 1);
                formData.append('page_url', window.location.href);

                if(mylist[j].aboveBelow !== "" && typeof mylist[j].aboveBelow !== "undefined"){
                    formData.append('aboveBelow', mylist[j].aboveBelow);
                    formData.append('aboveBelowPosition', mylist[j].aboveBelowPosition);
                }
                if(mylist[j].videoType !== "" && typeof mylist[j].videoType !== "undefined"){
                    formData.append('update', mylist[j].update);
                    formData.append('videoType', mylist[j].videoType);
                }

                formData.append('importing', 1);
                

                axios({
                    method: 'post',
                    url: apiurl+"api/add_folder",
                    data: formData,
                    headers: {
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    
                    onUploadProgress:(data)=>{
                        
                        
                        // var speed = ((data.loaded/100)/(data.timeStamp))
                        // console.log(speed+"Kbps")

                        var perc = Math.round((data.loaded / data.total) *100)
                        mylist[j].uploading = perc
                        if(perc < 100){
                            $("#bars"+j+" .uploadingMessages").text("Uploading...")
                        }else{
                            $("#bars"+j+" .pinnedoff").remove()
                            $("#bars"+j+" .uploadingMessages").text((mylist[j].type == "Video") ? "Encrypting Video..." : "Saving "+mylist[j].type+"...")
                        }

                        perc = (perc > 95) ? 95 : perc
                        var color = "#f46a6a"
                        if(perc > 20 && perc <= 40){
                            color = "#FFA500"
                        }else if(perc > 40 && perc <= 60){
                            color="#F3BD61"
                        }else if(perc > 60 && perc <= 80){
                            color="#f1b44c"
                        }else if(perc > 80 && perc <= 95){
                            color="#3DC594"
                        }
                        mylist[j].color=color
                        mylist[j].msg="Uploading..."
                        setQueue(mylist)

                        $("#bars"+j+" .insideBar").css({width:perc+"%",backgroundColor:color})
                        $("#bars"+j+" .percentage_span").html(perc+"%&nbsp;")
                    },
                    cancelToken: new CancelToken( cancel => cancelFileUpload.current = cancel)
                })
                .then(function (response) {
                    //handle success
                    console.log(response)
                    $("#bars"+j+" .insideBar").css({width:"100%",backgroundColor:"#34c38f"})
                    $("#bars"+j+" .percentage_span").html("100%&nbsp;")
                    $("#bars"+j+" .uploadingMessages").text("Saved")
                    
                    mylist[j].status = true
                    mylist[j].color="#34c38f"
                    mylist[j].msg="Saved"
                    mylist[j].uploading = 100
                    setQueue(mylist)
                    setUploading(false)
                    uploadQueue()
                    getChildList(p_id, main_id, searchType)
                    setRefresh(refresh == "No" ? "Yes" : "No")

                    if(typeof response.data.importid !== "undefined" && response.data.importid !== "" && response.data.importid !== null){
                        var check = []
                        check.push(response.data.importid)
                        importUploadedQueue(check)
                    }
                })
                .catch(function (response) {
                    
                    console.log(response, "Error")
                    $("#bars"+j+" .insideBar").css({backgroundColor:"#f46a6a"})
                    let msg = ""
                    if(typeof response.message !== "undefined"){
                        $("#bars"+j+" .uploadingMessages").text(response.message)
                        msg = response.message
                    }
                    
                    if(typeof response.response !== "undefined" && typeof response.response.data.message){
                        $("#bars"+j+" .uploadingMessages").text(response.response.data.message)
                        msg = response.response.data.message
                    }
                    

                    mylist[j].status = "Failed"
                    mylist[j].color="#f46a6a"
                    mylist[j].msg=msg
                    setQueue(mylist)
                    setUploading(false)
                    uploadQueue()
                    getChildList(p_id, main_id, searchType)
                    setRefresh(refresh == "No" ? "Yes" : "No")
                });

                break;
            }
        }
    }
    
    const cancelRequest = (index) => {
        var mylist = queue
        if(cancelFileUpload.current){
            cancelFileUpload.current("Operation Cancelled.")
            mylist[index].retry=1
            setQueue(mylist)
            setUploading(false)
            uploadQueue()
            setRefresh(refresh == "No" ? "Yes" : "No")
        }
    }

    const retryUpload = (index) => {
        var mylist = queue
        mylist[index].retry = ""
        mylist[index].status = false
        mylist[index].uploading = 0
        mylist[index].msg = ""
        mylist[index].color = ""
        setQueue(mylist)
        setRefresh(refresh == "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }

    const clearAll = () => {
        setQueue([])
    }

    const addNewFolder = (e, type) => {
        e.preventDefault()

        var data = {
            p_id: p_id,
            main_id: main_id,
            name: name,
            type: type,
            file: (type == "Document" || type == "Video" || type == "Image") ? file : "",
            status: false,
            uploading: 0,
            msg:"",
            color:"",
            retry:"",
            aboveBelow: aboveBelow,
            aboveBelowPosition: aboveBelowPosition
        }
        var oldQueue = queue
        oldQueue.push(data)
        setQueue(oldQueue)
        setRefresh(refresh == "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }
    
    const changeFolder = (mainid, pid) => {
        
        setPid(pid)
        setMainId(mainid)
        setIsTrash(false)
        // console.log("/admin/import/"+mainid+"/"+pid+additional_string)
        navigate("/admin/import/"+mainid+"/"+pid+additional_string)
        getChildList(pid, mainid, searchType)
        
    }
    
    const backToRoot = () => {
        setPid(0)
        setMainId(0)
        setAddnew("")
        navigate("/admin/assets")
        fetchRecent()
    }
    
    const stillUploading = () => {
        if(queue.length > 0){
            for(var j = 0;j<queue.length;j++){
                if(!uploading && !queue[j].status){
                    setUploading(true)
                }
            }
        }
    }
    const bulkUpload = (files, type) => {
        
        var oldQueue = queue
        var data = {}
        var name = ""
        var formats = []
        if(type === "Document"){
            formats = ["pdf"]
        }else if(type === "Image"){
            formats = ["png","jpg","jpeg","gif","svg"]
        }else if(type === "Video"){
            formats = ["mp4"]
        }
        

        for(var i=0;i<files.length;i++){
            name = files[i].name.split(".")
                data = {
                    p_id: p_id,
                    main_id: main_id,
                    name: name[0],
                    type: type,
                    file: files[i],
                    status: (formats.includes(name[1])) ? false : "Failed",
                    uploading: (formats.includes(name[1])) ? 0 : 100,
                    msg:(formats.includes(name[1])) ? "" : "Invalid "+type+" type (."+name[1]+").",
                    color:(formats.includes(name[1])) ? "" : "#f46a6a",
                    retry:"",
                    aboveBelow: aboveBelow,
                    aboveBelowPosition: aboveBelowPosition
                }
            
            oldQueue.push(data)
        }
        setQueue(oldQueue)
        setRefresh(refresh == "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }

    const [qeustionPreview, setQuestionPreview] = useState(null)

    const fileInfo = (item) => {
        var id = item.id
        var type = item.type
        if(type !== "Question"){
            setPreviewLoading(true)
            fetch(apiurl+"api/file_information", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        accecible: 1,
                        id: id,
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics())
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){

                        var resp = response.info;
                        if(resp.type == "Folder"){
                            resp.color = "#FCD462"
                        }else if(resp.type == "Question"){
                            resp.color = "#5691F3"
                        }else if(resp.type == "Video"){
                            resp.color = "#f46a6a"                       

                        }else if(resp.type == "Live"){
                            resp.color = "#f1b44c"
                        }else if(resp.type == "Document"){
                            resp.color = "#f46a6a"
                        }else if(resp.type == "Image"){
                            resp.color = "#50a5f1"
                        }else if(resp.type == "Youtube"){
                            resp.color = "#f46a6a"
                        }else if(resp.type == "Unknown"){
                            resp.color = "#74788d"
                        }
                        resp.tab = "English"
                        resp.id=id
                        setPreview(resp)
                        

                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setPreviewLoading(false)
                })
                .catch(err => {
                    setPreviewLoading(false)
                });
            }else{
                setQuestionPreview(item)
                // navigate("/admin/questions/"+params.id+"/"+params.p_id+"?qid="+id)
            }
    }

    const copyUrl = (url, name) => {
        navigator.clipboard.writeText(url)
        successModal("Success", "URL Copied Of "+name)
        updatTrail("Copied the url of <a href='"+url+"'>"+name+"</a>")
    }

    
    const importURLNewFolder = (e, type) => {
        e.preventDefault();
        
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = importurl.match(regExp);

        var oldQueue = queue
        var data = {}
        var name = ""
        var formats = []
        if(type === "Document"){
            formats = ["pdf"]
        }else if(type === "Image"){
            formats = ["png","jpg","jpeg","gif","svg"]
        }else if(type === "Video"){
            formats = ["mp4"]
        }


        if(type === "Video" && (match && match[2].length == 11)){
            window.$.get("https://www.googleapis.com/youtube/v3/videos?id="+match[2]+"&key=AIzaSyDyDqGHrbs1RHZEcy5EJ3vVoBRC5LT-MDo&part=snippet,contentDetails,statistics,status", {}, function(response){
                if(response.pageInfo.totalResults > 0){
                    data = {
                        p_id: p_id,
                        main_id: main_id,
                        name: response.items[0].snippet.title,
                        type: "Video",
                        file: "",
                        status: false,
                        uploading: 0,
                        msg: "Waiting...",
                        color: "#f46a6a",
                        retry:"",
                        aboveBelow: aboveBelow,
                        aboveBelowPosition: aboveBelowPosition,
                        youtubeID: match[2],
                        youtubeEmbedUrl:"https://www.youtube.com/embed/"+match[2],
                        youtubeDuration: response.items[0].contentDetails.duration
                    }
                    oldQueue.push(data)
                    setQueue(oldQueue)
                    setRefresh(refresh == "No" ? "Yes" : "No")
                    if(!uploading){
                        uploadQueue()
                    }
                }
            })
        }else{
            var filename = importurl.split('/').pop().split('#')[0].split('?')[0];
            var namesplit = filename.split(".")
            
            var status = "Failed"
            var uploading = 100
            var msg = (typeof namesplit[1] !== "undefined") ? "Invalid "+type+" Type ("+namesplit[1]+")." : "Invalid "+type+" type (UNKNOWN)."
            var color = "#f46a6a"
            if(typeof namesplit[1] !== "undefined" && formats.includes(namesplit[1])){
                status = false
                uploading = 0
                msg = ""
                color = ""
            }

            data = {
                p_id: p_id,
                main_id: main_id,
                name: namesplit[0],
                type: type,
                file: "",
                status: status,
                uploading: uploading,
                msg: msg,
                color: color,
                retry:"",
                aboveBelow: aboveBelow,
                aboveBelowPosition: aboveBelowPosition,
                fileURL: importurl,
                fullName: filename
            }
            oldQueue.push(data)
            setQueue(oldQueue)
            setRefresh(refresh == "No" ? "Yes" : "No")
            if(!uploading){
                uploadQueue()
            }
        }


    }

    const [openPicker, authResponse] = useDrivePicker();  
    const handleOpenPicker = (type) => {

        var formats = "application/pdf"
        if(type === "Document"){
            formats = "application/pdf"
        }else if(type === "Image"){
            formats = "image/gif,image/svg+xml,image/png,image/jpg,image/jpeg"
        }else if(type === "Video"){
            formats = "video/mp4"
        }

        openPicker({
          clientId: "427743329872-520a3qku8q76od41cncsdngjgl7qtfbp.apps.googleusercontent.com",
        //   developerKey: "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",
          developerKey: "AIzaSyDyDqGHrbs1RHZEcy5EJ3vVoBRC5LT-MDo",
          viewId: "DOCS",
          // token: token, // pass oauth token in case you already have one
          showUploadView: true,
          showUploadFolders: true,
          supportDrives: true,
          multiselect: true,
          viewMimeTypes: formats,
          callbackFunction: (data) => {
            if (data.action === 'cancel') {
              console.log('User clicked cancel/close button')
            }
            if(data.action === "picked"){
                if(data.docs.length > 0){
                    var list = data.docs;
                    
                    // https://drive.google.com/uc?export=view&id=

                    var oldQueue = queue
                    var data = {}
                    var name = ""
                    var formats = []
                    if(type === "Document"){
                        formats = ["pdf"]
                    }else if(type === "Image"){
                        formats = ["png","jpg","jpeg","gif","svg"]
                    }else if(type === "Video"){
                        formats = ["mp4"]
                    }

                    for(var i=0;i<list.length;i++){
                        name = list[i].name.split(".")
                            data = {
                                p_id: p_id,
                                main_id: main_id,
                                name: name[0],
                                type: type,
                                file: "",
                                status: (formats.includes(name[1])) ? false : "Failed",
                                uploading: (formats.includes(name[1])) ? 0 : 100,
                                msg:(formats.includes(name[1])) ? "" : "Invalid "+type+" type (."+name[1]+").",
                                color:(formats.includes(name[1])) ? "" : "#f46a6a",
                                retry:"",
                                aboveBelow: aboveBelow,
                                aboveBelowPosition: aboveBelowPosition,
                                fileURL: "https://drive.google.com/uc?export=view&id="+list[i].id,
                                fullName: list[i].name
                            }
                        
                        oldQueue.push(data)
                    }
                    setQueue(oldQueue)
                    setRefresh(refresh == "No" ? "Yes" : "No")
                    if(!uploading){
                        uploadQueue()
                    }
                }
            }
          },
        })
    }

    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }

    const [storageInfoList, setStorageInfo] = useState("")

    const storageInfo = () => {
        fetch(apiurl+"api/storageInfo", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setStorageInfo(response.storage)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const copyFiles = (x) => {
        var check = []
        window.$(".mark_checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length > 0){
            setSelectedFiles(check)
            setSelectionType(x)
        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }
    
    const copySingle = (x, type) => {
        var check = []
        window.$(".mark_checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length === 0){
            window.$("#id"+x).find("input").prop("checked", true)
        }
        copyFiles(type)
    }

    
    const removeFiles = (x, type) => {
        var check = []
        window.$(".mark_checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length === 0){
            window.$("#id"+x).find("input").prop("checked", true)
        }
        removeDialog()
    }

    const removeFilesList = (check) => {
        

        fetch(apiurl+"api/remove_files", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    source_id: check,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    getChildList(p_id, main_id, searchType)
                    successModal("Sucess", response.message)
                    setRefresh(refresh == "No" ? "Yes" : "No")

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });

    }

    const removeDialog = () => {
        var check = []
        window.$(".mark_checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length > 0){
            
            confirmAlert({
                title: 'Are You Sure?',
                message: "You want to remove this file?",
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => removeFilesList(check)
                  },
                  {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                  }
                ]
            });

        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }


    const pasteFiles = () => {
        var pasteUrl = (selectionType === "Copy") ? "paste_files" : "move_files"
        fetch(apiurl+"api/"+pasteUrl, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    source_id: selectedFiles,
                    destination_id: p_id,
                    main_id: main_id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    var oldQueue = queue
                    var data = {}
                    for(var i = 0;i<response.response.length;i++){
                        data = {
                            p_id: 0,
                            main_id: 0,
                            name: response.response[i].name,
                            type: response.response[i].type,
                            file: "",
                            status: (response.response[i].status === "OK") ? true : "Failed",
                            uploading: 100,
                            msg: (response.response[i].status === "OK") ? response.response[i].type + ' Saved' : response.response[i].message,
                            color: (response.response[i].status === "OK") ? "#34c38f" : "#f46a6a",
                            retry: "",
                            aboveBelow: "",
                            aboveBelowPosition: "",
                            fileURL: "",
                            fullName: ""
                        }
                        oldQueue.push(data)
                    }
                    
                    setQueue(oldQueue)
                    
                    setRefresh(refresh == "No" ? "Yes" : "No")
                    setSelectedFiles([])
                    setSelectionType([])
                    getChildList(p_id, main_id, searchType)
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    
    const[is_trash, setIsTrash] = useState(false)
    const [trashbinList, settrashBinList] = useState([])

    const showTrashbin = () => {
        
        fetch(apiurl+"api/trashbbin_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    settrashBinList(response.list)
                    setIsTrash(true)
                    storageInfo()
                    setRefresh(refresh == "No" ? "Yes" : "No")
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const restoreFile = (x) => {
        fetch(apiurl+"api/restoreFiles", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    id: x,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    showTrashbin()
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
        
        var check = []
        window.$(".mark_checkbox:checked").each(function () {
            check.push($(this).val());
        });
        setImportQueueList(check)
        callCheckFunction(check)
        window.$(".importCount").text(check.length)
    
    }

    const clearTrashBin = () => {
        fetch(apiurl+"api/clear_all_trash", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    showTrashbin()
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const searchBar = (x) => {
        var a = x.toLowerCase();
        window.$(".fileTitleList").each(function(){
            var b = $(this).text().toLowerCase()
            b += $(this).attr("data-bind").toLowerCase()
            // console.log(b)
            if(b.indexOf(a) != -1){
                $(this).parents("tr").show()
            }else{
                $(this).parents("tr").hide()
            }
        });
    }

    const [renameId, setRenameId] = useState("")
    const renameModal = (id, name) => {
        setName(name)
        setRenameId(id)
        window.$(".renameDialogBG").fadeIn()
    }

    const renameFile = () => {
        const newName = name
        const renamid = renameId
        if(name === ""){
            warningModal("Name Cannot ne null", "Please enter name of the item")
        }else{
            fetch(apiurl+"api/rename_file", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics()),
                        accecible: 1,
                        name: newName,
                        renameId: renamid
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setName("")
                        setRenameId("")
                        window.$(".renameDialogBG").fadeOut()
                        getChildList(p_id, main_id, searchType)
                        successModal("Success", response.message)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                })
                .catch(err => {
                    // console.log(err);
                });
            }
    }


    const markPin = (id) => {

        fetch(apiurl+"api/markPin", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    id: id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    if(p_id !== "" && main_id !== "" && p_id !== 0 && main_id !== 0){
                        getChildList(p_id, main_id, searchType)
                    }else{
                        fetchRecent()
                    }
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
        
    }


    const goToFolderWithHighlight = (main_id, p_id, id) => {
        setHighlighter(id)
        changeFolder(main_id, p_id)
    }


    const updateVideo = (e, id, videoType) => {
        var vid = e.target.files[0]
        var type = "Video"

        
        var oldQueue = queue
        var data = {}
        var name = ""
        var formats = []
        formats = ["mp4"]
        

        name = vid.name.split(".")
        data = {
            update:id,
            p_id: p_id,
            main_id: main_id,
            name: name[0]+" ("+videoType+" Video)",
            type: type,
            file: vid,
            videoType: videoType,
            status: (formats.includes(name[1])) ? false : "Failed",
            uploading: (formats.includes(name[1])) ? 0 : 100,
            msg:(formats.includes(name[1])) ? "" : "Invalid "+type+" type (."+name[1]+").",
            color:(formats.includes(name[1])) ? "" : "#f46a6a",
            retry:""
        }
    
        oldQueue.push(data)
        setQueue(oldQueue)
        setRefresh(refresh == "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }

    }
    


    const changePreviewTab = (tab) => {
        var prev = preview
        prev.tab = tab
        setPreview(prev)
        setRefresh(refresh == "No" ? "Yes" : "No")
    }

    const changeSearchType = (searchtype) => {
        setSearchType(searchtype)
        getChildList(p_id, main_id, searchtype)
    }

    const setFilterValues = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh == "No" ? "Yes" : "No")
        // getChildList(p_id, main_id, searchType)
    }

    const clearFilters = (cols) => {
        var fcols = cols.split(",")
        var temp = filter
        for(var i = 0;i<fcols.length;i++){
            if(fcols[i] !== "question_type"){
                temp[fcols[i]] = ""
            }else{
                temp[fcols[i]] = []
            }
        }
        setFilter(temp)
        setRefresh(refresh == "No" ? "Yes" : "No")
        getChildList(p_id, main_id, searchType)
    }

    const filterToggle = () => {
        setFilterTab(changeFilterTab ? false : true)
        window.$("#openFilter").toggleClass("btn-success")
        window.$("#openFilter").toggleClass("btn-primary")
    }

    const [importLoading, setImportLoading] = useState(false)
    const importUploadedQueue = (check) => {
        
        
        var url = (importType === "Question") ? "importQuestions" : "importFiles"
        var compalsarytype = (importType === "Document") ? 'document' : 'video'
        if(compalsary === "1"){
            url = "importCompalsary"
        }
        setImportLoading(true)

        fetch(apiurl+"api/"+url , {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    source_id: check,
                    aboveBelowPosition: importPosition,
                    aboveBelow: aboveBelow,
                    folderid: folderid,
                    courseid: courseid,
                    quizid: quizid,
                    compalsarytype: compalsarytype
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    window.$(".mark_checkbox:checked").each(function () {
                        $(this).prop("checked", false)
                    });
                    
                    getChildList(p_id, main_id, searchType)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setImportLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }
    
    const importFiles = () => {
        var check = importQueueList
        // var check = []
        // window.$(".mark_checkbox:checked").each(function () {
        //     check.push($(this).val());
        // });
        if(check.length > 0){
            importUploadedQueue(check)
        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }
    const selectQuestionType = (type) => {
        var temp = filter
        var typeList = temp['question_type']
        var index = typeList.indexOf(type);
        if (index !== -1) {
            typeList.splice(index, 1);
        }else{
            typeList.push(type)
        }
        temp['question_type'] = (typeList.length > 0) ? typeList : []
        setFilter(temp)
        getChildList(p_id, main_id, searchType)
        
    }

    var a = 0
    useEffect(() => {
        window.$("head").append("<style> html{ background:#f1f1f1; } </style>")
        
        additional_string = ""
        additional_string += importing
        if(p_id == 0){
            fetchRecent()
        }
        if(a == 0){
            stillUploading()
            // storageInfo()
            if(p_id != 0){
                getChildList(p_id, main_id, searchType)
            }
            a++
        }
    }, [])
    
    return(
        <>
        
        <ul className='error_success' id='error_success'>
      
        </ul>
        {
            !importLoading ?
                <button className='btn btn-primary btn-flat importBtn' onClick={() => importFiles(importQueueList)}>
                    Import Now (<span className="importCount">0</span>)
                </button>
            :
                <div style={{ position: "fixed", bottom: "10px", left: "calc(50% - 75px)", width: "150px", zIndex: 9, height:"90px" }} >
                    <Loading />
                </div>
        }

        <div className='importBody'>


        {
            
            <div className='assets-lib'>
                <div className='col-md-3 assetsbars'>
            {
                changeFilterTab && searchType === "all" &&
                    <div className='box no-border filtersArea'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                <strong> Filters </strong>
                            </h3>
                            <span className='pull-right cursor-pointer' title='Close' onClick={() => filterToggle() } >
                                <i className='fa fa-times'></i>
                            </span>
                        </div>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Item Created 
                            </h3>
                            {
                                (filter['created_from'] !== "" || filter['created_to'] !== "") && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("created_from,created_to")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                        </div>
                        <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                            <ul className='filtersBody'>
                                <li>
                                    <div>
                                        <label>
                                            From 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.created_from} onChange={(e) => setFilterValues("created_from", e.target.value) } />
                                </li>
                                <li>
                                    <div>
                                        <label>
                                            To 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.created_to} onChange={(e) => setFilterValues("created_to", e.target.value) }  />
                                </li>
                            </ul>
                        </div>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Item Modified 
                            </h3>
                            {
                                (filter['updated_from'] !== "" || filter['updated_to'] !== "") && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("updated_from,updated_to")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                        </div>
                        <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                            <ul className='filtersBody'>
                                <li>
                                    <div>
                                        <label>
                                            From 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.updated_from} onChange={(e) => setFilterValues("updated_from", e.target.value) }  />
                                </li>
                                <li>
                                    <div>
                                        <label>
                                            To 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.updated_to} onChange={(e) => setFilterValues("updated_to", e.target.value) }  />
                                </li>
                            </ul>
                        </div>


                            {
                                importType === "Assignment" &&
                                <>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Marks Between
                                        </h3>
                                        {
                                            (filter['marks_from'] !== "" || filter['marks_to'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("marks_from,marks_to")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li>
                                                <div>
                                                    <label>
                                                        From 
                                                    </label>
                                                </div>
                                                <input type={"number"} className="form-control" value={filter.marks_from} onChange={(e) => setFilterValues("marks_from", e.target.value) }  placeholder={"e.g 1"} />
                                            </li>
                                            <li>
                                                <div>
                                                    <label>
                                                        To 
                                                    </label>
                                                </div>
                                                <input type={"number"} className="form-control" value={filter.marks_to} onChange={(e) => setFilterValues("marks_to", e.target.value) }  placeholder={"e.g 50"} />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Availability
                                        </h3>
                                        {
                                            (filter['availability'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("availability")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li onClick={(e) => setFilterValues("availability", '1') } >
                                                <div>
                                                    <label>
                                                        Lifetime
                                                    </label>
                                                </div>
                                                {
                                                    filter.availability === "1" || filter.availability === 1 ?
                                                    <FeatherIcon icon={"check-circle"} />
                                                    :
                                                    <FeatherIcon icon={"circle"} />
                                                }
                                            </li>
                                            <li onClick={(e) => setFilterValues("availability", 'Time') }>
                                                <div>
                                                    <label>
                                                    Time Based
                                                    </label>
                                                </div>
                                                {
                                                    filter.availability === "Time" ?
                                                    <FeatherIcon icon={"check-circle"} />
                                                    :
                                                    <FeatherIcon icon={"circle"} />
                                                }
                                            </li>
                                        </ul>
                                    </div>



                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Start From
                                        </h3>
                                        {
                                            (filter['start_from'] !== "" || filter['start_to'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("start_from,start_to")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li>
                                                <div>
                                                    <label>
                                                        From 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.start_from} onChange={(e) => setFilterValues("start_from", e.target.value) }  />
                                            </li>
                                            <li>
                                                <div>
                                                    <label>
                                                        To 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.start_to} onChange={(e) => setFilterValues("start_to", e.target.value) }  />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            End From
                                        </h3>
                                        {
                                            (filter['end_from'] !== "" || filter['end_to'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("end_from,end_to")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li>
                                                <div>
                                                    <label>
                                                        From 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.end_from} onChange={(e) => setFilterValues("end_from", e.target.value) }  />
                                            </li>
                                            <li>
                                                <div>
                                                    <label>
                                                        To 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.end_to} onChange={(e) => setFilterValues("end_to", e.target.value) }  />
                                            </li>
                                        </ul>
                                    </div>

                                </>
                            }


                        {
                            importType === "Live" &&
                            <>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Class Date
                                    </h3>
                                    {
                                        (filter['class_date'] !== "" ) && 
                                        <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("class_date")} >
                                            <strong>
                                                Clear
                                            </strong>
                                        </span>
                                    }
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                            <div>
                                                <label>
                                                    Select Class Date 
                                                </label>
                                            </div>
                                            <input type={"date"} className="form-control" value={filter.class_date} onChange={(e) => setFilterValues("class_date", e.target.value) }  />
                                        </li>
                                    </ul>
                                </div>

                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Class Type
                                    </h3>
                                    {
                                        (filter['class_type'] !== "" ) && 
                                        <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("class_type")} >
                                            <strong>
                                                Clear
                                            </strong>
                                        </span>
                                    }
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li onClick={(e) => setFilterValues("class_type", 'Over') } >
                                            <div>
                                                <label>
                                                    Over
                                                </label>
                                            </div>
                                            {
                                                filter.class_type === "Over" ?
                                                <FeatherIcon icon={"check-circle"} />
                                                :
                                                <FeatherIcon icon={"circle"} />
                                            }
                                        </li>
                                        <li onClick={(e) => setFilterValues("class_type", 'Upcomming') }>
                                            <div>
                                                <label>
                                                Upcomming
                                                </label>
                                            </div>
                                            {
                                                filter.class_type === "Upcomming" ?
                                                <FeatherIcon icon={"check-circle"} />
                                                :
                                                <FeatherIcon icon={"circle"} />
                                            }
                                        </li>
                                    </ul>
                                </div>

                            </>
                        }
                        {
                            importType !== "Question" && importType !== "Live" && importType !== "Assignment" &&
                            <>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Item Size 
                                </h3>
                                {
                                    (filter['size_from'] !== "" ) && 
                                    <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("size_from")} >
                                        <strong>
                                            Clear
                                        </strong>
                                    </span>
                                }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <div>
                                            <label>
                                                From (MB)
                                            </label>
                                        </div>
                                        <input type={"number"} className="form-control" step={0.0001} placeholder={"e.g 0.1"}  value={filter.size_from} onChange={(e) => setFilterValues("size_from", e.target.value) }   />
                                    </li>
                                    <li>
                                        <div>
                                            <label>
                                                To (MB)
                                            </label>
                                        </div>
                                        <input type={"number"} className="form-control" step={0.0001} placeholder={"e.g 10"}  value={filter.size_to} onChange={(e) => setFilterValues("size_to", e.target.value) }  />
                                    </li>
                                </ul>
                            </div>
                            </>

                    }

                    {
                        importType === "Video,Youtube" &&
                        <>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Video Encrypted
                                </h3>
                            {
                                (filter['video_encryped'] !== "" ) && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("video_encryped")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <select className='form-control'  onChange={(e) => setFilterValues("video_encryped", e.target.value) } defaultValue={filter.video_encryped} >
                                            <option value={""}>All</option>
                                            <option value={"Yes"}>Yes</option>
                                            <option value={"No"}>No</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </>
                    }

                        {
                            importType === "Question" &&
                            <>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Search By Subject
                                    </h3>
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                            <input type={"text"} style={{width:"100%"}} value={filter.subject} className="form-control" placeholder='Enter Your Keyword...' onChange={(e) => setFilterValues("subject", e.target.value)} />
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }

                        {
                            importType === "Question" &&
                            <>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Search By Topic
                                    </h3>
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                        <input type={"text"} value={filter.topic} style={{width:"100%"}} className="form-control" placeholder='Enter Your Keyword...' onChange={(e) => setFilterValues("topic", e.target.value)} />
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }

                            <>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Search By Tags
                                    </h3>
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                            <input type={"text"} value={filter.tags} style={{width:"100%"}} className="form-control" placeholder='Enter Your Keyword...' onChange={(e) => setFilterValues("tags", e.target.value)} />
                                        </li>
                                    </ul>
                                </div>
                            </>
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block' onClick={() => {
                                        getChildList(p_id, main_id, searchType)
                                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                                    }} >
                                        Filter
                                    </button>
                                </div>
                        
                    </div>
                }

                {
                    !changeFilterTab &&
                    <div className='box no-border'>
                        <div className='box-header'>
                            <div className="btn-group btn-block">
                                
                                <div className='createbtnsCon'>
                                    {
                                        p_id != 0 &&
                                        <>
                                        {
                                            addNew !== "" &&
                                            <button type="button" className="btn iconbtns backbtn" title='Back' onClick={() => changeAddTab("", "", "")}>
                                                <FeatherIcon icon={"arrow-left"} />
                                            </button>
                                        }
                                        {
                                            // pagePermissions(props.pagepermissions, "Assets", "show_create_files_button") &&
                                            <button type="button" className="btn btn-block iconbtns dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                <FeatherIcon icon="plus"/> Create New
                                            </button>
                                        }
                                        <ul className="dropdown-menu new-dropdown">

                                            {
                                                // pagePermissions(props.pagepermissions, "Assets", "add_folders") &&
                                                // <li onClick={() => changeAddTab("Folder", "", "")} title="Add New Folder">
                                                //     <FeatherIcon icon={"folder-plus"} />
                                                //     New Folder
                                                // </li>
                                            }
                                            {
                                                // main_id != 3 &&
                                                <>
                                                {
                                                    // pagePermissions(props.pagepermissions, "Assets", "add_document") &&
                                                    importType === "Document" &&
                                                    <li onClick={() => changeAddTab("Document", "", "")} title="Upload Document">
                                                        <FeatherIcon icon={"file-text"} />
                                                        Upload Document
                                                    </li>
                                                }
                                                {
                                                    // pagePermissions(props.pagepermissions, "Assets", "add_video") &&
                                                    importType === "Video,Youtube" &&
                                                    <li onClick={() => changeAddTab("Video", "", "")} title="Upload Video">
                                                        <FeatherIcon icon={"video"} />
                                                        Upload Video
                                                    </li>
                                                }
                                                {
                                                    // pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                    importType === "Image" &&
                                                    <li onClick={() => changeAddTab("Image", "", "")} title="Upload Image">
                                                        <FeatherIcon icon={"image"} />
                                                        Upload Image
                                                    </li>
                                                }
                                                {
                                                    // pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                    importType === "Question" &&
                                                    <li onClick={() => changeAddTab("Question", "", "")} title="Upload Question">
                                                        <FeatherIcon icon={"info"} />
                                                        Upload Question
                                                    </li>
                                                }
                                                {
                                                     importType === "Live" &&
                                                    <li title='Add Live Class' onClick={() => changeAddTab("Live", "", "")}>
                                                        <FeatherIcon icon={"cast"} />
                                                        Add Live Class
                                                    </li>
                                                }
                                                {
                                                     importType === "Assignment" &&
                                                    <li title='Add Assignments' onClick={() => changeAddTab("Assignment", "", "")}>
                                                        <FeatherIcon icon={"file-text"} />
                                                        Add Assignments
                                                    </li>
                                                }
                                                </>
                                            }
                                            {
                                                // main_id == 3 && 
                                                // pagePermissions(props.pagepermissions, "Assets", "add_question") &&
                                                // <li onClick={() => changeAddTab("Question", "", "")}>
                                                //     <FeatherIcon icon={"help-circle"} />
                                                //     Add Question
                                                // </li>
                                            }
                                            
                                        </ul>
                                        </>
                                    }
                                    
                                </div>
                                
                                {
                                    p_id == 0 &&
                                    <h3 className='box-title'>
                                        Root
                                    </h3>
                                }
                            </div>
                        </div>
                        <div className='box-body'>
                            {
                                addNew == "" &&
                            <ul className='assets_list'>
                                
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_file_manager") &&
                                    
                                        <li onClick={() => changeFolder(2, 2)} className={(main_id == 2 && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/folder.png'} />
                                                File Manager 
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_questions") &&
                                    
                                        <li onClick={() => changeFolder(3, 3)} className={(main_id == 3 && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/information.png'} />
                                                Question Bank
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_promotional") &&
                                    
                                    
                                        // <li onClick={() => changeFolder(4, 4)} className={(main_id == 4 && !is_trash) ? 'active-assets-menu' : ""}>
                                        //     <span>
                                        //         <img src={process.env.PUBLIC_URL+'/assets/assets/images/tag.png'} />
                                        //         Promotionals Items
                                        //     </span>
                                        //     <span className='arrow-r'>
                                        //         <FeatherIcon icon={"arrow-right"} />
                                        //     </span>
                                        // </li>
                                        
                                }
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_live_classes") &&
                                    
                                    
                                        <li onClick={() => changeFolder(5, 5)} className={(main_id == 5 && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/live.png'} />
                                                Live Classes
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_live_classes") &&
                                    
                                    
                                        <li onClick={() => changeFolder(7, 7)} className={(main_id == 5 && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/live.png'} />
                                                Recordings
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_live_classes") &&
                                    
                                    
                                        <li onClick={() => changeFolder(8, 8)} className={(main_id == 8 && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/list.png'} />
                                                Assignment
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "view_uncategorized") && importing === "" &&
                                        <li onClick={() => changeFolder(6, 6)} className={(main_id == 6 && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/options.png'} />
                                                Uncategorized
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                            </ul>
                            }

                            {
                                addNew == "Folder" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Folder")}>
                                    <div className='assets_form_con'>
                                        <label>Folder Name</label>
                                        <input type={"text"} name="name" className='form-control'  placeholder={'e.g New Folder'}  required onChange={(e) => setName(e.target.value)}  />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"plus"} /> Create Folder
                                    </button>
                                </form>    
                                </>
                            }
                            
                            {
                                addNew == "Document" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Document")}>
                                    <div className='assets_form_con'>
                                        <label>Document Name</label>
                                        <input type={"text"} name="name" className='form-control' onChange={e => setName(e.target.value) } placeholder={'e.g Final Report'}  required />
                                    </div>
                                    <br />
                                    <div className='assets_form_con'>
                                        <label>Select Document</label>
                                        <input type={"file"} name="file" className='form-control' onChange={e => setFile(e.target.files[0]) } accept=".pdf" placeholder={'e.g Final Report'}   required />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"file-text"} /> Add Document
                                    </button>
                                </form>    
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Document")}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <section>
                                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                                            <input {...getInputProps()} accept=".pdf"/>
                                            
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            }

                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                                
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='linkImportArea'>
                                        <form action='' method='POST' onSubmit={e => importURLNewFolder(e, "Document")}>
                                            <div className='assets_form_con'>
                                                <div style={{width:"100%"}}>
                                                    <label>Import From URL</label>
                                                    <input type={"url"} name="url" className='form-control' onChange={e => setURL(e.target.value) } placeholder={'e.g Final Report'}  required />
                                                </div>
                                                <button className='btn btn-primary btn-flat'>
                                                    <FeatherIcon icon={"check-circle"} />
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='importFromDriveBtnsCon' onClick={() => handleOpenPicker("Document")} title={"Upload Documents From Google Drive"} >
                                        <div className='pickerBtn'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/google-drive.png'} />
                                            Import From Drive
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                addNew == "Video" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Video")}>
                                    <div className='assets_form_con'>
                                        <label>Video Name</label>
                                        <input type={"text"} name="name" className='form-control' onChange={e => setName(e.target.value) } placeholder={'e.g Introduction'}   required />
                                    </div>
                                    <br />
                                    <div className='assets_form_con'>
                                        <label>Select Video</label>
                                        <input type={"file"} name="file" className='form-control' onChange={e => setFile(e.target.files[0]) } accept=".mp4" placeholder={'e.g Introduction'}   required />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"video"} /> Add Video
                                    </button>
                                </form>    
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Video")}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <section>
                                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                                            <input {...getInputProps()} accept=".mp4"/>
                                            
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            }

                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                                
                                <div className='orCon'>
                                    <div className='leftbar'></div>
                                    <span className='text-center'>OR</span> 
                                    <div className='rightbar'></div>
                                </div>
                                <br />
                                <div className='linkImportArea'>
                                    <form action='' method='POST' onSubmit={e => importURLNewFolder(e, "Video")}>
                                        <div className='assets_form_con'>
                                            <div style={{width:"100%"}}>
                                                <label>Import From URL</label>
                                                <input type={"url"} name="url" className='form-control' onChange={e => setURL(e.target.value) } placeholder={'e.g Final Report'}  required />
                                            </div>
                                            <button className='btn btn-primary btn-flat'>
                                                <FeatherIcon icon={"check-circle"} />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='importFromDriveBtnsCon' onClick={() => handleOpenPicker("Video")} title={"Upload Videos From Google Drive"} >
                                        <div className='pickerBtn'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/google-drive.png'} />
                                            Import From Drive
                                        </div>
                                    </div>

                                </>
                            }
                            {
                                addNew == "Image" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Image")}>
                                    <div className='assets_form_con'>
                                        <label>Image Name</label>
                                        <input type={"text"} name="name" className='form-control' onChange={e => setName(e.target.value) } placeholder={'e.g Final Report'}   required />
                                    </div>
                                    <br />
                                    <div className='assets_form_con'>
                                        <label>Select Image</label>
                                        <input type={"file"} name="file" className='form-control' onChange={e => setFile(e.target.files[0]) } accept=".png, .jpg, .jpeg, .gif, .jpeg, .svg" placeholder={'e.g Final Report'}   required />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"image"} /> Add Image
                                    </button>
                                </form>    
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Image")}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <section>
                                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                                            <input {...getInputProps()} />
                                            
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            }

                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                                
                                <div className='orCon'>
                                    <div className='leftbar'></div>
                                    <span className='text-center'>OR</span> 
                                    <div className='rightbar'></div>
                                </div>
                                <br />
                                <div className='linkImportArea'>
                                    <form action='' method='POST' onSubmit={e => importURLNewFolder(e, "Image")}>
                                        <div className='assets_form_con'>
                                            <div style={{width:"100%"}}>
                                                <label>Import From URL</label>
                                                <input type={"url"} name="url" className='form-control' onChange={e => setURL(e.target.value) } placeholder={'e.g Final Report'}  required />
                                            </div>
                                            <button className='btn btn-primary btn-flat'>
                                                <FeatherIcon icon={"check-circle"} />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='importFromDriveBtnsCon' onClick={() => handleOpenPicker("Image")} title={"Upload Images From Google Drive"} >
                                        <div className='pickerBtn'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/google-drive.png'} />
                                            Import From Drive
                                        </div>
                                    </div>

                                </>
                            }


                        </div>
                    </div>
                }
                </div>


                <div className={ (importType !== "Question") ?  'col-md-6 assetsbars midFileMenager' : 'col-md-9 assetsbars midFileMenager' }>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                { is_trash ? "Trash Bin" : "Import " +importType+"(s)" }
                            </h3>
                            
                            {
                                !is_trash &&
                            <>
                                {
                                    // pagePermissions(props.pagepermissions, "Assets", "bulk_selection") &&
                                    searchType === "all" &&
                                    <>
                                    <div className="input-group pull-right btn btn-primary btn-flat" id='openFilter' onClick={() => filterToggle()}>
                                        <i className='fa fa-filter'></i>
                                    </div>
                                    </>
                                }
                            </>
                            }
                            
                            <div className="input-group pull-right searchBar">
                                {/* <input type="text" className="form-control" placeholder="Search..." style={{borderRightColor:"#FFF"}} onChange={(e) => searchBar(e.target.value)} /> */}
                                <input type="text" className="form-control" placeholder="Search..." style={{borderRightColor:"#FFF"}} onChange={(e) => setFilterValues("name_search", e.target.value)} />
                                <span className="input-group-addon">
                                    <FeatherIcon icon={"search"} />
                                </span>
                            </div>
                            <div className="input-group pull-right searchBar" style={{marginRight:"5px"}}>
                                <select name='searchType' className='form-control' defaultValue={searchType} onChange={(e) => changeSearchType(e.target.value)}>
                                    <option value={"folder"}>Current Folder</option>
                                    <option value={"all"}>All Files</option>
                                </select>
                            </div>
                        </div>

                        {
                            pathList.length > 0 && !is_trash &&
                            <div className='box-body breadCrums'>
                                <ul>
                                {
                                    pathList.map((item, index) => (
                                        <li title={item.name} onClick={() => changeFolder(main_id, item.id)} key={"path"+index}>
                                            {item.name}
                                            <FeatherIcon icon={"chevron-right"} />
                                        </li>    
                                    ))
                                }
                                </ul>    
                            </div>
                        }

                        
                    {
                        !is_trash &&
                        <>
                        {
                            p_id == 0 &&
                        <>
                        <div className='box-body grid_all'>
                            {
                                pinnedDataList.length > 0 &&
                                <>
                                    {
                                        pinnedDataList.map((item, index) => {
                                            let img = "folder"
                                            if(item.type == "Document"){
                                                img = "pdf"
                                            }else if(item.type == "Question"){
                                                img = "answer"
                                            }else if(item.type == "Video"){
                                                img = "play-button"
                                            }else if(item.type == "Recording"){
                                                img = "live"
                                            }else if(item.type == "Image"){
                                                img = "photo"
                                            }else if(item.type == "Youtube"){
                                                img = "youtube"
                                            }else if(item.type == "Live"){
                                                img = "voice"
                                            }
                                            return (
                                            
                                            <div className='col-md-4 grid_box_parent' key={"pinned"+item.id}>
                                                <div className='grid-box' title={item.name}>
                                                    <div className='grid-box-header'>
                                                        <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                        <div className='pinnedoff' title='Remove pinned Mark?' onClick={() => markPin(item.id)}>
                                                            <TbPinnedOff />
                                                        </div>
                                                    </div>
                                                    <div onClick={() => goToFolderWithHighlight(item.main_parent, item.p_id, item.id)}>
                                                        <p className='grid-box-title' >
                                                            {item.name}
                                                        </p>
                                                        <p className='grid-box-items'>
                                                            {item.itemsCount}
                                                            <span className='pull-right'>{item.file_size}</span>
                                                        </p>
                                                    </div>    
                                                </div>
                                            </div>
                                            
                                        )})
                                    }
                                </>
                            }
                            
                        </div>
                        
                        </>
                        }

                        {
                            p_id != 0 &&
                            <>
                            {
                                loading ? 
                                <div style={{minHeight:"300px"}}>
                                    <Loading />
                                </div>
                                :

                            (folderList.length == 0 && filesList.length == 0) ?
                            <>
                            <div className='no-files'>
                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/nofiles.png'} />
                                <p className='no-files-heading'>
                                    <strong>Oops!</strong>
                                </p>
                                <p className='no-files-msg'>
                                    You Haven't Added Any Item Yet.
                                </p>


                            </div>

                                <div className='createbtnsCon no-files-btns' >
                                    {
                                        p_id != 0 &&
                                        <>
                                        {
                                            // pagePermissions(props.pagepermissions, "Assets", "add_folders") &&
                                            // <button type="button" className="btn iconbtns btn-primary" title='New Folder' onClick={() => changeAddTab("Folder", "", "")}>
                                            //     <FeatherIcon icon={"folder-plus"} />
                                            //     New Folder
                                            // </button>
                                        }
                                        
                                        {
                                            // main_id != 3 &&
                                            <>
                                            {
                                                // pagePermissions(props.pagepermissions, "Assets", "add_document") &&
                                                importType === "Document" &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Upload Document' onClick={() => changeAddTab("Document", "", "")}>
                                                    <FeatherIcon icon={"file-text"} />
                                                    Upload Document
                                                </button>
                                            }
                                            {
                                                // pagePermissions(props.pagepermissions, "Assets", "add_video") &&
                                                importType === "Video,Youtube" &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Upload Video' onClick={() => changeAddTab("Video", "", "")}>
                                                    <FeatherIcon icon={"video"} />
                                                    Upload Video
                                                </button>
                                            }
                                            {
                                                // pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                importType === "Image" &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Upload Image' onClick={() => changeAddTab("Image", "", "")}>
                                                    <FeatherIcon icon={"image"} />
                                                    Upload Image
                                                </button>
                                            }
                                            </>
                                        }
                                        {
                                            //  main_id == 3 && 
                                            //  pagePermissions(props.pagepermissions, "Assets", "add_question") &&
                                            <button type="button" className="btn iconbtns btn-primary" title='Add Questions' onClick={() => changeAddTab("Question", "", "")}>
                                                <FeatherIcon icon={"help-circle"} />
                                                Add Question
                                            </button>
                                        }

                                        </>
                                    }

                            </div>
                            </>
                            :
                            <div className='box-body grid_all'>
                            <table id="staff_table" className="table file_manager_table">
                                <thead>
                                    <tr>
                                        <th className='text-center' >
                                            {
                                                compalsary !== "1" &&
                                                <input type={"checkbox"} id={"markAll"} onChange={() => markAll()}/>
                                            }
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Created
                                        </th>
                                        <th>
                                            Modified
                                        </th>
                                        {
                                            importType === "Live" &&
                                            <>
                                                <th>
                                                    Class Date & Time
                                                </th>
                                            </>
                                        }
                                        {
                                            importType === "Assignment" &&
                                            <>
                                                <th>
                                                    Marks
                                                </th>
                                                <th>
                                                    Availability
                                                </th>
                                                <th>
                                                    Start Time
                                                </th>
                                                <th>
                                                    End Time
                                                </th>
                                            </>
                                        }
                                        
                                        {
                                            importType !== "Question" && importType !== "Live" && importType !== "Assignment" &&
                                            <>
                                                <th>
                                                    Size
                                                </th>
                                                <th className='text-center'>
                                                    Encrypt
                                                </th>
                                            </>
                                        }
                                        {
                                            importType === "Question" &&
                                            <>
                                                <th className='text-center'>
                                                    (+ve Marks)
                                                </th>
                                                <th className='text-center'>
                                                    (-ve Marks)
                                                </th>
                                                <th className='text-center'>
                                                    Question Type	
                                                </th>
                                                <th className='text-center'>
                                                    Answer Type	
                                                </th>
                                            </>
                                        }
                                    </tr>
                                </thead>

                                <tbody className='connected-sortable droppable-area1' onLoad={() => callSorting()}>
                                    {
                                        typeof filesList[segment] !== "undefined" &&
                                        filesList[segment].length > 0 &&
                                        filesList[segment].map((item, index) => {
                                            
                                            let img = "folder"
                                            if(item.type == "Document"){
                                                img = "pdf"
                                            }else if(item.type == "Question"){
                                                img = "answer"
                                            }else if(item.type == "Video"){
                                                img = "play-button"
                                            }else if(item.type == "Recording"){
                                                img = "live"
                                            }else if(item.type == "Image"){
                                                img = "photo"
                                            }else if(item.type == "Youtube"){
                                                img = "youtube"
                                            }else if(item.type == "Live"){
                                                img = "voice"
                                            }else if(item.type == "Assignment"){
                                                img = "list"
                                            }

                                            var edit_permission = ""
                                            var remove_permission = ""
                                            var info_permission = ""
                                            var url_permission = ""
                                            if(item.type == "Document"){
                                                // edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_document")
                                                // remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_document")
                                                // info_permission = pagePermissions(props.pagepermissions, "Assets", "info_document")
                                                // url_permission = pagePermissions(props.pagepermissions, "Assets", "url_document")
                                            }else if(item.type == "Question"){
                                                // edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_question")
                                                // remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_question")
                                                // info_permission = pagePermissions(props.pagepermissions, "Assets", "info_question")
                                                // url_permission = pagePermissions(props.pagepermissions, "Assets", "url_question")
                                            }else if(item.type == "Video" || item.type == "Youtube"){
                                                // edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_video")
                                                // remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_video")
                                                // info_permission = pagePermissions(props.pagepermissions, "Assets", "info_video")
                                                // url_permission = pagePermissions(props.pagepermissions, "Assets", "url_video")
                                            }else if(item.type == "Image"){
                                                // edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_image")
                                                // remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_image")
                                                // info_permission = pagePermissions(props.pagepermissions, "Assets", "info_image")
                                                // url_permission = pagePermissions(props.pagepermissions, "Assets", "url_image")
                                            }else if(item.type == "Folder"){
                                                // edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_folders")
                                                // remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_folders")
                                                // info_permission = pagePermissions(props.pagepermissions, "Assets", "info_folders")
                                                // url_permission = false
                                            }
                                            
                                            var clas = (item.new_flick === 1) ? "draggable-item highlighted" : "draggable-item"
                                            if(item.id === highlighter){
                                                clas = "draggable-item highlighted"
                                            }

                                            moment.locale('en');
                                            var ct = item.created_at;
                                            var dt = item.updated_at;

                                            var asd = (item.assignment_start !== null) ? item.assignment_start : "";
                                            var aed = (item.assignment_end !== null) ? item.assignment_end : "";
                                            return(
                                                (item.main_parent !== "4" && item.main_parent !== 4) &&
                                                <tr 
                                                key={"files"+index} 
                                                id={"id"+item.id}
                                                className= {clas}
                                                style={{opacity: addedQuestions.includes(item.id.toString()) ? 0.5 : 1 }}
                                                >
                                                    <td className='text-center' >
                                                        {
                                                            item.type !== "Folder" && !addedQuestions.includes(item.id.toString()) &&
                                                            <>
                                                            {
                                                                compalsary !== "1" ?
                                                                <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.id}/>
                                                                :
                                                                <input type={"radio"} name="mark" className='mark_checkbox' value={item.id}/>
                                                            }
                                                            </>
                                                        }
                                                    </td>
                                                    <td onClick={() => (item.type == "Folder") ? changeFolder(main_id, item.id) : fileInfo(item)} title={(item.type == "Question") ? '' : item.name}>
                                                        <div className='fileTitleList' data-bind={item.subject+" "+item.topic+" "+item.tags}>
                                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}}>
                                                        {moment(ct).format("D-M-Y")}
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}}>
                                                        {moment(dt).format("D-M-Y")}
                                                    </td>
                                                    {
                                                        importType === "Live" &&
                                                        <>
                                                            <td>
                                                                {item.classtime}
                                                            </td>
                                                        </>
                                                    }

                                                    
                                                    {
                                                        importType === "Assignment" &&
                                                        <>
                                                            <td>
                                                                {item.assignment_marks}
                                                            </td>
                                                            <td>
                                                                {(item.availibility === 1 || item.availibility === "1") ? "Lifetime" : "Scheduled"}
                                                            </td>
                                                            <td>
                                                                
                                                                {
                                                                    asd !== "" ?
                                                                    moment(asd).format("D-M-Y")
                                                                    :
                                                                    "-"
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    aed !== "" ?
                                                                    moment(aed).format("D-M-Y")
                                                                    :
                                                                    "-"
                                                                }
                                                            </td>
                                                        </>
                                                    }
                                                    
                                                {
                                                    importType !== "Question" && importType !== "Live" && importType !== "Assignment" &&
                                                    <>
                                                        <td style={{whiteSpace:"nowrap"}}>
                                                            {
                                                                (item.file_size == "") ? "-" : item.file_size
                                                            }
                                                        </td>
                                                        <td className='text-center'>
                                                            {
                                                                item.type === "Video" &&
                                                                <>
                                                                {
                                                                    item.encrypted == null &&
                                                                    <div className='badge red-badge' title='Waiting For Encrypt'>
                                                                        <FeatherIcon icon={"clock"} />
                                                                    </div>
                                                                }
                                                                {
                                                                    item.encrypted == 1 &&
                                                                    <div className='badge yellow-badge' title='Encrypting Video...'>
                                                                        <FeatherIcon icon={"loader"} />
                                                                    </div>
                                                                }
                                                                {
                                                                    item.encrypted != null && item.encrypted != 1 &&
                                                                    <div className='badge green-badge' title='Video Encrypted'>
                                                                        <FeatherIcon icon={"check-circle"} />
                                                                    </div>
                                                                }
                                                                </>
                                                            }
                                                        </td>
                                                    </>
                                                }
                                                        
                                                {
                                                    importType === "Question" &&
                                                    <>
                                                        <td className='text-center'>
                                                            {item.posMarks}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.negMarks}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.question_type}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.answerType}
                                                        </td>
                                                    </>
                                                }
                                                </tr>
                                                )
                                            })
                                    }

                                </tbody>
                            </table>
                            
                            <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                {
                                    filesList.length > 0 && filesList.length <= 10 &&
                                    filesList.map((item, index) => {
                                        const active = index === segment ? " btn-primary" : ""
                                        return(
                                            <button className={'btn btn-flat'+active} key={"nav"+index} style={{marginLeft:"5px"}} onClick={() => {
                                                setSegment(index)
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                }} >
                                                {index+1}
                                            </button>
                                        )
                                    })
                                }
                                {
                                    filesList.length > 10 &&
                                    <div style={{width:"200px", float:"right"}}>
                                        <label>Last Page: {filesList.length}</label>
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                                    </div>
                                }
                            </div>
                            </div>
                                }
                            </>
                        }
                        </>
                        }

                    </div>
                </div>

                {
                    importType !== "Question" &&
                <div className='col-md-3 assetsbars'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Uploading Status
                            </h3>
                            {
                                queue.length > 0 && !uploading &&
                                <span className='pull-right clearAll' title='Clear All' onClick={() => clearAll()}>
                                    Clear All
                                </span>
                            }
                        </div>
                        <div className='col-md-12 storage-box-con'>

                            {
                                previewLoading &&
                                <div style={{minHeight:"300px"}}>
                                    <Loading />
                                </div>  
                                
                            }
                            {
                                preview !== "" && !previewLoading &&
                                
                                <div className='previewPanel'>
                                    <div className='previewContent' style={{backgroundColor:preview.color+"17"}}>
                                        <br />
                                        
                                        <span className='pull-right previewClose' style={{cursor:'pointer'}} title={"Close Preview"} onClick={() => setPreview("")}>
                                            <FeatherIcon icon={"x"} />
                                        </span>

                                        {
                                            preview.type !== "Image" && preview.type !== "Video" && preview.type !== "Youtube" &&
                                            <div className='chenterCircle forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+preview.thumb+'.png'} />
                                            </div>
                                        }
                                        
                                        {
                                            preview.type === "Image" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={assets_url + preview.path} />
                                            </div>
                                        }
                                        {
                                            preview.type === "Video" && 
                                            <>
                                            <div className='centerPreview' style={{backgroundColor:preview.color+"17"}}>
                                                {
                                                    preview.tab == "English" ?
                                                    <>
                                                    {
                                                        preview.encrypted_file !== "" && preview.encrypted_file !== null &&
                                                        <>
                                                            {/* <Video id={preview.id} type={"videoUrl"} /> */}
                                                            <Video id={preview.id} type={"encrypt"} />
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {
                                                        preview.encrypted_hindi_file !== "" && preview.encrypted_hindi_file !== null &&
                                                        // <Video id={preview.id} type={"hindi_videoUrl"} />
                                                        <Video id={preview.id} type={"hindi_encrypt"} />
                                                    }
                                                    </>
                                                }
                                            </div>
                                            <div className='changeTabBtns'>
                                                <button className={ preview.tab !== "English" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='English' onClick={() => changePreviewTab("English")}>
                                                    English
                                                </button>
                                                <button className={ preview.tab !== "Hindi" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='Hindi' onClick={() => changePreviewTab("Hindi")}>
                                                    Hindi
                                                </button>
                                            </div>
                                            </>
                                        }
                                        {
                                            preview.type === "Youtube" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <iframe src={preview.youtubeEmbedUrl} style={{border:"none"}}></iframe>
                                            </div>
                                        }
                                        <ul>
                                            <li>
                                                <strong>File Name: </strong> {preview.name}
                                            </li>

                                            
                                            
                                            {
                                                typeof preview.path !== "undefined" && preview.type === "Video" && preview.tab === "English" && 
                                                <li>
                                                    <strong>English File: </strong> 

                                                    <div className='changeVideoLink' onClick={() => window.$('#VideoChangeEnglish').click()}>
                                                        <input type={"file"} name="file" id='VideoChangeEnglish' style={{display:"none"}} className='form-control' onChange={e => updateVideo(e, preview.id, "English") } accept=".mp4" required />
                                                        {
                                                            preview.path !== null && preview.path !== "" ?

                                                            "Change Video"
                                                            :
                                                            "Add Video"
                                                        }
                                                    </div>
                                                </li>
                                            }

                                            {
                                                typeof preview.hindi_path !== "undefined" && preview.type === "Video" && preview.tab === "Hindi" && 
                                                <li>
                                                    <strong>Hindi File: </strong> 

                                                    <div className='changeVideoLink' onClick={() => window.$('#VideoChangeHindi').click()}>
                                                        <input type={"file"} name="file" id='VideoChangeHindi' style={{display:"none"}} className='form-control' onChange={e => updateVideo(e, preview.id, "Hindi") } accept=".mp4" required />
                                                        {
                                                            preview.hindi_path !== null && preview.hindi_path !== "" ?

                                                            "Change Video"
                                                            :
                                                            "Add Video"
                                                        }
                                                        
                                                    </div>
                                                </li>
                                            }

                                            {
                                                preview.type !== "" && typeof preview.type !== "undefined" &&
                                                <li>
                                                    <strong>File Type: </strong> {preview.type}
                                                </li>
                                            }
                                            {
                                                preview.extention !== "" && typeof preview.extention !== "undefined" &&
                                            <li>
                                                <strong>File Extension: </strong> {preview.extention}
                                            </li>
                                            }
                                            
                                            {
                                                preview.width !== "" && typeof preview.width !== "undefined" &&
                                            <li>
                                                <strong>Image Dimentions: </strong> {preview.width + "PX X " + preview.height+"PX"}
                                            </li>
                                            }
                                            {
                                                preview.org_size !== "" && typeof preview.org_size !== "undefined" && preview.tab === "English" &&
                                            <li>
                                                <strong>File Size: </strong> {preview.org_size}
                                            </li>
                                            }

                                            {
                                                preview.hindiItem !== "" && preview.hindi_size !== null && typeof preview.hindi_size !== "undefined" && preview.tab === "Hindi" &&
                                            <li>
                                                <strong>File Size: </strong> {preview.hindi_size}
                                            </li>
                                            }

                                            {
                                                preview.items !== "" && typeof preview.items !== "undefined" &&
                                            <li>
                                                <strong>Item{"(s)"} : </strong> {preview.items}
                                            </li>
                                            }
                                            {
                                                preview.pagecount !== "" && typeof preview.pagecount !== "undefined" && preview.pagecount !== 0 &&
                                            <li>
                                                <strong>Document Length: </strong> {preview.pagecount}
                                            </li>
                                            }
                                            
                                            
                                            {
                                                typeof preview.durationHour !== "undefined" && preview.durationHour !== "" && preview.tab === "English" &&
                                            <li>
                                                <strong>Video Duration: </strong> {preview.durationHour}
                                            </li>
                                            }
                                            {
                                                preview.tab === "Hindi" && typeof preview.hindiItem.durationHour !== "undefined" && preview.hindiItem !== "" && preview.hindiItem.durationHour !== "" && 
                                            <li>
                                                <strong>Video Duration: </strong> {preview.hindiItem.durationHour}
                                            </li>
                                            }
                                            {
                                                typeof preview.created_at !== "undefined" &&
                                            <li>
                                                <strong>Created On: </strong> {preview.created_at}
                                            </li>
                                            }
                                            {
                                                typeof preview.updated_at !== "undefined" &&
                                            <li>
                                                <strong>Last Modified On: </strong> {preview.updated_at}
                                            </li>
                                            }
                                        </ul>
                                        <br />
                                    </div>
                                </div>
                            }

                            {
                                queue.length > 0 ?

                                queue.map((item, index) => {
                                    
                                    let img = "folder"
                                    if(item.type == "Document"){
                                        img = "pdf"
                                    }else if(item.type == "Question"){
                                        img = "answer"
                                    }else if(item.type == "Video"){
                                        img = "play-button"
                                    }else if(item.type == "Recording"){
                                        img = "live"
                                    }else if(item.type == "Image"){
                                        img = "photo"
                                    }
                                    var uploaded = item.uploading
                                    if(item.status == true){
                                        uploaded=100
                                    }
                                    
                                    return (
                                        <div className='storage-box' title='Pdf Files' key={"bars"+index} id={"bars"+index}>
                                            <div className='storage-icon-con'>
                                                <div className='storage-icon'>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                </div>
                                                <div className='uploading-title'>
                                                    <strong>
                                                        {
                                                            item.name
                                                        }
                                                        
                                                        <div className='upoading-percentage pull-right'>
                                                            <span className='percentage_span'>
                                                                {item.uploading}% &nbsp;
                                                            </span>
                                                            <div className='pinnedoff' title='Cancel Uploading' onClick={() => cancelRequest(index)} style={{display:(!item.status ? "block" : "none")}}>
                                                                <FeatherIcon icon={"x"} />
                                                            </div>
                                                            {                                                   
                                                                item.retry == 1 &&
                                                                <div className='pinnedoff' title='Retry Uploading' onClick={() => retryUpload(index)}>
                                                                    <FeatherIcon icon={"rotate-cw"} />
                                                                </div>
                                                            }
                                                        </div>

                                                    </strong>
                                                    <div className='uploadingBar'>
                                                        <div className={item.status == true ? 'insideBar completeBar' : 'insideBar'} style={{width:item.uploading+"%",backgroundColor:item.uploading}}></div>
                                                    </div>
                                                    <div className='uploadingMessages'>
                                                        {item.msg}
                                                    </div>
                                                </div>
                                            </div>    
                                        </div>
                                    )
                                })
                                :
                            <>
                                <div className='emptyQueue'>
                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/queue.png'} />
                                    <p className="no-files-heading"><strong>Oops!</strong></p>
                                    <p className="no-files-msg">It looks live you haven't uploaded any items yet.</p>
                                </div>
                            </>
                        }
                        </div>
                    </div>
                </div>
                }
            </div>    
        }
        
        <div className='renameDialogBG' style={{display:"none"}}>
            <div className='renameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Rename
                    </p>
                </div>
                <div className='renameInputCon'>
                    <input type={"text"} name={"name"} className="renameInput" required placeholder='e.g Rename' onChange={(e) => setName(e.target.value)} value={name} />
                </div>
                <div className='renameFooter'>
                    <button className='btn btn-primary btn-flat pull-right' style={{marginTop:"10px"}} onClick={() => renameFile()}>
                        Rename
                    </button>
                </div>
            </div>
        </div>

        {
            addLiveClass &&
            
        <div className='renameDialogBG' id='LiveClassPop' >
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Add New Live Class
                        <span className='cursor-pointer pull-right' onClick={() => setAddLiveClass(false)} >
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <Liveclassimport courseid={courseid} folderid={folderid} />
                </div>
            </div>
        </div>
        }

        
        {
            addAssignment &&
        <div className='renameDialogBG' id='LiveClassPop' >
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        {assignmentType} Assignment
                        <span className='cursor-pointer pull-right' onClick={() => {
                            setAddAssignment(false)
                            }} >
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <Assignment main_id={8} p_id={8} aboveBelow={aboveBelow} aboveBelowPosition={aboveBelowPosition} type={assignmentType} assignmentId={assignmentId} from={"Import"} content_main_id={courseid} content_p_id={folderid} />
                </div>
            </div>
        </div>
        }

        
        {
            qeustionPreview !== null &&
            <div className='renameDialogBG' id='LiveClassPop' >
                <div className='renameBody bigRenameBody'>
                    <div className='renameHeading'>
                        <p className='text-left'>
                            Question Preview
                            <span className='cursor-pointer pull-right' onClick={() => {
                                setQuestionPreview(null)
                                }} >
                                <i className='fa fa-times'></i>
                            </span>
                        </p>
                    </div>
                    <div className='renameInputCon'>
                        
                <div className='col-md-12' style={{overflow:"hidden", backgroundColor:"#f2f2f2"}} >
                    <br />
                    {
                        qeustionPreview.essay !== null && qeustionPreview.essay !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Essay/Comprehensive
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview.essay}} ></div>
                            </div>
                    }
                    {
                        qeustionPreview.question !== null && qeustionPreview.question !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Question
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview.question}} ></div>
                            </div>
                    }
                    {
                        [...Array(6)].map((item, index) => {
                            return(
                                qeustionPreview['option'+(index+1)] !== null && qeustionPreview['option'+(index+1)] !== "" &&
                                <div className='box no-border' key={"key" + index}>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Option {(index+1)}
                                        </h3>
                                    </div>
                                    <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview['option'+(index+1)]}} ></div>
                                </div>
                            )
                        })
                    }
                    {
                        qeustionPreview.explanation !== null && qeustionPreview.explanation !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Explanation
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview.explanation}} ></div>
                            </div>
                    }
                    {
                        qeustionPreview.subject !== null && qeustionPreview.subject !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Subject
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview.subject}} ></div>
                            </div>
                    }
                    {
                        qeustionPreview.topic !== null && qeustionPreview.topic !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Topics
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview.topic}} ></div>
                            </div>
                    }
                    {
                        qeustionPreview.tags !== null && qeustionPreview.tags !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Tags
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: qeustionPreview.tags}} ></div>
                            </div>
                    }
                        <div className='col-md-4 no-left-padding'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        (+ve) Marks
                                    </h3>
                                </div>
                                <div className='box-body'>{qeustionPreview.posMarks}</div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        (-ve) Marks
                                    </h3>
                                </div>
                                <div className='box-body'>{qeustionPreview.negMarks}</div>
                            </div>
                        </div>
                        <div className='col-md-4 no-right-padding'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Answer Type
                                    </h3>
                                </div>
                                <div className='box-body'>{qeustionPreview.answerType}</div>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
            </div>
        }

        </div>
        </>



    )
  }
  return (
    <>
        <Assets_Liabrary />
    </>
  )
}
