import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
const cookies = new Cookies();

export default function Seo(props) {

  const params = useParams()
  const [title, setTitle] = useState(props.course.seotitle)
  const [slug, setSlug] = useState(props.course.seoslug)
  const [keywords, setKeywords] = useState(props.course.seokeywords)
  const [description, setDescription] = useState(props.course.seodescription)
  const [headscript, setHeadscript] = useState(props.course.seoheadscript)
  const [bodyscript, setBodyscript] = useState(props.course.seobodyscript)
  const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()

  const makeSlug = (value) => {
    var temp = value
    temp = temp.replace(/[ ]/g, "-")
    temp = temp.replace(/[^a-zA-Z0-9-]/g, "")
    setSlug(temp.toLowerCase())
  }

  const updateSeo = (e) => {
    e.preventDefault()
    formLoading(e.target)
    fetch(apiurl+"api/course_seo", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                courseid: params.id,
                title:title,
                slug:slug,
                keywords:keywords,
                description:description,
                headscript:headscript,
                bodyscript:bodyscript,
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
            })
        })
        .then(response => response.json())
        .then(response => {
            $(e.target).find(".loaderbody").fadeOut()
            if(typeof response.status !== "undefined" && response.status == "OK"){
                successModal("Success", response.message)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            
            // console.log(err);
        });
  }
  return (
    <div className='col-md-12'>
      <br />
      <div className='box no-border'>
        <div className='box-header'>
          <h3 className='box-title'>
            Search Engine Optimization
          </h3>
        </div>
        <form action='' method='POST' onSubmit={(e) => updateSeo(e)}>
          <div className='box-body'>
            <div className='col-md-6 no-left-padding'>
              <label>
                SEO Title
              </label>
              <input type={"text"} name={"title"} placeholder={"e.g Best Online Learning Platform"} className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className='col-md-6 no-right-padding'>
              <label>
                SEO Slug
              </label>
              <input type={"text"} name={"slug"} placeholder={"e.g best-online-learning-platform"} className="form-control" value={slug} onChange={(e) => makeSlug(e.target.value)} />
            </div>
          </div>
          <div className='box-body'>
            <div className='col-md-12 no-left-padding no-right-padding'>
              <label>
                SEO keywords
              </label>
              <textarea  placeholder="e.g E-learning,best learning platform,CA, CA foundation..." className="form-control" onChange={(e) => setKeywords(e.target.value)} defaultValue={keywords}></textarea>
            </div>
          </div>
          <div className='box-body'>
            <div className='col-md-12 no-left-padding no-right-padding'>
              <label>
                SEO Description
              </label>
              <textarea  placeholder="e.g Escholars provides online classes for CA foundation, CA inter both groups, Class 12, Class 11, B.Com, BBA, CA FINAL, CS foundation and CMA foundation at Concessional Prices. Our commitment is to provide the best education to our learners, which they should not only appreciate but feel proud to be a part of this institution...." className="form-control" onChange={(e) => setDescription(e.target.value)} defaultValue={description}></textarea>
            </div>
          </div>
          <div className='box-body'>
            <div className='col-md-12 no-left-padding no-right-padding'>
              <label>
                Head Script
              </label>
              <textarea name='headscript' placeholder='e.g <script src="https://example.com/head-script.js"><script>' className="form-control" onChange={(e) => setHeadscript(e.target.value)} defaultValue={headscript}></textarea>
            </div>
          </div>
          <div className='box-body'>
            <div className='col-md-12 no-left-padding no-right-padding'>
              <label>
                Body Script
              </label>
              <textarea name='bodyscript' placeholder='e.g <script src="https://example.com/body-script.js"><script>' className="form-control"  onChange={(e) => setBodyscript(e.target.value)} defaultValue={bodyscript}></textarea>
            </div>
          </div>
          <div className='box-footer'>
            <button className='btn btn-primary btn-flat btn-block'>
              <i className='fa fa-save'></i> Update
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
