import React, { useState, useEffect, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Adminprops from '../../VelidateWebsite/Adminprops'
import Cookies from 'universal-cookie'
import CommonFunc from '../includes/Common'
import Notfound from '../includes/Notfound'
import FeatherIcon from 'feather-icons-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading'



const cookies = new Cookies();

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
export default function Liveclassimport(props) {

  const LiveclassesPage = () => {


    const navigate = useNavigate()
    const params = useParams()
    const query = useQuery();
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()
    
    const [id, setId] = useState("")
    const [position, setPosition] = useState(1)
    const [name, setName] = useState("")
    const [classitme, setClasstime] = useState("")
    const [recording, setRecording] = useState("")
    const [preMessage, setPreMessage] = useState("")
    const [postMessage, setPostMessage] = useState("")

    const [preMessageEditor, setPreMessageEditor] = useState("")
    const [postMessageEditor, setPostMessageEditor] = useState("")



    const[pathList, setPathList] = useState([])
    const[filesList, setFilesList] = useState([])
    const[loading, setLoading] = useState(true)

    const[showForm, setShowForm] = useState(false)

    const clean = () => {
        setName("")
        setClasstime("")
        setRecording("")
        setPreMessage("")
        setPostMessage("")
            
        setTimeout(function(){
            setPreMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={""} onChange={ ( event, editor ) => { const data = editor.getData(); setPreMessage(data) } } /> )
            setPostMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={""} onChange={ ( event, editor ) => { const data = editor.getData(); setPostMessage(data) } } /> )
        }, 500)
    }

    const saveLiveClass = (e) => {
        e.preventDefault()
        formLoading(e.target)
        var url = (id === "") ? "import_new_live_classes" : "update_course_live_classes"
        fetch(apiurl+"api/"+url, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    id: id,
                    p_id: props.folderid,
                    main_id: props.courseid,
                    name: name,
                    classitme: classitme,
                    recording: recording,
                    preMessage: preMessage,
                    postMessage: postMessage,
                    position: position,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                
                // console.log(err);
            });
    }


    const checkEdit = () => {
        var qid = query.get("qid")
        if(qid !== null){
            setTimeout(function(){
                window.$("#id"+qid).click()
            }, 1000)
        }
    }

    const showFormNoew = () => {
        clean()
        setShowForm(true)
        setTimeout(function(){
            setPreMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={""} onChange={ ( event, editor ) => { const data = editor.getData(); setPreMessage(data) } } /> )
            setPostMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={""} onChange={ ( event, editor ) => { const data = editor.getData(); setPostMessage(data) } } /> )
        }, 1000)
    }

    const callEdit = (item) => {
        var arr = JSON.parse(item.classdata)
        setId(item.id)
        setName(item.name)
        setClasstime(item.classtime)
        setRecording((arr.recordingavailable !== null) ? arr.recordingavailable : '')
        setPosition(item.position)

        setPreMessageEditor("")
        setPostMessageEditor("")
        
        setPreMessage(arr.pre)
        setPostMessage(arr.post)

        setShowForm(true)
        setTimeout(function(){
            setPreMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={arr.pre} onChange={ ( event, editor ) => { const data = editor.getData(); setPreMessage(data) } } /> )
            setPostMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={arr.post} onChange={ ( event, editor ) => { const data = editor.getData(); setPostMessage(data) } } /> )
        }, 500)

    }

    var b = 0
    useEffect(() => {
      if(b === 0){
        if(typeof props.data !== "undefined" && props.data !== null){
            callEdit(props.data)
        }else{
            showFormNoew()
        }
        b++
      }
    }, [])

    return(
      
      <>
    {
        <div className='col-md-12'>
            <div className='box no-border'>
                <form action='' method='POST' onSubmit={(e) => saveLiveClass(e)} >
                    <div className='box-body'>
                        <div className='col-md-3'>
                            <label>Class Title <span className='text-red'>*</span></label>
                            <input type={"text"} className="form-control" required placeholder='e.g CA Foundation Introduction' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='col-md-3'>
                            <label>Class Available From <span className='text-red'>*</span></label>
                            <input type={"datetime-local"} className="form-control" required placeholder='e.g CA Foundation Introduction' value={classitme} onChange={(e) => setClasstime(e.target.value) }  />
                        </div>
                        <div className='col-md-3'>
                            <label>Show Recording if Available</label>
                            <select className='form-control' value={recording} onChange={(e) => setRecording(e.target.value) } >
                                <option value={""}>No</option>
                                <option value={"1"}>Yes</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>Position <span className='text-red'>*</span></label>
                            <input type={"number"} className="form-control" required placeholder='e.g 1' value={position} onChange={(e) => setPosition(e.target.value) }  />
                        </div>
                    </div>  
                    <div className='box-body'>
                        <div className='col-md-12'>
                            <label>Pre Class Message (shown to learners before live session)</label>
                            {
                                preMessageEditor !== "" ?
                                preMessageEditor
                                :
                                <>
                                    <br />
                                    <span>Loading...</span>
                                </>
                            }
                        </div>
                    </div>
                    <div className='box-body'>
                        <div className='col-md-12'>
                            <label>Post Class Message (shown to learners after live session)</label>
                            {
                                postMessageEditor !== "" ?
                                postMessageEditor
                                :
                                <>
                                    <br />
                                    <span>Loading...</span>
                                </>
                            }
                        </div>
                    </div>
                    <div className='box-footer'>
                        <div className='col-md-12'>
                            <button className='btn btn-flat btn-block btn-primary'>
                                Save Class
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
  }
  
      </>
      
    )
  }
  return (
    <>
      <LiveclassesPage />
    </>
  )
}
