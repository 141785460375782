import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
import { CSVLink, CSVDownload } from "react-csv";
const cookies = new Cookies();


export default function ManageEnquries() {
  const ManageEnquriesPage = (props) => {
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url} = CommonFunc()
    const [loading, setLoading] = useState(false)
    const [getEnquiries, setEnquiries] = useState([])
    const [segment, setSegment] = useState(0)
    let getToken = cookies.get('token')
    const [refresh, setRefresh] = useState("No")
    
    const [filter, setFilter] = useState({
        start: "",
        end: "",
    })
    const [filterLoading, setFilterLoading] = useState(false)

    
    const fetchList = () => {
        
        setLoading(true)
        fetch(apiurl+"api/getEnquiries", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      filter: filter
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    setEnquiries(response.list)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
                setFilterLoading(false)
            })
            .catch(err => {
            });
    }


    
    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["Name", "Email", "Contact", "Course", "Message"]
        // const th = ["Name", "Email", "Contact", "Course", "Message", "Date", "From"]
        var segmentTemp = getEnquiries
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = getEnquiries[j]
            for(var i = 0;i<temp.length;i++){

                moment.locale('en');
                var ct = temp[i]['created_at'];
                var otherinfo = (temp[i].otherdata !== null) ?  JSON.parse(temp[i].otherdata) : null

                td.push(
                    [
                        temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['course'], temp[i]['message']
                        // temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['course'], temp[i]['message'], moment(ct).format("D-M-Y H:m:s A"),
                        // otherinfo !== null && otherinfo.link_url !== undefined ? otherinfo.link_url : '-'
                    ]
                )
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Enquiries List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    
      
    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        fetchList()
        setFilterLoading(true)
    }

    var a = 0
    useEffect(() => {
        if(a === 0){
            fetchList()
            a++
        }
    }, [])
    
    return(
        <div className='col-md-12' >
            <div className="box no-border">
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter
                    </h3>
                    {
                        filterLoading &&
                        <span className='pull-right'  style={{color:"red"}} >
                            Applying Filter
                        </span>
                    }
                </div>
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>Start Date</label>
                        <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                    </div>
                    <div className='col-md-3'>
                        <label>End Date</label>
                        <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                    </div>
                </div>
            </div>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Enquiries
                    </h3>
                    
                    {
                        !downloading ?
                        <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest()} >
                            Download
                        </button>
                        :
                        <button className='btn btn-warning btn-flat pull-right' >
                            Preparing...
                        </button>
                    }
                </div>
                <div className='box-body'>
                    <table className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                <th>Sno</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Course</th>
                                <th>Message</th>
                                <th>Date</th>
                                <th>From</th>
                                <th className='hidden'>Other Info</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            getEnquiries.length > 0 && typeof getEnquiries[segment] !== "undefined" && getEnquiries[segment].length > 0 &&
                            getEnquiries[segment].map((item, index) => {
                                moment.locale('en');
                                var ct = item.created_at;
                                var otherinfo = (item.otherdata !== null) ?  JSON.parse(item.otherdata) : null
                                return(
                                    <tr key={"list"+index}>
                                        <td>{index+1}</td>
                                        <td>{item.name}</td>
                                        <td>
                                            <a href={ "mailto:" + item.email} title={ "Mail to" + item.email}>
                                                {item.email}
                                            </a>
                                        </td>
                                        <td>
                                            <a href={ "tel:" + item.contact} title={"Make Call On " + item.contact} >
                                                {item.contact}
                                            </a>
                                        </td>
                                        <td>{item.course}</td>
                                        <td>{item.message}</td>
                                        <td>{moment(ct).format("D-M-Y H:m:s A")}</td>
                                        <td >
                                            {otherinfo !== null && otherinfo['link_url'] !== undefined ? otherinfo['link_url'] : '-'}    
                                        </td>
                                        <td className='hidden'>
                                            {item.otherdata}    
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                
                <div className='box-footer text-right'>
                    {
                        getEnquiries.length > 0 && getEnquiries.length < 10 &&
                        getEnquiries.map((item, index) => {
                            const active = segment === index ? " btn-primary " : ""
                            return(
                                <button className={'btn btn-flat'+active} style={{marginLeft:"10px"}} key={"navs"+index} onClick={() => setSegment(index)} >
                                    {index+1}
                                </button>
                            )
                        })
                    }
                    {
                        getEnquiries.length > 10 &&
                        <div style={{width:"200px", float:"right"}}>
                            <label>Last Page: {getEnquiries.length}</label>
                            <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
  }
  return (
    <>
        {
            <Sidebar page={ManageEnquriesPage} permission="Manaege_Enquiries"/>   
        }
    </>
  )
}
