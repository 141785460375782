import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import {  useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import FeatherIcon from 'feather-icons-react';
import Video from '../Assets_Liabrary/Video';
const cookies = new Cookies();

export default function Basiccourseinfo(props) {
  
  const navigate = useNavigate()
  const params = useParams()
  const courseData = (props.course.coursedata !== "" && props.course.coursedata !== null) ? JSON.parse(props.course.coursedata) : ""
  const [name, setName] = useState(props.course.name)
  const [subheading, setSubheading] = useState((courseData !== "") ? courseData.subheading : '')
  const [instructor, setInstructor] = useState((courseData !== "") ? courseData.instructor : '')
  const [language, setLanguage] = useState((courseData !== "") ? courseData.language : '')
  const [contain, setContain] = useState((courseData !== "") ? courseData.contain : '')
  
  const [showbannerdata, setShowBannerData] = useState((props.course !== "") ? props.course.showbannerdata : '')
  const [showContentInformation, setShowContentInformation] = useState((props.course !== null) ? props.course.contenetinfo : '')
  const [redirectto, setRedirectTo] = useState((props.course !== null) ? props.course.redirectto : '')
  const [topeditor, setTopBanner] = useState((props.course !== "") ? props.course.topeditor : '')
  const [bottomeditor, setBottomBanner] = useState((props.course !== "") ? props.course.bottomeditor : '')

  const [showin, setShowIn] = useState((props.course !== "") ? props.course.free : '')
  const [availablefor, setAvailablefor] = useState((props.course !== "") ? props.course.availablefor : '')
  
  const [courseType, setCourseType] = useState(props.course.coursetype)
  const [courseStatus, setCourseStatus] = useState(props.course.status)
  const [addpoint, setAddpoint] = useState(1)

  const [pointLoading, setPointLoading] = useState(true)
  const [includesPoints, setIncludePoints] = useState([])
  const [whyPoints, setWhyPoints] = useState([])
  const [faqPoints, setFaqPoints] = useState([])

  const [pointUpdateArray, setPointUpdateArray] = useState({"title":"", "body" : "", "icon" : "", "id" : ""})
  const [pointID, setPointID] = useState("")
  const [pointTitle, setPointTitle] = useState("")
  const [pointBody, setPointBody] = useState("")
  const [pointType, setPointType] = useState("")
  const [importFileType, setImportFileType] = useState("Video")
  const [subcategories, setSubcategories] = useState([])

  const [booksIncluded, setBooksIncluded] = useState((props.course.books !== null) ? props.course.books : "")
  const [booksL, setBooksL] = useState((props.course.l !== null) ? props.course.l : "")
  const [booksB, setBooksB] = useState((props.course.b !== null) ? props.course.b : "")
  const [booksH, setBooksH] = useState((props.course.h !== null) ? props.course.h : "")
  const [booksW, setBooksW] = useState((props.course.w !== null) ? props.course.w : "")
  const [showchat, setShowchat] = useState((props.course.showchat !== null) ? props.course.showchat : "")

  const [prefix, setPrefix] = useState((props.course.prefix !== null) ? props.course.prefix : "")
  const [packagehour, setPackagehour] = useState((props.course.packagehour !== null) ? props.course.packagehour : "")
  

  const[previewLoading, setPreviewLoading] = useState(false)
  const[preview, setPreview] = useState("")

  const[youtube_thumbnail, setYoutube_thumbnail] = useState((props.course.youtube_thumbnail !== null) ? props.course.youtube_thumbnail : "")
  
  // const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const[refresh, setRefresh] = useState("No")
  const [categories, setCategoryList] = useState([])
  // const [categoriesLoading, setCategoriesLoading] = useState([])

  const [btns, setBtns] = useState( (props.course.btns !== null) ? JSON.parse(props.course.btns) : {
    "hide_all_buttons": "No",
    "hide_buy_now_button": "No",
    "hide_whatsapp_button": "No",
    "hide_notifyme_button": "Yes",
    "hide_redirect_button": "Yes",
    "redirect_url": "",
    "hide_plans": "No",
  })

  var a = 0;
  const {AdminBasics, successModal, errorModal, formLoading, updatTrail, warningModal} = CommonFunc()
  
  const submitBasicInfo = (e) => {
      
      e.preventDefault()
      formLoading(e.target)
      fetch(apiurl+"api/update_course_basic", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                courseid: props.course.otherid,
                name:name,
                subheading:subheading,
                instructor:instructor,
                language:language,
                contain:contain,
                courseType:courseType,
                courseStatus:courseStatus,
                showin:window.$("#subcategories").val(),
                books:booksIncluded,
                l:booksL,
                b:booksB,
                h:booksH,
                w:booksW,
                showchat:showchat,
                prefix: prefix,
                contenetinfo: showContentInformation,
                packagehour: packagehour,
                youtube_thumbnail: youtube_thumbnail,
                showbannerdata: showbannerdata,
                redirectto: redirectto,
                selectedCategories: window.$("#categories").val(),
                availablefor: window.$("#availablrfor").val(),
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                btns: btns
            })
        })
        .then(response => response.json())
        .then(response => {
          $(e.target).find(".loaderbody").fadeOut()
            if(typeof response.status !== "undefined" && response.status === "OK"){
                successModal("Success", response.message)
                props.basics()
                
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
          $(e.target).find(".loaderbody").fadeOut()
            // console.log(err);
        });
  }


  const addPoint = (type) => {
    fetch(apiurl+"api/add_course_point", {
      "method": "POST",
      "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
              courseid: props.course.otherid,
              type: type,
              point: addpoint,
              page_url: window.location.href,
              staff: AdminBasics(),
              accecible: 1
          })
      })
      .then(response => response.json())
      .then(response => {
          if(typeof response.status !== "undefined" && response.status === "OK"){
              successModal("Success", response.message)
              getPoints()
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
      })
      .catch(err => {
          // console.log(err);
      });
  }

  const updateAdditionalInformation = (col, data) => {
    warningModal("Loading...", "Saving Additional Information")
    fetch(apiurl+"api/updateAddistionalInfo", {
      "method": "POST",
      "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
              courseid: props.course.otherid,
              col: col,
              data: data,
              page_url: window.location.href,
              staff: AdminBasics(),
              accecible: 1
          })
      })
      .then(response => response.json())
      .then(response => {
          if(typeof response.status !== "undefined" && response.status === "OK"){
            successModal("Success", response.message)
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
      })
      .catch(err => {
          // console.log(err);
      });
  }

  
  const getSubcategories = () => {
    fetch(apiurl+"api/getSubcategories", {
      "method": "POST",
      "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
              categories: $("#categories").val(),
              page_url: window.location.href,
              staff: AdminBasics(),
              accecible: 1
          })
      })
      .then(response => response.json())
      .then(response => {
          if(typeof response.status !== "undefined" && response.status === "OK"){
            setSubcategories(response.list)
            // setTimeout(function(){
              window.$("#subcategories").unbind().select2()
            // }, 500) 
            
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
      })
      .catch(err => {
          // console.log(err);
      });
  }
  const getPoints = () => {
    setPointLoading(true)
    fetch(apiurl+"api/get_points_list", {
      "method": "POST",
      "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
              courseid: props.course.otherid,
              page_url: window.location.href,
              staff: AdminBasics(),
              accecible: 1
          })
      })
      .then(response => response.json())
      .then(response => {
          if(typeof response.status !== "undefined" && response.status === "OK"){
            setIncludePoints(response.includes)
            setWhyPoints(response.why)
            setFaqPoints(response.faq)
            setCategoryList(response.categories)
            if(window.$(".select2-selection").length > 0){
              window.$("select.select2-hidden-accessible").select2('destroy');
            }
            
            
            setTimeout(function(){
              window.$("#categories").select2().on("change", function (e) {
                getSubcategories()
              });
              window.$("#availablrfor").select2()

              getSubcategories()
            }, 500) 
            
          setTimeout(function(){
            callSorting("Includes", "area1")
            callSorting("Why", "area2")
            callSorting("FAQ", "area3")
          }, 2000) 
            // setTimeout(function(){
            //   window.$("#categories").select2();
            // }, 500)
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
          setPointLoading(false)
      })
      .catch(err => {
          // console.log(err);
      });
  }

  const changeField = (index, value, type, column) => {

      var temp2 = pointUpdateArray
      if(type === "Includes"){
        var temp = includesPoints
      }
      
      temp[index][column] = value
      setIncludePoints(temp)
      temp2[column] = value
      setPointUpdateArray(temp2)
      setRefresh(refresh === "No" ? "Yes" : "No")
  }

  const updatePoint = (e) => {
    e.preventDefault()
    formLoading(e.target)
    const formData = new FormData();
    
    formData.append('staff', JSON.stringify(AdminBasics()));
    formData.append('accecible', 1);
    formData.append('courseid', params.id);
    formData.append('type', "Course Include");
    formData.append('page_url', window.location.href);
    formData.append('title', window.$(e.target).find(".coursetitle").val());
    formData.append('id', window.$(e.target).find(".courseid").val());
    formData.append('file', window.$(e.target).find(".coursefile")[0].files[0]);
    // console.log(window.$(e.target).find(".coursefile")[0].files[0])
    fetch(apiurl+"api/update_course_point", {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
    })
    .then(response => response.json())
    .then(response => { 
        $(e.target).find(".loaderbody").fadeOut()
        if(typeof response.status !== "undefined" && response.status === "OK"){
            successModal("Success", response.message)
            getPoints()
        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
            if(typeof response.errors !== "undefined"){
                var i = 0
                $.each(response.errors, function(index, item){
                    setTimeout(function(){
                        errorModal("Oops Something Went Wrong", item[0])
                    }, i*1000)
                    i++
                });
            }else{
                errorModal("Oops Something Went Wrong", response.message)
            }
        }
    })
    .catch(err => {
        // console.log(err);
    });
    
  }

  const updatePointWithEditor = (e) => {
    e.preventDefault()
    formLoading(e.target)
    const formData = new FormData();
    
    formData.append('staff', JSON.stringify(AdminBasics()));
    formData.append('accecible', 1);
    formData.append('page_url', window.location.href);
    formData.append('courseid', params.id);
    formData.append('type', pointType);
    formData.append('title', pointTitle);
    formData.append('body', pointBody);
    formData.append('id', pointID);
    fetch(apiurl+"api/update_course_point", {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
    })
    .then(response => response.json())
    .then(response => { 
        $(e.target).find(".loaderbody").fadeOut()
        if(typeof response.status !== "undefined" && response.status === "OK"){
            successModal("Success", response.message)
            getPoints()
            
        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
            if(typeof response.errors !== "undefined"){
                var i = 0
                $.each(response.errors, function(index, item){
                    setTimeout(function(){
                        errorModal("Oops Something Went Wrong", item[0])
                    }, i*1000)
                    i++
                });
            }else{
                errorModal("Oops Something Went Wrong", response.message)
            }
        }
        window.$("#editPoint").fadeOut()
    })
    .catch(err => {
        // console.log(err);
    });
    
  }

  const changePointImage = (e) => {

    var file = e.target.files[0]
    if (file){
        let reader = new FileReader();
        reader.onload = function(event){
        $(e.target).parents("tr").find("img").attr('src', event.target.result);
      }
      reader.readAsDataURL(file);
    }
  }
  

  const removePoint = (id) => {
    fetch(apiurl+"api/remove_point", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                id: id,
                staff: AdminBasics(),
                accecible: 1
            })
        })
        .then(response => response.json())
        .then(response => {

            if(typeof response.status !== "undefined" && response.status === "OK"){
                successModal("Success", response.message)
                getPoints()
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            // console.log(err);
        });
}

  const removeDialog = (id) => {
    confirmAlert({
        title: 'Are You Sure?',
        message: "You won't be able to revert this back.",
        buttons: [
          {
            label: 'Yes',
            onClick: () => removePoint(id)
          },
          {
            label: 'No',
            onClick: () => console.log("Canceled")
          }
        ]
    });
}

const rePositionAll = (array, type) => {
    fetch(apiurl+"api/reposition_points", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                position_list: array,
                type:type,
                page_url: window.location.href,
                staff: JSON.stringify(AdminBasics())
            })
        })
        .then(response => response.json())
        .then(response => {
        })
        .catch(err => { });
}


const callSorting = (type, clas) => {
  window.$( ".droppable-"+clas ).sortable({
          stop: function(event, ui) {
              var array = [];
              window.$(event.target).find(".dragHandle").each(function (){
                  var a = $(this).attr("data-bind");
                  array.push(a);
              });

              rePositionAll(array, type)
      },
      connectWith: ".connected-sortable",
      stack: '.connected-sortable ul',
      handle: ".dragHandle",
  }).disableSelection();
  
}
const editPoint = (id, title, body, type) => {

  setPointID(id)
  setPointTitle(title)
  setPointBody(body)
  setPointType(type)
  
  setPointEditor(
    <CKEditor
        id={"ck-editor-text"}
        config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
        editor={ ClassicEditor }
        data={body}
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            setPointBody(data)
        } }
    />
  )

  setRefresh(refresh === "No" ? "Yes" : "No")
  $("#editPoint").fadeIn()
}


const fileInfo = (id, type) => {
  if(type !== "Question"){
      setPreviewLoading(true)
      fetch(apiurl+"api/file_information", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                  accecible: 1,
                  id: id,
                  page_url: window.location.href,
                  staff: JSON.stringify(AdminBasics())
              })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status === "OK"){

                  var resp = response.info;
                  if(resp.type === "Folder"){
                      resp.color = "#FCD462"
                  }else if(resp.type === "Question"){
                      resp.color = "#5691F3"
                  }else if(resp.type === "Video"){
                      resp.color = "#f46a6a"                       

                  }else if(resp.type === "Live"){
                      resp.color = "#f1b44c"
                  }else if(resp.type === "Document"){
                      resp.color = "#f46a6a"
                  }else if(resp.type === "Image"){
                      resp.color = "#50a5f1"
                  }else if(resp.type === "Youtube"){
                      resp.color = "#f46a6a"
                  }else if(resp.type === "Unknown"){
                      resp.color = "#74788d"
                  }
                  resp.tab = "English"
                  resp.id=id
                  setPreview(resp)
                  

              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              setPreviewLoading(false)
          })
          .catch(err => {
              setPreviewLoading(false)
          });
      }else{
          navigate("/admin/questions/"+params.id+"/"+params.p_id+"?qid="+id)
      }
}


const changePreviewTab = (tab) => {
  var prev = preview
  prev.tab = tab
  setPreview(prev)
  setRefresh(refresh === "No" ? "Yes" : "No")
}
const removeCompalsary = (col) => {

  fetch(apiurl+"api/removeCompalsary", {
    "method": "POST",
    "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "Authorization" : "Bearer "+getToken
    },
    "body": JSON.stringify({
            accecible: 1,
            col: col,
            courseid: props.course.otherid,
            page_url: window.location.href,
            staff: JSON.stringify(AdminBasics())
        })
    })
    .then(response => response.json())
    .then(response => {
      successModal("Success", response.message)
      props.basics()
    })
    .catch(err => { });

}

const copyUrl = (url) => {
  updatTrail("Copied the url <strong>(<a href='"+url+"' target='_BLANK' >"+url+"</a>)</strong>", "Course", "2")
  navigator.clipboard.writeText(url)
  successModal("Success", "Link Copied")
}

const changeBtnsValue = (col, val) => {
    var temp = btns
    temp[col] = val
    setBtns(temp)
    setRefresh(refresh === "No" ? "Yes" : "No")
}

const [pointEditor, setPointEditor] = useState("")
  useEffect(() => {
    if(a === 0)    {
      
      getPoints()
      setPointEditor(
        <CKEditor
            id={"ck-editor-text"}
            config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
            editor={ ClassicEditor }
            data={pointBody}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setPointBody(data)
            } }
        />
      )
      a++
    }
  }, [])

  return (
    <>
      <div className='col-md-12'>
        <form action='' method='POST' onSubmit={(e) => submitBasicInfo(e)}>
          <br />
            <div className='box no-border'>
              
              <div className='box-header no-right-padding no-left-padding'>
                <div className='col-md-12'>
                  <h3 className='box-title'>
                    Course Basic Information
                  </h3>
                </div>
              </div>
              
                <div className='box-body no-right-padding no-left-padding'>
                    <div className='col-md-3'>
                        <label>Course Title <span className='text-red'>*</span></label>
                        <input type={"text"} name="course_title" id='course_title' required placeholder='e.g Law CA:Inter' className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='col-md-6'>
                        <label>Course Sub Heading <span className='text-red'>*</span></label>
                        <textarea className='form-control' placeholder='e.g Example  sir has exclusively designed this course.' defaultValue={subheading} onChange={(e) => setSubheading(e.target.value)} ></textarea>
                        {/* <input type={"text"} name="course_subheading" id='course_subheading' required placeholder='e.g Example  sir has exclusively designed this course.' className='form-control' value={subheading} onChange={(e) => setSubheading(e.target.value)} /> */}
                    </div>
                    <div className='col-md-3'>
                        <label>Course Status </label>
                        <select name='' className='form-control' defaultValue={props.course.publisStatus ? courseStatus : 0} onChange={(e) => setCourseStatus(e.target.value)} >
                          
                          {
                            props.course.publisStatus &&
                            <option value={1}>Published</option>
                          }
                            <option value={0}>Un-Published</option>
                        </select>
                    </div>
                </div>
                <div className='box-body no-right-padding no-left-padding'>
                    <div className='col-md-3'>
                        <label>Instructor <span className='text-red'>*</span></label>
                        <input type={"text"} name="instructor" id='instructor' placeholder='e.g Example Sir' required className='form-control'  value={instructor} onChange={(e) => setInstructor(e.target.value)}/>
                    </div>
                    <div className='col-md-3'>
                        <label>Language <span className='text-red'>*</span></label>
                        <input type={"text"} name="language" id='language' placeholder='e.g English' required className='form-control'  value={language} onChange={(e) => setLanguage(e.target.value)}/>
                    </div>
                    <div className='col-md-3'>
                        <label>Course Tagline</label>
                        <input type={"text"} name="course_contain" id='course_contain' placeholder='e.g 1 Video Lacture, 5 Notes 2 Quiz' className='form-control'  value={contain} onChange={(e) => setContain(e.target.value)}/>
                    </div>
                    <div className='col-md-3'>
                        <label>Course Type</label>
                        {
                          (courseData === "" || courseData === null) ?
                          <select name='' className='form-control' defaultValue={courseType} onChange={(e) => setCourseType(e.target.value)} >
                              <option value={"Course"}>Course</option>
                              <option value={"Package"}>Package</option>
                          </select>
                          :
                          <input type={"text"} className={"form-control"} value={courseType} disabled />
                        }
                    </div>
                </div>
                <div className='box-body no-right-padding no-left-padding'>
                  <div className={'col-md-4'}>
                      <label>Available For</label>
                      <select className='form-control' multiple id='availablrfor' data-placeholder='Item Available For' defaultValue={(availablefor !== "") ? JSON.parse(availablefor) : ''} >
                        <option value={"Web"}>Web</option>
                        <option value={"Android"}>Android</option>
                        <option value={"IOS"}>IOS</option>
                        <option value={"Private"}>Private</option>
                      </select>
                    </div>

                  <div className={'col-md-8'}>
                    {
                      // pointLoading ? 
                      // <>
                      //     <Loading />
                      //   <br />
                      //   <br />
                      //   <br />
                      // </>
                      // :
                        <>
                        <label>Select Categories</label>
                          {
                            categories.length > 0 &&
                            <div className='div'>
                              
                                <select className="form-control" id='categories' multiple="multiple" style={{width:"100%"}} data-placeholder="Select Categories" defaultValue={JSON.parse(props.course.categories)} >
                                  {
                                    categories.map((item, index) => (
                                    <option value={item.categoryid.toString()} key={"cate"+index} >{item.categoryName}</option>
                                    ))
                                  }
                                </select>
                            </div>
                          }
                        </>
                    }
                    </div>
                </div>

                <div className='box-body no-left-padding no-right-padding'>
                  <div className={'col-md-12'}>
                      <label>Show In</label>
                      
                      <select className='form-control' value={ (showin === "" || showin === null) ? [] : JSON.parse(showin)} multiple id='subcategories' data-placeholder='Select Subcategories' onChange={() => console.log("Selected")} >
                        {
                          subcategories.length > 0 &&
                          subcategories.map((item, index) => {
                            return(
                              <option value={item.id} key={"sub"+index}>{item.name}</option>
                          )
                        })
                      }
                        
                      </select>
                    </div>
                </div>
                
                <div className='box-body no-right-padding no-left-padding'>
                  
                  {
                      courseType === "Course" &&
                      <div className={ 'col-md-2'}>
                          <label>Hours for package</label>
                          <input type={"number"} step={0.01} className='form-control' placeholder={"e.g 10"} value={packagehour} onChange={(e) => setPackagehour(e.target.value)} />
                      </div>
                  }

                  <div className={'col-md-2'}>
                      <label>
                        Books Included?
                      </label>
                      <select name='' className='form-control' defaultValue={booksIncluded} onChange={(e) => {
                        setBooksIncluded(e.target.value)
                      }} >
                        <option value=''>No</option>
                        <option value='1'>Yes</option>
                      </select>
                  </div>
                  
                  {
                    booksIncluded !== "" &&
                    
                    <>
                      <div className='col-md-2'>
                        <label>Length(CM) <span className='text-red'>*</span></label>
                        <input type={"number"} step={0.01} className='form-control' placeholder={"e.g 10"} required value={booksL} onChange={(e) => setBooksL(e.target.value)} />
                      </div>
                      <div className='col-md-2'>
                        <label>Breadth(CM) <span className='text-red'>*</span></label>
                        <input type={"number"} step={0.01} className='form-control' placeholder={"e.g 10"} required value={booksB} onChange={(e) => setBooksB(e.target.value)} />
                      </div>
                      <div className='col-md-2'>
                        <label>Height(CM) <span className='text-red'>*</span></label>
                        <input type={"number"} step={0.01} className='form-control' placeholder={"e.g 10"} required value={booksH} onChange={(e) => setBooksH(e.target.value)} />
                      </div>
                      <div className='col-md-2'>
                        <label>Weight(KG) <span className='text-red'>*</span></label>
                        <input type={"number"} step={0.01} className='form-control' placeholder={"e.g 1"} required value={booksW} onChange={(e) => setBooksW(e.target.value)} />
                      </div>
                    </>

                  }
                  
                  {
                    booksIncluded === "" &&
                    <>
                    <div className='col-md-2'>
                        <label>Show Chat</label>
                        <select className='form-control' value={showchat} onChange={(e) => setShowchat(e.target.value) } >
                          <option value={""}>No</option>
                          <option value={"1"}>Yes</option>
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label>Prefix </label>
                        <input type={"text"} step={0.01} className='form-control' placeholder={"e.g Eco"} value={prefix} onChange={(e) => setPrefix(e.target.value)} />
                    </div>
                    <div className='col-md-2'>
                        <label>Video Thumbnail</label>
                        <input type={"url"} className='form-control' defaultValue={youtube_thumbnail} onChange={(e) => setYoutube_thumbnail(e.target.value) } placeholder={"e.g https://assets.escholarsadmin.com/storage/assets/file-manager/1680938858_1679905144_Test.mp4"} />
                    </div>
                    
                    <div className='col-md-2'>
                        <label>Show Course Info On Banner?</label>
                        <select className='form-control' value={showbannerdata} onChange={(e) => setShowBannerData(e.target.value) } >
                          <option value={""}>No</option>
                          <option value={"1"}>Yes</option>
                        </select>
                    </div>
                    {
                      courseType === "Course" &&
                      <div className='col-md-2'>
                          <br />
                          <label>Show Content Information</label>
                          <select className='form-control' value={showContentInformation} onChange={(e) => setShowContentInformation(e.target.value)} >
                            <option value={""}>No</option>
                            <option value={"1"}>Yes</option>
                          </select>
                      </div>
                    }
                    </>
                  }
                </div>
                
                <div className='box-body no-right-padding no-left-padding'>
                      
                {
                    booksIncluded !== "" &&
                      <>
                      <div className='col-md-2'>
                          <label>Prefix </label>
                          <input type={"text"} step={0.01} className='form-control' placeholder={"e.g Eco"} value={prefix} onChange={(e) => setPrefix(e.target.value)} />
                      </div>
                      <div className='col-md-2'>
                          <label>Show Chat</label>
                          <select className='form-control' value={showchat} onChange={(e) => setShowchat(e.target.value) } >
                            <option value={""}>No</option>
                            <option value={"1"}>Yes</option>
                          </select>
                      </div>
                      <div className='col-md-2'>
                          <label>Video Thumbnail</label>
                          <input type={"url"} className='form-control' defaultValue={youtube_thumbnail} onChange={(e) => setYoutube_thumbnail(e.target.value) } placeholder={"e.g https://assets.escholarsadmin.com/storage/assets/file-manager/1680938858_1679905144_Test.mp4"} />
                      </div>
                      
                      <div className='col-md-2'>
                          <label>Show Course Info On Banner?</label>
                          <select className='form-control' value={showbannerdata} onChange={(e) => setShowBannerData(e.target.value) } >
                            <option value={""}>No</option>
                            <option value={"1"}>Yes</option>
                          </select>
                      </div>
                      {
                        courseType === "Course" &&
                        <div className='col-md-2'>
                            <label>Show Content Information</label>
                            <select className='form-control' value={showContentInformation} onChange={(e) => setShowContentInformation(e.target.value)} >
                              <option value={""}>No</option>
                              <option value={"1"}>Yes</option>
                            </select>
                        </div>
                      }
                      </>
                  }
                  
                  
                  </div>

                <div className='box-body no-right-padding no-left-padding'>
                <div className='col-md-2'>
                  <label>Hide All Plans & Buttons</label>
                  <select className='form-control' value={btns.hide_all_buttons} onChange={(e) => changeBtnsValue('hide_all_buttons', e.target.value)}>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <label>Hide Plans</label>
                  <select className='form-control' value={btns.hide_plans} onChange={(e) => changeBtnsValue('hide_plans', e.target.value)}>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <label>Hide Buy Now Button</label>
                  <select className='form-control' value={btns.hide_buy_now_button} onChange={(e) => changeBtnsValue('hide_buy_now_button', e.target.value)}>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <label>Hide Whatsapp Button</label>
                  <select className='form-control' value={btns.hide_whatsapp_button} onChange={(e) => changeBtnsValue('hide_whatsapp_button', e.target.value)}>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <label>Hide Notify Me Button</label>
                  <select className='form-control' value={btns.hide_notifyme_button} onChange={(e) => changeBtnsValue('hide_notifyme_button', e.target.value)}>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <label>Hide Redirect Button</label>
                  <select className='form-control' value={btns.hide_redirect_button} onChange={(e) => changeBtnsValue('hide_redirect_button', e.target.value)}>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                  </select>
                </div>
                </div>
                <div className='box-body '>
                  <div className='col-md-12 no-padding'>
                    <label>Redirect To</label>
                    <input type='url' className='form-control' value={btns.redirect_url} onChange={(e) => changeBtnsValue('redirect_url', e.target.value)} placeholder='e.g https://google.com' />
                  </div>

                </div>
                <div className='box-body no-right-padding no-left-padding'>
                  <div className='col-md-12'>
                    <span className='text-blue'>
                      <strong>Live URL: </strong><a target='_BLANK' href={'https://www.escholars.in/p/'+props.course.slug+'/'+props.course.otherid}>https://www.escholars.in/p/{props.course.slug}/{props.course.otherid}</a> &nbsp;&nbsp;
                        <span className='badge bg-blue cursor-pointer' title='Copy Link' onClick={(e) => copyUrl('https://www.escholars.in/p/'+props.course.slug+'/'+props.course.otherid)} >
                          <i className='fa fa-copy'></i>
                        </span>
                    </span>
                  </div>
                </div>
                
                <div className='box-body no-right-padding no-left-padding'>
                  <div className='col-md-12'>
                    <span className='text-blue'>
                    <strong>Preview URL:</strong> <a target='_BLANK' href={'https://www.escholars.in/p/'+props.course.slug+'/'+props.course.otherid+'?preview=true'}>https://www.escholars.in/p/{props.course.slug}/{props.course.otherid}?preview=true</a> &nbsp;&nbsp;
                        <span className='badge bg-blue cursor-pointer' title='Copy Link' onClick={(e) => copyUrl('https://www.escholars.in/p/'+props.course.slug+'/'+props.course.otherid+'?preview=true')} >
                          <i className='fa fa-copy'></i>
                        </span>
                    </span>
                  </div>
                </div>
                <div className='box-footer no-right-padding no-left-padding'>
                  <div className='col-md-12'>
                    <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={"Update"} />
                  </div>
                </div>
            </div>
        </form>
        
        
        </div>

        <div className='col-md-12'>
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
                Course Basic Information
              </h3>
            </div>
            {
              !pointLoading &&
                <div className='box-body'>
                  <CKEditor
                      id={"ck-editor-text"}
                      config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
                      editor={ ClassicEditor }
                      data={topeditor}
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setTopBanner(data)
                      } }
                  />
                </div>
            }
            <div className='box-footer'>
              <button className='btn btn-primary btn-flat btn-block' onClick={() => updateAdditionalInformation("topeditor", topeditor)}>
                <i className='fa fa-save'></i>&nbsp;Update Basic Information
              </button>
            </div>
          </div>
        </div>
        

        <div className='col-md-6'>
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
              Compulsory Video
              </h3>
              {
                props.course.compalsary_video !== null &&
                <span onClick={() => removeCompalsary('compalsary_video')} className="pull-right cursor-pointer" >
                  <i className='fa fa-times'></i>
                </span>
              }
            </div>
            <div className='box-body'>
              {
                props.course.compalsary_video === null ?
                  <div className='importVideo' onClick={(e) => {
                    setImportFileType("Video,Youtube")
                    
                    window.$("#importFrame").fadeIn()
                  }}>
                      <i className='fa fa-upload'></i>
                      Import Video
                  </div>
                  :
                  <div className='playVideo cursor-pointer' title='Play' onClick={() => fileInfo(props.course.compalsary_video, "video")} >
                    <i className='fa fa-play'></i>
                      Play Video
                  </div>
              }
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
              Compulsory Document
              </h3>
              {
                props.course.compalsary_document !== null &&
                <span onClick={() => removeCompalsary('compalsary_document')} className="pull-right cursor-pointer" >
                  <i className='fa fa-times'></i>
                </span>
              }
            </div>
            
            <div className='box-body'>
              {
                props.course.compalsary_document === null ?
                  <div className='importVideo' onClick={(e) => {
                    setImportFileType("Document")
                    
                    window.$("#importFrame").fadeIn()
                  }}>
                      <i className='fa fa-upload'></i>
                      Import Document
                  </div>
                  :
                  <div className='playVideo cursor-pointer' title='Play' onClick={() => fileInfo(props.course.compalsary_document, "document")} >
                    <i className='fa fa-file'></i>
                      View Document
                  </div>
              }
            </div>
          </div>
        </div>
        
            <div className='renameDialogBG' id="importFrame" style={{display:"none"}}>
              <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Import Item(s)
                        <span className='pull-right closePopup' onClick={() => {
                            props.basics()
                            window.$('#importFrame').fadeOut()
                        }} title="Close">
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='iframeCon'>
                    {
                        importFileType !== "" &&
                        <iframe title='Import' src={"/admin/import/2/2?importType="+importFileType+"&courseid="+props.course.id+"&folderid=0&aboveBelow=Above&importPosition=1&compalsary=1"} className="importIframe"></iframe>
                    }
                </div>
              </div>
            </div>

        <div className='col-md-12'>
        <div className='box no-border'>
          <div className='box-header no-right-padding no-left-padding'>
            <div className='col-md-12'>
              <h3 className='box-title'>
                This Course Includes
              </h3>

              <button className='btn btn-primary btn-flat pull-right' onClick={() => addPoint("Includes")} title={"Add Course Includes Point(s)"} >
                <i className='fa fa-plus'></i>
              </button>
              
              <input type={"number"} name={"point"} className="form-control pull-right" style={{width:"100px", marginRight:"5px"}} placeholder="e.g 1" value={addpoint} onChange={(e) => setAddpoint(e.target.value)} />

            </div>
          </div>
          <div className='box-body'>
                {
                  pointLoading ?
                  <>
                    <br /><br /><br />
                      <Loading />
                    <br /><br /><br />
                  </>
                  :
                  <>
                    {
                      includesPoints.length < 1 ? 
                      <p className='text-center'>No Records Found</p>
                      :
                      <table className='table table-bordered table-hover'>
                        <thead>
                          <tr>
                            <th className='text-center' style={{width:"100px"}}>Drag</th>
                            <th>Title</th>
                            <th>Icon</th>
                            <th className='text-right' style={{width:"100px"}} >Action</th>
                          </tr>
                        </thead>
                        <tbody className='connected-sortable droppable-area1' >
                          {
                          includesPoints.map((item, index) => (
                            <tr key={"includes"+index} className="draggable-item" data-bind={item.id}>
                                <td className='text-center text-center dragHandle' data-bind={item.id}>
                                    <i className='fa fa-arrows-v'></i>
                                </td>
                                <td>
                                    <input type={"text"} name="title" placeholder='e.g Lorem ipsum represents a long-held tradition for designers' value={item.title} onChange={(e) => changeField(index, e.target.value, "Includes", "title") } className='form-control' />
                                </td>
                                <td style={{width:"100px"}}>
                                    <img alt={'includesPoints '+index} src={ (item.icon === "" || item.icon === null) ? process.env.PUBLIC_URL+'/assets/assets/images/no-image.png' : base_config.assets_url+item.icon} style={{ width:"60px", objectFit:"contain",cursor:"pointer" }} onClick={(e) => window.$("#point"+item.id).click()} />
                                </td>
                                <td className='text-right'>
                                  <form action='' method='POST' onSubmit={(e) => updatePoint(e)}>
                                    <input type={"hidden"} name={"name"} className={"coursetitle"} value={item.title} />
                                    <input type={"hidden"} name={"id"} className={"courseid"} value={item.id} />
                                    <input type={"file"} style={{display:"none"}} className={"coursefile"} id={"point"+item.id} accept={".png,.jpg,.jpeg,.svg,.gif"} onChange={(e) => changePointImage(e)}/>
                                    <button className='btn btn-primary btn-flat' >
                                      <i className='fa fa-save'></i>
                                    </button>
                                    &nbsp;
                                    <button className='btn btn-danger btn-flat' type='button' onClick={() => removeDialog(item.id)}>
                                      <i className='fa fa-trash'></i>
                                    </button>
                                  </form>
                                </td>
                            </tr>
                          ))
                          }
                      </tbody>
                    </table>
                    }
                  </>
                }

          </div>
        </div>



        <div className='box no-border'>
          <div className='box-header no-right-padding no-left-padding'>
            <div className='col-md-12'>
              <h3 className='box-title'>
                Why Choose This Course
              </h3>

              <button className='btn btn-primary btn-flat pull-right' onClick={() => addPoint("Why")} title={"Add Course Why Point(s)"} >
                <i className='fa fa-plus'></i>
              </button>
              
              <input type={"number"} name={"point"} className="form-control pull-right" style={{width:"100px", marginRight:"5px"}} placeholder="e.g 1" value={addpoint} onChange={(e) => setAddpoint(e.target.value)} />

            </div>
          </div>
          <div className='box-body'>
                {
                  pointLoading ?
                  <>
                    <br /><br /><br />
                      <Loading />
                    <br /><br /><br />
                  </>
                  :
                  <>
                    {
                      whyPoints.length < 1 ? 
                      <p className='text-center'>No Records Found</p>
                      :
                      <table className='table table-bordered table-hover'>
                        <thead>
                          <tr>
                            <th className='text-center' style={{width:"100px"}}>Drag</th>
                            <th>Title</th>
                            <th className='text-right' style={{width:"100px"}} >Action</th>
                          </tr>
                        </thead>
                        <tbody className='connected-sortable droppable-area2'  >
                          {
                          whyPoints.map((item, index) => (
                            <tr key={"why"+index} className="draggable-item" data-bind={item.id}>
                                <td className='text-center dragHandle' data-bind={item.id}>
                                    <i className='fa fa-arrows-v'></i>
                                </td>
                                <td>
                                    {(item.title === "" || item.title === null) ? 'No Title Available' : item.title} 
                                </td>
                                <td className='text-right'>
                                    <button className='btn btn-primary btn-flat' onClick={(e) => editPoint(item.id, item.title, item.body, item.type)}>
                                      <i className='fa fa-pencil'></i>
                                    </button>
                                    &nbsp;
                                    <button className='btn btn-danger btn-flat' type='button' onClick={() => removeDialog(item.id)}>
                                      <i className='fa fa-trash'></i>
                                    </button>
                                </td>
                            </tr>
                          ))
                          }
                      </tbody>
                    </table>
                    }
                  </>
                }

          </div>
        </div>



        <div className='box no-border'>
          <div className='box-header no-right-padding no-left-padding'>
            <div className='col-md-12'>
              <h3 className='box-title'>
                FAQ
              </h3>

              <button className='btn btn-primary btn-flat pull-right' onClick={() => addPoint("Faq")} title={"Add Course FAQ Point(s)"} >
                <i className='fa fa-plus'></i>
              </button>
              
              <input type={"number"} name={"point"} className="form-control pull-right" style={{width:"100px", marginRight:"5px"}} placeholder="e.g 1" value={addpoint} onChange={(e) => setAddpoint(e.target.value)} />

            </div>
          </div>
          <div className='box-body'>
                {
                  pointLoading ?
                  <>
                    <br /><br /><br />
                      <Loading />
                    <br /><br /><br />
                  </>
                  :
                  <>
                    {
                      faqPoints.length < 1 ? 
                      <p className='text-center'>No Records Found</p>
                      :
                      <table className='table table-bordered table-hover'>
                        <thead>
                          <tr>
                            <th className='text-center' style={{width:"100px"}}>Drag</th>
                            <th>Title</th>
                            <th className='text-right' style={{width:"100px"}} >Action</th>
                          </tr>
                        </thead>
                        <tbody className='connected-sortable droppable-area3'  >
                          {
                          faqPoints.map((item, index) => (
                            <tr key={"faq"+index} className="draggable-item" data-bind={item.id}>
                                <td className='text-center dragHandle' data-bind={item.id}>
                                    <i className='fa fa-arrows-v'></i>
                                </td>
                                <td>
                                    {(item.title === "" || item.title === null) ? 'No Title Available' : item.title} 
                                </td>
                                <td className='text-right'>
                                    <button className='btn btn-primary btn-flat' onClick={(e) => editPoint(item.id, item.title, item.body, item.type)}>
                                      <i className='fa fa-pencil'></i>
                                    </button>
                                    &nbsp;
                                    <button className='btn btn-danger btn-flat' type='button' onClick={() => removeDialog(item.id)}>
                                      <i className='fa fa-trash'></i>
                                    </button>
                                </td>
                            </tr>
                          ))
                          }
                      </tbody>
                    </table>
                    }
                  </>
                }

          </div>
        </div>


              <div className='renameDialogBG' id='editPoint' style={{display:"none"}}>
                  <div className='renameBody bigRenameBody'>
                      <div className='renameHeading'>
                          <p className='text-left'>
                              Edit
                              <span className='pull-right closePopup' onClick={() => window.$('#editPoint').fadeOut()} title="Close">
                                <i className='fa fa-times'></i>
                              </span>
                          </p>
                      </div>
                      
                      <form action='' method='POST' onSubmit={(e) => updatePointWithEditor(e)}>
                        <div className='renameInputCon'>
                            <input type={"text"} name={"name"} className="renameInput" required placeholder='e.g CA Foundation 9.0' value={pointTitle} onChange={(e) => setPointTitle(e.target.value)} />
                        </div>
                        <br />
                        <div className='renameInputCon'>
                              {pointEditor}
                        </div>
                      
                      <div className='renameFooter'>
                          <button className='btn btn-primary btn-flat pull-right' style={{marginTop:"10px"}}  >
                              <i className='fa fa-save'></i>&nbsp;
                              Save
                          </button>
                      </div>
                        <br />
                      </form>
                  </div>
              </div>
      </div>

      <div className='col-md-12'>
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
                Course Additional Information
              </h3>
            </div>
            {
              
              !pointLoading &&
              <div className='box-body'>
                <CKEditor
                    id={"ck-editor-text"}
                    config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
                    editor={ ClassicEditor }
                    data={bottomeditor}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setBottomBanner(data)
                    } }
                />
              </div>
            }
            <div className='box-footer'>
              <button className='btn btn-primary btn-flat btn-block' onClick={() => updateAdditionalInformation("bottomeditor", bottomeditor)}>
                <i className='fa fa-save'></i>&nbsp;Update Additional Information
              </button>
            </div>
          </div>
        </div>



      {
                                preview !== "" && !previewLoading &&
                        <div className='renameDialogBG' id="renameDialogBG">
                            <div className='renameBody'>
                                <div className='previewPanel'>
                                    <div className='previewContent' style={{backgroundColor:preview.color+"17"}}>
                                        <br />
                                        
                                        <span className='pull-right previewClose' style={{cursor:'pointer'}} title={"Close Preview"} onClick={() => setPreview("")}>
                                            <FeatherIcon icon={"x"} />
                                        </span>

                                        {
                                            preview.type !== "Image" && preview.type !== "Video" && preview.type !== "Youtube" &&
                                            <div className='chenterCircle forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+preview.thumb+'.png'} alt={"Preview"} />
                                            </div>
                                        }
                                        
                                        {
                                            preview.type === "Image" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={preview.path} alt="Preview" />
                                            </div>
                                        }
                                        {
                                            preview.type === "Video" && 
                                            <>
                                            <div className='centerPreview' style={{backgroundColor:preview.color+"17"}}>
                                                {
                                                    preview.tab === "English" ?
                                                    <>
                                                    {
                                                        ((preview.encrypted_file !== "" && preview.encrypted_file !== null) || (preview.newencsrc !== null)) &&
                                                        <>
                                                            {/* <Video id={preview.id} type={"videoUrl"} /> */}
                                                            <Video id={preview.id} type={"encrypt"} newencsrc={preview.newencsrc} />
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {
                                                        preview.encrypted_hindi_file !== "" && preview.encrypted_hindi_file !== null &&
                                                        // <Video id={preview.id} type={"hindi_videoUrl"} />
                                                        <Video id={preview.id} type={"hindi_encrypt"} />
                                                    }
                                                    </>
                                                }
                                            </div>
                                            <div className='changeTabBtns'>
                                                <button className={ preview.tab !== "English" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='English' onClick={() => changePreviewTab("English")}>
                                                    English
                                                </button>
                                                <button className={ preview.tab !== "Hindi" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='Hindi' onClick={() => changePreviewTab("Hindi")}>
                                                    Hindi
                                                </button>
                                            </div>
                                            </>
                                        }
                                        {
                                            preview.type === "Youtube" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <iframe src={preview.youtubeEmbedUrl} style={{border:"none"}}></iframe>
                                            </div>
                                        }
                                        {/* <ul>
                                            <li>
                                                <strong>File Name: </strong> {preview.name}
                                            </li>
                                        </ul> */}
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
}

    </>
  )
}
