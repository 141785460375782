import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
const cookies = new Cookies();

export default function Expirycourse(props) {
    
  const params = useParams()
  const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [showLearnersList, setShowLearnersList] = useState(false)
  const [showLearnersListLoading, setShowLearnersListLoading] = useState(false)
  const [learnersList, setLearnersList] = useState([])
  const [segment, setSegment] = useState(1)
  const[refresh, setRefresh] = useState("No")
  const [notifications, setNotifications] = useState({
        "course_expiring_in" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
        },
        "course_expired" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
        },
        "initiatednotification" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
        },
    })

    const setColNotification = (data, main, col) => {
        var temp = notifications
        temp[main][col] = data
        setNotifications(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
      
  

    const saveNotificationInCourse = (e) => {
        e.preventDefault()
        warningModal("Loading", "Saving Notification Please Wait...")
        var temp2 = notifications
        temp2['course_expiring_in']['sendto'] = window.$("#course_expiring_in").val()
        temp2['course_expired']['sendto'] = window.$("#course_expired").val()
        temp2['initiatednotification']['sendto'] = window.$("#initiatednotification").val()
        fetch(apiurl + "api/saveNotificationInCourse", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                course_id: params.id,
                notification: temp2,
            })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    props.basics()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            }) 
    }


  var a = 0
  useEffect(() => {
    if(a === 0){
        // getList()
        
            var temp = notifications
            temp['course_expiring_in'] = (props.course.notification !== null) ? JSON.parse(props.course.notification) : temp['course_expiring_in']
            temp['course_expired'] = (props.course.expirednotification !== null) ? JSON.parse(props.course.expirednotification) : temp['course_expired']
            temp['initiatednotification'] = (props.course.initiatednotification !== null) ? JSON.parse(props.course.initiatednotification) : temp['initiatednotification']
            setNotifications(temp)
        
        
        if(window.$(".select2-selection").length > 0){
            window.$("select.select2-hidden-accessible").select2('destroy');
        }
        setTimeout(function(){
            setLoading(false)
            setTimeout(function(){
                
                window.$(".select22").each(function(){
                    var id = window.$(this).attr("id")
                    window.$("#"+id).select2()
                });

            }, 500) 
        }, 500)
        a++
    }
  }, [])
  return (
    <>
    <div className='col-md-4'>
        <br />
        <div className='box no-border'>
            <div className='box-header no-border'>
                <h3 className='box-title'>
                    Course Expiring in 7 Days Notification
                </h3><br />
                <small style={{color:"red"}} >{'{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{coursename}}'}</small>
            </div>
            
            <form action='' method='POST' onSubmit={(e) => saveNotificationInCourse(e)} >
                <div className='box-body' style={{position:"relative"}} >
                    <div className='col-md-6 no-left-padding'>
                        <label>Notification Title<span className='text-red' >*</span></label>
                        <input type={"text"} className="form-control" required value={notifications['course_expiring_in']["title"]} onChange={(e) => setColNotification(e.target.value, 'course_expiring_in', "title") } placeholder={"Enter Your Notification Title For : " + 'course_expiring_in'.replace(/_/g, " ")} />
                    </div>
                    <div className='col-md-6 no-right-padding'>
                        <label>URL (If Any)</label>
                        <input type={"url"} className="form-control" value={(notifications['course_expiring_in']["link"] === null) ? "" : notifications['course_expiring_in']["link"]} onChange={(e) => setColNotification(e.target.value, 'course_expiring_in', "link") } placeholder={"Enter Your Notification Link For : " + 'course_expiring_in'.replace(/_/g, " ")} />
                    </div>
                </div>
                <div className='box-body' style={{position:"relative"}} >
                <label>Notification Body<span className='text-red' >*</span></label>
                {
                    loading ? 
                    <>
                    <br /> Loading...
                    </>
                    :
                    <CKEditor 
                    editor={ ClassicEditor } 
                    
                    config={
                        { 
                        simpleUpload: { 
                            uploadUrl: 'api/ckeditor/image_upload' 
                        }, 
                        }

                    } 
                    data={notifications['course_expiring_in']["body"]} onChange={ ( event, editor ) => { const data = editor.getData(); setColNotification(data, 'course_expiring_in', "body") } } />
                }
                </div>
                <div className='box-body' style={{position:"relative"}} >
                    <label>Notification Body (APP)<span className='text-red' >*</span></label>
                    <textarea className="form-control" required onChange={(e) => setColNotification(e.target.value, 'course_expiring_in', "app_body") } placeholder={"Enter Your Notification App Body For : " + 'course_expiring_in'.replace(/_/g, " ")} defaultValue={notifications['course_expiring_in']["app_body"]}></textarea>
                    
                </div>
                
                <div className='box-body'>
                    <label>Send On</label>
                    <select className='form-control select22' multiple id={'course_expiring_in'} value={notifications['course_expiring_in']["sendto"]} onChange={(e) => setColNotification(e.target.value, 'course_expiring_in', "sendto") } data-placeholder={"Select Notification Send Via for : " + 'course_expiring_in'.replace(/_/g, " ")}  >
                        <option value={"Web"}>Web Push</option>
                        <option value={"App"}>App Push</option>
                        <option value={"In App"}>In App</option>
                        <option value={"Whatsapp"}>Whatsapp</option>
                    </select>
                </div>

                <div className='box-footer'>
                    <button className='btn btn-primary btn-flat btn-block' style={{textTransform:"capitalize"}} >
                        Save Notification
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div className='col-md-4'>
        <br />
        <div className='box no-border'>
            <div className='box-header no-border'>
                <h3 className='box-title'>
                    Course Expired
                </h3><br />
                <small style={{color:"red"}} >{'{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{coursename}}, {{promolist}}'}</small>
            </div>
            
            <form action='' method='POST' onSubmit={(e) => saveNotificationInCourse(e)} >
                <div className='box-body' style={{position:"relative"}} >
                    <div className='col-md-6 no-left-padding'>
                        <label>Notification Title<span className='text-red' >*</span></label>
                        <input type={"text"} className="form-control" required value={notifications['course_expired']["title"]} onChange={(e) => setColNotification(e.target.value, 'course_expired', "title") } placeholder={"Enter Your Notification Title For : " + 'course_expired'.replace(/_/g, " ")} />
                    </div>
                    <div className='col-md-6 no-right-padding'>
                        <label>URL (If Any)</label>
                        <input type={"url"} className="form-control" value={(notifications['course_expired']["link"] === null) ? "" : notifications['course_expired']["link"]} onChange={(e) => setColNotification(e.target.value, 'course_expired', "link") } placeholder={"Enter Your Notification Link For : " + 'course_expired'.replace(/_/g, " ")} />
                    </div>
                </div>
                <div className='box-body' style={{position:"relative"}} >
                <label>Notification Body<span className='text-red' >*</span></label>
                {
                    loading ? 
                    <>
                    <br /> Loading...
                    </>
                    :
                    <CKEditor 
                    editor={ ClassicEditor } 
                    
                    config={
                        { 
                        simpleUpload: { 
                            uploadUrl: 'api/ckeditor/image_upload' 
                        }, 
                        }

                    } 
                    data={notifications['course_expired']["body"]} onChange={ ( event, editor ) => { const data = editor.getData(); setColNotification(data, 'course_expired', "body") } } />
                }
                </div>
                <div className='box-body' style={{position:"relative"}} >
                    <label>Notification Body (APP)<span className='text-red' >*</span></label>
                    <textarea className="form-control" required onChange={(e) => setColNotification(e.target.value, 'course_expired', "app_body") } placeholder={"Enter Your Notification App Body For : " + 'course_expired'.replace(/_/g, " ")} defaultValue={notifications['course_expired']["app_body"]}></textarea>
                    
                </div>
                
                <div className='box-body'>
                    <label>Send On</label>
                    <select className='form-control select22' multiple id={'course_expired'} value={notifications['course_expired']["sendto"]} onChange={(e) => setColNotification(e.target.value, 'course_expired', "sendto") } data-placeholder={"Select Notification Send Via for : " + 'course_expired'.replace(/_/g, " ")}  >
                        <option value={"Web"}>Web Push</option>
                        <option value={"App"}>App Push</option>
                        <option value={"In App"}>In App</option>
                        <option value={"Whatsapp"}>Whatsapp</option>
                    </select>
                </div>

                <div className='box-footer'>
                    <button className='btn btn-primary btn-flat btn-block' style={{textTransform:"capitalize"}} >
                        Save Notification
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div className='col-md-4'>
        <br />
        <div className='box no-border'>
            <div className='box-header no-border'>
                <h3 className='box-title'>
                    After 7 Days of Payment Initiated
                </h3><br />
                <small style={{color:"red"}} >{'{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{coursename}}, {{promolist}}'}</small>
            </div>
            
            <form action='' method='POST' onSubmit={(e) => saveNotificationInCourse(e)} >
                <div className='box-body' style={{position:"relative"}} >
                    <div className='col-md-6 no-left-padding'>
                        <label>Notification Title<span className='text-red' >*</span></label>
                        <input type={"text"} className="form-control" required value={notifications['initiatednotification']["title"]} onChange={(e) => setColNotification(e.target.value, 'initiatednotification', "title") } placeholder={"Enter Your Notification Title For : " + 'initiatednotification'.replace(/_/g, " ")} />
                    </div>
                    <div className='col-md-6 no-right-padding'>
                        <label>URL (If Any)</label>
                        <input type={"url"} className="form-control" value={(notifications['initiatednotification']["link"] === null) ? "" : notifications['initiatednotification']["link"]} onChange={(e) => setColNotification(e.target.value, 'initiatednotification', "link") } placeholder={"Enter Your Notification Link For : " + 'initiatednotification'.replace(/_/g, " ")} />
                    </div>
                </div>
                <div className='box-body' style={{position:"relative"}} >
                <label>Notification Body<span className='text-red' >*</span></label>
                {
                    loading ? 
                    <>
                    <br /> Loading...
                    </>
                    :
                    <CKEditor 
                    editor={ ClassicEditor } 
                    
                    config={
                        { 
                        simpleUpload: { 
                            uploadUrl: 'api/ckeditor/image_upload' 
                        }, 
                        }

                    } 
                    data={notifications['initiatednotification']["body"]} onChange={ ( event, editor ) => { const data = editor.getData(); setColNotification(data, 'initiatednotification', "body") } } />
                }
                </div>
                <div className='box-body' style={{position:"relative"}} >
                    <label>Notification Body (APP)<span className='text-red' >*</span></label>
                    <textarea className="form-control" required onChange={(e) => setColNotification(e.target.value, 'initiatednotification', "app_body") } placeholder={"Enter Your Notification App Body For : " + 'initiatednotification'.replace(/_/g, " ")} defaultValue={notifications['initiatednotification']["app_body"]}></textarea>
                    
                </div>
                
                <div className='box-body'>
                    <label>Send On</label>
                    <select className='form-control select22' multiple id={'initiatednotification'} value={notifications['initiatednotification']["sendto"]} onChange={(e) => setColNotification(e.target.value, 'initiatednotification', "sendto") } data-placeholder={"Select Notification Send Via for : " + 'initiatednotification'.replace(/_/g, " ")}  >
                        <option value={"Web"}>Web Push</option>
                        <option value={"App"}>App Push</option>
                        <option value={"In App"}>In App</option>
                        <option value={"Whatsapp"}>Whatsapp</option>
                    </select>
                </div>

                <div className='box-footer'>
                    <button className='btn btn-primary btn-flat btn-block' style={{textTransform:"capitalize"}} >
                        Save Notification
                    </button>
                </div>
            </form>
        </div>
    </div>
    </>
  )
}
