import React, { useState, useEffect, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Adminprops from '../../VelidateWebsite/Adminprops'
import Cookies from 'universal-cookie'
import CommonFunc from '../includes/Common'
import Notfound from '../includes/Notfound'
import FeatherIcon from 'feather-icons-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading'
import Dropzone from 'react-dropzone'
import axios, {CancelToken, isCancel} from 'axios';


const cookies = new Cookies();

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
export default function Importquestions() {

      
  // var a = 0
  // useEffect(() => {
  //   if(a === 0){  
  //     setTimeout(function(){
  //       window.location.href = "#"
  //       a++
  //     }, 1000)
  //   }
  // }, [])

  const Question = (props) => {



    var rules = {}
    rules["Objective"] = {
          "question": true,
          "option1": true,
          "option2": true,
          "option3": true,
          "option4": true,
          "option5": true,
          "option6": true,
          "explanation": true,
          "answertype": true,
          "answer": true,
          "answerplaceholder": "e.g 1",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Subjective"] = {
          "question": true,
          "option1": false,
          "option2": false,
          "option3": false,
          "option4": false,
          "option5": false,
          "option6": false,
          "explanation": true,
          "answertype": false,
          "answer": false,
          "answerplaceholder": "e.g Agra",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Fill_In_The_Blanks"] = {
          "question": true,
          "option1": false,
          "option2": false,
          "option3": false,
          "option4": false,
          "option5": false,
          "option6": false,
          "explanation": true,
          "answertype": false,
          "answer": true,
          "answerplaceholder": "e.g Agra",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Match_The_Following"] = {
          "question": true,
          "option1": true,
          "option2": true,
          "option3": true,
          "option4": true,
          "option5": true,
          "option6": true,
          "explanation": true,
          "answertype": true,
          "answer": true,
          "answerplaceholder": "e.g 1",
          "answermultiplaceholder": "e.g 2,3",
        }
      rules["True_And_False"] = {
            "question": true,
            "option1": false,
            "option2": false,
            "option3": false,
            "option4": false,
            "option5": false,
            "option6": false,
            "explanation": true,
            "answertype": false,
            "answer": true,
            "answerplaceholder": "e.g 1",
            "answermultiplaceholder": "e.g 2,3",
          }

    const cancelFileUpload = useRef(null)
    const query = useQuery();
    const qid = query.get("qid");
    

    const navigate = useNavigate()
    const params = useParams()
    
    var importing = ( typeof query.get("importType") !== "undefined" && query.get("importType") !== null) ? "?importType="+query.get("importType") : "";
    importing += ( typeof query.get("courseid") !== "undefined" && query.get("courseid") !== null) ? "&courseid="+query.get("courseid") : "";
    importing += ( typeof query.get("folderid") !== "undefined" && query.get("folderid") !== null) ? "&folderid="+query.get("folderid") : "";
    importing += ( typeof query.get("aboveBelow") !== "undefined" && query.get("aboveBelow") !== null) ? "&aboveBelow="+query.get("aboveBelow") : "";
    importing += ( typeof query.get("importPosition") !== "undefined" && query.get("importPosition") !== null) ? "&importPosition="+query.get("importPosition") : "";
    importing += ( typeof query.get("quizid") !== "undefined" && query.get("quizid") !== null) ? "&quizid="+query.get("quizid") : "";
    const importType = query.get("importType");
    const courseid = query.get("courseid");
    const folderid = query.get("folderid");
    const aboveBelow = query.get("aboveBelow");
    const importPosition = query.get("importPosition");
    const quizid = query.get("quizid");


    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail} = CommonFunc()
    const[refresh, setRefresh] = useState("No") 
    const[tab, setTab] = useState("Objective")
    const[p_id, setPid] = useState((typeof params.p_id) !== "undefined" ? params.p_id : 0);
    const[main_id, setMainId] = useState((typeof params.id) !== "undefined" ? params.id : 0);
    const[pathList, setPathList] = useState([])
    const[filesList, setFilesList] = useState([])
    const[loading, setLoading] = useState(false)
    const[comprehensive, setComprehensive] = useState(false)
    
    const[essay, setEssay] = useState("")
    const[editID, setEditID] = useState("")
    const[question, setQuestion] = useState("")
    const[option1, setOption1] = useState("")
    const[option2, setOption2] = useState("")
    const[option3, setOption3] = useState("")
    const[option4, setOption4] = useState("")
    const[option5, setOption5] = useState("")
    const[option6, setOption6] = useState("")
    const[explanation, setExplanation] = useState("")
    const[posMarks, setPosMarks] = useState("")
    const[negMarks, setNegMarks] = useState("")
    const[answerType, setAnswerType] = useState("")
    const[correct, setCorrectAnswer] = useState("")
    const[plainName, setPlainName] = useState("")
    const[subject, setSubject] = useState("")
    const[topic, setTopic] = useState("")
    const[tags, setTags] = useState("")
    const[position, setposition] = useState(0)
    const[viewQues, setViewQuestion] = useState(false)


    const changeFolder = (mainid, pid) => {
      setPid(pid)
      setMainId(mainid)
      navigate("/admin/questions/"+mainid+"/"+pid)
  }

  const cleanOptions = () => {
    setEssay("")
    setQuestion("")
    setOption1("")
    setOption2("")
    setOption3("")
    setOption4("")
    setOption5("")
    setOption6("")
    setExplanation("")
    setPosMarks("")
    setNegMarks("")
    setAnswerType("")
    setCorrectAnswer("")
    setSubject("")
    setTopic("")
    setTags("")
    setEditID("")
  }

  const changeTab = (tab) => {
    setViewQuestion(false)
    setLoading(true)
    
    setTab(tab)
    cleanOptions()
    setTimeout(function(){
      setLoading(false)
    }, 3000)
  }

  const addQuestion = () => {

    const formData = new FormData();

    if(question === ""){
      errorModal("Oops!", "Question Cannot Be Null")
    }else{
      setLoading(true)
      formData.append('id', editID);
      formData.append('p_id', p_id);
      formData.append('main_id', 6);
      formData.append('type', "Question");
      formData.append('question_type', tab);
      
      formData.append('essay', (comprehensive) ? essay : "");

      formData.append('question', question);
      formData.append('option1', option1);
      formData.append('option2', option2);
      if(tab === "True_And_False"){
        formData.append('option1', "True");
        formData.append('option2', "False");
      }
      formData.append('option3', option3);
      formData.append('option4', option4);
      formData.append('option5', option5);
      formData.append('option6', option6);
      formData.append('explanation', explanation);
      formData.append('posMarks', posMarks);
      formData.append('negMarks', negMarks);
      formData.append('answerType', answerType);
      formData.append('correct', correct);
      formData.append('plainName', plainName);
      formData.append('subject', subject);
      formData.append('topic', topic);
      formData.append('tags', tags);
      
      formData.append('staff', JSON.stringify(AdminBasics()));
      formData.append('accecible', 1);
      formData.append('page_url', window.location.href);
      formData.append('position', position);
      formData.append('importing', 1);


    //   (editID === "") ? apiurl+"api/add_question" : apiurl+"api/edit_question"
      fetch( apiurl+"api/add_question" , {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
        })
        .then(response => response.json())
        .then(response => {
            console.log(response)
            if(typeof response.status !== "undefined" && response.status == "OK"){
                // successModal("Success", "Question Updated Successfully")
                if(typeof response.importid !== "undefined" && response.importid !== "" && response.importid !== null){
                    var check = response.importid
                    importUploadedQueue(check)
                }
                cleanOptions()
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        });
      }
  }

  
  const importUploadedQueue = (check) => {
        console.log("Asd")
    var url = "importQuestions"

    fetch(apiurl+"api/"+url , {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: JSON.stringify(AdminBasics()),
                accecible: 1,
                source_id: check,
                aboveBelowPosition: importPosition,
                aboveBelow: aboveBelow,
                folderid: folderid,
                courseid: courseid,
                quizid: quizid
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status == "OK"){
                successModal("Success", response.message)
                window.$(".mark_checkbox:checkbox:checked").each(function () {
                    $(this).prop("checked", false)
                });
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            
        })
        .catch(err => {
            // console.log(err);
        });
    }

  
  const bulkUpload = (files, type) => {
      window.$(".zipUploading").fadeIn()
      const formData = new FormData();
      formData.append('p_id', p_id);
      formData.append('main_id', main_id);
      formData.append('type', "Question");
      formData.append('staff', JSON.stringify(AdminBasics()));
      formData.append('accecible', 1);
      formData.append('page_url', window.location.href);
      formData.append('position', position);
      formData.append('file', files[0]);
      axios({
        method: 'post',
        url: apiurl+"api/upload_zip",
        data: formData,
        headers: {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        onUploadProgress:(data)=>{
            var perc = Math.round((data.loaded / data.total) *100)
            window.$(".zipInsideBar").animate({width: perc+"%"});
        },
          cancelToken: new CancelToken( cancel => cancelFileUpload.current = cancel)
      })
      .then(function (response) {
          window.$(".zipUploading").fadeOut()
          successModal("Success", response.data.message)
          if(typeof response.data.importid !== "undefined" && response.data.importid !== "" && response.data.importid !== null){
            var check = response.data.importid
            importUploadedQueue(check)
          }
          setRefresh(refresh == "No" ? "Yes" : "No")
      })
      .catch(function (response) {
        setRefresh(refresh == "No" ? "Yes" : "No")
        window.$(".zipUploading").fadeOut()
      });
  }


  const [essayEditor, setEssayEditor] = useState("")
  const [questionEditor, setQuestionEditor] = useState("")
  const [option1Editor, setOption1Editor] = useState("")
  const [option2Editor, setOption2Editor] = useState("")
  const [option3Editor, setOption3Editor] = useState("")
  const [option4Editor, setOption4Editor] = useState("")
  const [option5Editor, setOption5Editor] = useState("")
  const [option6Editor, setOption6Editor] = useState("")
  const [explanationEditor, setExplanationEditor] = useState("")

  const setEditors = () => {
    
    setLoading(true)
    setTimeout(function(){
      setQuestionEditor(
        <CKEditor
            editor={ ClassicEditor }
            config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
            data={question}   
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setQuestion(data)
                setPlainName(data.replace(/<[^>]*>?/gm, ''))
            } }
        />
      )
      setEssayEditor(
        <CKEditor
              editor={ ClassicEditor }
              config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
              data={essay}   
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setEssay(data)
                  // console.log( { event, editor, data } );
              } }
          />
      )
      setOption1Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option1} onChange={ ( event, editor ) => { const data = editor.getData(); setOption1(data) } } />
      )
      setOption2Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option2} onChange={ ( event, editor ) => { const data = editor.getData(); setOption2(data) } } />
      )
      setOption3Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option3} onChange={ ( event, editor ) => { const data = editor.getData(); setOption3(data) } } />
      )
      setOption4Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option4} onChange={ ( event, editor ) => { const data = editor.getData(); setOption4(data) } } />
      )
      setOption5Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option5} onChange={ ( event, editor ) => { const data = editor.getData(); setOption5(data) } } />
      )
      setOption6Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option6} onChange={ ( event, editor ) => { const data = editor.getData(); setOption6(data) } } />
      )
      setExplanationEditor(
        <CKEditor
              editor={ ClassicEditor }
              data={explanation}   
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setExplanation(data)
                  // console.log( { event, editor, data } );
              } }
          />
      )
        setLoading(false)
      },100)
  }
    var b = 0
    useEffect(() => {
      if(b === 0){
        if(typeof qid !== "undefined" && qid !== null && qid !== ""){
          setTimeout(function(){
            window.$("#viewQuestion"+qid).click();
          },2000)
        }

        setEditors()
        b++
      }
      // document.querySelector('.ck-editor__editable').ckeditorInstance.destroy()
    }, [])

    return(
      
      <>
      
        
      <ul className='error_success' id='error_success'>
      
      </ul>
        <div className='col-md-12'>
      <br /><br />  
            <div className='box no-border'>
                <div className='box-header'>
                    <a href={"/admin/import/"+params.id+"/"+params.p_id+importing} title='Back to Assets'>
                        <button className='btn btn-flat' style={{marginRight:"10px"}}>
                            <i className='fa fa-arrow-left'></i>
                        </button>
                    </a>
                  <h3 className='box-title'>
                    {tab.replace(/_/g, " ")} Question
                  </h3>
                  
                
                {
                    pathList.length > 0 &&
                    <div className='pull-right breadCrums'>
                        <ul>
                            <li onClick={() => navigate("/admin/assets/")}>
                                Assets <FeatherIcon icon={"chevron-right"}/>
                            </li>
                        {
                            pathList.map((item, index) => (
                                <li title={item.name} onClick={() => changeFolder(main_id, item.id)} key={"path"+index}>
                                    {item.name}
                                    <FeatherIcon icon={"chevron-right"} />
                                </li>    
                            ))
                        }
                        </ul>    
                    </div>
                }
                </div>
              </div>
        </div>
      {
        // pagePermissions(props.pagepermissions, "Assets", "add_question") &&
      
          <div className='col-md-3'> 
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className='box-title'>
                    Add Questions
                </h3>
              </div> 
              <div className='box-body'>
                <ul className='assets_list'>

                  <li onClick={() => changeTab("Objective")} className={(tab === "Objective") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/answer.png'} />
                          Objective Question
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                    
                  </li>
                  <li onClick={() => changeTab("Subjective")} className={(tab === "Subjective") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/qa.png'} />
                          Subjective Question
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>

                  <li onClick={() => changeTab("Fill_In_The_Blanks")} className={(tab === "Fill_In_The_Blanks") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/text-box.png'} />
                          Fil In The Blanks
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>

                  <li onClick={() => changeTab("True_And_False")} className={(tab === "True_And_False") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/decision.png'} />
                          True And False
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>

                  <li onClick={() => changeTab("Match_The_Following")} className={(tab === "Match_The_Following") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/jigsaw.png'} />
                          Match The Following
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>
                </ul>
              </div>
                
            </div>
            
            <div className='orCon'>
                <div className='leftbar'></div>
                <span className='text-center'>OR</span> 
                <div className='rightbar'></div>
            </div>
            <br />
            <div className='box no-border'>
              <div className='box-body'>
                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Document")}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <section>
                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                            <input {...getInputProps()} accept=".zip" />
                            
                            {
                                isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some zip file here, or click to select file</p>
                            }

                        </div>
                        </section>
                    )}
                </Dropzone>
              </div>
            </div>
          </div>
    }
    
          <div className='col-md-9'>

          {
            !viewQues && 
            // pagePermissions(props.pagepermissions, "Assets", "add_question") &&
            <>
                
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                      Comprehensive Question?
                  </h3>
                  
                  <div className='pull-right breadCrums'>
                    <label className="switch">
                        <input type="checkbox" value={(comprehensive) ? 1 : 0} defaultChecked={false} onChange={e => setComprehensive((comprehensive) ? false : true)}/>
                        <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {
                  
                  <div className='box-body' style={{display: (!comprehensive && "none")}}>
                        {essayEditor}
                    </div>
                }
              </div>

            {
                loading &&
                <div style={{position:"absolute", top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.5)", zIndex:9}}>
                    <Loading />
                </div>
            }
              <div className='box no-border'>
                <div className='box-body' style={{display: (!rules[tab]['question'] && "none")}}>
                    <label>Question</label>
                    {questionEditor}
                    
                  </div>
                  {
                    
                    <div className='box-body' style={{display: (!rules[tab]['option1'] && "none")}}>
                      <label>Option 1</label>
                        {option1Editor}
                    </div>
                  }
                  {
                    // rules[tab]['option2'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option2'] && "none")}}>
                    <label>Option 2</label>
                      {option2Editor}
                  </div>
                }
                {
                    // rules[tab]['option3'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option3'] && "none")}}>
                    <label>Option 3</label>
                      {option3Editor}
                  </div>
                }
                {
                    // rules[tab]['option4'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option4'] && "none")}}>
                    <label>Option 4</label>
                      {option4Editor}
                  </div>
                }
                {
                    // rules[tab]['option5'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['option5'] && "none")}}>
                    <label>Option 5</label>
                      {option5Editor}
                  </div>
                }
                {
                    // rules[tab]['option6'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['option5'] && "none")}}>
                    <label>Option 6</label>
                      {option6Editor}
                  </div>
                }
                {
                    // rules[tab]['explanation'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['explanation'] && "none")}}>
                    <label>Explanation</label>
                      {explanationEditor}
                  </div>
                }

                  <div className='box-body'>
                    <div className='col-md-4' style={{paddingLeft:0, display:"none"}}>
                      <label>Position</label>
                      <input type={"number"} name={"position"} className="form-control" placeholder='e.g 2' value={position} onChange={(e) => setposition(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{paddingLeft:0}}>
                      <label>Subject</label>
                      <input type={"text"} name={"subject"} className="form-control" placeholder='e.g Business Environment' value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{paddingRight:0}}>
                        <label>Topic</label>
                        <input type={"text"} name={"subject"} className="form-control" placeholder='e.g Business Environment Test' value={topic} onChange={(e) => setTopic(e.target.value)} />
                    </div>
                  </div>
                  <div className='box-body'>
                    <label>Tag(s)</label>
                    <input type={"text"} name={"tags"} className="form-control" placeholder='e.g Fill in blanks Test' value={tags} onChange={(e) => setTags(e.target.value)} />
                  </div>

                  <div className='box-body'>
                    <div className='col-md-3' style={{paddingLeft:0}}>
                      <label>(+ve Marks)</label>
                      <input type={"number"} step={0.01} name={"answer"} className="form-control" placeholder='e.g 1' value={posMarks} onChange={(e) => setPosMarks(e.target.value)} />
                    </div>
                    <div className='col-md-3'>
                      <label>(-ve Marks)</label>
                      <input type={"number"} step={0.01} name={"answer"} className="form-control" placeholder='e.g 0.25' value={negMarks}  onChange={(e) => setNegMarks(e.target.value)} />
                    </div>
                  {
                    rules[tab]['answertype'] &&
                    <div className='col-md-3'>
                      <label>Answer Type</label>
                      <select name='' className='form-control' defaultValue={ (answerType === null) ? "Single" : answerType} onChange={(e) => setAnswerType(e.target.value)}>
                        <option value={"Single"}>Single Correct</option>
                        <option value={"Multi"} >Multi Correct</option>
                      </select>
                    </div>
                  }
                  {
                    rules[tab]['answer'] &&
                    <div className='col-md-3' style={{paddingRight:0}}>
                      <label>Correct Answer</label>
                      <input type={"text"} name={"answer"} className="form-control" value={correct} placeholder={rules[tab]['answerplaceholder']} onChange={(e) => setCorrectAnswer(e.target.value)} />
                    </div>
                  }
                  </div>
                  <div className='box-footer'>
                    <button className='btn btn-primary btn-flat pull-right btn-block' onClick={() => addQuestion()}>
                      {
                        editID === "" ? "Add Question" : "Update Question"
                      }
                    </button>
                  </div>
              </div>
          </>
        }

        {
          viewQues &&
          <>
            {
              comprehensive &&
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Comprehensive
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : essay}}></div>
              </div>
            }
            {
              rules[tab]['question'] &&
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Question
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : question}}></div>
              </div>
            }
            
            {
              rules[tab]['option1'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 1
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option1}}></div>
                </div>
            }
            {
              rules[tab]['option2'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 2
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option2}}></div>
                </div>
            }
            
            {
              rules[tab]['option3'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 3
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option3}}></div>
                </div>
            }
            {
              rules[tab]['option4'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 4
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option4}}></div>
                </div>
            }
            
            {
              rules[tab]['option5'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 5
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option5}}></div>
                </div>
            }
            {
              rules[tab]['option6'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 6
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option6}}></div>
                </div>
            }
            {
              rules[tab]['explanation'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Explanation
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : explanation}}></div>
                </div>
            }
            <div className='col-md-4' style={{paddingLeft:0}}>
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Subject
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : subject}}></div>
              </div>
            </div>
              <div className='col-md-4'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Topic
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : topic}}></div>
                </div>
              </div>
              <div className='col-md-4'  style={{paddingRight:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Tags
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : tags}}></div>
                </div>
              </div>

              <div className='col-md-3' style={{paddingLeft:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      (+ve Marks)
                    </h3>
                    <span className='pull-right'>
                      {posMarks}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      (-ve Marks)
                    </h3>
                    
                    <span className='pull-right'>
                      {negMarks}
                    </span>
                  </div>
                </div>
              </div>
              {
              rules[tab]['answertype'] &&
              <div className='col-md-3'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Answer Type
                    </h3>
                    <span className='pull-right'>
                      {answerType}
                    </span>
                  </div>
                </div>
              </div>
            } 
            {
              rules[tab]['answer'] &&
              <div className='col-md-3' style={{paddingRight:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Correct Answer
                    </h3>
                    <span className='pull-right'>
                      {correct}
                    </span>
                  </div>
                </div>
              </div>
            }
          </>
        }
          </div>


            <div className='zipUploading' hidden>
                  <div className='zipUploadingContainer'>
                      <p className='text-center zipUploadingHeading'>
                        Uploading Questions...
                      </p>
                      <div className='zipUploadingBar'>
                        <div className='zipInsideBar'></div>
                      </div>
                      {/* <p className='outOf'>
                        4/20
                      </p> */}
                  </div>
            </div>

      </>
      
    )
  }
  return (
    <>
      <Question />
    </>
  )
}
