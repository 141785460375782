import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import Loading from '../includes/Loading';
import moment from 'moment';

const cookies = new Cookies();

export default function Blogs() {
    
  const Blogspage = (props) => {

    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    var a = 0;
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [blogForm, setBlogForm] = useState({
        id: "", title: "", subtext: "", body: "", thumbnail: "", banner: "", seoslug: "", seotitle: "", seokeywords: "", seodescription: "", blogcardimage: "", blogbannerimage: "", 
        showonhome: "0", status: "1", headscript: "", bodyscript: ""
    })
    const clearForm = () => {
        var temp = blogForm
        temp['id'] = ""
        temp['title'] = ""
        temp['subtext'] = ""
        temp['body'] = ""
        temp['thumbnail'] = ""
        temp['banner'] = ""
        temp['seoslug'] = ""
        temp['seotitle'] = ""
        temp['seokeywords'] = ""
        temp['seodescription'] = ""
        temp['blogcardimage'] = ""
        temp['blogbannerimage'] = ""
        temp['headscript'] = ""
        temp['bodyscript'] = ""
        temp['showonhome'] = "0"
        temp['status'] = "1"
        setBlogForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")

    }
    const [ckLoading, setCkLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [blogs, setBlogs] = useState([])

    const changeVar = (col, val) => {
        var temp = blogForm
        temp[col] = val
        setBlogForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const AddBlog = (e) => {

        e.preventDefault();
        formLoading(e.target)
        const formData = new FormData();
        formData.append('id', blogForm.id);
        formData.append('title', blogForm.title);
        formData.append('subtext', blogForm.subtext);
        formData.append('body', blogForm.body);
        formData.append('thumbnail', blogForm.thumbnail);
        formData.append('banner', blogForm.banner);
        formData.append('seoslug', blogForm.seoslug);
        formData.append('seotitle', blogForm.seotitle);
        formData.append('seokeywords', blogForm.seokeywords);
        formData.append('seodescription', blogForm.seodescription);
        formData.append('blogcardimage', blogForm.blogcardimage);
        formData.append('blogbannerimage', blogForm.blogbannerimage);
        formData.append('status', blogForm.status);
        formData.append('showonhome', blogForm.showonhome);
        formData.append('headscript', blogForm.headscript);
        formData.append('bodyscript', blogForm.bodyscript);
        
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/add_blog", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    $(e.target).trigger("reset");
                    clearForm()
                    setShow(false)
                    get_blogs()
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    

    const get_blogs = () => {
        setLoading(true)
        fetch(apiurl+"api/get_blog", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setBlogs(response.blogs)
                    
                    setTimeout(function(){
                        callSorting()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
        };

        const editBlog = (item) => {
            var temp = blogForm
            temp['id'] = item.id
            temp['title'] = item.title
            temp['subtext'] = item.subtext
            temp['body'] = item.body
            temp['seoslug'] = item.seoslug
            temp['seotitle'] = (item.seotitle !== null) ? item.seotitle : ""
            temp['seokeywords'] = (item.seokeywords !== null) ? item.seokeywords : "" 
            temp['seodescription'] = (item.seodescription !== null) ? item.seodescription : ""
            temp['blogcardimage'] = item.blogcardimage
            temp['headscript'] = item.headscript
            temp['bodyscript'] = item.bodyscript
            temp['showonhome'] = (item.showonhome === null) ? "" : item.showonhome
            console.log(item.showonhome)
            temp['status'] = item.status
            setBlogForm(temp)
            setRefresh(refresh === "No" ? "Yes" : "No")
            setShow(true)
            setCkLoading(true)
            setTimeout(function(){
                setCkLoading(false)
            },1000)
        }

        const removeBlog = (item) => {
            confirmAlert({
                title: 'Are You Sure?',
                message: "You won't be able to revert this back.",
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => removeThisblog(item)
                },
                {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                }
                ]
            });
        } 

    const removeThisblog = (item) => {
        fetch(apiurl+"api/remove_blog", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: item.id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    get_blogs()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_blogs_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }
    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }

    useEffect(() => {
        if(a == 0){
            get_blogs()
            // get_banners_list()
            a++
        }
     }, [])



    return(
    
    <>
    {
        show && pagePermissions(props.pagepermissions, "Blogs", "add_blog") &&
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        {
                            blogForm.id === "" ? "Add Blog" : "Update Blog"
                        }
                    </h3>
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setShow(false)} >
                        <i className='fa fa-times' ></i>
                    </button>
                </div>
                <form action='' method='POST' onSubmit={AddBlog} >
                    <div className='box-body'>
                        <label>Blog Title<span style={{color:"red"}} >*</span></label>        
                        <input type={"text"} className="form-control" required placeholder='e.g WHAT TO DO AFTER CA FOUNDATION EXAMS TILL RESULT' value={blogForm.title} onChange={(e) => changeVar("title", e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Blog Sub Text</label>        
                        <input type={"text"} className="form-control" placeholder='e.g What to do after CA foundation exams till result ‘The key is in not spending time but in investing it' value={blogForm.subtext} onChange={(e) => changeVar("subtext", e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Blog Body</label>        
                        {
                            ckLoading ? 
                            <div style={{position:"relative"}} >
                                <br /><br /><br />
                                <Loading />
                                <br /><br /><br /><br />
                            </div>
                            :
                            <CKEditor 
                            editor={ ClassicEditor } 
                            
                            config={
                                { 
                                    simpleUpload: { 
                                        uploadUrl: apiurl + 'api/ckeditor/image_upload'  
                                    }
                                }

                            } 
                            data={blogForm.body} onChange={ ( event, editor ) => { const data = editor.getData(); changeVar("body", data) } } />
                        }
                    </div>
                    <div className='box-body col-md-6'>
                        <label>Seo Slug<span style={{color:"red"}} >*</span></label>        
                        <input type={"text"} className="form-control" required placeholder='e.g what-to-do-after-ca-foundation-exams-till-result' value={blogForm.seoslug} onChange={(e) => changeVar("seoslug", e.target.value)} />
                    </div>
                    <div className='box-body col-md-6'>
                        <label>Seo Title</label>    
                        <input type={"text"} className="form-control" placeholder='e.g what-to-do-after-ca-foundation-exams-till-result' value={blogForm.seotitle} onChange={(e) => changeVar("seotitle", e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Seo Keywords</label>        
                        <textarea placeholder="e.g e.g What Next After CA Foundation Exams, What to do after ca foundation exams, what next after ca foundation, ca foundation exam what next, what to do after exam till result, ca foundation exam passed what next...." className="form-control" onChange={(e) => changeVar("seokeywords", e.target.value)} defaultValue={blogForm.seokeywords}></textarea>
                    </div>
                    <div className='box-body'>
                        <label>Seo Description</label>        
                        <textarea placeholder="e.g e.g What Next After CA Foundation Exams, What to do after ca foundation exams, what next after ca foundation, ca foundation exam what next, what to do after exam till result, ca foundation exam passed what next...." className="form-control" onChange={(e) => changeVar("seodescription", e.target.value)} defaultValue={blogForm.seodescription}></textarea>
                    </div>
                    <div className='box-body'>
                        <label>Head Script</label>        
                        <textarea placeholder="e.g e.g <script></script>" className="form-control" onChange={(e) => changeVar("headscript", e.target.value)} defaultValue={blogForm.headscript}></textarea>
                    </div>
                    <div className='box-body'>
                        <label>Body Script</label>        
                        <textarea placeholder="e.g e.g <script></script>" className="form-control" onChange={(e) => changeVar("bodyscript", e.target.value)} defaultValue={blogForm.bodyscript}></textarea>
                    </div>
                    <div className='box-body no-left-padding no-right-padding'>
                        <div className='box-body col-md-3'>
                            <label>Blog Card Image <span style={{color:"red"}} >Max 1 MB</span></label>        
                            <input type={"file"} className="form-control" accept='.png, .jpg, .jpeg, .svg, .gif' onChange={(e) => changeVar("blogcardimage", e.target.files[0])} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Blog Inside Banner <span style={{color:"red"}} >Max 1 MB</span></label>        
                            <input type={"file"} className="form-control" accept='.png, .jpg, .jpeg, .svg, .gif' onChange={(e) => changeVar("blogbannerimage", e.target.files[0])} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Status</label>        
                            <select className='form-control' defaultValue={blogForm.status} onChange={(e) => changeVar("status", e.target.value)}  >
                                <option value={"1"} >Publish</option>
                                <option value={"0"} >Un-Publish</option>
                            </select>
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Show On Home</label>        
                            <select className='form-control' defaultValue={blogForm.showonhome} onChange={(e) => changeVar("showonhome", e.target.value)}  >
                                <option value={"1"} >Yes</option>
                                <option value={"0"} >No</option>
                            </select>
                        </div>
                    </div>
                    
                    <div className='box-footer'>
                        <button className='btn btn-primary btn-flat btn-block'>
                            {
                                blogForm.id === "" ? "Add Blog" : "Update Blog"
                            }
                            
                        </button>
                    </div>
                </form>
            </div>
        </div>
    }

    <div className='col-md-12'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Manage Blogs
                </h3>
                {
                    !show &&
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => {
                        clearForm()
                        setShow(true)
                        setCkLoading(true)
                        setTimeout(function(){
                            setCkLoading(false)
                        },1000)
                    }} >
                        <i className='fa fa-plus' ></i> Add New Blog
                    </button>
                }
            </div>
            <div className='box-body'>
                <table id="studenttable" className="table table-bordered table-hover datatable">
                    <thead>
                        <tr>
                            <th ></th>
                            <th style={{width:"80px"}} >S.No</th>
                            <th>Title</th>
                            <th>Sub Text</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Show On Home?</th>
                            <th>Added by</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='connected-sortable droppable-area1'>
                        {
                            blogs.length > 0 &&
                            blogs.map((item, index) => {
                                var staff = JSON.parse(item.staff)
                                moment.locale('en');
                                var dt = item.created_at;
                                return(
                                    <tr 
                                    key={"data"+index}
                                    id={"id"+item.id}>
                                         <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                            <i className='fa fa-arrows-v'></i>
                                        </td>
                                        <td style={{width:"80px"}} >{index+1}</td>
                                        <td>
                                            <a href={"https://www.escholars.in/blog/"+item.seoslug+"?preview"} target={"_blank"} >
                                                {item.title}
                                            </a>
                                        </td>
                                        <td>{item.subtext}</td>
                                        <td>{moment(dt).format("D-M-Y HH:mm:ss")}</td>
                                        <td>
                                            {
                                                (item.status === "1" || item.status === 1) ?
                                                <span className='badge bg-green'><i className='fa fa-check'></i></span>
                                                :
                                                <span className='badge bg-red'><i className='fa fa-times'></i></span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                (item.showonhome === "1" || item.showonhome === 1) ?
                                                <span className='badge bg-green'><i className='fa fa-check'></i></span>
                                                :
                                                <span className='badge bg-red'><i className='fa fa-times'></i></span>
                                            }
                                        </td>
                                        <td>
                                            <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                            {staff.email}<br />
                                            {staff.contact}
                                        </td>
                                        <td style={{whiteSpace:"nowrap"}} >
                                            {
                                                pagePermissions(props.pagepermissions, "Blogs", "edit_blog") &&
                                                <button className='btn btn-primary btn-flat' onClick={() => editBlog(item)} >  
                                                    <i className='fa fa-pencil'></i>
                                                </button>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Blogs", "remove_blog") &&
                                                <button className='btn btn-danger btn-flat' onClick={() => removeBlog(item)}>
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    </>
    )
  }
  return (
    <>
      <Sidebar page={Blogspage} permission="Blogs"/>
    </>
  )
}
