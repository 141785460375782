import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { Link, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
import { CSVLink, CSVDownload } from "react-csv";


const cookies = new Cookies();

export default function Downloadrequets() {
  const Downloadrequetspage = (props) => {
    
    const params = useParams()
    const {errorModal, warningModal, successModal, pagePermissions, AdminBasics, formLoading} = CommonFunc()
    const [loading, setLoading] = useState(false)
    const [mydownload, setMydownloads] = useState([])
    let getToken = cookies.get('token')
    
    const [reqfrom, setReqFrom] = useState("")
    const [reqto, setReqTo] = useState("")
    const [resfrom, setResFrom] = useState("")
    const [resto, setResTo] = useState("")
    const [segment, setSegment] = useState(0)



    const getMyDownloads = () => {
        setLoading(true)
        fetch(apiurl+"api/get_download_requests", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      permissions: (pagePermissions(props.pagepermissions, "Download", "view_file")),
                      reqfrom:reqfrom,
                      reqto: reqto,
                      resfrom: resfrom,
                      resto: resto
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setMydownloads(response.download)
                    setReqFrom(response.reqfrom)
                    setReqTo(response.reqto)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    // setTimeout(function(){
                    //     window.$('#downloadtable').dataTable()
                    // }, 500)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                window.$(".loaderbody").remove()
                setLoading(false)
            })
            .catch(err => {
            });
    }

    const responseDownload = (resp, id) => {
        warningModal("Loading...", "")
        fetch(apiurl+"api/response_download_request", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      resp: resp,
                      id: id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getMyDownloads()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
        });
    }
    
    const filterRequests = (e) => {
        formLoading(e.target)
        e.preventDefault()
        getMyDownloads()
    }

    const downloadList = (item, temptd) => {
        var th = JSON.parse(item.th)
        var td = JSON.parse(item.td)

        const csvData = [
            ["firstname", "lastname", "email"],
            ["Ahmed", "Tomi", "ah@smthing.co.com"],
            ["Raed", "Labes", "rl@smthing.co.com"],
            ["Yezzi", "Min l3b", "ymin@cocococo.com"]
        ];
        console.log(temptd)
        // console.log(csvData)
        // console.log([th, td])
    }


    var a = 0
    useEffect(() => {
        if(a === 0){
            getMyDownloads()
            a++
        }
    }, [])
    
    return(
        // (pagePermissions(props.pagepermissions, "Assets", "download_permission")) ?

        <>
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filter
                        </h3>
                        {
                            (resfrom !== "" || resto !== "") &&
                            <span className='cursor-pointer pull-right' style={{color:"red"}} onClick={(e) => {
                                setResFrom("")
                                setResTo("")
                                getMyDownloads()
                            }} >
                                <i className='fa fa-times'></i>
                            </span>
                        }
                    </div>
                    <form action='' method='POST' onSubmit={(e) => filterRequests(e)} >
                        <div className='box-body'>
                            <label>
                                Requested From
                            </label>
                            <input type={"date"} className="form-control" value={reqfrom} onChange={(e) => setReqFrom(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>
                                Requested To
                            </label>
                            <input type={"date"} className="form-control" value={reqto} onChange={(e) => setReqTo(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>
                                Response From
                            </label>
                            <input type={"date"} className="form-control" value={resfrom} onChange={(e) => setResFrom(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>
                                Response To
                            </label>
                            <input type={"date"} className="form-control" value={resto} onChange={(e) => setResTo(e.target.value)} />
                        </div>
                        <div className='box-footer'>
                            <button className={"btn btn-primary btn-flat btn-block"}>
                                <i className='fa fa-filter'></i> Filter
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Download Requests
                        </h3>
                    </div>
                    {
                        loading  ? 
                        <>
                            <br /><br /><br />
                            <Loading />
                            <br /><br /><br />
                        </>
                        :
                        mydownload.length > 0 ?
                        <>
                            <div className='box-body' style={{overflow:"auto"}} >
                                <table id="downloadtable" className="table table-bordered table-hover datatable">
                                    <thead>
                                        <tr>
                                            <th>
                                                S.No
                                            </th>
                                            <th>
                                                File Name
                                            </th>
                                            <th>
                                                File Type
                                            </th>
                                            <th>
                                                Requested By
                                            </th>
                                            <th>
                                                Requested On
                                            </th>
                                            <th>
                                                Response Date
                                            </th>
                                            {
                                                (pagePermissions(props.pagepermissions, "Download_Requests", "view_request_response_by")) &&
                                                <th>
                                                    Response By
                                                </th>
                                            }
                                            <th>
                                                Response
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            typeof mydownload[segment] !== "undefined" &&
                                            mydownload[segment].length > 0 &&
                                            mydownload[segment].map((item, index) => {
                                                moment.locale('en');
                                                var dt = item.updated_at;
                                                var ct = item.created_at;
                                                var response = "Waiting"
                                                if(item.response === true){
                                                    response = "Accepted"
                                                }else if(item.response === false){
                                                    response = "Rejected"
                                                }
                                                var path = JSON.parse(item.path)
                                                var requestedby = JSON.parse(item.requesterdata)
                                                var responseby = (item.responsefromdata !== null) ? JSON.parse(item.responsefromdata) : null
                                                
                                                if(item.downloadfrom === "List"){
                                                    var temptd = [JSON.parse(item.th)]
                                                    var tdArray = JSON.parse(item.td)
                                                    for(var i = 0;i < tdArray.length;i++){
                                                        temptd.push(tdArray[i])
                                                    }
                                                }

                                                return(
                                                    <tr key={"download"+index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.downloadfrom === "List" &&
                                                                <>
                                                                    <CSVLink data={temptd} filename={item.filename + ".csv"} >{item.filename}</CSVLink>
                                                                </>
                                                            }
                                                            {
                                                                item.downloadfrom === "Attempt" &&
                                                                <>
                                                                    <a href={apiurl+"api/downloadanswersheet/"+item.th} target="_blank" title='Download' >
                                                                        {item.filename}
                                                                    </a>
                                                                </>
                                                            }
                                                            {
                                                                item.downloadfrom === "Quiz" &&
                                                                <>
                                                                    <a href={apiurl+"api/downloadtestpaper/"+item.th} target="_blank" title='Download' >
                                                                        {item.filename}
                                                                    </a>
                                                                </>
                                                            }
                                                            {
                                                                item.downloadfrom === "Assets" &&
                                                                item.filename
                                                            }

                                                            {
                                                                path !== null && 
                                                                    <>
                                                                    <br />
                                                                    <small style={{color:"#337ab7"}}>
                                                                        {item.downloadfrom}/ &nbsp;
                                                                        {
                                                                            path.length > 0 ?
                                                                            path.map((item2, index2)=>(
                                                                                item.filename !== item2.name &&
                                                                                <span key={"path"+index2} >
                                                                                    {item2.name}/  &nbsp;
                                                                                </span>
                                                                            ))
                                                                            :
                                                                            "-"
                                                                        }
                                                                    </small>
                                                                    </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {item.filetype}
                                                            {/* { item.downloadfrom === "List" && 'Excel'} */}
                                                            { item.downloadfrom }
                                                        </td>
                                                        <td>
                                                            <strong> { requestedby.name } <small>({requestedby.role})</small></strong><br />
                                                            <small style={{whiteSpace:"nowrap"}}>
                                                                <Link to={"#"} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    window.location.href="mailto:"+requestedby.email
                                                                    } } >
                                                                    { requestedby.email } 
                                                                </Link><br />
                                                                <Link to={"#"} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    window.location.href="tel:"+requestedby.contact
                                                                    } } >
                                                                    { requestedby.contact } 
                                                                </Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            {moment(ct).format("DD-MM-YYYY HH:mm:ss")}
                                                        </td>
                                                        <td>
                                                            {(item.updated_at !== item.created_at) ? moment(dt).format("DD-MM-YYYY HH:mm:ss") : '-'}
                                                        </td>
                                                        
                                                        {
                                                            (pagePermissions(props.pagepermissions, "Download_Requests", "view_request_response_by")) &&
                                                            <td>
                                                                {
                                                                    responseby !== null ?
                                                                    <>
                                                                        <strong> { responseby.name } <small>({responseby.role})</small></strong><br />
                                                                        <small style={{whiteSpace:"nowrap"}}>
                                                                            <Link to={"#"} onClick={(e) => {
                                                                                e.preventDefault()
                                                                                window.location.href="mailto:"+responseby.email
                                                                                } } >
                                                                                { responseby.email } 
                                                                            </Link><br />
                                                                            <Link to={"#"} onClick={(e) => {
                                                                                e.preventDefault()
                                                                                window.location.href="tel:"+responseby.contact
                                                                                } } >
                                                                                { responseby.contact } 
                                                                            </Link>
                                                                        </small>
                                                                    </>
                                                                    :
                                                                    "-"
                                                                }                                                                        
                                                            </td>
                                                        }

                                                        <td>
                                                            {
                                                                (item.updated_at === item.created_at) &&
                                                                <>
                                                                    <button className='btn btn-success btn-flat' onClick={(e) => responseDownload(true, item.id)}>
                                                                        Approve
                                                                    </button>
                                                                    &nbsp;
                                                                    <button className='btn btn-danger btn-flat' onClick={(e) => responseDownload(false, item.id)}>
                                                                        Decline
                                                                    </button>
                                                                </>
                                                            }
                                                            {
                                                                item.response === 1 &&
                                                                <button className='btn btn-success btn-flat' >
                                                                    Request Approved
                                                                </button>
                                                            }
                                                            {
                                                                item.response === 0 &&
                                                                <button className='btn btn-danger btn-flat' >
                                                                    Request Declined
                                                                </button>
                                                            }
                                                            
                                                        </td>
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='box-footer'>
                                <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                    {
                                        mydownload.length > 0 && mydownload.length <= 10 &&
                                        mydownload.map((item, index) => {
                                            const active = index === segment ? " btn-primary" : ""
                                            return(
                                                <button className={'btn btn-flat'+active} key={"key"+index} style={{marginLeft:"5px"}} onClick={() => {
                                                    setSegment(index)
                                                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                    }} >
                                                    {index+1}
                                                </button>
                                            )
                                        })
                                    }
                                    {
                                        mydownload.length > 10 &&
                                        <div style={{width:"200px", float:"right"}}>
                                            <label>Last Page: {mydownload.length}</label>
                                            <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                                setSegment(e.target.value - 1)
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                }} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                        :
                            <div className='box-body text-center no-downloads' >
                                <br /><br />
                                <FeatherIcon icon={"download"} />
                                <p>No Downloads Available</p>
                                <br /><br /><br /><br />
                            </div>
                    }
                </div>
            </div>

        {/* // : */}

        {/* // <Notfound /> */}
        </>
    )
  }
  return (
    <>
        {
            
            <Sidebar page={Downloadrequetspage} permission="Download_Requests"/>
            
        }
    </>
  )
}
