import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();
const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}
export default function Categorypositions() {
  const CategorypositionsPage = (props) => {
    
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const params = useParams()
    const query = useQuery();
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()

    const [courseLoading, setCourseLoading] = useState(false)

    const [courseListdata, setCourseListdata] = useState([])
    const [packageListdata, setPackageListdata] = useState([])
    const [freeListdata, setFreeListdata] = useState([])
    const [otherList, setOtherdata] = useState([])
    

    const courseList = () => {
        setCourseLoading(true)
        fetch(apiurl+"api/categoryCourseList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    staff: AdminBasics(),
                    page_url: window.location.href,
                    categoryid: params.id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setCourseListdata(response.list.course)
                    setPackageListdata(response.list.package)
                    setFreeListdata(response.list.free)
                    setOtherdata(response.list.other)
                    
                    setTimeout(function(){
                        callSorting("Packages", "area1")
                        callSorting("Courses", "area2")
                        callSorting("FreeStuff", "area3")
                        callSorting("Other", "area4")
                    }, 1000)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setCourseLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }
    
const rePositionAll = (array, type) => {
    fetch(apiurl+"api/reposition_category_course", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                page_url: window.location.href,
                staff: AdminBasics(),
                position_list: array,
                type:type,
                categoryid: params.id
            })
        })
        .then(response => response.json())
        .then(response => {
        })
        .catch(err => { });
}


const callSorting = (type, clas) => {
  window.$( ".droppable-"+clas ).sortable({
          stop: function(event, ui) {
              var array = [];
              window.$(event.target).find(".dragHandle").each(function (){
                  var a = $(this).attr("data-bind");
                  array.push(a);
              });

              rePositionAll(array, type)
      },
      connectWith: ".connected-sortable",
      stack: '.connected-sortable ul',
      handle: ".dragHandle",
  }).disableSelection();
  
}

    var a = 0
    useEffect(()=>{
        if(a === 0){
            
            courseList()
            a++
        }
    }, [])

    return(
        pagePermissions(props.pagepermissions, "Categories", "courses_position") ?
            <div className='col-md-12 no-left-padding no-right-padding'>
                <div className='col-md-3'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Packages
                            </h3>
                        </div>
                        <div className='box-body'>
                            {
                                packageListdata.length > 0 ?
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{width:"50px"}}>Drag</th>
                                            <th>Package Name</th>
                                        </tr>
                                    </thead>
                                    <tbody  className='connected-sortable droppable-area1'>
                                        {
                                            packageListdata.map((item, index) => (

                                                <tr key={"package"+index} className="draggable-item" data-bind={item.otherid}>
                                                    <td className='text-center text-center dragHandle' data-bind={item.otherid}>
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td>
                                                        <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                                                            <img src={assets_url+item.thumbnail} style={{width:"50px", objectFit:"contain", borderRadius:"5px"}} />
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <p>
                                    No Packages Found
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Courses
                            </h3>
                        </div>
                        <div className='box-body'>
                            {
                                courseListdata.length > 0 ?
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{width:"50px"}}>Drag</th>
                                            <th>Course Name</th>
                                        </tr>
                                    </thead>
                                    <tbody  className='connected-sortable droppable-area2'>
                                        {
                                            courseListdata.map((item, index) => (

                                                <tr key={"course"+index} className="draggable-item" data-bind={item.otherid}>
                                                    <td className='text-center text-center dragHandle' data-bind={item.otherid}>
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td>
                                                        <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                                                            <img src={assets_url+item.thumbnail} style={{width:"50px", objectFit:"contain", borderRadius:"5px"}} />
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <p>
                                    No Courses Found
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Free Stuff
                            </h3>
                        </div>
                        <div className='box-body'>
                            {
                                freeListdata.length > 0 ?
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{width:"50px"}}>Drag</th>
                                            <th>Course/Package Name</th>
                                        </tr>
                                    </thead>
                                    <tbody  className='connected-sortable droppable-area3'>
                                        {
                                            freeListdata.map((item, index) => (

                                                <tr key={"free"+index} className="draggable-item" data-bind={item.otherid}>
                                                    <td className='text-center text-center dragHandle' data-bind={item.otherid}>
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td>
                                                        <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                                                            <img src={assets_url+item.thumbnail} style={{width:"50px", objectFit:"contain", borderRadius:"5px"}} />
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <p>
                                    No Free Stuff Found
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Other Stuff
                            </h3>
                        </div>
                        <div className='box-body'>
                            {
                                otherList.length > 0 ?
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{width:"50px"}}>Drag</th>
                                            <th>Course/Package Name</th>
                                        </tr>
                                    </thead>
                                    <tbody  className='connected-sortable droppable-area4'>
                                        {
                                            otherList.map((item, index) => (

                                                <tr key={"free"+index} className="draggable-item" data-bind={item.otherid}>
                                                    <td className='text-center text-center dragHandle' data-bind={item.otherid}>
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td>
                                                        <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                                                            <img src={assets_url+item.thumbnail} style={{width:"50px", objectFit:"contain", borderRadius:"5px"}} />
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <p>
                                    No Free Stuff Found
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        :
            <Notfound />
    )
  }
  return (
    <>
      <Sidebar page={CategorypositionsPage} permission="Global"/>
    </>
  )
}
