import React, { useState, useEffect, useCallback, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import FeatherIcon from 'feather-icons-react';
import { TbPinnedOff, TbPinned } from "react-icons/tb";
import { FcGoogle } from "react-icons/fc";
import CommonFunc from '../includes/Common';
import Adminprops from '../../VelidateWebsite/Adminprops';
import apiurl from '../../Global';
import Cookies from 'universal-cookie';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Dropzone, {useDropzone} from 'react-dropzone'
import moment from 'moment';
import Loading from '../includes/Loading';
import axios, {CancelToken, isCancel} from 'axios';
import useDrivePicker from 'react-google-drive-picker'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Video from './Video';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import Assignment from './Assignment';


const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
const cookies = new Cookies();

export default function Assets() {
    
    
    const[queue, setQueue] = useState([])
    const cancelFileUpload = useRef(null)
    const[uploading, setUploading] = useState(false)

    const[selectedFiles, setSelectedFiles] = useState([]) 
    const[selectionType, setSelectionType] = useState([]) 
    const [highlighter, setHighlighter] = useState("")
    
    const query = useQuery();
    var additional_string = ""
    const importing = ( typeof query.get("importing") !== "undefined" && query.get("importing") !== null) ? "?importing="+query.get("importing") : "";


    var b = 1;
    useEffect(() => {
        if(b === 1){
            b++;
        }
    }, [])
    // storageInfo
    
const Assets_Liabrary = (props) => {
    
    

    const params = useParams()
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, alertModal} = CommonFunc()


    const[addNew, setAddnew] = useState("");
    
    const[aboveBelow, setAboveBelow] = useState("");
    const[aboveBelowPosition, setAboveBelowPosition] = useState("");
    
    const[p_id, setPid] = useState((typeof params.p_id) !== "undefined" ? params.p_id : 0);
    const[main_id, setMainId] = useState((typeof params.id) !== "undefined" ? params.id : 0);
    
    const[recent_list, seRecent_List] = useState([]);
    const navigate = useNavigate()

    const [addAssignment, setAddAssignment] = useState(false)
    const [assignmentType, setAssignmentType] = useState("Add")
    const [assignmentId, setAssignmentId] = useState([])
    const [totalFiles, setTotalFiles] = useState("")

    
    const [filterObject, setFilterObject] = useState({
        "searchType" : "",
        "fileTypes" : ""
    })

    const [filter, setFilter] = useState(
        {
            "created_from" : "",
            "created_to" : "",
            "updated_from" : "",
            "updated_to" : "",
            "size_from" : "",
            "size_to" : "",
            "video_encryped" : "",
            "subject" : "",
            "question_type" : [],
            "correct_marks" : "",
            "negetive_marks" : "",
            "answer_type" : "",
            "class_date" : "",
            "class_type" : "",
            "marks_from" : "",
            "marks_to" : "",
            "availability" : "",
            "start_from" : "",
            "start_to" : "",
            "end_from" : "",
            "end_to" : "",
            "name_search" : "",
        }
    )
    
    const changeAddTab = (x, abvblw, pos) => {
        setAboveBelow(abvblw)
        setAboveBelowPosition(pos)
        if(x !== "Assignment"){
            setAddnew(x)
        }
        if(x === "Question"){
            navigate("/admin/questions/"+params.id+"/"+params.p_id)
        }else if(x === "Live"){
            navigate("/admin/liveclasses/"+params.id+"/"+params.p_id)
        }else if(x === "Assignment"){
            setAssignmentType("Add")
            setAssignmentId([])
            setAddAssignment(true)
        }
    }

    

    const[pathList, setPathList] = useState([])
    const[folderList, setFolderList] = useState([])
    const[filesList, setFilesList] = useState([])
    const[pinnedList, setPinnedList] = useState([])
    const[loading, setLoading] = useState(true)
    const[preview, setPreview] = useState("")
    
    const[previewLoading, setPreviewLoading] = useState(false)


    const [editor, setEditor] = useState("")
    const [editorLoading, setEditorLoading] = useState(false)

    const [pinnedDataList, setpinneDataList] = useState([])
    const fetchRecent = () => {
        fetch(apiurl+"api/recent_files", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    seRecent_List(response.recent)
                    setpinneDataList(response.pinned)
                    
                    setRemovingToday(response.remove)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }


    
    const clearFilters = (cols) => {
        var fcols = cols.split(",")
        var temp = filter
        for(var i = 0;i<fcols.length;i++){
            if(fcols[i] !== "question_type"){
                temp[fcols[i]] = ""
            }else{
                temp[fcols[i]] = []
            }
        }
        setFilter(temp)
        setRefresh(refresh == "No" ? "Yes" : "No")
        getChildList(p_id, main_id)
    }

    const setFilterValues = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh == "No" ? "Yes" : "No")
        getChildList(p_id, main_id)
    }
    
    const selectQuestionType = (type) => {
        var temp = filter
        var typeList = temp['question_type']
        var index = typeList.indexOf(type);
        if (index !== -1) {
            typeList.splice(index, 1);
        }else{
            typeList.push(type)
        }
        temp['question_type'] = (typeList.length > 0) ? typeList : []
        setFilter(temp)
        getChildList(p_id, main_id)
        
    }

    const changeFilesType = (type) => {
        // var temp = filterObject
        // temp['searchType'] = "All"
        // temp['fileTypes'] = type
        // setFilterObject(temp)
        // getChildList(p_id, main_id)
    }

    const [removingToday, setRemovingToday] = useState(0)
    const [segment, setSegment] = useState(0)
    
    const [encrypt, setEncrypt] = useState(null)

    const getChildList = (pid, mainid) => {
        setLoading(true)
        fetch(apiurl+"api/child_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    p_id: pid,
                    main_id: mainid,
                    fileTypes: filterObject.fileTypes,
                    searchType: filterObject.searchType,
                    filter_list: JSON.stringify(filter),
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setFilesList(response.files)
                    setPathList(response.path)
                    setPinnedList(response.pinned)
                    setEncrypt(response.encrypt)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    checkCount()
                    setTotalFiles(response.totalFiles)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const[name, setName] = useState("")
    const[tags, setTags] = useState("")
    const[description, setDescription] = useState("")
    const[importurl, setURL] = useState("")
    const[file, setFile] = useState("")



    const[refresh, setRefresh] = useState("No")
    // const cleanName = (name) => {
    //     return str.replace(/[^a-zA-Z ]/g, "")
    // }
    const uploadQueue = () => {
        var mylist = queue
        // console.log(mylist)
        for(var j = 0;j<mylist.length;j++){
            // if(!uploading && !mylist[j].status){
            if(!mylist[j].status && (mylist[j].uploading === "" || mylist[j].uploading === 0)){
                
                setUploading(true)
                const formData = new FormData();
                formData.append('p_id', mylist[j].p_id);
                formData.append('name', mylist[j].name);
                formData.append('main_id', mylist[j].main_id);
                formData.append('type', mylist[j].type);
                if(mylist[j].type === "Document" || mylist[j].type === "Video" || mylist[j].type === "Recording" || mylist[j].type === "Image" || mylist[j].type === "Assignment") {
                    formData.append('file', mylist[j].file);
                }
                if(typeof mylist[j].fileURL !== "undefined" && mylist[j].fileURL !== "") {
                    formData.append('fileURL', mylist[j].fileURL);
                    formData.append('fullName', mylist[j].fullName);
                }
                
                
                if(typeof mylist[j].youtubeID !== "undefined" && mylist[j].youtubeID !== "") {
                    formData.append('youtubeID', mylist[j].youtubeID);
                    formData.append('youtubeEmbedUrl', mylist[j].youtubeEmbedUrl);
                    formData.append('youtubeDuration', mylist[j].youtubeDuration);
                }
                formData.append('encodeType', (mylist[j].encodeType !== undefined) ? mylist[j].encodeType : "OldEncode");
                

                formData.append('staff', JSON.stringify(AdminBasics()));
                formData.append('accecible', 1);
                formData.append('page_url', window.location.href);

                if(mylist[j].aboveBelow !== "" && typeof mylist[j].aboveBelow !== "undefined"){
                    formData.append('aboveBelow', mylist[j].aboveBelow);
                    formData.append('aboveBelowPosition', mylist[j].aboveBelowPosition);
                }
                if(mylist[j].videoType !== "" && typeof mylist[j].videoType !== "undefined"){
                    formData.append('update', mylist[j].update);
                    formData.append('videoType', mylist[j].videoType);
                }
                
                

                axios({
                    method: 'post',
                    url: apiurl+"api/add_folder",
                    data: formData,
                    headers: {
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    
                    onUploadProgress:(data)=>{
                        
                        
                        // var speed = ((data.loaded/100)/(data.timeStamp))
                        // console.log(speed+"Kbps")

                        var perc = Math.round((data.loaded / data.total) *100)
                        mylist[j].uploading = perc
                        if(perc < 100){
                            $("#bars"+j+" .uploadingMessages").text("Uploading...")
                        }else{
                            $("#bars"+j+" .pinnedoff").remove()
                            $("#bars"+j+" .uploadingMessages").text((mylist[j].type === "Video") ? "Encrypting Video..." : "Saving "+mylist[j].type+"...")
                        }

                        perc = (perc > 95) ? 95 : perc
                        var color = "#f46a6a"
                        if(perc > 20 && perc <= 40){
                            color = "#FFA500"
                        }else if(perc > 40 && perc <= 60){
                            color="#F3BD61"
                        }else if(perc > 60 && perc <= 80){
                            color="#f1b44c"
                        }else if(perc > 80 && perc <= 95){
                            color="#3DC594"
                        }
                        mylist[j].color=color
                        mylist[j].msg="Uploading..."
                        setQueue(mylist)

                        $("#bars"+j+" .insideBar").css({width:perc+"%",backgroundColor:color})
                        $("#bars"+j+" .percentage_span").html(perc+"%&nbsp;")
                    },
                    cancelToken: new CancelToken( cancel => cancelFileUpload.current = cancel)
                })
                .then(function (response) {
                    //handle success
                    
                    $("#bars"+j+" .insideBar").css({width:"100%",backgroundColor:"#34c38f"})
                    $("#bars"+j+" .percentage_span").html("100%&nbsp;")
                    $("#bars"+j+" .uploadingMessages").text("Saved")
                    
                    mylist[j].status = true
                    mylist[j].color="#34c38f"
                    mylist[j].msg="Saved"
                    mylist[j].uploading = 100
                    setQueue(mylist)
                    setUploading(false)
                    uploadQueue()
                    getChildList(p_id, main_id)
                    setRefresh(refresh === "No" ? "Yes" : "No")
                })
                .catch(function (response) {
                    
                    $("#bars"+j+" .insideBar").css({backgroundColor:"#f46a6a"})
                    let msg = ""
                    if(typeof response.message !== "undefined"){
                        $("#bars"+j+" .uploadingMessages").text(response.message)
                        msg = response.message
                    }
                    
                    if(typeof response.response !== "undefined" && typeof response.response.data.message){
                        $("#bars"+j+" .uploadingMessages").text(response.response.data.message)
                        msg = response.response.data.message
                    }
                    

                    mylist[j].status = "Failed"
                    mylist[j].color="#f46a6a"
                    mylist[j].msg=msg
                    setQueue(mylist)
                    setUploading(false)
                    uploadQueue()
                    getChildList(p_id, main_id)
                    setRefresh(refresh === "No" ? "Yes" : "No")
                });

                break;
            }
        }
    }
    
    const cancelRequest = (index) => {
        var mylist = queue
        if(cancelFileUpload.current){
            cancelFileUpload.current("Operation Cancelled.")
            mylist[index].retry=1
            setQueue(mylist)
            setUploading(false)
            uploadQueue()
            setRefresh(refresh === "No" ? "Yes" : "No")
        }
    }

    const retryUpload = (index) => {
        var mylist = queue
        mylist[index].retry = ""
        mylist[index].status = false
        mylist[index].uploading = 0
        mylist[index].msg = ""
        mylist[index].color = ""
        setQueue(mylist)
        setRefresh(refresh === "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }

    const clearAll = () => {
        setQueue([])
    }

    const addNewFolder = (e, type) => {
        e.preventDefault()

        var data = {
            p_id: p_id,
            main_id: main_id,
            name: name,
            type: type,
            file: (type === "Document" || type === "Video" || type === "Image") ? file : "",
            status: false,
            uploading: 0,
            msg:"",
            color:"",
            retry:"",
            aboveBelow: aboveBelow,
            aboveBelowPosition: aboveBelowPosition
        }
        var oldQueue = queue
        oldQueue.push(data)
        setQueue(oldQueue)
        setRefresh(refresh === "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }
    
    const changeFolder = (mainid, pid) => {
        
        setPid(pid)
        setMainId(mainid)
        setIsTrash(false)
        // console.log("/admin/assets/"+mainid+"/"+pid+additional_string)
        navigate("/admin/assets/"+mainid+"/"+pid+additional_string)
        getChildList(pid, mainid)
        
    }
    
    const backToRoot = () => {
        setPid(0)
        setMainId(0)
        setAddnew("")
        navigate("/admin/assets")
        fetchRecent()
    }
    
    const stillUploading = () => {
        if(queue.length > 0){
            for(var j = 0;j<queue.length;j++){
                if(!uploading && !queue[j].status){
                    setUploading(true)
                }
            }
        }
    }


    
    const bulkUploadUpdate = (files, type) => {
        
        var oldQueue = queue
        var data = {}
        var name = ""
        var format = ""
        var formats = []
        if(type === "Document"){
            formats = ["pdf"]
        }else if(type === "Image"){
            formats = ["png","jpg","jpeg","gif","svg"]
        }else if(type === "Video"){
            formats = ["mp4"]
        }
        

        for(var i=0;i<files.length;i++){
            name = files[i].name.split(".")
            
            format = (name.length > 1) ? name[(name.length - 1)] : ''

            for(var k = 0; k < (filesList[segment].length); k++){
                if(filesList[segment][k]['name'] === name[0]){
                    data = {
                        update: filesList[segment][k]['id'],
                        p_id: p_id,
                        main_id: main_id,
                        name: name[0],
                        type: type,
                        file: files[i],
                        encodeType: "NewEncode",
                        videoType: "English",
                        status: (formats.includes(format)) ? false : "Failed",
                        uploading: (formats.includes(format)) ? 0 : 100,
                        msg:(formats.includes(format)) ? "" : "Invalid "+type+" type (."+format+").",
                        color:(formats.includes(format)) ? "" : "#f46a6a",
                        retry:"",
                        aboveBelow: aboveBelow,
                        aboveBelowPosition: aboveBelowPosition
                    }
                    oldQueue.push(data)
                }
            }
        }

        setQueue(oldQueue)
        setRefresh(refresh === "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }

    const bulkUpload = (files, type) => {
        
        var oldQueue = queue
        var data = {}
        var name = ""
        var format = ""
        var formats = []
        if(type === "Document"){
            formats = ["pdf"]
        }else if(type === "Image"){
            formats = ["png","jpg","jpeg","gif","svg"]
        }else if(type === "Video"){
            formats = ["mp4"]
        }
        

        for(var i=0;i<files.length;i++){
            name = files[i].name.split(".")
            format = (name.length > 1) ? name[(name.length - 1)] : ''
                data = {
                    p_id: p_id,
                    main_id: main_id,
                    name: name[0],
                    type: type,
                    file: files[i],
                    status: (formats.includes(format)) ? false : "Failed",
                    uploading: (formats.includes(format)) ? 0 : 100,
                    msg:(formats.includes(format)) ? "" : "Invalid "+type+" type (."+format+").",
                    color:(formats.includes(format)) ? "" : "#f46a6a",
                    retry:"",
                    aboveBelow: aboveBelow,
                    aboveBelowPosition: aboveBelowPosition
                }
            
            oldQueue.push(data)
        }
        setQueue(oldQueue)
        setRefresh(refresh === "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }
    }

    const fileInfo = (id, type) => {
        // if(type !== "Question"){
            setPreviewLoading(true)
            fetch(apiurl+"api/file_information", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        accecible: 1,
                        id: id,
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics())
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){

                        var resp = response.info;
                        if(resp.type === "Folder"){
                            resp.color = "#FCD462"
                        }else if(resp.type === "Question"){
                            resp.color = "#5691F3"
                        }else if(resp.type === "Video"){
                            resp.color = "#f46a6a"                       

                        }else if(resp.type === "Live"){
                            resp.color = "#f1b44c"
                        }else if(resp.type === "Document"){
                            resp.color = "#f46a6a"
                        }else if(resp.type === "Image"){
                            resp.color = "#50a5f1"
                        }else if(resp.type === "Youtube"){
                            resp.color = "#f46a6a"
                        }else if(resp.type === "Live"){
                            resp.color = "#f46a6a"
                        }else if(resp.type === "Assignment"){
                            resp.color = "#98afba"
                        }else if(resp.type === "Recording"){
                            resp.color = "#f46a6a"
                        }else if(resp.type === "Unknown"){
                            resp.color = "#74788d"
                        }
                        resp.tab = "English"
                        resp.id=id
                        setPreview(resp)
                        

                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setPreviewLoading(false)
                })
                .catch(err => {
                    setPreviewLoading(false)
                });
            // }else{
            //     navigate("/admin/questions/"+params.id+"/"+params.p_id+"?qid="+id)
            // }
    }

    const copyUrl = (url, name) => {
        navigator.clipboard.writeText(url)
        successModal("Success", "URL Copied Of "+name)
        updatTrail("Copied the url of <a href='"+url+"'>"+name+"</a>", "Assets", "2")
    }

    const [contextMenuItems, setContextMenuItems] = useState("")
    const contextMenu = (e, id, position, type, edit_permission, remove_permission, info_permission, url_permission, file, name, download_permission, description, tags) => {
        e.preventDefault()
        
        var pageX = e.pageX
        var pageY = e.pageY
        setContextMenuItems(
            { 
            "id":id ,
            "position":position,
            "type":type,
            "edit":edit_permission,
            "remove":remove_permission,
            "info":info_permission,
            "url":url_permission,
            "download": download_permission,
            "pageX": pageX,
            "pageY": pageY,
            "file": file,
            "name": name,
            "description" : description,
            "tags": (tags === null) ? "" : tags
            }
        )
        setRefresh(refresh === "No" ? "Yes" : "No")
        setTimeout(function(){
            $("body").unbind().on("click", function(){
                $(".myContextMenu").hide();
            });
        },500);
        if($(".myContextMenu").length > 0){
            $(".myContextMenu").show();
        }
    }
    const importURLNewFolder = (e, type) => {
        e.preventDefault();
        
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = importurl.match(regExp);

        var oldQueue = queue
        var data = {}
        var name = ""
        var formats = []
        if(type === "Document"){
            formats = ["pdf"]
        }else if(type === "Image"){
            formats = ["png","jpg","jpeg","gif","svg"]
        }else if(type === "Video"){
            formats = ["mp4"]
        }


        if(type === "Video" && (match && match[2].length === 11)){
            window.$.get("https://www.googleapis.com/youtube/v3/videos?id="+match[2]+"&key=AIzaSyCuzljDmi8eAPF2J6r3udUQcnZum6c2mVk&part=snippet,contentDetails,statistics,status", {}, function(response){
                if(response.pageInfo.totalResults > 0){
                    data = {
                        p_id: p_id,
                        main_id: main_id,
                        name: response.items[0].snippet.title,
                        type: "Video",
                        file: "",
                        status: false,
                        uploading: 0,
                        msg: "Waiting...",
                        color: "#f46a6a",
                        retry:"",
                        aboveBelow: aboveBelow,
                        aboveBelowPosition: aboveBelowPosition,
                        youtubeID: match[2],
                        youtubeEmbedUrl:"https://www.youtube.com/embed/"+match[2],
                        youtubeDuration: response.items[0].contentDetails.duration
                    }
                    oldQueue.push(data)
                    setQueue(oldQueue)
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    if(!uploading){
                        uploadQueue()
                    }
                }
            })
        }else{
            var filename = importurl.split('/').pop().split('#')[0].split('?')[0];
            var namesplit = filename.split(".")
            
            var status = "Failed"
            var uploading = 100
            var msg = (typeof namesplit[1] !== "undefined") ? "Invalid "+type+" Type ("+namesplit[1]+")." : "Invalid "+type+" type (UNKNOWN)."
            var color = "#f46a6a"
            if(typeof namesplit[1] !== "undefined" && formats.includes(namesplit[1])){
                status = false
                uploading = 0
                msg = ""
                color = ""
            }

            data = {
                p_id: p_id,
                main_id: main_id,
                name: namesplit[0],
                type: type,
                file: "",
                status: status,
                uploading: uploading,
                msg: msg,
                color: color,
                retry:"",
                aboveBelow: aboveBelow,
                aboveBelowPosition: aboveBelowPosition,
                fileURL: importurl,
                fullName: filename
            }
            oldQueue.push(data)
            setQueue(oldQueue)
            setRefresh(refresh === "No" ? "Yes" : "No")
            if(!uploading){
                uploadQueue()
            }
        }


    }

    const [openPicker, authResponse] = useDrivePicker();  
    const handleOpenPicker = (type) => {

        var formats = "application/pdf"
        if(type === "Document"){
            formats = "application/pdf"
        }else if(type === "Image"){
            formats = "image/gif,image/svg+xml,image/png,image/jpg,image/jpeg"
        }else if(type === "Video"){
            formats = "video/mp4"
        }

        openPicker({
          clientId: "702904017207-jjvcos9ramjf72fe4gp8u3c8ukmr5f2n.apps.googleusercontent.com",
        //   developerKey: "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",
          developerKey: "AIzaSyAbb9pusMJcJCqSroGLLi7KjaNyPrp3caE",
          viewId: "DOCS",
          // token: token, // pass oauth token in case you already have one
          showUploadView: true,
          showUploadFolders: true,
          supportDrives: true,
          multiselect: true,
          viewMimeTypes: formats,
          callbackFunction: (data) => {
            if (data.action === 'cancel') {
              console.log('User clicked cancel/close button')
            }
            if(data.action === "picked"){
                if(data.docs.length > 0){
                    var list = data.docs;
                    
                    // https://drive.google.com/uc?export=view&id=

                    var oldQueue = queue
                    var data = {}
                    var name = ""
                    var format = ""
                    var formats = []
                    if(type === "Document"){
                        formats = ["pdf"]
                    }else if(type === "Image"){
                        formats = ["png","jpg","jpeg","gif","svg"]
                    }else if(type === "Video"){
                        formats = ["mp4"]
                    }

                    for(var i=0;i<list.length;i++){
                        name = list[i].name.split(".")
                        format = (name.length > 1) ? name[(name.length - 1)] : ''
                            data = {
                                p_id: p_id,
                                main_id: main_id,
                                name: name[0],
                                type: type,
                                file: "",
                                status: (formats.includes(format)) ? false : "Failed",
                                uploading: (formats.includes(format)) ? 0 : 100,
                                msg:(formats.includes(format)) ? "" : "Invalid "+type+" type (."+format+").",
                                color:(formats.includes(format)) ? "" : "#f46a6a",
                                retry:"",
                                aboveBelow: aboveBelow,
                                aboveBelowPosition: aboveBelowPosition,
                                fileURL: "https://drive.google.com/uc?export=view&id="+list[i].id,
                                fullName: list[i].name
                            }
                        
                        oldQueue.push(data)
                    }
                    setQueue(oldQueue)
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    if(!uploading){
                        uploadQueue()
                    }
                }
            }
          },
        })
    }

    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }

    const [storageInfoList, setStorageInfo] = useState("")
    const [storageInfoLoading, setStorageInfoLoading] = useState(false)

    const storageInfo = () => {
        setStorageInfoLoading(true)
        fetch(apiurl+"api/storageInfo", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setStorageInfo(response.storage)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setStorageInfoLoading(false)
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const copyFiles = (x) => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length > 0){
            setSelectedFiles(check)
            setSelectionType(x)
        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }
    
    const copySingle = (x, type) => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length === 0){
            window.$("#id"+x).find("input").prop("checked", true)
        }
        copyFiles(type)
    }

    
    const removeFiles = (x, type) => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length === 0){
            window.$("#id"+x).find("input").prop("checked", true)
        }
        removeDialog()
    }

    const removeFilesList = (check) => {

        alertModal("Loading...", "Removing files this may take a while.")

        fetch(apiurl+"api/remove_files", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    source_id: check,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    
                    getChildList(p_id, main_id)
                    successModal("Sucess", response.message)
                    setRefresh(refresh === "No" ? "Yes" : "No")

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });

    }

    const removeDialog = () => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length > 0){
            
            confirmAlert({
                title: 'Are You Sure?',
                message: "You want to remove this file?",
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => removeFilesList(check)
                  },
                  {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                  }
                ]
            });

        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }


    const pasteFiles = () => {
        var pasteUrl = (selectionType === "Copy") ? "paste_files" : "move_files"
        fetch(apiurl+"api/"+pasteUrl, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    source_id: selectedFiles,
                    destination_id: p_id,
                    main_id: main_id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    
                    var oldQueue = queue
                    var data = {}
                    for(var i = 0;i<response.response.length;i++){
                        data = {
                            p_id: 0,
                            main_id: 0,
                            name: response.response[i].name,
                            type: response.response[i].type,
                            file: "",
                            status: (response.response[i].status === "OK") ? true : "Failed",
                            uploading: 100,
                            msg: (response.response[i].status === "OK") ? response.response[i].type + ' Saved' : response.response[i].message,
                            color: (response.response[i].status === "OK") ? "#34c38f" : "#f46a6a",
                            retry: "",
                            aboveBelow: "",
                            aboveBelowPosition: "",
                            fileURL: "",
                            fullName: ""
                        }
                        oldQueue.push(data)
                    }
                    
                    setQueue(oldQueue)
                    
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    setSelectedFiles([])
                    setSelectionType([])
                    getChildList(p_id, main_id)
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    
    const[is_trash, setIsTrash] = useState(false)
    const [trashbinList, settrashBinList] = useState([])

    const showTrashbin = () => {
        
        fetch(apiurl+"api/trashbbin_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    settrashBinList(response.list)
                    setIsTrash(true)
                    // storageInfo()
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const restoreFile = (x) => {
        fetch(apiurl+"api/restoreFiles", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    id: x,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    showTrashbin()
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
        checkCount()
    }

    const clearselectedtrash = (check) => {
        alertModal("Loading...", "Removing files this may take a while.");
        fetch(apiurl+"api/clear_all_trash", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    check: check
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    showTrashbin()
                    successModal("Success", response.message)
                    window.$("#error_success").html("");
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const clearTrashBin = () => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length > 0){
            
            confirmAlert({
                title: 'Are You Sure?',
                message: "You want to remove this file?",
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => clearselectedtrash(check)
                  },
                  {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                  }
                ]
            });

        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }

    const searchBar = (x) => {
        var a = x.toLowerCase();
        window.$(".fileTitleList").each(function(){
            var b = $(this).text().toLowerCase()
            if(b.indexOf(a) != -1){
                $(this).parents("tr").show()
            }else{
                $(this).parents("tr").hide()
            }
        });
    }

    const [renameId, setRenameId] = useState("")
    const renameModal = (id, name, description, type = "Question", tags = "") => {
        if(type === "Live"){
            navigate("/admin/liveclasses/"+params.id+"/"+params.p_id+"?qid="+id)
        }

        console.log(tags, "Asdasd")
        setName(name)
        setTags((tags === null) ? "" : tags)
        setRenameId(id)
        setDescription(description)
        setEditor("")

        if(type === "Assignment"){
            setAssignmentType("Edit")
            setAssignmentId(id)
            setAddAssignment(true)
        }
        if(type === "Question"){
            navigate("/admin/questions/"+params.id+"/"+params.p_id+"?qid="+id)
        }

        if(type !== "Question" && type !== "Assignment"){
            
                if(type !== "Folder"){
                    setEditorLoading(true)
                    setTimeout(function(){
                        setEditor(
                            <CKEditor
                                id={"ck-editor-text"}
                                config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
                                editor={ ClassicEditor }
                                data={description}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setDescription(data)
                                } }
                            />
                        )
                        setEditorLoading(false)
                    }, 500)
                }
            window.$("#rename").fadeIn()
        }

    }

    const renameFile = () => {
        const newName = name
        const renamid = renameId
        if(name === ""){
            warningModal("Name Cannot ne null", "Please enter name of the item")
        }else{
            fetch(apiurl+"api/rename_file", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics()),
                        accecible: 1,
                        name: newName,
                        tags: tags,
                        renameId: renamid,
                        description: description
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        setName("")
                        setTags("")
                        setRenameId("")
                        window.$("#rename").fadeOut()
                        getChildList(p_id, main_id)
                        successModal("Success", response.message)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                })
                .catch(err => {
                    // console.log(err);
                });
            }
    }


    const markPin = (id) => {

        fetch(apiurl+"api/markPin", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    id: id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    
                    if(p_id !== "" && main_id !== "" && p_id !== 0 && main_id !== 0){
                        getChildList(p_id, main_id)
                    }else{
                        fetchRecent()
                    }
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
        
    }


    const goToFolderWithHighlight = (main_id, p_id, id) => {
        setHighlighter(id)
        changeFolder(main_id, p_id)
    }


    const updateVideo = (e, id, videoType, fileType, encodeType) => {
        var vid = e.target.files[0]
        var type = fileType

        
        var oldQueue = queue
        var data = {}
        var name = ""
        var format = ""

        var formats = []
        if((type === "Document") || (type === "Assignment")){
            formats = ["pdf"]
        }else if(type === "Image"){
            formats = ["png","jpg","jpeg","gif","svg"]
        }else if(type === "Video" || type === "Recording"){
            formats = ["mp4"]
        }
        console.log(type, formats)

        name = vid.name.split(".")
        format = (name.length > 1) ? name[(name.length - 1)] : ''

        data = {
            update:id,
            p_id: p_id,
            main_id: main_id,
            name: (fileType === "Video") ? name[0]+" ("+videoType+" Video)" : name[0],
            type: type,
            file: vid,
            encodeType: encodeType,
            videoType: videoType,
            status: (formats.includes(format)) ? false : "Failed",
            uploading: (formats.includes(format)) ? 0 : 100,
            msg:(formats.includes(format)) ? "" : "Invalid "+type+" type (."+format+").",
            color:(formats.includes(format)) ? "" : "#f46a6a",
            retry:""
        }
    
        oldQueue.push(data)
        setQueue(oldQueue)
        setRefresh(refresh === "No" ? "Yes" : "No")
        if(!uploading){
            uploadQueue()
        }

    }
    
    const changePreviewTab = (tab) => {
        var prev = preview
        prev.tab = tab
        setPreview(prev)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const downloadRequest = (id) => {
        fetch(apiurl+"api/assets_download_request", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    id: id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });   
    }

    const checkCount = () => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });

        window.$("#error_success").html('')
        if(check.length > 0){
            alertModal("Files Selection", "You have selected ("+check.length+") Items.")
        }
    }

    const [liveCLassesList, setLiveClassesList] = useState([])
    const [liveCLassesListLoading, setLiveClassesListLoading] = useState(true)
    const getLiveClassList = () => {
        setLiveClassesListLoading(true)
        fetch(apiurl+"api/getLiveClassList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({})
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setLiveClassesList(response.list)
                    setLiveClassesListLoading(false)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });   
    }

    const endClass = (item) => {
        warningModal("Loading", "Ending class please wait...");
        fetch(apiurl+"api/endClass", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                id:item.id,
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
            })
            })
            .then(response => response.json())
            .then(response => {
                getLiveClassList()
                successModal("Success", "Class Ended Successfully")
            })
            .catch(err => {
                // console.log(err);
            });  
    }
    

    const reEncyptVideo = (prev) => {
        if(window.confirm("Are you sure?")){
            alertModal("Processing...", "");
            fetch(apiurl+"api/reEncyptVideo", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    id:prev.id,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
                })
                .then(response => response.json())
                .then(response => {
                    successModal("Success", "Video Scheduled to encrypt");
                    window.$("#error_success").html("");
                })
                .catch(err => {
                    // console.log(err);
                });  
        }
    }

    var a = 0
    useEffect(() => {
        additional_string = ""
        additional_string += importing
        if(p_id === 0){
            fetchRecent()
        }
        if(a === 0){
            
            stillUploading()
            // getLiveClassList()
            // storageInfo()
            if(p_id != 0){
                getChildList(p_id, main_id)
            }
            a++
        }
    }, [])
    
    return(
        <>

        {
            contextMenuItems !== "" &&
                <div className='myContextMenu' style={{left:contextMenuItems.pageX, top:contextMenuItems.pageY}}>
                    <ul>
                        {
                            pagePermissions(props.pagepermissions, "Assets", "show_create_files_button") &&
                            <>
                                <li>
                                    <div className='contextNameCon'>
                                        <FeatherIcon icon={"arrow-up"} />
                                        Add Above
                                    </div>
                                    <FeatherIcon icon={"chevron-right"}/>

                                    <div className='contextMenu2'>
                                        <ul>
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_folders") &&
                                                <li onClick={() => changeAddTab("Folder", "Above", contextMenuItems.position)} title="Add New Folder">
                                                    <div className='contextNameCon'>
                                                        <FeatherIcon icon={"folder-plus"} />
                                                        New Folder
                                                    </div>
                                                </li>
                                            }
                                            {
                                                // main_id != 3 &&
                                                <>
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_document") &&
                                                    <li onClick={() => changeAddTab("Document", "Above", contextMenuItems.position)} title="Upload Document">
                                                        <div className='contextNameCon'>
                                                            <FeatherIcon icon={"file-text"} />
                                                            Upload Document
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_video") &&
                                                    <li onClick={() => changeAddTab("Video", "Above", contextMenuItems.position)} title="Upload Video">
                                                        <div className='contextNameCon'>
                                                            <FeatherIcon icon={"video"} />
                                                            Upload Video
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                    <li onClick={() => changeAddTab("Image", "Above", contextMenuItems.position)} title="Upload Image">
                                                        <div className='contextNameCon'>
                                                            <FeatherIcon icon={"image"} />
                                                            Upload Image
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    // pagePermissions(props.pagepermissions, "Assets", "add_live_class") &&
                                                    // <li title='Add Live Class' onClick={() => changeAddTab("Live", "", "")}>
                                                    //     <div className='contextNameCon'>
                                                    //         <FeatherIcon icon={"cast"} />
                                                    //         Add Live Class
                                                    //     </div>
                                                    // </li>
                                                }
                                                </>
                                            }
                                            {
                                                // main_id === 3 && 
                                                pagePermissions(props.pagepermissions, "Assets", "add_question") &&
                                                <li onClick={() => changeAddTab("Question", "Above", contextMenuItems.position)}>
                                                    <div className='contextNameCon'>
                                                        <FeatherIcon icon={"help-circle"} />
                                                        Add Question
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='contextNameCon'>
                                        <FeatherIcon icon={"arrow-down"} />
                                        Add Below
                                    </div>
                                    <FeatherIcon icon={"chevron-right"} />
                                    
                                    <div className='contextMenu2'>
                                        <ul>
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_folders") &&
                                                <li onClick={() => changeAddTab("Folder", "Below", contextMenuItems.position)} title="Add New Folder">
                                                    <div className='contextNameCon'>
                                                        <FeatherIcon icon={"folder-plus"} />
                                                        New Folder
                                                    </div>
                                                </li>
                                            }
                                            {
                                                // main_id != 3 &&
                                                <>
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_document") &&
                                                    <li onClick={() => changeAddTab("Document", "Below", contextMenuItems.position)} title="Upload Document">
                                                        <div className='contextNameCon'>
                                                            <FeatherIcon icon={"file-text"} />
                                                            Upload Document
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_video") &&
                                                    <li onClick={() => changeAddTab("Video", "Below", contextMenuItems.position)} title="Upload Video">
                                                        <div className='contextNameCon'>
                                                            <FeatherIcon icon={"video"} />
                                                            Upload Video
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                    <li onClick={() => changeAddTab("Image", "Below", contextMenuItems.position)} title="Upload Image">
                                                        <div className='contextNameCon'>
                                                            <FeatherIcon icon={"image"} />
                                                            Upload Image
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    // pagePermissions(props.pagepermissions, "Assets", "add_live_class") &&
                                                    // <li title='Add Live Class' onClick={() => changeAddTab("Live", "", "")}>
                                                    //     <div className='contextNameCon'>
                                                    //         <FeatherIcon icon={"cast"} />
                                                    //         Add Live Class
                                                    //     </div>
                                                    // </li>
                                                }
                                                </>
                                            }
                                            {
                                                // main_id === 3 && 
                                                pagePermissions(props.pagepermissions, "Assets", "add_question") &&
                                                <li onClick={() => changeAddTab("Question", "Below", contextMenuItems.position)}>
                                                    <div className='contextNameCon'>
                                                        <FeatherIcon icon={"help-circle"} />
                                                        Add Question
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                            </>
                        }
                        
                            <>
                            {
                                pagePermissions(props.pagepermissions, "Assets", "copy") &&
                                <li onClick={() => copySingle(contextMenuItems.id, "Copy")}>
                                    <div className='contextNameCon' title='Copy' >
                                        <FeatherIcon icon={"copy"} />
                                        Copy
                                    </div>
                                </li>
                            }
                            {
                                pagePermissions(props.pagepermissions, "Assets", "cut") &&
                                <li onClick={() => copySingle(contextMenuItems.id, "Cut")}>
                                    <div className='contextNameCon' title='Move' >
                                        <FeatherIcon icon={"scissors"} />
                                        Cut
                                    </div>
                                </li>
                            }
                            </>
                        {
                            selectedFiles.length > 0 &&
                            <li onClick={() => pasteFiles()}>
                                <div className='contextNameCon' title='Paste' >
                                    <FeatherIcon icon={"clipboard"} />
                                    Paste
                                </div>
                            </li>
                        }
                        

                        {
                            contextMenuItems.info &&
                            <li onClick={() => fileInfo(contextMenuItems.id, contextMenuItems.type)}>
                                <div className='contextNameCon' title='View Information'>
                                    <FeatherIcon icon={"info"} />
                                    View Details
                                </div>
                            </li>
                        }
                        {
                            contextMenuItems.url &&
                            <li onClick={() => copyUrl(assets_url+contextMenuItems.file, contextMenuItems.name)}>
                                <div className='contextNameCon' title='Copy URL' >
                                    <FeatherIcon icon={"link"} />
                                    Get Link
                                </div>
                            </li>
                        }
                        {
                            contextMenuItems.download &&
                            <li onClick={() =>  downloadRequest(contextMenuItems.id)}>
                                <div className='contextNameCon' title='Download' >
                                    <FeatherIcon icon={"download"} />
                                    Download
                                </div>
                            </li>
                        }
                        
                        {
                            contextMenuItems.type &&
                            <li onClick={() => renameModal(contextMenuItems.id, contextMenuItems.name, contextMenuItems.description, contextMenuItems.type, contextMenuItems.tags)}>
                                <div className='contextNameCon'>
                                    <FeatherIcon icon={"edit"} />
                                    Rename {contextMenuItems.type}
                                </div>
                            </li>
                        }
                        {
                            contextMenuItems.remove &&
                            <li onClick={() => removeFiles(contextMenuItems.id)}>
                                <div className='contextNameCon'>
                                    <FeatherIcon icon={"trash"} />
                                    Remove {contextMenuItems.type}
                                </div>
                            </li>
                        }

                    </ul>
                </div>
        }
        {
            
            <div className='assets-lib'>
                <div className='col-md-3 assetsbars'>

                    {
                        filterObject.searchType === "All" ?
                        <div className='box no-border filtersArea' style={{maxHeight:"fit-content"}} >
                        <div className='box-header'>
                            <h3 className='box-title'>
                                <strong> Filters </strong>
                            </h3>
                            <span className='pull-right cursor-pointer' title='Close' onClick={() => {
                                        var temp = filterObject
                                        temp['searchType'] = ""
                                        temp['fileTypes'] = ""
                                        setFilterObject(temp)
                                        getChildList(p_id, main_id)
                                    } } >
                                <i className='fa fa-times'></i>
                            </span>
                        </div>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Item Created 
                            </h3>
                            {
                                (filter['created_from'] !== "" || filter['created_to'] !== "") && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("created_from,created_to")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                        </div>
                        <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                            <ul className='filtersBody'>
                                <li>
                                    <div>
                                        <label>
                                            From 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.created_from} onChange={(e) => setFilterValues("created_from", e.target.value) } />
                                </li>
                                <li>
                                    <div>
                                        <label>
                                            To 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.created_to} onChange={(e) => setFilterValues("created_to", e.target.value) }  />
                                </li>
                            </ul>
                        </div>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Item Modified 
                            </h3>
                            {
                                (filter['updated_from'] !== "" || filter['updated_to'] !== "") && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("updated_from,updated_to")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                        </div>
                        <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                            <ul className='filtersBody'>
                                <li>
                                    <div>
                                        <label>
                                            From 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.updated_from} onChange={(e) => setFilterValues("updated_from", e.target.value) }  />
                                </li>
                                <li>
                                    <div>
                                        <label>
                                            To 
                                        </label>
                                    </div>
                                    <input type={"date"} className="form-control" value={filter.updated_to} onChange={(e) => setFilterValues("updated_to", e.target.value) }  />
                                </li>
                            </ul>
                        </div>


                            {
                                filterObject.fileTypes === "Assignment" &&
                                <>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Marks Between
                                        </h3>
                                        {
                                            (filter['marks_from'] !== "" || filter['marks_to'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("marks_from,marks_to")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li>
                                                <div>
                                                    <label>
                                                        From 
                                                    </label>
                                                </div>
                                                <input type={"number"} className="form-control" value={filter.marks_from} onChange={(e) => setFilterValues("marks_from", e.target.value) }  placeholder={"e.g 1"} />
                                            </li>
                                            <li>
                                                <div>
                                                    <label>
                                                        To 
                                                    </label>
                                                </div>
                                                <input type={"number"} className="form-control" value={filter.marks_to} onChange={(e) => setFilterValues("marks_to", e.target.value) }  placeholder={"e.g 50"} />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Availability
                                        </h3>
                                        {
                                            (filter['availability'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("availability")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li onClick={(e) => setFilterValues("availability", '1') } >
                                                <div>
                                                    <label>
                                                        Lifetime
                                                    </label>
                                                </div>
                                                {
                                                    filter.availability === "1" || filter.availability === 1 ?
                                                    <FeatherIcon icon={"check-circle"} />
                                                    :
                                                    <FeatherIcon icon={"circle"} />
                                                }
                                            </li>
                                            <li onClick={(e) => setFilterValues("availability", 'Time') }>
                                                <div>
                                                    <label>
                                                    Time Based
                                                    </label>
                                                </div>
                                                {
                                                    filter.availability === "Time" ?
                                                    <FeatherIcon icon={"check-circle"} />
                                                    :
                                                    <FeatherIcon icon={"circle"} />
                                                }
                                            </li>
                                        </ul>
                                    </div>



                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Start From
                                        </h3>
                                        {
                                            (filter['start_from'] !== "" || filter['start_to'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("start_from,start_to")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li>
                                                <div>
                                                    <label>
                                                        From 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.start_from} onChange={(e) => setFilterValues("start_from", e.target.value) }  />
                                            </li>
                                            <li>
                                                <div>
                                                    <label>
                                                        To 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.start_to} onChange={(e) => setFilterValues("start_to", e.target.value) }  />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            End From
                                        </h3>
                                        {
                                            (filter['end_from'] !== "" || filter['end_to'] !== "") && 
                                            <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("end_from,end_to")} >
                                                <strong>
                                                    Clear
                                                </strong>
                                            </span>
                                        }
                                    </div>
                                    <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                        <ul className='filtersBody'>
                                            <li>
                                                <div>
                                                    <label>
                                                        From 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.end_from} onChange={(e) => setFilterValues("end_from", e.target.value) }  />
                                            </li>
                                            <li>
                                                <div>
                                                    <label>
                                                        To 
                                                    </label>
                                                </div>
                                                <input type={"date"} className="form-control" value={filter.end_to} onChange={(e) => setFilterValues("end_to", e.target.value) }  />
                                            </li>
                                        </ul>
                                    </div>

                                </>
                            }


                        {
                            filterObject.fileTypes === "Live" &&
                            <>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Class Date
                                    </h3>
                                    {
                                        (filter['class_date'] !== "" ) && 
                                        <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("class_date")} >
                                            <strong>
                                                Clear
                                            </strong>
                                        </span>
                                    }
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                            <div>
                                                <label>
                                                    Select Class Date 
                                                </label>
                                            </div>
                                            <input type={"date"} className="form-control" value={filter.class_date} onChange={(e) => setFilterValues("class_date", e.target.value) }  />
                                        </li>
                                    </ul>
                                </div>

                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Class Type
                                    </h3>
                                    {
                                        (filter['class_type'] !== "" ) && 
                                        <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("class_type")} >
                                            <strong>
                                                Clear
                                            </strong>
                                        </span>
                                    }
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li onClick={(e) => setFilterValues("class_type", 'Over') } >
                                            <div>
                                                <label>
                                                    Over
                                                </label>
                                            </div>
                                            {
                                                filter.class_type === "Over" ?
                                                <FeatherIcon icon={"check-circle"} />
                                                :
                                                <FeatherIcon icon={"circle"} />
                                            }
                                        </li>
                                        <li onClick={(e) => setFilterValues("class_type", 'Upcomming') }>
                                            <div>
                                                <label>
                                                Upcomming
                                                </label>
                                            </div>
                                            {
                                                filter.class_type === "Upcomming" ?
                                                <FeatherIcon icon={"check-circle"} />
                                                :
                                                <FeatherIcon icon={"circle"} />
                                            }
                                        </li>
                                    </ul>
                                </div>

                            </>
                        }
                        {
                            filterObject.fileTypes !== "Question" && filterObject.fileTypes !== "Live" && filterObject.fileTypes !== "Assignment" &&
                            <>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Item Size 
                                </h3>
                                {
                                    (filter['size_from'] !== "" ) && 
                                    <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("size_from")} >
                                        <strong>
                                            Clear
                                        </strong>
                                    </span>
                                }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <div>
                                            <label>
                                                From (MB)
                                            </label>
                                        </div>
                                        <input type={"number"} className="form-control" step={0.0001} placeholder={"e.g 0.1"}  value={filter.size_from} onChange={(e) => setFilterValues("size_from", e.target.value) }   />
                                    </li>
                                    <li>
                                        <div>
                                            <label>
                                                To (MB)
                                            </label>
                                        </div>
                                        <input type={"number"} className="form-control" step={0.0001} placeholder={"e.g 10"}  value={filter.size_to} onChange={(e) => setFilterValues("size_to", e.target.value) }  />
                                    </li>
                                </ul>
                            </div>
                            </>

                    }

                    {
                        filterObject.fileTypes === "Video,Youtube" &&
                        <>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Video Encrypted
                                </h3>
                            {
                                (filter['video_encryped'] !== "" ) && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("video_encryped")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <select className='form-control'  onChange={(e) => setFilterValues("video_encryped", e.target.value) } defaultValue={filter.video_encryped} >
                                            <option value={""}>All</option>
                                            <option value={"Yes"}>Yes</option>
                                            <option value={"No"}>No</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </>
                    }

                    {
                        filterObject.fileTypes === "Question" &&
                        <>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Question Type
                                </h3>
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <label style={{width:"100%"}}>
                                            Objective
                                            <span className='pull-right'>
                                                <input type={"checkbox"} value={"Objective"} onClick={(e) => selectQuestionType("Objective")} />
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label style={{width:"100%"}}>
                                            Subjective
                                            <span className='pull-right'>
                                                <input type={"checkbox"} value={"Subjective"} onClick={(e) => selectQuestionType("Subjective")}  />
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label style={{width:"100%"}}>
                                            Fill In The Blanks
                                            <span className='pull-right'>
                                                <input type={"checkbox"} value={"Fill_In_The_Blanks"} onClick={(e) => selectQuestionType("Fill_In_The_Blanks")}  />
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label style={{width:"100%"}}>
                                            True And False
                                            <span className='pull-right'>
                                                <input type={"checkbox"} value={"True_And_False"} onClick={(e) => selectQuestionType("True_And_False")}  />
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label style={{width:"100%"}}>
                                            Match The Following
                                            <span className='pull-right'>
                                                <input type={"checkbox"} value={"Match_The_Following"} onClick={(e) => selectQuestionType("Match_The_Following")}  />
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Question Marks
                                </h3>
                                {
                                    (filter['size_from'] !== "" ) && 
                                    <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("size_from")} >
                                        <strong>
                                            Clear
                                        </strong>
                                    </span>
                                }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <div>
                                            <label>
                                                (+ve)
                                            </label>
                                        </div>
                                        <input type={"number"} className="form-control" step={0.01} placeholder={"e.g 0.1"}  value={filter.correct_marks} onChange={(e) => setFilterValues("correct_marks", e.target.value) }   />
                                    </li>
                                    <li>
                                        <div>
                                            <label>
                                                (-ve)
                                            </label>
                                        </div>
                                        <input type={"number"} className="form-control" step={0.01} placeholder={"e.g 10"}  value={filter.negetive_marks} onChange={(e) => setFilterValues("negetive_marks", e.target.value) }  />
                                    </li>
                                </ul>
                            </div>
                            
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Answer Type
                                </h3>
                            {
                                (filter['answer_type'] !== "" ) && 
                                <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("answer_type")} >
                                    <strong>
                                        Clear
                                    </strong>
                                </span>
                            }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <select className='form-control'  onChange={(e) => setFilterValues("answer_type", e.target.value) } defaultValue={filter.answer_type} >
                                            <option value={""}>All</option>
                                            <option value={"Single"}>Single</option>
                                            <option value={"Multi"}>Multiple</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </>
                    }
                        
                    </div>
                        :



                    <div className='box no-border'>
                        <div className='box-header'>
                            <div className="btn-group btn-block">
                                
                                <div className='createbtnsCon'>
                                    {
                                        p_id != 0 &&
                                        <>
                                        <button type="button" className="btn iconbtns backbtn" title='Back' onClick={() => navigate(-1)}>
                                            <FeatherIcon icon={"arrow-left"} />
                                        </button>
                                        {
                                            pagePermissions(props.pagepermissions, "Assets", "show_create_files_button") &&
                                            <button type="button" className="btn btn-block iconbtns dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                <FeatherIcon icon="plus"/> Create New
                                            </button>
                                        }
                                        <ul className="dropdown-menu new-dropdown">

                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_folders") &&
                                                <li onClick={() => changeAddTab("Folder", "", "")} title="Add New Folder">
                                                    <FeatherIcon icon={"folder-plus"} />
                                                    New Folder
                                                </li>
                                            }
                                            {
                                                // main_id != 3 &&
                                                <>
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_document") &&
                                                    <li onClick={() => changeAddTab("Document", "", "")} title="Upload Document">
                                                        <FeatherIcon icon={"file-text"} />
                                                        Upload Document
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_video") &&
                                                    <li onClick={() => changeAddTab("Video", "", "")} title="Upload Video">
                                                        <FeatherIcon icon={"video"} />
                                                        Upload Video
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                    <li onClick={() => changeAddTab("Image", "", "")} title="Upload Image">
                                                        <FeatherIcon icon={"image"} />
                                                        Upload Image
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_live_class") &&
                                                    <li title='Add Live Class' onClick={() => changeAddTab("Live", "", "")}>
                                                        <FeatherIcon icon={"cast"} />
                                                        Add Live Class
                                                    </li>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Assets", "add_assignment") &&
                                                    <li title='Add Assigments' onClick={() => changeAddTab("Assignment", "", "")}>
                                                        <FeatherIcon icon={"file-text"} />
                                                        Add Assigments
                                                    </li>
                                                }
                                                </>
                                            }
                                            {
                                                // main_id === 3 && 
                                                pagePermissions(props.pagepermissions, "Assets", "add_question") &&
                                                <li onClick={() => changeAddTab("Question", "", "")}>
                                                    <FeatherIcon icon={"help-circle"} />
                                                    Add Question
                                                </li>
                                            }
                                            
                                        </ul>
                                        </>
                                    }
                                    
                                </div>
                                
                                {
                                    p_id === 0 &&
                                    <h3 className='box-title'>
                                        Root
                                    </h3>
                                }
                            </div>
                        </div>
                        <div className='box-body'>
                            {
                                addNew === "" &&
                            <ul className='assets_list'>
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_file_manager") &&
                                    
                                        <li onClick={() => changeFolder(2, 2)} className={(main_id === "2" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/folder.png'} />
                                                File Manager 
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_questions") &&
                                    
                                        <li onClick={() => changeFolder(3, 3)} className={(main_id === "3" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/information.png'} />
                                                Question Bank
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_promotional") &&
                                    
                                    
                                        <li onClick={() => changeFolder(4, 4)} className={(main_id === "4" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/tag.png'} />
                                                Promotionals Items
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_live_classes") &&
                                    
                                    
                                        <li onClick={() => changeFolder(5, 5)} className={(main_id === "5" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/voice.png'} />
                                                Live Classes
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_recorded_classes") &&
                                    
                                    
                                        <li onClick={() => changeFolder(7, 7)} className={(main_id === "7" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/live.png'} />
                                                Recorded Classes
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_assignments") && importing === "" &&
                                        <li onClick={() => changeFolder(8, 8)} className={(main_id === "8" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/list.png'} />
                                                Assigments
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_uncategorized") && importing === "" &&
                                        <li onClick={() => changeFolder(6, 6)} className={(main_id === "6" && !is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/options.png'} />
                                                Uncategorized
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "view_trash_bin") && importing === "" &&
                                        <li onClick={() => showTrashbin()} className={(is_trash) ? 'active-assets-menu' : ""}>
                                            <span>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/recycle-bin.png'} />
                                                Trash
                                            </span>
                                            <span className='arrow-r'>
                                                <FeatherIcon icon={"arrow-right"} />
                                            </span>
                                        </li>
                                        
                                }
                                
                            </ul>
                            }

                            {
                                addNew === "Folder" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Folder")}>
                                    <div className='assets_form_con'>
                                        <label>Folder Name</label>
                                        <input type={"text"} name="name" className='form-control'  placeholder={'e.g New Folder'}  required onChange={(e) => setName(e.target.value)}  />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"plus"} /> Create Folder
                                    </button>
                                </form>    
                                </>
                            }
                            
                            {
                                addNew === "Document" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Document")}>
                                    <div className='assets_form_con'>
                                        <label>Document Name</label>
                                        <input type={"text"} name="name" className='form-control' onChange={e => setName(e.target.value) } placeholder={'e.g Final Report'}  required />
                                    </div>
                                    <br />
                                    <div className='assets_form_con'>
                                        <label>Select Document</label>
                                        <input type={"file"} name="file" className='form-control' onChange={e => setFile(e.target.files[0]) } accept=".pdf" placeholder={'e.g Final Report'}   required />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"file-text"} /> Add Document
                                    </button>
                                </form>    
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Document")}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <section>
                                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                                            <input {...getInputProps()} accept=".pdf"/>
                                            
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            }

                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                                
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='linkImportArea'>
                                        <form action='' method='POST' onSubmit={e => importURLNewFolder(e, "Document")}>
                                            <div className='assets_form_con'>
                                                <div style={{width:"100%"}}>
                                                    <label>Import From URL</label>
                                                    <input type={"url"} name="url" className='form-control' onChange={e => setURL(e.target.value) } placeholder={'e.g Final Report'}  required />
                                                </div>
                                                <button className='btn btn-primary btn-flat'>
                                                    <FeatherIcon icon={"check-circle"} />
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='importFromDriveBtnsCon' onClick={() => handleOpenPicker("Document")} title={"Upload Documents From Google Drive"} >
                                        <div className='pickerBtn'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/google-drive.png'} />
                                            Import From Drive
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                addNew === "Video" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Video")}>
                                    <div className='assets_form_con'>
                                        <label>Video Name</label>
                                        <input type={"text"} name="name" className='form-control' onChange={e => setName(e.target.value) } placeholder={'e.g Introduction'}   required />
                                    </div>
                                    <br />
                                    <div className='assets_form_con'>
                                        <label>Select Video</label>
                                        <input type={"file"} name="file" className='form-control' onChange={e => setFile(e.target.files[0]) } accept=".mp4" placeholder={'e.g Introduction'}   required />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"video"} /> Add Video
                                    </button>
                                </form>    
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Video")}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <section>
                                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                                            <input {...getInputProps()} accept=".mp4"/>
                                            
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            }

                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                                
                                <div className='orCon'>
                                    <div className='leftbar'></div>
                                    <span className='text-center'>OR</span> 
                                    <div className='rightbar'></div>
                                </div>
                                <br />
                                <div className='linkImportArea'>
                                    <form action='' method='POST' onSubmit={e => importURLNewFolder(e, "Video")}>
                                        <div className='assets_form_con'>
                                            <div style={{width:"100%"}}>
                                                <label>Import From URL</label>
                                                <input type={"url"} name="url" className='form-control' onChange={e => setURL(e.target.value) } placeholder={'e.g Final Report'}  required />
                                            </div>
                                            <button className='btn btn-primary btn-flat'>
                                                <FeatherIcon icon={"check-circle"} />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>



                                    <br />
                                    <div className='importFromDriveBtnsCon' onClick={() => handleOpenPicker("Video")} title={"Upload Videos From Google Drive"} >
                                        <div className='pickerBtn'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/google-drive.png'} />
                                            Import From Drive
                                        </div>
                                    </div>

                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center' style={{color:"green"}}><strong>UPDATE FILES</strong></span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <Dropzone onDrop={acceptedFiles => bulkUploadUpdate(acceptedFiles, "Video")}>
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <section>
                                            <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"} style={{border:"5px dashed green"}} >
                                                <input {...getInputProps()} accept=".mp4"/>
                                                
                                                {
                                                    isDragActive ?
                                                    <p>Drop the files here ...</p> :
                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                }

                                            </div>
                                            </section>
                                        )}
                                    </Dropzone>

                                </>
                            }
                            {
                                addNew === "Image" &&
                                <>
                                <form action='' method='POST' onSubmit={e => addNewFolder(e, "Image")}>
                                    <div className='assets_form_con'>
                                        <label>Image Name</label>
                                        <input type={"text"} name="name" className='form-control' onChange={e => setName(e.target.value) } placeholder={'e.g Final Report'}   required />
                                    </div>
                                    <br />
                                    <div className='assets_form_con'>
                                        <label>Select Image</label>
                                        <input type={"file"} name="file" className='form-control' onChange={e => setFile(e.target.files[0]) } accept=".png, .jpg, .jpeg, .gif, .jpeg, .svg" placeholder={'e.g Final Report'}   required />
                                    </div>
                                    <br />
                                    <button className='btn btn-primary btn-flat btn-block iconbtns' type='submit' >
                                        <FeatherIcon icon={"image"} /> Add Image
                                    </button>
                                </form>    
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Image")}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <section>
                                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                                            <input {...getInputProps()} />
                                            
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            }

                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                                
                                <div className='orCon'>
                                    <div className='leftbar'></div>
                                    <span className='text-center'>OR</span> 
                                    <div className='rightbar'></div>
                                </div>
                                <br />
                                <div className='linkImportArea'>
                                    <form action='' method='POST' onSubmit={e => importURLNewFolder(e, "Image")}>
                                        <div className='assets_form_con'>
                                            <div style={{width:"100%"}}>
                                                <label>Import From URL</label>
                                                <input type={"url"} name="url" className='form-control' onChange={e => setURL(e.target.value) } placeholder={'e.g Final Report'}  required />
                                            </div>
                                            <button className='btn btn-primary btn-flat'>
                                                <FeatherIcon icon={"check-circle"} />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                    <br />
                                    <div className='orCon'>
                                        <div className='leftbar'></div>
                                        <span className='text-center'>OR</span> 
                                        <div className='rightbar'></div>
                                    </div>
                                    <br />
                                    <div className='importFromDriveBtnsCon' onClick={() => handleOpenPicker("Image")} title={"Upload Images From Google Drive"} >
                                        <div className='pickerBtn'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/google-drive.png'} />
                                            Import From Drive
                                        </div>
                                    </div>

                                </>
                            }


                        </div>
                        {
                        //     addNew === "" && pagePermissions(props.pagepermissions, "Assets", "view_trash_bin") && importing === "" && removingToday > 0 &&
                            // encrypt !== null && encrypt !== undefined &&
                            // <div className='box-body grid_all'  title={ (encrypt.currentVideos !== null) ? "Encrypting " + encrypt.currentVideos.name : ""} onClick={() => (encrypt.currentVideos !==  null) ? changeFolder(encrypt.currentVideos.main_parent, encrypt.currentVideos.p_id) : ""} >
                            //     <div className='col-md-12 grid_box_parent'>
                            //         <div className={  (encrypt.encryptFlag) ? 'grid-box danger-color' : 'grid-box warning-color'}>
                            //             {/* <div className='grid-box-header'>
                            //                 <img src={process.env.PUBLIC_URL+'/assets/assets/images/warning.png'} />
                            //             </div> */}
                            //             <div>
                            //                 {
                            //                     encrypt.currentVideos !== null &&
                            //                     <p className='grid-box-title'>
                            //                         Encrypting {encrypt.currentVideos.name}
                            //                     </p>
                            //                 }
                            //                 <p className='grid-box-items'>
                            //                     {encrypt.remainingVideos} Videos is remaining to encrypt.
                            //                 </p>
                            //             </div>    
                            //         </div>
                            //     </div>
                            // </div>
                        }
                        {
                            liveCLassesList.length > 0 &&
                            <>
                            <div className='box-header no-left-padding no-right-padding'>
                                <h3 className='box-title'>
                                    Active Live Classes
                                </h3>
                                <span className='pull-right badge bg-yellow' onClick={() => getLiveClassList()}>
                                    <i className='fa fa-refresh'></i>
                                </span>
                            </div>
                            {
                                liveCLassesListLoading &&
                                <div className='box-body' style={{position:"relative"}} >
                                    <br /><br /><br /><br /><br /><br />
                                    <Loading />
                                    <br /><br /><br /><br /><br /><br />
                                    <br /><br /><br /><br /><br /><br />
                                </div>
                            }
                            
                            {
                                !liveCLassesListLoading &&
                                liveCLassesList.map((item, index) => {
                                    return(
                                        <div className='box-footer box-comments'>
                                            <div className="box-comment">
                                                <img className="img-circle img-sm" src={process.env.PUBLIC_URL+'/assets/assets/images/voice.png'} alt="User Image" />

                                                <div className="comment-text">
                                                    <span className="username">
                                                        {item.classdata.title}
                                                        <span className="text-muted pull-right">By <a href={'/admin/staff?id='+item.staff.id} title='View Profile'>{item.staff.name}</a></span>
                                                    </span>
                                                    <small>
                                                    {
                                                        item.path.length > 0 &&
                                                        item.path.map((item2, index2) => {
                                                            return(
                                                                index2 === 0 ?
                                                                item2.name + ": "
                                                                :
                                                                <a href={'/admin/assets/' + item2.main_parent + "/" + item2.p_id}  key={"path"+index2}>{item2.name}/ </a>
                                                            )
                                                        })
                                                    }
                                                    </small>
                                                    <br />
                                                    <small>
                                                        <strong>User: </strong>{item.user} ({(item.user === "zoom@escholars.in" ? "500" : "100")})<br />
                                                        <strong>Started At: </strong>{item.date}
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="box-body">
                                                <button className='btn btn-danger btn-flat btn-block' onClick={() => endClass(item)} >End this class?</button>
                                            </div>
                                        </div>
                                    )
                                })   
                            }
                            </>
                        }
                    </div>
                }
                </div>


                <div className='col-md-6 assetsbars'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                { is_trash ? "Trash Bin" : "My Files" + " ("+totalFiles+")" }
                            </h3>
                            
                            {
                                !is_trash &&
                            <>
                                {
                                    pagePermissions(props.pagepermissions, "Assets", "bulk_selection") &&
                                    <>
                                    {
                                        pagePermissions(props.pagepermissions, "Assets", "bulk_delete") &&
                                        <div className="input-group pull-right btn btn-danger btn-flat" onClick={() => removeDialog()}>
                                            Delete
                                        </div>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, "Assets", "bulk_cut") &&
                                        <div className="input-group pull-right btn btn-flat" id='cutBtn' onClick={() => copyFiles("Cut")}>
                                            Cut
                                        </div>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, "Assets", "bulk_copy") &&
                                        <div className="input-group pull-right btn btn-flat" id='copyBtn' onClick={() => copyFiles("Copy")}>
                                            Copy
                                        </div>
                                    }
                                        {
                                            selectedFiles.length > 0 &&
                                            <div className="input-group btn-primary pull-right btn btn-flat" onClick={() => pasteFiles()}>
                                                Paste
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            }
                            
                            {
                                is_trash && 
                                pagePermissions(props.pagepermissions, "Assets", "cleane_trash_bin") &&
                                trashbinList.length > 0 &&
                                <div className="input-group pull-right btn btn-danger btn-flat" title='Clear All Trash Bin' onClick={() => clearTrashBin()}>
                                    Clear Trash Bin
                                </div>
                            }


                            <div className="input-group pull-right searchBar">
                                {/* <input type="text" className="form-control" placeholder="Search..." style={{borderRightColor:"#FFF"}} onChange={(e) => searchBar(e.target.value)} /> */}
                                <input type="text" className="form-control" placeholder="Search..." style={{borderRightColor:"#FFF"}} onChange={(e) => setFilterValues("name_search", e.target.value)} />
                                
                                <span className="input-group-addon">
                                    <FeatherIcon icon={"search"} />
                                </span>
                            </div>
                        </div>

                        {
                            pathList.length > 0 && !is_trash &&
                            <div className='box-body breadCrums'>
                                <ul>
                                { 
                                    importing === "" &&
                                    <li onClick={() => navigate("/admin/assets/"+additional_string)}>
                                        Assets <FeatherIcon icon={"chevron-right"}/>
                                    </li>
                                }
                                {
                                    pathList.map((item, index) => (
                                        <li title={item.name} onClick={() => changeFolder(main_id, item.id)} key={"path"+index}>
                                            {item.name}
                                            <FeatherIcon icon={"chevron-right"} />
                                        </li>    
                                    ))
                                }
                                </ul>    
                            </div>
                        }

                        
                    {
                        !is_trash &&
                        <>
                        {
                            p_id === 0 &&
                        <>
                        <div className='box-body grid_all'>
                            {
                                pinnedDataList.length > 0 &&
                                <>
                                    {
                                        pinnedDataList.map((item, index) => {
                                            let img = "folder"
                                            if(item.type === "Document"){
                                                img = "pdf"
                                            }else if(item.type === "Question"){
                                                img = "answer"
                                            }else if(item.type === "Video"){
                                                img = "play-button"
                                            }else if(item.type === "Recording"){
                                                img = "live"
                                            }else if(item.type === "Image"){
                                                img = "photo"
                                            }else if(item.type === "Youtube"){
                                                img = "youtube"
                                            }else if(item.type === "Live"){
                                                img = "voice"
                                            }else if(item.type === "Assignment"){
                                                img = "list"
                                            }
                                            return (
                                            
                                            <div className='col-md-4 grid_box_parent' key={"pinned"+item.id}>
                                                <div className='grid-box' title={item.name}>
                                                    <div className='grid-box-header'>
                                                        <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                        <div className='pinnedoff' title='Remove pinned Mark?' onClick={() => markPin(item.id)}>
                                                            <TbPinnedOff />
                                                        </div>
                                                    </div>
                                                    <div onClick={() => goToFolderWithHighlight(item.main_id, item.p_id, item.id)}>
                                                        <p className='grid-box-title' >
                                                            {item.name}
                                                        </p>
                                                        <p className='grid-box-items'>
                                                            {item.itemsCount}
                                                            <span className='pull-right'>{item.file_size}</span>
                                                        </p>
                                                    </div>    
                                                </div>
                                            </div>
                                            
                                        )})
                                    }
                                </>
                            }
                            
                        </div>
                        {
                            recent_list.length > 0 && pagePermissions(props.pagepermissions, "Assets", "view_recent_files") &&
                        <>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Recent Files
                            </h3>
                        </div>
                        <div className='box-body'>
                            <table id="staff_table" className="table file_manager_table">
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Modified
                                        </th>
                                        <th>
                                            Size
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        recent_list.map((item, index) => {

                                            let img = "folder"
                                            if(item.type === "Document"){
                                                img = "pdf"
                                            }else if(item.type === "Question"){
                                                img = "answer"
                                            }else if(item.type === "Video"){
                                                img = "play-button"
                                            }else if(item.type === "Recording"){
                                                img = "live"
                                            }else if(item.type === "Image"){
                                                img = "photo"
                                            }else if(item.type === "Youtube"){
                                                img = "youtube"
                                            }else if(item.type === "Live"){
                                                img = "voice"
                                            }else if(item.type === "Assignment"){
                                                img = "list"
                                            }
                                            
                                            moment.locale('en');
                                            var dt = item.created_at;

                                            return(
                                                <tr key={"forlders"+index} title= {item.name}>
                                                    <td className='file_name_cell' onClick={() => goToFolderWithHighlight(item.main_parent, item.p_id, item.id)}>
                                                        <div className='fileTitleList'>
                                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}}>
                                                        {moment(dt).format("D-M-Y HH:mm:ss")}
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}} >
                                                        {
                                                            (item.file_size === "") ? "-" : item.file_size
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        </>
                        }
                        </>
                        }

                        {
                            p_id != 0 &&
                            <>
                            {
                                loading ? 
                                <div style={{minHeight:"300px"}}>
                                    <Loading />
                                </div>
                                :

                            (folderList.length === 0 && filesList.length === 0) ?
                            <>
                            <div className='no-files'>
                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/nofiles.png'} />
                                <p className='no-files-heading'>
                                    <strong>Oops!</strong>
                                </p>
                                <p className='no-files-msg'>
                                    You Haven't Added Any Item Yet.
                                </p>


                            </div>

                                <div className='createbtnsCon no-files-btns' >
                                    {
                                        p_id != 0 &&
                                        <>
                                        {
                                            pagePermissions(props.pagepermissions, "Assets", "add_folders") &&
                                            <button type="button" className="btn iconbtns btn-primary" title='New Folder' onClick={() => changeAddTab("Folder", "", "")}>
                                                <FeatherIcon icon={"folder-plus"} />
                                                New Folder
                                            </button>
                                        }
                                        
                                        {
                                            // main_id != 3 &&
                                            <>
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_document") &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Upload Document' onClick={() => changeAddTab("Document", "", "")}>
                                                    <FeatherIcon icon={"file-text"} />
                                                    Upload Document
                                                </button>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_video") &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Upload Video' onClick={() => changeAddTab("Video", "", "")}>
                                                    <FeatherIcon icon={"video"} />
                                                    Upload Video
                                                </button>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_image") &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Upload Image' onClick={() => changeAddTab("Image", "", "")}>
                                                    <FeatherIcon icon={"image"} />
                                                    Upload Image
                                                </button>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_live_class") &&
                                                <button type="button" className="btn iconbtns btn-primary" title='Add Live Class' onClick={() => changeAddTab("Live", "", "")}>
                                                    <FeatherIcon icon={"cast"} />
                                                    Add Live Class
                                                </button>
                                            }
                                            
                                            {
                                                pagePermissions(props.pagepermissions, "Assets", "add_assignment") &&
                                                <button type="button" className="btn iconbtns btn-primary"  title='Add Assigments' onClick={() => changeAddTab("Assignment", "", "")}>
                                                    <FeatherIcon icon={"file-text"} />
                                                    Add Assigments
                                                </button>
                                            }
                                            </>
                                        }
                                        {
                                            //  main_id === 3 && 
                                             pagePermissions(props.pagepermissions, "Assets", "add_question") &&
                                            <button type="button" className="btn iconbtns btn-primary" title='Add Questions' onClick={() => changeAddTab("Question", "", "")}>
                                                <FeatherIcon icon={"help-circle"} />
                                                Add Question
                                            </button>
                                        }

                                        </>
                                    }

                            </div>
                            </>
                            :
                            <div className='box-body grid_all'>
                            <table id="staff_table" className="table file_manager_table">
                                <thead>
                                    <tr>
                                        <th>

                                        </th>
                                        <th className='text-center' style={{display:pagePermissions(props.pagepermissions, "Assets", "bulk_selection") ? "table-cell" : "none"}}>
                                            <input type={"checkbox"} id={"markAll"} onChange={() => markAll()}/>
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Modified
                                        </th>
                                        <th>
                                            Size
                                        </th>
                                        <th className='text-center'>
                                            Encrypt
                                        </th>
                                        <th className='text-right'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className='connected-sortable droppable-area1' onLoad={() => callSorting()}>
                                    {
                                        typeof filesList[segment] !== "undefined" &&
                                        filesList[segment].length > 0 &&
                                        filesList[segment].map((item, index) => {
                                            
                                            let img = "folder"
                                            if(item.type === "Document"){
                                                img = "pdf"
                                            }else if(item.type === "Question"){
                                                img = "answer"
                                            }else if(item.type === "Video"){
                                                img = "play-button"
                                            }else if(item.type === "Recording"){
                                                img = "live"
                                            }else if(item.type === "Image"){
                                                img = "photo"
                                            }else if(item.type === "Youtube"){
                                                img = "youtube"
                                            }else if(item.type === "Live"){
                                                img = "voice"
                                            }else if(item.type === "Assignment"){
                                                img = "list"
                                            }

                                            var edit_permission = ""
                                            var remove_permission = ""
                                            var info_permission = ""
                                            var url_permission = ""
                                            var download_permission = pagePermissions(props.pagepermissions, "Assets", "download_permission")
                                            var faq_permission = false
                                            
                                            if(item.type === "Document"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_document")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_document")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_document")
                                                url_permission = pagePermissions(props.pagepermissions, "Assets", "url_document")
                                            }else if(item.type === "Question"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_question")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_question")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_question")
                                                url_permission = pagePermissions(props.pagepermissions, "Assets", "url_question")
                                            }else if(item.type === "Video" || item.type === "Youtube"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_video")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_video")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_video")
                                                url_permission = pagePermissions(props.pagepermissions, "Assets", "url_video")
                                                faq_permission = pagePermissions(props.pagepermissions, "Assets", "faq_video")
                                                
                                            }else if(item.type === "Image"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_image")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_image")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_image")
                                                url_permission = pagePermissions(props.pagepermissions, "Assets", "url_image")
                                            }else if(item.type === "Folder"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_folders")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_folders")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_folders")
                                                url_permission = false
                                            }else if(item.type === "Live"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_live_class")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_live_class")
                                                info_permission = false
                                                url_permission = false
                                            }else if(item.type === "Recording"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_recording")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_recording")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_recording")
                                                faq_permission = pagePermissions(props.pagepermissions, "Assets", "faq_recording")
                                                url_permission = false
                                            }else if(item.type === "Assignment"){
                                                edit_permission = pagePermissions(props.pagepermissions, "Assets", "edit_assignment")
                                                remove_permission = pagePermissions(props.pagepermissions, "Assets", "remove_assignment")
                                                info_permission = pagePermissions(props.pagepermissions, "Assets", "info_assignment")
                                                url_permission = false
                                            }
                                            if(main_id !== "4"){
                                                url_permission = false       
                                            }
                                            
                                            if(item.type === "Folder" || item.type === "Question" || item.type === "Video" || item.type === "Live"){
                                                download_permission = false
                                            }

                                            var clas = (item.new_flick === 1) ? "draggable-item highlighted" : "draggable-item"
                                            if(item.id === highlighter){
                                                clas = "draggable-item highlighted"
                                            }
                                            if(item.encrypted_path === "NOTFOUND"){
                                                clas += " bg-red"    
                                            }

                                            moment.locale('en');
                                            var dt = item.updated_at;
                                            return(
                                                <tr 
                                                key={"files"+index} 
                                                id={"id"+item.id}
                                                onContextMenu={(e) => contextMenu(e, item.id, item.position, item.type, edit_permission, remove_permission, info_permission, url_permission, item.file, item.name, download_permission, item.description, item.tags)}
                                                className= {clas}
                                                style={{opacity: ( selectionType === "Cut" && selectedFiles.includes(item.id.toString())) ? 0.7 : 1 }}
                                                >
                                                    <td className='text-center dragHandle' data-bind={item.id}>
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td className='text-center' style={{display:pagePermissions(props.pagepermissions, "Assets", "bulk_selection") ? "table-cell" : "none"}}>
                                                        <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.id} onChange={() => checkCount()} />
                                                    </td>
                                                    <td onClick={() => (item.type === "Folder") ? changeFolder(main_id, item.id) : fileInfo(item.id, item.type)} title={item.name}>
                                                        <div className='fileTitleList'>
                                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                            {item.name}
                                                        </div>
                                                        {/* newuplsts */}
                                                        {
                                                            item.newsrc !== null &&
                                                            <>
                                                                {
                                                                    item.newuplsts === null &&
                                                                    <small className="text-red">Waiting for upload to the server</small>
                                                                }
                                                                {
                                                                    item.newuplsts === 1 &&
                                                                    <small className="text-yellow">Uploading to server</small>
                                                                }
                                                                
                                                                {
                                                                    item.newuplsts === 2 &&
                                                                    <>
                                                                        {
                                                                            item.newsrcstatus === null &&
                                                                            <small className="text-red">Waiting for encryption</small>
                                                                        }
                                                                        {
                                                                            item.newsrcstatus === "1" &&
                                                                            <small className="text-yellow">Encrypting Video...</small>
                                                                        }
                                                                        {
                                                                            item.newsrcstatus === "2" &&
                                                                            <small className="text-green">Video Encrypted Successfully</small>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}}>
                                                        {moment(dt).format("D,M Y")}
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}}>
                                                        {
                                                            (item.file_size === "") ? "-" : item.file_size
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            (item.type === "Video" || item.type === "Recording") && main_id !== "4" &&
                                                            <>
                                                            {
                                                                item.encrypted === null &&  (item.progress === null || parseInt(item.progress) < 1) &&
                                                                <div className='badge red-badge' title='Waiting For Encrypt'>
                                                                    <FeatherIcon icon={"clock"} />
                                                                </div>
                                                            }
                                                            {
                                                                (parseInt(item.progress) > 0 && parseInt(item.progress) < 100) &&
                                                                <div className='badge yellow-badge' title='Encrypting Video...'>
                                                                    {item.progress}
                                                                </div>
                                                            }
                                                            {
                                                                item.encrypted != null && item.encrypted != 1 &&
                                                                <div className='badge green-badge' title='Video Encrypted'>
                                                                    <FeatherIcon icon={"check-circle"} />
                                                                </div>
                                                            }
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className='actionDiv'>
                                                            
                                                            {
                                                                faq_permission &&
                                                                <Link to={"/admin/add-assets-faq/"+item.id} target='_blank'>
                                                                    <div className='pinnedoff' title="Add FAQ's" >
                                                                        <FeatherIcon icon={"help-circle"} />
                                                                    </div>
                                                                </Link>
                                                            }

                                                            {
                                                                download_permission &&
                                                                <div className='pinnedoff' title='Request for download' onClick={() => downloadRequest(item.id)}>
                                                                    <FeatherIcon icon={"download"} />
                                                                </div>
                                                            }

                                                            {
                                                                url_permission && 
                                                                // item.type !== "Folder" && item.type !== "Video" && item.type !== "Question" && main_id === 4 &&
                                                                <div className='pinnedoff' title='Copy Link' onClick={() => copyUrl(assets_url+item.file, item.name)}>
                                                                    <FeatherIcon icon={"link"} />
                                                                </div>
                                                            }
                                                            {
                                                                info_permission &&
                                                                <div className='pinnedoff' title='View Information' onClick={() => fileInfo(item.id, item.type)}>
                                                                    <FeatherIcon icon={"info"} />
                                                                </div>
                                                            }

                                                            <div className='pinnedoff' onClick={() => markPin(item.id)} title={pinnedList.includes(item.id) ? "Un-Pin" : "Pin"}>
                                                                {
                                                                    pinnedList.includes(item.id) ?
                                                                    <TbPinnedOff />
                                                                    :
                                                                    <TbPinned />
                                                                }
                                                            </div>

                                                            {
                                                                edit_permission &&
                                                                <div className='pinnedoff' title={'Rename '+item.type} onClick={() => renameModal(item.id, item.name, item.description, item.type, item.tags)}>
                                                                    <FeatherIcon icon={"edit"} />
                                                                </div>
                                                            }
                                                            {
                                                                remove_permission &&
                                                                <div className='pinnedoff' title={'Remove '+item.type} onClick={() => removeFiles(item.id)}>
                                                                    <FeatherIcon icon={"trash"} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>   
                                                </tr>
                                                )
                                            })
                                    }

                                </tbody>
                            </table>
                            <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                {
                                    filesList.length > 0 && filesList.length <= 10 &&
                                    filesList.map((item, index) => {
                                        const active = index === segment ? " btn-primary" : ""
                                        return(
                                            <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => {
                                                setSegment(index)
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                }} >
                                                {index+1}
                                            </button>
                                        )
                                    })
                                }
                                {
                                    filesList.length > 10 &&
                                    <div style={{width:"200px", float:"right"}}>
                                        <label>Last Page: {filesList.length}</label>
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                            setSegment(e.target.value - 1)
                                            document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} />
                                    </div>
                                }
                            </div>
                            </div>
                                }
                            </>
                        }
                        </>
                        }


                        {/* trash Bin */}
                        {
                            
                            
                            is_trash &&
                            <div className='box-body'>
                                {
                                    trashbinList.length > 0 &&
                                        <table id="staff_table" className="table file_manager_table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    <input type={"checkbox"} id={"markAll"} onChange={() => markAll()}/>
                                                    </th>
                                                    <th>
                                                        Name
                                                    </th>
                                                    <th>
                                                        Created
                                                    </th>
                                                    <th>
                                                        Removed On
                                                    </th>
                                                    <th>
                                                        
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {
                                                        trashbinList.map((item, index) => {
                                                            
                                                            let img = "folder"
                                                            if(item.type === "Document"){
                                                                img = "pdf"
                                                            }else if(item.type === "Question"){
                                                                img = "answer"
                                                            }else if(item.type === "Video"){
                                                                img = "play-button"
                                                            }else if(item.type === "Recording"){
                                                                img = "live"
                                                            }else if(item.type === "Image"){
                                                                img = "photo"
                                                            }else if(item.type === "Youtube"){
                                                                img = "youtube"
                                                            }else if(item.type === "Live"){
                                                                img = "voice"
                                                            }else if(item.type === "Assignment"){
                                                                img = "list"
                                                            }
                                                            
                                                            moment.locale('en');
                                                            var ct = item.created_at;
                                                            var ut = item.updated_at;
                                                            return(
                                                                
                                                            <tr key={"trashList"+index}>
                                                                
                                                                <td className='text-center'>
                                                                    <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.id}/>
                                                                </td>
                                                                <td className='file_name_cell' title={item.name} >
                                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                                    {item.name}
                                                                </td>
                                                                <td>
                                                                    {moment(ct).format("D-M-Y HH:mm:ss")}
                                                                </td>
                                                                <td>
                                                                    {moment(ut).format("D-M-Y HH:mm:ss")}
                                                                </td>
                                                                <td>
                                                                    <div className='pinnedoff' style={{textAlign:"center"}} title='Restore' onClick={() => restoreFile(item.id)}>
                                                                        <FeatherIcon icon={"refresh-cw"} />
                                                                    </div>
                                                                </td>
                                                            </tr>  
                                                        )})
                                                    }
                                                </>
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        trashbinList.length === 0 &&
                                        <img src={process.env.PUBLIC_URL+'/assets/assets/images/emptytrash.png'} style={{width:"100%", objectFit:"contain"}}/>
                                    }
                            </div>
                        }

                    </div>
                </div>

                <div className='col-md-3 assetsbars'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                
                            {
                                !pagePermissions(props.pagepermissions, "Assets", "view_storage") ? 
                                "Preview"
                                :
                                <>
                                {
                                    preview !== "" ?
                                    "Preview"
                                    :
                                    queue.length > 0 ? "Uploading" : "Storage"
                                }
                                </>
                            }
                            </h3>
                            {
                                queue.length > 0 && !uploading &&
                                <span className='pull-right clearAll' title='Clear All' onClick={() => clearAll()}>
                                    Clear All
                                </span>
                            }
                        </div>
                        <div className='col-md-12 storage-box-con'>
                            

                            {
                                previewLoading &&
                                <div style={{minHeight:"300px"}}>
                                    <Loading />
                                </div>  
                                
                            }
                            {
                                preview !== "" && !previewLoading &&
                                
                                <div className='previewPanel'>
                                    <div className='previewContent' style={{backgroundColor:preview.color+"17"}}>
                                        <br />
                                        
                                        <span className='pull-right previewClose' style={{cursor:'pointer'}} title={"Close Preview"} onClick={() => setPreview("")}>
                                            <FeatherIcon icon={"x"} />
                                        </span>
                                        {
                                            preview.type !== "Image" && preview.type !== "Video" && preview.type !== "Youtube" && preview.type !== "Recording" &&
                                            <div className='chenterCircle forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+preview.thumb+'.png'} />
                                            </div>
                                        }
                                        
                                        {
                                            preview.type === "Image" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={assets_url + preview.path} />
                                            </div>
                                        }
                                        {
                                            (preview.type === "Video" || preview.type === "Recording") && 
                                            <>
                                            <div className='centerPreview' style={{backgroundColor:preview.color+"17"}}>
                                                {
                                                    preview.tab === "English" ?
                                                    <>
                                                    {
                                                        ((preview.encrypted_file !== "" && preview.encrypted_file !== null) || (preview.newencsrc !== null)) &&
                                                        <>
                                                            {/* <Video id={preview.id} type={"videoUrl"} /> */}
                                                            <Video id={preview.id} type={"encrypt"} newencsrc={preview.newencsrc} />
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {
                                                        preview.encrypted_hindi_file !== "" && preview.encrypted_hindi_file !== null &&
                                                        // <Video id={preview.id} type={"hindi_videoUrl"} />
                                                        <Video id={preview.id} type={"hindi_encrypt"} />
                                                    }
                                                    </>
                                                }
                                            </div>
                                            <div className='changeTabBtns'>
                                                <button className={ preview.tab !== "English" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='English' onClick={() => changePreviewTab("English")}>
                                                    English
                                                </button>
                                                <button className={ preview.tab !== "Hindi" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='Hindi' onClick={() => changePreviewTab("Hindi")}>
                                                    Hindi
                                                </button>
                                            </div>
                                            </>
                                        }
                                        {
                                            preview.type === "Youtube" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <iframe src={preview.youtubeEmbedUrl} style={{border:"none"}}></iframe>
                                            </div>
                                        }
                                        <ul>
                                            <li title={preview.name}>
                                                <strong>File Name: </strong> 
                                                {
                                                    preview.type === "Question" ?
                                                    <Link to={"/admin/questions/"+params.id+"/"+params.p_id+"?qid="+preview.id}>
                                                        {preview.name}
                                                    </Link>
                                                    :
                                                    preview.type === "Document" || preview.type === "Assignment" ?
                                                        <Link to={"#"} onClick={(e) => window.open('/admin/preview/'+preview.id)} title={"Show Preview of "+preview.name}>
                                                            {preview.name}
                                                        </Link>
                                                    :
                                                    preview.name
                                                    
                                                }
                                                
                                            </li>

                                            {
                                                // typeof preview.path !== "undefined" && preview.tab === "English" && 
                                                preview.type !== "Live" && preview.type !== "Question" && 
                                                <li>
                                                    <strong>File: </strong> 

                                                    <div className='changeVideoLink' onClick={() => window.$('#VideoChangeEnglish').click()}>
                                                        <input type={"file"} name="file" id='VideoChangeEnglish' style={{display:"none"}} className='form-control' onChange={e => updateVideo(e, preview.id, "English", preview.type, "OldEncode") } accept={(preview.type === "Video") ? ".mp4" : (preview.type === "Document" || preview.type === "Assignment") ? '.pdf' : '.png,.svg,.gif,.jpg,.jpeg'} required />
                                                        Change File
                                                    </div>
                                                </li>
                                            }

                                            {
                                                typeof preview.hindi_path !== "undefined" && preview.type === "Video" && preview.tab === "Hindi" && 
                                                <li>
                                                    <strong>Hindi File: </strong> 

                                                    <div className='changeVideoLink' onClick={() => window.$('#VideoChangeHindi').click()}>
                                                        <input type={"file"} name="file" id='VideoChangeHindi' style={{display:"none"}} className='form-control' onChange={e => updateVideo(e, preview.id, "Hindi", preview.type, "OldEncode") } accept=".mp4" required />
                                                        {
                                                            preview.hindi_path !== null && preview.hindi_path !== "" ?

                                                            "Change Video"
                                                            :
                                                            "Add Video"
                                                        }
                                                        
                                                    </div>
                                                </li>
                                            }

                                            {
                                                typeof preview.type !== "undefined" && preview.type !== "" && 
                                                <li>
                                                    <strong>File Type: </strong> {preview.type}
                                                </li>
                                            }
                                            {
                                               typeof preview.extention !== "undefined" && preview.extention !== "" && 
                                            <li>
                                                <strong>File Extension: </strong> {preview.extention}
                                            </li>
                                            }
                                            
                                            {
                                               typeof preview.width !== "undefined" && preview.width !== "" && 
                                            <li>
                                                <strong>Image Dimentions: </strong> {preview.width + "PX X " + preview.height+"PX"}
                                            </li>
                                            }
                                            {
                                                typeof preview.org_size !== "undefined" && preview.org_size !== "" && preview.tab === "English" &&
                                            <li>
                                                <strong>File Size: </strong> {preview.org_size}
                                            </li>
                                            }

                                            {
                                                typeof preview.hindi_size !== "undefined" && preview.hindiItem !== "" && preview.hindi_size !== null && preview.tab === "Hindi" &&
                                            <li>
                                                <strong>File Size: </strong> {preview.hindi_size}
                                            </li>
                                            }

                                            {
                                               typeof preview.items !== "undefined" && preview.items !== "" && 
                                            <li>
                                                <strong>Item{"(s)"} : </strong> {preview.items}
                                            </li>
                                            }
                                            {
                                                typeof preview.pagecount !== "undefined" && preview.pagecount !== "" && preview.pagecount !== 0 &&
                                            <li>
                                                <strong>Document Length: </strong> {preview.pagecount}
                                            </li>
                                            }
                                            
                                            {
                                                typeof preview.durationHour !== "undefined" && preview.durationHour !== "" && preview.tab === "English" &&
                                            <li>
                                                <strong>Video Duration: </strong> {preview.durationHour}
                                            </li>
                                            }
                                            {
                                                preview.tab === "Hindi" && typeof preview.hindiItem.durationHour !== "undefined" && preview.hindiItem !== "" && preview.hindiItem.durationHour !== "" && 
                                            <li>
                                                <strong>Video Duration: </strong> {preview.hindiItem.durationHour}
                                            </li>
                                            }


                                            {
                                                typeof preview.staff.name !== "undefined" &&
                                            <li>
                                                <strong>Added By: </strong> {preview.staff.name+" ("+preview.staff.role+")"}
                                            </li>
                                            }
                                            {
                                                typeof preview.last_modified_by.name !== "undefined" &&
                                            <li>
                                                <strong>Last Modified By: </strong> {preview.last_modified_by.name+" ("+preview.last_modified_by.role+")"}
                                            </li>
                                            }
                                            {
                                                typeof preview.created_at !== "undefined" &&
                                            <li>
                                                <strong>Created On: </strong> {preview.created_at}
                                            </li>
                                            }
                                            {
                                                typeof preview.updated_at !== "undefined" &&
                                            <li>
                                                <strong>Last Modified On: </strong> {preview.updated_at}
                                            </li>
                                            }

                                            {  
                                            preview.changelogcount.length > 0 &&
                                            pagePermissions(props.pagepermissions, "Assets", "show_changelog")  && 
                                                <li style={{overflow:"hidden"}}>
                                                    <strong>Item Chagelog ({preview.changelogcount.length}): </strong> 
                                                    <div className='changeVideoLink pull-right' onClick={() => window.$('#moreInformation').fadeIn()}>
                                                        <strong style={{color:preview.color}}>
                                                            View
                                                        </strong>
                                                    </div>
                                                </li>
                                            }
                                            {
                                                preview.usedin.length > 0 &&  pagePermissions(props.pagepermissions, "Assets", "show_usedin")  && 
                                                <li style={{overflow:"hidden"}}>
                                                    <strong>Item Used In ({preview.usedin.length}): </strong> 
                                                    <div className='changeVideoLink pull-right' onClick={() => window.$('#usedin').fadeIn()}>
                                                        <strong style={{color:preview.color}}>
                                                            View
                                                        </strong>
                                                    </div>
                                                </li>
                                            }
                                            
                                            {
                                                (preview.type === "Video" || preview.type === "Recording") && 
                                                <li>
                                                    <strong>Reencrypt Video?</strong>
                                                    <div className='changeVideoLink' onClick={() => reEncyptVideo(preview)}>
                                                        
                                                            REENCYPT
                                                        
                                                    </div>
                                                </li>
                                            }
                                            {
                                                (preview.type === "Video" || preview.type === "Recording") && 
                                                <li>
                                                    <strong>New Encode File: </strong> 

                                                    <div className='changeVideoLink' onClick={() => window.$('#VideoChangeEnglish2').click()}>
                                                        <input type={"file"} name="file" id='VideoChangeEnglish2' style={{display:"none"}} className='form-control' onChange={e => updateVideo(e, preview.id, "English", preview.type, "NewEncode") } accept={'.mp4'} required />
                                                        Change File
                                                    </div>
                                                </li>
                                            }

                                        </ul>
                                        <br />
                                    </div>
                                </div>
                            }

                            {
                                queue.length > 0 ?
                                <div className='colReverse'>
                                {
                                queue.map((item, index) => {
                                    
                                    let img = "folder"
                                    if(item.type === "Document"){
                                        img = "pdf"
                                    }else if(item.type === "Question"){
                                        img = "answer"
                                    }else if(item.type === "Video"){
                                        img = "play-button"
                                    }else if(item.type === "Recording"){
                                        img = "live"
                                    }else if(item.type === "Image"){
                                        img = "photo"
                                    }else if(item.type === "Live"){
                                        img = "voice"
                                    }else if(item.type === "Assignment"){
                                        img = "list"
                                    }
                                    var uploaded = item.uploading
                                    if(item.status === true){
                                        uploaded=100
                                    }
                                    
                                    return (
                                        <div className='storage-box' title='Pdf Files' key={"bars"+index} id={"bars"+index}  >
                                            <div className='storage-icon-con'>
                                                <div className='storage-icon'>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                </div>
                                                <div className='uploading-title'>
                                                    <strong>
                                                        {
                                                            item.name
                                                        }
                                                        
                                                        <div className='upoading-percentage pull-right'>
                                                            <span className='percentage_span'>
                                                                {item.uploading}% &nbsp;
                                                            </span>
                                                            <div className='pinnedoff' title='Cancel Uploading' onClick={() => cancelRequest(index)} style={{display:(!item.status ? "block" : "none")}}>
                                                                <FeatherIcon icon={"x"} />
                                                            </div>
                                                            {                                                   
                                                                item.retry === 1 &&
                                                                <div className='pinnedoff' title='Retry Uploading' onClick={() => retryUpload(index)}>
                                                                    <FeatherIcon icon={"rotate-cw"} />
                                                                </div>
                                                            }
                                                        </div>

                                                    </strong>
                                                    <div className='uploadingBar'>
                                                        <div className={item.status === true ? 'insideBar completeBar' : 'insideBar'} style={{width:item.uploading+"%",backgroundColor:item.uploading}}></div>
                                                    </div>
                                                    <div className='uploadingMessages'>
                                                        {item.msg}
                                                    </div>
                                                </div>
                                            </div>    
                                        </div>
                                    )
                                })
                            }
                                </div>
                                :

                                
                                <>
                                { 
                                preview === "" &&  !previewLoading &&  
                                <>
                                
                                {
                                storageInfoList !== "" && 
                                <>

                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_images_storage")  && 
                                    <div className={filterObject.fileTypes === "Image" ? 'storage-box activeStorage' : 'storage-box'} title='Images' onClick={(e) => changeFilesType("Image")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/photo.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Images
                                                </strong>
                                                <br />
                                                {storageInfoList.imageCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                            {storageInfoList.imageSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_documents_storage")  && 
                                    <div className={filterObject.fileTypes === "Document" ? 'storage-box activeStorage' : 'storage-box'} title='Documents' onClick={(e) => changeFilesType("Document")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/pdf.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Documents
                                                </strong>
                                                <br />
                                                {storageInfoList.documentCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.documentSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_questions_storage")  &&    
                                    <div className={filterObject.fileTypes === "Question" ? 'storage-box activeStorage' : 'storage-box'} title='Questions' onClick={(e) => changeFilesType("Question")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/answer.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Question/Answer
                                                </strong>
                                                <br />
                                                {storageInfoList.quizCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.quizSize}
                                        </div>
                                    </div>
                                }
                                
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_videos_storage")  && 

                                    <div className={filterObject.fileTypes === "Video" ? 'storage-box activeStorage' : 'storage-box'} title='Videos' onClick={(e) => changeFilesType("Video")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/play-button.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Videos
                                                </strong>
                                                <br />
                                                {storageInfoList.videoCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.videoSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_youtube_storage")  && 
                                    
                                    <div className={filterObject.fileTypes === "Youtube" ? 'storage-box activeStorage' : 'storage-box'} title='Youtube' onClick={(e) => changeFilesType("Youtube")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/youtube.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Youtube Videos
                                                </strong>
                                                <br />
                                                {storageInfoList.ytCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.ytSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_recordings_storage")  && 
                                    
                                    <div className={filterObject.fileTypes === "Recording" ? 'storage-box activeStorage' : 'storage-box'} title='Recordings' onClick={(e) => changeFilesType("Recording")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/live.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Recordings
                                                </strong>
                                                <br />
                                                {storageInfoList.recordingCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.recordingSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_live_storage")  && 
                                    
                                    <div className={filterObject.fileTypes === "Live" ? 'storage-box activeStorage' : 'storage-box'} title='Live' onClick={(e) => changeFilesType("Live")} >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/voice.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Live Classes
                                                </strong>
                                                <br />
                                                {storageInfoList.liveCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.liveSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_assignment_storage")  && 
                                    
                                    <div className={filterObject.fileTypes === "Assignment" ? 'storage-box activeStorage' : 'storage-box'} title='Assignments' onClick={(e) => changeFilesType("Assignment") } >
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/list.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                Assignments
                                                </strong>
                                                <br />
                                                {storageInfoList.assignmentCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.assignmentSize}
                                        </div>
                                    </div>
                                }
                                {
                                pagePermissions(props.pagepermissions, "Assets", "view_trash_bin_storage")  &&  importing === "" &&
                                    <div className='storage-box' title='Deleted Files'>
                                        <div className='storage-icon-con'>
                                            <div className='storage-icon'>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/recycle-bin.png'} />
                                            </div>
                                            <div className='storage-title'>
                                                <strong>
                                                    Trash Bin
                                                </strong>
                                                <br />
                                                {storageInfoList.trashCount} Item{"(s)"}
                                            </div>
                                        </div>    
                                        <div className='storage-size'>
                                        {storageInfoList.trashSize}
                                        </div>
                                    </div>
                                }
                                
                            </>
                            }
                            {
                                (preview === "" && queue.length < 1) &&
                                <div className='box no-border'>
                                    <div className='box-footer'>
                                        {
                                            !storageInfoLoading &&
                                                <button className='btn btn-primary btn-flat btn-block' onClick={() => storageInfo()}>
                                                    Calculate Storage?
                                                </button>
                                        }   
                                        {
                                            storageInfoLoading &&
                                                <button className='btn btn-warning btn-flat btn-block'>
                                                    Calculating Storage...
                                                </button>
                                        }   
                                    </div>
                                </div>
                            }
                            </>
                            }
                            </>
                        }
                        </div>
                    </div>
                </div>
            </div>    
        }
        
        <div className='renameDialogBG' id='rename' style={{display:"none"}}>
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Edit
                        <span className='pull-right cursor-pointer' title='Close' onClick={(e) => window.$(".renameDialogBG").fadeOut()}>
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <div className="col-md-6 no-left-padding">
                        <label>Name*</label>
                        <input type={"text"} name={"name"} className="renameInput" required placeholder='e.g Rename' onChange={(e) => setName(e.target.value)} value={name} />
                    </div>
                    <div className="col-md-6 no-right-padding">
                        <label>Tag(s)</label>
                        <input type={"text"} name={"name"} className="renameInput" placeholder='e.g Tags' onChange={(e) => setTags(e.target.value)} value={tags} />
                    </div>
                </div>
                <br />
                <br />
                <div className='renameInputCon' style={{marginTop:"50px"}} >
                    {
                        editorLoading ? 
                        <>
                            <br /><br />
                            <Loading />
                            <br /><br />
                        </>
                        :
                        editor
                    }
                </div>
                <div className='renameFooter'>
                    <button className='btn btn-primary btn-flat pull-right' style={{marginTop:"10px"}} onClick={() => renameFile()}>
                        Rename
                    </button>
                </div>
            </div>
        </div>
        {
            preview !== "" && !previewLoading &&  pagePermissions(props.pagepermissions, "Assets", "show_changelog")  && 
        <div className='renameDialogBG' id='moreInformation' style={{display:"none"}}>
            <div className='renameBody midRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Item Changelog
                        <span className='pull-right cursor-pointer' title='Close' onClick={(e) => window.$(".renameDialogBG").fadeOut()}>
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInsideBody'>
                    <table id="fileHistory" className="table file_manager_table table-striped">
                        <tbody>
                            {
                                preview.modified_trail.length > 0 &&
                                <>
                                {
                                    preview.modified_trail.map((item, index) => (
                                    <tr key={"trail"+index}>
                                        <td>
                                            {index+1}
                                        </td>
                                        <td>
                                            {
                                                (typeof item.date !== "undefined") ? item.date : "Not Available"
                                            }
                                        </td>
                                        <td>
                                            <p  dangerouslySetInnerHTML={{__html : item.action}}></p>
                                            <p>
                                                <span>
                                                    {item.staff.name}
                                                    &nbsp;&nbsp;
                                                    <strong>
                                                        <Link to="#" style={{fontSize:"10px"}} onClick={(e) => {
                                                            e.preventDefault()
                                                            window.location.href = "mailto:"+item.staff.email
                                                        }}>
                                                            <i className='fa fa-envelope'></i>&nbsp;
                                                            {item.staff.email}
                                                        </Link>
                                                        &nbsp;&nbsp;
                                                        {
                                                            item.staff.contact !== null &&
                                                            <Link to={"#"} style={{fontSize:"10px"}}  onClick={(e) => {
                                                                e.preventDefault()
                                                                window.location.href = "tel:"+item.staff.contact
                                                            }}>
                                                                <i className='fa fa-phone'></i>&nbsp;
                                                                {item.staff.contact}
                                                            </Link>
                                                        }
                                                        <Link to={"#"} style={{fontSize:"10px"}} className={"pull-right"} >
                                                            {item.staff.role}
                                                        </Link>
                                                    </strong>
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                        
                                    ))
                                }
                            </>
                        }
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        }   
        {
        preview !== "" && !previewLoading &&  pagePermissions(props.pagepermissions, "Assets", "show_usedin")  && 
        <div className='renameDialogBG' id='usedin' style={{display:"none"}} >
            <div className='renameBody midRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Item Used In
                        <span className='pull-right cursor-pointer' title='Close' onClick={(e) => window.$(".renameDialogBG").fadeOut()}>
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInsideBody'>
                    <table id="fileHistory" className="table file_manager_table table-striped">
                        <tbody>
                            {
                                preview.usedin.length > 0 &&
                                <>
                                {
                                    preview.usedin.map((item, index) => (
                                        <tr key={"usedin"+index}>
                                            <td>
                                                {index+1}
                                            </td>
                                            <td>
                                                {item.date}
                                            </td>
                                            <td  >
                                                <p  dangerouslySetInnerHTML={{__html : item.msg}}></p>
                                                <p>
                                                    <span>
                                                        {item.staff.name}
                                                        &nbsp;&nbsp;
                                                        <strong>
                                                            <Link to="#" style={{fontSize:"10px"}} onClick={(e) => {
                                                                e.preventDefault()
                                                                window.location.href = "mailto:"+item.staff.email
                                                            }}>
                                                                <i className='fa fa-envelope'></i>&nbsp;
                                                                {item.staff.email}
                                                            </Link>
                                                            &nbsp;&nbsp;
                                                            {
                                                                item.staff.contact !== null &&
                                                                <Link to={"#"} style={{fontSize:"10px"}}  onClick={(e) => {
                                                                    e.preventDefault()
                                                                    window.location.href = "tel:"+item.staff.contact
                                                                }}>
                                                                    <i className='fa fa-phone'></i>&nbsp;
                                                                    {item.staff.contact}
                                                                </Link>
                                                            }
                                                            <Link to={"#"} style={{fontSize:"10px"}} className={"pull-right"} >
                                                                {item.staff.role}
                                                            </Link>
                                                        </strong>
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    ))
                                    
                                }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        }


        {
            addAssignment &&
            
        <div className='renameDialogBG' id='LiveClassPop' >
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        {assignmentType} Assignment
                        <span className='cursor-pointer pull-right' onClick={() => {
                            getChildList(params.p_id, params.id)
                            setAddAssignment(false)
                            }} >
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <Assignment main_id={params.id} p_id={params.p_id} aboveBelow={aboveBelow} aboveBelowPosition={aboveBelowPosition} type={assignmentType} assignmentId={assignmentId} from={"Assets"} />
                </div>
            </div>
        </div>
        }

        </>



    )
  }
  return (
    <>
      <Sidebar page={Assets_Liabrary} permission="Assets"/>
    </>
  )
}
