import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
import Adminprops from '../../VelidateWebsite/Adminprops'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import Notfound from '../includes/Notfound';


const cookies = new Cookies();

export default function Editstaff() {
  const Staff = (props) => {
    
    const params = useParams()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [contact, setContact] = useState("")
    const [roleTag, setRoleTag] = useState("")

    const [roleList, setRoleList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [hideNewRole, setHideNewRole] = useState(false)

    const [loading, setLoading] = useState(true)
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [refres, setRefresh] = useState("No")

    const location = useLocation()
    

    var a = 0
    useEffect(() => {
        if(a == 0){
            get_staff();
            a++
        }
    }, [])

    const get_staff = () => {
        
        fetch(apiurl+"api/staff_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    staffdata: params.id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    // console.log(response)
                    setRoleList(response.roles)
                    setStaffList(response.staff)
                    setName(response.staff.name)
                    setEmail(response.staff.email)
                    setContact(response.staff.contact)
                    setRoleTag(response.staff.role)
                    
                    // console.log(getpermissions("Staff" ,"show_in_sidebar" , get_staff_permissions()))
                    // console.log(getpermissions("Staff", "show_in_sidebar", response.staff.permissions), 10)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const updateMember = (e) => {
        
        formLoading(e.target)

        e.preventDefault();
        setLoading(true)
        
        fetch(apiurl+"api/update_staff_member", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: params.id,
                    name: name,
                    roletag: roleTag,
                    contact: contact,
                    password: password,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const changeRole = (x) => {
        setRoleTag(x)
        if(x !== "New"){
            setHideNewRole(false)
        }else{
            setHideNewRole(true)
        }
    }
    
    return(
        <>
        
        {
            
            ((admin.id.toString() === params.id.toString()) || pagePermissions(props.pagepermissions, "Staff", "edit_staff")) ?
      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
               Edit Profile
            </h3>
          </div>
          <form action='' method='POST' onSubmit={updateMember} id="add_staff">
            <div className='box-body'>
                <label>Name<span className='text-red'>*</span></label>
                <input type={"text"} name="name" placeholder='e.g John Doe' className='form-control' required onChange={e => setName(e.target.value)} value={name}/>
            </div>
            <div className='box-body'>
                <label>Email<span className='text-red'>*</span></label>
                <input type={"text"} name="email" placeholder='e.g info@example.com' className='form-control' required onChange={e => setEmail(e.target.value)} value={email} disabled/>
            </div>
            <div className='box-body'>
                <label>Contact<span className='text-red'>*</span></label>
                <input type={"text"} name="contact" placeholder='e.g +9122222233' className='form-control' required onChange={e => setContact(e.target.value)} value={contact} />
            </div>
            <div className='box-body'>
                <label>Password</label>
                <input type={"text"} name="contact" placeholder='e.g Shivam@123' className='form-control' onChange={e => setPassword(e.target.value)} />
            </div>
            {
                admin.id.toString() !== params.id.toString() &&
                <>
            <div className='box-body'>
                <label>Role Tag<span className='text-red'>*</span></label>
                <select name='roletag' className='form-control' required onChange={e => changeRole(e.target.value)} value={roleTag} >
                    <option value={""}>Select Role</option>
                    {
                        roleList.length > 0 &&
                        roleList.map((item, index)=>(
                            <option value={item.role} key={"roles"+index} >{item.role}</option>
                        ))
                    }
                    <option value={"New"}>Assign New Role</option>
                </select>
            </div>
            
            {
                hideNewRole &&
                <div className='box-body'>
                    <label>Member Role Tag<span className='text-red'>*</span></label>
                    <input type={"text"} name="roletag" placeholder='e.g Sub Admin' className='form-control' required onChange={e => setRoleTag(e.target.value)} />
                </div>
            }
            </>
            }
            <div className='box-footer'>
                {/* <Link to={"/admin/staff"} title="Back?"> 
                    <input type={"button"} className="btn btn-flat pull-left" value={"Back"} />
                </Link> */}
                <input type={"submit"} className="btn btn-primary btn-flat pull-right submitbtn" value={"Update Profile"} />
            </div>
            
          </form>
        </div>
      </div>
      :
      <Notfound />
  }
      </>
    )
  }
  return (
    <>
      <Sidebar page={Staff} permission={"Global"}/>
    </>
  )
}
