import React, { useEffect } from 'react'
import Hls from 'hls.js';
import $ from "jquery"
import Plyr from 'plyr';
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';



export default function Video(props) {
    
    const {AdminBasics, assets_url} = CommonFunc()
    const adminDetails = AdminBasics()
    // const url = apiurl+"storage/"+props.type+"/"+props.id+"/playlist.m3u8"
    // const url = apiurl+"videoUrl/"+props.id+"/playlist.m3u8"
    
    // const url = (props.type === "encrypt") ? apiurl + "videoUrl/"+props.id+"/playlist.m3u8" : apiurl + "hindi_videoUrl/"+props.id+"/playlist.m3u8"
    const url = (props.type === "encrypt") ? assets_url + "storage/encrypt/"+props.id+"/playlist.m3u8" : assets_url + "storage/hindi_encrypt/"+props.id+"/playlist.m3u8"

    // const url = 'https://escholar588.s3.ap-south-1.amazonaws.com/storage/encrypt/10007/playlist.m3u8'
    
    // hindi_videoUrl
    // videoUrl/{id}/{file}
    const updateQuality = (newQuality) => {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            console.log("Found quality match with " + newQuality);
            window.hls.currentLevel = levelIndex;
          }
        });
      }
      const getRandom = (min, max) => Math.floor(Math.random()*(max-min+1)+min);

      const callAgain = () => {
        setInterval(() => {
            var inWidth = window.$(".plyr__video-wrapper").innerWidth()
            var inrheight = window.$(".plyr__video-wrapper").innerHeight()
            $(".Watermark").remove()
            $(".plyr__video-wrapper").append("<div class='Watermark' style='left:"+getRandom(0,  inWidth - 200)+"px;top:"+getRandom(0, inrheight - 200)+"px"+"'><strong>"+adminDetails.name+"</strong><br>"+adminDetails.contact+"<br>"+adminDetails.email+"<br>"+adminDetails.role+"</div>")
            
        }, 10000);
      }
      var a = 0
    useEffect(() => {

        if(a === 0){
            var video = document.getElementById('player');
            var url = (props.type === "encrypt") ? assets_url + "storage/encrypt/"+props.id+"/playlist.m3u8" : assets_url + "storage/hindi_encrypt/"+props.id+"/playlist.m3u8"
            
            if(props.newencsrc !== undefined && props.newencsrc !== "" && props.newencsrc !== null){
                url = assets_url + props.newencsrc
            }
            var videoSrc = url;
            var startTime = 0
            const defaultOptions = {
                "storage": { enabled: false },
                "controls": [
                    'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'
                ],
                // "forward": 10,
                // "markers": {
                //     enabled: true,
                //     points: [
                //         {
                //             time:10,
                //             label:"Lable Time"
                //         }
                //     ]
                // }
            };

            if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);
                
                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    
                    // Transform available levels into an array of integers (height values).
                    const availableQualities = hls.levels.map((l) => l.height)
                    availableQualities.unshift(0)

                    // Add new qualities to option
                    defaultOptions.quality = {
                    // default: availableQualities[1],
                    default: 0,
                    options: availableQualities,
                    // this ensures Plyr to use Hls to update quality level
                    forced: true,        
                    onChange: (e) => updateQuality(e),
                    }
                    // Add Auto Label 
                    defaultOptions.i18n = {
                        qualityLabel: {
                            0: 'Auto',
                        },
                    }
            
                    // Initialize here
                    
                    const player = new Plyr(video, defaultOptions);
                    player.on('ended', function() {
                        console.log("Ended")
                    })

                    player.on('ready', function() {
                        console.log("ready")
                        
                    })
                    
                    player.on('play', function() {
                        console.log("Play")
                        if(startTime !== 0){
                            player.currentTime = startTime
                            startTime = 0
                        }
                    })

                    player.on('timeupdate', function(currentTime) {
                        // console.log(currentTime.timeStamp)
                        // console.log(player.currentTime)
                    })
                    
                    player.on('ratechange', function(ratechange) {
                        console.log(player.speed)
                    })
                    player.on('enterfullscreen', function() {
                        console.log("Full Screen On")
                    })
                    player.on('exitfullscreen', function() {
                        console.log("Full Screen Off")
                    })
                    
                });
                
                hls.attachMedia(video);
                window.hls = hls;
                
                setTimeout(function(){
                    callAgain()
                },2000)
                

                
                // hls.attachMedia(video);
            }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                // video.src = videoSrc;
                const player = new Plyr(video, defaultOptions);
            }
            a++
        }
        
    }, [])

  return (
    <div >
        <video
            id="player"
            src={url}
            type="application/vnd.apple.mpegurl"
            >
        </video>
    </div>

  )
}
