import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();

export default function Resultbanners(props) {
    
    const params = useParams()
    const courseData = (props.course.coursedata !== "" && props.course.coursedata !== null) ? JSON.parse(props.course.coursedata) : ""
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal, assets_url} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    
    const [file, setFile] = useState("")
    const [bannerList, setBannerList] = useState([])

    const addBanner = (e) => {

        e.preventDefault()
        formLoading(e.target)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('courseid', props.course.otherid);
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);

        fetch(apiurl+"api/addCourseBanner", {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", "Banner Updated Successfully")
                    getBanner()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
    }

    const getBanner = () => {
        setLoading(true)
        fetch(apiurl+"api/getCourseBanner", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                courseid: props.course.otherid,
            })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setBannerList(response.list)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
        }
    const removeBanner = (item) => {
        warningModal("Loading...", "Removing banner Please Wait...")
        fetch(apiurl+"api/removeCourseBanner", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                id: item.id
            })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getBanner()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
    }
    
    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_resultbanner_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });
                    // console.log(array)
                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }
    var a = 0
    useEffect(() => {
        if(a === 0){
            getBanner()
            a++
        }
    },[])

  return (
    <>
        <br />  
        <div className='col-md-4'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Add New Banner
                    </h3>
                </div>
                <form action='' method='POST' onSubmit={(e) => addBanner(e)} >
                    <div className='box-body'>
                       <label>Select Banner</label> 
                       <span className='pull-right' style={{color:"red"}} ><strong>Less then 1 MB</strong></span>
                       <input type={"file"} className="form-control" onChange={(e) => setFile(e.target.files[0])} accept='.png,.jpg,.jpeg,.png,.svg' />
                    </div>
                    <div className='box-footer'>
                        <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={"Add Banner"} />
                    </div>
                </form>                    
            </div>
        </div>
        <div className='col-md-8'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Banner List
                    </h3>
                </div>
                <div className='box-body'>
                    <table id="staff_table" className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>
                                    Drag
                                </th>
                                <th>
                                    Position
                                </th>
                                <th>
                                    Image
                                </th>
                                <th>
                                    Addred By
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='connected-sortable droppable-area1' onLoad={() => callSorting()}>
                            {
                                bannerList.length > 0 &&
                                bannerList.map((item, index) => {
                                    const path = JSON.parse(item.file)
                                    const staff = JSON.parse(item.staff)
                                    return(
                                        <tr key={"banners"+index} className="draggable-item" data-bind={item.id}>
                                            <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                <i className='fa fa-arrows-v'></i>
                                            </td>
                                            <td>
                                                {item.position}
                                            </td>
                                            <td>
                                                <img src={assets_url+path.path} style={{width:"100px", height:"auto", objectFit:"contain"}} />
                                            </td>
                                            <td>
                                                <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                                {staff.email}<br />
                                                {staff.contact}
                                            </td>
                                            <td>
                                                <button className='btn btn-danger btn-flat' onClick={() => removeBanner(item)} >
                                                    <i className='fa fa-trash' ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
}
