import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Loading from '../../includes/Loading';
import CommonFunc from '../../includes/Common';
import apiurl from '../../../Global';

const cookies = new Cookies();
export default function Classstudents(props) {
    
    const [tab, setTab] = useState("All")
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [segment, setSegment] = useState(0)
    const [data, setData] = useState(null)
    const [count, setCount] = useState(null)

    const getLearnersList = () =>{
        fetch(apiurl+"api/getJoinedStudentsListNew", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                fileid: props.fileid
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setData(response.students)
                    setCount(response.counts)
                    // setShowLearnersList(response.tabs)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(true)
                // console.log(err);
            });
    }

    const [downloading, setDownloading] = useState(false)
    const studentsDownloadRequest = () => {
        setDownloading(true)
        const th = ["S.No", "Student Name", "Student Email", "Student Contact", "Student ID", "Class Name", "Course Name"]
        var segmentTemp = data[tab]
        var td = [];
        var sn = 1
        for(var j = 0; j < segmentTemp.length; j++){
            var temp = data[tab][j]
            for(var i = 0;i<temp.length;i++){
                var cdata = temp[i]['coursedata'] !== undefined ? JSON.parse(temp[i]['coursedata']) : ""
                td.push(
                    [
                        sn,
                        temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['userid'], 
                        ((temp[i]['classdata'] !== undefined) ? temp[i]['classdata']['title'] : ""),
                        (cdata !== "") ? cdata['name'] : "-"
                    ]
                )
                sn++
            }
        }
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Live Class Students List of ("+td.length+") Student Joined Class Entries from all files page"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }


useEffect(() => {
    getLearnersList()
}, [])

  return (
    <div className='col-md-12'>
        <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
              <li className={(tab == "All") ? "active" : ""}><a onClick={() => setTab("All")} >All Students ({count !== null ? count['All'] : ""})</a></li>
              <li className={(tab == "Joined") ? "active" : ""}><a onClick={() => setTab("Joined")} >Joined Students ({count !== null ? count['Joined'] : ""})</a></li>
              <li className={(tab == "Not Joined") ? "active" : ""}><a onClick={() => setTab("Not Joined")} >Not Joined Students ({count !== null ? count['Not Joined'] : ""})</a></li>
                <li className="pull-right" onClick={() => props.falseFunc(null)}><a className="badge bg-red"><i className="fa fa-times"></i></a></li>
                {
                    downloading ? 
                    <li className="pull-right" ><a className="badge bg-yellow" style={{padding:"10px"}} >Loading...</a></li>
                    :
                    <li className="pull-right" onClick={() => studentsDownloadRequest()}><a className="badge bg-blue"><i className="fa fa-download"></i></a></li>
                }
              {
                loading &&
                <li className='pull-right' >
                    <a className='badge bg-yellow'>Loading...</a>    
                </li>
                }
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="tab_1">
                <div className='box no-border'>
                    <div className='box-body'>
                    {
                        data !== null &&
                        <table id="staff_table" className="table file_manager_table">
                            <thead>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Email
                                    </th>
                                    <th>
                                    Contact
                                    </th>
                                    <th>
                                        Course 
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    typeof data[tab][segment] !== "undefined" &&
                                    data[tab][segment].length > 0 &&
                                    data[tab][segment].map((item, index) => {
                                        var cdata = item.coursedata !== undefined ? JSON.parse(item.coursedata) : ""
                                        return (
                                            <tr key={"key"+index}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <a href={'/admin/student-profile/'+item.userid} target='_blank'>
                                                        {item.name}
                                                    </a>
                                                </td>
                                                <td>
                                                    {item.email}
                                                </td>
                                                <td>
                                                    {item.contact}
                                                </td>
                                                <td>
                                                    {
                                                        cdata !== "" &&
                                                        cdata.name
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                    {
                        data !== null && data[tab] !== undefined &&
                        <div className='box-footer'>
                            <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                {
                                    data[tab].length > 0 && data[tab].length <= 10 &&
                                    data[tab].map((item, index) => {
                                        const active = index === segment ? " btn-primary" : ""
                                        return(
                                            <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => setSegment(index)} >
                                                {index+1}
                                            </button>
                                        )
                                    })
                                }
                                {
                                    data[tab].length > 10 &&
                                    <div style={{width:"200px", float:"right"}}>
                                        <label>Last Page: {data[tab].length}</label>
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
              </div>
              
            </div>
            
          </div>
    </div>
  )
}
