import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
import { CSVLink } from 'react-csv';
const cookies = new Cookies();


export default function Removerequested() {
  const RemoverequestedPage = (props) => {
        
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url, warningModal} = CommonFunc()

    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

    
    const [students, setStudents] = useState("")
    const [refresh, setRefresh] = useState("No")
    const [showFilter, setShowFilter] = useState(false)
    const [studentListCount, setStudentListCount] = useState([])
    
    
    const [statecitylist, setStatecitylist] = useState({state:[], city:[], pincodes:[], courses:[], totalstudents:0, categories:[] })
    const [filter, setFilter] = useState({
        "signupfrom" : "",
        "signupto" : "",
        "enrolledfrom" : "",
        "enrolledto" : "",
        "expiringfrom" : "",
        "expiringto" : "",
        "enrolledin" : [],
        "notenrolledin" : [],
        "include_expired" : "",
        "state" : [],
        "city" : [],
        "pincode" : [],
        "categories" : [],
        "verifiedemail" : "",
        "status" : "",
        "segment" : "",
        "walletmin" : "",
        "walletmax" : "",
        "noactivity" : "",
        "signedupvia" : "",
        "name" : "",
        "email" : "",
        "contact" : "",
        "userid" : "",
        "last_login_from" : "",
        "last_login_to" : "",
        "userid" : "",
        "order" : "DESC",
        "removerequest" : "Yes",
    })

    const [currentSegment, setCurrentSegment] = useState(0)

    const changeFilter = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }


    const [studentLoading, setStudentLoading] = useState(true)
    const [minStudents, setMinStudents] = useState(1)
    const [maxStudents, setMaxStudents] = useState(10000)
    const [maxStudentsFix, setMaxStudentsFix] = useState(10000)
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    const getStudents = (e = null, currentSegment = 1) => {
        setStudents([])
        var  temp = filter
        temp['enrolledin'] = window.$("#enrolledin").val()
        temp['notenrolledin'] = window.$("#notenrolledin").val()
        temp['state'] = window.$("#state").val()
        temp['city'] = window.$("#city").val()
        temp['pincode'] = window.$("#pincode").val()
        temp['categories'] = window.$("#categories").val()

        fetch(apiurl + "api/students?page="+currentSegment, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    filter: temp
                })
            })
            .then(response => response.json())
            .then(response => {
                setStatecitylist(response.statecitylist)
                setStudentListCount(response.studentListCount)
                setStudents(response.students)
                var max = 10000
                setMaxStudents((max < response.studentListCount) ? max : response.studentListCount)
                setMaxStudentsFix((max < response.studentListCount) ? max : response.studentListCount)
                setLastPage(response.statecitylist.lastPage)
                if(e !== null){
                    $(e.target).find(".loaderbody").remove()
                }
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                // setTimeout(function(){
                //     window.$('#studenttable').dataTable()
                // }, 500)
            })
    }

    const changeStatus = (item, value) => {
        
        fetch(apiurl+"api/change_student_status", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    activeid: item.id,
                    value: value,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                // CategoryList()
            })
            .catch(err => { });
    }

    const filterNow = (e) => {
        e.preventDefault()
        formLoading(e.target)
        getStudents(e)
    }

    
    const [downloading, setDownloading] = useState(false)
    const [downloadingPopup, setDownloadingPopup] = useState(false)
    const downloadRequestFetch = () => {

        const min = parseInt(minStudents)
        const max = parseInt(maxStudents)
        const diff = max - (min-1);
        if(max < min){
            errorModal("Error", '"FROM" Cannot be greater than "TO"')
        }else if(diff > maxStudentsFix){
            errorModal("Error", "Total Users can't be more than 10000")
        }else{
            setDownloading(true)
            var  temp = filter
            temp['enrolledin'] = window.$("#enrolledin").val()
            temp['notenrolledin'] = window.$("#notenrolledin").val()
            temp['state'] = window.$("#state").val()
            temp['city'] = window.$("#city").val()
            temp['pincode'] = window.$("#pincode").val()
            temp['categories'] = window.$("#categories").val()
            fetch(apiurl+"api/user/getStudentsForDownload", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        filter: temp,
                        min: min,
                        max: max
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        downloadRequest(response.students)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => { });
        }

        
    }
    const downloadRequest = (students) => {
        setDownloading(true)
        const th = ["Student ID", "Name", "Email", "Contact", "Signedup From", "Created At", "Updated At", "Added By (Name)", "Staff Email", "Staff Contact", "Staff Role"]
        var segmentTemp = students
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = students[j]
            for(var i = 0;i<temp.length;i++){

                var staff = (temp[i]['staff'] === null || temp[i]['staff'] === "") ? "" : JSON.parse(temp[i]['staff'])
                moment.locale('en');
                var ct = temp[i]['created_at'];
                var dt = temp[i]['updated_at'];

                td.push(
                    [
                        temp[i]['userid'], temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['signedupfrom'],
                        temp[i]['signedupfrom'], moment(ct).format("D-M-Y"), moment(dt).format("D-M-Y"), 
                        (staff === "") ? "-" : staff.name,  
                        (staff === "") ? "-" : staff.email,  
                        (staff === "") ? "-" : staff.contact,  
                        (staff === "") ? "-" : staff.role,  
                    ]
                )
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Students List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    const clearFilter = (e) => {
        var temp = filter
        temp = {
            "signupfrom" : filter.signupfrom,
            "signupto" : filter.signupto,
            "enrolledfrom" : "",
            "enrolledto" : "",
            "enrolledin" : [],
            "notenrolledin" : [],
            "state" : [],
            "city" : [],
            "pincode" : [],
            "verifiedemail" : "",
            "status" : "",
            "segment" : "",
            "walletmin" : "",
            "walletmax" : "",
            "noactivity" : "",
            "signedupvia" : "",
            "name" : "",
            "email" : "",
            "contact" : "",
            "userid" : "",
            "order" : "DESC",
            "removerequest" : "Yes",
        }
        window.$("#categories").val('').change();
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }

    const removeRequest = (item) => {
        warningModal("Loading...", "Removing request please wait...")
        fetch(apiurl+"api/removeRequest", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    userid: item.userid,
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getStudents();
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => { });
    }

    var a = 0
    useEffect(() => {
        if(a === 0){
            
            getStudents()
            a++
        }
    }, [])

    return(
        <>
        
    {
        downloadingPopup &&
        <div className='dialog' id='downloadPopup'>
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Export Users: <small style={{color:"red"}} >current filters' values will be used</small>
                        </h3>
                        <span className='pull-right cursor-pointer' onClick={() => setDownloadingPopup(false)} >
                            <i className='fa fa-times'></i>
                        </span>
                    </div>
                    <div className='box-body'>
                        <label>From</label>
                        <input type='number' className='form-control' placeholder='e.g 1' value={minStudents} onChange={(e) => setMinStudents(e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>To</label>
                        <input type='number' className='form-control' placeholder='e.g 100' value={maxStudents} onChange={(e) => setMaxStudents(e.target.value)} />
                    </div>
                    <p style={{color:"red", paddingLeft:10}}>
                        <strong>
                            Total Users can't be more than {maxStudentsFix}
                        </strong>
                    </p>
                    <div className='box-footer'>
                        <button className='btn btn-primary btn-flat btn-block' onClick={() => downloadRequestFetch()} >Download Students</button>
                    </div>
                </div>
            </div>
        </div>
  }
        {
            showFilter &&
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter Students
                    </h3>
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setShowFilter(false)} >
                        <i className='fa fa-times'></i>
                    </button>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)} style={{marginRight:"10px"}} >
                            Clear Filter
                        </button>
                </div>
                <form action='' method='POST' onSubmit={(e) => filterNow(e)} >
                
                <div className='box-body'>
                    <div className='col-md-2 no-left-padding'>
                        <label>
                            Signup From
                        </label>
                        <input type={"date"} className="form-control" value={filter.signupfrom} onChange={(e) => changeFilter("signupfrom", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2'>
                        <label>
                            Signup To
                        </label>
                        <input type={"date"} className="form-control" value={filter.signupto} onChange={(e) => changeFilter("signupto", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2'>
                        <label>
                            Enrolled From
                        </label>
                        <input type={"date"} className="form-control" value={filter.enrolledfrom} onChange={(e) => changeFilter("enrolledfrom", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2 no-right-padding'>
                        <label>
                            Enrolled To
                        </label>
                        <input type={"date"} className="form-control" value={filter.enrolledto} onChange={(e) => changeFilter("enrolledto", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2 no-right-padding'>
                        <label>
                            Expiring From
                        </label>
                        <input type={"date"} className="form-control" value={filter.expiringfrom} onChange={(e) => changeFilter("expiringfrom", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2 no-right-padding'>
                        <label>
                            Expiring To
                        </label>
                        <input type={"date"} className="form-control" value={filter.expiringto} onChange={(e) => changeFilter("expiringto", e.target.value)}  /> 
                    </div>
                </div>
                <div className='box-body'>
                    <div className='col-md-5 no-left-padding'>
                        <label>
                            Enrolled In
                        </label>
                        <select data-placeholder='Select Course(s)' className='form-control' id='enrolledin' defaultValue={filter.enrolledin} onChange={(e) => changeFilter("enrolledin", e.target.value)} multiple > 
                            {
                                
                                statecitylist.courses.length > 0 &&
                                statecitylist.courses.map((item, index) => {
                                    return(
                                        <option value={item.otherid} key={"en"+index} >
                                            {item.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        
                    </div>
                    <div className='col-md-5'>
                        <label>
                            Not Enrolled In
                        </label>
                        
                        <select data-placeholder='Select Course(s)' className='form-control' id='notenrolledin' defaultValue={filter.notenrolledin} onChange={(e) => changeFilter("notenrolledin", e.target.value)} multiple > 
                            {
                                
                                statecitylist.courses.length > 0 &&
                                statecitylist.courses.map((item, index) => {
                                    return(
                                        <option value={item.otherid} key={"en"+index} >
                                            {item.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-2 no-right-padding '>
                        <label>
                            Show Expired?
                        </label>
                        <select className='form-control' defaultValue={filter.include_expired} onChange={(e) => changeFilter("include_expired", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                        </select>
                    </div>
                </div>
                <div className='box-body'>
                    
                    <div className='col-md-3 no-left-padding '>
                        <label>
                            Email Verified?
                        </label>
                        <select className='form-control' defaultValue={filter.verifiedemail} onChange={(e) => changeFilter("verifiedemail", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Yes"}>Verified</option>
                            <option value={"No"}>Not Verified</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Status
                        </label>
                        <select className='form-control' defaultValue={filter.status} onChange={(e) => changeFilter("status", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Yes"}>Active</option>
                            <option value={"No"}>In Active</option>
                        </select>
                    </div>
                        <div className='col-md-3'>
                            <label>
                                State
                            </label>
                            <select className='form-control' data-placeholder='Select State(s)' id='state' defaultValue={filter.state} onChange={(e) => changeFilter("state", e.target.value)} multiple >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.state.length > 0 &&
                                    statecitylist.state.map((item, index) => {
                                        return(
                                            <option key={"state"+index} value={item.state}>{item.state.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                City
                            </label>
                            <select className='form-control' data-placeholder='Select City' id='city' defaultValue={filter.city} onChange={(e) => changeFilter("city", e.target.value)} multiple >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.city.length > 0 &&
                                    statecitylist.city.map((item, index) => {
                                        return(
                                            <option key={"city"+index} value={item.city}>{item.city.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                </div>
                
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>
                            Name
                        </label>
                        <input type={"text"} className="form-control" value={filter.name} onChange={(e) => changeFilter("name", e.target.value)} placeholder={"e.g Shivam"} /> 
                    </div>
                    <div className='col-md-3 '>
                        <label>
                            Email
                        </label>
                        <input type={"text"} className="form-control" value={filter.email} onChange={(e) => changeFilter("email", e.target.value)} placeholder={"e.g info@example.com"} /> 
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Contact
                        </label>
                        <input type={"text"} className="form-control" value={filter.contact} onChange={(e) => changeFilter("contact", e.target.value)} placeholder={"e.g 9711111111"} /> 
                    </div>
                    <div className='col-md-3 no-right-padding'>
                        <label>
                            User ID
                        </label>
                        <input type={"text"} className="form-control" value={filter.userid} onChange={(e) => changeFilter("userid", e.target.value)} placeholder={"e.g 161221121"} /> 
                    </div>
                </div>
                <div className='box-body'>
                    
                    <div className='col-md-3 no-left-padding '>
                        <label>
                            Pincode
                        </label>
                        <select className='form-control' id='pincode'  data-placeholder='Select Pincode(s)' defaultValue={filter.pincode} onChange={(e) => changeFilter("pincode", e.target.value)} multiple >
                            <option value={""}>Any</option>
                            {
                                statecitylist.pincodes.length > 0 &&
                                statecitylist.pincodes.map((item, index) => {
                                    return(
                                        <option key={"pincode"+index} value={item.pincode}>{item.pincode.toUpperCase()}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Segment
                        </label>
                        <select className='form-control' defaultValue={filter.segment} onChange={(e) => changeFilter("segment", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Not Purchased"}>Not Purchased</option>
                            <option value={"Free"}>Free</option>
                            <option value={"Paid"}>Paid</option>
                            <option value={"Recurring"}>Recurring</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Wallet (Min)
                        </label>
                        <input type={"number"} className='form-control' value={filter.walletmin} onChange={(e) => changeFilter("walletmin", e.target.value)} placeholder={"e.g 1"} />
                    </div>
                    <div className='col-md-3  no-right-padding'>
                        <label>
                            Wallet (Max)
                        </label>
                        <input type={"number"} className='form-control' value={filter.walletmax} onChange={(e) => changeFilter("walletmax", e.target.value)} placeholder={"e.g 90"} />
                    </div>
                    {/* <div className='col-md-3 '>
                        <label>
                            No Activity Since
                        </label>
                        <input type={"datetime-local"} className="form-control" value={filter.noactivity} onChange={(e) => changeFilter("noactivity", e.target.value)}  /> 
                    </div> */}
                </div>
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>
                            Signed Up Via
                        </label>
                        <select className='form-control' defaultValue={filter.signedupvia} onChange={(e) => changeFilter("signedupvia", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Android"}>Android</option>
                            <option value={"Ios"}>IOS</option>
                            <option value={"Web"}>Web</option>
                            <option value={"Admin"}>Admin</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Order
                        </label>
                        <select className='form-control' defaultValue={filter.order} onChange={(e) => changeFilter("order", e.target.value)}  >
                            <option value={"ASC"}>Ascending Order</option>
                            <option value={"DESC"}>Descending Order</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Last Login From
                        </label>
                        <input type={"date"} className="form-control" value={filter.last_login_from} onChange={(e) => changeFilter("last_login_from", e.target.value)}  /> 
                    </div>
                    <div className='col-md-3 no-right-padding'>
                        <label>
                            Last Login To
                        </label>
                        <input type={"date"} className="form-control" value={filter.last_login_to} onChange={(e) => changeFilter("last_login_to", e.target.value)}  /> 
                    </div>
                </div>
                    <div className='box-body'>
                        <div className="col-md-12 no-padding">
                            <label> Select Categories </label>
                            <select className='form-control' multiple id='categories' data-placeholder='Select Category'>
                                {
                                    statecitylist.categories.length > 0 &&
                                    statecitylist.categories.map((item, index) => {
                                        return(
                                            <option key={"cat"+index} value={item.value}>{item.lable.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                    </div>
                <div className='box-footer'>
                    <button className='btn btn-primary btn-flat btn-block'>Search Students</button>
                </div>
                </form>
            </div>
        </div>
    }

            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Manage Students ({studentListCount}/{statecitylist.totalstudents})
                            {
                                // filter.signupfrom === "" && filter.signupto === "" &&
                                // <small style={{"color": "red"}}>
                                //     &nbsp;&nbsp;&nbsp;You are currently viewing data of last 7 days.
                                // </small>
                            }
                        </h3>
                        {
                            <button className={'btn btn-'+(showFilter ? "success" : "primary")+' btn-flat pull-right'} style={{marginLeft:"10px"}} onClick={(e) => {
                                setShowFilter(true)
                                
                                setTimeout(function(){
                                    if(window.$(".select2-selection").length > 0){
                                    window.$("select.select2-hidden-accessible").select2('destroy');
                                    }else{
                                    window.$("#enrolledin").select2();
                                    window.$("#notenrolledin").select2()
                                    window.$("#state").select2()
                                    window.$("#city").select2()
                                    window.$("#pincode").select2()
                                    window.$("#categories").select2()
                                    }
                                }, 500)

                                }} >
                                <i className='fa fa-filter'></i>
                            </button>
                        }
                        
                        
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => setDownloadingPopup(true)} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                    <div className='box-body' style={{overflow:"auto"}} >
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>Student ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Signed Up From</th>
                                    <th>Created At</th>
                                    <th>Modified At</th>
                                    <th>Added By</th>
                                    {
                                        pagePermissions(props.pagepermissions, "Student_Removed_Requests", "change_status") &&
                                        <th>Status</th>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, "Student_Removed_Requests", "remove_request") &&
                                        <th>Action</th>
                                    }
                                    
                                </tr>
                            </thead>
                            <tbody>
                                
                            {
                                students.length > 0 && typeof students[currentSegment] !== "undefined" && students[currentSegment].length > 0 ? 
                                students[currentSegment].map((item, index) => {
                                    var letter = Array.from(item.name)[0];
                                    var staff = (item.staff === null || item.staff === "") ? "" : JSON.parse(item.staff)
                                    moment.locale('en');
                                    var ct = item.created_at; 
                                    var dt = item.updated_at;
                                    return(

                                        <tr key={"student"+index}>
                                        <td>
                                            <span className='otherInfo'>
                                            {(index+1)}
                                            </span>        
                                        </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {
                                                        pagePermissions(props.pagepermissions, "Student_Removed_Requests", "view_profile") ?
                                                        <Link to={"/admin/student-profile/"+item.userid} target={"_blank"} >
                                                            {item.userid}
                                                        </Link>
                                                        :
                                                        item.userid
                                                    }
                                                </span>        
                                            </td>
                                            <td>
                                            {
                                                pagePermissions(props.pagepermissions, "Student_Removed_Requests", "view_profile") ?
                                                <Link to={"/admin/student-profile/"+item.userid} target={"_blank"} >
                                                    <div className='studentName'>
                                                        <div className='profilePic' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                            {letter.toUpperCase()}
                                                        </div>
                                                        <span>
                                                            <strong>{item.name}</strong>
                                                        </span>
                                                    </div>
                                                </Link>
                                                :
                                                <div className='studentName'>
                                                    <div className='profilePic' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                        {letter.toUpperCase()}
                                                    </div>
                                                    <span>
                                                        <strong>{item.name}</strong>
                                                    </span>
                                                </div>
                                            }
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    <a href={'mailto:'+item.email} style={{whiteSpace:"nowrap"}}>
                                                        <i className='fa fa-envelope'></i> &nbsp;
                                                        {item.email}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    <a href={'tel:'+item.contact} style={{whiteSpace:"nowrap"}}>
                                                        <i className='fa fa-phone'></i> &nbsp;
                                                        {item.contact}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {item.signedupfrom}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {moment(ct).format("D-M-Y")}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {moment(dt).format("D-M-Y")}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    { (staff !== "") ? staff.name : "-"}
                                                </span>
                                            </td>
                                            {
                                                pagePermissions(props.pagepermissions, "Student_Removed_Requests", "change_status") &&
                                                    <td>
                                                        <span className='otherInfo'>
                                                            <label className="switch">
                                                                <input type="checkbox" defaultChecked={(item.status === 1 || item.status === "1") ? true : false} onChange={(e) => changeStatus(item, window.$(e.target).prop('checked'))} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </span>
                                                    </td>
                                            }
                                            
                                            {
                                                pagePermissions(props.pagepermissions, "Student_Removed_Requests", "remove_request") &&
                                                <td>
                                                    <button className='btn btn-danger btn-flat' onClick={() => removeRequest(item)}>
                                                        Remove Request?
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td>No Students Found...</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }

                            </tbody>
                        </table>
                    </div>
                    
                    <div className='box-footer text-right'>
                        {
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {lastPage}</label>
                                <div className="input-group input-group-sm">
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={currentPage} onChange={(e) => setCurrentPage(e.target.value) } />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-primary btn-flat" onClick={() => getStudents(null, currentPage)}>Go!</button>
                                        </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
  }
  return (
    <>
        {
            <Sidebar page={RemoverequestedPage} permission="Student_Removed_Requests"/>   
        }
    </>
  )
}
