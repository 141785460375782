import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();

var wrong = "#f46a6a"
var right = "#34c38f"
var warn = "#DA8C10"
export default function Answersheet(props) {

    const params = useParams()
    const attid = props.attid
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState(null)
    const [rank, setrank] = useState(null)
    const [answers, setAnswers] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const options = [1,2,3,4,5,6]
    
    const [update, setUpdate] = useState({
        type: "1",
        marks: 0,
        id: "",
        loading: false
    })
    
    

        const [color, setColor] = useState("#000000")
        const fetchList = () => {
            setLoading(true)
            fetch(apiurl+"api/fetchsingleattempt", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                          staff: AdminBasics(),
                          accecible: 1,
                          page_url: window.location.href,
                          attemptid: attid,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        
                        setList(response.attempt)
                        setAnswers(response.answers)
                        setrank(response.rank)
                        var colo = wrong
                        if(response.attempt.percentage > 20 && response.attempt.percentage <= 40){ colo = "#f46a6a" }
                        if(response.attempt.percentage > 40 && response.attempt.percentage <= 60){ colo = "#f4db6a" }
                        if(response.attempt.percentage > 60 && response.attempt.percentage <= 80){ colo = "#34c368" }
                        if(response.attempt.percentage > 80 && response.attempt.percentage <= 100){ colo = right }
                        
                        window.$("#percentage").percircle({
                            text: "",
                            percent: response.attempt.percentage,
                            progressBarColor: colo
                        });

                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                    setLoading(false)
                })
                .catch(err => {
                    
                    // console.log(err);
                });
          } 
          const changemarks = (col, val) => {
            var temp = update 
            temp[col] = val
            setUpdate(temp)
            setRefresh(refresh === "No" ? "Yes" : "No")
          }

          const saveMarks = (e) => {
            
            if(!update.loading){
                changemarks("loading", true)
                fetch(apiurl+"api/updateMarks", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                            staff: AdminBasics(),
                            accecible: 1,
                            page_url: window.location.href,
                            update: update,
                            attid: attid,
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            changemarks("id", "")
                            changemarks("type", "1")
                            changemarks("marks", 0)
                            changemarks("loading", false)
                            fetchList()
                            successModal("Success", response.message)
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            // alert(response.message)
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                    console.log(i)
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                            changemarks("loading", false)
                        }
                        
                    })
                    .catch(err => {
                        changemarks("loading", false)
                        // console.log(err);
                    });
                }

          }

        const generateResult = () => {
            warningModal("Loading...", "Generating Result Please Wait...")
            fetch(apiurl+"api/regenerateresult", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        staff: AdminBasics(),
                        accecible: 1,
                        page_url: window.location.href,
                        attid: attid,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        fetchList()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                })
                .catch(err => {
                });
        }

        const [downloading, setDownloading] = useState(false)
        const downloadAnswersheet = () => {

            setDownloading(true)
            
            
            fetch(apiurl+"api/downloadAttempt", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        attemptid: attid,
                        for: "Request to download Test Attempt With Attempt ID: " + attid
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setDownloading(false)
                })
                .catch(err => {
                    // console.log(err);
                    setDownloading(false)
                });
    
        }

      var a = 0
      useEffect(() => {
        if(a === 0){
            fetchList()
            a++
        }
      }, [])

  return (
    <div className='col-md-12 no-left-padding no-right-padding'>
        {
            <>
            <div className='col-md-6'>
                <br />
                <div className='box no-border' >
                    
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Obtained Percentage
                        </h3>
                        <span className='pull-right' >{attid}</span>
                    </div>
                    <div className='box-body text-center'>
                        <div id="percentage" className="big" style={{margin:"20px auto", float:"none"}} ></div>
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                <br />
                <div className='box no-border'>
                    <div className='box-header text-center'>
                        <h3 className='box-title'>
                            Test Summary
                        </h3>
                    </div>
                    <div className="box-footer no-padding">
                        <ul className="nav nav-stacked">
                            <li style={{padding:"10px"}}>
                                Date of Submission
                                <strong>
                                    <span className='pull-right'>
                                        { loading ? "Loading..." : list !== null ? list.quiz_start : "-" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Result Generated On
                                <strong>
                                    <span className='pull-right'>
                                        { loading ? "Loading..." : list !== null ? list.resultgen : "-" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Rank
                                <strong>
                                    <span className='pull-right'>
                                        { loading ? "Loading..." : list !== null ? rank.rank+"/"+rank.outof : "-" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                No Of Questions
                                <strong>
                                    <span className='pull-right'>
                                        { loading ? "Loading..." : list !== null ? list.quiz_data.quizquestions : "-" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Questions Attempted
                                <strong>
                                    <span className='pull-right'>
                                        { !loading && list.result !== null ? list.result.attempted : "Not Generated" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Questions Un-Attempted
                                <strong>
                                    <span className='pull-right'>
                                        { !loading && list.result !== null ? list.result.unattempted : "Not Generated" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Marked for Review
                                <strong>
                                    <span className='pull-right'>
                                        { !loading && list.result !== null ? list.result.marked : "Not Generated" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Marks Obtained
                                <strong>
                                    <span className='pull-right'>
                                        { !loading && list.result !== null ? list.result.result.earned + "/" + list.result.result.outof : "Not Generated" }
                                    </span>
                                </strong>
                            </li>
                            <li style={{padding:"10px"}}>
                                Marks Deducted
                                <strong>
                                    <span className='pull-right'>
                                        { !loading && list.result !== null ? list.result.result.negetive : "Not Generated" }
                                    </span>
                                </strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                !loading && list !== null &&
                <>
            <div className='col-md-12'>
                <div className='box no-border' >
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Basic Information
                        </h3>
                    </div>
                    <div className='box-body'>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Student Name</strong>
                            </p>
                            <p>
                            {
                                list.userdata !== null ?
                                    list.userdata.name
                                :
                                    "-"
                            }
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Student Email</strong>
                            </p>
                            <p className='line1' >
                            {
                                list.userdata !== null ?
                                    list.userdata.email
                                :
                                    "-"
                            }
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Student Contact</strong>
                            </p>
                            <p>
                            {
                                list.userdata !== null ?
                                    list.userdata.contact
                                :
                                    "-"
                            }
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' >
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Student ID</strong>
                            </p>
                            <p>
                            {list.userid}
                            </p>
                        </div>
                    </div>
                    
                    <div className='box-body'>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Attempt ID</strong>
                            </p>
                            <p>
                                {list.attemptid}
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Purchase ID</strong>
                            </p>
                            <p>
                            {list.purchase_id}
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Quiz ID</strong>
                            </p>
                            <p>
                            {list.quizid}
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' >
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Course ID</strong>
                            </p>
                            <p>
                            {list.course_id}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {
                list.modby !== null && 
                // props.staffinfo &&
            <div className='col-md-12'>
                <div className='box no-border' >
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Result Generated By
                        </h3>
                    </div>
                    <div className='box-body'>
                        
                    <div className='box-body'>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Staff Name</strong>
                            </p>
                            <p>
                                {list.modby.name}
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Staff Email</strong>
                            </p>
                            <p className='line1'>
                            {list.modby.email}
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' style={{borderRight:"0.5px solid rgba(0,0,0,0.1)"}}>
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Staff Contact</strong>
                            </p>
                            <p>
                            {list.modby.contact}
                            </p>
                        </div>
                        <div className='col-md-3 no-left-padding no-right-padding text-center' >
                            <p style={{backgroundColor:"rgba(0,0,0,0.03)", padding:"10px 0"}} >
                                <strong>Staff Role</strong>
                            </p>
                            <p>
                            {list.modby.role}
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        }

            </>
        }
            {
                !loading && 
                // props.alttermarks &&
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-body'>
                            <button onClick={() => generateResult()} className='btn btn-primary btn-flat btn-block'>{list.result === null ? "Generate Result?" : "Re-Generate Result?"}</button>
                        </div>
                    </div>
                </div>
            }
            <div className='col-md-12'>
                <div className='box no-border' >
                    
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Answersheet
                        </h3>
                        
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadAnswersheet()} >
                                Download?
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                </div>
                    {
                        loading ?
                        <>
                            <br /><br />
                                <Loading />
                            <br /><br />
                        </>
                        :
                        answers.length > 0 &&
                        answers.map((item, index) => {
                            const quizdata = JSON.parse(item.quizdata)
                            var ans = (item.correct !== null && item.correct !== "null" && item.correct !== "") ? JSON.parse(item.correct) : []
                            var myans = (item.answer !== null && item.answer !== "null" && item.answer !== "") ? JSON.parse(item.answer) : []
                            var modby = (item.modby !== null) ? JSON.parse(item.modby) : null
                            return(
                                <div key={"answer"+index} className={"ck-content"}>
                                    <div className='box no-border' style={{overflow:"hidden"}} >
                                        <div className='box-header'>
                                            <h3 className='box-title'>
                                                Question {index+1} ({quizdata.question_type}) 
                                            </h3>
                                            {
                                                item.answer_type === "ATTEMPT" &&
                                                <div style={{
                                                    backgroundColor: (item.correctincorrect === 1) ? right : wrong, justifyContent:"center",
                                                    alignItems:"center", borderRadius:20, float:"right", fontSize:12, padding:"0px 20px", color:"#FFF"
                                                }} >
                                                    
                                                    {(item.correctincorrect === 1) ? "Correct" :"Incorrect"}
                                                </div>
                                            }
                                            
                                            {
                                                ( item.answer_type === "UNATTEMPTED" || item.answer_type === "MARKED") &&
                                                <div style={{
                                                    backgroundColor: warn, justifyContent:"center",
                                                    alignItems:"center", borderRadius:20, float:"right", fontSize:12, padding:"0px 20px", color:"#FFF"
                                                }} >
                                                    Un-Attempted
                                                </div>
                                            }
                                            {
                                                <div style={{
                                                    backgroundColor:  wrong, justifyContent:"center", marginRight:"10px",
                                                    alignItems:"center", borderRadius:20, float:"right", fontSize:12, padding:"0px 10px", color:"#FFF"
                                                }} >
                                                    
                                                    {quizdata.negMarks} (-ve)
                                                </div>
                                            }
                                            {
                                                <div style={{
                                                    backgroundColor:  right, justifyContent:"center", marginRight:"10px",
                                                    alignItems:"center", borderRadius:20, float:"right", fontSize:12, padding:"0px 10px", color:"#FFF"
                                                }} >
                                                    
                                                    {quizdata.posMarks} (+ve)
                                                </div>
                                            }
                                        </div>
                                        {
                                            quizdata.essay !== null && quizdata.essay !== "" &&
                                            <div className='box-body' dangerouslySetInnerHTML={{__html : quizdata.essay}}></div>
                                        }
                                        {
                                            quizdata.question !== null && quizdata.question !== "" &&
                                            <div className='box-body' dangerouslySetInnerHTML={{__html : quizdata.question}}></div>
                                        }
                                        
                                        {
                                            (quizdata.question_type !== "Fill_In_The_Blanks" && quizdata.question_type !== "Subjective") && 
                                            options.map((item2, index) => {
                                                var op = "A"
                                                if(item2 === 1) { op = "A" }
                                                if(item2 === 2) { op = "B" }
                                                if(item2 === 3) { op = "C" }
                                                if(item2 === 4) { op = "D" }
                                                if(item2 === 5) { op = "E" }
                                                if(item2 === 6) { op = "F" }
                                                var col = "option"+item2
                                                var correctinc = false;
                                                if(item.question_type === "Objective" || item.question_type === "True_And_False" || item.question_type === "Match_The_Following"){
                                                    correctinc = ans.includes(item2.toString())
                                                }
                                                return(
                                                    quizdata[col] !== null && quizdata[col] !== "" && 
                                                <div  key={"answer"+index}>
                                                    <div className='box-body' style={{backgroundColor:"#F6F6F9", margin:10, borderRadius:10, position:"relative", display:"flex", alignItems:"flex-start", justifyContent:"space-between", marginBottom:0}}>
                                                        <div style={{
                                                            width:"30px", height:"30px", borderRadius:"50px", backgroundColor:(ans.includes(item2.toString())) ? right : wrong, alignItems:"center", justifyContent:"center",
                                                            fontSize:14, fontWeight:"bold", color:"#FFFFFF", lineHeight:"30px", textAlign:"center"
                                                            }} >
                                                            {op}
                                                        </div>
                                                        <div style={{width:"calc(100% - 50px)"}} dangerouslySetInnerHTML={{__html: quizdata[col]}} ></div>
                                                    </div>
                                                </div>
                                                    
                                                    )
                                                })
                                            }


                                    {
                                        (item.questiontype === "Fill_In_The_Blanks") &&
                                        <>
                                            <div className='box-body' style={{backgroundColor:"#F6F6F9", margin:10, borderRadius:10, position:"relative"}}>
                                                <div style={{
                                                    width:"30px", height:"30px", borderRadius:"50px", backgroundColor:"#F6F6F9", alignItems:"center", justifyContent:"center",
                                                    fontSize:14, fontWeight:"bold", color:"#000000", lineHeight:"30px", textAlign:"center"
                                                    }} >
                                                   Answer
                                                </div>
                                                <div style={{width:"calc(100% - 50px)"}} dangerouslySetInnerHTML={{__html: ans[0]}} ></div>
                                            </div>
                                            <br />
                                        </>
                                    }
                                    
                                    {
                                        (item.answer_type === "ATTEMPT") &&
                                        <>
                                            <div className='box-body' style={{backgroundColor:"#F6F6F9", margin:10, borderRadius:10, position:"relative"}}>
                                                <div style={{
                                                    borderRadius:"50px", backgroundColor:"#F6F6F9", 
                                                    fontSize:14, fontWeight:"bold", color:"#000000",
                                                    }} >
                                                    Student Answer
                                                </div>
                                                <p>
                                                    {
                                                        myans.length > 0 &&
                                                        myans.map((it, ind) => {
                                                            var ann = "A"
                                                            if(it === 1){ ann = "A" }
                                                            else if(it === 2){ ann = "B" }
                                                            else if(it === 3){ ann = "C" }
                                                            else if(it === 4){ ann = "D" }
                                                            else if(it === 5){ ann = "E" }
                                                            else if(it === 6){ ann = "F" }
                                                            else{ ann = it }
                                                            return(
                                                                ann+", "
                                                            )
                                                        })
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                        </>
                                    }
                                    {
                                        <>
                                        
                                        <div className='box-body' style={{backgroundColor:"#F6F6F9", margin:10, borderRadius:10, position:"relative"}}>
                                                <div style={{
                                                    borderRadius:"50px", backgroundColor:"#F6F6F9", 
                                                    fontSize:14, fontWeight:"bold", color:"#000000",
                                                    }} >
                                                    Explanation
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html: quizdata.explanation}}></div>
                                            </div>
                                            <br />
                                            </>
                                    }
                                    {
                                        // props.staffinfo && 
                                        item.modby !== null &&
                                        <>
                                            <div className='box-body' style={{backgroundColor:"#F6F6F9", margin:10, borderRadius:10, position:"relative"}}>
                                                <div style={{
                                                    borderRadius:"50px", backgroundColor:"#F6F6F9", 
                                                    fontSize:14, fontWeight:"bold", color:"#000000",
                                                    }} >
                                                    Last Modified By
                                                </div>
                                                <p>
                                                    <strong> {modby.name}</strong>,&nbsp;
                                                    {modby.email},&nbsp;
                                                    {modby.contact}&nbsp;
                                                    ({modby.role})
                                                </p>
                                            </div>
                                            <br />
                                        </>
                                    }
                                    {
                                        //  props.alttermarks &&
                                        <div className='col-md-12 no-left-padding no-right-padding'>
                                            {
                                                update.id !== item.id &&
                                                
                                                <div className='col-md-3 no-left-padding no-right-padding pull-right'>
                                                    <div className='box no-border no-shadow'>
                                                        <div className='box-body'>
                                                            <button className='btn btn-primary btn-block' title='Change Marks' onClick={() => changemarks("id", item.id)} >
                                                                Update Marks ?
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            
                                                <div className='col-md-3'>
                                                    <p style={{paddingTop:"15px"}} >
                                                        <strong>Obtained: </strong> {item.earnmarks}
                                                    </p>
                                                </div>
                                            {
                                                update.id === item.id &&
                                                <>
                                                
                                                <div className='col-md-3 no-right-padding'>
                                                    <div className='box no-border no-shadow'>
                                                        <div className='box-body'>
                                                            <select className='form-control' defaultValue={update.type} onChange={(e) => changemarks("type", e.target.value)} >
                                                                <option value={"0"} >Incorrect</option>
                                                                <option value={"1"} >Correct</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className='col-md-3 no-left-padding no-right-padding'>
                                                    <div className='box no-border no-shadow'>
                                                        <div className='box-body'>
                                                            <input type={'number'} minLength={1 - quizdata.negMarks} maxLength={quizdata.posMarks} value={update.marks} step={0.01}  onChange={(e) => changemarks("marks", e.target.value)} className={"form-control"} placeholder={"e.g 1"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-3 no-left-padding no-right-padding'>
                                                    <div className='box no-border no-shadow'>
                                                        <div className='box-body'>
                                                            <button className='btn btn-primary btn-block' onClick={(e) => saveMarks(e)}  style={{overflow:"hidden"}} >
                                                                {
                                                                    update.loading ? 
                                                                    "Loading..."
                                                                    :
                                                                    "Update Marks"
                                                                }
                                                                
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                        }
                                        </div>
                                    }
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
            </>
        }
    </div>
  )
}
