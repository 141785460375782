import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
const cookies = new Cookies();

export default function Notifyme(props) {

  const params = useParams()
  const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()

    const [loading, setLoading] = useState(true)
    const [segment, setSegment] = useState(0)
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])


    const [startdate, setStartDate] = useState("")
    const [enddate, setEnddate] = useState("")

  const getNotifymeRequests = () => {
    setLoading(true)
    fetch(apiurl+"api/getNotifymeRequests", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                courseid: params.id,
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                startdate: startdate,
                enddate: enddate
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status == "OK"){
                setTotal(response.total)
                setList(response.list)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
        .catch(err => {
            
            // console.log(err);
        });
  }

  const  [sendingNotification, setSendintNotification] = useState("danger")
  const sendNotification = () => {
    if(sendingNotification === "danger"){
        setSendintNotification("warning")
        fetch(apiurl+"api/sendNotifyMeNotifications", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: params.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    startdate: startdate,
                    enddate: enddate
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setSendintNotification("success")
                    setTimeout(function(){
                        setSendintNotification("danger")
                    }, 5000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
            });
    }
  }

  const [downloading, setDownloading] = useState(false)
  const downloadRequest = () => {
      setDownloading(true)
      const th = ["Student Name", "Student Email", "Student Contact", "Date"]
      var segmentTemp = list
      var td = [];

      for(var j = 0; j < segmentTemp.length; j++){
          var temp = list[j]
          for(var i = 0;i<temp.length;i++){

            const user = JSON.parse(temp[i].userdata)
            moment.locale('en');
            var ct = temp[i].created_at;

              td.push(
                  [
                    user['name'], user['email'], user['contact'], moment(ct).format("D-M-Y hh:mm:ss A")
                  ]
              )
          }
      }
      
      
      fetch(apiurl+"api/downloadLists", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                  page_url: window.location.href,
                  staff: AdminBasics(),
                  accecible: 1,
                  th: th,
                  td: td,
                  for: "Request to download Notify me students List of ("+td.length+") Entries of from "+props.course.name+"."
              })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status === "OK"){
                  successModal("Success", response.message)
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              setDownloading(false)
          })
          .catch(err => {
              // console.log(err);
              setDownloading(false)
          });

  }

  useEffect(function(){
    getNotifymeRequests()
  },[])
  return (
    <>
    <br />
    <div className='col-md-4'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Filter List
                </h3>
                <span className='pull-right label bg-red cursor-pointer' onClick={() => {
                    setStartDate("")
                    setEnddate("")
                }}>Clear Filter</span>
            </div>
            <div className='box-body'>
                <label>Start Date</label>
                <input type='date' className='form-control' value={startdate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div className='box-body'>
                <label>End Date</label>
                <input type='date' className='form-control' value={enddate} onChange={(e) => setEnddate(e.target.value)} />
            </div>
            <div className='box-footer'>
                <button className='btn btn-primary btn-flat btn-block' onClick={() => getNotifymeRequests()}>
                    Filter List
                </button>
            </div>
        </div>
    </div>
    <div className='col-md-8'>
      <div className='box no-border'>
        <div className='box-header'>
          <h3 className='box-title'>
            Notify me requests
          </h3>
          

        {
            !downloading ?
            <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest()} >
                Download
            </button>
            :
            <button className='btn btn-warning btn-flat pull-right' >
                Preparing...
            </button>
        }
        {
            list.length > 0 &&
            <button className={'btn btn-'+sendingNotification+' btn-flat pull-right'} style={{marginRight:"10px"}} onClick={() => sendNotification()} >
                { sendingNotification === "danger" && "Send Notification" }
                { sendingNotification === "warning" && "Sending Notifications..." }
                { sendingNotification === "success" && "Notification Sent" }
            </button>
        }
        </div>
        {
            loading ? 
            <div className='box-body' style={{position:"relative"}}>
                <Loading />
                <br /><br /><br /><br /><br /><br /><br />
            </div>
            :
            <>
            <div className='box-body'>
                <table className='table table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list[segment] !== undefined &&
                            list[segment].length > 0 &&
                            list[segment].map((item, index) => {
                                const user = JSON.parse(item.userdata)
                                moment.locale('en');
                                var ct = item.created_at;
                                return(
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.contact}</td>
                                        <td>{moment(ct).format("D-M-Y hh:mm:ss A")}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
                <div className='box-footer'>
                    <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                        {
                            list.length > 0 && list.length <= 10 &&
                            list.map((item, index) => {
                                const active = index === segment ? " btn-primary" : ""
                                return(
                                    <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => setSegment(index)} >
                                        {index+1}
                                    </button>
                                )
                            })
                        }
                        {
                            list.length > 10 &&
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {list.length}</label>
                                <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                            </div>
                        }
                    </div>
                </div>
            </>

        }
      </div>
    </div>
    </>
  )
}
