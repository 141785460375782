import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import moment from 'moment';
const cookies = new Cookies();

export default function Mailcontent() {
    
  const params = useParams()
  const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
  const [loading, setLoading] = useState(null)
  const [list, setList] = useState([])
  const [showLearnersList, setShowLearnersList] = useState(false)
  const [showLearnersListLoading, setShowLearnersListLoading] = useState(false)
  const [learnersList, setLearnersList] = useState([])
  const [segment, setSegment] = useState(1)

  const getList = (page) => {
    setLoading(true)
    fetch(apiurl + "api/getCourseNotifications", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            page_url: window.location.href,
            staff: AdminBasics(),
            accecible: 1,
            course_id: params.id,
            page: page,
        })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setList(response.list)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
  }
  
  const showLearners = (item) => {
    
    setShowLearnersList(true)
    setShowLearnersListLoading(true)
    setSegment(1)
    fetch(apiurl + "api/getCourseSegmentList", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                id: item.date,
            })
        })
        .then(response => response.json())
        .then(response => {
            setLearnersList(response.notification)
            setShowLearnersListLoading(false)
        })
}


  var a = 0
  useEffect(() => {
    if(a === 0){
        getList()
        a++
    }
  }, [])
  return (
    <div className='col-md-12'>
        <br />
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Notification Lists
                </h3>
                {
                    loading &&
                    <div style={{position:"relative", float:"right", marginRight:"25px"}} >
                        <Loading />
                    </div>
                }
            </div>
            {
                    showLearnersList ?
                    <>
                    {
                    showLearnersListLoading ?
                        <>
                            <br /><br />
                            <Loading />
                            <br /><br />
                        </>
                        :
                        <div className="tab-pane active" id="tab_1" style={{backgroundColor:"#FFFFFF", overflow:"auto",}} >
                            <button className='btn' style={{ marginLeft:"10px"}} onClick={() => {
                                setShowLearnersList(false)
                                setLearnersList([])
                            }} ><i className='fa fa-arrow-left'></i> Back</button>
                            <div className='box-body'>
                                <table id="studenttable" className="table table-bordered table-hover datatable">
                                    <thead>
                                        <tr>
                                            <th style={{width:"80px"}} >S.No</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Contact</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {
                                            typeof learnersList[segment-1]['successusers'] !== "undefined" &&
                                            learnersList[segment-1]['successusers'] !== null &&
                                            JSON.parse(learnersList[segment-1]['successusers']).map((item, index) => {
                                                return(
                                                    <tr key={"segment"+index} >
                                                        <td style={{width:"80px"}} >{index+1}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <a href={"mailto:"+item.email} title={"Mail To " + item.email} >
                                                                {item.email}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href={"tel:" + item.contact}>
                                                                {item.contact}
                                                            </a>
                                                        </td>
                                                        <td>{item.date}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        <div className='col-md-12'>
                            <div className='box-footer text-right no-left-padding no-right-padding'>
                                {
                                    learnersList.length > 0 &&
                                    learnersList.map((item, index) => {
                                        const active = ((index+1) === segment) ? "btn-primary" : ''
                                        return(
                                            <button className={'btn btn-flat '+active} key={"page" + index} 
                                            onClick={() => setSegment(index+1)}
                                            style={{marginRight:"5px"}}
                                            >
                                                {index+1}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    }
                    </>
                    :
            <>
            <div className='box-body'>
                <table id="studenttable" className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th style={{width:"80px"}} >S.No</th>
                            <th>Notification</th>
                            <th>Date</th>
                            <th>Send To</th>
                            <th>Sent By (Name)</th>
                            <th>Sent By (Email)</th>
                            <th>Sent By (Contact)</th>
                            <th>Sent By (Role)</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {
                           typeof list.data !== "undefined" && list !== null &&
                            // <></>
                            list.data.length > 0 &&
                            list.data.map((item, index) => {
                                var staff = JSON.parse(item.staff)
                                moment.locale('en');
                                var ct = item.created_at;
                                var notification = item.notification !== null ? JSON.parse(item.notification) : null
                                return(
                                    <tr key={"key"+index}>
                                        <td style={{width:"80px"}} >{index+1}</td>
                                        
                                        <td>
                                            {
                                                notification !== null &&
                                                <>
                                                    <small>
                                                        <strong>{notification.title}</strong><br />
                                                        {notification.app_body}
                                                    </small>
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {moment(ct).format("D-M-Y HH:mm:ss")}
                                        </td>
                                        <td onClick={() => showLearners(item)} className="cursor-pointer" title='Show Learners'>{item.sendto}</td>
                                        <td>{staff.name}</td>
                                        <td><a href={"mailto:"+staff.email}>{staff.email}</a></td>
                                        <td><a href={"tel:"+staff.contact}>{staff.contact}</a></td>
                                        <td>{staff.role}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className='box-footer text-right'>
                
                {
                    typeof list.data !== "undefined" && list !== null &&
                    [...Array(list.last_page)].map((item, index) => {
                        var active = list.current_page === index+1 ? " btn-primary" : ""
                        return(
                            <button className={'btn btn-flat'+active} key={"navs"+index}  onClick={() => getList(index+1)} style={{marginRight:"10px"}} >
                                {index+1}
                            </button>

                        )
                    })
                }
            </div>
            </>
            }
        </div>
    </div>
  )
}
