import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
const cookies = new Cookies();

function printimg(files){
    const obj = URL.createObjectURL(files)
    return obj;
  }

export default function Upsale(props) {

    const params = useParams()
    const [editId, setEditId] = useState("")
    const [planName, setPlanName] = useState("")
    const [listingPrice, setListingPrice] = useState(0)
    const [sellingPrice, setSellingPrice] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [gst, setGst] = useState(5)
    const [expireDays, setExpireDays] = useState("")

    const [exactdate, setExactdate] = useState("")
    const [hours, setHours] = useState("")

    const [showForm, setshowForm] = useState(false)
    const [plans, setPlans] = useState([])
    const [variations, setVariations] = useState([])
    const [plansLoading, setPlansLoading] = useState(true)
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [free, setFree] = useState(props.course.free)
    
    const [cbpercent, setCbPercentage] = useState("")
    const [cbdays, setCbDays] = useState("")
    const [cbcap, setCbCap] = useState("")

    const [cid, setCid] = useState("")
    const [varplans, setVarplans] = useState([])
    const [varplanid, setVarplanid] = useState("")
    const [pcbanner, setPcbanner] = useState("")
    const [mobbanner, setMobbanner] = useState("")


    const calculatePrice = (type) => {
        if(type === "Price"){
            // if(sellingPrice > 0){
                setDiscount(listingPrice - sellingPrice)
            // }
        }else if(type === "Discount"){
            setSellingPrice(listingPrice - discount)
        }
    }


    
    const addVariation = (e) => {
        e.preventDefault()
        formLoading(e.target)
        
        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('editId', editId);
        formData.append('courseid', params.id);
        formData.append('planName', planName);
        formData.append('listingPrice', listingPrice);
        formData.append('sellingPrice', sellingPrice);
        formData.append('exactdate', exactdate);
        formData.append('hours', hours);
        formData.append('discount', discount);
        formData.append('gst', gst);
        formData.append('expireDays', expireDays);
        formData.append('type', "Upsale");
        
        formData.append('pcbanner', pcbanner);
        formData.append('mobbanner', mobbanner);
        formData.append('selectedcourse', cid);
        formData.append('selectedplan', varplanid);

        fetch(apiurl+"api/add_upsale", {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").fadeOut()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getUpsales()
                    setshowForm(false)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                
                // console.log(err);
            });
    }

    const getUpsales = () => {
        setPlansLoading(true)
        fetch(apiurl+"api/get_plans", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    courseid: params.id,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    type: "Upsale"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setPlans(response.plans)
                    setPlansLoading(false)
                    if(response.cb !== null){
                        setCbDays(response.cb.cbdays)
                        setCbCap(response.cb.cbcap)
                        setCbPercentage(response.cb.cbpercent)
                    }

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setPlansLoading(false)
                }
            })
            .catch(err => { });
    }


    const getVariations = (cid = "") => {
        fetch(apiurl+"api/get_courses_and_plans", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    courseid: params.id,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    cid: cid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setVariations(response.list)
                    setVarplans(response.plans)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setPlansLoading(false)
                }
            })
            .catch(err => { });
    }

    const setVarData = (index) => {
        
        setVarplanid(varplans[index]['id'])
        // setPlanName(varplans[index]['plan_name'])
        // setListingPrice(varplans[index]['listing_price'])
        // setSellingPrice(varplans[index]['selling_price'])
        // setDiscount(varplans[index]['discount'])
        // setGst(varplans[index]['gst'])
        // setExpireDays((varplans[index]['days'] === null) ? "" : varplans[index]['days'])
        // setExactdate((varplans[index]['exactdate'] === null) ? "" : varplans[index]['exactdate'])
        // setHours((varplans[index]['hours'] === null) ? "" : varplans[index]['hours'])
    }
    
    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_plans", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    courseid: params.id,
                    position_list: array,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    type: "Upsale"
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }
    const removePlan = (id) => {
      fetch(apiurl+"api/remove_plan", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                  page_url: window.location.href,
                  planid: id,
                  courseid: params.id,
                  staff: AdminBasics(),
                  accecible: 1
              })
          })
          .then(response => response.json())
          .then(response => {
  
              if(typeof response.status !== "undefined" && response.status == "OK"){
                  successModal("Success", response.message)
                  getUpsales()
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
          })
          .catch(err => {
              // console.log(err);
          });
  }
  
    const removeDialog = (id) => {
      confirmAlert({
          title: 'Are You Sure?',
          message: "You won't be able to revert this back.",
          buttons: [
            {
              label: 'Yes',
              onClick: () => removePlan(id)
            },
            {
              label: 'No',
              onClick: () => console.log("Canceled")
            }
          ]
      });
  }

  const editPlan = (item) => {
    setPlanName(item.plan_name)
    setListingPrice(item.listing_price)
    setSellingPrice(item.selling_price)
    setDiscount(item.discount)
    setGst(item.gst)
    setExpireDays(item.days)
    setEditId(item.id)
    setshowForm(true)
  }
  const clean = () => {
    setPlanName("")
    setListingPrice(0)
    setSellingPrice(0)
    setDiscount(0)
    setGst(5)
    setExpireDays("")
    setEditId("")
  }

    const closeForm = () => {
        clean()
        setshowForm(false)
    }

    const setFreeCourse = (free) => {
        fetch(apiurl+"api/setFreeCourse", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    free: free,
                    courseid: props.course.otherid,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
            });    
    }

    const changeStatus = (item, value) => {
        
        fetch(apiurl+"api/change_price_status", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    activeid: item.id,
                    value: value,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                // CategoryList()
            })
            .catch(err => { });
    }


    
    var b = 0;
    useEffect(() => {
        if(b === 0){
            getVariations()
            getUpsales()
            setTimeout(function(){
                callSorting()
            }, 2000)
            b++
        }
    }, [])

  return (
    <>
    <br />
        {
            !showForm &&
            <>
            <div className='col-md-12' onClick={() => {
                clean()
                setshowForm((showForm) ? false : true)
            }}>
                <button className='btn btn-primary btn-flat pull-right'>
                    <i className='fa fa-plus'></i> Add New Upsale
                </button>
            </div>
            <br />
            <br />
            <br />
            </>
        }
        {
            showForm &&
        <div className='col-md-6'>
            <form action='' method='POST' onSubmit={(e) => addVariation(e)}>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            {editId === "" ? "Add New Upsale" : "Update Upsale"}
                        </h3>
                        <span className='pull-right cursor-pointer' onClick={() => closeForm()}>
                            <i className='fa fa-times'></i>
                        </span>
                    </div>
                    <div className='box-body'>
                        <label>Web Banner</label>
                        <div className='uploadbanner' onClick={(e) => {
                            window.$(e.target).find("input").click()
                        }}>
                            {
                                pcbanner !== "" ?
                                <img src={printimg(pcbanner)} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                                :
                                <>
                                    <div style={{padding:"40px 0"}}>
                                        Upload &nbsp; <i className='fa fa-upload'></i>
                                    </div>
                                </>
                            }
                            
                            <input type={"file"} style={{display:"none"}} accept={".png,.jpg,.jpeg"} onChange={(e) => setPcbanner(e.target.files[0])} />
                        </div>
                    </div>
                    <div className='box-body'>
                        <label>App Banner</label>
                        <div className='uploadbanner' onClick={(e) => {
                            window.$(e.target).find("input").click()
                        }}>
                            
                            {
                                mobbanner !== "" ?
                                <img src={printimg(mobbanner)} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                                :
                                <>
                                    <div style={{padding:"40px 0"}}>
                                        Upload &nbsp; <i className='fa fa-upload'></i>
                                    </div>
                                </>
                            }
                            <input type={"file"} style={{display:"none"}} accept={".png,.jpg,.jpeg"} onChange={(e) => setMobbanner(e.target.files[0])} />
                        </div>
                    </div>
                    <div className='box-body'>
                        <label>
                            Select Course/Package <span className='text-red'>*</span>
                        </label>
                        <select className='form-control' onChange={(e) => {
                            setCid(e.target.value)
                            getVariations(e.target.value)
                        }} >
                            <option value={""}>Select Course/Package</option>
                            {
                                variations.length > 0 &&
                                variations.map((item, index) => {
                                    return(
                                        <option key={"key"+index} value={item.otherid}>{item.name} ({item.otherid})</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='box-body'>
                        <label>
                            Select Plan <span className='text-red'>*</span>
                        </label>
                        <select className='form-control' onChange={(e) => {
                            setVarData(e.target.value)
                        }} >
                            <option value={""}>Select Plan</option>
                            {
                                varplans.length > 0 &&
                                varplans.map((item, index) => {
                                    return(
                                        <option key={"plan"+index} value={index}>{item.plan_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='box-body'>
                        <label>
                            Upsale Name <span className='text-red'>*</span>
                        </label>
                        <input type={"text"} name={"planname"} placeholder={"e.g 1 Month Subscription"} className="form-control" required value={planName} onChange={(e) => setPlanName(e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <div className='col-md-6 no-left-padding'>
                            <label>
                                Listing Price <span className='text-red'>*</span>
                            </label>
                            <input type={"number"} name={"planname"} step={0.01} placeholder={"e.g 5000"} className="form-control" required value={listingPrice} onChange={(e) => setListingPrice(e.target.value)} onKeyUp={() => calculatePrice("Price")} />
                        </div>
                        <div className='col-md-6 no-right-padding'>
                            <label>
                                Selling Price <span className='text-red'>(Leave Blank For Free)</span>
                            </label>
                            <input type={"number"} name={"planname"} step={0.01} placeholder={"e.g 3000"} className="form-control" value={sellingPrice} onChange={(e) => setSellingPrice(e.target.value)} onKeyUp={() => calculatePrice("Price")} />
                        </div>
                    </div>
                    <div className='box-body'>
                        <div className='col-md-6 no-left-padding'>
                            <label>
                                Discount <span className='text-red'>*</span>
                            </label>
                            <input type={"number"} name={"planname"} step={0.01} placeholder={"e.g 2000"} className="form-control" required value={discount} onChange={(e) => setDiscount(e.target.value)}  onKeyUp={() => calculatePrice("Discount")}  />
                        </div>
                        <div className='col-md-6 no-right-padding'>
                            <label>
                                GST(%) <span className='text-red'>*</span>
                            </label>
                            <input type={"number"} name={"planname"} step={0.01} placeholder={"e.g 5"} className="form-control" required value={gst} onChange={(e) => {
                                setGst(e.target.value)
                            }}  />
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        <div className='col-md-4 no-left-padding'>
                            <label>
                                Expired in Days &nbsp;<br />
                                <span className='text-red'>(Leave Blank For Lifetime)</span>
                            </label>
                            <input type={"number"} name={"planname"} placeholder={"e.g 30"} className="form-control" value={expireDays} onChange={(e) => {
                                    setExactdate("")
                                    setExpireDays(e.target.value)
                                }}  />
                        </div>
                        <div className='col-md-4 '>
                            <label>
                                Exact Date
                            </label>
                            <input type={"date"} name={"planname"} placeholder={"e.g 30"} className="form-control" value={exactdate} onChange={(e) => {
                                    setExpireDays("")
                                    setExactdate(e.target.value)
                                }}  />
                        </div>
                        <div className='col-md-4 no-right-padding'>
                            <label>
                                Total Hours<br />
                                <span className='text-red'>(Leave Blank For Lifetime)</span>
                            </label>
                            <input type={"number"} name={"planname"} placeholder={"e.g 30"} className="form-control" value={hours} onChange={(e) => {
                                    setHours(e.target.value)
                                }}  />
                        </div>
                    </div>
                    <div className='box-body'>
                        <button className='btn btn-primary btn-flat btn-block'>
                            <i className='fa fa-save'></i> 
                            {editId === "" ? " Add Plan" : " Update Plan"}
                        </button>
                    </div>
                </div>
            </form>    
        </div>
        }

        <div className='col-md-12'>
            

            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Upsale Plans
                    </h3>
                    <span className='pull-right' style={{display:"none"}}>
                        Free Course:- &nbsp;&nbsp; 
                        <label className="switch">
                            <input type="checkbox" defaultChecked={(free === 1) ? true : false} onChange={(e) => {
                                setFree(free === 1 ? 0 : 1)
                                setFreeCourse(free === 1 ? 0 : 1)
                            }} />
                            <span className="slider round"></span>
                        </label>
                    </span>
                </div>

                {
                    plansLoading ? 
                    
                    <>
                        <br /><br /><br />
                            <Loading />
                        <br /><br /><br />
                    </>
                    :

                    <div className={(free === 1) ? 'box-body opacityTable' : 'box-body'} >
                        <table className='table table-bordered table-hover'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Drag</th>
                                    <th>Course | Plan Name</th>
                                    <th>Upsale Name</th>
                                    <th>Listing Price</th>
                                    <th>Selling Price</th>
                                    <th>Discount</th>
                                    <th>OFF</th>
                                    <th>GST</th>
                                    <th>Expiry Days</th>
                                    <th>Exact Date</th>
                                    <th>Hours</th>
                                    <th className='text-center'>
                                        Status
                                    </th>
                                    <th className='text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className='connected-sortable droppable-area1' >
                                {
                                    plans.length > 0 &&
                                    <>
                                    {
                                        plans.map((item, index) => (
                                                <tr key={"plan"+index}  className="draggable-item" data-bind={item.id}>
                                                    <td className='text-center text-center dragHandle' data-bind={item.id}>
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td>{item.forcoursename + " | " + item.forplanname}</td>
                                                    <td>{item.plan_name}</td>
                                                    <td>{item.listing_price}</td>
                                                    <td>{item.selling_price}</td>
                                                    <td>{item.discount}</td>
                                                    <td>{item.off}%</td>
                                                    <td>{item.gst}</td>
                                                    <td>{(item.days === "" || item.days === null || item.days === 0) ? 'Lifetime' : item.days}</td>
                                                    <td>{item.exactdate}</td>
                                                    <td>{item.hours}</td>
                                                    <td className='text-center'>
                                                    <label className="switch">
                                                        <input type="checkbox" defaultChecked={(item.status === 1) ? true : false} onChange={(e) => changeStatus(item, window.$(e.target).prop('checked'))} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    </td>

                                                    <td className='text-right'>
                                                        {/* <button className='btn btn-primary btn-primary' onClick={() => editPlan(item)}>
                                                            <i className='fa fa-pencil'></i>
                                                        </button> */}
                                                        &nbsp;
                                                        <button className='btn btn-primary btn-danger' onClick={() => removeDialog(item.id)}>
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            
            
        </div>

    </>
  )
}
