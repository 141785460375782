import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();


export default function Filefeedback(props) {

    const params = useParams()
    const courseData = (props.course.coursedata !== "" && props.course.coursedata !== null) ? JSON.parse(props.course.coursedata) : ""
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")

    const [filter, setFilter] = useState({
        start: "",
        end: "",
        rating: "",
    })

    const [segment, setSegment] = useState(0)
    const fetchList = () => {
        setLoading(true)
        console.log(filter)
        fetch(apiurl+"api/get_feedbacks", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      filter: filter
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.feedbacks)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
      }
      
    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // fetchList()
    }

    const filterFeedbacks = (e) => {
        e.preventDefault();
        fetchList()
    }


    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        
        const th = ["Student ID", "File Name", "File Type", "File Path", "Rating", "Remark", "Date"]


        var segmentTemp = list
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = list[j]
            for(var i = 0;i<temp.length;i++){
                const review = JSON.parse(temp[i]['feedback'])
                const path = JSON.parse(temp[i]['path'])
                moment.locale('en');
                var ct = temp[i]['updated_at'];
                
                var pathstring = ""
                if(path !== null){
                    for(var k = 0;k<path.length;k++){
                        pathstring += path[k]['name']+"/ "
                    }
                }
                td.push(
                    [
                        temp[i]['userid'], path !== null ? path[path.length-1]['name'] : '-', temp[i]['type'], pathstring, review['star'], review['remark'], moment(ct).format("D-M-Y hh:mm:ss A")
                    ]
                )
            }
        }


        // var td = [];
        // for(var i = 0;i<temp.length;i++){
        //     const review = JSON.parse(temp[i]['feedback'])
        //     const path = JSON.parse(temp[i]['path'])
        //     moment.locale('en');
        //     var ct = temp[i]['updated_at'];
            
        //     var pathstring = ""
        //     if(path !== null){
        //         for(var k = 0;k<path.length;k++){
        //             pathstring += path[k]['name']+"/ "
        //         }
        //     }
        //     td.push(
        //         [
        //             temp[i]['userid'], path !== null ? path[path.length-1]['name'] : '-', temp[i]['type'], pathstring, review['star'], review['remark'], moment(ct).format("D-M-Y hh:mm:ss A")
        //         ]
        //     )
        // }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download File Feedbacks List of ("+td.length+") Entries"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
      var a = 0
      useEffect(() => {
        if(a === 0){
            fetchList()
            a++
        }
      }, [])

  return (
    <div className='col-md-12 no-left-padding no-right-padding'>
        {
            <>
            <div className='col-md-4'>
                <br />
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filters
                        </h3>
                        {
                            filter.start === "" && filter.end === "" && 
                            <>
                                <br />
                                <small style={{color:"red"}}>
                                    You are currently viewing data of last 7 days.
                                </small>
                            </>
                        }
                    </div>
                    
                    <form action='' method='POST' onSubmit={(e) => filterFeedbacks(e)} >
                        <div className='box-body'>
                            <label>Start Date</label>
                            <input type={"date"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                        </div>   
                        <div className='box-body'>
                            <label>End Date</label>
                            <input type={"date"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                        </div>   
                        <div className='box-body'>
                            <label>Ratings</label>
                            <select defaultValue={filter.rating} className={"form-control"} onChange={(e) => changeValue(e.target.value, "rating")}>
                                <option value={""}>Any</option>
                                <option value={5}>5 Star</option>
                                <option value={4}>4 Star</option>
                                <option value={3}>3 Star</option>
                                <option value={2}>2 Star</option>
                                <option value={1}>1 Star</option>
                            </select>
                        </div>   
                        <div className='box-footer'>
                            <button className='btn btn-flat btn-primary btn-block'>
                                <i className='fa fa-search'></i> &nbsp;
                                Search
                            </button>    
                        </div>     
                    </form>
                    
                </div>
            </div>
            <div className='col-md-8'>
                <br />
                {
                    
                    loading ? 
                    <>
                        <br /><br /><br />
                        <Loading />
                        <br /><br /><br />
                    </>
                    :
                    
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Feedbacks
                        </h3>
                    {
                        !downloading ?
                        <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                            Download
                        </button>
                        :
                        <button className='btn btn-warning btn-flat pull-right' >
                            Preparing...
                        </button>
                    }
                    </div>
                    <div className='box-body' style={{overflow:"auto"}}>
                        <table id="contenttable" className="table file_manager_table">
                        <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Student ID</th>
                                    <th>File Name</th>
                                    <th>File Type</th>
                                    <th>File Path</th>
                                    <th>File Rating</th>
                                    <th>File Remark</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.length > 0 && typeof list[segment] !== "undefined" && list[segment].length > 0 && 
                                    list[segment].map((item, index) => {
                                        const review = JSON.parse(item.feedback)
                                        const path = JSON.parse(item.path)
                                        moment.locale('en');
                                        var ct = item.updated_at;
                                        return(
                                            <tr key={"feedback"+index} >
                                                <td>{index+1}</td>
                                                <td>
                                                    <Link to={"#"} target={"_BLANK"} >
                                                        {item.userid}
                                                    </Link>    
                                                </td>
                                                <td>
                                                    
                                                    {   path !== null &&
                                                        path[path.length-1]['name']
                                                    }
                                                </td>
                                                <td>{item.type}</td>
                                                <td>
                                                    {
                                                        path !== null && path.length > 0 &&
                                                        path.map((item2, index2) => {
                                                            return(
                                                                <Link to={"/admin/course-content/"+params.id+"/"+item2.p_id} key={"path"+item2.id+"_"+index2} target={"_BLANK"} >
                                                                    {item2.name+" / "}
                                                                </Link>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td>{review.star}</td>
                                                <td>{review.remark}</td>
                                                <td>
                                                    {moment(ct).format("D-M-Y hh:mm:ss A")}
                                                </td>
                                            </tr>
                                            
                                            )
                                        })
                                }
                            </tbody>
                            
                        </table>
                    </div>
                    
                    <div className='box-footer text-right'>
                        {
                            list.length > 0 && list.length < 10 &&
                            list.map((item, index) => {
                                const active = segment === index ? " btn-primary " : ""
                                return(
                                    <button className={'btn btn-flat'+active} style={{marginLeft:"10px"}} key={"navs"+index} onClick={() => setSegment(index)} >
                                        {index+1}
                                    </button>
                                )
                            })
                        }
                        {
                            list.length > 10 &&
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {list.length}</label>
                                <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                            </div>
                        }
                    </div>
                </div>
                }
            </div>
            </>
        }
    </div>
  )
}
