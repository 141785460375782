import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
// import Adminprops from '../../VelidateWebsite/Adminprops'
// import { Link, useNavigate } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

export default function Loginsteps() {
  const LoginstepsPage = (props) => {

    const navigate = useNavigate()
    const [stepId, setStepId] = useState('')
    const [stepIcon, setStepIcon] = useState('')
    const [stepHeading, setStepHeading] = useState('')
    const [stepParagraph, setStepParagraph] = useState('')
    
    const [steps, setStepList] = useState([])
    
    // const [loading, setLoading] = useState(true)
    // const adminProps = Adminprops()
    // const admin = cookies.get('admin')
    // const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
    
    // const [refres, setRefresh] = useState("No")
    const addStep = (e) => {
        
        formLoading(e.target)
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('stepIcon', stepIcon)
        formData.append('stepHeading', stepHeading);
        formData.append('stepParagraph', stepParagraph);
        formData.append('stepId', stepId);

        // setLoading(true)
        var url = (stepId === "") ? 'add_new_step' : 'update_step'
        fetch(apiurl+"api/"+url, {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    StepList()
                    cleanFields()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                // setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    
    const StepList = () => {
        setStepList([])
        fetch(apiurl+"api/steps_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setStepList(response.steps)
                    setTimeout(function(){
                        callSorting()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                // setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const removeStep = (id) => {
        fetch(apiurl+"api/remove_step", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    id:id
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    StepList()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                // setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeStep(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }


    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_steps_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }

    const editSteps = (item) => {
        setStepId(item.id)
        setStepHeading(item.heading)
        setStepParagraph(item.paragraph)
        setStepIcon(item.paragraph)
    }
    const cleanFields = () => {
        setStepId("")
        setStepHeading("")
        setStepParagraph("")
        setStepIcon("")
    }

    var a = 0
    useEffect(() => {
        if(a === 0){
            StepList()
            a++
        }
    }, [])


    return(
        <>

        {
            
            pagePermissions(props.pagepermissions, "Login_Steps", "add_steps") &&

      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
                {
                    stepId !== "" ? 
                    "Update Step"
                    :
                    "Add New Step"
                }
               
            </h3>
            {
                stepId !== "" &&
                <span onClick={() => cleanFields() } className={"pull-right cursor-pointer"}>
                    <i className='fa fa-times'></i>
                </span>
            }
          </div>
          <form action='' method='POST' onSubmit={addStep} id="add_staff">
            <div className='box-body'>
                <label>Step Icon<span className='text-red'> (200PX * 200PX, PNG, Under 200KB)</span></label>
                <input type={"file"} accept={".png"} name="file" className='form-control' onChange={e => setStepIcon(e.target.files[0])}/>
            </div>
            <div className='box-body'>
                <label>Step Heading<span className='text-red'>*</span></label>
                <input type={"text"} name="categoryPackageText" placeholder='e.g How To Login' className='form-control' value={stepHeading} required onChange={e => setStepHeading(e.target.value)}/>
            </div>
            <div className='box-body'>
                <label>Step Paragraph<span className='text-red'>*</span></label>
                <input type={"text"} name="categoryFreeText" placeholder='e.g Click to login button' className='form-control' value={stepParagraph} required onChange={e => setStepParagraph(e.target.value)}/>
            </div>
            
            
            <div className='box-footer'>
                <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={ ( stepId !== "" ) ? "Update Step" : "Add Step"} required />
            </div>
            
          </form>
        </div>
      </div>
  }
  {
    // pagePermissions(props.pagepermissions, "Login_Steps", "view_category") &&
        <div className='col-md-8'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Steps List
                    </h3>
                </div>
                {
                    steps.length < 1 ?
                    <div className='box-body'>
                        No Steps Found
                    </div>
                    :
                    <div className='box-body'>
                        <table id="staff_table" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        Icon
                                    </th>
                                    <th>
                                        Heading
                                    </th>
                                    <th>
                                        paragraph
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='connected-sortable droppable-area1' >
                                {
                                    steps.map((item, index) => (
                                        <tr 
                                        key={"data"+index}
                                        id={"id"+item.id}>
                                            
                                            <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                <i className='fa fa-arrows-v'></i>
                                            </td>
                                            <td>
                                                <img src={assets_url+item.image} style={{width:"80px", height:"80px", objectFit:"cover"}} />
                                            </td>
                                            <td>
                                                {item.heading}
                                            </td>
                                            <td>
                                                {item.paragraph}
                                            </td>
                                            <td>
                                                {
                                                    pagePermissions(props.pagepermissions, "Login_Steps", "edit_steps") &&
                                                        <button className='btn btn-primary btn-flat' onClick={() => editSteps(item)}>
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                }
                                                &nbsp;&nbsp;
                                                {
                                                    pagePermissions(props.pagepermissions, "Login_Steps", "remove_steps") &&
                                                        <button className='btn btn-danger btn-flat' onClick={() => removeDialog(item.id)} >
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                }
                                                &nbsp;&nbsp;
                                            </td>
                                        </tr>
                                        
                                        ))
                                    }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
  }
      </>
    )
  }
  return (
    <>
      <Sidebar page={LoginstepsPage} permission="Login_Steps"/>
    </>
  )
}
