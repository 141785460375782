
import Cookies from 'universal-cookie';
import $ from "jquery"
import apiurl from '../../Global';
import Adminprops from '../../VelidateWebsite/Adminprops';
import { useState } from 'react';

const cookies = new Cookies();

export default function CommonFunc(){

  const [data, setData] = useState("Test")

    const Admin = () => {
      var admin = cookies.get("admin");
      return admin;
    };

    const AdminBasics = () => {
      var admin = cookies.get("admin");
      return {
        id: admin.id,
        name: admin.name,
        email: admin.email,
        contact: admin.contact,
        role: admin.role,
      };
    };

    const successModal = (title, msg) => {
        var modal = document.createElement("li")
        modal.className = "success_modal"

        var div = document.createElement("div")
        div.className = "error_success_data"
        div.innerHTML = "<i class='fa fa-check-square-o'></i> <strong>"+title+"</strong><p className='error_success_para'>"+msg+"</p>"

        modal.appendChild(div);
        var update = document.getElementById("error_success").appendChild(modal);

        $(update).fadeIn()

        setTimeout(function(){
            $(update).fadeOut()
        }, 3000)
    };
    
    const warningModal = (title, msg) => {
        var modal = document.createElement("li")
        modal.className = "warning_modal"

        var div = document.createElement("div")
        div.className = "error_success_data"
        div.innerHTML = "<i class='fa fa-exclamation-triangle'></i> <strong>"+title+"</strong><p className='error_success_para'>"+msg+"</p>"

        modal.appendChild(div);
        var update = document.getElementById("error_success").appendChild(modal);

        $(update).fadeIn()
        setTimeout(function(){
            $(update).fadeOut()
        }, 3000)
    };
    

    const alertModal = (title, msg) => {
      var modal = document.createElement("li")
      modal.className = "alert_modal"

      var div = document.createElement("div")
      div.className = "error_success_data"
      div.innerHTML = "<i class='fa fa-exclamation-triangle'></i> <strong>"+title+"</strong><p className='error_success_para'>"+msg+"</p>"

      modal.appendChild(div);
      var update = document.getElementById("error_success").appendChild(modal);

      $(update).fadeIn()
      
  };
  

    const errorModal = (title, msg) => {
        var modal = document.createElement("li")
        modal.className = "error_modal"

        var div = document.createElement("div")
        div.className = "error_success_data"
        div.innerHTML = "<i class='fa fa-times'></i> <strong>"+title+"</strong><p className='error_success_para'>"+msg+"</p>"

        modal.appendChild(div);
        var update = document.getElementById("error_success").appendChild(modal);

        $(update).fadeIn()
        setTimeout(function(){
            $(update).fadeOut()
        }, 3000)
    };
    
    const formLoading = (element) => {
        $(element).css("position","relative")
        var loaderbody = document.createElement("div")
        loaderbody.className = "loaderbody"

        
        var loader = document.createElement("div")
        loader.className = "loader"
        loaderbody.append(loader)
        $(element).append(loaderbody)
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const pagePermissions = (permissions_list, pagename , permission) => {
      // console.log(permissions_list)
      // console.log(pagename.startsWith("course1"))
      // GLOBALCOURSE 
      var permissionArray = permissions_list
      var role = AdminBasics()
      // console.log(pagename)
      if((role.role == "Admin") ||( pagename == "Global")){
        return true
      }else{
        
        if(permissionArray !== null && typeof permissionArray !== "undefined" && permissionArray !== "none"){
          permissionArray = permissionArray.filter(function(item){
              var check = (pagename.startsWith("course1")) ? "GLOBALCOURSE" : ""
              if((item.page_name === pagename) || (item.page_name === check)){
                return item 
              }
          })
          if(permissionArray.length > 0){
            var filtered = permissionArray[0].staff_facilities.filter(function(item){
                if(item.name === permission){
                  return item 
                }
            })
            // console.log(filtered)
          }
          
          return ((typeof filtered !== "undefined" && filtered.length > 0) ? filtered[0]['value'] : false)
          
        }else{
          return false
        }
      }
    }

    const updatTrail = (message, tag, flag) => {
      fetch(apiurl+"api/update_trail", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+cookies.get('token')
        },
        "body": JSON.stringify({
                accecible: 1, 
                page_url: window.location.href,
                message: message,
                tag: tag,
                flag: flag,
                staff: JSON.stringify(AdminBasics())
            })
        })
        .then(response => response.json())
        .then(response => {
        })
        .catch(err => {
        });
    }
    
    const assets_url_old = "https://assets.escholarsadmin.com/"
    const assets_url = "https://assets.escholars.in/" 
    
  
  return {Admin, AdminBasics, successModal, warningModal, errorModal, alertModal, formLoading, capitalizeFirstLetter, pagePermissions, assets_url, updatTrail}
}
  