import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import FeatherIcon from 'feather-icons-react';
// import { useNavigate, useParams } from 'react-router-dom';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const noImage = "/assets/assets/images/no-image.png"
const cookies = new Cookies();

export default function Filtercourse(props) {
    
    const FiltercoursePage = () => {

    //     const navigate = useNavigate()
    //     const params = useParams()
    //   const admin = cookies.get('admin')
      let getToken = cookies.get('token')
      const [courses, setCourses] = useState([])
      const [loading, setLoading] = useState("")
      const [cardStyle, setCardStyle] = useState("Grid")
      const [categoryList, setCategoryList] = useState([])
      const [categoryLoading, setCategoryLoading] = useState(true)
      const {AdminBasics, errorModal, successModal, assets_url, pagePermissions} = CommonFunc()
      const [filter, setFilter] = useState(
        {
            "created_from" : "",
            "created_to" : "",
            "updated_from" : "",
            "updated_to" : "",
            // "course_type" : "",
            "course_type" : (props.type !== "package") ? "" : "Course",
            "course_status" : "",
            "searchkey" : "",
            "categories" : [],
            "free" : "",
            "type" : "Yes",
        }
    )

    const CategoryList = () => {
        setCategoryLoading(true)
        fetch(apiurl+"api/category_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCategoryList(response.categories)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setCategoryLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const[refresh, setRefresh] = useState("No")

    const setFilterValues = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        fetchCourses()
    }

    const clearFilters = (cols) => {
        var fcols = cols.split(",")
        var temp = filter
        for(var i = 0;i<fcols.length;i++){
            if(fcols[i] !== "categories"){
                temp[fcols[i]] = ""
            }else{
                temp[fcols[i]] = []
            }
        }
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        fetchCourses()
    }

    const searchProduct = (e) => {
        e.preventDefault()
        setFilterValues("searchkey", filter.searchkey)
    }


    const fetchCourses = () => {
        setLoading(true)
        fetch(apiurl+"api/fetch_courses", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    filter: filter,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCourses(response.course)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
    }
    
    const [packageContent, setPackageContent] = useState( (typeof props.course !== "undefined" && props.course.packagecontent !== null) ? JSON.parse(props.course.packagecontent) : []) 
    const goToCourse = (courseid, e) => {
        if(props.type === "allcourse"){
            window.open("/admin/course/"+courseid)
        }

        var falsetemp = JSON.stringify(packageContent)
        var temp = JSON.parse(falsetemp)
        if(props.type === "package"){
            var index = temp.indexOf(courseid);
            var fromto = ""
            var buttons = ""
            
            if (index !== -1) {
                temp.splice(index, 1);
                fromto = "Remove from"
                buttons = [
                    {
                    label: 'Remove From Existing',
                    onClick: () => addCourseToPackage(temp, "removeexisting", courseid)
                    },
                    {
                    label: 'Remove From Package Only',
                    onClick: () => addCourseToPackage(temp, "remove", courseid)
                    },
                    {
                    label: 'Cancel',
                    onClick: () => console.log("Canceled")
                    }
                ]
            }else{
                temp.push(courseid)
                fromto = "Add to"
                buttons = [
                    {
                    label: 'Add To Existing',
                    onClick: () => addCourseToPackage(temp, "existing", courseid)
                    },
                    {
                    label: 'Add To Package Only',
                    onClick: () => addCourseToPackage(temp, "non", courseid)
                    },
                    {
                    label: 'Cancel',
                    onClick: () => console.log("Canceled")
                    }
                ]
            }


            confirmAlert({
                title: fromto + ' ?',
                message: fromto + " Package Only or Add to existing users as well?",
                buttons: buttons
            });

        }
    }

    const addCourseToPackage = (temp, type, courseid) => {
        
        setPackageContent(temp)
        setPackageContentInCourse(temp, type, courseid)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const setPackageContentInCourse = (courseids, type, courseid) => {
        setLoading(true)
        fetch(apiurl+"api/set_packages", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseids: courseids,
                    packageid: props.course.otherid,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    type: type,
                    courseid: courseid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
            });
    }

    const selectCategoryType = (type) => {
        var temp = filter
        var typeList = temp['categories']
        var index = typeList.indexOf(type);
        if (index !== -1) {
            typeList.splice(index, 1);
        }else{
            typeList.push(type)
        }
        temp['categories'] = (typeList.length > 0) ? typeList : []
        setFilter(temp)
        fetchCourses()
    }
    
    var a = 0
    useEffect(() => {
        if(a === 0){
            fetchCourses()
            CategoryList()
            a++
        }
    }, [])


        return(
          <>
            <div className='col-md-12 no-left-padding no-right-padding' style={{marginBottom:"20px"}}>
                <div className='assets-lib' style={{alignItems:"flex-start"}} >
                    <div className='col-md-3 assetsbars'>
                        <div className='box no-border filtersArea' style={{maxHeight:"100%"}}>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    <strong> Filters </strong>
                                </h3>
                            </div>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Item Created 
                                </h3>
                                {
                                    (filter['created_from'] !== "" || filter['created_to'] !== "") && 
                                    <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("created_from,created_to")} >
                                        <strong>
                                            Clear
                                        </strong>
                                    </span>
                                }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <div>
                                            <label>
                                                From 
                                            </label>
                                        </div>
                                        <input type={"date"} className="form-control" value={filter.created_from} onChange={(e) => setFilterValues("created_from", e.target.value) } />
                                    </li>
                                    <li>
                                        <div>
                                            <label>
                                                To 
                                            </label>
                                        </div>
                                        <input type={"date"} className="form-control" value={filter.created_to} onChange={(e) => setFilterValues("created_to", e.target.value) }  />
                                    </li>
                                </ul>
                            </div>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Item Modified 
                                </h3>
                                {
                                    (filter['updated_from'] !== "" || filter['updated_to'] !== "") && 
                                    <span className='pull-right cursor-pointer ' style={{color:"red"}}  title='Clear' onClick={() => clearFilters("updated_from,updated_to")} >
                                        <strong>
                                            Clear
                                        </strong>
                                    </span>
                                }
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    <li>
                                        <div>
                                            <label>
                                                From 
                                            </label>
                                        </div>
                                        <input type={"date"} className="form-control" value={filter.updated_from} onChange={(e) => setFilterValues("updated_from", e.target.value) }  />
                                    </li>
                                    <li>
                                        <div>
                                            <label>
                                                To 
                                            </label>
                                        </div>
                                        <input type={"date"} className="form-control" value={filter.updated_to} onChange={(e) => setFilterValues("updated_to", e.target.value) }  />
                                    </li>
                                </ul>
                            </div>
                        {
                            props.type !== "package" &&
                            <>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Course Type
                                    </h3>
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                            <div>
                                                <label>
                                                    Course 
                                                </label>
                                            </div>
                                            {
                                                filter.course_type === "Course" ?
                                                <div onClick={() => setFilterValues("course_type", "") } className="cursor-pointer">
                                                    <FeatherIcon icon="check-circle" />
                                                </div>
                                                :
                                                <div onClick={() => setFilterValues("course_type", "Course") } className="cursor-pointer">
                                                    <FeatherIcon icon="circle" />
                                                </div>
                                            }
                                        </li>
                                        <li>
                                            <div>
                                                <label>
                                                Package 
                                                </label>
                                            </div>
                                            {
                                                filter.course_type === "Package" ?
                                                <div onClick={() => setFilterValues("course_type", "") } className="cursor-pointer">
                                                    <FeatherIcon icon="check-circle" />
                                                </div>
                                                :
                                                <div onClick={() => setFilterValues("course_type", "Package") } className="cursor-pointer">
                                                    <FeatherIcon icon="circle" />
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                </div>

                            </>
                        }

                        {
                            props.type === "package" &&
                            <>
                            
                            <div className='box-header'>
                                    <h3 className='box-title'>
                                        Added To Package?
                                    </h3>
                                </div>
                                <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                    <ul className='filtersBody'>
                                        <li>
                                            <div>
                                                <label>
                                                    Yes 
                                                </label>
                                            </div>
                                            {
                                                filter.type === "Yes" ?
                                                <div onClick={() => setFilterValues("type", "") } className="cursor-pointer">
                                                    <FeatherIcon icon="check-circle" />
                                                </div>
                                                :
                                                <div onClick={() => setFilterValues("type", "Yes") } className="cursor-pointer">
                                                    <FeatherIcon icon="circle" />
                                                </div>
                                            }
                                        </li>
                                        <li>
                                            <div>
                                                <label>
                                                    No 
                                                </label>
                                            </div>
                                            {
                                                filter.type === "No" ?
                                                <div onClick={() => setFilterValues("type", "") } className="cursor-pointer">
                                                    <FeatherIcon icon="check-circle" />
                                                </div>
                                                :
                                                <div onClick={() => setFilterValues("type", "No") } className="cursor-pointer">
                                                    <FeatherIcon icon="circle" />
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                </>
                        }


                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Course Status
                                </h3>
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                <ul className='filtersBody'>
                                    
                                    <li>
                                        <div>
                                            <label>
                                            Published 
                                            </label>
                                        </div>
                                        {
                                            filter.course_status === 1 ?
                                            <div onClick={() => setFilterValues("course_status", "") } className="cursor-pointer">
                                                <FeatherIcon icon="check-circle" />
                                            </div>
                                            :
                                            <div onClick={() => setFilterValues("course_status", 1) } className="cursor-pointer">
                                                <FeatherIcon icon="circle" />
                                            </div>
                                        }
                                    </li>
                                    
                                    <li>
                                        <div>
                                            <label>
                                            Un-Published 
                                            </label>
                                        </div>
                                        {
                                            filter.course_status === 0 ?
                                            <div onClick={() => setFilterValues("course_status", "") } className="cursor-pointer" >
                                                <FeatherIcon icon="check-circle" />
                                            </div>
                                            :
                                            <div onClick={() => setFilterValues("course_status", 0) } className="cursor-pointer">
                                                <FeatherIcon icon="circle" />
                                            </div>
                                        }
                                    </li>

                                </ul>
                            </div>

                            
                            <div className='box-header' style={{display:"none"}}>
                                <h3 className='box-title'>
                                    Course Paid/Free
                                </h3>
                            </div>
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1", display:"none"}}>
                                <ul className='filtersBody'>
                                    
                                    <li onClick={() => setFilterValues("free", (filter.free === 0) ? "" : 0) } className="cursor-pointer">
                                        <div>
                                            <label>
                                            Paid 
                                            </label>
                                        </div>
                                        {
                                            filter.free === 0 ?
                                            <div>
                                                <FeatherIcon icon="check-circle" />
                                            </div>
                                            :
                                            <div>
                                                <FeatherIcon icon="circle" />
                                            </div>
                                        }
                                    </li>
                                    
                                    <li onClick={() => setFilterValues("free", (filter.free === 1) ? "" : 1) } className="cursor-pointer">
                                        <div>
                                            <label>
                                            Free
                                            </label>
                                        </div>
                                        {
                                            filter.free === 1 ?
                                            <div >
                                                <FeatherIcon icon="check-circle" />
                                            </div>
                                            :
                                            <div>
                                                <FeatherIcon icon="circle" />
                                            </div>
                                        }
                                    </li>

                                </ul>
                            </div>

                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Select Category
                                </h3>
                            </div>
                            {
                                categoryLoading &&
                                <div className='box-header'>
                                    <Loading />
                                </div>
                            }
                            <div className='box-body' style={{borderBottom:"5px solid #F1F1F1"}}>
                                
                                <ul className='filtersBody'>
                                    {
                                        <>
                                        {
                                            categoryList.length > 0 &&
                                            categoryList.map((item, index) => (
                                                <li key={"category"+index} onClick={() => selectCategoryType(item.categoryid) }>
                                                    <label style={{width:"100%"}} className="cursor-pointer">
                                                        {item.categoryName}
                                                        <span className='pull-right'>
                                                            
                                                            {
                                                                filter.categories.indexOf(item.categoryid) !== -1 ?
                                                                <div>
                                                                    <FeatherIcon icon="check-square" />
                                                                </div>
                                                                :
                                                                <div className="cursor-pointer">
                                                                    <FeatherIcon icon="square" />
                                                                </div>
                                                            }

                                                            {/* <input type={"checkbox"} value={item.categoryid} onClick={(e) => selectCategoryType(item.categoryid)} /> */}
                                                        </span>
                                                    </label>
                                                </li>
                                                
                                                ))
                                            }
                                        </>
                                    }

                                </ul>
                            </div>


                        </div>
                    </div>
                    <div className='col-md-9 assetsbars'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    My Courses ({courses.length})
                                </h3>
                                <span className='pull-right'>
                                    <div className={ (cardStyle === "Grid") ? 'btn btn-primary' : "btn"} onClick={() => setCardStyle("Grid")}>
                                        <i className='fa fa-square' ></i>
                                    </div>
                                    <div className={ (cardStyle === "List") ? 'btn btn-primary' : "btn"} onClick={() => setCardStyle("List")}>
                                        <i className='fa fa-list' ></i>
                                    </div>
                                    {
                                        props.type === "package" &&
                                        <>
                                            {
                                                filter.type === "Yes" ?
                                                <div className={ 'btn btn-success'} onClick={() => setFilterValues("type", "No")}>
                                                    <i className='fa fa-plus' ></i> Add More
                                                </div>
                                                :
                                                <div className={ 'btn btn-warning'} onClick={() => setFilterValues("type", "Yes")}>
                                                    <i className='fa fa-times' ></i> Close
                                                </div>
                                            }
                                        </>
                                    }

                                </span>
                            </div>
                            <div className='box-body' style={{background:"#F6F6F9"}}>
                                <div className='col-md-12'>
                                    <form action='' method='POST' onSubmit={(e) => searchProduct(e)}  >
                                        <div className="input-group">
                                            <input type={"search"} placeholder={"Search By Course Name, Instructor Name, Course Sub text etc..."} className={"form-control courseSearchField"} value={filter.searchkey} 
                                            onChange={(e) => {
                                                var temp = filter
                                                temp["searchkey"] = e.target.value
                                                setFilter(temp)
                                                setRefresh(refresh === "No" ? "Yes" : "No")
                                            }} />
                                            <span className="input-group-addon cursor-pointer no-padding btn-primary"  >
                                                <button className='btn-primary' style={{padding:"6px 15px", margin:0, border:0, backgroundColor:"transparent"}}>
                                                    <i className='fa fa-search' ></i>
                                                </button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {
                                loading ? 
                                <div className='box no-border' style={{background:"#F6F6F9"}}>
                                    <br /><br /><br /><br /><br /><br />
                                        <div className='box-body'>
                                                <Loading />
                                        </div>
                                    <br /><br /><br /><br /><br /><br />
                                </div>
                                :
                                <>
                                {
                                    courses.length > 0 ?
                                    <div className='box-body courseCardsContainer' style={{background:"#F6F6F9"}}>
                                        {
                                            courses.map((item, index) => {
                                                var img = (item.thumbnail === "") || (item.thumbnail === null) ? noImage : assets_url+item.thumbnail
                                                var coursedata = (item.coursedata !== "") && (item.coursedata !== null) ? JSON.parse(item.coursedata) : ""
                                                
                                                moment.locale('en');
                                                var cd = item.created_at;
                                                var ud = item.updated_at;
                                                var cls = (cardStyle === "List") ?  'courseCard fullCourseCard' : 'courseCard'
                                                var btnClass = (packageContent.indexOf(item.otherid) !== -1) ? 'btn btn-success manageBtn' : 'btn btn-primary manageBtn'
                                                var btntext = ""
                                                var btnicon = ""
                                                var show = true
                                                var permi = false

                                                if(props.type === "allcourse"){
                                                    btntext = "Manage"
                                                    btnicon = "fa fa-angle-right"
                                                    permi = pagePermissions(props.pagepermissions, "course"+item.otherid, "show_in_sidebar")
                                                }else{
                                                    if((packageContent.indexOf(item.otherid) !== -1)){
                                                        btntext = "Added"
                                                        btnicon = "fa fa-check"
                                                        if(filter.type === "No"){ show = false }
                                                        permi = true
                                                    }else{
                                                        btntext = "Add"
                                                        btnicon = "fa fa-plus"
                                                        if(filter.type === "Yes"){ show = false }
                                                        permi = true
                                                    }
                                                }

                                                


                                                return(
                                                    show && permi &&
                                                    <div className={ cls } key={"course"+index} onClick={(e) => goToCourse(item.otherid, e)} >
                                                        <div className='courseCardImage'>
                                                            <img src={img} title={item.name} alt={item.name} loading="lazy" />
                                                            {
                                                                // props.type !== "package" &&
                                                                <div className='overlayBox'>
                                                                    {/* <div className='usersList'>
                                                                        <ul>
                                                                            <li className={'user1'}>
                                                                                <img src='https://edugyani.in/dist/img/user2-160x160.jpg' alt='User 1' />
                                                                            </li>
                                                                            <li className={'user2'}>
                                                                                <img src='https://edugyani.in/dist/img/user4-128x128.jpg' alt='User 2' />
                                                                            </li>
                                                                            <li className={'user3'}>
                                                                                <img src='https://edugyani.in/dist/img/user2-160x160.jpg' alt='User 3' />
                                                                            </li>
                                                                            <li className={'user4'}>+56</li>
                                                                        </ul>
                                                                    </div> */}
                                                                    <br />
                                                                    {
                                                                        pagePermissions(props.pagepermissions, "course"+item.otherid, "show_course_card_details") &&
                                                                        <>
                                                                            <p className='courseContains'>
                                                                                Active Students: {item.astu}
                                                                            </p>
                                                                            <p className='courseContains'>
                                                                                Inactive Students: {item.inastu}
                                                                            </p>
                                                                        </>
                                                                    }
                                                                    {
                                                                        coursedata !== "" &&
                                                                        <>
                                                                            <p className='courseContains'>
                                                                                {coursedata.contain}
                                                                            </p>
                                                                            <p className='courseContains'>
                                                                                {coursedata.language}
                                                                            </p>
                                                                        </>
                                                                    }
                                                                    <p className='courseContains'>
                                                                        Created On: {moment(cd).format("D-M-Y HH:mm:ss")}
                                                                    </p>
                                                                    <p className='courseContains'>
                                                                        Last Modified: {moment(ud).format("D-M-Y HH:mm:ss")}
                                                                    </p>
                                                                </div>
                                                            }
                                                            <div className={ (item.status === 1 ) ? 'published' : "unpublished"}>{item.coursetype}</div>
                                                        </div>
                                                        <div className='cardBottomBody'>
                                                            <p className='CourseCardName'>
                                                                {item.name}
                                                            </p>
                                                            <p className='courseCardSubtext'>
                                                                {(coursedata !== "") ? coursedata.subheading : 'Lorem ipsum represents a long-held tradition for designers, typographers and the like.'}
                                                            </p>
                                                            <div className='cardFooterArea' style={{paddingBottom:"15px"}}>
                                                                <p className=''>
                                                                    <strong>
                                                                        {(coursedata !== "") ? coursedata.instructor : "Not Available"}
                                                                    </strong>
                                                                    <span className='pull-right' >
                                                                        <button className={btnClass}>
                                                                            <span className='manageBtnSpan'>
                                                                               { btntext }  &nbsp;
                                                                            </span> 
                                                                            <i className={ btnicon }></i>
                                                                        </button>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    )
                                                })
                                            }
                                    </div>
                                    :
                                    
                                    <div className='no-files'>
                                        <img src={process.env.PUBLIC_URL+'/assets/assets/images/no-courses.png'} style={{width:"300px", height:"300px"}} alt="No Courses Found. Please try another filter." />
                                        <p className='no-files-heading'>
                                            <strong>Oops!</strong>
                                        </p>
                                        <p className='no-files-msg'>
                                            No Courses Found. Please try another filter.
                                        </p>
                                    </div>
                                    }
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>

          </>
        )
      }

  return (
    <>
        <FiltercoursePage />
    </>
  )
}
