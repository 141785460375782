import React from 'react'
import { Link } from 'react-router-dom'

export default function Notfound() {
  return (
    <div className='col-md-12'>
      <div className='box no-border'>
        <div className='box-body' style={{textAlign:"center"}}>
            <Link to={"/admin/dashboard"} >
              <img src={process.env.PUBLIC_URL+'/assets/assets/images/404.jpg'} style={{width: "600px", margin:"100px auto"}} />
            </Link>
        </div>
      </div>
    </div>
  )
}
