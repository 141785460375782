import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound'; 
import { CSVLink } from 'react-csv';
const cookies = new Cookies();


export default function Enrolled(props) {
    
    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
    const params = useParams()
    const courseData = (props.course.coursedata !== "" && props.course.coursedata !== null) ? JSON.parse(props.course.coursedata) : ""
    
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal, assets_url} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [segment, setSegment] = useState(0)
    const [enrollForm, setEnrollForm] = useState(false)
    const [search, setSearch] = useState("")

    const [userid, setUserid] = useState("")
    const [expireDays, setExpireDays] = useState("")
    const [exactdate, setExactdate] = useState("")
    const [hours, setHours] = useState("")
    const [cb, setCB] = useState("")
    const [cbexp, setCbexp] = useState("")
    const [remark, setRemark] = useState("")
    const [notify, setNotify] = useState("")
    const [variations, setVariations] = useState([])
    const [selectedPlan, setSelectedPlan] = useState("")
    const [csvFile, setCsvFile] = useState("")
    const [csvFields, setCsvFields] = useState([])
    const [csvFileShow, setCsvFileShow] = useState(false)
    const [totalArray, setTotalArray] = useState(null)
    
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    const [minStudents, setMinStudents] = useState(1)
    const [maxStudents, setMaxStudents] = useState(10000)
    const [maxStudentsFix, setMaxStudentsFix] = useState(10000)
    
    const clearForm = () => {
        setExpireDays("")
        setExactdate("")
        setHours("")
        setCB("")
        setCbexp("")
        setRemark("")
        setNotify("")
    }

    const [filter, setFilter] = useState({
        start: "",
        end: "",
        name: "",
        active: "",
        type: "",
    })

    const fetchList = (currentPage = 1) => {
        setLoading(true)
        fetch(apiurl+"api/enrolledstudents?page="+currentPage, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      filter: filter
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.users)
                    setVariations(response.variations)
                    setTotalArray(response.totalArray)
                    setLastPage(response.lastpage)
                    
                    
                    var max = 10000
                    setMaxStudents((max < response.totalArray.current) ? max : response.totalArray.current)
                    setMaxStudentsFix((max < response.totalArray.current) ? max : response.totalArray.current)
                    
                    // setTimeout(function(){
                    //     window.$('#fetchtable').dataTable()
                    // }, 500)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
    }
        
    const [userList, setUserList] = useState([])
    const [transferLoading, setTransferLoading] = useState(false)
    const getStudentsList = (x) => {
        setTransferLoading(true)
        if(x !== "" && x !== " "){
            fetch(apiurl + "api/searchStudent", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        name: x,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    setUserList(response.students)
                    setTransferLoading(false)
                })
            }else{
                setUserList([])
                setTransferLoading(false)
            }
    }
    
    
    const [bulkResp, setBulkResponse] = useState({
        "success": [], "errors" : [], "failed" : []
    })
    

    const [csvLoading, setCsvLoading] = useState(false)
    const [csvHeaders, setCsvHeaders] = useState(false)
    const [csvIndex, setCsvIndex] = useState("0/0 Enrolling...")

    const startBulkEnrolling = (list, index, headers) => {
        
        if(list[index] !== undefined){
            setCsvIndex(index + "/" + (list.length) + " Enrolling...")
            
            const formData = new FormData();
            formData.append('staff', JSON.stringify(AdminBasics()));
            formData.append('accecible', 1);
            formData.append('page_url', window.location.href);
            formData.append('courseid', params.id);
            formData.append('userid', userid);
            formData.append('expireDays', expireDays);
            formData.append('exactdate', exactdate);
            formData.append('hours', hours);
            formData.append('cb', cb);
            formData.append('cbexp', cbexp);
            formData.append('remark', remark);
            formData.append('selectedPlan', selectedPlan);
            formData.append('notify', notify);
            formData.append('data', JSON.stringify(list[index]));
            fetch(apiurl+"api/bulkEnrollingNew", {
                "method": "POST",
                "headers": {
                    // "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": formData
                })
                .then(response => response.json())
                .then(response => {
                    
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            var success = bulkResp.success
                            var errors = bulkResp.errors
                            if(response.data.success.length > 0){ success = success.concat(response.data.success); }
                            if(response.data.errors.length > 0){ errors = errors.concat(response.data.errors); }
                            var temp = bulkResp
                            temp['success'] = success
                            temp['errors'] = errors
                            setBulkResponse(temp)

                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(ind, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                    console.log(i)
                                });
                            }else{
                                var errors = bulkResp.errors
                                var tempArr = []
                                
                                for(var i = 0;i<headers.length;i++){
                                    if(headers[i] !== "Message"){
                                        tempArr.push(list[index][headers[i]])
                                    }else{
                                        tempArr.push("Failed to enroll")
                                    }
                                }
                                errors = errors.concat([tempArr]); 
                                var temp = bulkResp
                                temp['errors'] = errors
                                setBulkResponse(temp)
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        
                    setTimeout(function(){
                        setRefresh(refresh === "No" ? "Yes" : "No")
                        startBulkEnrolling(list, index + 1, headers)
                    }, 500)
                })
                .catch(err => {
                    
                });

        }else{
            setCsvIndex(null)
            successModal("success", "Enrolled Successfull")
        }
    }

    const getCsvDataNew = (e) => {
        e.preventDefault()
        formLoading(e.target)
        if(csvFile !== ""){
            if(!csvLoading){
                setCsvLoading(true)
                const formData = new FormData();
                formData.append('staff', JSON.stringify(AdminBasics()));
                formData.append('accecible', 1);
                formData.append('page_url', window.location.href);
                formData.append('csvFile', csvFile);
                
                fetch(apiurl+"api/getCsvDataNew", {
                    "method": "POST",
                    "headers": {
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": formData
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            window.$("#file").val("")
                            setCsvHeaders(response.headers)
                            
                            var success = bulkResp.success
                            var errors = bulkResp.errors
                            success[0] = response.headers;
                            errors[0] = response.headers;
                            errors[0].push("Message")
                            var temp = bulkResp
                            temp['success'] = success
                            temp['errors'] = errors
                            setBulkResponse(temp)
                            setRefresh(refresh === "No" ? "Yes" : "No")

                            startBulkEnrolling(response.data, 0, response.headers)
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                    console.log(i)
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                    })
                    .catch(err => {
                        // console.log(err);
                    });
            }
        }else{
            if(!csvLoading){
                setCsvLoading(true)
                let tempData = [{"Name" : userid.name, "Email" : userid.email, "User_id" : userid.userid}]
                var success = bulkResp.success
                var errors = bulkResp.errors
                success.push(["Name", "Email", "User_id"])
                errors.push(["Name", "Email", "User_id", "Message"])
                
                var temp = bulkResp
                temp['success'] = success
                temp['errors'] = errors
                setBulkResponse(temp)
                startBulkEnrolling(tempData, 0, ["Name", "Email", "User_id"])
                setRefresh(refresh === "No" ? "Yes" : "No")
            }
        }
    }

    
    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // fetchList(1)
    }

    const filterFeedbacks = (e) => {
        e.preventDefault();
        fetchList(1)
    }
    
    
    const [downloading, setDownloading] = useState(false)
    const [downloadingPopup, setDownloadingPopup] = useState(false)
    const downloadRequestFetch = () => {

        const min = parseInt(minStudents)
        const max = parseInt(maxStudents)
        const diff = max - (min-1);
        if(max < min){
            errorModal("Error", '"FROM" Cannot be greater than "TO"')
        }else if(diff > maxStudentsFix){
            errorModal("Error", "Total Users can't be more than 10000")
        }else{
            setDownloading(true)
            var  temp = filter
            
            setDownloadingPopup(false)
            fetch(apiurl+"api/user/getEnrolledForDownload", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        courseid: params.id,
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        filter: temp,
                        min: min,
                        max: max
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        downloadRequest(response.students)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => { });
        }

        
    }
    const downloadRequest = (students) => {
        setDownloading(true)
        const th = ["Enrolled On", "Student ID", "Student Name", "Student Email", "Student Contact", "Enrollment Count", "Expiry Date"]
        var segmentTemp = students
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = students[j]
            for(var i = 0;i<temp.length;i++){
                moment.locale('en');
                var ct = temp[i]['updated_at'];
                var exp = (temp[i]['expiredate'] !== null) ? new Date(temp[i]['expiredate'] * 1000) : null
                td.push(
                    [
                        temp[i]['new_created_at'], temp[i]['uid'], temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['total'],
                        (temp[i]['expiredate'] !== null) ?  exp.toLocaleString("en-IN") : "Lifetime"
                    ]
                )
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Enrollment List of ("+td.length+") Entries of "+courseData['name']+"."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    const [marked, setMarked] = useState([])
    const markSample = () => {
        var mark = []
        window.$(".checkbox").each(function(){
            if($(this).prop("checked")){
                mark.push($(this).val())
            }
        });
        setMarked(mark)
    }

      var a = 0
      useEffect(() => {
        if(a === 0){
            fetchList(1)
            a++
        }
      }, [])

  return (
    <div className='col-md-12 no-left-padding no-right-padding'>
        
    {
        downloadingPopup &&
        <div className='dialog' id='downloadPopup'>
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Export Users: <small style={{color:"red"}} >current filters' values will be used</small>
                        </h3>
                        <span className='pull-right cursor-pointer' onClick={() => setDownloadingPopup(false)} >
                            <i className='fa fa-times'></i>
                        </span>
                    </div>
                    <div className='box-body'>
                        <label>From</label>
                        <input type='number' className='form-control' placeholder='e.g 1' value={minStudents} onChange={(e) => setMinStudents(e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>To</label>
                        <input type='number' className='form-control' placeholder='e.g 100' value={maxStudents} onChange={(e) => setMaxStudents(e.target.value)} />
                    </div>
                    <p style={{color:"red", paddingLeft:10}}>
                        <strong>
                            Total Users can't be more than {maxStudentsFix}
                        </strong>
                    </p>
                    <div className='box-footer'>
                        <button className='btn btn-primary btn-flat btn-block' onClick={() => downloadRequestFetch()} >Download Students</button>
                    </div>
                </div>
            </div>
        </div>
  }
        {
            <>
            <br />

            {
                csvLoading &&
                <>
                    <div className='col-md-12 no-padding'>
                        <div className='col-md-4'>
                            <div className={"alert alert-" + (csvIndex !== null ? "warning" : "success") + " alert-dismissible"}>
                                <h4><i className="fa fa fa-check"></i> {csvIndex !== null ? csvIndex : "All Enrollment Processed"} </h4>
                                1 Enrollment may take upto 1 Minute or less
                                <br />
                                {
                                    (csvIndex !== null) ?
                                    <span style={{visibility:'hidden'}}>s</span>
                                    :
                                    <a href=''>
                                        <strong>PLEASE REFRESH PAGE</strong>
                                    </a>
                                }   
                            </div>
                        </div> 
                        <div className='col-md-4'>
                            <div className="alert alert-danger alert-dismissible">
                                <h4><i className="icon fa fa-warning"></i> Failed Data! </h4>
                                {(bulkResp.errors.length - 1 < 0) ? 0 : bulkResp.errors.length - 1} Students Failed to Uploaded  <br />
                                {
                                    bulkResp.errors.length > 1 ?
                                    <strong>
                                        &nbsp;-&nbsp;
                                        <CSVLink data={bulkResp.errors} filename={"Failed Enrollments.csv"} >Download List</CSVLink>
                                    </strong>
                                    :
                                    <span style={{visibility:'hidden'}}>s</span>
                                }
                            </div>
                        </div>   
                        <div className='col-md-4'>
                            <div className="alert alert-success alert-dismissible">
                                <h4><i className="icon fa fa-warning"></i> Success Data!</h4>
                                {(bulkResp.success.length - 1 < 0) ? 0 : bulkResp.success.length - 1} Students Uploaded Successfully  <br />
                                    {
                                        bulkResp.success.length > 1 ?
                                        <strong>
                                            &nbsp;-&nbsp;
                                            <CSVLink data={bulkResp.success} filename={"Success Enrollments.csv"} >Download List</CSVLink>
                                        </strong>    
                                        :
                                        <span style={{visibility:'hidden'}}>s</span>
                                    }
                            </div>
                        </div> 

                    </div>    
                </>
            }
            
            
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filters
                        </h3>
                        {
                            filter.start === "" && filter.end === "" && 
                            <>
                                <br />
                                <small style={{color:"red"}}>
                                    You are currently viewing data of last 7 days.
                                </small>
                            </>
                        }
                    </div>
                    
                    <form action='' method='POST' onSubmit={(e) => filterFeedbacks(e)} >
                        <div className='box-body'>
                            <label>Enrolled Date (Min)</label>
                            <input type={"date"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                        </div>   
                        <div className='box-body'>
                            <label>Enrolled Date (Max)</label>
                            <input type={"date"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                        </div>   
                        <div className='box-body'>
                            <label>Name, Email, Contact</label>
                            <input type={"text"} className={"form-control"} value={filter.name} onChange={(e) => changeValue(e.target.value, "name")} placeholder={"e.g Shivam..."} />
                        </div> 
                        <div className='box-body'>
                            <label>Course Expired?</label>
                            <select className='form-control' defaultValue={filter.active} onChange={(e) => changeValue(e.target.value, "active")} >
                                <option value={""} >Any</option>
                                <option value={"No"} >Not Expired</option>
                                <option value={"Yes"} >Expired</option>
                            </select>
                        </div>  
                        {
                            props.course.coursetype === "Course" &&
                                <div className='box-body'>
                                    <label>Enrolled As?</label>
                                    <select className='form-control' defaultValue={filter.type} onChange={(e) => changeValue(e.target.value, "type")} >
                                        <option value={""} >Any</option>
                                        <option value={"Course"} >Course</option>
                                        <option value={"Package"} >Package</option>
                                    </select>
                                </div>   
                        }
                        <div className='box-footer'>
                            <button className='btn btn-flat btn-primary btn-block'>
                                <i className='fa fa-search'></i> &nbsp;
                                Search
                            </button>    
                        </div>     
                    </form>
                    
                </div>
            </div>
            <div className='col-md-8'>
                {
                    enrollForm &&
                    <div className='row'>
                    <div className='col-md-12'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Enroll New Student
                                </h3>
                                <button className='btn btn-primary btn-flat pull-right' onClick={() => setEnrollForm(false)} >
                                    <i className='fa fa-times'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <div className='input-group'>
                                    <input type={"text"} placeholder={"e.g Shivam"} className="form-control" value={search} onChange={(e) => {
                                        setSearch(e.target.value)
                                        getStudentsList(e.target.value)
                                    }}  />
                                    <span className='input-group-addon'>
                                        <i className='fa fa-search'></i>
                                    </span>
                                </div>
                            </div>
                            {
                                userList.length > 0 &&
                                userList.map((item, index) => {
                                    var init = item.name[0]
                                    return(
                                        
                                        <div className='box-body cursor-pointer' key={"studentName"+index} style={{ border: (userid.userid === item.userid) ? "1px solid green" : "none"}} 
                                        onClick={() => setUserid(item)}
                                        >
                                            <div className="user-block" key={"name"+index} >
                                                
                                                {
                                                    item.image !== null ?
                                                    <img className="img-circle" src={assets_url + item.image} alt="User Image" />
                                                    :
                                                    <div className='userImage' style={{color:colors[init.toLowerCase()], backgroundColor: colors[init.toLowerCase()]+"29", width:"40px", height:"40px", fontSize:18, float:"left"}}>
                                                        {init.toUpperCase()}
                                                    </div>    
                                                }
                                                
                                                <span className="username">
                                                    <a >
                                                        {item.name}
                                                        <small className='pull-right'>
                                                            {item.userid}
                                                        </small>
                                                    </a>
                                                </span>
                                                <span className="description">{item.email}</span>
                                                <span className="description">{item.contact}</span>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                    <div className='col-md-6'>
                        {/* <form action='' method='POST' onSubmit={(e) => enrollStudent(e)} > */}
                        <form action='' method='POST' onSubmit={(e) => getCsvDataNew(e)} id='bulkSubmit' >
                        
                        
                            {
                                userList.length < 1 &&
                                <div className='box no-border'>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Bulk Enrollment
                                        </h3>
                                        
                                        <span className='pull-right label bg-green cursor-poiter' onClick={() => setCsvFileShow(csvFileShow ? false : true)} >
                                            Download Sample
                                        </span>
                                    </div>
                                    {
                                        csvFileShow &&
                                        <>
                                            <div className='box-body'>
                                                <label>
                                                    Select Basic Fields
                                                </label>
                                                <ul className='fieldSelect'>
                                                    {/* <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Name"} /> &nbsp; Name </label></li> */}
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Email"} /> &nbsp; Email</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Contact"} /> &nbsp; Contact</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"User_id"} /> &nbsp; User ID </label></li>
                                                    {/* <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Address"} /> &nbsp; Address </label></li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"State"} /> &nbsp; State </label></li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"City"} /> &nbsp; City</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Pincode"} /> &nbsp; Pincode</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Landmark"} /> &nbsp; Landmark </label></li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Address_Name"} /> &nbsp; Address Name</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Address_Email"} /> &nbsp; Address Email</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Address_Contact"} /> &nbsp; Address Contact</label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Address_Alternative_Contact"} /> &nbsp; Address Alternative Contact No</label> </li> */}
                                                    
                                                </ul>
                                                <br />
                                                <label>
                                                    Select Plan Fields
                                                </label>
                                                <ul className='fieldSelect'>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Expired_In_Days"} /> &nbsp; Expired In Days <span style={{color:"red"}}>&nbsp;(e.g 10)</span></label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Expired_on_Date"} /> &nbsp; Expired on Date (yyyy-mm-dd) <span style={{color:"red"}}>&nbsp;(e.g 2023-12-01)</span></label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Total_Hours"} /> &nbsp; Total Hours <span style={{color:"red"}}>&nbsp;(e.g 10)</span></label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Cashback_Points"} /> &nbsp; Cashback Points <span style={{color:"red"}}>&nbsp;(e.g 100)</span></label> </li>
                                                    <li><label> <input type='checkbox' name='check[]' className='checkbox' onChange={() => markSample()} value={"Cashback_Expiry_in_Days"} /> &nbsp; Cashback Expiry (in Days) <span style={{color:"red"}}>&nbsp;(e.g 10)</span></label> </li>
                                                </ul>
                                            </div>   
                                            <div className='box-body'>
                                                {
                                                    marked.length > 0 &&
                                                    <CSVLink data={[marked]} className='btn btn-block btn-primary btn-flat' filename={"Bulk_Enrollment_Sample.csv"} >Download Now</CSVLink>
                                                }
                                            </div>
                                        </>
                                    }
                                    <div className='box-body'>
                                        <label>
                                            Select .CSV File 
                                        </label>
                                        <input type='file' className='form-control' id='file' onChange={(e) => setCsvFile(e.target.files[0])} accept='.csv' />
                                    </div>
                                </div>
                            }
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Create Plan
                                    </h3>
                                </div>
                                <div className='box-body'>
                                    <label>
                                        Select Plan &nbsp;
                                        <span className='text-red'>(Leave Blank For Lifetime)</span>
                                    </label>
                                    <select className='form-control' value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                                        <option value={""} >Custome Plan</option>
                                        {
                                            variations.length > 0 &&
                                            variations.map((item, index) => {
                                                return(
                                                    <option value={item.id} key={"index" + index} >{item.plan_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                {
                                    selectedPlan === "" &&
                                    <>
                                        <div className='box-body'>
                                            <label>
                                                Expired in Days &nbsp;
                                                <span className='text-red'>(Leave Blank For Lifetime)</span>
                                            </label>
                                            <input type={"number"} name={"planname"} placeholder={"e.g 30"} className="form-control" value={expireDays} onChange={(e) => {
                                                    setExactdate("")
                                                    setExpireDays(e.target.value)
                                                }}  />
                                        </div>
                                        <div className='box-body'>
                                            <label>
                                                Exact Date
                                            </label>
                                            <input type={"date"} placeholder={"e.g 30"} className="form-control" value={exactdate} onChange={(e) => {
                                                    setExpireDays("")
                                                    setExactdate(e.target.value)
                                                }}  />
                                        </div>
                                        <div className='box-body'>
                                            <label>
                                                Total Hours &nbsp;
                                                <span className='text-red'>(Leave Blank For Lifetime)</span>
                                            </label>
                                            <input type={"number"} placeholder={"e.g 30"} className="form-control" value={hours} onChange={(e) => {
                                                    setHours(e.target.value)
                                                }}  />
                                        </div>
                                        <div className='box-body'>
                                            <label>
                                                Cashback &nbsp;
                                            </label>
                                            <input type={"number"}  placeholder={"e.g 30"} className="form-control" value={cb} onChange={(e) => {
                                                    setCB(e.target.value)
                                                }}  />
                                        </div>
                                        <div className='box-body'>
                                            <label>
                                                Cashback Expiry (in Days) &nbsp;
                                                <span className='text-red'>(Leave Blank For Lifetime)</span>
                                            </label>
                                            <input type={"number"} placeholder={"e.g 30"} className="form-control" value={cbexp} onChange={(e) => {
                                                    setCbexp(e.target.value)
                                                }}  />
                                        </div>
                                    </>
                                }
                                <div className='box-body'>
                                    <label>
                                        Enter Remarks
                                        <span className='text-red'>*</span>
                                    </label>
                                    <textarea value={remark} required className='form-control' onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} placeholder={"Enter Remark"} ></textarea>
                                </div>
                                <div className='box-body'>
                                    <label>
                                        Send Notification?
                                    </label>
                                    <select className='form-control' value={notify} onChange={(e) => setNotify(e.target.value)} >
                                        <option value={""}>No</option>
                                        <option value={"Yes"}>Yes</option>
                                    </select>
                                </div>
                                <div className='box-footer'>
                                    <input type={"submit"} className={"btn btn-primary btn-flat btn-block"} value={"Enroll Now"} />
                                </div>
                            </div>
                        </form>
                    </div>
                            
                    </div>
                }
                {
                    
                    loading ? 
                    <>
                        <br /><br /><br />
                        <Loading />
                        <br /><br /><br />
                    </>
                    :
                    !enrollForm &&
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Enrolled Students 
                            {
                                totalArray !== null &&
                                <strong>
                                    &nbsp;&nbsp;
                                    ({totalArray.current}/{totalArray.total})
                                </strong>
                            }                            
                        </h3>
                        {
                            
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => setEnrollForm(true)} >
                                <i className='fa fa-plus'></i> Enroll
                            </button>
                        }
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => setDownloadingPopup(true)} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                    <div className='box-body' style={{overflow:"auto"}}>
                        <table id="fetchtable" className="table file_manager_table">
                        <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Enrolled On</th>
                                    <th>Student ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Enrolled Count</th>
                                    <th>Expiry Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                        typeof list[segment] !== "undefined" &&
                                        list[segment].length > 0 &&
                                        list[segment].map((item, index) => {
                                        moment.locale('en');
                                        var ct = item.created_at;
                                        var exp = (item.expiredate !== null) ? new Date(item.expiredate * 1000) : null

                                        return(
                                            <tr key={"user"+index} >
                                                <td>{(index+1) * (segment+1)}</td>
                                                <td>
                                                    {/* {moment(ct).format("D-M-Y hh:mm:ss A")} */}
                                                    {item.new_created_at}
                                                </td>
                                                <td>
                                                    {item.uid} 
                                                </td>
                                                <td>
                                                    <Link to={'/admin/student-profile/'+item.uid} title={"View Profile"} >
                                                        {item.name}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <a title={'Mail on ' + item.email} onClick={() => window.location.href="mailto:"+item.email} >
                                                        {item.email}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a title={'Make a Call on ' + item.contact} onClick={() => window.location.href="tel:"+item.contact}>
                                                        {item.contact}
                                                    </a>
                                                </td>
                                                <td>
                                                    {
                                                        item.total
                                                    }
                                                </td>
                                                <td>
                                                    
                                                    {
                                                        (item.expiredate !== null) ?  exp.toLocaleString("en-IN") : "Lifetime"
                                                    }
                                                </td>
                                                {/* <td> */}
                                                    {
                                                        // item.expired !== null ?
                                                        // <span className={"badge bd-red"} style={{padding:"5px 10px", display:"inline"}} >In-Active</span>
                                                        // :
                                                        // <span className={"badge bg-green"} style={{padding:"5px 10px", display:"inline"}} >Active</span>
                                                    }
                                                {/* </td> */}
                                            </tr>
                                            
                                            )
                                        })
                                }
                            </tbody>
                            
                        </table>
                    </div>
                    <div className='box-footer'>
                        <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                            {
                                
                                <div style={{width:"200px", float:"right"}}>
                                    <label>Last Page: {lastPage}</label>
                                    <div className="input-group input-group-sm">
                                            <input type={"number"} className="form-control" placeholder='e.g 1' value={currentPage} onChange={(e) => setCurrentPage(e.target.value) } />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-primary btn-flat" onClick={() => fetchList(currentPage)}>Go!</button>
                                            </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
            </>
        }
    </div>
  )
}
