import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Loading from '../includes/Loading'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
const cookies = new Cookies();

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
export default function Transactionbooks() {

    const TransactionbooksPage = (props) => {
        const admin = cookies.get('admin')
        let getToken = cookies.get('token')
        const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()

        const query = useQuery();
        const [booksLoading, setBooksLoading] = useState(false)
        const [book, setBook] = useState(null)
        const [transactions, setTransactions] = useState([])
        const [tab, setTab] = useState("")
        const params = useParams()
        const id = params.id

        const getBook = () => {
            if(!booksLoading){
                setBooksLoading(true)
                fetch(apiurl+"api/getBook", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                            page_url: window.location.href,
                            id: id,
                            staff: AdminBasics(),
                            accecible: 1
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            setBook(response.book)
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        setBooksLoading(false)
                    })
                    .catch(err => {
                        // console.log(err);
                    });
            }
        }

        const flowSubmit = (e) => {
            e.preventDefault()
            formLoading(e.target)
            
            var formData = new FormData(e.target);
            formData.append('staff', JSON.stringify(AdminBasics()));
            formData.append('accecible', 1);
            formData.append('page_url', window.location.href);
            var url = (tab === "Add") ? "api/addFlow" : "api/minusFlow"
            fetch(apiurl + url, {
                "method": "POST",
                "headers": {
                    // "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": formData
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        getTransactions()
                        $(e.target).trigger('reset')
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    $(e.target).find(".loaderbody").remove()
                })
                .catch(err => { });
        }
        
        const closeTab = () => {
            setTab("")
            getBook()
        }

        const getTransactions = (e) => {
            if(e !== undefined){
                e.preventDefault()
            }
            if(!booksLoading){
                setBooksLoading(true)
                fetch(apiurl+"api/bookTransactions", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                            page_url: window.location.href,
                            id: id,
                            staff: AdminBasics(),
                            accecible: 1,
                            startDate:startDate,
                            endDate:endDate,
                            transactionType: transactionType
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            setTransactions(response.transactions)
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        setBooksLoading(false)
                    })
                    .catch(err => {
                        // console.log(err);
                    });
            }
        }
        
        const [searchParams, setSearchParams] = useSearchParams();

        const [startDate, setStartDate] = useState((searchParams.get("startdate") === null) ? "" : searchParams.get("startdate"))
        const [endDate, setEndDate] = useState((searchParams.get("enddate") === null) ? "" : searchParams.get("enddate"))
        const [transactionType, setTransactionType] = useState((searchParams.get("type") === null) ? "" : searchParams.get("type"))
        
        const downloadList = () => {
            const th = ["Transaction For", "Opening Stock", "Flow", "Inventory", "Added By", "Closing Stock"];
            var td = []
            for(var i = 0;i < transactions.length;i++){
                transactions[i]['body'] = (transactions[i]['body'] === null) ? "" : transactions[i]['body']
                td.push([
                    (transactions[i]['title'] + " " + transactions[i]['body'].replace(/<[^>]*>?/gm, '')), transactions[i]['summary']['oldvalue'], ((transactions[i]['flow'] === 1) ? 'Added' : "Removed"), 
                    transactions[i]['quantity'], transactions[i]['addedby']['name'], transactions[i]['summary']['oldvalue']
                ])
            }
            fetch(apiurl+"api/downloadLists", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        th: th,
                        td: td,
                        for: "Request to Download "+book.name+" Books Statement of ("+td.length+") Entries."
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => {
                });
        }
        

        useEffect(() => {
            getBook()
            getTransactions()
            // window.$("#select2").select2()
        }, [])


        return(
            <>
            {
                booksLoading &&
                <div className='col-md-12'>
                    <div className="box no-border">
                        <div className='box-header'>
                            Loading...
                        </div>
                    </div>
                </div>
            }
                <div className='col-md-4'>

                    {
                        tab === "Add" &&
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Add Quantity
                                </h3>
                                <span className='pull-right badge bg-red' onClick={() => closeTab()} >
                                    <i className=' fa fa-times'></i>
                                </span>
                            </div>
                            <form action='' method='POST' onSubmit={(e) => flowSubmit(e) } >
                                <input type='hidden' name='id' value={id} />
                                <input type='hidden' name='flow' value="1" />
                                <div className='box-body'>
                                    <label>Reason<span className='text-red'>*</span></label>
                                    <select name='title' className='form-control' required >
                                        <option value={""}>Select Reason</option>
                                        <option value={"New Units"}>New Units</option>
                                        <option value={"RTO"}>RTO-return</option>
                                        <option value={"Other"}>Other</option>
                                    </select>
                                </div>
                                <div className='box-body'>
                                    <label>Remarks <span className='text-red'>(If Any)</span></label>
                                    <textarea name='remarks' placeholder='e.g Other Reason' className='form-control'></textarea>
                                </div>
                                <div className='box-body'>
                                    <label>Quantity <span className='text-red'>*</span></label>
                                    <input name='quantity' placeholder='e.g 10' className='form-control'/>
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-success btn-flat btn-block'>
                                        Add Quantity
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        tab === "Minus" &&
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Remove Quantity
                                </h3>
                                <span className='pull-right badge bg-red' onClick={() => closeTab()} >
                                    <i className=' fa fa-times'></i>
                                </span>
                            </div>
                            <form action='' method='POST' onSubmit={(e) => flowSubmit(e) } >
                                <input type='hidden' name='id' value={id} />
                                <input type='hidden' name='flow' value="2" />
                                <div className='box-body'>
                                    <label>Reason<span className='text-red'>*</span></label>
                                    <select name='title' className='form-control' required >
                                        <option value={""}>Select Reason</option>
                                        <option value={"Office Pickup"}>Office Pickup</option>
                                        <option value={"Other"}>Other</option>
                                    </select>
                                </div>
                                <div className='box-body'>
                                    <label>Remarks <span className='text-red'>(If Any)</span></label>
                                    <textarea name='remarks' placeholder='e.g Other Reason' className='form-control'></textarea>
                                </div>
                                <div className='box-body'>
                                    <label>Quantity <span className='text-red'>*</span></label>
                                    <input name='quantity' placeholder='e.g 10' className='form-control'/>
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-danger btn-flat btn-block'>
                                        Minus Quantity
                                    </button>
                                </div>
                            </form>
                        </div>
                    }

                    {
                        tab === "" &&
                        book !== null &&
                        <div className="box box-widget widget-user-2">
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Book Information
                                </h3>
                                
                                <Link className='pull-right badge bg-red' to={'/admin/manage-books'} >
                                    <i className='fa fa-times'></i>
                                </Link>
                            </div>
                            <div className="no-padding">
                                {
                                    (book.image !== null && book.image !== "") &&
                                    <img src={assets_url + book.image} style={{width:"100%", objectFit:"contain"}} />
                                }
                                
                            </div>
                            <div className="box-header" style={{textAlign:"center"}} >
                                <h3 className='box-title'>
                                    {book.name}
                                </h3>
                            </div>
                            <div className="box-footer no-padding">
                                <ul className="nav nav-stacked">
                                    <li><a href="#">Initial Inventory <span className="pull-right label bg-blue">{book.initialbook}</span></a></li>
                                    <li><a href="#">Remaining Inventory <span className="pull-right label bg-green">{book.quantity}</span></a></li>
                                    <li><a href="#">Created At <span className="pull-right">{book.created_at}</span></a></li>
                                    <li><a href="#">Updated At <span className="pull-right">{book.updated_at}</span></a></li>
                                    <li><a href={'/admin/staff?id='+book.addby.id} target='_blank'>Added By <span className="pull-right">{book.addby.name}</span></a></li>
                                    <li><a href={'/admin/staff?id='+book.moddedby.id} target='_blank'>Last Updated By <span className="pull-right">{book.moddedby.name}</span></a></li>
                                </ul>
                            </div>
                            <div className='box-footer'>
                                <div className='col-md-6 no-left-padding'>
                                    <button className='btn btn-success btn-flat btn-block' onClick={() => setTab("Add")} ><i className='fa fa-plus'></i> Add Quantity</button>
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <button className='btn btn-danger btn-flat btn-block' onClick={() => setTab("Minus")}><i className='fa fa-minus'></i> Remove Quantity</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='col-md-8'>
                    
                    <div className='box no-border' style={{display:"none"}}>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <div className='box-title'>
                                    Filter Transactions
                                </div>
                                <a href='?' className='label bg-yellow pull-right cursor-pointer'>
                                    Clear Filters
                                </a>
                            </div>
                            
                            <form action='' method='GET' onSubmit={(e) => getTransactions(e)} >
                                <div className='box-body'>
                                    <div className='col-md-3 no-left-padding'>
                                        <label>Start Date</label>
                                        <input type='date' className='form-control'  name='startdate' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className='col-md-3'>
                                        <label>End Date</label>
                                        <input type='date' className='form-control'  name='enddate' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                    <div className='col-md-6 no-right-padding'>
                                        <label>Transaction Type</label>
                                        <select name='transaction_type' class='form-control'  data-placeholder="Select Transaction Type" value={transactionType} onChange={(e) => setTransactionType(e.target.value)} >
                                            <option value={""}>Select Transaction Type</option>
                                            <option value={"Dispatched"}>Dispatched</option>
                                            <option value={"StaffMinus"}>Staff(-)</option>
                                            <option value={"StaffPlus"}>Staff(+)</option>
                                            <option value={"Fresh Unit"}>Fresh Units</option>
                                        </select> 
                                    </div>
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block'>
                                        Filter Now
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Transaction History {transactionType !== "" ? "(" + transactionType + ")" : ""}
                            </h3>
                            <span className='label bg-green pull-right cursor-pointer' onClick={(e) => downloadList()} >
                                Download?
                            </span>
                        </div>
                        <div className='box-body'>
                            
                            <table id="bookstable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th style={{width:"80px"}} >S.No</th>
                                        <th>Reason</th>
                                        <th>Added By</th>
                                        <th>Inventory</th>
                                        <th>Opening Stock</th>
                                        <th>Closing Stock</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.length > 0 &&
                                        transactions.map((item, index) => {
                                            return(
                                                <tr key={"key" + index}>
                                                    <td style={{width:"80px"}} >{index+1}</td>
                                                    <td>
                                                        {
                                                            item.orderinfo !== "" ?
                                                            <>
                                                                <a href={'/admin/order-info/'+item.orderinfo.purchase_id+'/'+item.orderinfo.uid} target='_BLANK' >
                                                                    {item.title}<br />
                                                                    <small className='text-red' dangerouslySetInnerHTML={{__html: item.body}}></small>
                                                                </a>
                                                            </>
                                                            :
                                                            <>
                                                                {item.title}<br />
                                                                <small className='text-red' dangerouslySetInnerHTML={{__html: item.body}}></small>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.addedby.name}
                                                    </td>
                                                    <td>
                                                        <span className={(item.flow === 1) ? 'label bg-green' : 'label bg-red'}>
                                                            {item.quantity}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {item.summary.oldvalue}
                                                    </td>
                                                    <td>
                                                        {item.summary.newquantity}
                                                    </td>
                                                    <td>{item.created_date}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Sidebar page={TransactionbooksPage} permission="Books_Management"/>
    )
}
