import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const cookies = new Cookies();

export default function Faq() {
  const Faqpage = (props) => {

    
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    const [loading, setLoading] = useState(false)
    const [mydownload, setMydownloads] = useState([])
    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [searching, setSearching] = useState("")
    const [id, setId] = useState("")
    const [category, setCategory] = useState("")
    let getToken = cookies.get('token')
    
    const [faqList, setFaqList] = useState([])
    const [faqListLoading, setFaqListLoading] = useState(false)
    
    const [categoryName, setCategoryName] = useState("")
    const [faqzip, setFAQzip] = useState("")
    const [categoryId, setCategoryId] = useState("")

    const [errorsList, setErrorsList] = useState(null);
    const [categories, setCategories] = useState([])
    const [tab, setTab] = useState("tab0")
    const [cats, setCats] = useState([])
    const [courses, setCourses] = useState([])
    const [selectedCourses, setselectedCourses] = useState([])
    
    const [viewQuestion, setViewQuestion] = useState(null)
    const [search, setSearch] = useState("")

    const addFaq = (e) => {
        e.preventDefault();
        formLoading(e.target)
        
        fetch(apiurl+"api/addfaq", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                question: question,
                answer: answer,
                searching: searching,
                category: category,
                id: id,
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href
            })
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    setAnswer("")
                    setQuestion("")
                    setSearching("")
                    setId("")
                    setCategory("")
                    faqData()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const faqData = () => {
        setFaqListLoading(true)
        fetch(apiurl+"api/fetchfaq", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                from: "admin",
                search: search
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setFaqList(response.faqList)
                    setCategories(response.categories)
                    setCats(response.cats)
                    setCourses(response.courses)
                    setTimeout(function(){
                        window.$("#select2").unbind().select2()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setFaqListLoading(false)
            })
            .catch(err => {
                setFaqListLoading(false)
                // console.log(err);
            });
    }

    const edit = (item) => {
        setAnswer(item.answer)
        setQuestion(item.question)
        setSearching(item.searching)
        setCategory(item.category)
        setId(item.id)
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    const removeFaq = (id) => {
        fetch(apiurl+"api/removeFaq", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                id: id,
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    faqData()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setFaqListLoading(false)
            })
            .catch(err => {
                setFaqListLoading(false)
                // console.log(err);
            });
    }
    
    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeFaq(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }


    
    const addCategory = (e) => {
        e.preventDefault();
        formLoading(e.target)
        
        fetch(apiurl+"api/addfaqcategory", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                categoryname: categoryName,
                categoryid: categoryId,
                id: id,
                courses: window.$("#select2").val(),
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
            })
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    setCategoryId("")
                    setCategoryName("")
                    setCategories(categories)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const uploadzip = (e) => {
        e.preventDefault()
        formLoading(e.target)
        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('zip', faqzip);
        formData.append('category', category);

        
        fetch(apiurl+"api/uploadfaqzip", {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    faqData()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    
                    setErrorsList(typeof response.data.errorlist !== "undefined" ? response.data.errorlist : null)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    
    
    var a = 0
    useEffect(() => {
        if(a === 0) {
            faqData()
            a++
        }
    }, [])

    return(
        <>
        
            {
                errorsList !== null && errorsList.length > 0 &&
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-body'>
                            <div className="alert alert-danger alert-dismissible">
                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <h4><i className="icon fa fa-ban"></i> Alert!</h4>
                                <ul>
                                {
                                    errorsList.map((item, index) => (
                                    <li key={"index"+index}>{item}</li>
                                    ))
                                }
                                </ul>
                            </div>
                        </div>
                    </div> 
                </div>
            }

            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Add New FAQ Category
                        </h3>
                    </div>
                    <form action='' onSubmit={(e) => addCategory(e)} >
                        <div className='box-body'>
                            <label>Enter Category Name</label>
                            <input type={"name"} className="form-control" value={categoryName} placeholder="e.g Use Dashboard" onChange={(e) => setCategoryName(e.target.value)} required />
                        </div>
                        <div className='box-body'>
                            <label>Enter Courses (If Any)</label>
                            <select className='form-control select2' id='select2' value={selectedCourses} multiple data-placeholder='Select Courses Id Any' >
                                {
                                    courses.length > 0 &&
                                    courses.map((item, index) => {
                                        return(
                                            <option value={item.otherid} key={"index"+index} >{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        
                        <div className='box-footer'>
                            <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={"Add Category"} />
                        </div>
                    </form>
                </div>
                
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Upload Zip
                        </h3>
                        <a href={assets_url + 'assets/FAQ Sample.docx'} target='_blank' className='pull-right' >
                            <i className='fa fa-download'></i>
                        </a>
                    </div>

                    <form action='' onSubmit={(e) => uploadzip(e)} >
                        
                        <div className='box-body'>
                            <label>Category</label>
                            <select className='form-control' required defaultValue={category} onChange={(e) => setCategory(e.target.value)} >
                                <option value={""} >Select Category</option>
                                {
                                    categories.length > 0 &&
                                    categories.map((item, index) => {
                                        return(
                                            <option key={item.otherid} value={item.id} >{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                                
                        <div className='box-body'>
                            <label>Select Zip</label>
                            <input type={"file"} className="form-control" accept='.zip' placeholder="e.g Use Dashboard" onChange={(e) => setFAQzip(e.target.files[0])} required />
                        </div>
                        <div className='box-footer'>
                            <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={"Upload Zip"} />
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Add New FAQ
                        </h3>
                    </div>
                    <form action='' method='POST' onSubmit={(e) => addFaq(e)} >
                        <div className='box-body'>
                            <label>Question</label>
                            <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={question} onChange={ ( event, editor ) => { const data = editor.getData(); setQuestion(data) } } />
                        </div>
                        <div className='box-body'>
                            <label>Answer</label>
                            <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={answer} onChange={ ( event, editor ) => { const data = editor.getData(); setAnswer(data) } } />
                        </div>
                        <div className='box-body'>
                            <div className="col-md-6 no-left-padding">
                                <label>Serching Tags</label>
                                <input type={"text"} required className="form-control" placeholder='e.g Course, Home' value={searching} onChange={(e) => setSearching(e.target.value)} />
                            </div>
                            <div className="col-md-6 no-right-padding">
                                <label>Category</label>
                                <select className='form-control' required defaultValue={category} onChange={(e) => setCategory(e.target.value)} >
                                    <option value={""} >Select Category</option>
                                    {
                                        categories.length > 0 &&
                                        categories.map((item, index) => {
                                            return(
                                                <option key={"op"+index} value={item.id} >{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='box-footer'>
                            {
                                <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={id === "" ? "Add Question" : "Update Question"} />
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-md-12">
                <div className='box no-border'>
                    <div className='box-body'>
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" placeholder='e.g CA Foundation 9.0' onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-primary btn-flat" onClick={() => faqData()}>Search</button>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                        {
                            cats.length > 0 &&
                            cats.map((item, index) => {
                                return(
                                    <li key={"tab"+index} className={tab === "tab"+index ? 'active' : ''} onClick={() => setTab("tab"+index)}><a href={"#tab"+index}  data-toggle="tab" aria-expanded="false">{item.name}</a></li>
                                )
                            })
                        }
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active">
                            <br />
                            {
                                faqListLoading ? 
                                <div className='col-md-12'>
                                    <div className='box no-border'>
                                    <br /><br /><br /><br /><br /><br /><br /><br />
                                        <Loading />
                                    </div>
                                </div>    
                                :
                            <div className='col-md-12'>
                                
                                        {
                                            cats.length > 0 &&
                                            cats.map((item, index) => {
                                                return(
                                                    (tab === "tab"+index) &&
                                                    <>
                                                    {
                                                        item.faqs !== null && item.faqs.length > 0 &&
                                                        item.faqs.map((item2, index2) => {
                                                            
                                                            moment.locale('en');
                                                            var ct = item2.created_at;
                                                            var ut = item2.updated_at;
                                                            var staff = JSON.parse(item2.staff)
                                                            
                                                            var question = item2.question.replace(/(<img[^>]+>(?:<\/img>)?)/g, "[[IMG]]"); 
                                                            question = window.jQuery(question).text();
                                                            
                                                            return(
                                                                <div className='box no-border' key={"key"+index2}>
                                                                    <div className='box-body'>
                                                                        <div className="box-header with-border">
                                                                            <div className="user-block" style={{display:"flex", alignItems:"center"}}>
                                                                                <div style={{width:40, height:40, borderRadius:50, display:"flex", alignItems:"center", justifyContent:"center"}} className='bg-green img-circle'>
                                                                                    <strong>
                                                                                        {index2+1}
                                                                                    </strong>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="username" style={{marginLeft:10}}><a href="#">By {staff.name} <small>({staff.role})</small></a></span>
                                                                                    <span className="description" style={{marginLeft:10}}>Last Modified - {moment(ut).format("D-M-Y")}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="box-tools">
                                                                                <button type="button" className="btn btn-box-tool" data-toggle="tooltip" title="View Question"  onClick={() => setViewQuestion(item2)}>
                                                                                <i className="fa fa-eye"></i></button>
                                                                                &nbsp;
                                                                                <button type="button" className="btn btn-box-tool" data-toggle="tooltip" title="Edit Question"  onClick={() => edit(item2)}>
                                                                                <i className="fa fa-edit"></i></button>
                                                                                &nbsp;
                                                                                <button type="button" className="btn btn-box-tool" onClick={() => removeDialog(item2.id)}  ><i className="fa fa-times"></i></button>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            (viewQuestion === null || viewQuestion.id !== item2.id) ?
                                                                                <div className="box-body">
                                                                                    <p>{question}</p>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="box-body" ><strong>Question</strong></div>
                                                                                    <div className="box-body" dangerouslySetInnerHTML={{__html: item2.question}}></div>
                                                                                    <div className="box-body" ><strong>Answer</strong></div>
                                                                                    <div className="box-body" dangerouslySetInnerHTML={{__html: item2.answer}}></div>
                                                                                    <div className="box-body" ><strong>Searching Terms</strong></div>
                                                                                    <div className="box-body" dangerouslySetInnerHTML={{__html: item2.searching}}></div>
                                                                                </>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                )
                                            })
                                        }
                            </div>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
  }
  return (
    <>
      <Sidebar page={Faqpage} permission="Faq"/>
    </>
  )
}
