import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';

const cookies = new Cookies();

export default function Editbanner() {
    const params = useParams()
  const Banners = (props) => {
    const [pc_banner, setPc_banner] = useState("")
    const [mobile_banner, setMobile_banner] = useState("")
    const [banner_alt, setBanner_alt] = useState("")
    const [banner_title, setBanner_title] = useState("")
    const [banner_url, setBanner_url] = useState("")
    const [section, setSection] = useState("Top Banner")
    const [availablefor, setAvailablefor] = useState([])
    const [banner_list, setBanner_list] = useState([])
    const [loading, setLoading] = useState(false)
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const[refresh, setRefresh] = useState("No")
    var a = 0;
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()

    const get_banners_list = () => {
        setLoading(true)
        fetch(apiurl+"api/single_banner", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    id: params.id,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setBanner_list(response.banner)
                    setBanner_alt(response.banner.banner_alt)
                    setBanner_title(response.banner.banner_title)
                    setBanner_url(response.banner.banner_url)
                    setSection(response.banner.section)
                    setAvailablefor(JSON.parse(response.banner.availablefor))
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    setLoading(false)
                    setTimeout(function(){
                        if(window.$(".select2-selection").length > 0){
                          window.$("select.select2-hidden-accessible").select2('destroy');
                        }else{
                          window.$("#availablrfor").select2()
                        }
                    }, 500) 
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setLoading(false)
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const validateBanner = (e) => {
        e.preventDefault();

        formLoading(e.target)
        const formData = new FormData();
        formData.append('pc_banner', pc_banner);
        formData.append('mobile_banner', mobile_banner);
        formData.append('banner_alt', banner_alt);
        formData.append('banner_title', banner_title);
        formData.append('banner_url', banner_url);
        formData.append('section', section);
        formData.append('availablefor', window.$("#availablrfor").val());
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('id', params.id);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/update_banner", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : pagePermissions(props.pagepermissions, "Banner", "remove_banner") ? "Bearer "+getToken : ""
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").fadeOut()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    $(e.target).trigger("reset");
                    get_banners_list()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    useEffect(() => {
        if(a == 0){
            get_banners_list()
            a++
        }
     }, [])



    return(
    pagePermissions(props.pagepermissions, "Banner", "edit_banner") &&
    <>
    
      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
                Edit Banners
            </h3>
            <p className='text-red'>
                <strong>
                    File Size: Upto 1 MB<br />
                    Accepted Formats: .jpg, .jpeg, .png, .gif, .svg<br />
                </strong>
            </p>
          </div>
          {
            loading ? 
            <>
                <br /><br />
                <Loading />
                <br /><br />
            </>
            :
            <form action='#' method='POST' onSubmit={validateBanner}>
                <div className='box-body'>
                    <label>PC Banner <span className='text-red'>1600*500</span></label>
                    {
                        banner_list.pc_banner !== null && banner_list.pc_banner !== "" &&
                        <a href={base_config.assets_url+banner_list.pc_banner} className='pull-right' target={"_BLANK"}>
                            <i className='fa fa-eye'></i>
                        </a>
                    }
                    <input type={"file"} name="pc_banner" className='form-control' onChange={e => setPc_banner(e.target.files[0])} accept=".png, .jpg, .jpeg, .svg, .gif" />
                </div>
                <div className='box-body'>
                    <label>Mobile Banner <span className='text-red'>400*600*</span></label>
                    {
                        banner_list.mobile_banner !== null && banner_list.mobile_banner !== "" &&
                        <a href={base_config.assets_url+banner_list.mobile_banner} className='pull-right' target={"_BLANK"}>
                            <i className='fa fa-eye'></i>
                        </a>
                    }
                    <input type={"file"} name="mobile_banner" className='form-control' onChange={e => setMobile_banner(e.target.files[0])} accept=".png, .jpg, .jpeg, .svg, .gif" />
                </div>
                <div className='box-body'>
                    <label>Banner Alt </label>
                    <input type={"text"} name="banner_alt" className='form-control' placeholder='e.g Toppers of 2022' onChange={e => setBanner_alt(e.target.value)} value={banner_alt}/>
                </div>
                <div className='box-body'>
                    <label>Banner Title </label>
                    <input type={"text"} name="banner_title" className='form-control' placeholder='e.g Toppers of 2022'  onChange={e => setBanner_title(e.target.value)} value={banner_title}/>
                </div>
                <div className='box-body'>
                    <label>Banner URL </label>
                    <input type={"url"} name="banner_url" className='form-control' placeholder='e.g https://escholar.in' onChange={e => setBanner_url(e.target.value)} value={banner_url}/>
                </div>
                <div className='box-body'>
                    <label>Select Section</label>
                    <select className='form-control' defaultValue={section} onChange={(e) => setSection(e.target.value)} >
                        <option value={"Top Banner"}>Top Banner</option>
                        <option value={"Bottom Banner"}>Bottom Banner</option>
                    </select>
                </div>
                <div className='box-body'>
                    <label>Available For</label>
                    <select className='form-control' multiple id='availablrfor' data-placeholder='Item Available For' defaultValue={availablefor} >
                        <option value={"Web"}>Web</option>
                        <option value={"Mobile"}>Mobile Web</option>
                        <option value={"Android"}>Android</option>
                        <option value={"IOS"}>IOS</option>
                    </select>
                </div>
                <div className='box-footer'>
                    <Link to={"/admin/banner"} className="btn btn-flat" title='Back'>
                        Back
                    </Link>
                    <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={"Update Banner"}/>
                </div>
            </form>  
        }
        </div>
      </div>
      
    </>
    )
  }
  return (
    <>
      <Sidebar page={Banners} permission="Banner"/>
    </>
  )
}
