import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Adminprops from '../../VelidateWebsite/Adminprops'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import Sidebar from '../includes/Sidebar'
import Facilites from './Facilites';
import Notfound from '../includes/Notfound';
import Loading from '../includes/Loading';
const cookies = new Cookies();

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

export default function Coursefacilities() {
  const Facilities = (props) => {
    var aa = 0
    const params = useParams()
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(true)
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {errorModal, AdminBasics, pagePermissions, updatTrail, assets_url, formLoading, successModal, warningModal} = CommonFunc()
    const [refres, setRefresh] = useState("No")
    const [list, setList] = useState(Facilites)
    const [selected_permissions, setSelected_permissions] = useState([])

    const [courseLoading, setCourseLoading] = useState(false)
    const [courseListdata, setCourseListdata] = useState([])
    const [coursePermitdata, setCoursePermitdata] = useState([])
    const [staffList, setStaffList] = useState([])
    
    const query = useQuery();
    const courseid = query.get("courseid");
    const courseindex = "course"+courseid;
    const [permissionArray, setPermissionArray] = useState([])
    const [importId, setImportId] = useState("")
    const [settingpermission, setsettingpermission] = useState({
        status: false
    })
    
    const coursePermissionsCheck = (permissions_list, pagename , permission) => {
        var permissionArray = permissions_list
          if(permissionArray !== null && typeof permissionArray !== "undefined" && permissionArray !== "none"){
            permissionArray = permissionArray.filter(function(item){
                if(item.page_name == pagename){
                  return item 
                }
            })
            
            if(permissionArray.length > 0){
              var filtered = permissionArray[0].staff_facilities.filter(function(item){
                  if(item.name == permission){
                    return item 
                  }
              })
              // console.log(filtered)
            }
            
            return ((typeof filtered !== "undefined" && filtered.length > 0) ? filtered[0]['value'] : false)
            
          }else{
            return false
          }
          
      }

      const headings = {
        "show_in_sidebar": "Basic Permissions",
        "test_attempts_tab": "Quiz Attempt Permissions",
        "assignments_tab": "Assignments Permissions",
        "view_course_folder": "View Stuff in Course Content",
        "add_course_folder": "Add Stuff in Course Content",
        "edit_course_folder": "Edit Stuff in Course Content",
        "delete_course_folder": "Delete Stuff in Course Content",
        "schedule_course_folder": "Schedule Stuff in Course Content",
        "info_course_folder": "View Info Of Stuff in Course Content",
        "download_course_document": "Download Stuff in Course Content",
        "schedule_course_image": "Schedule Stuff in Course Content",
      };
    const check_permissions = (x) => {
        var temp = [
            {
                page_name : courseindex,
                staff_facilities : [
                    { name : "show_in_sidebar", value: coursePermissionsCheck(x, courseindex, "show_in_sidebar") },
                    { name : "acivity_panel", value: coursePermissionsCheck(x, courseindex, "acivity_panel") },
                    { name : "basic_information", value: coursePermissionsCheck(x, courseindex, "basic_information") },
                    { name : "price_variations", value: coursePermissionsCheck(x, courseindex, "price_variations") },
                    { name : "link_promocodes", value: coursePermissionsCheck(x, courseindex, "link_promocodes") },
                    { name : "upsale", value: coursePermissionsCheck(x, courseindex, "upsale") },
                    { name : "seo", value: coursePermissionsCheck(x, courseindex, "seo") },
                    // { name : "live_classes", value: coursePermissionsCheck(x, courseindex, "live_classes") },
                    { name : "package_content", value: coursePermissionsCheck(x, courseindex, "package_content") },
                    { name : "course_content", value: coursePermissionsCheck(x, courseindex, "course_content") },
                    { name : "mail_notification", value: coursePermissionsCheck(x, courseindex, "mail_notification") },
                    { name : "recycle_bin", value: coursePermissionsCheck(x, courseindex, "recycle_bin") },
                    { name : "daily_traffic_chart", value: coursePermissionsCheck(x, courseindex, "daily_traffic_chart") },
                    { name : "daily_revenue_chart", value: coursePermissionsCheck(x, courseindex, "daily_revenue_chart") },
                    { name : "india_chart", value: coursePermissionsCheck(x, courseindex, "india_chart") },
                    { name : "files_feedback", value: coursePermissionsCheck(x, courseindex, "files_feedback") },
                    { name : "enrolled_tab", value: coursePermissionsCheck(x, courseindex, "enrolled_tab") },
                    { name : "banners_tab", value: coursePermissionsCheck(x, courseindex, "banners_tab") },
                    { name : "expire_notification", value: coursePermissionsCheck(x, courseindex, "expire_notification") },
                    { name : "all_content_schedule_button", value: coursePermissionsCheck(x, courseindex, "all_content_schedule_button") },
                    { name : "download_all_button", value: coursePermissionsCheck(x, courseindex, "download_all_button") },
                    { name : "send_notification_box", value: coursePermissionsCheck(x, courseindex, "send_notification_box") },
                    { name : "course_mark_all", value: coursePermissionsCheck(x, courseindex, "course_mark_all") },
                    { name : "course_drag_all", value: coursePermissionsCheck(x, courseindex, "course_drag_all") },
                    { name : "show_course_card_details", value: coursePermissionsCheck(x, courseindex, "show_course_card_details") },
                    { name : "show_order_details", value: coursePermissionsCheck(x, courseindex, "show_order_details") },
                    { name : "course_chats_tab", value: coursePermissionsCheck(x, courseindex, "course_chats_tab") },
                    { name : "notifyme_tab", value: coursePermissionsCheck(x, courseindex, "notifyme_tab") },
                    { name : "books_tab", value: coursePermissionsCheck(x, courseindex, "books_tab") },
                    { name : "logs_tab", value: coursePermissionsCheck(x, courseindex, "logs_tab") },
                    
                    
                    { name : "test_attempts_tab", value: coursePermissionsCheck(x, courseindex, "test_attempts_tab") },
                    { name : "view_test_summary", value: coursePermissionsCheck(x, courseindex, "view_test_summary") },
                    { name : "alter_quiz_marks", value: coursePermissionsCheck(x, courseindex, "alter_quiz_marks") },
                    { name : "show_staff_details", value: coursePermissionsCheck(x, courseindex, "show_staff_details") },


                    
                    { name : "assignments_tab", value: coursePermissionsCheck(x, courseindex, "assignments_tab") },
                    { name : "all_assignment_tab", value: coursePermissionsCheck(x, courseindex, "all_assignment_tab") },
                    { name : "teacher_review_tab", value: coursePermissionsCheck(x, courseindex, "teacher_review_tab") },
                    { name : "accepted_by_teacher_tab", value: coursePermissionsCheck(x, courseindex, "accepted_by_teacher_tab") },
                    { name : "rejected_by_teacher_tab", value: coursePermissionsCheck(x, courseindex, "rejected_by_teacher_tab") },
                    { name : "finished_assignment_tab", value: coursePermissionsCheck(x, courseindex, "finished_assignment_tab") },
                    { name : "rejected_assignment_tab", value: coursePermissionsCheck(x, courseindex, "rejected_assignment_tab") },
                    { name : "related_products_tab", value: coursePermissionsCheck(x, courseindex, "related_products_tab") },
                    { name : "show_assignment_trail_staff", value: coursePermissionsCheck(x, courseindex, "show_assignment_trail_staff") },
                    { name : "allow_reupload", value: coursePermissionsCheck(x, courseindex, "allow_reupload") },
                    
                    { name : "view_course_folder", value: coursePermissionsCheck(x, courseindex, "view_course_folder") },
                    { name : "view_course_image", value: coursePermissionsCheck(x, courseindex, "view_course_image") },
                    { name : "view_course_video", value: coursePermissionsCheck(x, courseindex, "view_course_video") },
                    { name : "view_course_document", value: coursePermissionsCheck(x, courseindex, "view_course_document") },
                    { name : "view_course_quiz", value: coursePermissionsCheck(x, courseindex, "view_course_quiz") },
                    { name : "view_course_live_classes", value: coursePermissionsCheck(x, courseindex, "view_course_live_classes") },
                    { name : "view_course_assignment", value: coursePermissionsCheck(x, courseindex, "view_course_assignment") },
                    
                    { name : "add_course_folder", value: coursePermissionsCheck(x, courseindex, "add_course_folder") },
                    { name : "add_course_image", value: coursePermissionsCheck(x, courseindex, "add_course_image") },
                    { name : "add_course_video", value: coursePermissionsCheck(x, courseindex, "add_course_video") },
                    { name : "add_course_document", value: coursePermissionsCheck(x, courseindex, "add_course_document") },
                    { name : "add_course_quiz", value: coursePermissionsCheck(x, courseindex, "add_course_quiz") },
                    { name : "add_course_live_classes", value: coursePermissionsCheck(x, courseindex, "add_course_live_classes") },
                    { name : "add_course_assignment", value: coursePermissionsCheck(x, courseindex, "add_course_assignment") },
                    
                    { name : "edit_course_folder", value: coursePermissionsCheck(x, courseindex, "edit_course_folder") },
                    { name : "edit_course_image", value: coursePermissionsCheck(x, courseindex, "edit_course_image") },
                    { name : "edit_course_video", value: coursePermissionsCheck(x, courseindex, "edit_course_video") },
                    { name : "edit_course_document", value: coursePermissionsCheck(x, courseindex, "edit_course_document") },
                    { name : "edit_course_quiz", value: coursePermissionsCheck(x, courseindex, "edit_course_quiz") },
                    { name : "edit_course_live_classes", value: coursePermissionsCheck(x, courseindex, "edit_course_live_classes") },
                    { name : "edit_course_assignment", value: coursePermissionsCheck(x, courseindex, "edit_course_assignment") },
                    
                    { name : "delete_course_folder", value: coursePermissionsCheck(x, courseindex, "delete_course_folder") },
                    { name : "delete_course_image", value: coursePermissionsCheck(x, courseindex, "delete_course_image") },
                    { name : "delete_course_video", value: coursePermissionsCheck(x, courseindex, "delete_course_video") },
                    { name : "delete_course_document", value: coursePermissionsCheck(x, courseindex, "delete_course_document") },
                    { name : "delete_course_quiz", value: coursePermissionsCheck(x, courseindex, "delete_course_quiz") },
                    { name : "delete_course_live_classes", value: coursePermissionsCheck(x, courseindex, "delete_course_live_classes") },
                    { name : "delete_course_assignment", value: coursePermissionsCheck(x, courseindex, "delete_course_assignment") },

                    // { name : "schedule_course_folder", value: coursePermissionsCheck(x, courseindex, "schedule_course_folder") },
                    { name : "schedule_course_image", value: coursePermissionsCheck(x, courseindex, "schedule_course_image") },
                    { name : "schedule_course_video", value: coursePermissionsCheck(x, courseindex, "schedule_course_video") },
                    { name : "schedule_course_document", value: coursePermissionsCheck(x, courseindex, "schedule_course_document") },
                    { name : "schedule_course_quiz", value: coursePermissionsCheck(x, courseindex, "schedule_course_quiz") },
                    { name : "schedule_course_live_classes", value: coursePermissionsCheck(x, courseindex, "schedule_course_live_classes") },
                    { name : "schedule_course_assignment", value: coursePermissionsCheck(x, courseindex, "schedule_course_assignment") },

                    { name : "info_course_folder", value: coursePermissionsCheck(x, courseindex, "info_course_folder") },
                    { name : "info_course_image", value: coursePermissionsCheck(x, courseindex, "info_course_image") },
                    { name : "info_course_video", value: coursePermissionsCheck(x, courseindex, "info_course_video") },
                    { name : "info_course_document", value: coursePermissionsCheck(x, courseindex, "info_course_document") },
                    { name : "add_course_quiz_question", value: coursePermissionsCheck(x, courseindex, "add_course_quiz_question") },
                    { name : "info_course_live_classes", value: coursePermissionsCheck(x, courseindex, "info_course_live_classes") },
                    { name : "info_course_assignment", value: coursePermissionsCheck(x, courseindex, "info_course_assignment") },

                    { name : "download_course_document", value: coursePermissionsCheck(x, courseindex, "download_course_document") },
                    
                    { name : "download_course_assignment", value: coursePermissionsCheck(x, courseindex, "download_course_assignment") },
                ]
            },
        ]
        var tempindex = {}
        tempindex[courseindex] = temp
        setPermissionArray(tempindex)
        setRefresh(refres == "No" ? "Yes" : "No")
    }

    const refreshSettingStatus = () => {
        var temp = settingpermission
        temp['status'] = false
        setsettingpermission(temp)
        setRefresh("No" ? "Yes" : "No")
    }
    const selectAll = (id) => {
        var temp = settingpermission
        temp['status'] = true
        setsettingpermission(temp)

        window.$(".btnid"+id).each(function(){
            window.$(this).click()
        })
        setTimeout(function(){
            // update_permissions(permissionArray, "Yes")
            refreshSettingStatus()
        }, 1000)
        
    }

    const get_staff = () => {
        fetch(apiurl+"api/staff_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    staffdata: params.id,
                    pagepermission: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    setStaffList(response.stafflist)
                    setName(response.staff.name)
                    updatTrail("Visited <strong>"+response.staff.name+"</strong> Permission Page", "Staff", "2")
                    
                    setTimeout(function(){
                        window.$("#select2").select2()
                    }, 2000)

                    check_permissions(response.staff.permissions)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const courseList = () => {
        setCourseLoading(true)
        fetch(apiurl+"api/courseList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    staff: AdminBasics(),
                    staffid: params.id,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){

                    setCourseListdata(response.list)
                    setCoursePermitdata(response.coursespermit)
                    if(courseid !== null){
                        setTimeout(function(){
                            window.$("#select2Courses").select2()
                        }, 2000)
                    }
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setCourseLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const update_permissions = (permissions) => {
        
        warningModal("Loading..", "Facilities updating please wait...")
        courseid !== null &&
        fetch(apiurl+"api/update_course_permissions", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    id: params.id,
                    courseindex: courseindex,
                    courseid: courseid,
                    page_url: window.location.href,
                    col: "coursepermission",
                    permissions: JSON.stringify(permissions),
                    coursesList: window.$("#select2Courses").val()
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    // window.location.reload()
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const change_permission = (page, option) => {
        var permissions = permissionArray
        if(permissions[courseindex][page]['staff_facilities'][option]['value'] == true){
            permissions[courseindex][page]['staff_facilities'][option]['value'] = false
        }else{
            permissions[courseindex][page]['staff_facilities'][option]['value'] = true
        }
        
        // if(!settingpermission.status){
        //     update_permissions(permissions, "No")
        // }
        setPermissionArray(permissions)
        setRefresh(refres == "No" ? "Yes" : "No")
    }

    const importPermission = (e) => {
        
        formLoading(e.target)
        e.preventDefault();

        fetch(apiurl+"api/importpermission", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    id: params.id,
                    iid: importId,
                    type: "Course",
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", "Permission Imported Successfully")
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    
    const clearAllPermissions = () => {
        
        if(window.confirm("Are you sure you want to reset all courses permissions?")){
        
            warningModal("Loading..", "Reseting All Permissions Please Wait...")
            
            fetch(apiurl+"api/clearAllPermissions", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        staff: AdminBasics(),
                        accecible: 1,
                        id: params.id,
                        page_url: window.location.href,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        window.location.reload()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
            }
    }

    var a = 0
    useEffect(() => {
        if(a == 0){
            
            get_staff();
            courseList();
            a++
        }
    }, [])
    
    return(

        pagePermissions(props.pagepermissions, "Staff", "course_facility_staff") ?
        <div className='col-md-12 no-left-padding no-right-padding'>
             <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <div className='box-title'>
                                Select Course
                            </div>
                        </div>
                        <div className='box-body'>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder='e.g CA Foundation: Perfect Practice + Analyser' onChange={(e) => {
                                    window.$(".mynavstack li").each(function(){
                                        var b = window.$(this).attr("title").toLowerCase()
                                        if(b.indexOf(e.target.value.toLowerCase()) != -1){
                                            window.$(this).show()
                                        }else{
                                            window.$(this).hide()
                                        }
                                    });
                                }} />
                                <span className="input-group-addon"><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    {
                            <div className='box no-border'>
                                <form action='' method='POST' onSubmit={(e) => importPermission(e)} >
                                    <div className='box-body'>
                                        <label>Import Permissions</label>
                                        <select className='form-control' id='select2' onChange={(e) => setImportId(e.target.value)} required >
                                        <option value={""}>Select Staff Member</option>
                                            {
                                                staffList.length > 0 &&
                                                staffList.map((item, index) => (
                                                    item.id !== params.id &&
                                                    <option value={item.id} key={"option"+index} >
                                                        {item.name} {item.email} ({item.role})
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='box-footer'>
                                        <button className='btn btn-primary btn-flat btn-block'>Import</button>
                                    </div>
                                </form>
                            </div>
                    }
                    {
                        coursePermitdata.length > 0 &&
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Permissioned Courses
                                </h3>
                                
                            </div>
                            <div className='box-body'>
                                <ul className="nav nav-stacked mynavstack">
                                        <>
                                        {
                                            coursePermitdata.map((item, index)=>(
                                                    <li title={item.name} key={"course"+index}  >
                                                        <Link to={"/admin/course-facilities/"+params.id+"/?courseid="+item.otherid} title={item.name}>
                                                            <div className='courseListSearch' >
                                                                <img src={assets_url+item.thumbnail} style={{"width":"50px", objectFit:"contain"}} />
                                                                &nbsp;&nbsp;
                                                                {item.name} 
                                                            </div>
                                                            <span className={(courseid === item.otherid) ? "pull-right badge bg-blue" : "pull-right badge"}>
                                                                <i className='fa fa-arrow-right'></i>    
                                                            </span>
                                                        </Link>
                                                    </li>
                                            ))
                                        }
                                        </>
                                </ul>
                            </div>
                            <div className='box-footer'>
                                <button className='btn btn-danger btn-flat btn-block' onClick={() => clearAllPermissions()}>Clear All Permissions?</button>
                            </div>
                        </div>  
                    }
                    <div className='box no-border'>
                        <div className='box-body'>
                            <ul className="nav nav-stacked mynavstack">
                                {
                                    courseListdata.length > 0 ?
                                    <>
                                    {
                                        courseListdata.map((item, index)=>(
                                            <li key={"course"+index} title={item.name} >
                                                <Link to={"/admin/course-facilities/"+params.id+"/?courseid="+item.otherid} title={item.name}>
                                                    <div className='courseListSearch' >
                                                        <img src={assets_url+item.thumbnail} style={{"width":"50px", objectFit:"contain"}} />
                                                        &nbsp;&nbsp;
                                                        {item.name} 
                                                    </div>
                                                    <span className={(courseid === item.otherid) ? "pull-right badge bg-blue" : "pull-right badge"}>
                                                        <i className='fa fa-arrow-right'></i>    
                                                    </span>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                    </>
                                    :
                                    <li>
                                        No Courses Found
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>    
                </div>   

            {
                courseid !== null &&
            <div className='col-md-8'>
                {
                    courseListdata.length > 0 &&
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Bulk Course Permission?
                            </h3>
                        </div>
                        <form action='' method='POST'>
                            <div className='box-body'>
                                <select className='form-control select2' id='select2Courses' data-placeholder='Select Courses' multiple >
                                    {
                                        coursePermitdata.map((item, index)=> {
                                            return(
                                                <option value={item.otherid} key={"staffc" + index} >{item.name}</option>           
                                            )
                                        })
                                    }
                                    {
                                        courseListdata.map((item, index)=> {
                                            return(
                                                <option value={item.otherid} key={"staff" + index} >{item.name}</option>           
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </form>
                    </div>
                }
                <div className='box no-border'>
                    <div className='box-header'>
                        <div className='box-title'>
                        Authorities
                        </div>
                    </div>
                    <div className='box-body'>
                        
                        {
                            courseLoading ? 
                            <>
                                <br /><br />
                                <Loading />
                                <br /><br />
                            </>
                            :
                            <>
                           
                            {
                                (typeof permissionArray[courseindex] !== "undefined") && permissionArray[courseindex].length > 0 && 
                                <>
                                    {
                                        permissionArray[courseindex][0]['staff_facilities'].map((item2, index2) => {
                                            // var ctababval = ""
                                            if(headings[item2.name] !== undefined){
                                                // console.log(headings[0])
                                                var objarr = Object.values(headings)
                                                aa = objarr.indexOf(headings[item2.name])
                                            }
                                            var idd = aa
                                            
                                            return(
                                                <>
                                                    {
                                                        headings[item2.name] !== undefined &&
                                                            <div className='box-header no-left-padding' style={{width:"100%", borderTop:"2px solid #58617a", marginTop:"20px", marginBottom:"10px"}} key={"bt"+index2} >
                                                                <div style={{ display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}} >
                                                                    <h3 className='box-title'>
                                                                        {headings[item2.name]}
                                                                    </h3>
                                                                    {
                                                                        settingpermission.status ?
                                                                        <button className='btn btn-warning pull-right btn-flat' >Loading...</button>
                                                                        :
                                                                        <button className='btn btn-danger pull-right btn-flat' onClick={() => {
                                                                            selectAll(idd)
                                                                        }} >Select All</button>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                    }
    
                                                    <button className={(item2.value == true) ? 'btn btn-flat my-btns btn-primary btnid'+aa : "btn btn-flat my-btns btnid"+aa } onClick={() => change_permission(0, index2)} key={"btn"+index2} >
                                                        {item2.name.replace(/_/g, " ")}
                                                    </button>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }
                            </>
                        }
                    </div>
                </div> 
                
                <button style={{width:"100%", position:"fixed", bottom:"20px", right:"15px", maxWidth:"200px"}} className='btn btn-danger btn-flat btn-block'
                onClick={() => update_permissions(permissionArray)}
                >
                    <i className='fa fa-save'></i> Update Permissions
                </button>   
            </div>  
        }
        </div>
      :
      <Notfound />
    )
  }
  return (
    <>
      <Sidebar page={Facilities} permission="Staff"/>
    </>
  )
}
