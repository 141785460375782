import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const cookies = new Cookies();

export default function Banner() {
    
  const Banners = (props) => {
    const [pc_banner, setPc_banner] = useState("")
    const [mobile_banner, setMobile_banner] = useState("")
    const [banner_alt, setBanner_alt] = useState("")
    const [banner_title, setBanner_title] = useState("")
    const [banner_url, setBanner_url] = useState("")
    const [banner_list, setBanner_list] = useState([])
    const [section, setSection] = useState("Top Banner")
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    var a = 0;
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()

    const get_banners_list = () => {
        
        fetch(apiurl+"api/banner_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : pagePermissions(props.pagepermissions, "Banner", "view_banner") ? "Bearer "+getToken : ""
            },
            "body": JSON.stringify({
                    accecible: 1,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setBanner_list(response.banner)
                    setTimeout(function(){
                        window.$("#availablrfor").unbind().select2()
                    }, 500) 
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const validateBanner = (e) => {
        e.preventDefault();

        formLoading(e.target)
        const formData = new FormData();
        formData.append('pc_banner', pc_banner);
        formData.append('mobile_banner', mobile_banner);
        formData.append('banner_alt', banner_alt);
        formData.append('banner_title', banner_title);
        formData.append('banner_url', banner_url);
        formData.append('section', section);
        formData.append('availablefor', window.$("#availablrfor").val());
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/add_banner", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : pagePermissions(props.pagepermissions, "Banner", "add_banner") ? "Bearer "+getToken : ""
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").fadeOut()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    $(e.target).trigger("reset");
                    get_banners_list()
                    setTimeout(function(){
                        window.location.reload()
                    },500)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }



    const removeBanner = (id) => {
        fetch(apiurl+"api/remove_banner", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : pagePermissions(props.pagepermissions, "Banner", "remove_banner") ? "Bearer "+getToken : ""
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    // get_banners_list()
                    setTimeout(function(){
                        window.location.reload()
                    },500)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeBanner(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }

    const initializeSortable = () => {
        const that = this;
        $( ".droppable-area1" ).sortable({
            stop: function(event, ui) {
                alert();
           },
           connectWith: ".connected-sortable",
           stack: '.connected-sortable ul'
       }).disableSelection();
    }

    const reset = () => {
        alert()
    }


    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_banner_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });
                    // console.log(array)
                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }
    useEffect(() => {
        if(a == 0){
            get_banners_list()
            a++
            
        }
     }, [])



    return(
    
    <>
    {
        pagePermissions(props.pagepermissions, "Banner", "add_banner") &&
      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
                Add Banners
            </h3>
            <p className='text-red'>
                <strong>
                    File Size: Upto 1 MB<br />
                    Accepted Formats: .jpg, .jpeg, .png, .gif, .svg<br />
                </strong>
            </p>
          </div>
            <form action='#' method='POST' onSubmit={validateBanner}>
                <div className='box-body'>
                    <label>PC Banner <span className='text-red'>Width 1600PX : Height 500PX || Aspect Ration 16:5</span></label>
                    <input type={"file"} name="pc_banner" className='form-control' onChange={e => setPc_banner(e.target.files[0])} accept=".png, .jpg, .jpeg, .svg, .gif" />
                </div>
                <div className='box-body'>
                    <label>Mobile Banner <span className='text-red'>Width 500PX : Height 500PX || Aspect Ration 1:1</span></label>
                    <input type={"file"} name="mobile_banner" className='form-control' onChange={e => setMobile_banner(e.target.files[0])} accept=".png, .jpg, .jpeg, .svg, .gif" />
                </div>
                <div className='box-body'>
                    <label>Banner Alt </label>
                    <input type={"text"} name="banner_alt" className='form-control' placeholder='e.g Toppers of 2022' onChange={e => setBanner_alt(e.target.value)}/>
                </div>
                <div className='box-body'>
                    <label>Banner Title </label>
                    <input type={"text"} name="banner_title" className='form-control' placeholder='e.g Toppers of 2022'  onChange={e => setBanner_title(e.target.value)}/>
                </div>
                <div className='box-body'>
                    <label>Banner URL </label>
                    <input type={"url"} name="banner_url" className='form-control' placeholder='e.g https://escholar.in' onChange={e => setBanner_url(e.target.value)}/>
                </div>
                <div className='box-body'>
                    <label>Select Section</label>
                    <select className='form-control' defaultValue={section} onChange={(e) => setSection(e.target.value)} >
                        <option value={"Top Banner"}>Top Banner</option>
                        <option value={"Bottom Banner"}>Bottom Banner</option>
                    </select>
                </div>
                <div className='box-body'>
                    <label>Available For</label>
                    <select className='form-control' multiple id='availablrfor' data-placeholder='Item Available For' >
                        <option value={"Web"}>Web</option>
                        <option value={"Mobile"}>Mobile Web</option>
                        <option value={"Android"}>Android</option>
                        <option value={"IOS"}>IOS</option>
                    </select>
                </div>
                <div className='box-footer'>
                    <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={"Add Banner"}/>
                </div>
            </form>  
        </div>
      </div>
  }
      
      
    {
        pagePermissions(props.pagepermissions, "Banner", "view_banner") &&
        <div className='col-md-8'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        All Banners
                    </h3>
                </div>
                <div className='box-body' style={{overflow:"auto"}} >
                    <table id="staff_table" className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>
                                    Drag
                                </th>
                                {/* <th>
                                    S.No
                                </th> */}
                                <th>
                                    PC Banner
                                </th>
                                <th>
                                    Mobile Banner
                                </th>
                                <th>
                                    Section
                                </th>
                                <th>
                                    Banner Alt
                                </th>
                                <th>
                                    Banner Title
                                </th>
                                <th>
                                    Banner URL
                                </th>
                                <th>
                                    Available For
                                </th>
                                <th>
                                    PC Banner Info
                                </th>
                                <th>
                                    Mobile Banner Info
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='connected-sortable droppable-area1' onLoad={() => callSorting()}>
                            {
                                banner_list.length > 0 &&
                                banner_list.map((item, index) => {

                                    const pc_banner_data = (item.pc_banner_data !== null) ? JSON.parse(item.pc_banner_data) : null
                                    const mobile_banner_data =  (item.mobile_banner_data !== null) ? JSON.parse(item.mobile_banner_data) : null
                                    
                                    return (
                                    <tr key={"banners"+index} className="draggable-item" data-bind={item.id}>
                                        
                                        <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                            <i className='fa fa-arrows-v'></i>
                                        </td>
                                        {/* <td>
                                            {index+1}
                                        </td> */}
                                        <td>
                                            {
                                                pc_banner_data !== null &&
                                                <img referrerPolicy='no-referrer' src={base_config.assets_url+item.pc_banner} style={{width:"50px", height:"50px", objectFit:"contain"}} />
                                            }
                                        </td>
                                        <td>
                                            {
                                                mobile_banner_data !== null &&
                                                <img referrerPolicy='no-referrer' src={base_config.assets_url+item.mobile_banner} style={{width:"50px", height:"50px", objectFit:"contain"}} />
                                            }
                                        </td>
                                        <td>
                                            {item.section}
                                        </td>
                                        <td>
                                            {item.banner_alt}
                                        </td>
                                        <td>
                                            {item.banner_title}
                                        </td>
                                        <td>
                                            <a href={item.banner_url} target='_BLANK' >
                                                {item.banner_url}
                                            </a>
                                        </td>
                                        <td>
                                            {
                                                item.availablefor
                                            }
                                        </td>
                                        <td>
                                            {
                                                pc_banner_data !== null &&
                                                <>
                                                    <strong>{pc_banner_data.width} X {pc_banner_data.height}</strong><br />
                                                    <strong>Size: </strong> {pc_banner_data.org_size}<br />
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {
                                                mobile_banner_data !== null &&
                                                <>
                                                <strong>{mobile_banner_data.width} X {mobile_banner_data.height}</strong><br />
                                                <strong>Size: </strong> {mobile_banner_data.org_size}<br />
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {
                                                pagePermissions(props.pagepermissions, "Banner", "edit_banner") &&
                                                <Link to={"/admin/edit-banner/"+item.id} className='btn btn-primary btn-flat'>
                                                    <i className='fa fa-edit'></i>
                                                </Link>
                                            }
                                            &nbsp;
                                            {
                                                pagePermissions(props.pagepermissions, "Banner", "remove_banner") &&
                                                <button className='btn btn-danger btn-flat' onClick={e => removeDialog(item.id)} title="Remove Banner">
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                )})
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    
    </>
    )
  }
  return (
    <>
      <Sidebar page={Banners} permission="Banner"/>
    </>
  )
}
