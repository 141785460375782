import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './Home';
import Login from './admin/Login';
import Admindashboard from './admin/Admindashboard';
import { useEffect, useState } from 'react';
import { isEmptyObject } from 'jquery';
import apiurl from './Global';
import Notfound from './Notfound';
import Cookies from 'universal-cookie';
import Staff from './admin/staff/Staff';
import Editstaff from './admin/staff/Editstaff';
import Editfacilities from './admin/staff/Editfacilities';
import Banner from './admin/Images/Banner';
import Editbanner from './admin/Images/Editbanner';
import Assets from './admin/Assets_Liabrary/Assets';
import Import from './admin/Assets_Liabrary/Import';
import Video from './admin/Assets_Liabrary/Video';
import Questions from './admin/Assets_Liabrary/Questions';
import Managecourse from './admin/Course/Managecourse';
import Course from './admin/Course/Course';
import Coursecontent from './admin/Course/Coursecontent';
import Createquiz from './admin/Course/Createquiz';
import Editquiz from './admin/Course/Editquiz';
import Addquestions from './admin/Course/Addquestions';
import Importquestions from './admin/Assets_Liabrary/Importquestions';
import Editquestion from './admin/Course/Editquestion';
import Coursefacilities from './admin/staff/Coursefacilities';
import Managecategories from './admin/Categories/Managecategories';
import Test from './Test';
import Logs from './admin/Logs/Logs';
import Preview from './admin/Assets_Liabrary/Preview';
import Mydownloads from './admin/Downloads/Mydownloads';
import Downloadrequets from './admin/Downloads/Downloadrequets';
import Categorypositions from './admin/Categories/Categorypositions';
import Liveclasses from './admin/Assets_Liabrary/Liveclasses';
import Loginsteps from './admin/Loginsteps/Loginsteps';
import Manageuser from './admin/Student/Managestudent';
import Managestudent from './admin/Student/Managestudent';
import Themes from './admin/Themes/Themes';
import Rules from './admin/Rules/Rules';
import Orders from './admin/Orders/Orders';
import Orderinfo from './admin/Orders/Orderinfo';
import Addforum from './admin/Forum/Addforum';
import Reports from './admin/Forum/Reports';
import Faq from './admin/Faq/Faq';
import Chat from './admin/Chat/Chat';
import Sales from './admin/Sales/Sales';
import Messanger from './admin/Messanger/Messanger';
import Notifications from './admin/Notifications/Notification';
import Studentprofile from './admin/Student/Studentprofile';
import Blogs from './admin/Blogs/Blogs';
import Pages from './admin/Pages/Pages';
import Promocode from './admin/Promocode/Promocode';
import Allattempts from './admin/Course/Alltestattempts';
import Doubts from './admin/Chat/Doubts';
import Feedback from './admin/Feedback/Feedback';
import Allfiles from './admin/Course/Allfiles';
import Addsocial from './admin/Social/Addsocial';
import Reportedquestions from './admin/ReportedQuestions/Reportedquestions';
import ManageEnquries from './admin/Enquiries/Enquiries';
import Permissiongroups from './admin/staff/Permissiongroups';
import Usage from './admin/Usage/Usage';
import Managestudentlimit from './admin/Student/Managestudentlimit';
import Liveusage from './admin/Usage/Liveusage';

import NotificationStaff from './admin/staff/Notification';
import Removerequested from './admin/Student/Removerequested';
import Assetsfaq from './admin/Assets_Liabrary/Assetsfaq';
import Pagedata from './admin/Pages/Pagedata';
import Requestedvideos from './admin/Requestedvideos/Requestedvideos';
import Processes from './admin/Processes/Processes';
import Managebooks from './admin/Books/Managebooks';
import Transactionbooks from './admin/Books/Transactionbooks';
import Shippingrules from './admin/Shipping/Shippingrules';
import Orderbooks from './admin/Orders/Orderbooks';
import { app,messaging } from './firebase';
import { getMessaging ,getToken } from "firebase/messaging";
import Shopifylink from './admin/Shopify/Shopifylink';


const cookies = new Cookies();
let loggedin = cookies.get('admin')
            

function App() {

  var a = 0;
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  
  const [adminProps, setAdminprops] = useState([])
  const {pathname} = useLocation()
  
  function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        getToken(messaging, { vapidKey: "BIoKzVjpJ0U-n1e-E4jUbftoBnWH6Vg90ykZwhgCqtVJNoOCOeZaNME3EezNHAwFOFzgY3Z4SE1-MFC0bFcEa7s"}).then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            // console.log(currentToken)
            window.localStorage.setItem("notifytoken", currentToken);
            // registerToken(currentToken)
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
      }
      else{
        console.log("Permission not granted")
      }
    })
  }
  
  useEffect(() => {
    if(a == 0){
      window.scrollTo(0,0)
      requestPermission()

      a++
    }
  }, [pathname])



  return (
    <Routes basename={process.env.PUBLIC_URL}>

      <Route exact path="/not-found" element={<Notfound />} />
            <Route exact path="/test" element={<Test />} />
      {/* {console.log(adminProps.slug)} */}
        <Route exact path="*" element={<Notfound />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path={ "/admin/login" } element={<Login />} />
        {
          typeof loggedin !== "undefined" && loggedin !== "" && loggedin !== null &&
          <> 

            <Route exact path="/admin/dashboard" element={<Admindashboard />} />
            <Route exact path="/admin/staff" element={<Staff />} />
            <Route exact path="/admin/permissiongroups" element={<Permissiongroups />} />
            <Route exact path="/admin/edit-staff/:id" element={<Editstaff />} />
            <Route exact path="/admin/edit-facilities/:id" element={<Editfacilities />} />
            <Route exact path="/admin/course-facilities/:id" element={<Coursefacilities />} />

            <Route exact path="/admin/banner" element={<Banner />} />
            <Route exact path="/admin/edit-banner/:id" element={<Editbanner />} />

            <Route exact path="/admin/assets" element={<Assets />} />
            <Route exact path="/admin/assets/:id/:p_id" element={<Assets />} />
            <Route exact path="/admin/import/:id/:p_id" element={<Import />} />
            <Route exact path="/admin/importquestions/:id/:p_id" element={<Importquestions />} />
            <Route exact path="/admin/Video" element={<Video />} />
            <Route exact path="/admin/questions/:id/:p_id" element={<Questions />} />
            <Route exact path="/admin/liveclasses/:id/:p_id" element={<Liveclasses />} />
            <Route exact path="/admin/add-assets-faq/:id" element={<Assetsfaq />} />

            
            <Route exact path="/admin/manage-categories" element={<Managecategories />} />
            <Route exact path="/admin/categories/positions/:id" element={<Categorypositions />} />

            
            <Route exact path="/admin/manage-courses" element={<Managecourse />} />
            <Route exact path="/admin/course/:id" element={<Course />} />
            <Route exact path="/admin/all-files/:type" element={<Allfiles />} />
            <Route exact path="/admin/course-content/:id" element={<Coursecontent />} />
            <Route exact path="/admin/course-content/:id/:p_id" element={<Coursecontent />} />
            <Route exact path="/admin/create-quiz/:id/:p_id" element={<Createquiz />} />
            <Route exact path="/admin/edit-quiz/:id" element={<Editquiz />} />
            <Route exact path="/admin/add-questions/:id" element={<Addquestions />} />
            <Route exact path="/admin/editquestion/:id" element={<Editquestion />} />

            <Route exact path="/admin/logs" element={<Logs />} />
            <Route exact path="/admin/preview/:id" element={<Preview />} />
            
            <Route exact path="/admin/my-downloads" element={<Mydownloads />} />
            <Route exact path="/admin/download-requests" element={<Downloadrequets />} />


            
            <Route exact path="/admin/login-steps" element={<Loginsteps />} />
            <Route exact path="/admin/manage-students" element={<Managestudent />} />
            <Route exact path="/admin/manage-students-remove" element={<Removerequested />} />
            <Route exact path="/admin/manage-students-limit" element={<Managestudentlimit />} />
            <Route exact path="/admin/student-profile/:userid" element={<Studentprofile />} />
            <Route exact path="/admin/themes" element={<Themes />} />

            
            <Route exact path="/admin/rules" element={<Rules />} />
            <Route exact path="/admin/Orders/:type" element={<Orders />} />
            <Route exact path="/admin/order-info/:orderid/:userid" element={<Orderinfo />} />
            <Route exact path="/admin/order-books/:orderid/:userid" element={<Orderbooks />} />
            
            <Route exact path="/admin/forum/manage" element={<Addforum />} />
            <Route exact path="/admin/forum/reports" element={<Reports />} />

            <Route exact path="/admin/faq" element={<Faq />} />
            <Route exact path="/admin/chat" element={<Chat />} />
            <Route exact path="/admin/chat/:resp" element={<Chat />} />
            <Route exact path="/admin/sales" element={<Sales />} />
            <Route exact path="/admin/usage" element={<Usage />} />
            <Route exact path="/admin/live_usage" element={<Liveusage />} />
            <Route exact path="/admin/messages" element={<Messanger />} />
            <Route exact path="/admin/notifications" element={<Notifications />} />


            <Route exact path="/admin/blogs" element={<Blogs />} />
            <Route exact path="/admin/pages" element={<Pages />} />
            <Route exact path="/admin/promocodes" element={<Promocode />} />
            <Route exact path="/admin/all-attempts" element={<Allattempts />} />
            <Route exact path="/admin/doubts" element={<Doubts />} />
            <Route exact path="/admin/feedbacks" element={<Feedback />} />
            
            <Route exact path="/admin/social-links" element={<Addsocial />} />
            <Route exact path="/admin/manage-reports" element={<Reportedquestions />} />

            <Route exact path="/admin/manage-enquiries" element={<ManageEnquries />} />
            <Route exact path="/admin/mynotifications" element={<NotificationStaff />} />

            <Route exact path="/admin/pageData/:id/:pageid" element={<Pagedata />} />

            <Route exact path="/admin/videorequests" element={<Requestedvideos />} />
            <Route exact path="/admin/Processes" element={<Processes />} />

            <Route exact path="/admin/manage-books" element={<Managebooks />} />
            <Route exact path="/admin/manage-books/:id" element={<Transactionbooks />} />

            
            <Route exact path="/admin/shipping-rules" element={<Shippingrules />} />

            <Route exact path="/admin/shopifyLinks" element={<Shopifylink />} />

          </>
        }

    </Routes>
  );
}

export default App;
