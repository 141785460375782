import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Filtercourse from './Filtercourse';

const cookies = new Cookies();

export default function Managecourse() {

    const ManagecoursePage = (props) => {

      const admin = cookies.get('admin')
      let getToken = cookies.get('token')
      var a = 0;
    //   const [name, setCourseName] = useState("")
      const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
      
    const addCourse = () => {
      fetch(apiurl+"api/add_course", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                  name: window.$("#coursename").val(),
                  staff: AdminBasics(),
                  accecible: 1,
                  page_url: window.location.href,
              })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status == "OK"){
                  successModal("Success", response.message)
                  window.location.reload()
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  // alert(response.message)
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              window.$('#addNewCourse').fadeOut()
          })
          .catch(err => {
            window.$('#addNewCourse').fadeOut()
              // console.log(err);
          });
      }


        return(
          <>
          {
            pagePermissions(props.pagepermissions, "Courses", "add_course")  && 
            <>
              <div className='col-md-12'>
                  <button className='btn btn-primary btn-flat pull-right' title='Add New Course' onClick={() => window.$('#addNewCourse').fadeIn()}>
                    <i className='fa fa-plus'></i> Add New Course
                  </button>
              </div>
              <div className='renameDialogBG' id='addNewCourse' style={{display:"none"}}>
                  <div className='renameBody'>
                      <div className='renameHeading'>
                          <p className='text-left'>
                              Course Name
                              <span className='pull-right closePopup' onClick={() => window.$('#addNewCourse').fadeOut()} title="Close">
                                <i className='fa fa-times'></i>
                              </span>
                          </p>
                      </div>
                      <div className='renameInputCon'>
                          <input type={"text"} name={"name"} className="renameInput" required placeholder='e.g CA Foundation 9.0' id="coursename" />
                      </div>
                      <div className='renameFooter'>
                          <button className='btn btn-primary btn-flat pull-right' style={{marginTop:"10px"}}  onClick={() => addCourse()}>
                              Add Now
                          </button>
                      </div>
                  </div>
              </div>
              <br /><br /><br />
            </>
          }

          <Filtercourse type={"allcourse"} pagepermissions={props.pagepermissions} />

          </>
        )
      }

  return (
    <>
        <Sidebar page={ManagecoursePage} permission="Global"/>
    </>
  )
}
