import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import moment from 'moment';
import apiurl from '../../Global';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const noImage = "/assets/assets/images/no-image.png"
const cookies = new Cookies();
export default function Relatedproducts(props) {

    let getToken = cookies.get('token')
    const [courses, setCourses] = useState([])
    const [selectedCourses, setSelectedCourses] = useState( (props.course.related !== null) ? JSON.parse(props.course.related) : [])
    const [loading, setLoading] = useState("")
    const[refresh, setRefresh] = useState("No")
    const {AdminBasics, errorModal, successModal, assets_url, pagePermissions, warningModal} = CommonFunc()
    const [filter, setFilter] = useState({
        "created_from" : "",
        "created_to" : "",
        "updated_from" : "",
        "updated_to" : "",
        // "course_type" : "",
        "course_type" : (props.type !== "package") ? "" : "Course",
        "course_status" : "",
        "searchkey" : "",
        "categories" : [],
        "free" : "",
        "type" : "Yes",
    })

    const fetchCourses = () => {
        setLoading(true)
        fetch(apiurl+"api/fetch_courses", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    filter: filter,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCourses(response.course)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
    }

    const searchProducts = (key) => {
        window.$(".boxes").hide();
        window.$(".boxes").each(function(){
            var name = window.$(this).attr("data-key").toLowerCase();
            key = key.toLowerCase()
            if(name.includes(key)){
                window.$(this).show();
            }
        });
    }
    const relatedList = (index) => {
        var a = selectedCourses;
        if(!a.includes(courses[index]['otherid'])){
            a.push(courses[index]['otherid'])
        }else{
            const inde = a.indexOf(courses[index]['otherid']);
            if (inde > -1) { // only splice array when item is found
                a.splice(inde, 1); // 2nd parameter means remove one item only
            }
        }
        setSelectedCourses(a)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const saveRelatedProducts = () => {
        warningModal("Loading...", "Updating Related Products Please Wait...")
        fetch(apiurl+"api/saveRelatedProducts", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    selectedCourses: selectedCourses,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    courseid: props.course.otherid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    props.basics()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
    }

    useEffect(() => {
        fetchCourses()
    },[selectedCourses])

  return (
    <>
    <br />
    <div className='col-md-12' style={{display:"flex", flexWrap:"wrap"}}>
        <div className='box no-border'>
            <div className='box-header'>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder='e.g CA Foundation 9.0' onChange={(e) => searchProducts(e.target.value)} />
                    <span className="input-group-btn">
                        <button type="button" className="btn btn-primary btn-flat">Go!</button>
                    </span>
                </div>
            </div>    
        </div>    
    </div>
    <div className='col-md-12' style={{display:"flex", flexWrap:"wrap", position:"relative"}}>
        <button style={{
            position:"fixed",
            bottom: "20px",
            zIndex:9,
            left:"calc(50% - 125px)",
            width:"250px"

        }} 
        className='btn btn-success btn-flat'
        onClick={() => saveRelatedProducts()}
        >
            Save Related Procuts
        </button>
        {
            courses.length > 0 &&
            courses.map((item, index) => {
                var img = (item.thumbnail === "") || (item.thumbnail === null) ? noImage : assets_url+item.thumbnail
                moment.locale('en');
                var cd = item.created_at;
                return(
                    <div className='col-md-4 boxes' key={"index"+index} data-key={item.name} onClick={() => relatedList(index)} >
                        <div className='box no-border box-widget'>
                            <div className="box-header with-border">
                                <div className="user-block">
                                    <img className="img-circle" src={img} title={item.name} alt={item.name} loading="lazy" />
                                    <span className="username" style={{marginRight:"50px"}}><a >{item.name}</a></span>
                                    <span className="description">Created On - {moment(cd).format("D-M-Y HH:mm:ss")}</span>
                                    <span className="description">Available For - {item.availablefor}</span>
                                </div>
                                <div className="box-tools">
                                    <button type="button" className={"btn btn-box-tool" + ((selectedCourses.includes(item.otherid)) ? ' bg-green' : '' )} data-toggle="tooltip" title="Mark as read">
                                        {
                                            (!selectedCourses.includes(item.otherid)) ?
                                            <i className="fa fa-circle-o"></i>
                                            :
                                            <i className="fa fa-check-circle-o"></i>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    )
                })
            }   
    </div>
    </>
  )
}
