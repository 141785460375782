import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import Sidebar from '../includes/Sidebar';
import CommonFunc from '../includes/Common';
import apiurl from '../../Global';
import axios from 'axios';
const cookies = new Cookies();

export default function Requestedvideos() {
  const Requestedvideospage = (props) => {
    
    const [loading, setLoading] = useState(false)
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, warningModal, formLoading, pagePermissions} = CommonFunc()
    const [downloadrequests, setDownloadRequests] = useState([])
    const [colorGroups, setColorGroups] = useState([])

    const [uploadingPop, setUploadingPop] = useState(null)
    const [uploadType, setUploadType] = useState("Video")
    const [typeCount, setTypeCount] = useState(null)



const getRequests = (uploadType) => {
    if(!loading){
        setLoading(true)
      fetch(apiurl + "api/getDownloadRequests", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            type:uploadType
        })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setDownloadRequests(response.list)
                setColorGroups(response.groups)
                setTypeCount(response.types)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
    }
  }

  const [pathList, setPath] = useState([]);
  const getPath = (fileid) => {
    fetch(apiurl + "api/getDownloadPath", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            fileid:fileid
        })
        })
        .then(response => response.json())
        .then(response => {
            setPath(response.list)
        })
  }

  const selectUploading = (item) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if(!uploading){
        setPath([])
        getPath(item.fileid)
        setUploadingPop(item)
    }else{
        warningModal("Please Wait...", "Already in progress...")
    }
  }
  
  const changeUploadType = (type) => {
    setUploadType(type)
    getRequests(type)
  }

  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const uploadVideo = (item) => {
    if(!uploading){
        setUploading(true)
        const formData = new FormData();
        formData.append("fileid", item.fileid)
        formData.append("file", file)
        axios({
            method: 'post',
            url: apiurl+"api/uploadProcessedVideo",
            data: formData,
            headers: {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            onUploadProgress:(data)=>{
                var perc = Math.round((data.loaded / data.total) *100)
                console.log(perc)
                setPercentage(perc)
            }
        })
        .then(function (response) {
            //handle success
            if(response.data.status === "OK"){
                successModal("Video Saved Successfully", "It will be available for students within 5-10 minutes");
                closePop();
                // setTimeout(function(){
                //     window.location.reload()
                // },1000);
            }
        })
        .catch(function (response) {
            errorModal("Something went wrong", response.message);
        });
    }
  }
  const closePop = () => {
    setUploadingPop(null)
    setUploading(false)
    setFile(null)
    setPercentage(0)
  }

  useEffect(() => {
    getRequests(uploadType)
  }, [])

    return(
      <>
        <div className='col-md-8' style={{height: "calc(100vh - 150px)", overflow: "auto"}}>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        {uploadType} Download Requests 
                        {
                            typeCount !== null &&
                            <>
                                ( {typeCount[uploadType]})
                            </>
                        }     

                    </h3>
                    <span className='label bg-green pull-right' onClick={() => changeUploadType(uploadType === "Video" ? "Recording" : "Video")} style={{cursor:"pointer"}}  >
                        {uploadType === "Video" ? "Switch to Recordings" : "Switch to Videos"} ?
                    </span>
                </div>
                <div className='box-body'>
                    <table id="studenttable" className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                <th style={{width:"80px"}} >S.No</th>
                                <th>File Name</th>
                                <th>Total Requests</th>
                                <th>Folder ID</th>
                                <th>File Size & Length</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                downloadrequests.length > 0 &&
                                downloadrequests.map((item, index) => {
                                    var info = JSON.parse(item.file_data)
                                    var bg = colorGroups[ "color" + item.p_id] !== undefined ? colorGroups[ "color" + item.p_id] : ""
                                    if(item.type === "Recording"){
                                        bg = "FFFFFF"
                                    }
                                    return(
                                        uploadType === item.type &&
                                        <tr key={"key" + index} style={{background: "#" + bg + "66"}} >
                                            <td style={{width:"80px"}} >{index + 1}</td>
                                            <td>
                                                <a href={'/admin/assets/' + item.main_parent + '/' + item.p_id} target='_BLANK'>
                                                    {item.name} 
                                                </a>
                                            </td>
                                            <td>{item.total}</td>
                                            <td>{item.p_id}</td>
                                            <td>
                                                {info.org_size} | {info.durationHour}
                                            </td>
                                            <td>
                                                {
                                                    item.uploaded === null ?
                                                        <span className='badge bg-yellow' onClick={() => selectUploading(item)}>
                                                            <i className='fa fa-upload'></i>
                                                        </span>
                                                    :
                                                        <span className='badge bg-green' >
                                                            <i className='fa fa-check'></i>
                                                        </span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {
            uploadingPop !== null &&
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            {uploadingPop.name}
                        </h3>
                        <span className='badge bg-red pull-right' onClick={() => closePop()}><i className='fa fa-times'></i></span>
                    </div>
                    <div className='box-header'>
                        {
                            pathList.length > 0 &&
                            pathList.map((item, index) => {
                                return(
                                    <a key={"key"+index}>{item.name}/ </a>
                                )
                            })
                        }
                    </div>
                    {
                        !uploading &&
                        <div className='box-body'>
                            <input type='file' className='form-control' accept='.mp4' onChange={(e) => setFile(e.target.files[0])} />
                        </div>
                    }
                    <div className='box-footer'>
                        {
                            !uploading ?
                            <button className='btn btn-primary btn-flat btn-block' onClick={() => uploadVideo(uploadingPop)}>Upload Compressed Video</button>
                            :
                            <button className='btn btn-warning btn-flat btn-block' > {percentage < 100 ? "Uploading... " + percentage + "%" : "Processing Video"}</button>
                        }
                    </div>
                </div>
            </div>
        }
      </>
    )
  }
  return (
    <>
      <Sidebar page={Requestedvideospage} permission="Global"/>
    </>
  )
}
