import React, { useEffect, useState } from 'react'

export default function Test() {

  const [loading, setLoading] = useState(true)
  const [movies, setMovies] = useState([])
  const [pageNo , setPageno] = useState(1)
  const [maxpageNo , setMaxPageno] = useState([])
  
  const [name, setName] = useState("")
  const [job, setJob] = useState("")

  const [refresh, setRefresh] = useState("No")

  const getMovies = () => {
    setLoading(true)
    fetch("https://reqres.in/api/users?page="+pageNo, {
      "method": "GET",
      "body": null
      })
      .then(response => response.json())
      .then(response => {
        console.log(response)
        setMovies(response.data)
        
        // var arr = new Array(5).fill('');
        // console.log(arr)

        setMaxPageno(Array(response.total_pages).fill(''))
        setLoading(false)
      })
      .catch(err => {
      });
  }

  const submitForm = (e) => {
    e.preventDefault()
    console.log(name, job)
    fetch("https://reqres.in/api/register", {
        "method": "POST",
        "body": JSON.stringify({
            email: name, 
            password: job
        })
        })
        .then(response => response.json())
        .then(response => {
          console.log(response.error)
        //   alert(response.error)
        })
        .catch(err => {
        });
    }

    const changePage = (page) => {
        setPageno(page)
        getMovies()
        setRefresh(refresh == "No" ? "Yes" : "No")
    }
  // https://reqres.in/api/users
  useEffect(() => {
    getMovies()
  },[])

  return (
    <>

    <form action='' method='POST' onSubmit={(e) => submitForm(e)}>
        <input type={"text"} className="form-control" placeholder='asdas' value={name} onChange={(e) => setName(e.target.value)} />
        <input type={"text"} className="form-control" placeholder='job' value={job} onChange={(e) => setJob(e.target.value)} />
        <input type={"submit"} />
    </form>

      {
        loading ? 
        <p>Loading...</p>
        :
        movies.length > 0 ?
          movies.map((item, index) => {
            var title = item.title
            if(item.title === "Iron Man"){
              title = "Iron Man Donny"
            }
            return(
                <>
              <div key={item.id} style={{width:"100px", height:"200px"}}>
                {
                    <img src={item.avatar} />
                }
                {item.first_name}
                <br />
                {item.last_name}
                <br />
                {item.email}
              </div>
              </>
          )})
        :
          <p>
            Moview Not Found
          </p>
      }
      
        <ul>
            {/* {console.log(maxpageNo)} */}
            {
                maxpageNo.length > 0 &&
                maxpageNo.map((item, index) => (
                    <li onClick={(e) => changePage(index+1)} key={"asd"+index} >
                        Page {index+1}
                    </li>
                ))
            }
        </ul>
    </>
  )
}