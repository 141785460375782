
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Adminprops = () => {
  var adminProps = cookies.get("adminprops");
  return adminProps;
};

export default Adminprops;
  