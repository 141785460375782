import React, { useState, useEffect } from 'react'
import $ from "jquery"
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import CommonFunc from '../includes/Common'
import Loading from '../includes/Loading';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const cookies = new Cookies();
const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

function printimg(files){
    const obj = URL.createObjectURL(files)
    return obj;
}

export default function Commentlist({item, index, postid, items, commentpermission, removepermission}) {
    
    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    const [comments, setComments] = useState(false)
    let getToken = cookies.get('token')
    const [commentsLoading, setCommentsLoading] = useState(false)
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()
    const [refresh, setRefresh] = useState("No")

    const [commentid, setCommentId] = useState("")
    const [commentidLoading, setCommentIdLoading] = useState("")
    const [commentidEditor, setCommentIdEditor] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [comment, setComment] = useState("")
    const [sending, setSending] = useState(false)
    const [commentRemoved, setCommentRemoved] = useState(false)

    const openEditor = (item) => {
        setCommentIdLoading(true)
        setCommentId(item.id)
        setCommentIdEditor(null)
        setTimeout(function(){
            setCommentIdEditor(<CKEditor editor={ ClassicEditor } config={
                { 
                  simpleUpload: { 
                    uploadUrl: 'api/ckeditor/image_upload'
                  }, 
                
                  mention: {
                    feeds: [
                        {
                            marker: '@',
                            feed: (queryText) => getFeedItems(queryText, items),
                            minimumCharacters: 1,
                            itemRenderer: customItemRenderer
                        }
                    ]
                }  
                }} data={comment} onChange={ ( event, editor ) => { const data = editor.getData(); setComment(data) } } />)
            setCommentIdLoading(false)
        }, 100)
    }
    const imagePicked = (e) => {
        setSelectedImage(e.target.files[0])
    }
    
    const fetchComments = (postid, pid) => {
        setCommentsLoading(true)
        fetch(apiurl+"api/commentListAdmin", {
          "method": "POST",
          "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
              postid: postid,
              pid: pid,
              staff: AdminBasics(),
              accecible: 1,
              page_url: window.location.href,
          })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status === "OK"){
                  setComments(response.comments)
                  setRefresh(refresh === "No" ? "Yes" : "No")
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  // alert(response.message)
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              setCommentsLoading(false)
              
          })
          .catch(err => {
            setCommentsLoading(false)  
              
          });
    }


    const addComment = () => {
        if(!sending){
            if(comment !== ""){
                setSending(true)
                
                const formData = new FormData();
                console.log(comment)
                formData.append('postid', postid);
                formData.append('staff', JSON.stringify(AdminBasics()));
                formData.append('pid', item.id);
                formData.append('comment', comment);
                formData.append('accecible', 1);
                formData.append('page_url', window.location.href);

                if(selectedImage !== null) {
                    formData.append('files', selectedImage);
                }
                
                var mentions = [];
                window.$(".mycustomck"+item.id+" [data-userid]").each(function(){
                    mentions.push(window.$(this).attr("data-userid"));
                });
                formData.append('mentions', mentions);
                
                fetch(apiurl+"api/newCommentAdmin", { 
                    "method": "POST",
                    "headers": { 
                        // "Content-Type": "multipart/form-data", 
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": formData
                    })
                    .then(response => response.json()) 
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status === "OK"){
                            successModal("Success", "Comment Added Successfully")
                            setCommentIdLoading(false)
                            setCommentId("")
                            setCommentIdEditor(null)
                            fetchComments(postid, item.id)
                            setRefresh(refresh === "No" ? "Yes" : "No")
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            // alert(response.message)
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                    console.log(i)
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        setSending(false)
                    }) 
                    .catch(err => {
                        console.log(err)
                        setSending(false)
                    }); 
                }
        }
    }

  
    function getFeedItems( queryText, items ) {
  
        // As an example of an asynchronous action, return a promise
        // that resolves after a 100ms timeout.
        // This can be a server request or any sort of delayed action.
        
        return new Promise( resolve => {
            setTimeout( () => {
                const itemsToDisplay = items
                    // Filter out the full list of all items to only those matching the query text.
                    .filter( isItemMatching )
                    // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
                    .slice( 0, 10 );
      
                resolve( itemsToDisplay );
            }, 100 );
        } );
      
        // Filtering function - it uses the `name` and `username` properties of an item to find a match.
        function isItemMatching( item ) {
            // Make the search case-insensitive.
            const searchString = queryText.toLowerCase();
            
            // Include an item in the search results if the name or username includes the current user input.
            return (
                item.name.toLowerCase().includes( searchString ) ||
                item.id.toLowerCase().includes( searchString )
            );
        }
      }
      function customItemRenderer( item ) {
        
        const itemElement = document.createElement( 'div' );
        if(item.avatar === ""){
            itemElement.innerHTML = "<div className='mentionBox' style='display: flex; align-items: center; padding: 10px; border-bottom: 0.5px solid rgba(0,0,0,0.2);'> <div className='mentionIconImage' style='width: 30px; height: 30px; background-color: "+colors[item.init.toLowerCase()]+"29; border-radius: 30px; display: flex; justify-content: center; align-items: center; font-weight: bold; color: "+colors[item.init.toLowerCase()]+"; margin-right: 10px;'> "+item.init.toUpperCase()+" </div> <div className='mentionIconName' style='display: flex; flex-direction: column; line-height: 14px;'> <span className='mentionName' > <strong style='font-weight: bold;'> "+item.name+" </strong> </span> <span className='mentionEmail' style='font-size: 12px;margin-top:-7px' > "+item.email+" </span> </div> </div>"
          }else{
            itemElement.innerHTML = "<div className='mentionBox' style='display: flex; align-items: center; padding: 10px; border-bottom: 0.5px solid rgba(0,0,0,0.2);'> <div className='mentionIconImage' style='width: 30px; height: 30px; background-color: "+colors[item.init.toLowerCase()]+"29; overflow:hidden; border-radius: 30px; display: flex; justify-content: center; align-items: center; font-weight: bold; color: "+colors[item.init.toLowerCase()]+"; margin-right: 10px;'> <img src='"+item.avatar+"' style='width:100%;height:100%;object-fit:cover' /> </div> <div className='mentionIconName' style='display: flex; flex-direction: column; line-height: 14px;'> <span className='mentionName' > <strong style='font-weight: bold;'> "+item.name+" </strong> </span> <span className='mentionEmail' style='font-size: 12px;margin-top:-7px' > "+item.email+" </span> </div> </div>"
          }
        // itemElement.classList.add( 'custom-item' );
        // itemElement.id = `mention-list-item-id-${ item.userId }`;
        // itemElement.textContent = `${ item.name } `;
      
        // const usernameElement = document.createElement( 'span' );
      
        // usernameElement.classList.add( 'custom-item-username' );
        // usernameElement.textContent = item.email;
      
        // itemElement.appendChild( usernameElement );
      
        return itemElement;
      }

      const removeCommentFunc = (item) => {
        warningModal("Loading", "Removing comment...")
        fetch(apiurl+"api/removeComment", { 
            "method": "POST",
            "headers": { 
                "Content-Type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                postid: postid,
                id: item.id,
            })
            })
            .then(response => response.json()) 
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    setCommentRemoved(true)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            }) 
            .catch(err => {
                console.log(err)
            }); 
      }

      const removeComment = (item) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You want to remove this comment?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeCommentFunc(item)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
      }

  return (
    
    !commentRemoved ?
    <div className="box-comment" key={"comment"+index}>
        {
            item.udata.image !== "" && item.udata.image !== null ?
            <img className="img-circle" src={ assets_url + item.udata.image} alt="User Image" />
            :
            <div style={{
            width:"30px", height:"30px", borderRadius:"40px", 
            display:"flex", alignItems:"center", 
            justifyContent:"center", fontSize:16,fontWeight:"bold",
            backgroundColor: colors[item.udata.init.toLowerCase()]+"29",
            color: colors[item.udata.init.toLowerCase()],
            float:"left"
            }} >
            {item.udata.init.toUpperCase()}
            </div>
        }
        
        <div className="comment-text">
            <span className="username" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}} >
                <span style={{display:"flex", alignItems:"center", marginBottom:"5px"}}>
                    <a href={ (item.role === "Student") ? "/admin/student-profile/"+item.uid : ""} target={"_blank"} >
                    {item.udata.name}
                    </a>
                    
                    {
                        item.udata.icon !== "" &&
                    <span style={{display:"inline-block", marginLeft:"5px"}} >
                        <img src={item.udata.icon} className={"vericon"} />
                    </span>
                    }
                    
                    </span>
                    <span>
                        {
                            removepermission &&
                            <span className='badge bg-red pull-right' onClick={() => removeComment(item)} >
                                <i className='fa fa-trash' ></i>
                            </span>
                        }
                        <span className='pull-right' style={{fontSize:"12px", paddingRight:"10px"}} >
                        {item.ago}
                        </span>
                    </span>
                </span>
            <div dangerouslySetInnerHTML={{__html: item.comment}} ></div>
            <br /><br />
        </div>
        
            {
            item.image !== null && item.image.length > 0 &&
            <div className='box-footer no-padding' style={{display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap", margin:"10px 0", borderRadius:10}} >
            {
                item.image.map((item2, index2) => {
                return(
                    <div 
                    key={"images"+index2+item.id} 
                    style={{margin:"10px", borderRadius:"5px", width:"100px", height:"100px", overflow:"hidden"}}
                    data-fancybox="gallery" data-src={assets_url + item2.path}
                    >
                        <img src={assets_url + item2.path} className={"attachment-img-my"} />
                    </div>  
                )
                })
            }
            </div>
            }
            <div className='box-footer' style={{backgroundColor:"transparent"}}>
                <button type="button" onClick={() => fetchComments(postid, item.id)} className="btn btn-default btn-xs pull-right">{item.reply} Replies <i className="fa fa-share"></i></button>
                <button type="button" onClick={() => openEditor(item)} className="btn btn-default btn-xs pull-right"  style={{marginRight:"10px"}} >Reply</button>
            </div>
            {
                commentsLoading ? 
                <div className='box-body' style={{position:"relative"}} >
                    <br /><br />
                    <Loading />
                    <br /><br /><br />
                </div>
                :
                comments !== null && comments.length > 0 &&
                comments.map((item2, index2) => {
                    return(
                        <div className="box-footer box-comments" key={"comment"+index2}  style={{backgroundColor: "rgba(0,0,0,0.05)", padding: "10px", marginBottom:"10px", marginLeft:"40px", minWidth:"500px"}}>
                            <Commentlist 
                            item={item2} 
                            index={index2} 
                            postid={postid}
                            items={items} 
                            commentpermission={commentpermission} 
                            removepermission={removepermission} 
                        />
                        </div>
                    )
                    })
                }

            {
                commentid === item.id && commentpermission &&
                <>
                    <div className={'box-footer mycustomck'+commentid} style={{position:"relative"}}>
                        {
                            commentidLoading ?
                            <>
                                <br /><br />
                                <Loading />
                                <br /><br />
                            </>
                            :
                            commentidEditor
                        }
                    </div>
                    <div className='box-footer no-left-padding no-right-padding'>
                        <div className='col-md-3'>
                            <button className='btn btn-primary btn-block' onClick={(e) => {
                                window.$(e.target).parent().find("input").click()
                            }}>Add Image</button> 
                            <input type={"file"} className="form-control" onChange={(e) => imagePicked(e)} style={{display:"none"}} accept=".png,.jpg,.jpeg,.gif,.svg" />
                        </div>
                        <div className='col-md-3 no-padding' style={{position:"relative"}}>
                            {
                                selectedImage !== null &&
                                <>
                                <div style={{
                                    width:"20px", height:"20px", backgroundColor:"#FFF", borderRadius:"20px", display:"flex",
                                    alignItems:"center", justifyContent:"center", color:"#000", position:"absolute", right:0, top:0, zIndex:9,
                                    cursor:"pointer"
                                }} 
                                onClick={() => setSelectedImage(null)}
                                >
                                    <i className='fa fa-times'></i>
                                </div>
                                <img src={printimg(selectedImage)} className="attachment-img-my" />
                                </>
                            }
                        </div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3' style={{position:"relative"}}>
                            {
                                sending ? 
                                <Loading />
                                :
                                <button className='btn btn-primary btn-block' onClick={() => addComment()}>
                                    Reply
                                </button>
                            }
                        </div>
                    </div>
                </>
            }
        
    </div>
    :
    <p>Comment Removed</p>
  )
}
