import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import Loading from '../includes/Loading';
import moment from 'moment';

const cookies = new Cookies();

export default function Promocode() {
    
  const Promocodepage = (props) => {

    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    var a = 0;
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [showSelect2, setShowSelect2] = useState(true)
    const [promoForm, setPromoForm] = useState({
        id: "", title: "", body: "", thumbnail: "", promo: "", showonhome: "0", status: "1", off: "1", first_perchase: "", no_of_codes: 1, max_discount_val: "1", 
        min_cart_value: "1", valid_from: "",  valid_to: "",  maximum_use_count: "10000", maximum_use_count_for_learner: "1", courses: [],  
    })
    const [filter, setFilter] = useState({
        title: "", course: [], tab: false
    })
    const [segment, setSegment] = useState(0)
    const changeFilter = (col, val) => {
        var temp = filter
        temp[col] = val
        setFilter(temp)
        if(col === "tab"){
            setTimeout(function(){
                window.$("#categoriesfilter").unbind().select2()
            }, 1000)
        }
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const clearForm = () => {
        var temp = promoForm
        temp['id'] = ""
        temp['title'] = ""
        temp['body'] = ""
        temp['thumbnail'] = ""
        temp['promo'] = ""
        temp['showonhome'] = "0"
        temp['status'] = "1"
        temp['off'] = "1"
        temp['first_perchase'] = ""
        temp['no_of_codes'] = "1"
        temp['max_discount_val'] = "1"
        temp['min_cart_value'] = "1"
        temp['valid_from'] = ""
        temp['valid_to'] = ""
        temp['maximum_use_count'] = "10000"
        temp['maximum_use_count_for_learner'] = "1"
        temp['courses'] = ""
        setPromoForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")

    }
    const [ckLoading, setCkLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [promos, setPromos] = useState([])
    const [courses, setCourses] = useState([])
    const [totalArray, setTotalArray] = useState(null)

    const changeVar = (col, val) => {
        var temp = promoForm
        temp[col] = val
        setPromoForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const AddPromo = (e) => {

        e.preventDefault();
        formLoading(e.target)
        const formData = new FormData();
        formData.append('id', promoForm.id);
        formData.append('title', promoForm.title);
        formData.append('body', promoForm.body);
        formData.append('thumbnail', promoForm.thumbnail);
        formData.append('promo', promoForm.promo);
        formData.append('status', promoForm.status);
        formData.append('off', promoForm.off);
        formData.append('showonhome', promoForm.showonhome);
        
        formData.append('first_perchase', promoForm.first_perchase);
        formData.append('no_of_codes', promoForm.no_of_codes);
        formData.append('max_discount_val', promoForm.max_discount_val);
        formData.append('min_cart_value', promoForm.min_cart_value);
        formData.append('valid_from', promoForm.valid_from);
        formData.append('valid_to', promoForm.valid_to);
        formData.append('maximum_use_count', promoForm.maximum_use_count);
        formData.append('maximum_use_count_for_learner', promoForm.maximum_use_count_for_learner);
        formData.append('courses', window.$("#categories").val());
        
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/add_promo", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    $(e.target).trigger("reset");
                    clearForm()
                    setShow(false)
                    get_promos()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    

    const get_promos = () => {
        setLoading(true)
        var temp = filter
        temp['course'] = window.$("#categoriesfilter").val()
        fetch(apiurl+"api/get_promoNew", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    filter: temp
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setPromos(response.promocodes)
                    setCourses(response.courses)
                    setTotalArray(response.totalArray)
                    setTimeout(function(){
                        window.$("#categories").unbind().select2()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
        };

        const editPromo = (item) => {
            var temp = promoForm
            
            temp['id'] = item.id
            temp['title'] = item.title
            temp['body'] = item.body
            temp['promo'] = item.promo
            temp['showonhome'] = (item.showonhome === null) ? "" : item.showonhome
            temp['status'] = item.status
            temp['off'] = item.off
            temp['no_of_codes'] = 1
            temp['first_perchase'] = (item.first_perchase === 0 || item.first_perchase === null || item.first_perchase === "0") ? "" : 1
            temp['max_discount_val'] = item.max_discount_val
            temp['min_cart_value'] = item.min_cart_value
            temp['valid_from'] = item.valid_from_format
            temp['valid_to'] = item.valid_to_format
            temp['maximum_use_count'] = item.maximum_use_count
            temp['maximum_use_count_for_learner'] = item.maximum_use_count_for_learner
            temp['courses'] = (item.courses !== null && item.courses !== "") ? item.courses.split(",") : []
            
            

            setPromoForm(temp)
            setRefresh(refresh === "No" ? "Yes" : "No")
            setShow(true)
            setCkLoading(true)

            setShowSelect2(false)
            setTimeout(function(){
                setShowSelect2(true)
            }, 1000)
            setTimeout(function(){
                window.$("#categories").unbind().select2()
            }, 2000)
            setTimeout(function(){
                setCkLoading(false)
            },1000)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }

        const removePromo = (item) => {
            confirmAlert({
                title: 'Are You Sure?',
                message: "You won't be able to revert this back.",
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => removeThispromo(item)
                },
                {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                }
                ]
            });
        } 

    const removeThispromo = (item) => {
        fetch(apiurl+"api/remove_promo", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: item.id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    get_promos()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const filterForm = (e) => {
        formLoading(e.target)
        e.preventDefault()
        get_promos()    
        setTimeout(function(){
            $(e.target).find(".loaderbody").fadeOut()
        },1000)
    }

    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["S.No", "Title", "Body", "Created At", "Percentage Off", "Promo Code", "Status", "Show On Website/App", "First Purchase", "Maximum Discount Value", 
        "Minimum Cart Value", "Valid From", "Valid To", "Maximum Use Count", "Maximum Use Count for Learners", "Total Discount Clain", "Alloted To 'n' Courses","Used Count" , "Added By Name", "Added By Email", "Added By Contact", "Added By Role"]
        
        var segmentTemp = promos
        var td = [];
        var sn = 1
        for(var j = 0; j < segmentTemp.length; j++){
            var temp = promos[j]
            for(var i = 0;i<temp.length;i++){
                
                var staff = JSON.parse(temp[i].staff)
                moment.locale('en');
                var dt = temp[i].created_at;

                td.push(
                    [
                        sn,
                        temp[i]['title'], temp[i]['body'], moment(dt).format("D-M-Y HH:mm:ss"), temp[i]['off']+"%", temp[i]['promo'], (temp[i]['status'] === 1) ? 'Yes' : "No", 
                        (temp[i]['showonhome'] === 1) ? 'Yes' : "No", (temp[i]['first_perchase'] === 1) ? 'Yes' : "No",  temp[i]['max_discount_val'],
                        temp[i]['min_cart_value'], temp[i]['valid_from'], temp[i]['valid_to'], temp[i]['maximum_use_count'], temp[i]['maximum_use_count_for_learner'], temp[i]['collection'], (temp[i]['courses'] === null) ? 0 : temp[i]['coursescount'],
                        temp[i]['used'], staff.name, staff.email, staff.contact, staff.role
                    ]
                )
                sn++
            }
        }
        console.log(td);
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Promocodes List of ("+td.length+") Entries from all files page"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    useEffect(() => {
        if(a == 0){
            get_promos()
            // get_banners_list()
            a++
        }
     }, [])

     

    return(
    
    <>
    
    {
        show && pagePermissions(props.pagepermissions, "Promocodes", "add_promocode") &&
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        {
                            promoForm.id === "" ? "Add Promo" : "Update Promo"
                        }
                    </h3>
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setShow(false)} >
                        <i className='fa fa-times' ></i>
                    </button>
                </div>
                <form action='' method='POST' onSubmit={AddPromo} >
                    <div className='box-body col-md-4'>
                        <label>Promo Title<span style={{color:"red"}} >*</span></label>        
                        <input type={"text"} className="form-control" required placeholder='e.g WHAT TO DO AFTER CA FOUNDATION EXAMS TILL RESULT' value={promoForm.title} onChange={(e) => changeVar("title", e.target.value)} />
                    </div>
                    <div className='box-body col-md-8'>
                        <label>Promo Body</label>        
                        <input type={"text"} className="form-control" placeholder='e.g What to do after CA foundation exams till result ‘The key is in not spending time but in investing it' value={promoForm.body} onChange={(e) => changeVar("body", e.target.value)} />
                    </div>
                    
                    <div className='box-body no-left-padding no-right-padding'>
                        
                        <div className='box-body col-md-3'>
                            <label>Percentage Off<span style={{color:"red"}} >*</span></label>        
                            <input type={"number"} className="form-control" required placeholder='e.g 10' value={promoForm.off} onChange={(e) => changeVar("off", e.target.value)} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Promo Code <span style={{color:"red"}} >*</span></label>        
                            <input type={"text"} required className="form-control" onChange={(e) => changeVar("promo", e.target.value)} value={promoForm.promo} placeholder={"e.g WELCOME100"} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Status</label>        
                            <select className='form-control' defaultValue={promoForm.status} onChange={(e) => changeVar("status", e.target.value)}  >
                                <option value={"1"} >Publish</option>
                                <option value={"0"} >Un-Publish</option>
                            </select>
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Show On Website/App</label>        
                            <select className='form-control' defaultValue={promoForm.showonhome} onChange={(e) => changeVar("showonhome", e.target.value)}  >
                                <option value={"1"} >Yes</option>
                                <option value={"0"} >No</option>
                            </select>
                        </div>
                    </div>
                    
                    <div className='box-body' style={{paddingTop:"0px", paddingBottom:"0px"}}>
                        <div className=' col-md-3 no-left-padding'>
                            <label>First Purchase? <span style={{color:"red"}} >*</span></label>    
                                
                            <select className='form-control' value={promoForm.first_perchase} onChange={(e) => changeVar("first_perchase", e.target.value)}  >
                                <option value={"1"} >Yes</option>
                                <option value={""} >No</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>No Of Codes <span style={{color:"red"}} >*</span></label>        
                            <input type={"number"} required className="form-control" onChange={(e) => changeVar("no_of_codes", e.target.value)} value={promoForm.no_of_codes} placeholder={"e.g 1"} />
                        </div>
                        <div className=' col-md-3'>
                            <label>Maximum Discount Value <span style={{color:"red"}} >*</span></label>        
                            <input type={"number"} required className="form-control" onChange={(e) => changeVar("max_discount_val", e.target.value)} value={promoForm.max_discount_val} placeholder={"e.g 100"} />
                        </div>
                        <div className=' col-md-3 no-right-padding'>
                            <label>Minimum Cart Value <span style={{color:"red"}} >*</span></label>        
                            <input type={"number"} required className="form-control" onChange={(e) => changeVar("min_cart_value", e.target.value)} value={promoForm.min_cart_value} placeholder={"e.g 100"} />
                        </div>
                    </div>
                    
                    <div className='box-body no-left-padding no-right-padding'>
                        <div className='box-body col-md-3'>
                            <label>Valid From <span style={{color:"red"}} >*</span></label>        
                            <input type={"datetime-local"} required className="form-control" onChange={(e) => changeVar("valid_from", e.target.value)} value={promoForm.valid_from} placeholder={"e.g 1"} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Valid To <span style={{color:"red"}} >*</span></label>        
                            <input type={"datetime-local"} required className="form-control" onChange={(e) => changeVar("valid_to", e.target.value)} value={promoForm.valid_to} placeholder={"e.g 1"} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Maximum Use Count <span style={{color:"red"}} >*</span></label>        
                            <input type={"number"} required className="form-control" onChange={(e) => changeVar("maximum_use_count", e.target.value)} value={promoForm.maximum_use_count} placeholder={"e.g 100"} />
                        </div>
                        <div className='box-body col-md-3'>
                            <label>Maximum Use Count for Learners <span style={{color:"red"}} >*</span></label>        
                            <input type={"number"} required className="form-control" onChange={(e) => changeVar("maximum_use_count_for_learner", e.target.value)} value={promoForm.maximum_use_count_for_learner} placeholder={"e.g 100"} />
                        </div>
                    </div>

                    {
                        showSelect2 &&
                    <div className='box-body'>
                        <label>Select Course <span style={{color:"red"}} >(Leave Blank For All Courses)</span></label>
                        <select className='form-control' id='categories' multiple defaultValue={promoForm.courses} data-placeholder='Select Courses' >
                            {
                                courses.length > 0 &&
                                courses.map((item, index) => {
                                    return(
                                        <option value={item.otherid} key={"index"+index} >{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    }
                    
                    
                    <div className='box-footer'>
                        <button className='btn btn-primary btn-flat btn-block'>
                            {
                                promoForm.id === "" ? "Add Promo" : "Update Promo"
                            }
                            
                        </button>
                    </div>
                </form>
            </div>
        </div>
    }
{
    filter.tab &&
    <div className='col-md-12'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Filter Promocodes     
                </h3>
                    <button className='btn btn-danger btn-flat pull-right' onClick={() => changeFilter("tab", false)} >
                        <i className='fa fa-times' ></i>
                    </button>
            </div>
            <form action='' method='POST' onSubmit={(e) => filterForm(e)}>
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>Title/Body/Promocode</label>
                        <input type='text' className='form-control' placeholder='e.g WELCOME100' value={filter.title} onChange={(e) => changeFilter("title", e.target.value)} />
                    </div>
                    <div className='col-md-7 no-left-padding'>
                        <label>Courses</label>
                        <select className='form-control' id='categoriesfilter' multiple defaultValue={filter.course} data-placeholder='Select Courses' >
                            {
                                courses.length > 0 &&
                                courses.map((item, index) => {
                                    return(
                                        <option value={item.otherid} key={"index"+index} >{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-2 no-left-padding'>
                        <label style={{visibility:"hidden"}}>Title/Body/Promocode</label>
                        <input type='submit' className='form-control btn btn-primary btn-block btn-flat' value={"Filter"}/>
                    </div>
                </div>
            </form>
        </div>
    </div>
  }
    <div className='col-md-12'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Manage promos
                            {
                                totalArray !== null &&
                                <strong>
                                    &nbsp;&nbsp;
                                    ({totalArray.Promocodes}/{totalArray.total})
                                </strong>
                            } 
                </h3>
                {
                    (!show && pagePermissions(props.pagepermissions, "Promocodes", "add_promocode")) &&
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => {
                        clearForm()
                        setShow(true)
                        setCkLoading(true)
                        setTimeout(function(){
                            setCkLoading(false)
                            window.$("#categories").unbind().select2()
                        },1000)
                    }} >
                        <i className='fa fa-plus' ></i> Add New Promo
                    </button>
                }
                {
                    !filter.tab &&
                    <button className='btn btn-success btn-flat pull-right' onClick={() => changeFilter("tab", true)} style={{marginRight:"10px"}} >
                        <i className='fa fa-filter' ></i>
                    </button>
                }
                {
                    !downloading ?
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} style={{marginRight:"10px"}} >
                        Download
                    </button>
                    :
                    <button className='btn btn-warning btn-flat pull-right' style={{marginRight:"10px"}} >
                        Preparing...
                    </button>
                }
            </div>
            <div className='box-body'>
                <table id="studenttable" className="table table-bordered table-hover datatable">
                    <thead>
                        <tr>
                            <th style={{width:"80px"}} >S.No</th>
                            <th>Title & Body</th>
                            <th>Date</th>
                            <th>Code</th>
                            <th>Details</th>
                            <th>Code Used</th>
                            <th>Status</th>
                            <th>Website/App</th>
                            <th>Added by</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            typeof promos[segment] !== "undefined" &&
                            promos[segment].length > 0 &&
                            promos[segment].map((item, index) => {
                                var staff = JSON.parse(item.staff)
                                moment.locale('en');
                                var dt = item.created_at;
                                return(
                                    <tr key={"key"+index} >
                                        <td style={{width:"80px"}} >{index+1}</td>
                                        <td>
                                            <strong>{item.title}</strong><br />
                                            {item.body}
                                        </td>
                                        <td>{moment(dt).format("D-M-Y HH:mm:ss")}</td>
                                        <td>{item.promo}</td>
                                        <td>
                                            <small><strong>First Purchase:</strong> {item.first_perchase}</small><br />
                                            <small><strong>Maximum Discount Value:</strong> {item.max_discount_val}</small><br />
                                            <small><strong>Minimum Cart Value:</strong> {item.min_cart_value}</small><br />
                                            <small><strong>Valid From:</strong> {moment(item.valid_from).format("D-M-Y HH:mm:ss")}</small><br />
                                            <small><strong>Valid To:</strong> {moment(item.valid_to).format("D-M-Y HH:mm:ss")}</small><br />
                                            <small><strong>Maximum Use Count:</strong> {item.maximum_use_count}</small><br />
                                            <small><strong>Maximum Use Count for Learners:</strong> {item.maximum_use_count_for_learner}</small>
                                        </td>
                                        <td>{item.used}</td>
                                        <td>
                                            {
                                                (item.status === "1" || item.status === 1) ?
                                                <span className='badge bg-green'><i className='fa fa-check'></i></span>
                                                :
                                                <span className='badge bg-red'><i className='fa fa-times'></i></span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                (item.showonhome === "1" || item.showonhome === 1) ?
                                                <span className='badge bg-green'><i className='fa fa-check'></i></span>
                                                :
                                                <span className='badge bg-red'><i className='fa fa-times'></i></span>
                                            }
                                        </td>
                                        <td>
                                            <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                            {staff.email}<br />
                                            {staff.contact}
                                        </td>
                                        <td style={{whiteSpace:"nowrap"}} >
                                            {
                                                pagePermissions(props.pagepermissions, "Promocodes", "edit_promocode") &&
                                                <button className='btn btn-primary btn-flat' onClick={() => editPromo(item)} >  
                                                    <i className='fa fa-pencil'></i>
                                                </button>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Promocodes", "remove_promocode") &&
                                                <button className='btn btn-danger btn-flat' onClick={() => removePromo(item)}>
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                    </tbody>
                </table>
            </div>
            <div className='box-footer'>
                <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                    {
                        promos.length > 0 && promos.length <= 10 &&
                        promos.map((item, index) => {
                            const active = index === segment ? " btn-primary" : ""
                            return(
                                <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => setSegment(index)} >
                                    {index+1}
                                </button>
                            )
                        })
                    }
                    {
                        promos.length > 10 &&
                        <div style={{width:"200px", float:"right"}}>
                            <label>Last Page: {promos.length}</label>
                            <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>

    </>
    )
  }
  return (
    <>
      <Sidebar page={Promocodepage} permission="Promocodes"/>
    </>
  )
}
