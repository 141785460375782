import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import apiurl from '../../Global';
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';
const cookies = new Cookies();

export default function Bookstab() {

    const params = useParams()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()

    const [booksLoading, setBooksLoading] = useState(false)
    const [booksSelectedLoading, setBooksSelectedLoading] = useState(false)
    const [books, setBook] = useState([])

    const [selectedBooks, setSelectedBooks] = useState([])
    const [refresh, setRefresh] = useState("No")

    function inArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] == needle) return true;
        }
        return false;
    }

    const getCourseBooks = () => {
        if(!booksSelectedLoading){
            setBooksSelectedLoading(true)
            fetch(apiurl+"api/getCourseBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        courseid: params.id,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setSelectedBooks(response.books)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setBooksSelectedLoading(false)
                })
                .catch(err => { });
        }
    }

    const getBooks = () => {
        if(!booksLoading){
            setBooksLoading(true)
            fetch(apiurl+"api/getBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setBook(response.books)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setBooksLoading(false)
                })
                .catch(err => { });
        }
    }

    const addToList = (item) => {
        var temp = selectedBooks
        temp.push(item.id.toString())
        setSelectedBooks(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const removeFromList = (item) => {
        
        var temp = selectedBooks
        const index = temp.indexOf(item.id.toString());
        if (index > -1) { // only splice array when item is found
            temp.splice(index, 1); // 2nd parameter means remove one item only
        }
        setSelectedBooks(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const searchBook = (id, key) => {
        $("#"+id+" tbody tr").each(function(){
            var name = window.$(this).attr('data-name')
            if(name.includes(key)){
                window.$(this).show()
            }else{
                window.$(this).hide()
            }
        });
    }

    const saveBooks = () => {
        if(!booksLoading){
            setBooksLoading(true)
            fetch(apiurl+"api/saveBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        courseid: params.id,
                        selectedBooks: selectedBooks
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setBooksLoading(false)
                })
                .catch(err => { });
        }
    }

    useEffect(() => {
        getCourseBooks()
        getBooks()
    }, [])

  return (
    <>
        <div className='col-md-6'>
            <div className='box no-border'>
                <div className='box-body'>
                    <input type='text' name='' id='search1' placeholder='e.g Book Name' className='form-control' onChange={(e) => searchBook("bookstable", e.target.value.toLowerCase())} />
                </div>
            </div>
        </div>
        <div className='col-md-6'>
            <div className='box no-border'>
                <div className='box-body'>
                    <input type='text' name='' id='search2' placeholder='e.g Book Name' className='form-control' onChange={(e) => searchBook("selectedbookstable", e.target.value.toLowerCase())} />
                </div>
            </div>
        </div>
        <div className='col-md-6'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Select Books
                    </h3>
                </div>
                
            <div className='box-body'>
                <table id="bookstable" className="table table-bordered table-hover datatable">
                    <thead>
                        <tr>
                            <th style={{width:"80px"}} >S.No</th>
                            <th>Title</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            books.length > 0 &&
                            books.map((item, index) => {
                                return(
                                    !inArray(item.id, selectedBooks) &&
                                    <tr data-name={item.name.toLowerCase()} key={"key"+index} onClick={() => addToList(item)} >
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className='booksCon cursor-pointer' >
                                                <div className='boooksImage'>
                                                    <img src={ assets_url + item.image} alt={item.name} />
                                                </div>
                                                <div>
                                                    <strong>{item.name}</strong>
                                                    <br />
                                                    <small>
                                                        <strong>
                                                            Qty Left:&nbsp; 
                                                        </strong>
                                                        {item.quantity}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <button className='btn btn-success btn-flat'>
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            </div>
        </div>
        <div className='col-md-6'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Selected Books
                    </h3>
                    {
                        booksLoading ? 
                        <button className='btn btn-warning btn-flat pull-right' >Loading...</button>
                        :
                        <button className='btn btn-primary btn-flat pull-right' onClick={() => saveBooks()} >Save</button>
                    }
                    
                </div>
            <div className='box-body'>
                <table id="selectedbookstable" className="table table-bordered table-hover datatable">
                    <thead>
                        <tr>
                            <th style={{width:"80px"}} >S.No</th>
                            <th>Title</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            books.length > 0 &&
                            books.map((item, index) => {
                                return(
                                    inArray(item.id, selectedBooks) &&
                                    <tr data-name={item.name.toLowerCase()} key={"key"+index} onClick={() => removeFromList(item)} >
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className='booksCon cursor-pointer' >
                                                <div className='boooksImage'>
                                                    <img src={ assets_url + item.image} alt={item.name} />
                                                </div>
                                                <div>
                                                    <strong>{item.name}</strong>
                                                    <br />
                                                    <small>
                                                        <strong>
                                                            Qty Left:&nbsp; 
                                                        </strong>
                                                        {item.quantity}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <button className='btn btn-danger btn-flat'>
                                                <i className='fa fa-times'></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </>    
  )
}
