import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
// import Adminprops from '../../VelidateWebsite/Adminprops'
// import { Link, useNavigate } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies();

export default function Themes() {


    const ThemesPage = (props) => {
        
        const navigate = useNavigate()
        let getToken = cookies.get('token')
        const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()

        const [theme_name, setTheme_name] = useState("")
        const [themeId, setThemeId] = useState("")
        const [themeColor, setThemeColor] = useState("#000173")
        const [themeBody, setThemeBody] = useState("#f1f1f7")
        const [themeButton, setThemeButton] = useState("#000173")
        const [themeHeading, setThemeHeading] = useState("#000173")
        const [themeText, setThemeText] = useState("#2A3041")
        const [preview, setPreview] = useState(null)
        
        const [themeBoldText, setThemeBoldText] = useState("#000000")
        const [themeSubBody, setThemeSubBody] = useState("#FFFFFF")
        const [refresh, setRefresh] = useState("No")

        const [themes, setThemes] = useState([])

        const saveTheme = (e) => {
            e.preventDefault()
            formLoading(e.target)
            var url = (themeId === "") ? 'add_theme' : 'update_theme'
            fetch(apiurl+"api/"+url, {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        themeColor: themeColor,
                        themeBody: themeBody,
                        themeButton: themeButton,
                        themeHeading: themeHeading,
                        themeText: themeText,
                        themeBoldText: themeBoldText,
                        themeSubBody: themeSubBody,
                        theme_name: theme_name
                    })
                })
                .then(response => response.json())
                .then(response => {
                    $(e.target).find(".loaderbody").fadeOut()
                    setTimeout(function(){  
                        $(e.target).find(".loaderbody").remove()
                    }, 1000);
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        getThemes()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    // setLoading(false)
                })
                .catch(err => {
                    // console.log(err);
                });

        }
        const getThemes = () => {
            setThemes([])
            fetch(apiurl + "api/getthemes", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    setThemes(response.themes)
                    setTimeout(function(){
                        callSorting()
                    },500)
                })
        }
 
    
    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_themes", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    position_list: array,
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }       
    const callSorting = () => {
        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(event.target).find(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
    }

    
    const removeBanner = (id) => {
        fetch(apiurl+"api/remove_theme", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getThemes()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeBanner(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }

    const changeStatus = (id, target, index) => {
        const temp = themes;
        var newstatus = 0
        if(target.value == 1){
            temp[index].status = 0
        }else{
            temp[index].status = 1
            newstatus = 1
        }
        setThemes(temp)
        setRefresh((refresh == "No") ? "Yes" : "No")
        fetch(apiurl+"api/update_theme_status", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    value: newstatus,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

        var a = 0
        useEffect(() => {
            if( a === 0){
                getThemes()
                a++
            }
        }, [])

        return(
            <>
                {
                    pagePermissions(props.pagepermissions, "Themes", "add_theme") && preview === null &&
                    <div className='col-md-4'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Add New Theme
                                </h3>
                            </div>
                            <form action='' method='POST' onSubmit={(e) => saveTheme(e)}>
                                <div className='box-body'>
                                    <label>Theme Name </label>
                                    <input type={"text"} required className={"form-control"} placeholder={"e.g Dark Mode"} value={theme_name} onChange={(e) => setTheme_name(e.target.value)} />
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Theme Color </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeColor} onChange={(e) => setThemeColor(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeColor} onChange={(e) => setThemeColor(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Body Color </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeBody} onChange={(e) => setThemeBody(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeBody} onChange={(e) => setThemeBody(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Sub Body Color </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeSubBody} onChange={(e) => setThemeSubBody(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeSubBody} onChange={(e) => setThemeSubBody(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Button Color </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeButton} onChange={(e) => setThemeButton(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeButton} onChange={(e) => setThemeButton(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Theme Heading </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeHeading} onChange={(e) => setThemeHeading(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeHeading} onChange={(e) => setThemeHeading(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Theme Text Color </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeText} onChange={(e) => setThemeText(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeText} onChange={(e) => setThemeText(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-body'>
                                    <div className='col-md-10 no-left-padding'>
                                        <label>Theme Bold Text Color </label>
                                        <input type={"text"} className={"form-control"} placeholder={"e.g #FFFFFF"} value={themeBoldText} onChange={(e) => setThemeBoldText(e.target.value)} />
                                    </div>
                                    <div className='col-md-2 no-right-padding'>
                                        <label style={{visibility:"hidden"}}>s</label>
                                        <input type={"color"} className={"form-control no-padding"} value={themeBoldText} onChange={(e) => setThemeBoldText(e.target.value)} />
                                    </div>
                                </div>
                                <div className='box-footer'>
                                    <button className={"btn btn-primary btn-block btn-flat"}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                {
                     pagePermissions(props.pagepermissions, "Themes", "view_theme") &&
                     <div className='col-md-8'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Manage Themes
                                </h3>
                            </div>
                            <div className='box-body' style={{overflow:"auto"}}>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                            </th>
                                            <th>
                                                Theme Name
                                            </th>
                                            <th>
                                                Theme Color
                                            </th>
                                            <th>
                                                Body Color
                                            </th>
                                            <th>
                                                Body Sub Color
                                            </th>
                                            <th>
                                                Button Color
                                            </th>
                                            <th>
                                                Theme Heading
                                            </th>
                                            <th >
                                                Theme Text Color
                                            </th>
                                            <th >
                                                Theme Bold Text Color
                                            </th>
                                            <th>
                                                Status
                                            </th>
                                            <th className='text-center'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='connected-sortable droppable-area1' style={{overflow:'auto'}}>
                                        {
                                            themes.length > 0 ?
                                            themes.map((item, index) => {

                                                return(

                                                    <tr key={"theme"+index} className="draggable-item" data-bind={item.id}>
                                                        <td className='text-center text-center dragHandle' data-bind={item.id}>
                                                            <i className='fa fa-arrows-v'></i>
                                                        </td>
                                                        <td>
                                                            {item.theme_name} 
                                                        </td>
                                                        <td>
                                                            {item.themeColor} <span className='colorImp' style={{backgroundColor:item.themeColor}}></span>
                                                        </td>
                                                        <td>
                                                            {item.themeBody} <span className='colorImp' style={{backgroundColor:item.themeBody}}></span>
                                                        </td>
                                                        <td>
                                                            {item.themeSubBody} <span className='colorImp' style={{backgroundColor:item.themeSubBody}}></span>
                                                        </td>
                                                        <td>
                                                            {item.themeButton} <span className='colorImp' style={{backgroundColor:item.themeButton}}></span>
                                                        </td>
                                                        <td>
                                                            {item.themeHeading} <span className='colorImp' style={{backgroundColor:item.themeHeading}}></span>
                                                        </td>
                                                        <td >
                                                            {item.themeText} <span className='colorImp' style={{backgroundColor:item.themeText}}></span>
                                                        </td>
                                                        <td>
                                                            {item.themeBoldText} <span className='colorImp' style={{backgroundColor:item.themeBoldText}}></span>
                                                        </td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" value={(item.status == 1) ? 1 : 0} defaultChecked={(item.status) ? true : false} onChange={e => changeStatus(item.id, e.target, index)}/>
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td className='text-center' style={{whiteSpace:"nowrap"}} >
                                                            {
                                                                <button className='btn btn-success btn-flat' onClick={e => setPreview(item)} title="Preview Theme">
                                                                    <i className='fa fa-eye'></i>
                                                                </button>
                                                            }
                                                            &nbsp;
                                                            {
                                                                pagePermissions(props.pagepermissions, "Themes", "remove_theme") &&
                                                                <button className='btn btn-danger btn-flat' onClick={e => removeDialog(item.id)} title="Remove Theme">
                                                                    <i className='fa fa-trash'></i>
                                                                </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                    
                                                    )
                                                })
                                            :
                                            <tr>
                                                <td>
                                                    No Themes Found
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                </td>
                                                <td >
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                     </div>
                }
                {
                    preview !== null &&
                    <div className='col-md-4'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Preview
                                </h3>
                                <span className='label bg-green pull-right' onClick={() => setPreview(null)} >
                                    <i className='fa fa-times'></i>
                                </span>
                            </div>
                            <div className='box-body'>
                                <div className='previewBox' style={{backgroundColor:preview.themeBody}}>
                                    <div className='previewMenu' style={{backgroundColor:preview.themeColor}}>
                                        <div className='menubars'>
                                            <i className='fa fa-bars'></i>
                                        </div>
                                        <div className='menuLogo'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/logo.png'} />
                                        </div>
                                        <div className='menubars'>
                                            <i className='fa fa-user'></i>
                                        </div>
                                    </div>
                                    <div className='previewBanner'>
                                        <div className='previewNavsCol'>
                                            <div className='previewNavs' >
                                                <i className='fa fa-arrow-left'></i>
                                            </div>
                                            <div className='previewNavs' >
                                                <i className='fa fa-arrow-right'></i>
                                            </div>
                                        </div>
                                        <div className='previewBannerCon'>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.gif'} />
                                        </div>
                                    </div>
                                    <div className='previewBoxBody'>
                                        <p className='previewHeadings' style={{color: preview.themeHeading}} >Our Categories</p>
                                    </div>
                                    <div className='previewBoxBody' style={{backgroundColor:preview.themeSubBody}} >
                                        <ul className='previewcategories'>
                                            <li style={{backgroundColor:preview.themeButton, color:"#FFF"}} >CA Foundation</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}}>CA Inter</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}}>CA Final</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}}>Class 11</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}}>Books</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}}>CMA Foundation</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}}>CS Foundation</li>
                                        </ul>
                                    </div>
                                    
                                    <div className='previewBoxBody'>
                                        <div className='previewCourseBg' style={{backgroundColor:preview.themeColor+"29"}}>
                                            <p className='previewHeadings text-center' style={{color: preview.themeHeading}} >Our Packages</p>
                                            <br />
                                            <div className='previewCourseContainer' >
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.jpg'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeBoldText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.gif'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.gif'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.jpg'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className='previewButtonContainer'>
                                                <button style={{backgroundColor:preview.themeButton}} >View All &nbsp; <i className='fa fa-arrow-right'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='previewBoxBody'>
                                        <div className='previewCourseBg' style={{backgroundColor:preview.themeColor+"29"}}>
                                            <p className='previewHeadings text-center' style={{color: preview.themeHeading}} >Our Courses</p>
                                            <br />
                                            <div className='previewCourseContainer' >
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.gif'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.jpg'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.jpg'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.gif'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='coursePreview' style={{backgroundColor: preview.themeSubBody}} >
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/dummy.jpg'} />
                                                    <div className='previewCourseNameArea'>
                                                        <p className='previewCourseName' style={{color:preview.themeText}}>Law :CA Inter</p>
                                                        <p className='previewCourseLang' style={{color:preview.themeText}}><i className='fa fa-circle-o'></i> Language: English</p>
                                                    </div>
                                                    <div className='previewCoursePriceArea'>
                                                        <span style={{color:preview.themeHeading}} >
                                                            <strong>
                                                            ₹200
                                                            </strong>
                                                        </span>
                                                        <span style={{color:preview.themeText}}>
                                                            ₹500
                                                        </span>
                                                        <span style={{backgroundColor:preview.themeHeading, color:"#FFF", padding: '5px', borderRadius: '20px', fontSize:"10px"}}>
                                                            50% OFF
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className='previewButtonContainer'>
                                                <button style={{backgroundColor:preview.themeButton}} >View All &nbsp; <i className='fa fa-arrow-right'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className='previewBoxBody' style={{backgroundColor:preview.themeSubBody, marginBottom:0}} >
                                        <ul className='previewcategories privacyPolicyArea'>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}} ><i className='fa fa-circle-o'></i>&nbsp;Privacy Policy</li>
                                            <li style={{backgroundColor:preview.themeBody, color:preview.themeText}} ><i className='fa fa-circle-o'></i>&nbsp;Terms & Conditions</li>
                                        </ul>
                                    </div>
                                    <div className='previewSearch' style={{backgroundColor:preview.themeButton, color:"#FFF"}}>
                                        <i className='fa fa-search'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <>
            <Sidebar page={ThemesPage} permission="Themes"/>
        </>
    )
}
