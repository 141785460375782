import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../../includes/Common';
import Cookies from 'universal-cookie';
import apiurl from '../../../Global';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

const cookies = new Cookies();

export default function Mynotifications(props) {
  
  const navigate = useNavigate()
  const params = useParams()
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
  
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  
  const getUserData = () => {
    setLoading(true)
    fetch(apiurl+"api/user/getUserNotifications", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+props.dummyToken
        },
        "body": JSON.stringify({
            userid: params.userid,
            page: 1,
            count: 100
        })
        })
        .then(response => response.json()) 
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setData(response.list)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            console.log(err)
        });
}

  var a = 0  
  useEffect(() => {
    if(a === 0)    {
      getUserData()
      a++
    }
  }, [])

  return (
    <>
      <div className='col-md-12'>
          <br />
          {
            data !== null && typeof data !== "undefined" && data.length > 0 &&
            data.map((item, index) => {
                moment.locale('en');
                var dt = item.created_at;
                return(
                    <div key={"key"+index} >
                        <div className='box-header'>
                            <h3 className='box-title'>
                                <strong>{ item.day }</strong>
                            </h3>
                        </div>
                    
                        {
                            item.notifications.length > 0 &&
                            item.notifications.map((item2, index2) => {
                                return(
                                    <div className='box no-border' key={"key2"+index2}>
                                        <div className='box no-border'>
                                            <div className='box-header'>
                                                <h3 className='box-title'>
                                                    { item2.title }
                                                </h3>
                                            </div>
                                            <div className='box-body' dangerouslySetInnerHTML={{__html: item2.body}} ></div>
                                        </div>
                                    </div>
                                    
                                    )
                                })
                        }
                    </div>
                )
            })
          }

      </div>
    </>
  )
}
