import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const cookies = new Cookies();

export default function Assetsfaq() {
  const Assetsfaqpage = (props) => {

    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    const [loading, setLoading] = useState(false)
    let getToken = cookies.get('token')
    const [faqList, setFaqList] = useState([])
    const [faqListLoading, setFaqListLoading] = useState(false)
    const [tab, setTab] = useState("tab0")
    const [cats, setCats] = useState([])
    const [viewQuestion, setViewQuestion] = useState(null)
    const [search, setSearch] = useState("")
    const [addQuestions, setAddQuestions] = useState(false)
    
    const [file, setFile] = useState(false)
    const [assetsFaqs, setAssetsFaqs] = useState([])
    const [added, setAdded] = useState([])

    const id = params.id
    
    const fileFaqData = () => {
        warningModal("Loading...", "Fetching Assets File FAQ's...")
        fetch(apiurl+"api/fetchassetsfaq", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                id: id,
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setFile(response.file)
                    setAssetsFaqs(response.list)
                    setAdded(response.added)
                    setTimeout(function(){
                        callSorting()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
            });
    }

    
    
    const addFaqToAsset = (item, getlist) => {
        warningModal("Loading...", "Adding faq to assets file please wait...")
        fetch(apiurl+"api/addFaqToAsset", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                id: id,
                fid: item.id,
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message);
                    setAdded(response.added)
                    if(getlist){
                        fileFaqData()
                    }
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
            });
    }


    const faqData = () => {
        setFaqListLoading(true)
        fetch(apiurl+"api/fetchfaq", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                from: "admin",
                search: search
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setFaqList(response.faqList)
                    setCats(response.cats)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setFaqListLoading(false)
            })
            .catch(err => {
                setFaqListLoading(false)
                // console.log(err);
            });
    }
    


    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_assetsfaq_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });
                    // console.log(array)
                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }
    
    var a = 0
    useEffect(() => {
        if(a === 0) {
            fileFaqData()
            a++
        }
    }, [])

    return(
        <>
            {
                (!addQuestions && file !== null) &&
                    <div className="col-md-12">
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Add 
                                    <strong>
                                        &nbsp;
                                        {file.name} 
                                        &nbsp;
                                    </strong>
                                    FAQ's
                                </h3>
                                <button className='btn btn-primary btn-flat pull-right' onClick={() => {
                                    setAddQuestions(true)
                                    faqData()
                                }}>
                                    <i className='fa fa-plus'></i> Add Questions
                                </button>
                            </div>
                        </div>
                        <div  className='connected-sortable droppable-area1'>
                            {
                                assetsFaqs !== null && assetsFaqs.length > 0 &&
                                assetsFaqs.map((item2, index2) => {
                                    
                                    moment.locale('en');
                                    var ct = item2.created_at;
                                    var ut = item2.updated_at;
                                    var staff = JSON.parse(item2.added_by)
                                    
                                    var question = item2.faqdata.question.replace(/(<img[^>]+>(?:<\/img>)?)/g, "[[IMG]]"); 
                                    question = window.jQuery(question).text();
                                    
                                    return(
                                        <div className='box no-border draggable-item' key={"key"+index2} data-bind={item2.id}>
                                            <div className='box-body'>
                                                <div className="box-header with-border">
                                                    <div className="user-block" style={{display:"flex", alignItems:"center"}}>
                                                        <div style={{width:40, height:40, borderRadius:50, display:"flex", alignItems:"center", justifyContent:"center"}} className='bg-green img-circle'>
                                                            <strong>
                                                                {index2+1}
                                                            </strong>
                                                        </div>
                                                        <div>
                                                            <span className="username" style={{marginLeft:10}}><a href="#">By {staff.name} <small>({staff.role})</small></a></span>
                                                            <span className="description" style={{marginLeft:10}}>Last Modified - {moment(ut).format("D-M-Y")}</span>
                                                        </div>
                                                    </div>
                                                    <div className="box-tools">
                                                        <span data-bind={item2.id} className="btn btn-box-tool dragHandle" title="Move FAQ" >
                                                        <i className='fa fa-arrows-v'></i></span>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-box-tool" data-toggle="tooltip" title="View Question"  onClick={() => setViewQuestion(item2)}>
                                                        <i className="fa fa-eye"></i></button>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-box-tool" onClick={() => addFaqToAsset({id: item2.faqid}, true)}  ><i className="fa fa-times"></i></button>
                                                    </div>
                                                </div>

                                                {
                                                    (viewQuestion === null || viewQuestion.id !== item2.id) ?
                                                        <div className="box-body">
                                                            <p>{question}</p>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="box-body" ><strong>Question</strong></div>
                                                            <div className="box-body" dangerouslySetInnerHTML={{__html: item2.faqdata.question}}></div>
                                                            <div className="box-body" ><strong>Answer</strong></div>
                                                            <div className="box-body" dangerouslySetInnerHTML={{__html: item2.faqdata.answer}}></div>
                                                        </>
                                                }

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
            {
                addQuestions &&
                <>
            <div className="col-md-12">
                <div className='box no-border'>
                    <div className='box-body'>
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" placeholder='e.g CA Foundation 9.0' onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-btn" style={{marginRight:10}}>
                                    <button type="button" className="btn btn-primary btn-flat" style={{marginRight:5}} onClick={() => faqData()}>Search</button>
                                </span>
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-danger btn-flat" onClick={() => setAddQuestions(false)}>
                                        <i className='fa fa-times'></i>
                                    </button>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                        {
                            cats.length > 0 &&
                            cats.map((item, index) => {
                                return(
                                    <li key={"tab"+index} className={tab === "tab"+index ? 'active' : ''} onClick={() => setTab("tab"+index)}><a href={"#tab"+index}  data-toggle="tab" aria-expanded="false">{item.name}</a></li>
                                )
                            })
                        }
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active">
                            <br />
                            {
                                faqListLoading ? 
                                <div className='col-md-12'>
                                    <div className='box no-border'>
                                    <br /><br /><br /><br /><br /><br /><br /><br />
                                        <Loading />
                                    </div>
                                </div>    
                                :
                            <div className='col-md-12'>
                                
                                        {
                                            cats.length > 0 &&
                                            cats.map((item, index) => {
                                                return(
                                                    (tab === "tab"+index) &&
                                                    <>
                                                    {
                                                        item.faqs !== null && item.faqs.length > 0 &&
                                                        item.faqs.map((item2, index2) => {
                                                            
                                                            moment.locale('en');
                                                            var ct = item2.created_at;
                                                            var ut = item2.updated_at;
                                                            var staff = JSON.parse(item2.staff)
                                                            
                                                            var question = item2.question.replace(/(<img[^>]+>(?:<\/img>)?)/g, "[[IMG]]"); 
                                                            question = window.jQuery(question).text();

                                                            var already = false;
                                                            if(added.includes(item2.id)){
                                                                already = true
                                                            }

                                                            return(
                                                                <div className='box no-border' key={"key"+index2}>
                                                                    <div className='box-body'>
                                                                        <div className="box-header with-border">
                                                                            <div className="user-block" style={{display:"flex", alignItems:"center"}}>
                                                                                <div style={{width:40, height:40, borderRadius:50, display:"flex", alignItems:"center", justifyContent:"center"}} className='bg-green img-circle'>
                                                                                    <strong>
                                                                                        {index2+1}
                                                                                    </strong>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="username" style={{marginLeft:10}}><a href="#">By {staff.name} <small>({staff.role})</small></a></span>
                                                                                    <span className="description" style={{marginLeft:10}}>Last Modified - {moment(ut).format("D-M-Y")}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="box-tools">
                                                                                {
                                                                                    !already &&
                                                                                    <button type="button" className="btn btn-box-tool bg-green" data-toggle="tooltip" title="Add Question"  onClick={() => addFaqToAsset(item2, false)}>
                                                                                        <i className='fa fa-plus'></i>&nbsp;
                                                                                        Add Question
                                                                                    </button>
                                                                                }
                                                                                {
                                                                                    already &&
                                                                                    <button type="button" className="btn btn-box-tool bg-red" data-toggle="tooltip" title="Remove Question"  onClick={() => addFaqToAsset(item2, false)}>
                                                                                        <i className='fa fa-trash'></i>&nbsp;
                                                                                        Remove Question
                                                                                    </button>
                                                                                }
                                                                                &nbsp;
                                                                                <button type="button" className="btn btn-box-tool" data-toggle="tooltip" title="View Question"  onClick={() => setViewQuestion(item2)}>
                                                                                <i className="fa fa-eye"></i></button>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            (viewQuestion === null || viewQuestion.id !== item2.id) ?
                                                                                <div className="box-body">
                                                                                    <p>{question}</p>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="box-body" ><strong>Question</strong></div>
                                                                                    <div className="box-body" dangerouslySetInnerHTML={{__html: item2.question}}></div>
                                                                                    <div className="box-body" ><strong>Answer</strong></div>
                                                                                    <div className="box-body" dangerouslySetInnerHTML={{__html: item2.answer}}></div>
                                                                                    <div className="box-body" ><strong>Searching Terms</strong></div>
                                                                                    <div className="box-body" dangerouslySetInnerHTML={{__html: item2.searching}}></div>
                                                                                </>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                )
                                            })
                                        }
                            </div>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
        }
        </>
    )
  }
  return (
    <>
      <Sidebar page={Assetsfaqpage} permission="Assets"/>
    </>
  )
}
