import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Sidebar from '../includes/Sidebar';

const cookies = new Cookies();

function printimg(files){
    const obj = URL.createObjectURL(files)
    return obj;
}

function getCourseName(arr, id){
    
    var filteredArray = arr.filter(function(item){
        return item.otherid.indexOf(id) > -1
    })
    if(filteredArray.length < 1){
        return "Root";
    }else{
        return filteredArray[0]['name'];
    }
}

const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
export default function Reportedquestions() {
  const Reportspage = (props) => {
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    const [loading, setLoading] = useState(false)
    let getToken = cookies.get('token')
    const [courseslist, setCourseslist] = useState([])
    const [files, setFiles] = useState([])
    const [refresh, setRefresh] = useState("No")
    const [segment, setSegment] = useState(0)
    const[viewQuestion, setViewQuestion] = useState(null)
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        user: "",
        modify: "",
        courseid: "",
        sortby: "report",
        sort: "DESC",
        status: "Any",
        filename: "",
        scheduletype: "Any",
        schedulestart: "",
        scheduleend: "",
        classmin: "",
        classmax: "",
    })
    const [filterLoading, setFilterLoading] = useState(false)


    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // if(col !== "chatid"){
            // getGroups()
            // setFilterLoading(true)
        // }
        
    }
      
    const getGroups = (e) => {
        fetch(apiurl+"api/getReportedQuestions", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                filter: filter
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setFiles(response.list)
                    setCourseslist(response.courses)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
                setFilterLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setFilterLoading(false)
                // console.log(err);
            });
    }

    
    const filterFeedbacks = (e) => {
        e.preventDefault();
        getGroups()
        setFilterLoading(true)
    }


    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["File Name", "Path", "Created At", "Updated At", "Schedule Type", "Start Date", "End Date", "Days From", "Access Days", "Class Time"]
        
        var segmentTemp = files
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = files[j]
            for(var i = 0;i<temp.length;i++){
                
                var schedule = (temp[i]['scheduledata'] !== null && temp[i]['scheduledata'] !== "") ? JSON.parse(temp[i]['scheduledata']) : null
                var path = temp[i]['path']
                moment.locale('en');
                var ct = temp[i]['created_at'];
                var ut = temp[i]['updated_at'];
                var pathString = "";
                for(var k = 0;k < path.length; k++){
                    pathString += path['name']+"/ "
                }

                td.push(
                    [
                        temp[i]['name'], pathString, moment(ct).format("D-M-Y HH:mm:ss"), moment(ut).format("D-M-Y HH:mm:ss"), schedule !== null ? schedule.type : "-", 
                        (schedule !== null && schedule.startdate !== undefined) ? moment(schedule.startdate).format("D-M-Y HH:mm:ss") : "-", 
                        (schedule !== null && schedule.enddate !== undefined) ? moment(schedule.enddate).format("D-M-Y HH:mm:ss") : "-", 
                        (schedule !== null && schedule.daysFrom !== undefined) ? schedule.daysFrom : "-", 
                        (schedule !== null && schedule.accessDays !== undefined) ? schedule.accessDays : "-", 
                        temp[i]['classtime']
                    ]
                )
            }
        }
        console.log(td);
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download "+params.type+" List of ("+td.length+") Entries from all files page"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            user: "",
            modify: "",
            courseid: "",
            sortby: "report",
            sort: "DESC",
            status: "Any",
            filename: "",
            scheduletype: "Any",
            schedulestart: "",
            scheduleend: "",
            classmin: "",
            classmax: "",
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }


    var a = 0
    useEffect(() => {
        if(a === 0) {
            setLoading(true)
            getGroups()
            a++
        }
    }, [])

    return(
        <>  


{
                viewQuestion !== null &&
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Question No 
                            </h3>
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => setViewQuestion(null)} >
                                <i className='fa fa-times'></i>
                            </button>
                        </div>
                    </div>
                    {
                        viewQuestion[1].essay !== null && viewQuestion[1].essay !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Essay/Comprehensive
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].essay}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].question !== null && viewQuestion[1].question !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Question
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].question}} ></div>
                            </div>
                    }
                    {
                        [...Array(6)].map((item, index) => {
                            return(
                                viewQuestion[1]['option'+(index+1)] !== null && viewQuestion[1]['option'+(index+1)] !== "" &&
                                <div className='box no-border' key={"key" + index}>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Option {(index+1)}
                                        </h3>
                                    </div>
                                    <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1]['option'+(index+1)]}} ></div>
                                </div>
                            )
                        })
                    }
                    {
                        viewQuestion[1].explanation !== null && viewQuestion[1].explanation !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Explanation
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].explanation}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].subject !== null && viewQuestion[1].subject !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Subject
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].subject}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].topic !== null && viewQuestion[1].topic !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Topics
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].topic}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].tags !== null && viewQuestion[1].tags !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Tags
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].tags}} ></div>
                            </div>
                    }
                        <div className='col-md-3 no-left-padding'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        (+ve) Marks
                                    </h3>
                                </div>
                                <div className='box-body'>{viewQuestion[1].posMarks}</div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        (-ve) Marks
                                    </h3>
                                </div>
                                <div className='box-body'>{viewQuestion[1].negMarks}</div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Answer Type
                                    </h3>
                                </div>
                                <div className='box-body'>{viewQuestion[1].answerType}</div>
                            </div>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Answer
                                    </h3>
                                </div>
                                <div className='box-body'>
                                    {
                                        viewQuestion[2] === "" ?
                                        "-"
                                        :
                                        viewQuestion[2].join(',')
                                    }    
                                </div>
                            </div>
                        </div>
                        

                </div>
            }

            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filter Files
                            <br />
                            
                            {
                                filter.start === "" && filter.end === "" &&
                                <small style={{"color": "red"}}>
                                    &nbsp;&nbsp;&nbsp;You are currently viewing data of last 7 days.
                                </small>
                            }
                        </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                        <div style={{float:"right", overflow:"hidden", color:"red"}} >
                            {
                                filterLoading  &&  "Applying Filters..."
                            }
                        </div>
                    </div>
                    <form action='' method='POST' onSubmit={(e) => filterFeedbacks(e)} >
                        <div style={{overflow:"hidden"}}>
                            <div className='box-body col-md-12'>
                                <label>Select Course</label>
                                <select className='form-control' defaultValue={filter.courseid} onChange={(e) => changeValue(e.target.value, "courseid")} >
                                    <option value={""}>Any</option>
                                    {
                                        courseslist.length > 0 &&
                                        courseslist.map((item, index) => {
                                            return(
                                                <option key={"op"+index} value={item.otherid.toString()}>{item.name}</option>
                                            )
                                        })
                                    }
                                    
                                </select>
                            </div>
                      
                            <div className='box-body col-md-12'>
                                <label>Search</label>
                                <input type={"text"} className={"form-control"} value={filter.filename} onChange={(e) => changeValue(e.target.value, "filename")} placeholder={"e.g Notes"} />
                            </div>  
                            <div className='box-body col-md-6'>
                                <label>Report Date (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Report Date (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Sort By</label>
                                <select className='form-control' defaultValue={filter.sortby} onChange={(e) => changeValue(e.target.value, "sortby")} >
                                    <option value={"report"}>Reported On</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Order</label>
                                <select className='form-control' defaultValue={filter.sort} onChange={(e) => changeValue(e.target.value, "sort")} >
                                    <option value={"ASC"}>Ascending Order</option>
                                    <option value={"DESC"}>Descending Order</option>
                                </select>
                            </div>
                            <div className="box-footer">
                                <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={"Filter Now"} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                        { params.type } List
                        </h3>
                        {
                            // !downloading ?
                            // <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                            //     Download
                            // </button>
                            // :
                            // <button className='btn btn-warning btn-flat pull-right' >
                            //     Preparing...
                            // </button>
                        }
                    </div>
                    <div className='box-body'>
                        <table id="staff_table" className="table file_manager_table">
                            <thead>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th>
                                        Name & Path
                                    </th>
                                    <th>
                                        Created At
                                    </th>
                                    <th>
                                        Reported By
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    typeof files[segment] !== "undefined" &&
                                    files[segment].length > 0 &&
                                    files[segment].map((item, index) => {
                                        var quizdata = JSON.parse(item.quizdata)
                                        var ques = (item.quizdata !== null) ? JSON.parse(item.quizdata) : []
                                        var ansType = (item.correct !== null) ? JSON.parse(item.correct) : ""
                                        // console.log(ansType)
                                        var myarr = [item, ques, ansType]
                                        moment.locale('en');
                                        var ct = item.created_at;
                                        return(
                                            <tr key={"index" + index} >
                                                <td>
                                                    {(index+1)}
                                                </td>
                                                <td>
                                                    <div dangerouslySetInnerHTML={{__html:quizdata.question}} ></div>
                                                    
                                                    <a href={'/admin/course-content/'+item.course_id+'/0'} target="_blank" >
                                                        <strong>({getCourseName(courseslist, item.course_id)}): &nbsp;&nbsp;</strong>
                                                    </a>
                                                    {
                                                        item.path.length > 0 &&
                                                        item.path.map((item2, index2) => {
                                                            var createlink = false
                                                            if(item.path.type === "Folder"){ 
                                                                createlink = true 
                                                            }else{
                                                                if((index2+1) !== item.path.length){
                                                                    createlink = true 
                                                                }
                                                            }
                                                            return(
                                                                createlink ?
                                                                    <a href={'/admin/course-content/'+item.course_id+'/'+item2.id} key={"course"+index2} target={"_blank"}>
                                                                        {item2.name}/ &nbsp;
                                                                    </a>
                                                                :
                                                                <span key={"course"+index2}>
                                                                    { item2.name }
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    {new Date(item.report * 1000).toLocaleString()}
                                                    {/* {moment(ct).format("D-M-Y HH:mm:ss")} */}
                                                </td>
                                                <td>
                                                    <strong>
                                                        {item.userdata.name}
                                                    </strong>
                                                    <br />
                                                    <a href={'mailto:'+item.userdata.email} >
                                                        {item.userdata.email}
                                                    </a>
                                                    <br />
                                                    <a href={'tel:'+item.userdata.contact} >
                                                        {item.userdata.contact}
                                                    </a>
                                                </td>
                                                <td>
                                                    <button className='btn btn-primary btn-flat' onClick={() => {
                                                        setViewQuestion(myarr)
                                                        document.body.scrollTop = document.documentElement.scrollTop = 0;

                                                        }}>
                                                        <i className='fa fa-eye'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='box-footer'>
                        <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                            {
                                files.length > 0 && files.length <= 10 &&
                                files.map((item, index) => {
                                    const active = index === segment ? " btn-primary" : ""
                                    return(
                                        <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => setSegment(index)} >
                                            {index+1}
                                        </button>
                                    )
                                })
                            }
                            {
                                files.length > 10 &&
                                <div style={{width:"200px", float:"right"}}>
                                    <label>Last Page: {files.length}</label>
                                    <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
  }
  return (
    <>
      <Sidebar page={Reportspage} permission="Reported_Questions"/>
    </>
  )
}
