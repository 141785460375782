import React, { useState, useEffect } from 'react'
import $ from "jquery"

import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import Loading from '../includes/Loading';
import Commentlist from './Commentlist';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const cookies = new Cookies();

function printimg(files){
  const obj = URL.createObjectURL(files)
  return obj;
}


const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
export default function Addforum() {
  const Addforumpage = (props) => {

    
    const query = useQuery();
    
    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()
    const [printCk, setPrintCk] = useState(null)
    const [ckdata, setCkdata] = useState("")

    const [forumLoading, setForumLoading] = useState(false)
    const [lastpage, setLastpage] = useState(99)
    const [page, setPage] = useState({page: 1})
    const [forum, setForum] = useState([])
    const [atEnd, setAtEnd] = useState(false)
    const [refresh, setRefresh] = useState("No")

    const [comments, setComments] = useState([])
    const [commentsLoading, setCommentsLoading] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [postid, setPostId] = useState(null)
    const [pid, setPid] = useState(0)

    
    const [selectedImage, setSelectedImage] = useState(null)
    const [comment, setComment] = useState("")
    const [sending, setSending] = useState(false)


    const [selectedImageAdd, setSelectedImageAdd] = useState(null)
    const [selectedImageThumb, setSelectedImageThumb] = useState(null)
    const [commentAdd, setCommentAdd] = useState("")
    const [sendingAdd, setSendingAdd] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    
    
    const [items, setItems] = useState([])
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        pin: "",
        hide: "",
        role: "",
        userid: "",
        user: "",
        search_in_post: "",
        attachments: "",
        postid: ( typeof query.get("postid") !== "undefined" && query.get("postid") !== null) ? query.get("postid") : "",
    })

    const [filterLoading, setFilterLoading] = useState(false)
    const [totalform, setTotalform] = useState(0)
    const [formCount, setFormCount] = useState(0)

    const imagePicked = (e) => {
      setSelectedImage(e.target.files[0])
    }
    const fetchForum = (page) => {
      fetch(apiurl+"api/forumlistAdmin", {
        "method": "POST",
        "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            userid: admin.id,
            mine: false,
            page: page,
            count: 10,
            staff: AdminBasics(),
            accecible: 1,
            page_url: window.location.href,
            filter: filter
        })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
              
                if(page === 1){
                  setForum(response.list)
                  setItems(response.userList)
                }else{
                  setForum(prev => ([...prev, ...response.list]))
                }
                setLastpage(response.lastpage)
                setTimeout(function(){
                  window.$('#scrollForum').unbind().on('scroll', function() {
                    if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
                        callEnd(response.lastpage)
                    }
                  })
                }, 2000);
                setTotalform(response.totalform)
                setFormCount(response.total)
                setRefresh(refresh === "No" ? "Yes" : "No")
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            
            setTimeout(function(){
              if(window.$(".select2-selection").length > 0){
                window.$("select.select2-hidden-accessible").select2('destroy');
              }
              window.$(".select2").each(function(){
                  var id = window.$(this).attr("id")
                  window.$("#"+id).select2()
              });
          }, 500) 

            setForumLoading(false)
            setAtEnd(false)
            setFilterLoading(false)
        })
        .catch(err => {
            setForumLoading(false)  
            setAtEnd(false)
            setFilterLoading(false)
        });
    }
    const callEnd = (endpage) => {
      
      var temp = page
      temp['page'] = temp['page'] + 1

      if(temp['page'] <= endpage){
        setPage(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        setAtEnd(true)
        fetchForum(temp['page'])
      }else{
        setAtEnd(false)
      }
    }

    const fetchComments = (postid, pid) => {
      
        fetch(apiurl+"api/commentListAdmin", {
          "method": "POST",
          "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
              postid: postid,
              pid: pid,
              staff: AdminBasics(),
              accecible: 1,
              page_url: window.location.href,
          })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status === "OK"){
                  setComments(response.comments)
                  setRefresh(refresh === "No" ? "Yes" : "No")
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  // alert(response.message)
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              setCommentsLoading(false)
              setShowComments(true)
          })
          .catch(err => {
            setCommentsLoading(false)  
              
          });
    }
    const getComments = (item, pid) => {
      var postid = item.postid
      if(!commentsLoading){
        setPostId(postid)
        setPid(pid)
        setCommentsLoading(true)
        fetchComments(postid, pid)
      }
    }
    
    const addComment = () => {
      if(!sending){
          if(comment !== ""){
              setSending(true)
              
              const formData = new FormData();
              formData.append('postid', postid);
              formData.append('staff', JSON.stringify(AdminBasics()));
              formData.append('pid', 0);
              formData.append('comment', comment);
              formData.append('accecible', 1);
              formData.append('page_url', window.location.href);

              if(selectedImage !== null) {
                  formData.append('files', selectedImage);
              }
              
              var mentions = [];
              window.$(".myck0 [data-userid]").each(function(){
                mentions.push(window.$(this).attr("data-userid"));
              });
              formData.append('mentions[]', mentions);
              
              fetch(apiurl+"api/newCommentAdmin", { 
                  "method": "POST",
                  "headers": { 
                      // "Content-Type": "multipart/form-data", 
                      "accept": "application/json",
                      "Authorization" : "Bearer "+getToken
                  },
                  "body": formData
                  })
                  .then(response => response.json()) 
                  .then(response => {
                      if(typeof response.status !== "undefined" && response.status === "OK"){
                          successModal("Success", "Comment Added Successfully")
                          setComment("")
                          setSelectedImage(null)
                          fetchComments(postid, 0)
                          setRefresh(refresh === "No" ? "Yes" : "No")
                      }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                          // alert(response.message)
                          if(typeof response.errors !== "undefined"){
                              var i = 0
                              $.each(response.errors, function(index, item){
                                  setTimeout(function(){
                                      errorModal("Oops Something Went Wrong", item[0])
                                  }, i*1000)
                                  i++
                                  console.log(i)
                              });
                          }else{
                              errorModal("Oops Something Went Wrong", response.message)
                          }
                      }
                      setSending(false)
                  }) 
                  .catch(err => {
                      console.log(err)
                      setSending(false)
                  }); 
              }
      }
  }

  const addPost = () => {
    // getAppNotificationsUsers("In-App", "getInappNotificationsUsers", "1720873194")
      if(!sendingAdd){
        if(commentAdd !== ""){
          setSendingAdd(true)
          
          const formData = new FormData();
          formData.append('staff', JSON.stringify(AdminBasics()));
          formData.append('accecible', 1);
          formData.append('page_url', window.location.href);
          formData.append('comment', commentAdd);
          if(selectedImageThumb !== null) {
            for(var i = 0;i<selectedImageThumb.length;i++){
              formData.append('files[]', selectedImageAdd[selectedImageThumb[i]]);
            }
          }

          var mentions = [];
          window.$(".myckcon [data-userid]").each(function(){
              mentions.push(window.$(this).attr("data-userid"));
          });
          formData.append('mentions[]', mentions);
          // formData.append('notifyvia', window.$("#select2").val());
          
          fetch(apiurl+"api/addforumAdmin", { 
              "method": "POST",
              "headers": { 
                  // "Content-Type": "multipart/form-data", 
                  "accept": "application/json",
                  "Authorization" : "Bearer "+getToken
              },
              "body": formData
              })
              .then(response => response.json()) 
              .then(response => {
                  if(typeof response.status !== "undefined" && response.status === "OK"){
                      successModal("Success", response.message)
                      setCommentAdd("")
                      setSelectedImageAdd(null)
                      setSelectedImageThumb(null)
                      setPage(1)
                      fetchForum(1)
                      setRefresh(refresh === "No" ? "Yes" : "No")
                      if(notifyVia === "Yes"){
                        getAppNotificationsUsers("In-App", "getInappNotificationsUsers", response.postid)
                      }else{
                        setSendingAdd(false)
                      }
                  }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                      // alert(response.message)
                      if(typeof response.errors !== "undefined"){
                          var i = 0
                          $.each(response.errors, function(index, item){
                              setTimeout(function(){
                                  errorModal("Oops Something Went Wrong", item[0])
                              }, i*1000)
                              i++
                              console.log(i)
                          });
                      }else{
                          errorModal("Oops Something Went Wrong", response.message)
                      }
                      setSendingAdd(false)
                  }
                  
              }) 
              .catch(err => {
                  console.log(err)
                  setSendingAdd(false)
              }); 
          }else{
            warningModal("Oops!!!", "Please Write Something")
          }
      }
  }

  const [sendNotifs, setSendNotifs] = useState(false)
  const [appNotifs, setAppNotifs] = useState(true)
  const [slots, setSlots] = useState("")
  const [slotsTab, setSlotsTab] = useState("App")
  const [webNotifs, setWebNotifs] = useState(true)
  const [inappNotifs, setInAppNotifs] = useState(true)
  const [notifyVia, setNotifyVia] = useState("Yes")
  
  const getAppNotificationsUsers = (type, slug, postid) => {
      setSendNotifs(true)
      fetch(apiurl+"api/"+slug, {
        "method": "POST",
        "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            staff: AdminBasics(),
            accecible: 1,
            page_url: window.location.href,
            postid: postid
        })
        })
        .then(response => response.json())
        .then(response => {
          sendNotificationsNow(type, response.list, 0, postid)
        })
  }

  const sendNotificationsNow = (type, list, index, postid) => {
    setSlots("Sending " + (index + 1) + " Out Of " + list.length + " Slots")
    setSlotsTab(type)
    fetch(apiurl+"api/sendForumNotificationsNow", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
          staff: AdminBasics(),
          accecible: 1,
          page_url: window.location.href,
          type: type,
          list: list[index],
          postid: postid
      })
      })
      .then(response => response.json())
      .then(response => {
        if(type === "App"){
          if(list[(index + 1)] !== undefined){ sendNotificationsNow("App", list, (index+1), postid) }else{ 
            setAppNotifs("DONE")
            getAppNotificationsUsers("Web", "getWebNotificationsUsers", postid)
           }
        } 
        else if(type === "Web"){
          if(list[(index + 1)] !== undefined){ sendNotificationsNow("Web", list, (index+1), postid) }else{ 
            setWebNotifs("DONE")
            setSendingAdd(false)
           }
        }    
        else if(type === "In-App"){
          if(list[(index + 1)] !== undefined){ sendNotificationsNow("In-App", list, (index+1), postid) }else{ 
            setInAppNotifs("DONE")
            getAppNotificationsUsers("App", "getAppNotificationsUsers", postid)
           }
        }    
        
        if(typeof response.message !== "undefined" && response.status !== "OK"){
            // alert(response.message)
            if(typeof response.errors !== "undefined"){
                var i = 0
                $.each(response.errors, function(index, item){
                    setTimeout(function(){
                        errorModal("Oops Something Went Wrong", item[0])
                    }, i*1000)
                    i++
                    console.log(i)
                });
            }else{
                errorModal("Oops Something Went Wrong", response.message)
            }
            setSendingAdd(false)
        }
      })
    
  }

  const showSelectedImages = (e) => {
    var temp = e.target.files
    var temp2 = []
    for(var i=0;i<temp.length;i++){
      temp2.push(i)
    }
    setSelectedImageThumb(temp2)
    setSelectedImageAdd(temp)
  }
  const removeSelection = (index) => {
    var temp = selectedImageThumb
    
    temp.splice(index, 1) 
    setSelectedImageThumb( (temp.length > 0) ? temp : null)
    
    setRefresh(refresh === "No" ? "Yes" : "No")
  }


const userList = async (name) => {
  const resp = await fetch(apiurl+"api/getForumStudents", {
    "method": "POST",
    "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "Authorization" : "Bearer "+getToken
    },
    "body": JSON.stringify({
            accecible: 1,
            page_url: window.location.href,
            staff: AdminBasics(),
            name: name
        })
    })
    .then(response => response.json())
    
    if(resp.status === "OK"){
      return resp.users;
    }
    return [];
}
  
async function getFeedItems( queryText) {
  
  // As an example of an asynchronous action, return a promise
  // that resolves after a 100ms timeout.
  // This can be a server request or any sort of delayed action.
  
  // items = []


  return new Promise( resolve => {
      setTimeout( async () => {
        
        // const itemsToDisplay = items
        const items = await userList(queryText.toLowerCase())
        const itemsToDisplay = items
              // Filter out the full list of all items to only those matching the query text.
              .filter( isItemMatching )
              // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
              .slice( 0, 10 );
          resolve( itemsToDisplay );
      }, 100 );
  } );

  // Filtering function - it uses the `name` and `username` properties of an item to find a match.
  function isItemMatching( item ) {
      // Make the search case-insensitive.
      const searchString = queryText.toLowerCase();
      
      // Include an item in the search results if the name or username includes the current user input.
      return (
          item.email.toLowerCase().includes( searchString ) ||
          item.id.toLowerCase().includes( searchString )
      );
  }
}
function customItemRenderer( item ) {
  
  const itemElement = document.createElement( 'div' );

  // const div = document.createElement('div');

  // div.style.width="30px";
  // div.style.height="30px";
  // div.style.borderRadius="30px";
  // div.style.backgroundColor="red";
  if(item.avatar === ""){
    itemElement.innerHTML = "<div className='mentionBox' style='display: flex; align-items: center; padding: 10px; border-bottom: 0.5px solid rgba(0,0,0,0.2);'> <div className='mentionIconImage' style='width: 30px; height: 30px; background-color: "+colors[item.init.toLowerCase()]+"29; border-radius: 30px; display: flex; justify-content: center; align-items: center; font-weight: bold; color: "+colors[item.init.toLowerCase()]+"; margin-right: 10px;'> "+item.init.toUpperCase()+" </div> <div className='mentionIconName' style='display: flex; flex-direction: column; line-height: 14px;'> <span className='mentionName' > <strong style='font-weight: bold;'> "+item.name+" </strong> </span> <span className='mentionEmail' style='font-size: 12px;margin-top:-7px' > "+item.email+" </span> </div> </div>"
  }else{
    itemElement.innerHTML = "<div className='mentionBox' style='display: flex; align-items: center; padding: 10px; border-bottom: 0.5px solid rgba(0,0,0,0.2);'> <div className='mentionIconImage' style='width: 30px; height: 30px; background-color: "+colors[item.init.toLowerCase()]+"29; overflow:hidden; border-radius: 30px; display: flex; justify-content: center; align-items: center; font-weight: bold; color: "+colors[item.init.toLowerCase()]+"; margin-right: 10px;'> <img src='"+item.avatar+"' style='width:100%;height:100%;object-fit:cover' /> </div> <div className='mentionIconName' style='display: flex; flex-direction: column; line-height: 14px;'> <span className='mentionName' > <strong style='font-weight: bold;'> "+item.name+" </strong> </span> <span className='mentionEmail' style='font-size: 12px;margin-top:-7px' > "+item.email+" </span> </div> </div>"
  }

  // itemElement.classList.add( 'custom-item' );
  // itemElement.id = `mention-list-item-id-${ item.userId }`;
  // itemElement.textContent = `${ item.name } `;

  // const usernameElement = document.createElement( 'span' );

  // usernameElement.classList.add( 'custom-item-username' );
  // usernameElement.textContent = item.email;

  // itemElement.appendChild( usernameElement );

  return itemElement;
}

const hideUnhide = (x, postid, col) => {
  warningModal("Loading", "Updating post...")
  fetch(apiurl+"api/hideUnhidePost", { 
      "method": "POST",
      "headers": { 
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
        staff: AdminBasics(),
        accecible: 1,
        page_url: window.location.href,
        postid: postid,
        showhide: x,
        col: col
      })
      })
      .then(response => response.json()) 
      .then(response => {
          if(typeof response.status !== "undefined" && response.status === "OK"){
              successModal("Success", response.message)
              fetchForum(1)
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              // alert(response.message)
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                      console.log(i)
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
          setSendingAdd(false)
      }) 
      .catch(err => {
          console.log(err)
          setSendingAdd(false)
      }); 
  
}


const removePostFunc = (postid) => {
  warningModal("Loading", "Removing post...")
  fetch(apiurl+"api/removePost", { 
      "method": "POST",
      "headers": { 
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
      },
      "body": JSON.stringify({
        staff: AdminBasics(),
        accecible: 1,
        page_url: window.location.href,
        postid: postid,
      })
      })
      .then(response => response.json()) 
      .then(response => {
          if(typeof response.status !== "undefined" && response.status === "OK"){
              successModal("Success", response.message)
              fetchForum(1)
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              // alert(response.message)
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                      console.log(i)
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
      }) 
      .catch(err => {
          console.log(err)
      }); 
  
}

function MentionCustomization( editor ) {
  // The upcast converter will convert view <a className="mention" href="" data-user-id="">
  // elements to the model 'mention' text attribute.
  editor.conversion.for( 'upcast' ).elementToAttribute( {
      view: {
          name: 'span',
          key: 'data-mention',
          classes: 'mention',
          attributes: {
              'data-user-id': true
          }
      },
      model: {
          key: 'mention',
          value: viewItem => {
              // The mention feature expects that the mention attribute value
              // in the model is a plain object with a set of additional attributes.
              // In order to create a proper object use the toMentionAttribute() helper method:
              const mentionAttribute = editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
                  // Add any other properties that you need.
                  // link: viewItem.getAttribute( 'href' ),
                  userId: viewItem.getAttribute( 'data-user-id' )
              } );

              return mentionAttribute;
          }
      },
      converterPriority: 'high'
  } );

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for( 'downcast' ).attributeToElement( {
      model: 'mention',
      view: ( modelAttributeValue, { writer } ) => {
          // Do not convert empty attributes (lack of value means no mention).
          if ( !modelAttributeValue ) {
              return;
          }

          return writer.createAttributeElement( 'a', {
              class: 'mention',
              'data-mention': modelAttributeValue.id,
              'data-userid': modelAttributeValue.userId,
          }, {
              // Make mention attribute to be wrapped by other attribute elements.
              priority: 20,
              // Prevent merging mentions together.
              id: modelAttributeValue.uid
          } );
      },
      converterPriority: 'high'
  } );
}
const removePost = (postid) => {
  
    confirmAlert({
        title: 'Are You Sure?',
        message: "You want to remove this post?",
        buttons: [
          {
            label: 'Yes',
            onClick: () => removePostFunc(postid)
          },
          {
            label: 'No',
            onClick: () => console.log("Canceled")
          }
        ]
    });
}

const changeValue = (value, col) => {
  var temp = filter
  temp[col] = value
  setFilter(temp)
  setRefresh(refresh === "Yes" ? "No" : "Yes")
  // if(col === "tab"){
    // setFilterLoading(true)
    //   fetchForum(1)
  // }
  
}



const clearFilter = (e) => {
  var temp = filter
  temp = {
    start: filter.start,
    end: filter.end,
    pin: "",
    hide: "",
    role: "",
    userid: "",
    user: "",
    attachments: "",
    postid: ( typeof query.get("postid") !== "undefined" && query.get("postid") !== null) ? query.get("postid") : "",
}
  setFilter(temp)
  // filterFeedbacks(e)
  setRefresh(refresh === 'No' ? "Yes" : "No")
}
  const filterForum = (e) => {
    e.preventDefault();
    fetchForum(1)
  }
    var b = 0
    useEffect(() => {
      if(b === 0){
        setForumLoading(true)
        fetchForum(1)
        
        // setPrintCk(null)
        // setPrintCk(<CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: 'api/ckeditor/image_upload', }, }} data={"<p>Hello</p>"} onChange={ ( event, editor ) => { const data = editor.getData(); setCkdata(data) } } />)    
        b++
      }
    }, [])


    return(
      <>

      {
        sendNotifs &&
        <div className='col-md-12 no-padding' >
        <>
        {
          inappNotifs === true &&
              <div className='col-md-4'>
                  <div className="alert alert-warning alert-dismissible">
                    <h4><i className="icon fa fa-warning"></i> Sending In App Notifications...!</h4>
                    {
                      slotsTab === "In-App" &&
                      <p>{slots}</p>
                    }
                  </div>
              </div>
        }
        {
          inappNotifs == "DONE" &&
              <div className='col-md-4'>
                  <div className="alert alert-success alert-dismissible">
                    <h4><i className="icon fa fa-check"></i> In App Notifications Sent!</h4>
                  </div>
              </div>
        }
        {
          appNotifs === true &&
              <div className='col-md-4'>
                  <div className="alert alert-warning alert-dismissible">
                    <h4><i className="icon fa fa-warning"></i> Sending App Notifications...!</h4>
                    {
                      slotsTab === "App" &&
                      <p>{slots}</p>
                    }
                  </div>
              </div>
        }
        {
          appNotifs == "DONE" &&
              <div className='col-md-4'>
                  <div className="alert alert-success alert-dismissible">
                    <h4><i className="icon fa fa-check"></i> App Notifications Sent!</h4>
                  </div>
              </div>
        }
        {
          webNotifs === true &&
              <div className='col-md-4'>
                  <div className="alert alert-warning alert-dismissible">
                    <h4><i className="icon fa fa-warning"></i> Sending Web Notifications...!</h4>
                    {
                      slotsTab === "Web" &&
                      <p>{slots}</p>
                    }
                  </div>
              </div>
        }
        {
          webNotifs == "DONE" &&
              <div className='col-md-4'>
                  <div className="alert alert-success alert-dismissible">
                    <h4><i className="icon fa fa-check"></i> Web Notifications Sent!</h4>
                  </div>
              </div>
        }
        </>
        </div>
      }

      { !forumLoading && 
      <>
      {
        pagePermissions(props.pagepermissions, "Forum", "add_forum") &&
        <>
      <div className='col-md-12'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              Add New Post
            </h3>
          </div>
          <div className='box-body'>
            <div className='col-md-4 no-padding'>
              
              <div className='box-footer no-left-padding no-right-padding' style={{position:"relative"}} >
                <div className='selectImage' onClick={(e) => {
                                window.$(e.target).parent().find("input").click()
                            }} >
                  
                      <i className='fa fa-image'></i>
                  
                  <input type={"file"} className="form-control" multiple onChange={(e) => showSelectedImages(e)} style={{display:"none"}} accept=".png,.jpg,.jpeg,.gif,.svg" />
                  
                </div>
                

                
              </div>
            </div>
            
            <div className='col-md-8 no-padding'>
              <div className='box-footer no-left-padding no-right-padding'>
                <div className='col-md-12 myckcon' style={{marginBottom:"10px"}}>
                  
                  <CKEditor 
                  editor={ ClassicEditor } 
                  
                  config={
                    { 
                      simpleUpload: { 
                        uploadUrl: 'api/ckeditor/image_upload' 
                      }, 
                      mention: {
                          feeds: [
                              {
                                  marker: '@',
                                  feed: (queryText) => getFeedItems(queryText),
                                  minimumCharacters: 1,
                                  itemRenderer: customItemRenderer
                              }
                          ]
                      }
                    }
                  } 
                  onReady={(editor) => MentionCustomization(editor)}
                  data={commentAdd} onChange={ ( event, editor ) => { const data = editor.getData(); setCommentAdd(data) } } />
                </div>
                <div className='col-md-12 myckcon' style={{marginBottom:"10px"}}>
                  {/* <select className='form-control select2' id='select2' multiple data-placeholder='Notify Via...'>
                    <option value={"App"}>App Push</option>
                    <option value={"Web"}>Web Push</option>
                    <option value={"Whatsapp"}>Whatsapp</option>
                    <option value={"In App"}>In App</option>
                  </select> */}
                  <label>Notify Students?</label>
                  <select className='form-control' defaultValue={notifyVia} onChange={(e) => setNotifyVia(e.target.value)} data-placeholder='Notify?'>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </select>
                </div>
                <div className='col-md-12 no-padding'>
                    {
                      selectedImageThumb !== null && selectedImageThumb.length > 0 &&
                      <div className='box-footer' style={{display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}} >
                      {
                        selectedImageThumb.map((item, index) => {
                          return(
                            <div
                            key={"imagesforum"+index} 
                            style={{position:"relative"}}
                            >
                              <div 
                              style={{margin:"10px", borderRadius:"5px", width:"100px", height:"100px", overflow:"hidden", position:"relative"}}
                              data-fancybox="gallery" data-src={printimg(selectedImageAdd[item])}
                              >
                                  <img src={printimg(selectedImageAdd[item])} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                              </div>  
                              
                              <div style={{
                                    width:"20px", height:"20px", backgroundColor:"#FFF", borderRadius:"20px", display:"flex",
                                    alignItems:"center", justifyContent:"center", color:"#000", position:"absolute", right:0, top:0, zIndex:9,
                                    cursor:"pointer"
                                }} 
                                onClick={() => removeSelection(index)}
                                >
                                    <i className='fa fa-times'></i>
                                </div>
                            </div>
                          )
                        })
                      }
                      </div>
                    }
                </div>
                
                  <div className='col-md-12' style={{position:"relative"}}>
                      {
                          sendingAdd ? 
                          <div style={{height:"50px", position:"relative"}}>
                            <Loading />
                          </div>
                          :
                          <button className='btn btn-primary btn-block' onClick={() => addPost()}>
                              Add New Post
                          </button>
                      }
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      }
      {
        pagePermissions(props.pagepermissions, "Forum", "filter_forum") &&
        showFilter &&
      <div className='col-md-12'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              Filter
            </h3>
            {
              filterLoading &&
              <span className='pull-right' style={{position:"relative", marginRight:"30px"}} >
                <Loading />
              </span>
            }
            
            {
              !filterLoading &&
              <button className='btn btn-danger btn-flat pull-right' onClick={() => setShowFilter(false)} >
                <i className='fa fa-times'></i>
              </button>
            }
            <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)} style={{marginRight:"10px"}}>
                Clear Filter
            </button>
          </div>
          <div className='box-body'>
            
          <form action='' method='POST' onSubmit={(e) => filterForum(e)} >
              <div style={{overflow:"hidden"}}>
                <div className='box-body col-md-3'>
                    <label>Date of Post (Min)</label>
                    <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                </div>   
                <div className='box-body col-md-3'>
                    <label>Date of Post (Max)</label>
                    <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                </div>   
                <div className='box-body col-md-3'>
                    <label>Pinned?</label>
                    <select className='form-control' value={filter.pin} onChange={(e) => changeValue(e.target.value, "pin")}  >
                      <option value={""}>Any</option>
                      <option value={"Yes"}>Pinned</option>
                      <option value={"No"}>Un-Pinned</option>
                    </select>
                </div> 
                <div className='box-body col-md-3'>
                    <label>Hidden?</label>
                    <select className='form-control' value={filter.hide} onChange={(e) => changeValue(e.target.value, "hide")}  >
                      <option value={""}>Any</option>
                      <option value={"Yes"}>Hidden</option>
                      <option value={"No"}>Visible</option>
                    </select>
                </div> 
                <div className='box-body col-md-3'>
                    <label>Posted By?</label>
                    <select className='form-control' value={filter.role} onChange={(e) => changeValue(e.target.value, "role")}  >
                      <option value={""}>Any</option>
                      <option value={"Student"}>Student</option>
                      <option value={"Staff"}>Staff</option>
                    </select>
                </div>   
                <div className='box-body col-md-3'>
                    <label>Has Photos?</label>
                    <select className='form-control' value={filter.attachments} onChange={(e) => changeValue(e.target.value, "attachments")}  >
                      <option value={""}>Any</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                </div> 
                <div className='box-body col-md-3'>
                    <label>Post ID</label>
                    <input type={"text"} className={"form-control"} value={filter.postid} onChange={(e) => changeValue(e.target.value, "postid")} placeholder={"e.g 16121221121"} />
                </div> 
                <div className='box-body col-md-3'>
                    <label>User ID</label>
                    <input type={"text"} className={"form-control"} value={filter.userid} onChange={(e) => changeValue(e.target.value, "userid")} placeholder={"e.g 16121221121"} />
                </div> 
                <div className='box-body col-md-6'>
                    <label>User (Name, Email, Contact, User ID)</label>
                    <input type={"text"} className={"form-control"} value={filter.user} onChange={(e) => changeValue(e.target.value, "user")} placeholder={"e.g Shivam, info@example.com"} />
                </div> 
                <div className='box-body col-md-6'>
                    <label>Search In Post</label>
                    <input type={"text"} className={"form-control"} value={filter.search_in_post} onChange={(e) => changeValue(e.target.value, "search_in_post")} placeholder={"e.g Shivam, info@example.com"} />
                </div> 
              </div>
                <div className='box-footer'> 
                    
                    <button className='btn btn-flat btn-primary btn-block'  >
                        <i className='fa fa-search'></i> &nbsp;
                        Search
                    </button>    
                </div>     
            </form>
          </div>
        </div>
      </div>
    }
      </>
  }

      <div className='col-md-6'>
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className='box-title'>
                  Forum &nbsp; <strong>(Total Forum: {formCount + "/" + totalform})</strong>
                </h3>
                
                {
                  !showFilter &&
                  <button className='btn btn-primary btn-flat pull-right' onClick={() => setShowFilter(true)} >
                    <i className='fa fa-filter'></i>
                  </button>
                }
              </div>
            </div>
            <div style={{
              overflowY:"auto", height:"calc(100vh - 150px)",
            }}
            id={"scrollForum"}
            >
              {
                forumLoading ?
                <div className='box-body'>
                  <br /><br /><br /><br />
                  <Loading />
                  <br /><br /><br /><br />
                </div>
                :
              forum !== null && forum.length > 0 &&
              forum.map((item, index) => {
                var thisPost = postid === item.postid ? "btn-primary" : ""
                return(
                  <div className={item.hide === 1 ? 'box no-border opacityhover' : 'box no-border'} key={"forum"+index} >
                      <div className='box-body'>
                        <div className="box box-widget">
                          <div className="box-header with-border">
                            <div className="user-block">

                            {
                              item.udata.image !== "" && item.udata.image !== null ?
                              <img className="img-circle" src={ assets_url + item.udata.image} alt="User Image" />
                              :
                              <div style={{
                                width:"40px", height:"40px", borderRadius:"40px", 
                                display:"flex", alignItems:"center", 
                                justifyContent:"center", fontSize:16,fontWeight:"bold",
                                backgroundColor: colors[item.udata.init.toLowerCase()]+"29",
                                color: colors[item.udata.init.toLowerCase()],
                                float:"left"
                                }} >
                                {item.udata.init.toUpperCase()}
                              </div>
                            }

                              <span className="username" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}} >
                                <span style={{display:"flex", alignItems:"center", marginBottom:"5px"}}>
                                  <a href={ (item.role === "Student") ? "/admin/student-profile/"+item.uid : ""} target={"_blank"} >
                                    {item.udata.name}
                                  </a>
                                  
                                  {
                                      item.udata.icon !== "" &&
                                    <span style={{display:"inline-block", marginLeft:"5px"}} >
                                        <img src={item.udata.icon} style={{width:"20px", height:"20px", objectFit:"contain"}}  />
                                    </span>
                                    }
                                    
                                  </span>
                                  {/* <span className='pull-right' style={{fontSize:"12px"}} >
                                    {item.role}
                                  </span> */}
                                  <div>
                                    
                                {
                                  pagePermissions(props.pagepermissions, "Forum", "remove_forum") &&
                                    <span className='pull-right bg-red badge' onClick={() => removePost(item.postid)} >
                                      <i className='fa fa-trash'></i>
                                    </span>
                                }
                                    
                                  {
                                    pagePermissions(props.pagepermissions, "Forum", "hide_forum") &&
                                    <>
                                      {
                                        item.hide === 1 ? 
                                          <span className='pull-right bg-green badge' style={{marginRight:"10px", padding:"10px", display:"inline-block"}} title={"Un-Hide Post"} 
                                          onClick={() => hideUnhide(null, item.postid, "hide")}
                                          >
                                            Un-Hide?
                                          </span>
                                          :
                                          <span className='pull-right bg-red badge' style={{marginRight:"10px", padding:"10px", display:"inline-block"}} title={"Hide Post"} 
                                          onClick={() => hideUnhide(1, item.postid, "hide")}
                                          >
                                            Hide?
                                          </span> 
                                      }
                                    </>
                                  }
                                    
                                                                
                                  {
                                    pagePermissions(props.pagepermissions, "Forum", "pin_forum") &&
                                    <>
                                      {
                                        item.pinned === 1 ? 
                                          <span className='pull-right bg-green badge' style={{marginRight:"10px", padding:"10px", display:"inline-block"}} title={"Un-Hide Post"} 
                                          onClick={() => hideUnhide(null, item.postid, "pinned")}
                                          >
                                            Un-Pin?
                                          </span>
                                          :
                                          <span className='pull-right bg-red badge' style={{marginRight:"10px", padding:"10px", display:"inline-block"}} title={"Hide Post"} 
                                          onClick={() => hideUnhide(1, item.postid, "pinned")}
                                          >
                                            Pin?
                                          </span> 
                                      }
                                    </>
                                    }

                                    

                                  </div>
                              </span>
                              
                              <span className="description">Shared publicly - {item.date}</span>
                            </div>
                            
                          </div>
                          
                          <div className="box-body" dangerouslySetInnerHTML={{__html: item.postdata.replace(/\n/g,'<br />')}}></div>
                            
                            {
                              item.files !== null && item.files.length > 0 &&
                              <div className='box-footer' style={{display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}} >
                              {
                                item.files.map((item2, index2) => {
                                  return(
                                    <div 
                                    key={"images"+index2+item.id} 
                                    style={{margin:"10px", borderRadius:"5px", width:"100px", height:"100px", overflow:"hidden"}}
                                    data-fancybox="gallery" data-src={assets_url + item2.path}
                                    >
                                        <img src={assets_url + item2.path} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                                    </div>  
                                  )
                                })
                              }
                              </div>
                            }

                        </div>
                        {
                          pagePermissions(props.pagepermissions, "Forum", "view_comments") &&
                          <button type="button" onClick={() => getComments(item, 0)} className={"btn btn-default btn-xs pull-right " + thisPost} style={{color: (thisPost !== "") ? "#FFF" : ""}} > {item.comments} Comments <i className="fa fa-share"></i></button>
                        }
                      </div>
                </div>
                )
              })
            }
          </div>
            {
              atEnd &&
              
              <div className='box-body' style={{position:"relative"}} >
                <Loading />
                <br /><br /><br /><br />
              </div>
            }
        </div>
        {
          showComments &&
          <div className='col-md-6'>
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className='box-title'>
                  Comments
                </h3>
              </div>
            </div>
            {
                  commentsLoading ?
                  <div className='box no-border'>
                    <div className='box-body' style={{position:"relative"}} >
                      <br /><br />
                      <Loading />
                      <br /><br />
                      <br /><br />
                    </div>
                  </div>
                  :
                  <>
                    {
                      comments.map((item, index) => {
                      return(
                          <div className='box no-border'  key={"comment"+index}>
                            <div className="box-footer box-comments">
                              <Commentlist 
                                item={item} 
                                index={index} 
                                postid={postid} 
                                key={"comment"+index} 
                                items={items} 
                                commentpermission={pagePermissions(props.pagepermissions, "Forum", "make_comments")} 
                                removepermission={pagePermissions(props.pagepermissions, "Forum", "remove_comments")} 
                                />
                            </div>
                          </div>
                        )
                      })
                    }
                    
                    {
                      pagePermissions(props.pagepermissions, "Forum", "make_comments") &&
                    <div className='box-footer no-left-padding no-right-padding'>
                      <div className='col-md-12 myck0' style={{marginBottom:"10px"}}>
                        <CKEditor 
                          editor={ ClassicEditor } 
                          config={
                            { 
                              simpleUpload: { 
                                uploadUrl: 'api/ckeditor/image_upload'
                              }, 
                            
                              mention: {
                                feeds: [
                                    {
                                        marker: '@',
                                        feed: (queryText) => getFeedItems(queryText, items),
                                        minimumCharacters: 1,
                                        itemRenderer: customItemRenderer
                                    }
                                ]
                            }  
                            }}
                            onReady={(editor) => MentionCustomization(editor)}
                            data={comment} onChange={ ( event, editor ) => { const data = editor.getData(); setComment(data) } } 
                          />
                      </div>
                        <div className='col-md-3'>
                            <button className='btn btn-primary btn-block' onClick={(e) => {
                                window.$(e.target).parent().find("input").click()
                            }}>Add Image</button> 
                            <input type={"file"} className="form-control" onChange={(e) => imagePicked(e)} style={{display:"none"}} accept=".png,.jpg,.jpeg,.gif,.svg" />
                        </div>
                        <div className='col-md-3 no-padding' style={{position:"relative"}}>
                            {
                                selectedImage !== null &&
                                <>
                                <div style={{
                                    width:"20px", height:"20px", backgroundColor:"#FFF", borderRadius:"20px", display:"flex",
                                    alignItems:"center", justifyContent:"center", color:"#000", position:"absolute", right:0, top:0, zIndex:9,
                                    cursor:"pointer"
                                }} 
                                onClick={() => setSelectedImage(null)}
                                >
                                    <i className='fa fa-times'></i>
                                </div>
                                <img src={printimg(selectedImage)} className="attachment-img-my" />
                                </>
                            }
                        </div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3' style={{position:"relative"}}>
                            {
                                sending ? 
                                <Loading />
                                :
                                <button className='btn btn-primary btn-block' onClick={() => addComment()}>
                                    Reply
                                </button>
                            }
                        </div>
                    </div>
                    }
                  </>
            }
          </div>
        }

      </>
    )
  }
  return (
    <>
      <Sidebar page={Addforumpage} permission="Forum"/>
    </>
  )
}
