import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loading from '../includes/Loading';

const cookies = new Cookies();

export default function Addsocial() {
    
  const Addsocials = (props) => {
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    var a = 0;
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
    const socialArray = ["Facebook", "Youtube", "Twitter", "Instagram", "Pinterest", "Skype", "Linkedin", "Whatsapp", "Contact Support", "Email Support"]
    const [form, setForm] = useState({ "thumbnail" : "", "title" : "", "url" : "", "type" : "", "position" : "", "id" : "" })
    const [refresh, setRefresh] = useState("No")
    const [active, setActive] = useState("Facebook")
    const [loading, setLoading] = useState(true)

    const [list, setList] = useState([])

    const setFormVals = (col, val) => {
        var temp = form
        temp[col] = val
        setForm(temp)
        setRefresh( refresh === "No" ? "Yes" : "No")
    }

    const updateSocialLink = (e) => {
        e.preventDefault();

        formLoading(e.target)
        const formData = new FormData();
        formData.append('thumbnail', form.thumbnail);
        formData.append('id', form.id);
        formData.append('title', form.title);
        formData.append('url', form.url);
        formData.append('type', form.type);
        formData.append('position', form.position);
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/updateSocialMedia", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getSocialLinks()
                    resetLink()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const getSocialLinks = () => {
        setLoading(true)
        fetch(apiurl+"api/getSocialLinks", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    socialArray: socialArray,
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.links)
                    setTimeout(function(){
                        callSorting()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => { });
    }

    const editLink = (item) => {
        var temp = form
        temp["thumbnail"] = ""
        temp["title"] = item.title
        temp["url"] = item.url
        temp["type"] = item.type
        temp["position"] = item.position
        temp["id"] = item.id
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const resetLink = () => {
        var temp = form
        temp["thumbnail"] = ""
        temp["title"] = ""
        temp["url"] = ""
        temp["type"] = ""
        temp["position"] = ""
        temp["id"] = ""
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    
    const removeLink = (id) => {
        fetch(apiurl+"api/remove_link", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getSocialLinks()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeLink(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }
    
    const rePositionAll = (array) => {
        fetch(apiurl+"api/repositionSocial", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {
        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }


    useEffect(() => {
        if(a == 0){
            getSocialLinks()
            a++
        }
     }, [])



    return(
    <>
      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
                {
                    form.id === "" ? 
                        "Add Social Media URL"
                    :
                        "Update Social Media"
                }
                
            </h3>
            {
                form.id !== "" &&
                <button className='btn btn-primary btn-flat pull-right' onClick={() => resetLink()}>
                    <i className='fa fa-times'></i>
                </button>
            }
          </div>
            <form action='#' method='POST' onSubmit={(e) => updateSocialLink(e)}>
                <div className='box-body'>
                    <p className='text-red'>
                        <strong>
                            File Size: Upto 1 MB<br />
                            Accepted Formats: .jpg, .jpeg, .png, .gif, .svg<br />
                        </strong>
                    </p>
                </div>
                {/* <div className='box-body'>
                    <label>Thumbnail <span className='text-red'>500*500</span></label>
                    <input type={"file"} name="pc_banner" className='form-control' onChange={e => setFormVals('thumbnail', e.target.files[0])} accept=".png, .jpg, .jpeg, .svg, .gif" />
                </div> */}
                <div className='box-body'>
                    <label>Social Title </label>
                    <input type={"text"} name="banner_title" required className='form-control' value={form.title} placeholder='e.g Toppers of 2022'  onChange={e => setFormVals('title', e.target.value)}/>
                </div>
                <div className='box-body'>
                    <label>Social URL </label>
                    <input type={"url"} name="banner_url" required className='form-control' value={form.url} placeholder='e.g https://escholar.in' onChange={e => setFormVals('url', e.target.value)}/>
                </div>
                <div className='box-body'>
                    <label>Select Type</label>
                    <select className='form-control' value={form.type} required onChange={(e) => setFormVals('type', e.target.value)} >
                        <option value={""}>Select Social Type</option>
                        {
                            socialArray.map((item, index) => {
                                return( 
                                    <option value={item} key={index} >{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='box-body'>
                    <label>Position </label>
                    <input type={"number"} name="banner_url" required className='form-control' value={form.position} placeholder='e.g 1' onChange={e => setFormVals('position', e.target.value)}/>
                </div>
                <div className='box-footer'>
                    <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={form.id === "" ? "Add Social Media URL" : "Update Social Media"}/>
                </div>
            </form>  
        </div>
      </div>


      <div className={pagePermissions(props.pagepermissions, "Feedbacks", "add_feedback")  ? "col-md-8" : "col-md-12"}>
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                    {
                        socialArray.map((item, index) => {
                            return(
                                <li className={ (active === item) ? "active" : ""} key={"index" + index} onClick={() => setActive(item)} ><a  >{item}</a></li>
                            )
                        })
                    }
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="tab_1">
                        <div className='box no-border' style={{position:"relative"}} >
                            {
                                loading ?
                                <>
                                    <br /><br /><br /><br />
                                    <Loading />
                                    <br /><br /><br /><br />
                                </>
                                :
                                <div className='box-body'>
                                    <table id="studenttable" className="table table-bordered table-hover datatable" >
                                        <thead>
                                            <tr>
                                                <th style={{width:"80px"}} >S.No</th>
                                                {/* <th>Thumbnail</th> */}
                                                <th>Title</th>
                                                <th>Link</th>
                                                <th>Added By</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className='connected-sortable droppable-area1' >
                                            {
                                                typeof list[active] !== "undefined" && list[active].length > 0 &&
                                                list[active].map((item, index) => {
                                                    var staff = JSON.parse(item.staff)
                                                    var thumbnail = (item.thumbnail !== null) ? JSON.parse(item.thumbnail) : null
                                                    return(
                                                        
                                                        <tr key={"key"+index} >
                                                            <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                                <i className='fa fa-arrows-v'></i>
                                                            </td>
                                                            <td style={{width:"80px"}} >{index+1}</td>
                                                            {/* <td>
                                                                {
                                                                    thumbnail !== null &&
                                                                    <img src={assets_url + thumbnail.path} style={{width:"60px", height:"60px", objectFit:"contain"}} />
                                                                }
                                                            </td> */}
                                                            <td>{item.title}</td>
                                                            <td>
                                                                <a href={item.url} title={item.title} >
                                                                {item.url}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                                                {staff.email}<br />
                                                                {staff.contact}
                                                            </td>
                                                            <td style={{whiteSpace:"nowrap"}} >
                                                                <button className='btn btn-primary btn-flat' onClick={() => editLink(item)} >
                                                                    <i className='fa fa-edit'></i>
                                                                </button>
                                                                <button className='btn btn-danger btn-flat' style={{marginLeft:"5px"}}  onClick={e => removeDialog(item.id)} >
                                                                    <i className='fa fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
  }
  return (
    <>
      <Sidebar page={Addsocials} permission="Social_Links"/>
    </>
  )
}
