import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
const cookies = new Cookies();


export default function Linkpromocodes(props) {

    const params = useParams()
    const [plans, setPlans] = useState([])
    const [plansLoading, setPlansLoading] = useState(true)
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    
    const [showUsed, setShowUsedBy] = useState(null)
    const [showUsedList, setShowUsedList] = useState([])


    const showUsedBy = (item) => {
        setShowUsedBy(item)
        
        fetch(apiurl+"api/getUsedPromocodeLinks", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    promo: item.id,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setShowUsedList(response.list)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setPlansLoading(false)
                }
            })
            .catch(err => { });
    }
    
    const getPlans = () => {
        setPlansLoading(true)
        
        fetch(apiurl+"api/get_plans", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    courseid: params.id,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    type: "Price"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setPlans(response.plans)
                    setPlansLoading(false)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setPlansLoading(false)
                }
            })
            .catch(err => { });
    }


    const AddComponent = ({item, index}) => {
        const [title, setTitle] = useState("")
        const [discount, setDicount] = useState(0)
        const [usage, setUsage] = useState(10)
        const [id, setId] = useState("")
        
        const [inLoadin, setInloading] = useState(false)
        const [promoList, setPromoList] = useState([])

        const editLink = (item) => {
            setUsage(item.usagecount)
            setDicount(item.discount)
            setTitle(item.title)
            setId(item.id)
        }

        const addPromoLink = (e) => {
            e.preventDefault();
            formLoading(e.target)
            
            fetch(apiurl+"api/addLinkPromocode", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        accecible: 1,
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics()),
                        courseid: params.id,
                        planid: item.id,
                        title: title,
                        discount: discount,
                        usage: usage,
                        id: id
                    })
                })
                .then(response => response.json())
                .then(response => {
                    $(e.target).find(".loaderbody").remove()
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setTitle("")
                        setDicount(0)
                        setUsage(10)
                        successModal("Success", response.message)
                        getPromoLink()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                        
                    }
                })
                .catch(err => { });
        }
        
        const getPromoLink = () => {
            setInloading(true)
            fetch(apiurl+"api/getLinkPromocode", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        accecible: 1,
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics()),
                        courseid: params.id,
                        planid: item.id,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setPromoList(response.links)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                        
                    }
                    setInloading(false)
                })
                .catch(err => { });
        }


        
        const removeLink = (item) => {
            confirmAlert({
                title: 'Are You Sure?',
                message: "You won't be able to revert this back.",
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => removeThisLink(item)
                },
                {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                }
                ]
            });
        } 

        
    const removeThisLink = (item) => {
        fetch(apiurl+"api/removePromoLink", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: item.id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getPromoLink()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const changeStatus = (item, value, index, col) => {
        console.log("asdasd", item)
        fetch(apiurl + "api/change_promolink_status_for_course", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    activeid: item.id,
                    value: value,
                    col: col,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                getPromoLink()
                // CategoryList()
            })
            .catch(err => { });
    }

        var b = 0
        useEffect(() => {
            if(b === 0){
                getPromoLink()
                b++
            }
        }, [])
        return(
            <div className='col-md-12 no-padding' key={"index"+index} >


                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            {item.plan_name}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                                <small>
                                    (
                                    <strong>Listing Price:</strong> {item.listing_price}
                                </small>,&nbsp;&nbsp;
                                <small>
                                    <strong>Selling Price:</strong> {item.selling_price}
                                </small>,&nbsp;&nbsp; 
                                <small>
                                    <strong>Discount:</strong> {item.discount}
                                </small>,&nbsp;&nbsp;
                                <small>
                                    <strong>Off %:</strong> {item.off}
                                </small>,&nbsp;&nbsp;
                                <small>
                                    <strong>GST:</strong> {item.gst}
                                    )
                                </small>
                        </h3>
                    </div>
                </div>
                <div className='col-md-3 no-left-padding'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                {
                                    (id === "") ? "Add New Promocode Link" : "Update Promocode Link"
                                }
                            </h3>
                                {
                                    (id !== "") &&
                                        <span className='pull-right badge bg-red'>
                                            <i className='fa fa-times'></i>
                                        </span>
                                }
                        </div>
                        <form action='' method='POST' onSubmit={addPromoLink} >
                            <div className='box-body'>
                                <label>Title</label>
                                <input type={"text"} className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} placeholder={"e.g Diwali Discount"} required />
                            </div>
                            <div className='box-body'>
                                <label>Additional Discount in Rupees</label>
                                <input type={"text"} className="form-control" value={discount} onChange={(e) => setDicount(e.target.value)} placeholder={"e.g 100"} required />
                            </div>
                            <div className='box-body'>
                                <label>Usage Count <span style={{color:"red"}}>Leave blank for infinite</span></label>
                                <input type={"number"} className="form-control" value={usage} onChange={(e) => setUsage(e.target.value)} placeholder={"e.g 10"} required />
                            </div>
                            <div className='box-footer'>
                                <button className='btn btn-primary btn-flat btn-block'>
                                    {
                                        (id !== "") ? "Update Link Promocode" : "Add Link Promocode"
                                    }
                                    
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='col-md-9 no-right-padding'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Manage Links
                            </h3>
                            <p style={{color:"red"}}>
                                <strong>Exp promocodes</strong> will be send automatically to the student when his/her course gets expired.<br />
                                <strong>7D Initiated</strong> promocodes will be send automatically to the student when student has initiated the payment but haven't purchased course till seventh day.<br />
                            </p>
                        </div>
                        {
                            inLoadin ?
                            <div className='box-body' style={{position:"relative"}} >
                                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                                <Loading />
                            </div>
                            :
                            <div className='box-body' style={{overflow:"auto"}} >
                                
                                {
                                    (showUsed === null || showUsed.planid.toString() !== item.id.toString()) &&
                                        <table id="studenttable" className="table table-bordered table-hover datatable">
                                            <thead>
                                                <tr>
                                                    <th style={{width:"80px"}} >S.No</th>
                                                    <th>Title</th>
                                                    <th>Discount</th>
                                                    <th>Usage</th>
                                                    <th>Link</th>
                                                    <th>Date</th>
                                                    <th>Exp Promocode</th>
                                                    <th>7D Initiated</th>
                                                    <th>Added by</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {
                                                promoList.length > 0 &&
                                                <tbody>
                                                    {
                                                    promoList.map((item, index) => {
                                                        var staff = JSON.parse(item.staff)
                                                        moment.locale('en');
                                                        var dt = item.created_at;
                                                        return(
                                                            <tr key={"link"+index} >
                                                                <td style={{width:"80px"}} >{index+1}</td>
                                                                <td className='cursor-pointer'  >
                                                                    <span style={{color:"#3c8dbc"}} onClick={() => showUsedBy(item)} >
                                                                        {item.title}
                                                                    </span>
                                                                </td>
                                                                <td>{item.discount}</td>
                                                                <td>{item.usagecount}</td>
                                                                <td>
                                                                    <a href={'https://www.escholars.in/p/'+props.course.seoslug+'/'+params.id+'?offer='+item.codeid} target={'_blank'}    >
                                                                        https://www.escholars.in/p/{props.course.seoslug}/{params.id}?offer={item.codeid}
                                                                    </a>
                                                                </td>
                                                                <td>{moment(dt).format("D-M-Y HH:mm:ss")}</td>
                                                                <td>
                                                                    <label className="switch">
                                                                        <input type="checkbox" value={(item.forexp == 1) ? 1 : 0} defaultChecked={(item.forexp) ? true : false} onChange={e => changeStatus(item, e.target.value, index, 'forexp')}/>
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <label className="switch">
                                                                        <input type="checkbox" value={(item.for7init == 1) ? 1 : 0} defaultChecked={(item.for7init) ? true : false} onChange={e => changeStatus(item, e.target.value, index, 'for7init')}/>
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </td>
                                                                
                                                                <td>
                                                                    <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                                                    {staff.email}<br />
                                                                    {staff.contact}
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-warning btn-flat' onClick={() => editLink(item)} >
                                                                        <i className='fa fa-edit'></i>
                                                                    </button>
                                                                    <button className='btn btn-danger btn-flat' onClick={() => removeLink(item)} >
                                                                        <i className='fa fa-trash'></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            }
                                        </table>
                                    }
                            </div>

                        }
                    </div>
                </div>
                
            </div>
        )
    }

    var b = 0;
    useEffect(() => {
        if(b === 0){
            getPlans()
            b++
        }
    }, [])

  return (
    <>
    <br />

        <div className='col-md-12'>
            
        {
                    showUsed !== null &&
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                {showUsed.title} <small style={{color:"red", fontWeight:"bold"}} >(#{showUsed.codeid})</small>
                            </h3>
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => {
                                setShowUsedBy(null)
                                setShowUsedList([])
                            }} >
                                <i className='fa fa-times'></i>
                            </button>
                        </div>
                        <div className='box-body'>
                            <table id="studenttable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th style={{width:"80px"}} >S.No</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Order ID</th>
                                        <th>Course/Package</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        showUsedList.length > 0 &&
                                        showUsedList.map((item, index) => {
                                            return(
                                                <tr>
                                                    <td>
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        <Link to={'/admin/student-profile/'+item.student.userid} target='_blank' >
                                                            { item.student.name }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        { item.student.email }
                                                    </td>
                                                    <td>
                                                        { item.student.contact }
                                                    </td>
                                                    <td>
                                                        { item.orderid }
                                                    </td>
                                                    <td>
                                                        { item.ordername }
                                                    </td>
                                                </tr>
                                                )
                                            })
                                        }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                
            {
                plans.length > 0 && showUsed === null &&
                plans.map((item, index) => {
                    return(
                        <AddComponent item={item} index={index} key={"index"+index} />
                    )
                })
            }

            
        </div>

    </>
  )
}
