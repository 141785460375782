import React, { useEffect, useState } from 'react'
import $ from "jquery"
import apiurl from '../Global';
import Cookies from 'universal-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../admin/includes/Loading';
import Adminprops from '../VelidateWebsite/Adminprops';
import FeatherIcon from 'feather-icons-react';
import CommonFunc from './includes/Common';


const cookies = new Cookies();


const height = window.innerHeight;
const width = window.innerWidth;

export default function Login(props) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [showPass, setShowPass] = useState(false)
    const [resp, setResp] = useState("")
    const [resptype, setRespType] = useState("1")
    var a = 0
    // const query = new URLSearchParams(this.props.location.search);
    const [searchParams, setSearchParams] = useSearchParams();
    

    useEffect(() => {
        if(a == 0){
            
            $("body").append('<style>.parcialLoading{width:100%;height:100%;position:absolute;top:0;left:0;z-index:9;background-color:rgba(255,255,255,.5)}.loader{border:5px solid #f3f3f3;border-radius:50%;border-top:5px solid #3498db;width:30px;height:30px;-webkit-animation:2s linear infinite spin;animation:2s linear infinite spin;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0)}100%{-webkit-transform:rotate(360deg)}}@keyframes spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}</style>');
            a++;
            
            let loggedin = cookies.get('admin')
            let getToken = cookies.get('token')
            // console.log(loggedin)
            if((typeof loggedin !== "undefined" && loggedin !== "") && (typeof getToken !== "undefined" && getToken !== "")){
                navigate("/admin/dashboard")
            }else{
                setLoading(false)
            }
            
        }
    }, [])

    const validateInput = (e) => {
        e.preventDefault();
        setLoading(true)
        fetch(apiurl+"api/admin_login", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
            },
            "body": JSON.stringify({
                    email: email,
                    password: password,
                })
            })
            .then(response => response.json())
            .then(response => {
                
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    setEmail("")
                    setPassword("")
                    var adminArray = response.admin
                    delete adminArray['permissions'];
                    delete adminArray['coursepermission'];
                    delete adminArray['password'];
                    
                    let d = new Date();
                    d.setTime(d.getTime() + (60*60*1000*1000));
                    cookies.set('admin', JSON.stringify(adminArray), { path: '/', expires:d });
                    cookies.set('token', JSON.stringify(response.token), { path: '/', expires:d });
                    
                    setRespType(1)
                    setResp("Loggedin Successfully Redirecting Please Wait...")
                    
                    window.location.href="/admin/dashboard"

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    setLoading(false)
                    setRespType(0)
                    setResp(response.message)
                    
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

  return (
    <>
    {
        loading ?
        <Loading />
        :


        <>
            <section className="newlogin">
                <div className="logincon">
                    <div className="circle"></div>
                    <div className="w50 float-left flexlogin">
                        <div className="leftlogin">
                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/loginLeft.svg'} />
                        </div>
                    </div>
                    <div className="w50 float-left flexlogin" style={{padding:"50px 0"}}>
                        <div className="containermy">
                            <div className="containermy center-text">
                                {/* <div className="profileicon">
                                <FeatherIcon icon={"user"} />
                                </div> */}
                                <div className="login-logo">
                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/logo.png'} />
                                </div>
                                <p className="text-center loginheading">
                                    WELCOME BACK!
                                </p>
                                <p className="text-center "  style={{fontSize:"12px", color:(resptype === 0) ? "red" : "green"}}>
                                    <strong>
                                        {resp}
                                        <span style={{color:"red"}} >{searchParams.get("auto") !== null && "Automatically Logged Out"}</span>
                                    </strong>
                                </p>
                                <form method="POST" action="#" id="signup" onSubmit={validateInput}>
                                    <div className="formcon">
                                        <label>
                                            Your Email
                                        </label>
                                        <div className="iconbox">
                                            <FeatherIcon icon={"mail"} />
                                        </div>
                                        <div className="fieldbox">
                                            <input type="email" placeholder="e.g john@example.com" name="email" id="email" required="" value={email}  onChange={e => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="formcon">
                                        <label>
                                            Your Password
                                            
                                        </label>
                                        <div className="iconbox">
                                            <FeatherIcon icon={"key"} />
                                        </div>
                                        <div className="fieldbox">
                                            <input type={showPass ? "text" : "password"} placeholder="e.g @John123" name="password" required="" id="password" value={password} onChange={e => setPassword(e.target.value)}/>
                                            <div className="passshow" onClick={() => setShowPass(showPass ? false : true)} >
                                                {
                                                    showPass ? 
                                                    <FeatherIcon icon={"eye-off"}  />
                                                    :
                                                    <FeatherIcon icon={"eye"} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formcon left-text terms space-between" style={{border:"none"}}>
                                        <div></div>
                                        <button className="loginButton">
                                            Login
                                        </button>
                                    </div>
                                </form>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <p className="center-text copyrights">
                    © 2023 <a href="https://escholars.in" ><b>Escholars</b></a> All Rights Reserve 
                    {/* <a href="https://website99.net/" ><b>Designed & Powered By Website99</b></a> */}
                </p>
            </section>


        {/* <div className='loginBG'>
        <div className="login-box">
            <div className="login-logo">
                <img src={process.env.PUBLIC_URL+'/assets/assets/images/logo.png'} />
            </div>
            
            <div className="login-box-body">
                <p className="login-box-msg">Sign in to start your session</p>

                <form action="#" method="post" onSubmit={validateInput}>
                    <div className="form-group has-feedback">
                        <input type="email" className="form-control" placeholder="Email" name='email' onChange={e => setEmail(e.target.value)}/>
                        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                    </div>
                    <div className="form-group has-feedback">
                        <input type="password" className="form-control" placeholder="Password" name='password' onChange={e => setPassword(e.target.value)}/>
                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                    </div>
                    <div className="row">
                        <div className="col-xs-8">
                        </div>
                        
                        <div className="col-xs-4">
                        <button type="submit" className="btn btn-block btn-flat" style={{backgroundColor:"#000173", color:"#FFF"}} >Sign In</button>
                        </div>
                        
                    </div>
                </form>
            </div>
            
            </div>
        </div> */}
        </>
        }
    </>
  )
}
