
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging ,getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAbb9pusMJcJCqSroGLLi7KjaNyPrp3caE",
    authDomain: "escholars-560b8.firebaseapp.com",
    projectId: "escholars-560b8",
    storageBucket: "escholars-560b8.appspot.com",
    messagingSenderId: "702904017207",
    appId: "1:702904017207:web:c43520a7627bd4ba5dbab2",
    measurementId: "G-NPDPZ5JB3D"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);