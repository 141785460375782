import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import apiurl from '../../Global';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
const cookies = new Cookies();

export default function Editquiz() {


  const Editquizpage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [quiztitle, setQuiztitle] = useState("")
    const [quiztime, setQuiztime] = useState("")
    const [quizquestions, setQuizquestions] = useState("")
    const [quizattempts, setQuizattempts] = useState("")
    const [quiztype, setQuiztype] = useState("Regular")
    const [quizinstruction, setQuizinstruction] = useState("")
    const [quizcourseid, setQuizcourseid] = useState("")
    const [quizpid, setQuizpid] = useState("")
    const [quizmintime, setQuizquizmintime] = useState("")
    const [quizpostmsg, setQuizpostmsg] = useState("")
    const [resultafter, setResultafter] = useState("")
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()
    const[refresh, setRefresh] = useState("No") 
    const [grades, setgrades] = useState([
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" }
    ])
    

    const resetVals = () => {
        setQuiztitle("")
        setQuiztime("")
        setQuizquestions("")
        setQuizattempts("")
        setQuiztype("Regular")
        setQuizinstruction("")
        setQuizquizmintime("")
        setQuizpostmsg("")
        setResultafter("")
    }

    const updateQuiz = (e) => {
        formLoading(e.target)
        e.preventDefault()
      fetch(apiurl+"api/update_quiz", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                    quizid: params.id,
                    quiztitle: quiztitle, 
                    quiztime: quiztime,
                    quizquestions: quizquestions,
                    quizattempts: quizattempts,
                    quiztype: quiztype,
                    quizinstruction: quizinstruction,
                    quizpostmsg: quizpostmsg,
                    resultafter: resultafter,
                    courseid: params.id,
                    p_id: params.p_id,
                    quizmintime: quizmintime,
                    grades: grades,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
              })
          })
          .then(response => response.json())
          .then(response => {

                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){
                    $(e.target).find(".loaderbody").remove()
                }, 2000)

              if(typeof response.status !== "undefined" && response.status == "OK"){
                  successModal("Success", response.message)
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  // alert(response.message)
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              window.$('#addNewCourse').fadeOut()
          })
          .catch(err => {
            window.$('#addNewCourse').fadeOut()
              // console.log(err);
          });
      }

      const single_quiz = () => {
        fetch(apiurl+"api/single_quiz", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    quizid: params.id,
                    p_id: params.p_id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
  
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setQuiztitle(response.data.quiztitle)
                    setQuiztime(response.data.quiztime)
                    setQuizquestions(response.data.quizquestions)
                    setQuizattempts(response.data.quizattempts)
                    
                    setQuiztype(response.data.quiztype)
                    setQuizinstruction(response.data.quizinstruction)
                    setQuizcourseid(response.data.main_parent)
                    setQuizpid(response.data.p_id)
                    setQuizquizmintime(response.data.quizmintime)
                    setResultafter(response.data.resultgenerate)
                    setQuizpostmsg((response.data.quizpostmsg === null) ? "" : response.data.quizpostmsg)
                    
                    setgrades(
                        response.data['assignment_grades'] === null ?
                        [
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" }
                        ]
                        :
                        JSON.parse(response.data['assignment_grades'])
                    )
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                window.$('#addNewCourse').fadeOut()
            })
            .catch(err => {
              window.$('#addNewCourse').fadeOut()
                // console.log(err);
            });
      }


      const changeColval = (col, index, val) => {
        var temp = grades
        temp[index][col] = val
        setgrades(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    
      var a = 0
      useEffect(() => {
        if(a === 0){
            single_quiz()
           a++ 
        }
      }, [])

    return(
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                       Update Quiz
                    </h3>
                </div>
                <form action='' method='POST' onSubmit={(e) => updateQuiz(e)}>
                    <div className='box-body no-left-padding no-right-padding'>
                        <div className='col-md-4'>
                            <label>Quiz Title<span className='text-red'>*</span></label>
                            <input type={"text"} className="form-control" placeholder='e.g CA Foundation 9.0 Test Series' value={quiztitle} onChange={(e) => setQuiztitle(e.target.value)} />
                        </div>
                        <div className='col-md-4'>
                            <label>Quiz Time<span className='text-red'>* (In Minutes)</span></label>
                            <input type={"text"} className="form-control" placeholder='e.g 60' value={quiztime} onChange={(e) => setQuiztime(e.target.value)}  />
                        </div>
                        <div className='col-md-4'>
                            <label>No of Questions<span className='text-red'>*</span></label>
                            <input type={"text"} className="form-control" placeholder='e.g 60' value={quizquestions} onChange={(e) => setQuizquestions(e.target.value)}  />
                        </div>
                    </div>
                    <div className='box-body no-left-padding no-right-padding'>
                        <div className='col-md-3'>
                            <label>Submission Time<span className='text-red'>Leave Blank For No Limit</span></label>
                            <input type={"number"} className="form-control" placeholder='e.g 40' value={quizmintime} onChange={(e) => setQuizquizmintime(e.target.value)}  />
                        </div>
                        <div className='col-md-3'>
                            <label>No of attempts allowed<span className='text-red'>*</span></label>
                            <input type={"text"} className="form-control" placeholder='e.g 5' value={quizattempts} onChange={(e) => setQuizattempts(e.target.value)}  />
                        </div>
                        <div className='col-md-3'>
                            <label>Quiz Type<span className='text-red'>*</span></label>
                            <select className='form-control' value={quiztype} onChange={(e) => setQuiztype(e.target.value) } >
                                <option value={"Regular"}>Regular</option>
                                <option value={"Random"}>Random</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>Result Generate After (Mins) <span className='text-red'>(Leave Blank For Instant)</span></label>
                            <input type={"number"} className="form-control" placeholder='e.g 60' value={resultafter} onChange={(e) => setResultafter(e.target.value)}  />
                        </div>
                    </div>
                    <div className='box-body'>
                        <label>Quiz Instructions</label>
                        <CKEditor
                            editor={ ClassicEditor }
                            config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
                            data={ (quizinstruction === "") ?  "<p>Enter Quiz Instruction</p>" : quizinstruction}   
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setQuizinstruction(data)
                                // console.log( { event, editor, data } );
                            } }
                        />
                    </div>
                    <div className='box-body'>
                        <label>Quiz Post Message</label>
                        <CKEditor
                            editor={ ClassicEditor }
                            config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
                            data={ (quizpostmsg === "") ?  "<p>Enter Quiz Post Message</p>" : quizpostmsg}   
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setQuizpostmsg(data)
                                // console.log( { event, editor, data } );
                            } }
                        />
                    </div>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Manage Grades
                        </h3>
                        <p>
                            <strong style={{color:"red"}} >
                                Min value input includes the more  than value and
                                Max value input  includes less than value.
                            </strong>
                        </p>
                    </div>
                    {
                        grades !== null && grades.length > 0 &&
                        grades.map((item, index) => {
                            return(
                                <div className='box-body no-left-padding no-right-padding' key={"key"+index} >
                                    <div className='col-md-3'>
                                        <label>Min %</label>
                                        <input type={"number"} value={item.min} className={"form-control"} placeholder={"e.g "+(index*10)} onChange={(e) => changeColval("min", index, e.target.value)} />
                                    </div>
                                    <div className='col-md-3'>
                                        <label>Max %</label>
                                        <input type={"number"} value={item.max} className={"form-control"} placeholder={"e.g "+((index+1)*10)} onChange={(e) => changeColval("max", index, e.target.value)} />
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Message</label>
                                        <input type={"text"} value={item.msg} className={"form-control"} placeholder={"e.g Congratulations"} onChange={(e) => changeColval("msg", index, e.target.value)} />
                                    </div>
                                </div>
                                
                                )
                            })
                        }
                    <div className='box-footer'>
                        <Link to={"/admin/course-content/"+quizcourseid+"/"+quizpid} >
                            <button className='btn' type='button'>
                                <i className='fa fa-arrows-left'></i> Back
                            </button>
                        </Link>
                        <button className='btn btn-primary btn-flat pull-right' type='submit'> Update Quiz</button>
                    </div>
                </form>
            </div>
        </div>
    )
  }
  return (
    <>
      <Sidebar page={Editquizpage} permission="Courses"/>
    </>
  )
}
