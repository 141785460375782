import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
import { CSVLink, CSVDownload } from "react-csv";
const cookies = new Cookies();


export default function Mydownloads() {
  const Mydownloadspage = (props) => {
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url} = CommonFunc()
    const [loading, setLoading] = useState(false)
    const [mydownload, setMydownloads] = useState([])
    const [segment, setSegment] = useState(0)
    let getToken = cookies.get('token')
    
    const getMyDownloads = () => {
        
        setLoading(true)
        fetch(apiurl+"api/get_my_downloads", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setMydownloads(response.download)
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    // setTimeout(function(){
                    //     window.$('#downloadtable').dataTable()
                    // }, 500)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
            });
    }

    
    const downloadFile = (item) => {
        
        warningModal("Downloading...", "")
        fetch(apiurl+"api/downloaded", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      download: item.id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    
                    if(item.filetype === "Document"){
                        if(item.downloadfrom === "Assets"){
                            window.open(apiurl + 'api/f7dee8b852c3bdb9cf82bfae8b456220/'+item.fileid)
                        }
                    }else{
                        window.open(assets_url + item.fileurl)
                    }
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
            });
    } 

    var a = 0
    useEffect(() => {
        if(a === 0){
            getMyDownloads()
            a++
        }
    }, [])
    
    return(
        // (pagePermissions(props.pagepermissions, "Assets", "download_permission")) ?
        <>
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            My Downloads
                        </h3>
                    </div>
                    {
                        loading  ? 
                        <>
                            <br /><br /><br />
                            <Loading />
                            <br /><br /><br />
                        </>
                        :
                        mydownload.length > 0 ?
                            <div className='box-body'>
                                <table id="downloadtable" className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                S.No
                                            </th>
                                            <th>
                                                File Name
                                            </th>
                                            <th>
                                                Requested On
                                            </th>
                                            <th>
                                                Response Date
                                            </th>
                                            <th>
                                                Response
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            typeof mydownload[segment] !== "undefined" &&
                                            mydownload[segment].length > 0 &&
                                            mydownload[segment].map((item, index) => {
                                                
                                                moment.locale('en');
                                                var dt = item.updated_at;
                                                var ct = item.created_at;
                                                var response = "Waiting"
                                                if(item.response === 1){
                                                    response = "Accepted"
                                                }else if(item.response === 0){
                                                    response = "Rejected"
                                                }

                                                if(item.downloadfrom === "List"){
                                                    var temptd = [JSON.parse(item.th)]
                                                    var tdArray = JSON.parse(item.td)
                                                    for(var i = 0;i < tdArray.length;i++){
                                                        temptd.push(tdArray[i])
                                                    }
                                                }
                                                return(
                                                    <tr key={"download"+index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>
                                                            {item.filename}
                                                        </td>
                                                        <td>
                                                            {moment(ct).format("DD-MM-YYYY HH:mm:ss")}
                                                        </td>
                                                        <td>
                                                            {(item.updated_at !== item.created_at) ? moment(dt).format("DD-MM-YYYY HH:mm:ss") : '-'}
                                                        </td>
                                                        <td>
                                                            {
                                                                response === "Waiting" &&
                                                                <button className='btn bg-yellow btn-flat'>Wating for response</button>
                                                            }

                                                            {
                                                                response === "Accepted" &&
                                                                <>


                                                            {
                                                                item.downloadfrom === "List" &&
                                                                <>
                                                                    <CSVLink data={temptd} filename={item.filename + ".csv"}  className="btn btn-success btn-flat">Download File</CSVLink>
                                                                </>
                                                            }
                                                            {
                                                                item.downloadfrom === "Attempt" &&
                                                                <>
                                                                    <a href={apiurl+"api/downloadanswersheet/"+item.th} className='btn btn-success btn-flat' target="_blank" title='Download' >
                                                                        Download Attempt
                                                                    </a>
                                                                </>
                                                            }
                                                            {
                                                                item.downloadfrom === "Quiz" &&
                                                                <>
                                                                    <a href={apiurl+"api/downloadtestpaper/"+item.th} className='btn btn-success btn-flat' target="_blank" title='Download' >
                                                                        Download Quiz
                                                                    </a>
                                                                </>
                                                            }
                                                            {
                                                                item.downloadfrom === "Assets" &&
                                                                <button className='btn btn-success btn-flat' onClick={(e) => downloadFile(item)} >Download File</button>
                                                            }

                                                                </>
                                                            }
                                                            {
                                                                response === "Rejected" &&
                                                                <button className='btn btn-danger btn-flat'>Request Rejected</button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        :
                            <div className='box-body text-center no-downloads' >
                                <br /><br />
                                <FeatherIcon icon={"download"} />
                                <p>No Downloads Available</p>
                                <br /><br /><br /><br />
                            </div>
                    }
                    <div className='box-footer'>
                        <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                            {
                                mydownload.length > 0 && mydownload.length <= 10 &&
                                mydownload.map((item, index) => {
                                    const active = index === segment ? " btn-primary" : ""
                                    return(
                                        <button className={'btn btn-flat'+active} key={"key"+index} style={{marginLeft:"5px"}} onClick={() => {
                                            setSegment(index)
                                            document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} >
                                            {index+1}
                                        </button>
                                    )
                                })
                            }
                            {
                                mydownload.length > 10 &&
                                <div style={{width:"200px", float:"right"}}>
                                    <label>Last Page: {mydownload.length}</label>
                                    <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                        setSegment(e.target.value - 1)
                                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                                        }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            

            </>

        // :

        // <Notfound />

    )
  }
  return (
    <>
        {
            
            <Sidebar page={Mydownloadspage} permission="Global"/>
            
        }
    </>
  )
}
