import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Loading from '../includes/Loading'
import { Link } from 'react-router-dom';
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
const cookies = new Cookies();

export default function Managebooks() {

    const ManagebooksPage = (props) => {
        const admin = cookies.get('admin')
        let getToken = cookies.get('token')
        const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()

        const [booksLoading, setBooksLoading] = useState(false)
        const [books, setBook] = useState([])
        const [edit, setEdit] = useState(null)
        const [refresh, setRefresh] = useState("No")
        const [name, setName] = useState("")
        

        const addNewBook = (e) => {
            e.preventDefault();
            formLoading(e.target)
            var formData = new FormData(e.target);
            formData.append('staff', JSON.stringify(AdminBasics()));
            formData.append('accecible', 1);
            formData.append('page_url', window.location.href);
            
            fetch(apiurl+"api/addNewBook", {
                "method": "POST",
                "headers": {
                    // "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": formData
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        $(e.target).trigger('reset')
                        getBooks()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    $(e.target).find(".loaderbody").remove()
                })
                .catch(err => { });
        }

        const rePositionAll = (array) => {
            fetch(apiurl+"api/repositionBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        accecible: 1,
                        position_list: array,
                        page_url: window.location.href,
                        staff: AdminBasics()
                    })
                })
                .then(response => response.json())
                .then(response => {
                })
                .catch(err => { });
        }
    
        const callSorting = () => {
            window.$( ".droppable-area1" ).sortable({
                    stop: function(event, ui) {
                        var array = [];
                        window.$(".dragHandle").each(function (){
                            var a = $(this).attr("data-bind");
                            array.push(a);
                        });
    
                        rePositionAll(array)
                },
                connectWith: ".connected-sortable",
                stack: '.connected-sortable ul',
                handle: ".dragHandle",
            }).disableSelection();
           
        }

        const updateBook = (e) => {
            e.preventDefault();
            formLoading(e.target)
            var formData = new FormData(e.target);
            formData.append('staff', JSON.stringify(AdminBasics()));
            formData.append('accecible', 1);
            formData.append('page_url', window.location.href);
            
            fetch(apiurl+"api/updateBook", {
                "method": "POST",
                "headers": {
                    // "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": formData
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        setEdit(null)
                        getBooks()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    $(e.target).find(".loaderbody").remove()
                })
                .catch(err => { });
        }

        const getBooks = () => {
            if(!booksLoading){
                setBooksLoading(true)
                fetch(apiurl+"api/getBooks", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                            page_url: window.location.href,
                            staff: AdminBasics(),
                            accecible: 1
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            setBook(response.books)
                            
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        setBooksLoading(false)
                    })
                    .catch(err => { });
            }
        }
        

        const searchBook = (value) => {
            $("#bookstable tbody tr").each(function(){
                var name = window.$(this).attr('data-name')
                if(name.includes(value)){
                    window.$(this).show()
                }else{
                    window.$(this).hide()
                }
            });
        }

        const setEditVal = (item) => {
            setEdit(item)
            setName(item.name)
        }

        const removeBook = (item) => {
            if(window.confirm("Are you sure?")){
                fetch(apiurl+"api/removeBook", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                            page_url: window.location.href,
                            staff: AdminBasics(),
                            accecible: 1,
                            id: item.id
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            successModal("Success", response.message)
                            getBooks()
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                    })
                    .catch(err => { });
            }
        }

        const [startdate, setStartDate] = useState("")
        const [enddate, setEndDate] = useState("")

        const [filteredBooks, setFilteredBooks] = useState([])
        const [fileName, setFileName] = useState("")

        const filterBooks = (e) => {
            e.preventDefault()
            formLoading(e.target)
            var formData = new FormData(e.target);
            formData.append("page_url", window.location.href)
            formData.append("staff", JSON.stringify(AdminBasics()))
            formData.append("accecible", 1)
            fetch(apiurl+"api/filterBooks", {
                "method": "POST",
                "headers": {
                    // "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": formData
                })
                .then(response => response.json())
                .then(response => {
                    $(e.target).find(".loaderbody").remove()
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setFilteredBooks(response.books)
                        setFileName(response.name)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => { });
        }

        const downloadList = () => {
            const th = ["S.No", "Book Name", "Opening Stock", "Dispatched", "Other (-)", "Other (+)", "Fresh Units", "Closing Stock", "Pending Stock"];
            // const th = ["Book Name", "Opening Stock", "Dispatched", "Other (-)", "Other (+)", "Fresh Units", "Closing Stock"];
            var td = []
            for(var i = 0;i < filteredBooks.length;i++){
                td.push([
                    (i + 1),
                    filteredBooks[i]["book"]['name'], filteredBooks[i]['openingstock'], filteredBooks[i]['dispatched'], filteredBooks[i]['staffminus'], 
                    filteredBooks[i]['staffplus'], filteredBooks[i]['freshunit'], filteredBooks[i]['closing']
                    , filteredBooks[i]['willdispatch']
                ])
            }
            fetch(apiurl+"api/downloadLists", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        th: th,
                        td: td,
                        for: "Request to Download " + fileName
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => {
                });
        }

        useEffect(() => {
            getBooks()
            window.$("#select2").select2()
        }, [])


        return(
            
            <>
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Filter Books
                            </h3>
                                <a href='' className='label bg-yellow pull-right cursor-pointer'>
                                    Clear Filters
                                </a>
                            {/* <span className='badge bg-red pull-right'>
                                <i className='fa fa-times'></i>
                            </span> */}
                        </div>
                        <form action='' method='GET' onSubmit={(e) => filterBooks(e)} >
                            <div className='box-body'>
                                <div className='col-md-3 no-left-padding'>
                                    <label>Start Date</label>
                                    <input type='date' className='form-control'  name='startdate' onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div className='col-md-3'>
                                    <label>End Date</label>
                                    <input type='date' className='form-control'  name='enddate' onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <label>Books</label>
                                    <select name='books[]' class='form-control' multiple id="select2" data-placeholder="Select Books" >
                                        {
                                            books.length > 0 &&
                                            books.map((item, index) => {
                                                return(
                                                    <option value={item.id} key={"key"+index}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select> 
                                </div>
                            </div>
                            <div className='box-footer'>
                                <button className='btn btn-primary btn-flat btn-block'>
                                    Filter Now
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            {
                filteredBooks.length > 0 &&
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Filtered List
                            </h3>
                            <span className='label bg-green pull-right cursor-pointer' onClick={(e) => downloadList()} >
                                Download?
                            </span>
                        </div>
                        <div className='box-body'>
                            <table id="bookstable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Book</th>
                                        <th>Opening Stock</th>
                                        <th>Dispatched</th>
                                        <th>Other (-)</th>
                                        <th>Other (+)</th>
                                        <th>Fresh Unit</th>
                                        <th>Closing Stock</th>
                                        <th>Pending Stock</th>
                                    </tr>
                                </thead>
                                <tbody>   
                                    {
                                        filteredBooks.map((item, index) => {
                                            return(
                                                <tr key={"key" + index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.book.name}</td>
                                                    <td>
                                                        <a href={'/admin/manage-books/'+item.book.id+"?startdate="+startdate+"&enddate="+enddate+"&type="} target='_blank'>
                                                            {item.openingstock}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={'/admin/manage-books/'+item.book.id+"?startdate="+startdate+"&enddate="+enddate+"&type=Dispatched"} target='_blank'>
                                                            {item.dispatched}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={'/admin/manage-books/'+item.book.id+"?startdate="+startdate+"&enddate="+enddate+"&type=StaffMinus"} target='_blank'>
                                                            {item.staffminus}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={'/admin/manage-books/'+item.book.id+"?startdate="+startdate+"&enddate="+enddate+"&type=StaffPlus"} target='_blank'>
                                                            {item.staffplus}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={'/admin/manage-books/'+item.book.id+"?startdate="+startdate+"&enddate="+enddate+"&type=Fresh Unit"} target='_blank'>
                                                            {item.freshunit}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={'/admin/manage-books/'+item.book.id+"?startdate="+startdate+"&enddate="+enddate+"&type="} target='_blank'>
                                                            {item.closing}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {item.willdispatch}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

                {
                    pagePermissions(props.pagepermissions, "Books_Management", "add_book") &&
                    edit === null &&
                        <div className='col-md-4'>
                            <div className='box no-border'>
                                <div className="box-header">
                                    <h3 className="box-title">Add New Book</h3>
                                </div>
                                <form action='' method='POST' id='addBook' onSubmit={(e) => addNewBook(e)} encType='multipart/form-data' >
                                    <div className='box-body'>
                                        <label>Book Image</label>
                                        <input type='file' name='file' accept='.png,.svg,.jpg,.jpeg,gif,.webp' id='file' className='form-control' />
                                    </div>
                                    <div className='box-body'>
                                        <label>Book Name<span className='text-red'>*</span></label>
                                        <input type='text' name='name' placeholder='e.g CA Foundation 9.0' required className='form-control' />
                                    </div>
                                    <div className='box-body'>
                                        <label>Quantity<span className='text-red'>*</span></label>
                                        <input type='number' name='quantity' placeholder='e.g 10' required className='form-control' />
                                    </div>
                                    <div className='box-footer'>
                                        <button className='btn btn-primary btn-flat btn-block'>
                                            <i className='fa fa-plus'></i> Add New Book
                                        </button>
                                    </div>
                                </form>    
                            </div>
                        </div>
                }
                
                {
                    pagePermissions(props.pagepermissions, "Books_Management", "edit_book") &&
                    edit !== null &&
                        <div className='col-md-4'>
                            <div className='box no-border'>
                                <div className="box-header">
                                    <h3 className="box-title">Edit Book</h3>
                                    <span className='pull-right badge bg-red' onClick={() => setEdit(null)} >
                                        <i className=' fa fa-times'></i>
                                    </span>
                                </div>
                                <form action='' method='POST' id='addBook' onSubmit={(e) => updateBook(e)} encType='multipart/form-data' >
                                    <input type='hidden' name='id' value={edit.id} />
                                    <div className='box-body'>
                                        <label>
                                            Book Image
                                        </label>
                                            {
                                                assets_url + edit.image !== null &&
                                                <a href={ assets_url + edit.image} target='_blank' title='View Image' className='pull-right'>
                                                    <i className='fa fa-eye'></i>
                                                </a>
                                            }
                                        <input type='file' name='file' accept='.png,.svg,.jpg,.jpeg,gif,.webp' id='file' className='form-control' />
                                    </div>
                                    <div className='box-body'>
                                        <label>Book Name<span className='text-red'>*</span></label>
                                        <input type='text' name='name' placeholder='e.g CA Foundation 9.0' required className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className='box-footer'>
                                        <button className='btn btn-primary btn-flat btn-block'>
                                            <i className='fa fa-edit'></i> Update Book
                                        </button>
                                    </div>
                                </form>    
                            </div>
                        </div>
                }

                <div className='col-md-8'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <div className='col-md-8 no-left-padding'>
                                <h3 className='box-title'>
                                    Manage Books ({books.length}) {booksLoading && <span className='text-red'>Loading...</span> }
                                </h3>
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <input type='text' name='search' id='search' placeholder='e.g Seach By name...' className='form-control' onChange={(e) => searchBook(e.target.value.toLowerCase())} />
                            </div>
                        </div>
                        <div className='box-body'onLoad={() => callSorting()}>
                            <table id="bookstable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th style={{width:"80px"}} ></th>
                                        <th >S.No</th>
                                        <th>Title</th>
                                        <th>Add | Last Mod By</th>
                                        <th>Date | Last Updated</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='connected-sortable droppable-area1' >
                                    {
                                        books.length > 0 &&
                                        books.map((item, index) => {
                                            return(
                                                <tr data-name={item.name.toLowerCase()} key={"key"+index} >
                                                    <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td className='text-center'>
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        <div className='booksCon cursor-pointer' >
                                                            <div className='boooksImage'>
                                                                <img src={ assets_url + item.image} alt={item.name} />
                                                            </div>
                                                            <div>
                                                                <a href={'/admin/manage-books/'+item.id}>
                                                                    <strong>{item.name}</strong>
                                                                    <br />
                                                                    <small>
                                                                        <strong>
                                                                            Qty Left:&nbsp; 
                                                                        </strong>
                                                                        {item.quantity}
                                                                    </small>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={'/admin/staff?id='+item.addby.id} target='_blank'>
                                                            {item.addby.name}
                                                        </Link>
                                                        &nbsp;&nbsp;
                                                         |
                                                         &nbsp;&nbsp;
                                                        <Link to={'/admin/staff?id='+item.moddedby.id} target='_blank'>
                                                            {item.moddedby.name}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {item.created_date}<br />
                                                        {item.updated_date}
                                                    </td>
                                                    <td>
                                                        {
                                                            pagePermissions(props.pagepermissions, "Books_Management", "edit_book") &&
                                                            <span className='badge bg-green cursor-pointer' title='Edit Book' onClick={() => setEditVal(item)} >
                                                                <i className='fa fa-edit'></i>
                                                            </span>
                                                        }
                                                        &nbsp;
                                                        
                                                        {
                                                            pagePermissions(props.pagepermissions, "Books_Management", "remove_book") &&
                                                                <span className='badge bg-red cursor-pointer' title='Remove Book' onClick={() => removeBook(item)} >
                                                                    <i className='fa fa-trash'></i>
                                                                </span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Sidebar page={ManagebooksPage} permission="Books_Management"/>
    )
}
