import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
import Adminprops from '../../VelidateWebsite/Adminprops'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import Notfound from '../includes/Notfound';
import Facilites from './Facilites';
import CoursesFacilitiesList from './CoursesFacilitiesList';
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const cookies = new Cookies();

export default function Permissiongroups() {
  const Permissiongroupspage = (props) => {
    
    const params = useParams()
    const [name, setName] = useState("")
    const [list, setList] = useState(Facilites)
    const [Courselist, setCourseList] = useState(CoursesFacilitiesList)

    const [addGroup, setAddGroup] = useState(false)

    const [loading, setLoading] = useState(true)
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, formLoading, pagePermissions, capitalizeFirstLetter} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [submitloading, setSubmitLoading] = useState(false)
    const [groups, setGroups] = useState([])

    const change_permission = (type, page, option) => {
        var permissions = (type === "basic") ? list : Courselist
        if(permissions[page]['staff_facilities'][option]['value'] == true){
            permissions[page]['staff_facilities'][option]['value'] = false
        }else{
            permissions[page]['staff_facilities'][option]['value'] = true
        }
        // update_permissions(permissions)
        if(type === "basic"){
            setList(permissions)
        }else{
            setCourseList(permissions)
        }
        setRefresh(refresh == "No" ? "Yes" : "No")
    }
    const headings = {
        "Assets+view_file_manager": "View Assets",
        "Assets+show_create_files_button": "Add Assets",
        "Assets+edit_folders": "Edit Assets",
        "Assets+remove_folders": "Delete From Assets",
        "Assets+info_folders": "View Info Of File",
        "Assets+url_document": "Copy URL",
        "Assets+view_storage": "View Storage Information",
        "Assets+bulk_selection": "Other Information",
    };
    
    const courseheadings = {
        "show_in_sidebar": "Basic Permissions",
        "test_attempts_tab": "Quiz Attempt Permissions",
        "assignments_tab": "Assignemnts Permissions",
        "view_course_folder": "View Stuff in Course Content",
        "add_course_folder": "Add Stuff in Course Content",
        "edit_course_folder": "Edit Stuff in Course Content",
        "delete_course_folder": "Delete Stuff in Course Content",
        "schedule_course_folder": "Schedule Stuff in Course Content",
        "info_course_folder": "View Info Of Stuff in Course Content",
        "download_course_document": "Download Stuff in Course Content",
        "schedule_course_image": "Schedule Stuff in Course Content",
    };

    const getPermissionGroup = () => {
        setLoading(true)
        fetch(apiurl+"api/get_permission_group", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setLoading(false)
                    setGroups(response.group)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setLoading(false)
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const updatePermissionGroup = () => {
        if(name === ""){
            errorModal("Oops", "Name Cannot be null")
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }else{
            

            fetch( (editId === null) ? apiurl+"api/add_permission_group" : apiurl+"api/update_permission_group", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        staff: AdminBasics(),
                        accecible: 1,
                        page_url: window.location.href,
                        name: name,
                        basic_permission: list,
                        course_permission: Courselist,
                        id: editId
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        setTimeout(function(){
                            window.location.reload()
                        }, 1000)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }
    const [editId, setEditId] = useState(null)
    const coursePermissionsCheck = (permissions_list, pagename , permission) => {
        var permissionArray = permissions_list
        
          if(permissionArray !== null && typeof permissionArray !== "undefined" && permissionArray !== "none"){
            permissionArray = permissionArray.filter(function(item){
                if(item.page_name == pagename){
                  return item 
                }
            })
            if(permissionArray.length > 0){
              var filtered = permissionArray[0].staff_facilities.filter(function(item){
                  if(item.name == permission){
                    return item 
                  }
              })
            }
            
            return ((typeof filtered !== "undefined" && filtered.length > 0) ? filtered[0]['value'] : false)
            
          }else{
            return false
          }     
    }
    const editGroup = (item) => {
        setEditId(item.id)
        setName(item.name)
        var basic_permission = (item.basic_permission === null) ? null : JSON.parse(item.basic_permission)
        var course_permission = (item.course_permission === null) ? null : JSON.parse(item.course_permission)
        
        setAddGroup(true)
        if(basic_permission !== null){
            var permissions = list
            for(var i = 0; i < permissions.length ; i++){
                for(var j = 0; j < permissions[i]['staff_facilities'].length ; j++){
                    permissions[i]['staff_facilities'][j]['value'] = coursePermissionsCheck(basic_permission, permissions[i]['page_name'], permissions[i]['staff_facilities'][j]['name'])
                }
            }
            setList(permissions)
        }
        
        if(course_permission !== null){
            var permissions = Courselist
            for(var i = 0; i < permissions.length ; i++){
                for(var j = 0; j < permissions[i]['staff_facilities'].length ; j++){
                    permissions[i]['staff_facilities'][j]['value'] = coursePermissionsCheck(course_permission, permissions[i]['page_name'], permissions[i]['staff_facilities'][j]['name'])
                }
            }
            setCourseList(permissions)
        }

        setRefresh(refresh == "No" ? "Yes" : "No")

    }


    const removeGroup = (id) => {
        fetch(apiurl+"api/remove_permission_group", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getPermissionGroup()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (item) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeGroup(item.id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }

    var a = 0
    useEffect(() => {
        if(a == 0){
            getPermissionGroup()
            a++
        }
    }, [])

    
    return(
        <>
        {
            addGroup &&
            <>
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            {editId === null ? "Add New Permission Group" : "Updated Permission Group" }
                        </h3>
                        <button className='btn btn-primary btn-flat pull-right' onClick={() => window.location.reload()} >
                            <i className='fa fa-times'></i>
                        </button>
                    </div>
                    <div className='box-body'>
                        <label>Group Name <span style={{color:"red"}} >*</span></label>
                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} className='form-control' placeholder='e.g Sales Group' />
                    </div>
                </div>
            </div>
            
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Basic Permissions
                        </h3>
                    </div>
                </div>
                
                {
                    list.map((item, index) => (
                        <div className='box no-border' key={"itemlist"+index}>
                            <div className='box-header'> 
                                <h3 className='box-title'>
                                    Manage <strong>{item.page_name}</strong>
                                </h3>
                            </div>
                            <div className='box-body'>
                                {
                                    item.staff_facilities.map((item2, index2) => (
                                        <>
                                            {
                                                headings[item.page_name + "+" + item2.name] !== undefined &&
                                                    <div className='box-header no-left-padding' style={{width:"100%", borderTop:"2px solid #58617a", marginTop:"20px"}} key={"bt"+index2} >
                                                        <h3 className='box-title'>
                                                            {headings[item.page_name + "+" + item2.name]}
                                                        </h3>
                                                    </div>
                                            }

                                            <button onClick={() => change_permission("basic", index, index2)} className={(item2.value == true) ? 'btn btn-flat my-btns btn-primary' : 'btn btn-flat my-btns'} title={capitalizeFirstLetter(item2.name.replace(/_/g, " "))} key={"btn"+index2}>
                                                {item2.name.replace(/_/g, " ")}
                                            </button>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                        ))
                    
                    }
            </div>
            
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Course Permissions
                        </h3>
                    </div>
                </div>
                <div className='box no-border'>
                    <div className='box-body'>
                        
                        {
                            Courselist[0]['staff_facilities'].map((item2, index2) => {
                                // var ctababval = ""
                                // if(courseheadings[item2.name] !== undefined){
                                //     // console.log(headings[0])
                                //     var objarr = Object.values(headings)
                                //     aa = objarr.indexOf(courseheadings[item2.name])
                                // }
                                
                                return(
                                    <>
                                        {
                                            courseheadings[item2.name] !== undefined &&
                                                <div className='box-header no-left-padding' style={{width:"100%", borderTop:"2px solid #58617a", marginTop:"20px", marginBottom:"10px"}} key={"bt"+index2} >
                                                    <div style={{ display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}} >
                                                        <h3 className='box-title'>
                                                            {courseheadings[item2.name]}
                                                        </h3>
                                                    </div>
                                                </div>
                                        }

                                        <button className={(item2.value == true) ? 'btn btn-flat my-btns btn-primary ' : "btn btn-flat my-btns " } onClick={() => change_permission("course", 0, index2)} key={"btn"+index2} >
                                            {item2.name.replace(/_/g, " ")}
                                        </button>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {
                submitloading ?
                <button className='btn btn-flat btn-block btn-warning'style={{width:"100%", position:"fixed", bottom:"20px", right:"15px", maxWidth:"100px"}} >
                    Loading...
                </button>
                :
                <button style={{width:"100%", position:"fixed", bottom:"20px", right:"15px", maxWidth:"100px"}} className='btn btn-danger btn-flat btn-block'
                onClick={() => updatePermissionGroup()}
                >
                    <i className='fa fa-save'></i> Save
                </button>
            }
            </>
        }
        {
            
        !addGroup &&
        <>
            { 
                loading ? 
                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-body' style={{position:"relative"}} >
                            <br /><br /><br />
                                <Loading />
                            <br /><br /><br />
                        </div>
                    </div>
                </div>

                :
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Permission Groups
                            </h3>
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => setAddGroup(true)} >
                                <i className='fa fa-plus'></i>
                            </button>
                        </div>
                        {
                            groups.length < 1 &&
                            <div className='box-body' style={{textAlign:"center"}} >
                                <p>No Groups Found</p>
                            </div>
                        }
                        {
                            groups.length > 0 &&
                            <div className='box-body' >
                                <table id="staff_table" className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th style={{textAlign:"center"}} >
                                                S.No
                                            </th>
                                            <th >
                                                Name
                                            </th>
                                            <th style={{textAlign:"center"}} >
                                                Basic Permissions
                                            </th>
                                            <th style={{textAlign:"center"}} >
                                                Course Permissions
                                            </th>
                                            <th style={{textAlign:"center"}} >
                                                Group Used By
                                            </th>
                                            <th>
                                                Created At
                                            </th>
                                            <th>
                                                Last Modified At
                                            </th>
                                            <th>
                                                Created By
                                            </th>
                                            <th>
                                                Last Modified By
                                            </th>
                                            <th style={{textAlign:"center"}} >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        groups.map((item, index) => {
                                            moment.locale('en');
                                            var ct = item.created_at; 
                                            var ut = item.updated_at; 
                                            var staff = (item.staff !== null) ? JSON.parse(item.staff) : null
                                            var lastmod = (item.lastmod !== null) ? JSON.parse(item.lastmod) : null
                                            return(
                                                <tr key={"list"+index} >
                                                    <td style={{textAlign:"center"}} >
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td style={{textAlign:"center"}} >
                                                        {
                                                            item.basic_permission !== null ?
                                                            <small className='label bg-green'>
                                                                <i className='fa fa-check'></i>
                                                            </small>
                                                            :
                                                            <small className='label bg-red'>
                                                                <i className='fa fa-times'></i>
                                                            </small>
                                                        }
                                                    </td>
                                                    <td style={{textAlign:"center"}} >
                                                        {
                                                            item.course_permission !== null ?
                                                            <small className='label bg-green'>
                                                                <i className='fa fa-check'></i>
                                                            </small>
                                                            :
                                                            <small className='label bg-red'>
                                                                <i className='fa fa-times'></i>
                                                            </small>
                                                        }
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {
                                                            item.usedin > 0 ?
                                                            <small className='label bg-green'>
                                                                {item.usedin}
                                                            </small>
                                                            :
                                                            <small className='label bg-red'>
                                                                0
                                                            </small>
                                                        }
                                                    </td>
                                                    <td>{moment(ct).format("D-M-Y HH:mm:ss")}</td>
                                                    <td>{moment(ut).format("D-M-Y HH:mm:ss")}</td>
                                                    <td>
                                                        
                                                        {
                                                            staff !== null &&
                                                        <>
                                                            { staff.name } (<small>{ staff.role }</small>)<br />
                                                                <strong>
                                                                        <a href={ "mailto:" + staff.email } title={"Mail On " + staff.email } >
                                                                        { staff.email }
                                                                        </a>
                                                                </strong><br />
                                                                <strong>
                                                                        <a href={ "tel:" + staff.contact } title={"Call On " + staff.contact } >
                                                                        { staff.contact }
                                                                        </a>
                                                                </strong><br />
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        
                                                        {
                                                            lastmod !== null &&
                                                        <>
                                                            { lastmod.name } (<small>{ lastmod.role }</small>)<br />
                                                                <strong>
                                                                        <a href={ "mailto:" + lastmod.email } title={"Mail On " + lastmod.email } >
                                                                        { lastmod.email }
                                                                        </a>
                                                                </strong><br />
                                                                <strong>
                                                                        <a href={ "tel:" + lastmod.contact } title={"Call On " + lastmod.contact } >
                                                                        { lastmod.contact }
                                                                        </a>
                                                                </strong><br />
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={{textAlign:"center"}} >
                                                        <button className='btn btn-primary btn-flat' onClick={() => {
                                                            editGroup(item)
                                                        }} >
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                        <button className='btn btn-danger btn-flat' onClick={() => {
                                                            removeDialog(item)
                                                        }} >
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                        
                                                )
                                            })
                                }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
        }
        </>
    )
  }
  return (
    <>
      <Sidebar page={Permissiongroupspage} permission={"Permission_Groups"}/>
    </>
  )
}
