import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../../includes/Common';
import Cookies from 'universal-cookie';
import apiurl from '../../../Global';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

const cookies = new Cookies();

export default function Myprofile(props) {
  
  const navigate = useNavigate()
  const params = useParams()
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
  
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState("")
  const [name, setName] = useState(props.user.name)
  const [email, setEmail] = useState(props.user.email)
  const [contact, setContact] = useState(props.user.contact)
  const [password, setPassword] = useState("")
  const [maxlogin, setMaxlogin] = useState(props.user.maxlogin)
  

const addStudent = (e) => {
    e.preventDefault()
    formLoading(e.target)
    
    const formData = new FormData();
    formData.append('staff', JSON.stringify(AdminBasics()));
    formData.append('accecible', 1);
    formData.append('page_url', window.location.href);
    formData.append('name', name)
    formData.append('email', email);
    formData.append('contact', contact);
    formData.append('image', image);
    formData.append('password', password);
    formData.append('maxlogin', maxlogin);
    formData.append('userid', params.userid);

    var url = 'update_student'
    fetch(apiurl+"api/"+url, {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
        })
        .then(response => response.json())
        .then(response => {
            
            $(e.target).find(".loaderbody").fadeOut()
            setTimeout(function(){  
                $(e.target).find(".loaderbody").remove()
            }, 1000);
            if(typeof response.status !== "undefined" && response.status === "OK"){
                successModal("Success", response.message)
                props.updateUser()
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            // setLoading(false)
        })
        .catch(err => {
            // console.log(err);
        });


}

  var a = 0  
  useEffect(() => {
    if(a === 0)    {
      a++
    }
  }, [])

  return (
    <>
    <br />
      <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>Update Student</h3>
                    </div>
                    <form action='' method='POST' onSubmit={(e) => addStudent(e)} >

                        <div className='box-body'>
                            <label>
                                Student Profile Photo
                            </label>
                            <input type={"file"} className={"form-control"} placeholder={"e.g John Doe"} onChange={(e) => setImage(e.target.files[0])} accept={".png,.jpg,.jpeg"} />
                        </div>
                        <div className='box-body'>
                            <label>
                                Student Name
                            </label>
                            <input type={"text"} required value={name} className={"form-control"} placeholder={"e.g John Doe"} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>
                                Student Email
                            </label>
                            <input type={"email"} required value={email} className={"form-control"} placeholder={"e.g info@example.com"} onChange={(e) => setEmail(e.target.value)}  />
                        </div>
                        <div className='box-body'>
                            <label>
                                Student Contact
                            </label>
                            <input type={"text"} value={contact} className={"form-control"} placeholder={"e.g 9711111111"} onChange={(e) => setContact(e.target.value)}  />
                        </div>
                        <div className='box-body'>
                            <label>
                                Student Max Login
                            </label>
                            <input type={"number"} required value={maxlogin} className={"form-control"} placeholder={"e.g 10"} onChange={(e) => setMaxlogin(e.target.value)}  />
                        </div>
                        <div className='box-body'>
                            <label>
                                Student Password
                            </label>
                            <input type={"text"} value={password} className={"form-control"} placeholder={"e.g Escholar@123"} onChange={(e) => setPassword(e.target.value)}  />
                        </div>
                        <div className='box-footer'>
                            <button className='btn btn-primary btn-flat btn-block'>
                                <i className='fa fa-save'></i> &nbsp; Update Student
                            </button>
                        </div>
                    </form>
                </div>
            </div>
    </>
  )
}
