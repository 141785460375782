import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar';
import Cookies from 'universal-cookie';
import CommonFunc from '../includes/Common';
import { useParams } from 'react-router-dom';
import base_config from '../../Config';
import apiurl from '../../Global';
import Loading from '../includes/Loading';
const cookies = new Cookies();

export default function Shopifylink() {
    const ShopifylinkPage = (props) => {
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    const shopifyLinks = () => {
        setLoading(true)
        fetch(apiurl+"api/shopifyLinks", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setList(response.list)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
            });
    }
    const copyText = (text, target) => {
        navigator.clipboard.writeText(text)
        window.$(target).attr("class", "label bg-green");
        window.$(target).text("Link Copied");
        setTimeout(function(){
            window.$(target).attr("class", "label bg-red");
            window.$(target).text("Copy Link");
        }, 5000)
    }

    var a = 0
    useEffect(() => {
        if(a === 0) {
            shopifyLinks()
            a++
        }
    },[])
    return(
        <>
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Get Courses Links
                        </h3>
                        {
                            loading &&
                            <span className='label bg-red pull-right'>
                                Loading...
                            </span>
                        }
                    </div>
                    <div className='box-body'>
                        <table id="studenttable" className="table table-bordered table-hover file_manager_table">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>Name</th>
                                    <th>Live Link</th>
                                    <th>Preview Link</th>
                                    <th>Shopify Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.length > 0 &&
                                    list.map((item, index) => {
                                        return(
                                            
                                            <tr key={"in" + index}>
                                                <td>{index + 1}</td>

                                                <td className='file_name_cell' >
                                                    <div className='fileTitleList'>
                                                        <img src={ base_config.assets_url + item.thumbnail} style={{width:"40px", height:"40px"}} />
                                                        {item.name}
                                                    </div>
                                                </td>

                                                <td><span className='label bg-red' style={{cursor:"pointer"}} onClick={(e) => copyText(item.liveURL, e.target)}>Copy Live URL</span></td>
                                                <td><span className='label bg-red' style={{cursor:"pointer"}} onClick={(e) => copyText(item.previewURL, e.target)}>Copy Preview URL</span></td>
                                                <td><span className='label bg-red' style={{cursor:"pointer"}} onClick={(e) => copyText(item.shopifyURL, e.target)}>Copy Shopify URL</span></td>
                                            </tr>
                                            
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
  }
  return (
    <>
      <Sidebar page={ShopifylinkPage} permission="Shopify_Links"/>
    </>
  )
}
