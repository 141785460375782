import React, { useState, useEffect, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Adminprops from '../../VelidateWebsite/Adminprops'
import Cookies from 'universal-cookie'
import CommonFunc from '../includes/Common'
import Notfound from '../includes/Notfound'
import FeatherIcon from 'feather-icons-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading'
import Dropzone from 'react-dropzone'
import axios, {CancelToken, isCancel} from 'axios';


const cookies = new Cookies();

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
export default function Questions() {

      
  // var a = 0
  // useEffect(() => {
  //   if(a === 0){  
  //     setTimeout(function(){
  //       window.location.href = "#"
  //       a++
  //     }, 1000)
  //   }
  // }, [])


  const Question = (props) => {



    var rules = {}
    rules["Objective"] = {
          "question": true,
          "option1": true,
          "option2": true,
          "option3": true,
          "option4": true,
          "option5": true,
          "option6": true,
          "explanation": true,
          "answertype": true,
          "answer": true,
          "answerplaceholder": "e.g 1",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Subjective"] = {
          "question": true,
          "option1": false,
          "option2": false,
          "option3": false,
          "option4": false,
          "option5": false,
          "option6": false,
          "explanation": true,
          "answertype": false,
          "answer": false,
          "answerplaceholder": "e.g Agra",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Fill_In_The_Blanks"] = {
          "question": true,
          "option1": false,
          "option2": false,
          "option3": false,
          "option4": false,
          "option5": false,
          "option6": false,
          "explanation": true,
          "answertype": false,
          "answer": true,
          "answerplaceholder": "e.g Agra",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Match_The_Following"] = {
          "question": true,
          "option1": true,
          "option2": true,
          "option3": true,
          "option4": true,
          "option5": true,
          "option6": true,
          "explanation": true,
          "answertype": true,
          "answer": true,
          "answerplaceholder": "e.g 1",
          "answermultiplaceholder": "e.g 2,3",
        }
      rules["True_And_False"] = {
            "question": true,
            "option1": false,
            "option2": false,
            "option3": false,
            "option4": false,
            "option5": false,
            "option6": false,
            "explanation": true,
            "answertype": false,
            "answer": true,
            "answerplaceholder": "e.g 1",
            "answermultiplaceholder": "e.g 2,3",
          }

    const cancelFileUpload = useRef(null)
    const query = useQuery();
    const qid = (query.get("qid") !== null) ? query.get("qid") : "";
    const [mainqid, setmainqid] = useState(query.get("qid"));
    
    const [segment, setSegment] = useState(0)

    const navigate = useNavigate()
    const params = useParams()
    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail} = CommonFunc()
    const[refresh, setRefresh] = useState("No") 
    const[tab, setTab] = useState("")
    const[p_id, setPid] = useState((typeof params.p_id) !== "undefined" ? params.p_id : 0);
    const[main_id, setMainId] = useState((typeof params.id) !== "undefined" ? params.id : 0);
    const[pathList, setPathList] = useState([])
    const[filesList, setFilesList] = useState([])
    const[loading, setLoading] = useState(true)
    const[comprehensive, setComprehensive] = useState(false)
    
    const[essay, setEssay] = useState("")
    const[editID, setEditID] = useState("")
    const[question, setQuestion] = useState("")
    const[option1, setOption1] = useState("")
    const[option2, setOption2] = useState("")
    const[option3, setOption3] = useState("")
    const[option4, setOption4] = useState("")
    const[option5, setOption5] = useState("")
    const[option6, setOption6] = useState("")
    const[explanation, setExplanation] = useState("")
    const[posMarks, setPosMarks] = useState("")
    const[negMarks, setNegMarks] = useState("")
    const[answerType, setAnswerType] = useState("")
    const[correct, setCorrectAnswer] = useState("")
    const[plainName, setPlainName] = useState("")
    const[subject, setSubject] = useState("")
    const[topic, setTopic] = useState("")
    const[tags, setTags] = useState("")
    const[position, setposition] = useState(0)
    const[viewQues, setViewQuestion] = useState(false)
    const [errorsList, setErrorsList] = useState(null);

    const getChildList = (pid, mainid) => {
      setLoading(true)
      fetch(apiurl+"api/child_list", {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                  accecible: 1,
                  p_id: pid,
                  main_id: mainid,
                  page_url: window.location.href,
                  staff: JSON.stringify(AdminBasics()),
                  cdFiltes:"Question"
              })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status == "OK"){
                  setFilesList(response.files)
                  setPathList(response.path)
                  setposition(response.files.length + 1)
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              setLoading(false)
              callView()
              // setTimeout(function(){
              //   alert()
              //   window.$("#viewQuestion"+qid).click();
              // }, 2000)
              
          })
          .catch(err => {
              // console.log(err);
          });
    }
    
    const changeFolder = (mainid, pid) => {
      setPid(pid)
      setMainId(mainid)
      navigate("/admin/assets/"+mainid+"/"+pid)
      getChildList(pid, mainid)
  }

  const cleanOptions = () => {
    setEssay("")
    setQuestion("")
    setOption1("")
    setOption2("")
    setOption3("")
    setOption4("")
    setOption5("")
    setOption6("")
    setExplanation("")
    setPosMarks("")
    setNegMarks("")
    setAnswerType("")
    setCorrectAnswer("")
    setSubject("")
    setTopic("")
    setTags("")
    setEditID("")
  }

  const changeTab = (tab) => {
    setViewQuestion(false)
    setLoading(true)
    
    setTab(tab)
    cleanOptions()
    setEditors("", "", "", "", "", "", "", "", "")
    setTimeout(function(){
      setLoading(false)
    }, 3000)
  }

  const addQuestion = () => {

    const formData = new FormData();

    if(question === ""){
      errorModal("Oops!", "Question Cannot Be Null")
    }else{
      setLoading(true)
      formData.append('id', editID);
      formData.append('p_id', p_id);
      formData.append('main_id', main_id);
      formData.append('type', "Question");
      formData.append('question_type', tab);
      
      formData.append('essay', (comprehensive) ? essay : "");

      formData.append('question', question);
      formData.append('option1', option1);
      formData.append('option2', option2);
      if(tab === "True_And_False"){
        formData.append('option1', "True");
        formData.append('option2', "False");
      }
      formData.append('option3', option3);
      formData.append('option4', option4);
      formData.append('option5', option5);
      formData.append('option6', option6);
      formData.append('explanation', explanation);
      formData.append('posMarks', posMarks);
      formData.append('negMarks', negMarks);
      formData.append('answerType', answerType);
      formData.append('correct', correct);
      formData.append('plainName', plainName);
      formData.append('subject', subject);
      formData.append('topic', topic);
      formData.append('tags', tags);
      
      formData.append('staff', JSON.stringify(AdminBasics()));
      formData.append('accecible', 1);
      formData.append('page_url', window.location.href);
      formData.append('position', position);


      
      fetch( (editID === "") ? apiurl+"api/add_question" : apiurl+"api/edit_question" , {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status == "OK"){
                if(editID === ""){
                  successModal("Success", "Question Added Successfully")
                }else{
                  successModal("Success", "Question Updated Successfully")
                }
                cleanOptions()
                getChildList(p_id, main_id)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        });
      }
  }

  const editQuestion = (question) => {
      cleanOptions()
      changeTab(question.question_type)
      if(question.essay !== null){
        setComprehensive(true)
      }
      var ansType = (question.correct !== null) ? JSON.parse(question.correct) : ""
      setEditID(question.id)
      setEssay(question.essay !== null ? question.essay : "")
      setQuestion(question.question !== null ? question.question : "")
      setOption1(question.option1 !== null ? question.option1 : "")
      setOption2(question.option2 !== null ? question.option2 : "")
      setOption3(question.option3 !== null ? question.option3 : "")
      setOption4(question.option4 !== null ? question.option4 : "")
      setOption5(question.option5 !== null ? question.option5 : "")
      setOption6(question.option6 !== null ? question.option6 : "")
      setExplanation(question.explanation !== null ? question.explanation : "")
      setPosMarks(question.posMarks !== null ? question.posMarks : "")
      setNegMarks(question.negMarks !== null ? question.negMarks : "")
      setAnswerType(question.answerType !== null ? question.answerType : "")
      setCorrectAnswer(ansType.join(","))
      setSubject(question.subject !== null ? question.subject : "")
      setTopic(question.topic !== null ? question.topic : "")
      setTags(question.tags !== null ? question.tags : "")
      window.$('html,body').scrollTop(0);
      setEditors(
        question.essay !== null ? question.essay : "", 
        question.question !== null ? question.question : "", 
        question.option1 !== null ? question.option1 : "", 
        question.option2 !== null ? question.option2 : "", 
        question.option3 !== null ? question.option3 : "", 
        question.option4 !== null ? question.option4 : "", 
        question.option5 !== null ? question.option5 : "", 
        question.option6 !== null ? question.option6 : "", 
        question.explanation !== null ? question.explanation : ""
      )
  }

  const viewQuestion = (question) => {
    changeTab(question.question_type)
    setViewQuestion(true)
    if(question.essay !== null){
      setComprehensive(true)
    }
    var ansType = (question.correct !== null) ? JSON.parse(question.correct) : ""
    setEssay(question.essay !== null ? question.essay : "")
    setQuestion(question.question !== null ? question.question : "")
    setOption1(question.option1 !== null ? question.option1 : "")
    setOption2(question.option2 !== null ? question.option2 : "")
    setOption3(question.option3 !== null ? question.option3 : "")
    setOption4(question.option4 !== null ? question.option4 : "")
    setOption5(question.option5 !== null ? question.option5 : "")
    setOption6(question.option6 !== null ? question.option6 : "")
    setExplanation(question.explanation !== null ? question.explanation : "")
    setPosMarks(question.posMarks !== null ? question.posMarks : "")
    setNegMarks(question.negMarks !== null ? question.negMarks : "")
    setAnswerType(question.answerType !== null ? question.answerType : "")
    setCorrectAnswer(ansType.join(","))
    setSubject(question.subject !== null ? question.subject : "")
    setTopic(question.topic !== null ? question.topic : "")
    setTags(question.tags !== null ? question.tags : "")
    window.$('html,body').scrollTop(0);
    updatTrail("Viewed "+question.name+" Question ("+question.question_type+")", "Assets", "1")
  }

  
  const bulkUpload = (files, type) => {
      window.$(".zipUploading").fadeIn()
      const formData = new FormData();
      formData.append('p_id', p_id);
      formData.append('main_id', main_id);
      formData.append('type', "Question");
      formData.append('staff', JSON.stringify(AdminBasics()));
      formData.append('accecible', 1);
      formData.append('page_url', window.location.href);
      formData.append('position', position);
      formData.append('file', files[0]);
      setErrorsList(null)
      axios({
        method: 'post',
        url: apiurl+"api/upload_zip",
        data: formData,
        headers: {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        onUploadProgress:(data)=>{
            var perc = Math.round((data.loaded / data.total) *100)
            window.$(".zipInsideBar").animate({width: perc+"%"});
        },
          cancelToken: new CancelToken( cancel => cancelFileUpload.current = cancel)
      })
      .then(function (response) {
          console.log(response.data)
          getChildList(p_id, main_id)
          window.$(".zipUploading").fadeOut()

          setErrorsList(typeof response.data.errorlist !== "undefined" ? response.data.errorlist : null)
          if(typeof response.data.status !== "undefined" && response.data.status == "OK"){
            successModal("Success", response.data.message)
          }else if(typeof response.data.message !== "undefined" && response.data.status !== "OK"){
              // alert(response.message)
              if(typeof response.data.errors !== "undefined"){
                  var i = 0
                  $.each(response.data.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                      console.log(i)
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.data.message)
              }
          }
          

          setRefresh(refresh == "No" ? "Yes" : "No")
      })
      .catch(function (response) {
          setRefresh(refresh == "No" ? "Yes" : "No")
          window.$(".zipUploading").fadeOut()
          console.log(response, response.message)
          if(response.message !== undefined){
              errorModal("Oops Something Went Wrong", response.response.data.message)
          }
      });
  }


  const [essayEditor, setEssayEditor] = useState("")
  const [questionEditor, setQuestionEditor] = useState("")
  const [option1Editor, setOption1Editor] = useState("")
  const [option2Editor, setOption2Editor] = useState("")
  const [option3Editor, setOption3Editor] = useState("")
  const [option4Editor, setOption4Editor] = useState("")
  const [option5Editor, setOption5Editor] = useState("")
  const [option6Editor, setOption6Editor] = useState("")
  const [explanationEditor, setExplanationEditor] = useState("")

  const setEditors = (essay, question, option1, option2, option3, option4, option5, option6, explanation) => {
    setLoading(true)
    setEssayEditor("")
    setQuestionEditor("")
    setOption1Editor("")
    setOption2Editor("")
    setOption3Editor("")
    setOption4Editor("")
    setOption5Editor("")
    setOption6Editor("")
    setExplanationEditor("")
    setTimeout(function(){
      setQuestionEditor(
        <CKEditor
            editor={ ClassicEditor } 
            
            config={{
                simpleUpload: {
                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                },
            }}

            data={question}   
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setQuestion(data)
                setPlainName(data.replace(/<[^>]*>?/gm, ''))
            } }
        />
      )
      setEssayEditor(
        <CKEditor
              editor={ ClassicEditor }
              
              config={{
                  simpleUpload: {
                      uploadUrl: apiurl+'api/ckeditor/image_upload',
                  },
              }}
              data={essay}   
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setEssay(data)
                  // console.log( { event, editor, data } );
              } }
          />
      )
      
      setOption1Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={option1} onChange={ ( event, editor ) => { const data = editor.getData(); setOption1(data) } } />
      )
      setOption2Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={option2} onChange={ ( event, editor ) => { const data = editor.getData(); setOption2(data) } } />
      )
      setOption3Editor(
        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }}  data={option3} onChange={ ( event, editor ) => { const data = editor.getData(); setOption3(data) } } />
      )
      setOption4Editor(
        <CKEditor editor={ ClassicEditor }  config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={option4} onChange={ ( event, editor ) => { const data = editor.getData(); setOption4(data) } } />
      )
      setOption5Editor(
        <CKEditor editor={ ClassicEditor }  config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={option5} onChange={ ( event, editor ) => { const data = editor.getData(); setOption5(data) } } />
      )
      setOption6Editor(
        <CKEditor editor={ ClassicEditor }  config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={option6} onChange={ ( event, editor ) => { const data = editor.getData(); setOption6(data) } } />
      )
      setExplanationEditor(
        <CKEditor
              editor={ ClassicEditor }
              config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} 
              data={explanation}   
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setExplanation(data)
                  // console.log( { event, editor, data } );
              } }
          />
      )
      setRefresh(refresh !== "No" ? "No" : "Yes" )
        setLoading(false)
        // setViewQuestion(false)
      },100)
  }

  const callView = () => {
    console.log("Called")
    setTimeout(function(){
      if(mainqid !== null){
        window.$("#viewQuestion"+mainqid).click()
        setmainqid(null)
      }

    }, 1000)
  }

    var b = 0
    useEffect(() => {
      if(b === 0){
        
        getChildList(p_id, main_id)
        b++
      }
    }, [])

    return(
      
      !pagePermissions(props.pagepermissions, "Assets", "add_question") ? 
      <Notfound />
      :
      <>
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    {tab.replace(/_/g, " ")} Question
                  </h3>
                  
                
                {
                    pathList.length > 0 &&
                    <div className='pull-right breadCrums'>
                        <ul>
                            <li onClick={() => navigate("/admin/assets/")}>
                                Assets <FeatherIcon icon={"chevron-right"}/>
                            </li>
                        {
                            pathList.map((item, index) => (
                                <li title={item.name} onClick={() => changeFolder(main_id, item.id)} key={"path"+index}>
                                    {item.name}
                                    <FeatherIcon icon={"chevron-right"} />
                                </li>    
                            ))
                        }
                        </ul>    
                    </div>
                }
                </div>

              {
                errorsList !== null && errorsList.length > 0 &&
                  <div className='box-body'>
                    <div className="alert alert-danger alert-dismissible">
                      <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                      <h4><i className="icon fa fa-ban"></i> Alert!</h4>
                      <ul>
                        {
                          errorsList.map((item, index) => (
                           <li key={"index"+index}>{item}</li>
                          ))
                        }
                      </ul>
                    </div>
                  </div> 
              }

              </div>
              
        </div>
      {
        pagePermissions(props.pagepermissions, "Assets", "add_question") &&
      
          <div className='col-md-3'> 
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className='box-title'>
                    Add Questions
                </h3>
              </div>
              

              <div className='box-body'>
                <ul className='assets_list'>

                  <li onClick={() => changeTab("Objective")} className={(tab === "Objective") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/answer.png'} />
                          Objective Question
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                    
                  </li>
                  <li onClick={() => changeTab("Subjective")} className={(tab === "Subjective") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/qa.png'} />
                          Subjective Question
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>

                  <li onClick={() => changeTab("Fill_In_The_Blanks")} className={(tab === "Fill_In_The_Blanks") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/text-box.png'} />
                          Fill In The Blanks
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>

                  <li onClick={() => changeTab("True_And_False")} className={(tab === "True_And_False") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/decision.png'} />
                          True And False
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>

                  <li onClick={() => changeTab("Match_The_Following")} className={(tab === "Match_The_Following") ? 'active-assets-menu' : ""}>
                      <span>
                          <img src={process.env.PUBLIC_URL+'/assets/assets/images/jigsaw.png'} />
                          Match The Following
                      </span>
                      <span className='arrow-r'>
                          <FeatherIcon icon={"arrow-right"} />
                      </span>
                  </li>
                </ul>
              </div>
                
            </div>
            
            <div className='orCon'>
                <div className='leftbar'></div>
                <span className='text-center'>OR</span> 
                <div className='rightbar'></div>
            </div>
            <br />
            <div className='box no-border'>
              <div className='box-body'>
                <Dropzone onDrop={acceptedFiles => bulkUpload(acceptedFiles, "Document")}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <section>
                        <div {...getRootProps()} className={isDragActive ? "draggable-area activedrag" : "draggable-area"}>
                            <input {...getInputProps()} accept=".zip" />
                            
                            {
                                isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some zip file here, or click to select file</p>
                            }

                        </div>
                        </section>
                    )}
                </Dropzone>
              </div>
            </div>
          </div>
    }
    
          <div className='col-md-9'>

    {
        tab !== "" ?
        <>
          {
            !viewQues && pagePermissions(props.pagepermissions, "Assets", "add_question") &&
            <>
                
            {
                loading &&
                <div style={{position:"absolute", top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.5)", zIndex:9}}>
                    <Loading />
                </div>
            }
              
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                      Comprehensive Question?
                  </h3>
                  
                  <div className='pull-right breadCrums'>
                    <label className="switch">
                        <input type="checkbox" value={(comprehensive) ? 1 : 0} defaultChecked={false} onChange={e => setComprehensive((comprehensive) ? false : true)}/>
                        <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {
                  
                  <div className='box-body' style={{display: (!comprehensive && "none")}}>
                        {essayEditor}
                    </div>
                }
              </div>
              
              <div className='box no-border'>
                <div className='box-body' style={{display: (!rules[tab]['question'] && "none")}}>
                    <label>Question</label>
                    {questionEditor}
                    
                  </div>
                  {
                    
                    <div className='box-body' style={{display: (!rules[tab]['option1'] && "none")}}>
                      <label>Option 1</label>
                        {option1Editor}
                    </div>
                  }
                  {
                    // rules[tab]['option2'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option2'] && "none")}}>
                    <label>Option 2</label>
                      {option2Editor}
                  </div>
                }
                {
                    // rules[tab]['option3'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option3'] && "none")}}>
                    <label>Option 3</label>
                      {option3Editor}
                  </div>
                }
                {
                    // rules[tab]['option4'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option4'] && "none")}}>
                    <label>Option 4</label>
                      {option4Editor}
                  </div>
                }
                {
                    // rules[tab]['option5'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['option5'] && "none")}}>
                    <label>Option 5</label>
                      {option5Editor}
                  </div>
                }
                {
                    // rules[tab]['option6'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['option5'] && "none")}}>
                    <label>Option 6</label>
                      {option6Editor}
                  </div>
                }
                {
                    // rules[tab]['explanation'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['explanation'] && "none")}}>
                    <label>Explanation</label>
                      {explanationEditor}
                  </div>
                }

                  <div className='box-body'>
                    <div className='col-md-4' style={{paddingLeft:0, display:"none"}}>
                      <label>Position</label>
                      <input type={"number"} name={"position"} className="form-control" placeholder='e.g 2' value={position} onChange={(e) => setposition(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{paddingLeft:0}}>
                      <label>Subject</label>
                      <input type={"text"} name={"subject"} className="form-control" placeholder='e.g Business Environment' value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{paddingRight:0}}>
                        <label>Topic</label>
                        <input type={"text"} name={"subject"} className="form-control" placeholder='e.g Business Environment Test' value={topic} onChange={(e) => setTopic(e.target.value)} />
                    </div>
                  </div>
                  <div className='box-body'>
                    <label>Tag(s)</label>
                    <input type={"text"} name={"tags"} className="form-control" placeholder='e.g Fill in blanks Test' value={tags} onChange={(e) => setTags(e.target.value)} />
                  </div>

                  <div className='box-body'>
                    <div className='col-md-3' style={{paddingLeft:0}}>
                      <label>(+ve Marks)</label>
                      <input type={"number"} step={0.01} name={"answer"} className="form-control" placeholder='e.g 1' value={posMarks} onChange={(e) => setPosMarks(e.target.value)} />
                    </div>
                    <div className='col-md-3'>
                      <label>(-ve Marks)</label>
                      <input type={"number"} step={0.01} name={"answer"} className="form-control" placeholder='e.g 0.25' value={negMarks}  onChange={(e) => setNegMarks(e.target.value)} />
                    </div>
                  {
                    rules[tab]['answertype'] &&
                    <div className='col-md-3'>
                      <label>Answer Type</label>
                      <select name='' className='form-control' defaultValue={ (answerType === null) ? "Single" : answerType} onChange={(e) => setAnswerType(e.target.value)}>
                        <option value={"Single"}>Single Correct</option>
                        <option value={"Multi"} >Multi Correct</option>
                      </select>
                    </div>
                  }
                  {
                    rules[tab]['answer'] &&
                    <div className='col-md-3' style={{paddingRight:0}}>
                      <label>Correct Answer</label>
                      <input type={"text"} name={"answer"} className="form-control" value={correct} placeholder={rules[tab]['answerplaceholder']} onChange={(e) => setCorrectAnswer(e.target.value)} />
                    </div>
                  }
                  </div>
                  <div className='box-footer'>
                    <button className='btn btn-primary btn-flat pull-right btn-block' onClick={() => addQuestion()}>
                      {
                        editID === "" ? "Add Question" : "Update Question"
                      }
                    </button>
                  </div>
              </div>
          </>
        }
        </>
        :
        <div className='box no-border'>
          <div className='box-header'>
              <h3 className='box-title'>
                You havan't selected any question type yet
              </h3>
          </div>
        </div>
    }

        {
          viewQues &&
          <>
            {
              comprehensive &&
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Comprehensive
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : essay}}></div>
              </div>
            }
            {
              rules[tab]['question'] &&
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Question
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : question}}></div>
              </div>
            }
            
            {
              rules[tab]['option1'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 1
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option1}}></div>
                </div>
            }
            {
              rules[tab]['option2'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 2
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option2}}></div>
                </div>
            }
            
            {
              rules[tab]['option3'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 3
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option3}}></div>
                </div>
            }
            {
              rules[tab]['option4'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 4
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option4}}></div>
                </div>
            }
            
            {
              rules[tab]['option5'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 5
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option5}}></div>
                </div>
            }
            {
              rules[tab]['option6'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 6
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option6}}></div>
                </div>
            }
            {
              rules[tab]['explanation'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Explanation
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : explanation}}></div>
                </div>
            }
            <div className='col-md-4' style={{paddingLeft:0}}>
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Subject
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : subject}}></div>
              </div>
            </div>
              <div className='col-md-4'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Topic
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : topic}}></div>
                </div>
              </div>
              <div className='col-md-4'  style={{paddingRight:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Tags
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : tags}}></div>
                </div>
              </div>

              <div className='col-md-3' style={{paddingLeft:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      (+ve Marks)
                    </h3>
                    <span className='pull-right'>
                      {posMarks}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      (-ve Marks)
                    </h3>
                    
                    <span className='pull-right'>
                      {negMarks}
                    </span>
                  </div>
                </div>
              </div>
              {
              rules[tab]['answertype'] &&
              <div className='col-md-3'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Answer Type
                    </h3>
                    <span className='pull-right'>
                      {answerType}
                    </span>
                  </div>
                </div>
              </div>
            } 
            {
              rules[tab]['answer'] &&
              <div className='col-md-3' style={{paddingRight:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Correct Answer
                    </h3>
                    <span className='pull-right'>
                      {correct}
                    </span>
                  </div>
                </div>
              </div>
            }
          </>
        }
          </div>

          <div className='col-md-12'>
            <div className='box no-border'>
              <div className='box-header'>
                  <h3 className='box-title'>
                    Questions List
                  </h3>
              </div>
              <div className='box-body' style={{overflow:"auto"}}>
                  <table id="staff_table" className="table file_manager_table questionsPageTable">
                    <thead>
                        <tr>
                            <th className='text-center'>
                                S.No
                            </th>
                            <th>
                                Question
                            </th>
                            <th className='text-center'>
                                (+ve Marks)
                            </th>
                            <th className='text-center'>
                                (-ve Marks)
                            </th>
                            <th className='text-center'>
                                Question Type
                            </th>
                            <th className='text-center'>
                                Answer Type
                            </th>
                            <th className='text-center'>
                                Correct Answer
                            </th>
                            <th className='text-center'>
                                Edit
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      
                      {
                        // filesList.length > 0 &&
                        <>
                          {
                             typeof filesList[segment] !== "undefined" &&
                             filesList[segment].length > 0 &&
                             filesList[segment].map((item, index) => {
                              var ansType = (item.correct !== null) ? JSON.parse(item.correct) : ""
                              if(ansType !== ""){
                                ansType = ansType.join(',')
                                ansType = ansType.replace(/ /g, '&nbsp');
                              }
                              item.negMarks = item.negMarks.replace(/ /g, '&nbsp');
                              item.posMarks = item.posMarks.replace(/ /g, '&nbsp');
                              
                              return(
                                  <tr key={"rows" + index} style={{backgroundColor:(qid.toString() === item.id.toString()) && "#e9e9e9"}}>
                                    <td className='text-center'>
                                    {(index+1) * (segment+1)}
                                    </td>
                                    <td>
                                      
                                        {/* {
                                          item.essay !== "" && item.essay !== null &&
                                          <>
                                          <div dangerouslySetInnerHTML={{__html : item.essay}}>
                                          </div>
                                          </>
                                        } */}
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Question</strong><br>"+item.question}} >
                                          
                                          </div>

                                        {/* {
                                          item.option1 !== "" && item.option1 !== null &&
                                          <>
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Option 1</strong><br>"+item.option1}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.option2 !== "" && item.option2 !== null &&
                                          <>
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Option 2</strong><br>"+item.option2}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.option3 !== "" && item.option3 !== null &&
                                          <>
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Option 3</strong><br>"+item.option3}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.option4 !== "" && item.option4 !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Option 4</strong><br>"+item.option4}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.option5 !== "" && item.option5 !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Option 5</strong><br>"+item.option5}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.option6 !== "" && item.option6 !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Option 6</strong><br>"+item.option6}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.explanation !== "" && item.explanation !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Explanation</strong><br>"+item.explanation}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.subject !== "" && item.subject !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Subject</strong><br>"+item.subject}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.topic !== "" && item.topic !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Topic</strong><br>"+item.topic}} >
                                          </div>
                                          </>
                                        }
                                        {
                                          item.tags !== "" && item.tags !== null &&
                                          <>
                                          <br />
                                          <div dangerouslySetInnerHTML={{__html : "<strong>Tags</strong><br>"+item.tags}} >
                                          </div>
                                          </>
                                        } */}
                                    </td>
                                    <td className='text-center'>
                                      {item.posMarks}
                                    </td>
                                    <td className='text-center'>
                                      {item.negMarks}
                                    </td>
                                    <td className='text-center'>
                                      {item.question_type}
                                    </td>
                                    <td className='text-center'>
                                      {item.answerType}
                                    </td>
                                    <td className='text-center'>
                                      <span style={{backgroundColor:"red"}} >
                                      {
                                        (ansType === "") ? "-" : ansType
                                        // ansType === "" ?
                                        // "-"
                                        // :
                                        // ansType
                                        // ansType.join(',')
                                      }
                                      </span>
                                    </td>
                                    <td style={{whiteSpace:"nowrap"}}>
                                      <button className='btn btn-primary btn-flat' onClick={() => editQuestion(item)} title="Edit Question">
                                        <i className='fa fa-edit'></i>
                                      </button>
                                      &nbsp;
                                      <button className='btn btn-success btn-flat' id={'viewQuestion'+item.id} onClick={() => viewQuestion(item)} title="View Question">
                                        <i className='fa fa-eye'></i>
                                      </button>
                                    </td>
                                  </tr>
                              )
                            })
                          }
                        </>
                      }
                    </tbody>
                  </table>
              </div>
              <div className='box-footer'>
                <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                    {
                        filesList.length > 0 && filesList.length <= 10 &&
                        filesList.map((item, index) => {
                            const active = index === segment ? " btn-primary" : ""
                            return(
                                <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => setSegment(index)} >
                                    {index+1}
                                </button>
                            )
                        })
                    }
                    {
                        filesList.length > 10 &&
                        <div style={{width:"200px", float:"right"}}>
                            <label>Last Page: {filesList.length}</label>
                            <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                        </div>
                    }
                </div>
                </div>
            </div>  
          </div>

            <div className='zipUploading' hidden>
                  <div className='zipUploadingContainer'>
                      <p className='text-center zipUploadingHeading'>
                        Uploading Questions...
                      </p>
                      <div className='zipUploadingBar'>
                        <div className='zipInsideBar'></div>
                      </div>
                      {/* <p className='outOf'>
                        4/20
                      </p> */}
                  </div>
            </div>

      </>
      
    )
  }
  return (
    <>
      <Sidebar page={Question} permission="Assets"/>
    </>
  )
}
