import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
const cookies = new Cookies();


export default function Managestudentlimit() {
  const Managestudentpage = (props) => {
        
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()

    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

    const [addTab, setAddTab] = useState(false)

    const [id, setId] = useState("")
    const [image, setImage] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [password, setPassword] = useState("")
    const [students, setStudents] = useState("")
    const [refresh, setRefresh] = useState("No")
    const [showFilter, setShowFilter] = useState(false)
    const [studentListCount, setStudentListCount] = useState([])
    
    const [statecitylist, setStatecitylist] = useState({state:[], city:[], pincodes:[], courses:[], totalstudents:0 })
    const [filter, setFilter] = useState({
        "signupfrom" : "",
        "signupto" : "",
        "enrolledfrom" : "",
        "enrolledto" : "",
        "enrolledin" : [],
        "notenrolledin" : [],
        "state" : [],
        "city" : [],
        "pincode" : [],
        "verifiedemail" : "",
        "status" : "",
        "segment" : "",
        "walletmin" : "",
        "walletmax" : "",
        "noactivity" : "",
        "signedupvia" : "",
        "name" : "",
        "email" : "",
        "contact" : "",
        "userid" : "",
        "order" : "DESC",
    })

    const [currentSegment, setCurrentSegment] = useState(0)

    const changeFilter = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        // getStudents()
    }

    
    const [studentLoading, setStudentLoading] = useState(true)
    const [minStudents, setMinStudents] = useState(1)
    const [maxStudents, setMaxStudents] = useState(10000)
    const [maxStudentsFix, setMaxStudentsFix] = useState(10000)
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const getStudents = (e = null, currentSegment = 1) => {
        // setStudents([])
                setStudentLoading(true)
        var  temp = filter
        if((temp['name'] !== "") || (temp['email'] !== "") || (temp['contact'] !== "") || (temp['userid'] !== "")){
            temp['enrolledin'] = window.$("#enrolledin").val()
            temp['notenrolledin'] = window.$("#notenrolledin").val()
            temp['state'] = window.$("#state").val()
            temp['city'] = window.$("#city").val()
            temp['pincode'] = window.$("#pincode").val()

            fetch(apiurl + "api/students?page="+currentSegment, {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        filter: temp,
                        limit: 10
                    })
                })
                .then(response => response.json())
                .then(response => {
                    setStatecitylist(response.statecitylist)
                    setStudentListCount(response.studentListCount)
                    setStudents(response.students)
                    setStudentLoading(false)

                    var max = 10000
                    setMaxStudents((max < response.studentListCount) ? max : response.studentListCount)
                    setMaxStudentsFix((max < response.studentListCount) ? max : response.studentListCount)
                    setLastPage(response.statecitylist.lastPage)

                    if(e !== null){
                        $(e.target).find(".loaderbody").remove()
                    }
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    // setTimeout(function(){
                    //     window.$('#studenttable').dataTable()
                    // }, 500)
                })

            }
    }

    const filterNow = (e) => {
        e.preventDefault()
        formLoading(e.target)
        // setCurrentSegment(1)
        getStudents(e, 1)
    }

    
    const [downloading, setDownloading] = useState(false)
    const [downloadingPopup, setDownloadingPopup] = useState(false)
    const downloadRequestFetch = () => {

        const min = parseInt(minStudents)
        const max = parseInt(maxStudents)
        const diff = max - (min-1);
        
        if(max < min){
            errorModal("Error", '"FROM" Cannot be greater than "TO"')
        }else if(diff > maxStudentsFix){
            errorModal("Error", "Total Users can't be more than 10000")
        }else{
            setDownloading(true)
            var  temp = filter
            temp['enrolledin'] = window.$("#enrolledin").val()
            temp['notenrolledin'] = window.$("#notenrolledin").val()
            temp['state'] = window.$("#state").val()
            temp['city'] = window.$("#city").val()
            temp['pincode'] = window.$("#pincode").val()
            temp['categories'] = window.$("#categories").val()
            fetch(apiurl+"api/user/getStudentsForDownload", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        filter: temp,
                        min: min,
                        max: max
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        downloadRequest(response.students)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => { });
        }

        
    }
    const downloadRequest = (students) => {
        setDownloading(true)
        const th = ["Student ID", "Name", "Email", "Contact", "Signedup From", "Created At", "Updated At", "Added By (Name)", "Staff Email", "Staff Contact", "Staff Role"]
        var segmentTemp = students
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = students[j]
            for(var i = 0;i<temp.length;i++){

                var staff = (temp[i]['staff'] === null || temp[i]['staff'] === "") ? "" : JSON.parse(temp[i]['staff'])
                moment.locale('en');
                var ct = temp[i]['created_at'];
                var dt = temp[i]['updated_at'];

                td.push(
                    [
                        temp[i]['userid'], temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['signedupfrom'],
                        temp[i]['signedupfrom'], moment(ct).format("D-M-Y"), moment(dt).format("D-M-Y"), 
                        (staff === "") ? "-" : staff.name,  
                        (staff === "") ? "-" : staff.email,  
                        (staff === "") ? "-" : staff.contact,  
                        (staff === "") ? "-" : staff.role,  
                    ]
                )
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Searchable Students List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    
    var a = 0
    useEffect(() => {
        if(a === 0){
            // getStudents()
            a++
        }
    }, [])

    return(
        <>
        {
            downloadingPopup &&
            <div className='dialog' id='downloadPopup'>
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Export Users: <small style={{color:"red"}} >current filters' values will be used</small>
                            </h3>
                            <span className='pull-right cursor-pointer' onClick={() => setDownloadingPopup(false)} >
                                <i className='fa fa-times'></i>
                            </span>
                        </div>
                        <div className='box-body'>
                            <label>From</label>
                            <input type='number' className='form-control' placeholder='e.g 1' value={minStudents} onChange={(e) => setMinStudents(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>To</label>
                            <input type='number' className='form-control' placeholder='e.g 100' value={maxStudents} onChange={(e) => setMaxStudents(e.target.value)} />
                        </div>
                        <p style={{color:"red", paddingLeft:10}}>
                            <strong>
                                Total Users can't be more than {maxStudentsFix}
                            </strong>
                        </p>
                        <div className='box-footer'>
                            <button className='btn btn-primary btn-flat btn-block' onClick={() => downloadRequestFetch()} >Download Students</button>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter Students
                    </h3>
                </div>
                <form action='' method='POST' onSubmit={(e) => filterNow(e)} >
                
                
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>
                            Name
                        </label>
                        <input type={"text"} className="form-control" value={filter.name} onChange={(e) => changeFilter("name", e.target.value)} placeholder={"e.g Shivam"} /> 
                    </div>
                    <div className='col-md-3 '>
                        <label>
                            Email
                        </label>
                        <input type={"text"} className="form-control" value={filter.email} onChange={(e) => changeFilter("email", e.target.value)} placeholder={"e.g info@example.com"} /> 
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Contact
                        </label>
                        <input type={"text"} className="form-control" value={filter.contact} onChange={(e) => changeFilter("contact", e.target.value)} placeholder={"e.g 9711111111"} /> 
                    </div>
                    <div className='col-md-3 no-right-padding'>
                        <label>
                            User ID
                        </label>
                        <input type={"text"} className="form-control" value={filter.userid} onChange={(e) => changeFilter("userid", e.target.value)} placeholder={"e.g 161221121"} /> 
                    </div>
                </div>
                <div className='box-footer'>
                    <button className='btn btn-primary btn-flat btn-block' type='submit' >
                        Filter Students
                    </button>
                </div>
                </form>
            </div>
        </div>
    }
    {
        students.length > 0 && typeof students[currentSegment] !== "undefined" && students[currentSegment].length > 0 &&
            <div className='col-md-12'>
            <div className='box no-border' style={{position:"relative"}}>
                    {
                        studentLoading &&
                        <div style={{position:"absolute", width:"100%", height:"100%", background:"rgba(255,255,255,0.5)"}} >
                            <Loading />
                        </div>
                    }
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Students List
                            {
                                // filter.signupfrom === "" && filter.signupto === "" &&
                                // <small style={{"color": "red"}}>
                                //     &nbsp;&nbsp;&nbsp;You are currently viewing data of last 7 days.
                                // </small>
                            }
                        </h3>
                        {
                            <button className={'btn btn-'+(showFilter ? "success" : "primary")+' btn-flat pull-right'} style={{marginLeft:"10px"}} onClick={(e) => {
                                setShowFilter(true)
                                
                                setTimeout(function(){
                                    if(window.$(".select2-selection").length > 0){
                                    window.$("select.select2-hidden-accessible").select2('destroy');
                                    }else{
                                    window.$("#enrolledin").select2();
                                    window.$("#notenrolledin").select2()
                                    window.$("#state").select2()
                                    window.$("#city").select2()
                                    window.$("#pincode").select2()
                                    }
                                }, 500)

                                }} >
                                <i className='fa fa-filter'></i>
                            </button>
                        }
                        
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => setDownloadingPopup(true)} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                    <div className='box-body' style={{overflow:"auto"}} >
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>Student ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Signed Up From</th>
                                    <th>Created At</th>
                                    <th>Modified At</th>
                                    <th>Added By</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                
                            {
                                students.length > 0 && typeof students[currentSegment] !== "undefined" && students[currentSegment].length > 0 ? 
                                students[currentSegment].map((item, index) => {
                                    var letter = Array.from(item.name)[0];
                                    var staff = (item.staff === null || item.staff === "") ? "" : JSON.parse(item.staff)
                                    moment.locale('en');
                                    var ct = item.created_at; 
                                    var dt = item.updated_at;
                                    return(

                                        <tr key={"student"+index}>
                                        <td>
                                            <span className='otherInfo'>
                                            {(index+1)}
                                            </span>        
                                        </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {
                                                        pagePermissions(props.pagepermissions, "Students", "view_profile") ?
                                                        <Link to={"/admin/student-profile/"+item.userid} target={"_blank"} >
                                                            {item.userid}
                                                        </Link>
                                                        :
                                                        item.userid
                                                    }
                                                </span>        
                                            </td>
                                            <td>
                                            {
                                                pagePermissions(props.pagepermissions, "Students", "view_profile") ?
                                                <Link to={"/admin/student-profile/"+item.userid+"?manage=1"} target={"_blank"} >
                                                    <div className='studentName'>
                                                        <div className='profilePic' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                            {letter.toUpperCase()}
                                                        </div>
                                                        <span>
                                                            <strong>{item.name}</strong>
                                                        </span>
                                                    </div>
                                                </Link>
                                                :
                                                <div className='studentName'>
                                                    <div className='profilePic' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                        {letter.toUpperCase()}
                                                    </div>
                                                    <span>
                                                        <strong>{item.name}</strong>
                                                    </span>
                                                </div>
                                            }
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    <a href={'mailto:'+item.email} style={{whiteSpace:"nowrap"}}>
                                                        <i className='fa fa-envelope'></i> &nbsp;
                                                        {item.email}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    <a href={'tel:'+item.contact} style={{whiteSpace:"nowrap"}}>
                                                        <i className='fa fa-phone'></i> &nbsp;
                                                        {item.contact}
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {item.signedupfrom}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {moment(ct).format("D-M-Y")}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    {moment(dt).format("D-M-Y")}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='otherInfo'>
                                                    { (staff !== "") ? staff.name : "-"}
                                                </span>
                                            </td>
                                            
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td>No Students Found...</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }

                            </tbody>
                        </table>
                    </div>
                    <div className='box-footer text-right'>
                        {
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {lastPage}</label>
                                <div className="input-group input-group-sm">
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={currentPage} onChange={(e) => setCurrentPage(e.target.value) } />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-primary btn-flat" onClick={() => getStudents(null, currentPage)}>Go!</button>
                                        </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
  }
        </>
    )
  }
  return (
    <>
        {
            
            <Sidebar page={Managestudentpage} permission="Searchable_Students"/>
            
        }
    </>
  )
}
