import React, { useState, useEffect } from 'react'
import $ from "jquery"

import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import apiurl from '../../Global';
import { useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import Loading from '../includes/Loading';
import Commentlist from './Commentlist';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';

const cookies = new Cookies();


const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
export default function Reports() {
  const Reportspage = (props) => {
    
    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()
    

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState("No")
    const [commentData, setCommentData] = useState(null)
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        postid: "",
        commentid: "",
        type: "",
        removed: "",
        removedmin: "",
        removedmax: "",
        user: "",
    })

    const [filterLoading, setFilterLoading] = useState(false)
    const [list, setList] = useState([])

    
    
const changeValue = (value, col) => {
  var temp = filter
  temp[col] = value
  setFilter(temp)
  setRefresh(refresh === "Yes" ? "No" : "Yes")
  // if(col === "tab"){
    //   fetchForum(1)
  // }
  
}

const fetchForum = () => {
    setLoading(true)
    fetch(apiurl+"api/reportsList", {
        "method": "POST",
        "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
            staff: AdminBasics(),
            accecible: 1,
            page_url: window.location.href,
            filter: filter
        })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setList(response.list)
                setRefresh(refresh === "No" ? "Yes" : "No")
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)  
        });
}


const updateEntry = (id, postid, commentid) => {
    fetch( apiurl+"api/removedReport", { 
        "method": "POST",
        "headers": { 
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
          staff: AdminBasics(),
          accecible: 1,
          page_url: window.location.href,
          id: id,
          postid: postid,
          commentid: commentid
        })
        })
        .then(response => response.json()) 
        .then(response => {
            fetchForum()
        }) 
}


const removePostFunc = (item) => {
    warningModal("Loading...", "")
    fetch( (item.type === 1) ? apiurl+"api/removeComment" : apiurl+"api/removePost", { 
        "method": "POST",
        "headers": { 
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
          staff: AdminBasics(),
          accecible: 1,
          page_url: window.location.href,
          postid: item.postid,
          id: item.commentid,
        })
        })
        .then(response => response.json()) 
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                successModal("Success", response.message)
                updateEntry(item.id, item.postid, item.commentid)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        }) 
        .catch(err => {
            console.log(err)
        }); 
}

const remove = (item) => {
    
    confirmAlert({
        title: 'Are You Sure?',
        message: (item.type === 1) ? "You want to remove this comment?" : "You want to remove this post?",
        buttons: [
          {
            label: 'Yes',
            onClick: () => removePostFunc(item)
          },
          {
            label: 'No',
            onClick: () => console.log("Canceled")
          }
        ]
    });
}

const filterForum = (e) => {
  e.preventDefault();
  fetchForum()
}


const [downloading, setDownloading] = useState(false)
const downloadRequest = () => {
    setDownloading(true)
    var temp = list
    const th = ["User ID", "Reported By", "Report Type", "Post ID", "Date"]
    var td = [];
    for(var i = 0;i<temp.length;i++){
        moment.locale('en');
        var dt = temp[i]['created_at'];
        var user = JSON.parse(temp[i]['udata'])
        td.push(
            [
                temp[i]['uid'], user['name'], temp[i]['type'] === 1 ? "Comment" : "Post", temp[i]['postid'], moment(dt).format("D-M-Y HH:mm:ss"), 
            ]
        )
    }
    
    
    fetch(apiurl+"api/downloadLists", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                th: th,
                td: td,
                for: "Request to download Reported Forum & Comments List of ("+td.length+") Entries"
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                successModal("Success", response.message)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setDownloading(false)
        })
        .catch(err => {
            // console.log(err);
            setDownloading(false)
        });

}
    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            postid: "",
            commentid: "",
            type: "",
            removed: "",
            removedmin: "",
            removedmax: "",
            user: "",
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }
    var b = 0
    useEffect(() => {
      if(b === 0){
        fetchForum()
        b++
      }
    }, [])


    return(
        <>
            {
                commentData === null &&
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Filter
                            </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                        </div>
                    <div className='box-body'>
                    <form action='' method='POST' onSubmit={(e) => filterForum(e)} >
                        <div style={{overflow:"hidden"}}>
                            <div className='box-body col-md-6'>
                                <label>Date of Report (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Date of Report (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                            </div>   

                            
                            <div className='box-body col-md-6'>
                                <label>Post ID</label>
                                <input type={"text"} className={"form-control"} value={filter.postid} onChange={(e) => changeValue(e.target.value, "postid")} placeholder={"e.g 1672299681"} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Comment ID</label>
                                <input type={"text"} className={"form-control"} value={filter.commentid} onChange={(e) => changeValue(e.target.value, "commentid")} placeholder={"e.g 10"}/>
                            </div> 


                            <div className='box-body col-md-6'>
                                <label>Type?</label>
                                <select className='form-control' value={filter.type} onChange={(e) => changeValue(e.target.value, "type")}  >
                                <option value={""}>Any</option>
                                <option value={1}>Post</option>
                                <option value={0}>Comment</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Removed?</label>
                                <select className='form-control' value={filter.removed} onChange={(e) => changeValue(e.target.value, "removed")}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Date of Remove (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.removedmin} onChange={(e) => changeValue(e.target.value, "removedmin")} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Date of Remove (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.removedmax} onChange={(e) => changeValue(e.target.value, "removedmax")} />
                            </div> 
                            <div className='box-body col-md-12'>
                                <label>Reported By (Name, Email, Contact, User ID)</label>
                                <input type={"text"} className={"form-control"} value={filter.user} onChange={(e) => changeValue(e.target.value, "user")} placeholder={"e.g Shivam, info@example.com"} />
                            </div> 
                        </div>
                            <div className='box-footer'> 
                                
                                <button className='btn btn-flat btn-primary btn-block'  >
                                    <i className='fa fa-search'></i> &nbsp;
                                    Search
                                </button>    
                            </div>     
                        </form>
                    </div>
                    
                    </div>
                </div>
            }
            <div className='col-md-8'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Manage Reports (Forum & Comments)
                        </h3>
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                    <div className='box-body' style={{position:"relative", overflow:"auto"}}>
                        {
                            loading ? 
                            <>
                                <br /><br />
                                <Loading />  
                                <br /><br />
                            </>
                            :
                            
                            <table id="staff_table" className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            S.No
                                        </th>
                                        <th>
                                            User ID
                                        </th>
                                        <th>
                                            Reported By
                                        </th>
                                        <th className='text-center'>
                                            Report Type
                                        </th>
                                        <th className='text-center'>
                                            Post ID
                                        </th>
                                        <th className='text-center'>
                                            Comment ID
                                        </th>
                                        <th className='text-center'>
                                            Date
                                        </th>
                                        <th className='text-center'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.length > 0 &&
                                        list.map((item, index) => {
                                            moment.locale('en');
                                            var dt = item.created_at;
                                            var user = JSON.parse(item.udata)
                                            return(
                                                <tr key={"report"+index} >
                                                    <td>
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        <a href={"/admin/student-profile/"+item.uid} target={"_blank"} >
                                                            {item.uid}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <strong>{ user.name }</strong>
                                                    </td>
                                                    <td className='text-center'>
                                                        {item.type === 1 ? "Comment" : "Post"}
                                                    </td>
                                                    <td className='cursor-pointer text-center'>
                                                        <strong >
                                                            <a onClick={() => window.open("/admin/forum/manage?postid="+item.postid)} title={"View Post"} >
                                                                {item.postid}
                                                            </a>
                                                        </strong>
                                                    </td>
                                                    <td className='cursor-pointer text-center' onClick={() => (item.comment !== null && item.comment !== "") ? setCommentData(JSON.parse(item.comment)) : ""} title={"View Comment"} >
                                                        {
                                                            item.commentid !== null &&
                                                            <strong>
                                                                <a >
                                                                    {item.commentid}
                                                                </a>
                                                            </strong>
                                                        }
                                                        
                                                    </td>
                                                    <td className='text-center'>
                                                        {moment(dt).format("D-M-Y HH:mm:ss")}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            item.removed === null ?
                                                            <>
                                                                {
                                                                    item.type !== 1 && pagePermissions(props.pagepermissions, "Reported_Forum_And_Comments", "remove_post") &&
                                                                    <button className='btn btn-danger btn-flat' title='Remove Post' onClick={() => remove(item)} >
                                                                        <i className='fa fa-trash'></i>
                                                                    </button>
                                                                }
                                                                {
                                                                    item.type === 1 && pagePermissions(props.pagepermissions, "Reported_Forum_And_Comments", "remove_comment") &&
                                                                    <button className='btn btn-danger btn-flat' title='Remove Comment' onClick={() => remove(item)} >
                                                                        <i className='fa fa-trash'></i>
                                                                    </button>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                Removed On { item.removedstring }
                                                                
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
            {
                commentData !== null &&
                <div className='col-md-4'>
                    <div className="box box-widget">
                        <div className="box-header with-border">
                        <div className="user-block">
                            <span className="username" style={{marginLeft:0}} ><a href="#">{commentData.udata.name}</a></span>
                            <span className="description" style={{marginLeft:0}}>Shared publicly - {commentData.created_at}</span>
                        </div>
                        
                        <div className="box-tools">
                            <button type="button" className="btn btn-box-tool" data-widget="remove" onClick={() => setCommentData(null)} ><i className="fa fa-times"></i></button>
                        </div>
                        
                        </div>
                        
                        <div className="box-body" dangerouslySetInnerHTML={{__html: commentData.comment}}></div>
                        {
                              commentData.images !== null && JSON.parse(commentData.images.length) > 0 &&
                              <div className='box-footer' style={{display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}} >
                              {
                                JSON.parse(commentData.images).map((item, index) => {
                                  return(
                                    <div 
                                    key={"images"+index} 
                                    style={{margin:"10px", borderRadius:"5px", width:"100px", height:"100px", overflow:"hidden"}}
                                    data-fancybox="gallery" data-src={assets_url + item.path}
                                    >
                                        <img src={assets_url + item.path} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                                    </div>  
                                  )
                                })
                              }
                              </div>
                            }
                    </div>
                </div>
            }
        </>
    )
  }
  return (
    <>
      <Sidebar page={Reportspage} permission="Reported_Forum_And_Comments"/>
    </>
  )
}
