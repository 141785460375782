import React from 'react'

export default function Loading() {
  return (
    
    <div className='parcialLoading'>
        <div className="loader"></div>
    </div>
  )
}
