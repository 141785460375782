import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();


export default function Changelog(props) {



    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [staffName, setStaffName] = useState("")
    const fetchList = () => {
        
        if(!loading) {
        setLoading(true)

        fetch(apiurl+"api/getCourseLog", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    courseid: params.id,
                    startDate: startDate,
                    endDate: endDate,
                    staffName: staffName,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.list)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
        }
      }

    const filterLogs = (e) => {
        e.preventDefault();
        fetchList()  
    }

      var a = 0
      useEffect(() => {
        if(a === 0){
            fetchList()
            a++
        }
      }, [])

  return (
    <>
            <br />
        <div className='col-md-4'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter Logs
                    </h3>
                </div>
                <form action='' method='GET' onSubmit={(e) => filterLogs(e)} >
                    <div className='box-body'>
                        <label>Start Date</label>
                        <input type='date' name='start_date' value={startDate} className='form-control' placeholder='e.g Date' onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>End Date</label>
                        <input type='date' name='end_date' value={endDate} className='form-control' placeholder='e.g Date' onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Staff Name,Email,contact</label>
                        <input type='text' name='staffname' value={staffName} className='form-control' placeholder='e.g John Doe' onChange={(e) => setStaffName(e.target.value)} />
                    </div>
                    <div className='box-footer'>
                        <button className='btn btn-primary btn-flat btn-block'>Filter Logs</button>
                    </div>
                </form>
            </div>
        </div>
        <div className='col-md-8'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Log History
                    </h3>
                    {
                        loading &&
                        <span className='pull-right label bg-red'>Loading...</span>
                    }
                </div>
                <div className='box-body'>
                    
                    <table id="staff_table" className="table file_manager_table">
                        <thead>
                            <tr>
                                <th>
                                    S.No
                                </th>
                                <th>
                                    Log
                                </th>
                                <th>
                                    Staff
                                </th>
                                <th>
                                    Created At
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {
                                list.length > 0 &&
                                list.map((item, index) => {
                                    return(
                                        <tr key={"index" + index}>    
                                            <td>{index + 1}</td>
                                            <td >
                                                <a href={item.page_url} target='_BLANK'>
                                                    <div dangerouslySetInnerHTML={{__html: item.msg}}></div>
                                                </a>
                                                {
                                                    item.path !== null &&
                                                    item.path.length > 0 &&
                                                    <strong>Path: </strong>
                                                }
                                                {
                                                    item.path !== null &&
                                                    item.path.length > 0 &&
                                                    item.path.map((item2, index2) => {
                                                        return(
                                                            <small key={"index2" + index2}>
                                                                <a href={"/admin/course-content/" + params.id + "/" + item2.p_id} target='_BLANK'>
                                                                    {item2.name} /  
                                                                </a>
                                                            </small>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (item.staff !== "" && item.staff !== null) &&
                                                    <>
                                                        <small>
                                                            <strong>Name: </strong> {item.staff_data.name}<br />
                                                            <strong>Email: </strong> {item.staff_data.email}<br />
                                                            <strong>Contact No: </strong> {item.staff_data.contact}<br />
                                                            <strong>Role: </strong> {item.staff_data.role}
                                                        </small>
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {item.date}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </>    
  )
}
