import React, { useState, useEffect } from 'react'
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import $ from "jquery"
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import Iframe from 'react-iframe'

const cookies = new Cookies();
function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
}
export default function Pagedata() {
    const PagedataPage = (props) => {
        const params = useParams()
        const admin = cookies.get('admin')
        let getToken = cookies.get('token')
        const [loading, setLoading] = useState(true)
        const [page, setPage] = useState("")
        const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()

        const get_page = () => {
            setLoading(true)
            fetch(apiurl+"api/singlepageAdmin", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        id: params.id,
                        pageid: params.pageid
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        console.log("https://landing.escholarsadmin.com/api/template/"+response.redirect.pageid+"/"+response.redirect.adminid+"/"+response.redirect.id)
                        window.location.href = "https://landing.escholarsadmin.com/api/template/"+response.redirect.pageid+"/"+response.redirect.adminid+"/"+response.redirect.id
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        // alert(response.message)
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setLoading(false)
                })
                .catch(err => {
                    
                    // console.log(err);
                });
            };

            useEffect(() => {
                get_page()
            }, [])

        return(
            !loading ?
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                {page.title}
                            </h3>
                        </div>
                        <div className='box-body'>
                            <iframe style={{width:"100%", height:"calc(100vh - 200px)"}} src="https://landing.escholarsadmin.com/api/template/landingone" ></iframe>
                        </div>
                    </div>    
                </div>
            :
            <div className='col-md-12'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Loading...
                        </h3>
                    </div>
                </div>    
            </div>

        )
    }
  return (
    <>
      <Sidebar page={PagedataPage} permission="Global"/>
    </>
  )
}
