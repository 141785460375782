import React, { useState, useEffect } from 'react'
import Sidebar from '../includes/Sidebar';
import Singlechat from './Singlechat';

export default function Chat() {
  const ChatPages = (props) => {
    
    var a = 0
    useEffect(() => {
      if(a === 0){
        a++
      }  
    }, [])


    return(
        <>
            <Singlechat pagepermissions={props.pagepermissions} allcourse={true} />
        </>
    )
  }
  return (
    <>
      <Sidebar page={ChatPages} permission="Chat"/>
    </>
  )
}
