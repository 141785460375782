import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import Sidebar from '../includes/Sidebar';
import moment from 'moment/moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const cookies = new Cookies();

export default function Orderbooks() {
  const OrderbooksPages = (props) => {
    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const orderid = params.orderid
    const mainuserid = params.userid
    
    
    const [booksLoading, setBooksLoading] = useState(false)
    const [booksSelectedLoading, setBooksSelectedLoading] = useState(false)
    const [books, setBook] = useState([])

    const [selectedBooks, setSelectedBooks] = useState([])

    function inArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] == needle) return true;
        }
        return false;
    }

    const getOrderBooks = (order) => {
        if(!booksSelectedLoading){
            setBooksSelectedLoading(true)
            fetch(apiurl+"api/getOrderBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        courseid: order.course_id,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setSelectedBooks(response.books)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setBooksSelectedLoading(false)
                })
                .catch(err => { });
        }
    }

    const getBooks = () => {
        if(!booksLoading){
            setBooksLoading(true)
            fetch(apiurl+"api/getBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setBook(response.books)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setBooksLoading(false)
                })
                .catch(err => { });
        }
    }

    const addToList = (item) => {
        var temp = selectedBooks
        temp.push(item.id.toString())
        setSelectedBooks(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const removeFromList = (item) => {
        
        var temp = selectedBooks
        const index = temp.indexOf(item.id.toString());
        if (index > -1) { // only splice array when item is found
            temp.splice(index, 1); // 2nd parameter means remove one item only
        }
        setSelectedBooks(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const searchBook = (id, key) => {
        $("#"+id+" tbody tr").each(function(){
            var name = window.$(this).attr('data-name')
            if(name.includes(key)){
                window.$(this).show()
            }else{
                window.$(this).hide()
            }
        });
    }

    const saveBooks = () => {
        if(!booksLoading){
            setBooksLoading(true)
            fetch(apiurl+"api/saveOrderBooks", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        orderid: orderid,
                        userid: mainuserid,
                        book: selectedBooks
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        setTimeout(function(){
                            window.location.reload()
                        }, 1000);
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setBooksLoading(false)
                })
                .catch(err => { });
        }
    }

    const [orderInfo, setOrderInfo] = useState("")

    const getOrderBasicInfo = () => {
        fetch(apiurl+"api/orderBasicInfo", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    orderid: orderid,
                    userid: mainuserid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setOrderInfo(response.order)
                    if(response.order.alterbooks === null){
                        getOrderBooks(response.order)
                    }else{
                        setSelectedBooks(response.order.alterbooks)
                    }
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setBooksLoading(false)
            })
            .catch(err => { });
    }

    useEffect(() => {
        getOrderBasicInfo()
        getBooks()
    }, [])

    return(
        
    <>
        {
            orderInfo !== "" &&
            <div className='col-md-4'>
                <div className="box box-widget widget-user-2">
                    <div className="widget-user-header bg-yellow no-padding">
                        <img src={ assets_url + orderInfo.information.course.thumbnail} style={{width:"100%", objectFit:"contain"}} />
                    </div>
                    <div className="box-footer no-padding">
                        <ul className="nav nav-stacked">
                            <li><a href="#" ><strong>{orderInfo.information.course.name}</strong></a></li>
                            <li><a href="#">Purchase ID <span className="pull-right label bg-blue">{orderInfo.prefixid}</span></a></li>
                            <li><a href="#">Buyer Name <span className="pull-right">{orderInfo.userdata.name}</span></a></li>
                            <li><a href="#">Buyer Email <span className="pull-right">{orderInfo.userdata.email}</span></a></li>
                            <li><a href="#">Buyer Contact <span className="pull-right">{orderInfo.userdata.contact}</span></a></li>
                            {
                                orderInfo.alterbooksby !== null &&
                                <>
                                    <li><a href="#">Altered By Name <span className="pull-right">{orderInfo.alterbooksby.name}</span></a></li> 
                                    <li><a href="#">Altered By Email <span className="pull-right">{orderInfo.alterbooksby.email}</span></a></li> 
                                    <li><a href="#">Altered By Contact <span className="pull-right">{orderInfo.alterbooksby.contact}</span></a></li> 
                                </>
                            }
                            <li>
                                <a className='btn btn-flat btn-block btn-flat btn-primary' href={'/admin/order-info/'+orderid+'/'+mainuserid} style={{color:"#FFFFFF"}} >
                                    Back to Order Info
                                </a>
                            </li> 
                        </ul>
                    </div>
                </div>
            </div>
        }
        <div className='col-md-8'>
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-body'>
                        <input type='text' name='' id='search1' placeholder='e.g Book Name' className='form-control' onChange={(e) => searchBook("bookstable", e.target.value.toLowerCase())} />
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-body'>
                        <input type='text' name='' id='search2' placeholder='e.g Book Name' className='form-control' onChange={(e) => searchBook("selectedbookstable", e.target.value.toLowerCase())} />
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Select Books
                        </h3>
                    </div>
                    
                <div className='box-body'>
                    <table id="bookstable" className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                <th style={{width:"80px"}} >S.No</th>
                                <th>Title</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                books.length > 0 &&
                                books.map((item, index) => {
                                    return(
                                        !inArray(item.id, selectedBooks) &&
                                        <tr data-name={item.name.toLowerCase()} key={"key"+index} onClick={() => addToList(item)} >
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className='booksCon cursor-pointer' >
                                                    <div className='boooksImage'>
                                                        <img src={ assets_url + item.image} alt={item.name} />
                                                    </div>
                                                    <div>
                                                        <strong>{item.name}</strong>
                                                        <br />
                                                        <small>
                                                            <strong>
                                                                Qty Left:&nbsp; 
                                                            </strong>
                                                            {item.quantity}
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <button className='btn btn-success btn-flat'>
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Selected Books
                        </h3>
                        {
                            booksLoading ? 
                            <button className='btn btn-warning btn-flat pull-right' >Loading...</button>
                            :
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => saveBooks()} >Save</button>
                        }
                        
                    </div>
                <div className='box-body'>
                    <table id="selectedbookstable" className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                <th style={{width:"80px"}} >S.No</th>
                                <th>Title</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                books.length > 0 &&
                                books.map((item, index) => {
                                    return(
                                        inArray(item.id, selectedBooks) &&
                                        <tr data-name={item.name.toLowerCase()} key={"key"+index} onClick={() => removeFromList(item)} >
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className='booksCon cursor-pointer' >
                                                    <div className='boooksImage'>
                                                        <img src={ assets_url + item.image} alt={item.name} />
                                                    </div>
                                                    <div>
                                                        <strong>{item.name}</strong>
                                                        <br />
                                                        <small>
                                                            <strong>
                                                                Qty Left:&nbsp; 
                                                            </strong>
                                                            {item.quantity}
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <button className='btn btn-danger btn-flat'>
                                                    <i className='fa fa-times'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </>    
    )
  }
  return (
    <>
      <Sidebar page={OrderbooksPages} permission="Global"/>
    </>
  )
}
