import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import Sidebar from '../includes/Sidebar';
import moment from 'moment/moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const cookies = new Cookies();

export default function Orderinfo() {
  const OrdersPages = (props) => {
    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(false)
    const orderid = params.orderid
    const mainuserid = params.userid
    const [order, setOrder] = useState(null)
    const [information, setInformation] = useState(null)
    const [address, setAddress] = useState(null)
    const [userdata, setUserdata] = useState(null)
    const [gst, setGst] = useState(null)
    const [staff, setStaff] = useState(null)
    const [consignment, setConsignment] = useState(null)
    const [reffrenceNo, setReffrenceNo] = useState(null)
    const [consignmentInfo, setConsignmentInfo] = useState(null)
    
    const [transferThis, setTransferThis] = useState(false)
    const [transferSearch, setTransferSearch] = useState("")
    const [transferLoading, setTransferLoading] = useState(false)
    const [userList, setUserList] = useState([])
    const [transferingLoading, setTransferingSearch] = useState(false)
    const [transferHistory, setTransferHistory] = useState([])
    const [editValidity, setEditvalidity] = useState(null)
    const [editHistory, setEditHistory] = useState(null)
    const [editTable, setEditTable] = useState(false)
    const [changeBooks, setChangeBooks] = useState(false)
    const [trackingDetails, setTrackingDetails] = useState([])
    
    const trackOrder = () => {
        fetch(apiurl + "api/track-order/"+orderid, { 
            "method": "GET",
            "headers": { 
                "content-type": "application/json",   
                "accept": "application/json"
            }
            })
            .then(response => response.json()) 
            .then(response => { 
                if(response.status === "OK"){
                    setTrackingDetails(response.data)
                }else if(response.status === "Failed"){  
                }
            }) 
            .catch(err => {
                console.log(err)
            });
    }
    
    const getOrders = () => {
        setLoading(true)
        fetch(apiurl + "api/orderninfoByPRID", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    orderid: orderid,
                    userid: mainuserid
                })
            })
            .then(response => response.json())
            .then(response => {

                
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setOrder(response.order)
                    console.log(response.order)
                    setInformation(JSON.parse(response.order.information))
                    // console.log(JSON.parse(response.order.address))
                    setAddress(JSON.parse(response.order.address))

                    var userdata = JSON.parse(response.order.userdata);
                    
                    if(!pagePermissions(props.pagepermissions, "course"+response.order.course_id, "show_order_details")){
                        userdata['name'] = (userdata['name'].substring(0,3)) + '*'.repeat((userdata['name'].length > 3) ? userdata['name'].length - 3 : 1) 
                        userdata['contact'] = (userdata['contact'].substring(0,4)) + "******"
                        var expEmail = userdata['email'].split("@")
                        userdata['email'] = (expEmail[0].substring(0,3)) + '*'.repeat((expEmail[0].length > 3) ? expEmail[0].length - 3 : 1) + "@" + ((expEmail[1] !== undefined) ? expEmail[1] : "")
                    }

                    setUserdata(userdata)

                    setGst(JSON.parse(response.order.gst))
                    setConsignment(response.order.consignmentEmpty)
                    setReffrenceNo(response.order.reference)
                    setConsignmentInfo(JSON.parse(response.order.consignment))
                    setTransferHistory(JSON.parse(response.order.transferhistory))
                    setStaff( (response.order.staff !== null) ? JSON.parse(response.order.staff) : null)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
    }

    const changeValue = (col1, col2, value) => {
        var temp = consignment
        if(col2 !== ""){
            temp[col1][col2] = value
        }else{
            temp[col1] = value
        }
        setConsignment(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const addConsignment = (e) => {
        e.preventDefault()
        formLoading(e.target)
        fetch(apiurl + "api/addConsignmentV2", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    consignment: consignment,
                    orderid: order.prefixid,
                    name: information.course.name,
                    amount: information.price.final,
                    userid: mainuserid
                })
            })
            .then(response => response.json())
            .then(response => {
                
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    setTimeout(function(){
                        window.location.reload()
                    }, 500)
                    // getOrders()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
    }
    
    const searchStudent = (x) => {
        setTransferSearch(x)
        setTransferLoading(true)
        if(x !== "" && x !== " "){
            fetch(apiurl + "api/searchStudent", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        name: x,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    setUserList(response.students)
                    setTransferLoading(false)
                })
            }else{
                setUserList([])
                setTransferLoading(false)
            }
    }

    
    const transferToStudent = (userid, e) => {
        setTransferingSearch(true)
        fetch(apiurl + "api/transferOrder", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    userid: userid,
                    orderid: orderid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    window.location.reload()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                    setTransferingSearch(false)
                }
            })
    }
    const removeDialog = (userid, e) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You want to transfer this order?",
            buttons: [
                {
                label: 'Yes',
                onClick: () => transferToStudent(userid, e)
                },
                {
                label: 'No',
                onClick: () => console.log("Canceled")
                }
            ]
        });
    }

    const saveConsignment = () => {
        warningModal("Loading...", "Saving Consignment Please Wait...")
        
        fetch(apiurl + "api/saveConsignment", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    consignment: consignment,
                    orderid: order.prefixid,
                    userid: mainuserid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getOrders()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
    }

    const changeItemValidity = (e) => {
        e.preventDefault()
        formLoading(e.target)
        fetch(apiurl + "api/changeCourseValidity", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    item: editValidity,
                })
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getOrders()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
    }

    const resetCourseProgress = (item) => {
        var a = window.confirm("Are You Sure You Want To Refresh Course Progress? You Won't be Able to Return It Back")
        if(a){
            warningModal("Loading...", "Course Progress Refreshig Please Wait...")
            fetch(apiurl + "api/resetCourseProgress", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        item: item,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        getOrders()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
            }
    }

    const removeFromPortal = (item) => {
        var a = window.confirm("Are You Sure You Want To Remove This Item? You Won't be Able to Return It Back")
        if(a){
            warningModal("Loading...", "Course Removing Please Wait...")
            fetch(apiurl + "api/removeFromPortal", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        item: item,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        // getOrders()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
            }
    }

    const removeFromStudent = (item, type) => {
        var a = window.confirm("Are You Sure?")
        if(a){
            warningModal("Loading...", "Task Under Progress...")
            fetch(apiurl + "api/removeFromStudent", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        item: item,
                        type: type
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        getOrders()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
            }
    }

    const changeValidityData = (col, value) => {
        var temp = editValidity
        temp[col] = value
        setEditvalidity(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const [paymentID, setPaymentID] = useState("")
    const [remark, setRemark] = useState("")

    const completeThisOrder = (e, order) => {
        e.preventDefault()
        if(remark === ""){
            alert("Please Enter Remarks")
        }else if(paymentID === ""){
            alert("Please Enter Payment ID")
        }else{
            warningModal("Loading...", "Task Under Progress...")
            
            fetch(apiurl + "api/addMojoId", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        remark: remark,
                        mojoid: paymentID,
                        purchaseid: order.purchase_id,
                        initid: order.payment_id,
                        uid: order.uid
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        setTimeout(function(){
                            window.location.reload()
                        }, 500)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
        }
    }
    
    const [sendConsignment, setSendConsignment] = useState(false)

    var a = 0
    useEffect(() => {
      if(a === 0){
        getOrders()
        trackOrder()
        a++
      }  
    }, [])


    return(
        <>
        
        {
            loading ?
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-body' style={{paddingTop:"100px", paddingBottom: "100px"}}>
                    <Loading />
                </div>
            </div>
        </div>
    :
        pagePermissions(props.pagepermissions, "Orders", "orders_info") &&
        <>
    {
        !editTable &&
        <>
        <div className="col-md-12 no-padding">
            {
                (consignment !== null && reffrenceNo === null && order !== null && (order.process !== "delivered" && order.process !== "initiated") || (sendConsignment)) &&
            <>
            <form action='' method='POST' onSubmit={(e) => addConsignment(e)} style={{overflow:"hidden"}} >
                <div className="col-md-12 no-padding">
                    <div className="col-md-6 ">
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                Warehouse Details
                                </h3>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-6 no-left-padding'>
                                    <label>Name<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g John Doe' value={consignment.origin_details.name} onChange={(e) => changeValue("origin_details", "name", e.target.value)} />
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <label>Email<span style={{color:"red"}}>*</span></label>
                                    <input type={"email"} className={"form-control"} required placeholder='e.g info@example.com' value={consignment.origin_details.email} onChange={(e) => changeValue("origin_details", "email", e.target.value)} />
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-6 no-left-padding'>
                                    <label>Contact<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} required placeholder='e.g 9178787878' value={consignment.origin_details.contact} onChange={(e) => changeValue("origin_details", "contact", e.target.value)} />
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <label>Pincode<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g 110033' value={consignment.origin_details.pincode} onChange={(e) => changeValue("origin_details", "pincode", e.target.value)} />
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-12 no-left-padding no-right-padding'>
                                    <label>Address<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Your Address' value={consignment.origin_details.address} onChange={(e) => changeValue("origin_details", "address", e.target.value)} />
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-6 no-left-padding'>
                                    <label>City<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Delhi' value={consignment.origin_details.city} onChange={(e) => changeValue("origin_details", "city", e.target.value)} />
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <label>State<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Delhi' value={consignment.origin_details.state} onChange={(e) => changeValue("origin_details", "state", e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                Destination Details
                                </h3>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-6 no-left-padding'>
                                    <label>Name<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g John Doe' value={consignment.destination_details.name} onChange={(e) => changeValue("destination_details", "name", e.target.value)} />
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <label>Email<span style={{color:"red"}}>*</span></label>
                                    <input type={"email"} className={"form-control"} required placeholder='e.g info@example.com' value={consignment.destination_details.email} onChange={(e) => changeValue("destination_details", "email", e.target.value)} />
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-4 no-left-padding'>
                                    <label>Contact<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} required placeholder='e.g 9178787878' value={consignment.destination_details.contact} onChange={(e) => changeValue("destination_details", "contact", e.target.value)} />
                                </div>
                                <div className='col-md-4 '>
                                    <label>Alternative Contact </label>
                                    <input type={"number"} className={"form-control"} placeholder='e.g 9178787878' value={consignment.destination_details.alternative_no} onChange={(e) => changeValue("destination_details", "alternative_no", e.target.value)} />
                                </div>
                                <div className='col-md-4 no-right-padding'>
                                    <label>Pincode<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g 110033' value={consignment.destination_details.pincode} onChange={(e) => changeValue("destination_details", "pincode", e.target.value)} />
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-6 no-left-padding'>
                                    <label>Flat, House No., Building, Company, Apartment<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Your Address' value={consignment.destination_details.address} onChange={(e) => changeValue("destination_details", "address", e.target.value)} />
                                </div>
                                <div className='col-md-6 no-right-padding'>
                                    <label>Area, Street, Sector, Village<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Your Address Line 2' value={consignment.destination_details.address2} onChange={(e) => changeValue("destination_details", "address2", e.target.value)} />
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-12 no-left-padding no-right-padding'>
                                    <label>Landmark<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Your Landmark' value={consignment.destination_details.landmark} onChange={(e) => changeValue("destination_details", "landmark", e.target.value)} />
                                </div>
                            </div>

                            <div className='box-body'>
                                <div className='col-md-4 no-left-padding'>
                                    <label>City<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Delhi' value={consignment.destination_details.city} onChange={(e) => changeValue("destination_details", "city", e.target.value)} />
                                </div>
                                <div className='col-md-4'>
                                    <label>State<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} required placeholder='e.g Delhi' value={consignment.destination_details.state} onChange={(e) => changeValue("destination_details", "state", e.target.value)} />
                                </div>
                                <div className='col-md-4 no-right-padding'>
                                    <label>Shipping Type<span style={{color:"red"}}>*</span></label>
                                    <select className='form-control' required value={consignment.destination_details.consignmenttype} onChange={(e) => changeValue("destination_details", "consignmenttype", e.target.value)}  >
                                        <option value={"DTDC"}>DTDC</option>
                                        <option value={"Logistify"}>Logistify</option>
                                        <option value={"Indian Post"}>Indian Post</option>
                                        <option value={"Other"}>Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="col-md-12 ">
                {
                    consignment.destination_details.consignmenttype === "DTDC" &&
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Dimensions
                                </h3>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-2 no-left-padding'>
                                    <label>Length(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.l} onChange={(e) => changeValue("dimentions", "l", e.target.value)} />
                                </div>
                                <div className='col-md-2 '>
                                    <label>Breadth(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.b} onChange={(e) => changeValue("dimentions", "b", e.target.value)} />
                                </div>
                                <div className='col-md-2 '>
                                    <label>Height(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.h} onChange={(e) => changeValue("dimentions", "h", e.target.value)} />
                                </div>
                                <div className='col-md-2'>
                                    <label>Weight(KG)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 2' value={consignment.dimentions.w} onChange={(e) => changeValue("dimentions", "w", e.target.value)} />
                                </div>
                                <div className='col-md-2'>
                                    <label>Service Type<span style={{color:"red"}}>*</span></label>
                                    <select className='form-control' required defaultValue={consignment.service_type} onChange={(e) => changeValue("service_type", "", e.target.value)} >
                                        <option value={""} >Select Service Type</option>
                                        <option value={"GROUND EXPRESS"} >GROUND EXPRESS</option>
                                        <option value={"PRIORITY"} >PRIORITY</option>
                                        <option value={"EXPRESS"} >EXPRESS</option>
                                    </select>
                                </div>
                                <div className='col-md-2 no-right-padding'>
                                    <label>Load Type<span style={{color:"red"}}>*</span></label>
                                    <select className='form-control' required defaultValue={consignment.load_type} onChange={(e) => changeValue("load_type", "", e.target.value)}>
                                        <option value={""} >Select Load Type</option>
                                        <option value={"NON-DOCUMENT"} >NON-DOCUMENT</option>
                                        <option value={"DOCUMENT"} >DOCUMENT</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                }


                {
                    consignment.destination_details.consignmenttype === "Logistify" &&
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Dimensions
                                </h3>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-2 no-left-padding'>
                                    <label>Length(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.l} onChange={(e) => changeValue("dimentions", "l", e.target.value)} />
                                </div>
                                <div className='col-md-2 '>
                                    <label>Breadth(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.b} onChange={(e) => changeValue("dimentions", "b", e.target.value)} />
                                </div>
                                <div className='col-md-2 '>
                                    <label>Height(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.h} onChange={(e) => changeValue("dimentions", "h", e.target.value)} />
                                </div>
                                <div className='col-md-2'>
                                    <label>Weight(KG)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 2' value={consignment.dimentions.w} onChange={(e) => changeValue("dimentions", "w", e.target.value)} />
                                </div>
                                <div className='col-md-2'>
                                    <label>Courier Name<span style={{color:"red"}}>*</span></label>
                                    <input type={"text"} className={"form-control"} step={0.01} required placeholder='e.g Bluedart' value={consignment.courier_name} onChange={(e) => changeValue("courier_name", "", e.target.value)} />
                                </div>
                                <div className='col-md-2 no-right-padding'>
                                    <label>Courier ID<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 2' value={consignment.courier_id} onChange={(e) => changeValue("courier_id", "", e.target.value)} />
                                </div>
                                
                            </div>
                        </div>
                }
                
                {
                    consignment.destination_details.consignmenttype === "Indian Post" &&
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Dimensions
                                </h3>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-3 no-left-padding'>
                                    <label>Length(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.l} onChange={(e) => changeValue("dimentions", "l", e.target.value)} />
                                </div>
                                <div className='col-md-3 '>
                                    <label>Breadth(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.b} onChange={(e) => changeValue("dimentions", "b", e.target.value)} />
                                </div>
                                <div className='col-md-3 '>
                                    <label>Height(CM)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 10' value={consignment.dimentions.h} onChange={(e) => changeValue("dimentions", "h", e.target.value)} />
                                </div>
                                <div className='col-md-3 no-right-padding' >
                                    <label>Weight(KG)<span style={{color:"red"}}>*</span></label>
                                    <input type={"number"} className={"form-control"} step={0.01} required placeholder='e.g 2' value={consignment.dimentions.w} onChange={(e) => changeValue("dimentions", "w", e.target.value)} />
                                </div>
                                
                            </div>
                        </div>
                }

                {
                    consignment.destination_details.consignmenttype === "Other" &&
                    // <div className='col-md-12'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Shipping Details
                                </h3>
                            </div>
                            <div className='box-body'>
                                <div className='col-md-4 no-left-padding'>
                                    <label>Title<span style={{color:"red"}}>*</span></label>
                                    <input type='text' required value={consignment.other.title} className='form-control' placeholder='e.g Sending from Ekart' onChange={(e) => changeValue("other", "title", e.target.value)} />
                                </div>
                                <div className='col-md-4'>
                                    <label>Track ID<span style={{color:"red"}}>*</span></label>
                                    <input type='text' required value={consignment.other.track_id} className='form-control' placeholder='e.g #TRACK123' onChange={(e) => changeValue("other", "track_id", e.target.value)} />
                                </div>
                                <div className='col-md-4 no-right-padding'>
                                    <label>Tracking URL<span style={{color:"red"}}>*</span></label>
                                    <input type='text' required value={consignment.other.track_url} className='form-control' placeholder='e.g https://example.com/track' onChange={(e) => changeValue("other", "track_url", e.target.value)} />
                                </div>
                            </div>
                        </div>
                    // </div>
                }
                    {/* <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>Notification Message <span style={{color:"red"}}>(Leave Blank for no notification)</span></h3>
                        </div>
                        <div className='box-body'>
                            <textarea className='form-control' placeholder='e.g Your Order Received' defaultValue={consignment.notification} onChange={(e) => changeValue("notification", "", e.target.value)} ></textarea>
                        </div>
                    </div> */}
                    <div className='col-md-6 no-left-padding'>
                        <div className='box no-border'>
                            <div className='box-footer'>
                                <input type={"button"} className={"btn btn-success btn-flat btn-block"} onClick={() => saveConsignment()} value={"Save Consignment"} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 no-right-padding'>
                        <div className='box no-border'>
                            <div className='box-footer'>
                                <input type={"submit"} className={"btn btn-primary btn-flat btn-block"} value={"Send Consignment"} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>   
            </>
        }
            <div className="col-md-4" >
                <div className='box no-border'>
                {
                    userdata !== null && order !== null &&
                    <div className="box box-primary no-border" >
                        <div className='box-header no-border'>
                            <h3 className='box-title'>
                                Order By
                            </h3>
                        </div>
                        
                        <div className='box-body'>
                            <ul className="list-group list-group-unbordered">
                                <li className="list-group-item">
                                    <b>Name</b> 
                                        {
                                            (!pagePermissions(props.pagepermissions, "course"+order.course_id, "show_order_details")) ?
                                            <span className='pull-right'>{userdata.name}</span>
                                            :
                                            <a href={"/admin/student-profile/"+order.uid} target={"_blank"} className="pull-right">{userdata.name} </a>
                                            
                                        }
                                </li>
                                <li className="list-group-item">
                                    <b>Email</b> <a className="pull-right" href={'mailto:'+userdata.email}>{userdata.email}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Contact</b> <a className="pull-right" href={'tel:'+userdata.contact}>{userdata.contact}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Student ID</b> <a href={"/admin/student-profile/"+order.uid} target={"_blank"} className="pull-right">{order.uid}</a>
                                </li>
                            </ul>
                        </div>
                        {
                            order.process !== "initiated" &&
                                <div className='box-footer'>
                                    {
                                        
                                        pagePermissions(props.pagepermissions, "Orders", "edit_order") &&
                                        <div className='col-md-12 no-padding'>
                                            <button className='btn btn-warning btn-flat btn-block' onClick={() => {
                                                setEditTable(true)
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} >
                                                Edit This Order
                                            </button>
                                            <br />
                                        </div>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, "Orders", "edit_order") &&
                                        (order.process === "unprocessed" || order.process === "failed") &&
                                        <div className='col-md-12 no-padding'>
                                            <button className='btn bg-info btn-flat btn-block' onClick={() => {
                                                window.location.href='/admin/order-books/' + orderid + '/' + mainuserid
                                            }}
                                            >
                                                Change Books
                                            </button>
                                            <br />
                                        </div>
                                    }
                                    {
                                        (order.process === "unprocessed" || order.process === "failed") &&
                                        <div className='col-md-12 no-padding'>
                                            <button className='btn btn-success btn-flat btn-block' onClick={() => {
                                                setSendConsignment(true)
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} >
                                                Change Consignment
                                            </button>
                                            <br />
                                        </div>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, "Orders", "transfer_order") &&
                                        <div className='col-md-12 no-padding'>
                                            <button className='btn btn-danger btn-flat btn-block' onClick={() => setTransferThis(true)} >
                                                Transfer This Order
                                            </button>
                                        </div>
                                    }
                                </div>
                        }
                        
                    </div>
                }
                </div>


                
                {
                    order !== null &&
                    order.process == "initiated" &&
                    pagePermissions(props.pagepermissions, "Orders", "edit_order") &&
                    <>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Complete This Order?
                                </h3>
                            </div>
                            <form method="POST" onSubmit={(e) => completeThisOrder(e, order)} >
                                <div className='box-body'>
                                    <label>Payment ID<span className='text-red'>*</span></label>
                                    <input type='text' className='form-control' onChange={(e) => setPaymentID(e.target.value)} placeholder='e.g MOJO16456789' />
                                </div>
                                <div className='box-body'>
                                    <label>Remark<span className='text-red'>*</span></label>
                                    <input type='text' className='form-control' onChange={(e) => setRemark(e.target.value)} placeholder='e.g For Personal use' />
                                </div>
                                <div className='box-footer'>
                                    <button className='btn btn-success btn-flat btn-block' type='submit' >
                                        Complete This Order?
                                    </button>
                                </div>
                            </form>    
                        </div>
                    </>
                }
                
                {
                    pagePermissions(props.pagepermissions, "Orders", "transfer_order") && 
                    transferHistory !== null && transferHistory.length > 0 &&
                    transferHistory.map((item, index) => {
                        return(
                            <div className='box no-border' key={"transfer"+index} >
                                <div className="box box-primary no-border" >
                                    <div className='box-body'>
                                        <ul className="list-group list-group-unbordered">
                                            <li className="list-group-item">
                                                <b>Transfer Date</b> <a className="pull-right">{item.date}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Transfer From</b> <a className="pull-right">{item.olduser.name} <strong>({item.olduser.userid})</strong></a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Transfer To</b> <a href={"/admin/student-profile/"+item.newuser.userid} target={"_blank"} className="pull-right">{item.newuser.name} <strong>({item.newuser.userid})</strong></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }
                <div className="box no-border"  >
                    
                    {
                        information !== null &&
                        <div className="box-body box-profile" id='leftCourse'>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <div className='courseImageCon' style={{width:"300px"}} >
                                    {
                                        information.course.thumbnail !== null && information.course.thumbnail !== "" &&
                                        <img src={assets_url + information.course.thumbnail} alt="Course Photo" className='containImage cursor-pointer' title='Change Image'/>
                                    }
                                </div>
                                <div style={{paddingLeft:"10px"}}>
                                    <h3 className="profile-username ">
                                        {information.course.name}
                                    </h3>
                                    <p className="text-muted line2">
                                        {(information.course.coursedata !== "") ? information.course.coursedata.subheading : ""}
                                    </p>
                                </div>
                            </div>
                            <ul className="list-group list-group-unbordered">
                                <li className="list-group-item">
                                    <b>Instructor</b> <a className="pull-right">{(information.course.coursedata !== "") ? information.course.coursedata.instructor : ""}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Language</b> <a className="pull-right">{(information.course.coursedata !== "") ? information.course.coursedata.language : ""}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Contain</b> <a className="pull-right">{(information.course.coursedata !== "") ? information.course.coursedata.contain : ""}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Type</b> <a className="pull-right">{(information.course.coursetype !== "") ? information.course.coursetype : ""}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Books Available?</b> <a className="pull-right">{(information.course.books === 1) ? "Yes" : "No"}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Order ID</b> <a className="pull-right">{order.prefixid}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Payment ID</b> <a className="pull-right">{order.payment_id}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Cart ID</b> <a className="pull-right">{order.cartid}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Consignment Status</b> <a className="pull-right" >{order.process.toUpperCase()}</a>
                                </li>
                                {
                                    // information.price.final > 0 &&
                                    // <li className="list-group-item">
                                    //     <b>Print Invoice</b> 
                                    //     <a className="pull-right" onClick={() => window.$("#rename").fadeIn()} >
                                    //         <span className='badge bg-blue'>
                                    //             <i className='fa fa-download'></i>
                                    //         </span>
                                    //     </a>
                                    // </li>
                                }
                            </ul>
                        </div>
                    }
                </div>
                {
                  order !== null && order.packagecontent !== null && order.packagecontent.length > 0 &&
                  
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Package Content
                            </h3>
                        </div>
                        <div className='box-body'>
                            {
                                order.packagecontent.map((item, index) => {
                                    return(
                                    <div className="attachment-block clearfix" key={"also"+index} >
                                        <img className="attachment-img" src={assets_url + item.thumbnail} alt="Attachment Image" />

                                        <div className="attachment-pushed">
                                        <h4 className="attachment-heading">
                                            <Link to={"/admin/course/"+item.courseid} >
                                                {item.name}
                                            </Link>
                                        </h4>
                                        <div className="attachment-text">
                                            <strong style={{fontSize:"12px"}}>
                                                ID:- {item.courseid}
                                            </strong>
                                        </div>
                                        
                                        </div>
                                    </div>
                                        
                                        )
                                    })
                                }
                        </div>
                    </div>
                }
                {
                  order !== null &&  order.together.length > 1 &&
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Total Cart Summary
                            </h3>
                        </div>
                        <div className='box-body'>
                            {
                                order.together.map((item, index) => {
                                    return(
                                    <div className="attachment-block clearfix" key={"also"+index} >
                                        <img className="attachment-img" src={assets_url + item.info.course.thumbnail} alt="Attachment Image" />

                                        <div className="attachment-pushed">
                                        <h4 className="attachment-heading">
                                            <Link to={"/admin/order-info/"+item.orderid} >
                                                {item.info.course.name}
                                            </Link>
                                        </h4>
                                        <div className="attachment-text line1">
                                            {item.info.course.coursedata.subheading}<br />
                                        </div>
                                        <div className="attachment-text">
                                            <strong style={{fontSize:"12px"}}>
                                                {item.orderid}
                                            </strong>
                                        </div>
                                        
                                        </div>
                                    </div>
                                        
                                        )
                                    })
                                }
                        </div>
                    </div>
                }

                {
                    information !== null && information.course.books === 1 &&
                        <div className="box no-border">
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Track Order
                                </h3>
                            </div>
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                    {
                                        trackingDetails.length > 0 &&
                                        trackingDetails.map((item, index) => {
                                            return(
                                                <li className="list-group-item" key={"s"+index}>
                                                    <b> {item.strAction} </b>
                                                    
                                                    <a className="pull-right" > 
                                                        {item.strActionDate} {item.strActionTime}
                                                     </a>
                                                    <br />
                                                    {item.strOrigin} <br />
                                                    {item.strDestination} <br />
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>    
                        </div>
                }

            </div>
            <div className="col-md-8 no-padding" style={{display:"flex", alignItems:"flex-start", flexWrap:"wrap"}} >
            <div className='col-md-6'>

                {
                    information !== null &&
                    <div className="box box-primary no-border" >
                        <div className='box-header no-border'>
                            <h3 className='box-title'>
                                Selected Plan Information
                            </h3>
                        </div>
                        <div className='box-body'>
                            <ul className="list-group list-group-unbordered">
                                <li className="list-group-item">
                                    <b>Plan Name</b> <a className="pull-right">{information.plan.plan_name}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Listing Price</b> <a className="pull-right">{information.plan.listing_price}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Selling Price</b> <a className="pull-right">{information.plan.selling_price}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Discount</b> <a className="pull-right">{information.plan.discount} ({information.plan.off}% OFF)</a>
                                </li>
                                <li className="list-group-item">
                                    <b>GST(%)</b> <a className="pull-right">{information.plan.gst}%</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Expired in Days </b> <a className="pull-right">{information.plan.days}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Exact On Exact Date </b> <a className="pull-right">{information.plan.exactdate}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Total Hours </b> <a className="pull-right">{information.plan.hours}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Expiry Info </b> <a className="pull-right">{information.plan.tag}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                
                {
                    address !== null &&
                    <div className="box box-primary no-border" >
                        <div className='box-header no-border'>
                            <h3 className='box-title'>
                                Shipping Address
                            </h3>
                        </div>
                        <div className='box-body'>
                            <ul className="list-group list-group-unbordered">
                                <li className="list-group-item">
                                    <b>Name</b> <a className="pull-right">{address.name}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Email</b> <a className="pull-right" href={'mailto:'+address.email}>{address.email}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Contact</b> <a className="pull-right" href={'tel:'+address.contact}>{address.contact}</a>
                                </li>
                                {
                                    address.alternative_no !== undefined &&
                                    <li className="list-group-item">
                                        <b>Alternative Contact</b> <a className="pull-right" href={'tel:'+address.alternative_no}>{address.alternative_no}</a>
                                    </li>
                                }
                                <li className="list-group-item">
                                    <b>Address</b> <a className="pull-right">{address.address}</a>
                                </li>
                                {
                                    address.address2 !== null && address.address2 !== undefined &&
                                    <li className="list-group-item">
                                        <b>Address Line 2</b> <a className="pull-right">{address.address2}</a>
                                    </li>
                                }
                                <li className="list-group-item">
                                    <b>City</b> <a className="pull-right">{address.city}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>State</b> <a className="pull-right">{address.state}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Pincode</b> <a className="pull-right">{address.pincode}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Landmark</b> <a className="pull-right">{address.landmark}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className='col-md-6'>
                {
                    information !== null &&
                    <>
                        <div className="box box-primary no-border" >
                            <div className='box-header no-border'>
                                <h3 className='box-title'>
                                    Payment Summary
                                </h3>
                            </div>
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Listing Price</b> <a className="pull-right">{information.price.price}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Discount</b> <a className="pull-right">{information.price.discount}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Additional Discount</b> <a className="pull-right">{information.price.additional}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>GST</b> <a className="pull-right">{information.price.gst} ({information.price.gstper}%)</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Paid Amount </b> <a className="pull-right">{information.price.final}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Used Coins </b> <a className="pull-right">{information.price.deduct}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Earned CB Coins </b> <a className="pull-right">{information.price.cb}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        {
                            typeof information.price.promocode !== "undefined" && information.price.promocode !== null && information.price.promocode.status === "OK" &&
                                <div className="box box-primary no-border" >
                                    <div className='box-header no-border'>
                                        <h3 className='box-title'>
                                            Promocode Used
                                        </h3>
                                    </div>
                                    <div className='box-body'>
                                        <ul className="list-group list-group-unbordered">
                                            <li className="list-group-item">
                                                <b>Promocode</b> <a className="pull-right">#{information.price.promocode.code}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Percentage Off</b> <a className="pull-right">{information.price.promocode.off}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Discount Received</b> <a className="pull-right">{information.price.promocode.promodiscount}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                        }
                        {
                            typeof information.price.promolinkarray !== "undefined" && 
                            information.price.promolinkarray !== null &&
                            information.price.promolinkarray !== "" &&
                            typeof information.price.promolinkarray.title !== "undefined" &&
                                <div className="box box-primary no-border" >
                                    <div className='box-header no-border'>
                                        <h3 className='box-title'>
                                            Promocode Link Used
                                        </h3>
                                    </div>
                                    <div className='box-body'>
                                        <ul className="list-group list-group-unbordered">
                                            <li className="list-group-item">
                                                <b>Link Title</b> <a className="pull-right">{information.price.promolinkarray.title}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Discount Received</b> <a className="pull-right">{information.price.promolinkarray.discount}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Link Code</b> <a className="pull-right">{information.price.promolinkarray.codeid}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                        }
                    </>    
                }
                
                {
                    gst !== null &&
                    <div className="box box-primary no-border" >
                        <div className='box-header no-border'>
                            <h3 className='box-title'>
                                Gst Information
                            </h3>
                        </div>
                        <div className='box-body'>
                            <ul className="list-group list-group-unbordered">
                                <li className="list-group-item">
                                    <b>Company Name</b> <a className="pull-right">{gst.company}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>GST No</b> <a className="pull-right" >{gst.gst}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {
                    staff !== null &&
                    <div className="box box-primary no-border" >
                        <div className='box-header no-border'>
                            <h3 className='box-title'>
                                Allotted By
                            </h3>
                        </div>
                        <div className='box-body'>
                            <ul className="list-group list-group-unbordered">
                                <li className="list-group-item">
                                    <b>Name</b> <a className="pull-right" href={'/admin/staff?id='+staff.id} target='_blank' >{staff.name}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Email</b> <a className="pull-right" >{staff.email}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Contact</b> <a className="pull-right" >{staff.contact}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Role</b> <a className="pull-right" >{staff.role}</a>
                                </li>
                                {
                                    information.course.remark !== undefined &&
                                    <li className="list-group-item">
                                        <b>Remark for allotment</b> <a className="pull-right" >{information.course.remark}</a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                }
                
                {
                    reffrenceNo !== null && consignmentInfo !== null &&
                    order.shipversion === null &&
                    <>
                    {
                        // consignmentInfo.type !== undefined &&
                        <>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Consignment Settings
                                </h3>
                            </div>
                            
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                {
                                    consignmentInfo.type === undefined &&
                                    <>
                                        <li className="list-group-item">
                                            <b>Package Length</b> <a className="pull-right">{consignmentInfo.consignments[0]['pieces_detail'][0]['length']} CM</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Package Breadth </b> <a className="pull-right">{consignmentInfo.consignments[0]['pieces_detail'][0]['width']} CM</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Package Height</b> <a className="pull-right">{consignmentInfo.consignments[0]['pieces_detail'][0]['height']} CM</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Package Weight</b> <a className="pull-right">{consignmentInfo.consignments[0]['pieces_detail'][0]['weight']} KG</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Service Type</b> <a className="pull-right">{consignmentInfo.consignments[0]['consignment_type']}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Load Type</b> <a className="pull-right">{consignmentInfo.consignments[0]['load_type']}</a>
                                        </li>
                                    </>
                                }
                                
                                {
                                    consignmentInfo.type !== undefined && consignmentInfo.type === "Other" &&
                                    <>
                                        <li className="list-group-item">
                                            <b>Title</b> <a className="pull-right">{consignmentInfo.consignments[0]['other'].title}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Tracking ID</b> <a className="pull-right">{consignmentInfo.consignments[0]['other'].track_id}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Tracking URL</b> <a className="pull-right">{consignmentInfo.consignments[0]['other'].track_url}</a>
                                        </li>
                                    </>
                                }
                                </ul>
                            </div>
                        </div>
                        </>

                    }
                        <div className="box box-primary no-border" >
                            <div className='box-header no-border'>
                                <h3 className='box-title'>
                                    Consignment Information
                                </h3>
                            </div>
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Reffrence No</b> <a className="pull-right">{reffrenceNo}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Name</b> <a className="pull-right">{consignmentInfo.consignments[0]['destination_details'].name}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Contact</b> <a className="pull-right" href={'tel:'+consignmentInfo.consignments[0]['destination_details'].phone}>{consignmentInfo.consignments[0]['destination_details'].phone}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Address</b> <a className="pull-right">{consignmentInfo.consignments[0]['destination_details'].address_line_1}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>City</b> <a className="pull-right">{consignmentInfo.consignments[0]['destination_details'].city}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>State</b> <a className="pull-right">{consignmentInfo.consignments[0]['destination_details'].state}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Pincode</b> <a className="pull-right">{consignmentInfo.consignments[0]['destination_details'].pincode}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="box box-primary no-border" >
                            <div className='box-header no-border'>
                                <h3 className='box-title'>
                                    Consignment Saved By
                                </h3>
                            </div>
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Name</b> <a className="pull-right">{consignmentInfo.staff.name}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Contact</b> <a className="pull-right" href={'tel:'+consignmentInfo.staff.contact}>{consignmentInfo.staff.contact}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Email</b> <a className="pull-right">{consignmentInfo.staff.email}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Role</b> <a className="pull-right">{consignmentInfo.staff.role}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                }
                {
                    consignmentInfo !== null &&
                    order.shipversion === 2 &&
                    <>
                        {/* <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Consignment Settings
                                </h3>
                            </div>
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                {
                                    <>
                                        <li className="list-group-item">
                                            <b>Package Length</b> <a className="pull-right">{consignmentInfo['dimentions']['l']} CM</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Package Breadth </b> <a className="pull-right">{consignmentInfo['dimentions']['b']} CM</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Package Height</b> <a className="pull-right">{consignmentInfo['dimentions']['h']} CM</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Package Weight</b> <a className="pull-right">{consignmentInfo['dimentions']['w']} KG</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Consignment Title</b> <a className="pull-right">{consignmentInfo['consignment_title']}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Reference No</b> <a className="pull-right">{consignmentInfo['reffrence_no']}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Track ID</b> <a className="pull-right">{consignmentInfo['trackid']}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Track URL</b> <a className="pull-right">{consignmentInfo['trackurl']}</a>
                                        </li>
                                        {
                                            consignmentInfo['shipped_via'] !== undefined &&
                                            <li className="list-group-item">
                                                <b>Shipped Via</b> <a className="pull-right">{consignmentInfo['shipped_via']}</a>
                                            </li>
                                        }
                                    </>
                                }
                                </ul>
                            </div>
                        </div>
                        <div className="box box-primary no-border" >
                            <div className='box-header no-border'>
                                <h3 className='box-title'>
                                    Consignment Information
                                </h3>
                            </div>
                            <div className='box-body'>
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Name</b> <a className="pull-right">{consignmentInfo['shipping_address']['name']}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Contact</b> <a className="pull-right" href={'tel:'+consignmentInfo['shipping_address']['contact']}>{consignmentInfo['shipping_address']['contact']}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Address</b> <a className="pull-right">{consignmentInfo['shipping_address']['address']}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>City</b> <a className="pull-right">{consignmentInfo['shipping_address']['city']}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>State</b> <a className="pull-right">{consignmentInfo['shipping_address']['state']}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Pincode</b> <a className="pull-right">{consignmentInfo['shipping_address']['pincode']}</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}

                    {
                        (order.consignmenttrail !== null && order.consignmenttrail.length > 0) &&
                        order.consignmenttrail.map((item, index) => {
                            
                            return(
                                <div className="box box-default no-border" key={index + "s"} >
                                    <div className="box-header ">
                                        <h3 className="box-title">{item.date}</h3>
                                        <div className="box-tools pull-right">
                                            <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                                            </button>
                                        </div>  
                                    </div>
                                    <div className="box-body mylistgroup" style={{display:"block"}}>
                                        {
                                            <>
                                                <li className="list-group-item">
                                                    <b>Package Length</b> <a className="pull-right">{item['dimentions']['l']} CM</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Package Breadth </b> <a className="pull-right">{item['dimentions']['b']} CM</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Package Height</b> <a className="pull-right">{item['dimentions']['h']} CM</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Package Weight</b> <a className="pull-right">{item['dimentions']['w']} KG</a>
                                                </li>
                                                <li className="list-group-item" >
                                                    <b>Consignment Title</b> <a className="pull-right">{item['consignment_title']}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Reference No</b> <a className="pull-right">{item['reffrence_no']}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Track ID</b> <a className="pull-right">{item['trackid']}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Track URL</b> <a className="pull-right">{item['trackurl']}</a>
                                                </li>
                                                {
                                                    consignmentInfo['shipped_via'] !== undefined &&
                                                    <li className="list-group-item">
                                                        <b>Shipped Via</b> <a className="pull-right">{item['shipped_via']}</a>
                                                    </li>
                                                }

                                                    <li className="list-group-item">
                                                        <b>Name</b> <a className="pull-right">{item['shipping_address']['name']}</a>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Contact</b> <a className="pull-right" href={'tel:'+item['shipping_address']['contact']}>{item['shipping_address']['contact']}</a>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Address</b> <a className="pull-right">{item['shipping_address']['address']}</a>
                                                    </li>
                                                    
                                                    {
                                                        item['shipping_address']['address2'] !== undefined && 
                                                        <>
                                                            <li className="list-group-item">
                                                                <b>Address</b> <a className="pull-right">{item['shipping_address']['address2']}</a>
                                                            </li>
                                                        </>
                                                    }

                                                    <li className="list-group-item">
                                                        <b>City</b> <a className="pull-right">{item['shipping_address']['city']}</a>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>State</b> <a className="pull-right">{item['shipping_address']['state']}</a>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Pincode</b> <a className="pull-right">{item['shipping_address']['pincode']}</a>
                                                    </li>
                                            </>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                        
                    </>
                }
            </div>
            
            
            </div>

        </div>
        
        {/* <div className='renameDialogBG' id='rename' style={{display:"none"}} >
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Print Invoice
                        <span className='pull-right cursor-pointer' title='Close' onClick={(e) => window.$(".renameDialogBG").fadeOut()}>
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <iframe src={ assets_url + 'invoice/' + orderid} title='Invoice' style={{width:"100%", minHeight:"75vh", border:0, objectFit:"contain"}}></iframe>
                </div>
            </div>
        </div> */}
        {
            transferThis &&
            <div className='renameDialogBG' id='transferThis' >
                <div className='renameBody'>
                    <div className='renameHeading'>
                        <p className='text-left'>
                            Transfer Order
                            <span className='pull-right cursor-pointer' title='Close' onClick={(e) => setTransferThis(false)}>
                                <i className='fa fa-times'></i>
                            </span>
                        </p>
                    </div>
                    <div className='renameInputCon'>
                        <div className="input-group">
                        <input type={"search"} className="form-control" placeholder='Search By Name, Email, Student ID' value={transferSearch} onChange={(e) => searchStudent(e.target.value)} />
                            <span className="input-group-addon"><i className="fa fa-search"></i></span>
                        </div>
                    </div>
                    {
                        transferingLoading ?
                        <div className='renameInputCon' style={{padding:"100px 0", textAlign:"center"}} >
                            <Loading />
                            <br />
                            <br />
                            <strong>Transfering Order Please Wait...</strong>
                        </div>    
                        :
                    <div className='renameInputCon' style={{height:"70vh", overflow:"auto"}}>
                        {
                            transferLoading ? 
                            <div style={{padding:"100px 0"}}>
                                <Loading />
                            </div>
                            :
                            <>
                            { userList.length > 0 &&
                                <ul className='studentList'>
                                    {
                                        userList.map((item, index) => {
                                            var letter = Array.from(item.name)[0];
                                            return(
                                                <li key={"user"+index} onClick={(e) => removeDialog(item.userid, e) } style={{position:"relative"}} >
                                                    <div className='studentListMain' >
                                                        <div className='userImage' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                            {letter.toUpperCase()}
                                                        </div>
                                                        <div className='studentsName'>
                                                            <span style={{display:'flex', alignItems:"center", justifyContent:'space-between', width:"100%"}} >
                                                                <strong>{item.name}</strong>
                                                                <strong className='pull-right'>{item.userid}</strong>
                                                            </span>
                                                            <span>
                                                                <i className='fa fa-envelope'></i> {item.email}
                                                            </span>
                                                            <span>
                                                                <i className='fa fa-phone'></i> {item.contact}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                
                                                )
                                            })
                                        }
                                    
                                </ul>
                            }
                            {
                                userList.length === 0 && transferSearch !== "" &&
                                <div style={{padding:"100px 0", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/nousers.jpeg'} style={{width:"250px", height:"auto", objectFit:"contain"}} />
                                    <strong style={{fontSize:"20px"}}>
                                        No Students Found
                                    </strong>
                                </div>
                            }
                            {
                                transferSearch === "" &&
                                <div style={{padding:"100px 0", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/brain.png'} style={{width:"100%", height:"auto", objectFit:"contain"}} />
                                    <strong style={{fontSize:"20px"}}>
                                        Search Students
                                    </strong>
                                    <p  >e.g 1666960944</p>
                                </div>
                            }
                            </>
                        }
                    </div>
                    }
                </div>
            </div>
        }
        </>
  }
  
        {
                pagePermissions(props.pagepermissions, "Orders", "edit_order") &&
                order !== null && order.courses_list !== null && order.courses_list.length > 0 &&
                editTable &&
                <>
                    <div className={'col-md-8'}>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Edit Order&nbsp;
                                    <small style={{color:"red"}} >(*RF = Remove From)</small>
                                </h3>
                                <button className='btn btn-primary btn-flat pull-right'  onClick={() => {
                                        setEditTable(false)
                                        document.body.scrollTop = document.documentElement.scrollTop = 0
                                        }}>
                                    <i className='fa fa-times'></i>
                                </button>
                            </div>
                            <div className='box-body'>
                                <table id="staff_table" className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                Course
                                            </th>
                                            {
                                                pagePermissions(props.pagepermissions, "Orders", "remove_from_student") &&
                                                <th className='text-center' >
                                                    RF Student
                                                </th>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Orders", "remove_from_portal") &&
                                                <th className='text-center' >
                                                    RF Portal
                                                </th>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Orders", "reset_progress") &&
                                                <th className='text-center' >
                                                    Reset Progress
                                                </th>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Orders", "change_validity") &&
                                                <th className='text-center' >
                                                    Validity
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        order.courses_list.map((item, index) => {
                                            return(
                                            <tr className={ (editValidity !== null && editValidity.id === item.id) ? 'bg-success' : ''} >
                                                <td className='cursor-pointer' onClick={() => {
                                                            setEditvalidity(null)
                                                            setEditHistory(item)
                                                        }}  title='Edit history' >
                                                    {item.name} {item.type === "Package" ? '(Package)' : ''}
                                                    {
                                                        item.expired &&
                                                        <>
                                                            <br />
                                                            <small style={{color:"red"}}>Item Expired</small>
                                                        </>
                                                    }
                                                </td>
                                                {
                                                    pagePermissions(props.pagepermissions, "Orders", "remove_from_student") &&
                                                    <td className='text-center' >
                                                        {
                                                            item.hide ?
                                                            <span className='label bg-yellow cursor-pointer' title='Re Enroll to student' onClick={() => removeFromStudent(item, "enroll")} >
                                                                <i className='fa fa-refresh'></i>
                                                            </span>
                                                            :
                                                            <span className='label bg-red cursor-pointer' title='Remove from student' onClick={() => removeFromStudent(item, "remove")} >
                                                                <i className='fa fa-trash'></i>
                                                            </span>
                                                        }
                                                    </td>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Orders", "remove_from_portal") &&
                                                    <td className='text-center' >
                                                        <span className='label bg-red cursor-pointer' title='Remove from portal' onClick={() => removeFromPortal(item)} >
                                                            <i className='fa fa-trash'></i>
                                                        </span>
                                                    </td>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Orders", "reset_progress") &&
                                                    <td className='text-center' >
                                                        {
                                                            item.type === "Course" &&
                                                            <span className='label bg-yellow cursor-pointer' title='Reset Course Progress' onClick={() => resetCourseProgress(item)}>
                                                                <i className='fa fa-refresh'></i>
                                                            </span>
                                                        }
                                                    </td>
                                                }
                                                {
                                                    pagePermissions(props.pagepermissions, "Orders", "change_validity") &&
                                                    <td className='text-center' >
                                                        <span className='label bg-green cursor-pointer' title='Change Validity' onClick={() => {
                                                            setEditvalidity(item)
                                                            setEditHistory(null)
                                                        }} >
                                                            <i className='fa fa-edit'></i>
                                                        </span>
                                                    </td>
                                                }
                                            </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>

                    {
                        pagePermissions(props.pagepermissions, "Orders", "edit_history") && editHistory !== null &&
                        <div className='col-md-4'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Edit History
                                    </h3>
                                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setEditHistory(null)} >
                                        <i className='fa fa-times'></i>
                                    </button>
                                </div>
                                {
                                    editHistory.mod.length > 0 &&
                                    editHistory.mod.map((item, index) => {
                                        return(
                                            <div className='box-body' key={index+"key"} >
                                                <label>{item.name} ({item.role})</label><br />
                                                <small>{item.email}</small><br />
                                                <small>{item.contact}</small><br />
                                                <small>{item.date}</small><br />
                                                <small><strong>Action:</strong>{item.message}</small>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Orders", "change_validity") && editValidity !== null &&
                        <div className='col-md-4'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Change Validity
                                    </h3>
                                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setEditvalidity(null)} >
                                        <i className='fa fa-times'></i>
                                    </button>
                                </div>
                                <form action='' method='POST' onSubmit={(e) => changeItemValidity(e)} >
                                    <div className='box-body'>
                                        <label>Expiry Date</label>
                                        <input type='date' className='form-control' value={editValidity.expiredate} onChange={(e) => changeValidityData("expiredate", e.target.value)} />
                                    </div>
                                    {
                                        editValidity.type === "Course" &&
                                        <div className='box-body'>
                                            <label>
                                                Remaining Watch Hours
                                                <br />
                                                <small style={{color:"red"}} >(Leave Blank/Empty For Lifetime)</small>
                                            </label>
                                            <input type='number' step={0.01} className='form-control' value={editValidity.remaining_hour} onChange={(e) => changeValidityData("remaining_hour", e.target.value)} placeholder='e.g 100' />
                                        </div>
                                    }
                                    <div className='box-footer'>
                                        <button className='btn btn-primary btn-flat btn-block'><i className='fa fa-save'></i> Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }

                </>
            }

        </>
    }
    
        </>
    )
  }
  return (
    <>
      <Sidebar page={OrdersPages} permission="Global"/>
    </>
  )
}
