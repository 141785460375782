import React, { useState, useEffect, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Adminprops from '../../VelidateWebsite/Adminprops'
import Cookies from 'universal-cookie'
import CommonFunc from '../includes/Common'
import Notfound from '../includes/Notfound'
import FeatherIcon from 'feather-icons-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading'
import Dropzone from 'react-dropzone'
import axios, {CancelToken, isCancel} from 'axios';


const cookies = new Cookies();

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
export default function Editquestion() {

  const Question = (props) => {



    var rules = {}
    rules["Objective"] = {
          "question": true,
          "option1": true,
          "option2": true,
          "option3": true,
          "option4": true,
          "option5": true,
          "option6": true,
          "explanation": true,
          "answertype": true,
          "answer": true,
          "answerplaceholder": "e.g 1",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Subjective"] = {
          "question": true,
          "option1": false,
          "option2": false,
          "option3": false,
          "option4": false,
          "option5": false,
          "option6": false,
          "explanation": true,
          "answertype": false,
          "answer": false,
          "answerplaceholder": "e.g Agra",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Fill_In_The_Blanks"] = {
          "question": true,
          "option1": false,
          "option2": false,
          "option3": false,
          "option4": false,
          "option5": false,
          "option6": false,
          "explanation": true,
          "answertype": false,
          "answer": true,
          "answerplaceholder": "e.g Agra",
          "answermultiplaceholder": "e.g 2,3",
        }
    rules["Match_The_Following"] = {
          "question": true,
          "option1": true,
          "option2": true,
          "option3": true,
          "option4": true,
          "option5": true,
          "option6": true,
          "explanation": true,
          "answertype": true,
          "answer": true,
          "answerplaceholder": "e.g 1",
          "answermultiplaceholder": "e.g 2,3",
        }
      rules["True_And_False"] = {
            "question": true,
            "option1": false,
            "option2": false,
            "option3": false,
            "option4": false,
            "option5": false,
            "option6": false,
            "explanation": true,
            "answertype": false,
            "answer": true,
            "answerplaceholder": "e.g 1",
            "answermultiplaceholder": "e.g 2,3",
          }

    const navigate = useNavigate()
    const params = useParams()
    const cancelFileUpload = useRef(null)
    const query = useQuery();
    const qid = params.id;
    // const qid = query.get("qid");
    

    

    const adminProps = Adminprops()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail} = CommonFunc()
    const[refresh, setRefresh] = useState("No") 
    const[tab, setTab] = useState("Objective")
    const[p_id, setPid] = useState((typeof params.p_id) !== "undefined" ? params.p_id : 0);
    const[main_id, setMainId] = useState((typeof params.id) !== "undefined" ? params.id : 0);
    const[pathList, setPathList] = useState([])
    const[filesList, setFilesList] = useState([])
    const[loading, setLoading] = useState(true)
    const[comprehensive, setComprehensive] = useState(false)
    
    const[essay, setEssay] = useState("")
    const[editID, setEditID] = useState("")
    const[question, setQuestion] = useState("")
    const[option1, setOption1] = useState("")
    const[option2, setOption2] = useState("")
    const[option3, setOption3] = useState("")
    const[option4, setOption4] = useState("")
    const[option5, setOption5] = useState("")
    const[option6, setOption6] = useState("")
    const[explanation, setExplanation] = useState("")
    const[posMarks, setPosMarks] = useState("")
    const[negMarks, setNegMarks] = useState("")
    const[answerType, setAnswerType] = useState("")
    const[correct, setCorrectAnswer] = useState("")
    const[plainName, setPlainName] = useState("")
    const[subject, setSubject] = useState("")
    const[topic, setTopic] = useState("")
    const[tags, setTags] = useState("")
    const[position, setposition] = useState(0)
    const[viewQues, setViewQuestion] = useState(false)

    const changeFolder = (mainid, pid) => {
      setPid(pid)
      setMainId(mainid)
      navigate("/admin/questions/"+mainid+"/"+pid)
  }

  const cleanOptions = () => {
    setEssay("")
    setQuestion("")
    setOption1("")
    setOption2("")
    setOption3("")
    setOption4("")
    setOption5("")
    setOption6("")
    setExplanation("")
    setPosMarks("")
    setNegMarks("")
    setAnswerType("")
    setCorrectAnswer("")
    setSubject("")
    setTopic("")
    setTags("")
    setEditID("")
  }

  const changeTab = (tab) => {
    setViewQuestion(false)
    setLoading(true)
    
    setTab(tab)
    cleanOptions()
  }

  const addQuestion = () => {

    const formData = new FormData();

    if(question === ""){
      errorModal("Oops!", "Question Cannot Be Null")
    }else{
      setLoading(true)
      formData.append('id', editID);
      formData.append('type', "Question");
      formData.append('question_type', tab);
      
      formData.append('essay', (comprehensive) ? essay : "");

      formData.append('question', question);
      formData.append('option1', option1);
      formData.append('option2', option2);
      if(tab === "True_And_False"){
        formData.append('option1', "True");
        formData.append('option2', "False");
      }
      formData.append('option3', option3);
      formData.append('option4', option4);
      formData.append('option5', option5);
      formData.append('option6', option6);
      formData.append('explanation', explanation);
      formData.append('posMarks', posMarks);
      formData.append('negMarks', negMarks);
      formData.append('answerType', answerType);
      formData.append('correct', correct);
      formData.append('plainName', plainName);
      formData.append('subject', subject);
      formData.append('topic', topic);
      formData.append('tags', tags);
      
      formData.append('staff', JSON.stringify(AdminBasics()));
      formData.append('accecible', 1);
      formData.append('page_url', window.location.href);
      formData.append('position', position);
      formData.append('importing', 1);


    //   (editID === "") ? apiurl+"api/add_question" : apiurl+"api/edit_question"
      fetch( apiurl+"api/updateCourseQuestion" , {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
        })
        .then(response => response.json())
        .then(response => {
            console.log(response)
            if(typeof response.status !== "undefined" && response.status == "OK"){
                successModal("Success", "Question Updated Successfully")
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        });
      }
  }
  const editQuestion = (question) => {
    
    changeTab(question.question_type)
    
    if(question.essay !== null){
      setComprehensive(true)
    }
    
    console.log(question.correct)
    var ansType = (question.correct !== null) ? question.correct.join(",") : "";
    setEditID(qid)
    
    setEssay(question.essay !== null ? question.essay : "")
    setQuestion(question.question !== null ? question.question : "")
    setOption1(question.option1 !== null ? question.option1 : "")
    setOption2(question.option2 !== null ? question.option2 : "")
    setOption3(question.option3 !== null ? question.option3 : "")
    setOption4(question.option4 !== null ? question.option4 : "")
    setOption5(question.option5 !== null ? question.option5 : "")
    setOption6(question.option6 !== null ? question.option6 : "")
    setExplanation(question.explanation !== null ? question.explanation : "")
    setPosMarks(question.posMarks !== null ? question.posMarks : "")
    setNegMarks(question.negMarks !== null ? question.negMarks : "")
    setAnswerType(question.answerType !== null ? question.answerType : "")
    setCorrectAnswer(ansType)
    setSubject(question.subject !== null ? question.subject : "")
    setTopic(question.topic !== null ? question.topic : "")
    setTags(question.tags !== null ? question.tags : "")
    window.$('html,body').scrollTop(0);
    setLoading(false)

}
  const getQuestion = () => {
    
    setLoading(true)
    fetch(apiurl+"api/getCourseQuestion", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                  id: params.id,
                  staff: AdminBasics(),
                  accecible: 1,
                  page_url: window.location.href,
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status == "OK"){
                // successModal("Success", response.message)
                editQuestion(response.question.quizdata)
                console.log(response.question.quizdata)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
          window.$('#addNewCourse').fadeOut()
            // console.log(err);
        });
  }
  

    var b = 0
    useEffect(() => {
      if(b === 0){
        window.$("head").append("<style> html{ background:#f1f1f1; } </style>")
        getQuestion()
        b++
      }
    }, [])

    return(
      
      <>
      
        
      <ul className='error_success' id='error_success'>
      
      </ul>
      <div className='importBody'>
        
    
          <div className='col-md-9'>

          {
            <>
                {
                  loading &&
                  <>
                    <div style={{position:"absolute", top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.5)", zIndex:9}}>
                      <Loading />
                    </div>
                  </>
                }

              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                      Comprehensive Question?
                  </h3>
                  
                  <div className='pull-right breadCrums'>
                    <label className="switch">
                        <input type="checkbox" value={(comprehensive) ? 1 : 0} defaultChecked={false} onChange={e => setComprehensive((comprehensive) ? false : true)}/>
                        <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {
                  <div className='box-body' style={{display: (!comprehensive && "none")}}>
                        <CKEditor
                        
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                            editor={ ClassicEditor }
                            data={essay}   
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setEssay(data)
                                // console.log( { event, editor, data } );
                            } }
                        />
                    </div>
                }
              </div>

            {
              <div className='box no-border'>
                {
                  
                  <div className='box-body'>
                    <label>Question</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={question}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              console.log(data)
                              setQuestion(data)
                              setPlainName(data.replace(/<[^>]*>?/gm, ''))
                          } }
                      />
                  </div>
                }
                  {
                    
                    <div className='box-body' style={{display: (!rules[tab]['option1'] && "none")}}>
                      <label>Option 1</label>
                        <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                            editor={ ClassicEditor }
                            data={option1}   
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setOption1(data)
                                // console.log( { event, editor, data } );
                            } }
                        />
                    </div>
                  }
                  {
                    // rules[tab]['option2'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option2'] && "none")}}>
                    <label>Option 2</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={option2}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setOption2(data)
                              // console.log( { event, editor, data } );
                          } }
                      />
                  </div>
                }
                {
                    // rules[tab]['option3'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option3'] && "none")}}>
                    <label>Option 3</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={option3}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setOption3(data)
                              // console.log( { event, editor, data } );
                          } }
                      />
                  </div>
                }
                {
                    // rules[tab]['option4'] &&
                  <div className='box-body' style={{display: (!rules[tab]['option4'] && "none")}}>
                    <label>Option 4</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={option4}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setOption4(data)
                              // console.log( { event, editor, data } );
                          } }
                      />
                  </div>
                }
                {
                    // rules[tab]['option5'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['option5'] && "none")}}>
                    <label>Option 5</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={option5}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setOption5(data)
                              // console.log( { event, editor, data } );
                          } }
                      />
                  </div>
                }
                {
                    // rules[tab]['option6'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['option5'] && "none")}}>
                    <label>Option 6</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={option6}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setOption6(data)
                              // console.log( { event, editor, data } );
                          } }
                      />
                  </div>
                }
                {
                    // rules[tab]['explanation'] &&
                  <div className='box-body'  style={{display: (!rules[tab]['explanation'] && "none")}}>
                    <label>Explanation</label>
                      <CKEditor
                            config={{
                                simpleUpload: {
                                    uploadUrl: apiurl+'api/ckeditor/image_upload',
                                },
                            }}
                          editor={ ClassicEditor }
                          data={explanation}   
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setExplanation(data)
                              // console.log( { event, editor, data } );
                          } }
                      />
                  </div>
                }

                  <div className='box-body'>
                    <div className='col-md-4' style={{paddingLeft:0, display:"none"}}>
                      <label>Position</label>
                      <input type={"number"} name={"position"} className="form-control" placeholder='e.g 2' value={position} onChange={(e) => setposition(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{paddingLeft:0}}>
                      <label>Subject</label>
                      <input type={"text"} name={"subject"} className="form-control" placeholder='e.g Business Environment' value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div className='col-md-6' style={{paddingRight:0}}>
                        <label>Topic</label>
                        <input type={"text"} name={"subject"} className="form-control" placeholder='e.g Business Environment Test' value={topic} onChange={(e) => setTopic(e.target.value)} />
                    </div>
                  </div>
                  <div className='box-body'>
                    <label>Tag(s)</label>
                    <input type={"text"} name={"tags"} className="form-control" placeholder='e.g Fill in blanks Test' value={tags} onChange={(e) => setTags(e.target.value)} />
                  </div>

                  <div className='box-body'>
                    <div className='col-md-3' style={{paddingLeft:0}}>
                      <label>(+ve Marks)</label>
                      <input type={"number"} step={0.01} name={"answer"} className="form-control" placeholder='e.g 1' value={posMarks} onChange={(e) => setPosMarks(e.target.value)} />
                    </div>
                    <div className='col-md-3'>
                      <label>(-ve Marks)</label>
                      <input type={"number"} step={0.01} name={"answer"} className="form-control" placeholder='e.g 0.25' value={negMarks}  onChange={(e) => setNegMarks(e.target.value)} />
                    </div>
                  {
                    rules[tab]['answertype'] &&
                    <div className='col-md-3'>
                      <label>Answer Type</label>
                      <select name='' className='form-control' defaultValue={ (answerType === null) ? "Single" : answerType} onChange={(e) => setAnswerType(e.target.value)}>
                        <option value={"Single"}>Single Correct</option>
                        <option value={"Multi"} >Multi Correct</option>
                      </select>
                    </div>
                  }
                  {
                    rules[tab]['answer'] &&
                    <div className='col-md-3' style={{paddingRight:0}}>
                      <label>Correct Answer</label>
                      <input type={"text"} name={"answer"} className="form-control" value={correct} placeholder={rules[tab]['answerplaceholder']} onChange={(e) => setCorrectAnswer(e.target.value)} />
                    </div>
                  }
                  </div>
                  <div className='box-footer'>
                    <button className='btn btn-primary btn-flat pull-right btn-block' onClick={() => addQuestion()}>
                      {
                        editID === "" ? "Add Question" : "Update Question"
                      }
                    </button>
                  </div>
              </div>
            }
          </>
        }

        {
          viewQues &&
          <>
            {
              comprehensive &&
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Comprehensive
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : essay}}></div>
              </div>
            }
            {
              rules[tab]['question'] &&
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Question
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : question}}></div>
              </div>
            }
            
            {
              rules[tab]['option1'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 1
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option1}}></div>
                </div>
            }
            {
              rules[tab]['option2'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 2
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option2}}></div>
                </div>
            }
            
            {
              rules[tab]['option3'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 3
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option3}}></div>
                </div>
            }
            {
              rules[tab]['option4'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 4
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option4}}></div>
                </div>
            }
            
            {
              rules[tab]['option5'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 5
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option5}}></div>
                </div>
            }
            {
              rules[tab]['option6'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Option 6
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : option6}}></div>
                </div>
            }
            {
              rules[tab]['explanation'] &&
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Explanation
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : explanation}}></div>
                </div>
            }
            <div className='col-md-4' style={{paddingLeft:0}}>
              <div className='box no-border'>
                <div className='box-header'>
                  <h3 className='box-title'>
                    Subject
                  </h3>
                </div>
                <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : subject}}></div>
              </div>
            </div>
              <div className='col-md-4'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Topic
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : topic}}></div>
                </div>
              </div>
              <div className='col-md-4'  style={{paddingRight:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Tags
                    </h3>
                  </div>
                  <div className='box-body ck-content' dangerouslySetInnerHTML={{__html : tags}}></div>
                </div>
              </div>

              <div className='col-md-3' style={{paddingLeft:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      (+ve Marks)
                    </h3>
                    <span className='pull-right'>
                      {posMarks}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      (-ve Marks)
                    </h3>
                    
                    <span className='pull-right'>
                      {negMarks}
                    </span>
                  </div>
                </div>
              </div>
              {
              rules[tab]['answertype'] &&
              <div className='col-md-3'>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Answer Type
                    </h3>
                    <span className='pull-right'>
                      {answerType}
                    </span>
                  </div>
                </div>
              </div>
            } 
            {
              rules[tab]['answer'] &&
              <div className='col-md-3' style={{paddingRight:0}}>
                <div className='box no-border'>
                  <div className='box-header'>
                    <h3 className='box-title'>
                      Correct Answer
                    </h3>
                    <span className='pull-right'>
                      {correct}
                    </span>
                  </div>
                </div>
              </div>
            }
          </>
        }
        </div>
          </div>


      </>
      
    )
  }
  return (
    <>
      <Question />
    </>
  )
}
