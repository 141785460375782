import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
import Answersheet from './Answersheet';
const cookies = new Cookies();
export default function Attempt(props) {
    
    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [courses, setCourses] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const courseid = typeof params.id !== "undefined" ? [params.id] : []
    const [segment, setSegment] = useState(0)
    
    const [showgraph, setShowgraph] = useState(false)
    const [graphData, setGraphData] = useState([])
    const [total, setTotal] = useState(0)
    
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        resstart: "",
        resend: "",
        userid: "",
        purchase_id: "",
        quizid: "",
        quizname: "",
        attemptid: "",
        courseid: courseid,
        orderby: "sort_test_start",
        order: "DESC",
        result: "",
        marksmin: "",
        marksmax: "",
        userdata: "",
    })
    
    
    // const [attemptid, setAttemptid] = useState(null)
    const [attemptid, setAttemptid] = useState(null)
    const [select2Loading, setSelect2Loading] = useState(false)

    const fetchList = () => {
        setLoading(true)
        
        if(window.$(".select2-selection").length > 0){
            window.$("select.select2-hidden-accessible").select2('destroy');
        }
        var temp = filter;
        if(courseid.length === 0){
            temp['courseid'] = window.$("#select2").val()
        }
        
        setSelect2Loading(true)
        fetch(apiurl+"api/fetchattempts", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                    //   courseid: params.id,
                      filter: temp,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.list)
                    setGraphData(response.graph)
                    
                    // if(courses.length < 1){
                        setCourses(response.courses)
                    // }
                    setTotal(response.total)
                    setSelect2Loading(false)
                    setTimeout(function(){
                        window.$("#select2").select2()
                    }, 500) 
                    // document.body.scrollTop = document.documentElement.scrollTop = 0;
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
      }
      
    
    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // fetchList()
    }
    
    const filterAttempts = (e) => {
        e.preventDefault();
        setShowgraph(false)
        fetchList()
    }

    
    
    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["S.No", "Attempt Date", "Attempt ID", "Student ID", "Student Name", "Student Email", "Student Contact", "Quiz Name", "Course Name", "Purchase ID", "Quiz ID", "Course ID", 
        "Result Status", "Marks Obtained", "Total Marks", "Percentage", "Correct", "Incorrect", "Skipped", "Time Taken"]
        var segmentTemp = list
        var td = [];
        var count = 1
        for(var j = 0; j < segmentTemp.length; j++){
            var temp = list[j]
            for(var i = 0;i<temp.length;i++){

                var userdata = (temp[i]['userdata'] !== null) ? JSON.parse(temp[i]['userdata']) : null
                var quiz_data = JSON.parse(temp[i]['quiz_data'])
                var result = (temp[i]['result'] !== null && temp[i]['result'] !== "") ? JSON.parse(temp[i]['result']) : null
                moment.locale('en');
                var ct = temp[i]['updated_at'];

                td.push(
                    [
                        count, moment(ct).format("D-M-Y hh:mm:ss A"), temp[i]['attemptid'],  temp[i]['userid'], userdata['name'], userdata['email'], userdata['contact'],
                        quiz_data.quiztitle, temp[i]['course_name'], temp[i]['purchase_id'], temp[i]['quizid'], temp[i]['course_id'], (temp[i]['result'] === null) ? 'Not Generated' : "Generated",
                        (temp[i]['result'] === null) ? '-' : result.result.earned,
                        (temp[i]['result'] === null) ? '-' : result.result.outof,
                        (temp[i]['result'] === null) ? '-' : temp[i]['percentage'],
                        (temp[i]['result'] === null) ? '-' : result.correct,
                        (temp[i]['result'] === null) ? '-' : result.incorrect,
                        (temp[i]['result'] === null) ? '-' : result.unattempted,
                        (temp[i]['result'] === null) ? '-' : (Math.round((result.timetaken/60) * 100) / 100) + "Minutes",
                    ]
                )
                count++;
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Test Attempt List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            resstart: "",
            resend: "",
            userid: "",
            purchase_id: "",
            quizid: "",
            quizname: "",
            attemptid: "",
            courseid: courseid,
            orderby: "sort_test_start",
            order: "DESC",
            result: "",
            marksmin: "",
            marksmax: "",
            userdata: "",
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }

    const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
    }

    const [generatingResult, setGeneratingResult] = useState(false);
    const [allowingReatt, setAllowingReatt] = useState(false);
    const [generatingResultIndex, setGeneratingResultIndex] = useState("0/0 generating Result");
    const generateResult = () => {
        if(!generatingResult){
            
            var check = []
            window.$(".mark_checkbox:checkbox:checked").each(function () {
                check.push($(this).val());
            });
            if(check.length < 1){
                warningModal("Oops!!!", "Please select atlease 1 attempt");
            }else{
                setGeneratingResult(true)
                startGenerating(check, 0)
            }
        }
    }
    const [errString, setErrString] = useState("");
    const startGenerating = (check, index) => {
        
        if(check[index] !== undefined){
            setGeneratingResultIndex(index + "/" + check.length+" generating Result")
            fetch(apiurl+"api/generateBulkResult", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        staff: AdminBasics(),
                        accecible: 1,
                        page_url: window.location.href,
                        attemptid: check[index],
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        setTimeout(() => {
                            startGenerating(check, index + 1)    
                        }, 1000);
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                        setErrString(errString + check[index] + ", ")
                        setTimeout(() => {
                            startGenerating(check, index + 1)    
                        }, 1000);
                    }
                    setLoading(false)
                })
            }else{
                setGeneratingResultIndex("Done")
                successModal("Success", check.length + " Results Generated")
            }
    }

    const allowReattempt = () => {
        if(!allowingReatt){
            var check = []
            window.$(".mark_checkbox:checkbox:checked").each(function () {
                check.push($(this).val());
            });
            if(check.length < 1){
                warningModal("Oops!!!", "Please select atlease 1 attempt");
            }else{
                setAllowingReatt(true)
                fetch(apiurl+"api/allowReattempt", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                            staff: AdminBasics(),
                            accecible: 1,
                            page_url: window.location.href,
                            attemptids: JSON.stringify(check),
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            successModal("Success", "Re-Attempt Allowed to " + check.length + " Attempts.<br />Please refresh page.")
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                    console.log(i)
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        setAllowingReatt(false)
                    })
            }
        }
    }

    var a = 0
    useEffect(() => {
        if(a === 0){
            setTimeout(function(){
                fetchList()
            }, 1000)
            a++
        }
    }, [])
  return (
    <>
        <div className='col-md-12 no-left-padding no-right-padding'>
            
            <>{
                attemptid === null &&
                <div className='col-md-12'>
                    <br />
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Filters
                            </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                            {
                                filter.start === "" && filter.end === "" && 
                                <>
                                    <br />
                                    <small style={{color:"red"}}>
                                        You are currently viewing data of last 7 days.
                                    </small>
                                </>
                            }
                        </div>
                        
                    <form action='' method='POST' onSubmit={(e) => filterAttempts(e)} >
                        <div style={{overflow:"hidden"}}>
                            <div className='box-body col-md-3'>
                                <label>Date of Attempt (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Date of Attempt (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Date of Result (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.resstart} onChange={(e) => changeValue(e.target.value, "resstart")} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Date of Result (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.resend} onChange={(e) => changeValue(e.target.value, "resend")} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Userid</label>
                                <input type={"text"} className={"form-control"} value={filter.userid} onChange={(e) => changeValue(e.target.value, "userid")} placeholder={"e.g 1667198582"} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Purchase ID</label>
                                <input type={"text"} className={"form-control"} value={filter.purchase_id} onChange={(e) => changeValue(e.target.value, "purchase_id")} placeholder={"e.g 1667198582"} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Quiz Name</label>
                                <input type={"text"} className={"form-control"} value={filter.quizname} onChange={(e) => changeValue(e.target.value, "quizname")} placeholder={"e.g CA Foundation test"} />
                            </div>   
                            <div className='box-body col-md-3'>
                                <label>Quiz ID</label>
                                <input type={"text"} className={"form-control"} value={filter.quizid} onChange={(e) => changeValue(e.target.value, "quizid")} placeholder={"e.g 1667198582"} />
                            </div>     

                            <div className='box-body col-md-12' style={{display: (typeof props.allcourse !== "undefined") ? "block" : "none"}} > 
                                <label>Select Course</label>
                                {
                                    !select2Loading &&
                                    <select className='form-control' value={filter.courseid} multiple id='select2' onChange={(e) => changeValue(window.$(e.target).val(), "courseid")} data-placeholder='Select Course(s)' >
                                        {
                                            courses.length > 0 &&
                                            courses.map((item, index) => {
                                                return(
                                                <option value={item.otherid} key={"key"+index}>{item.name}</option>
                                                )
                                            })
                                        }
                                        
                                    </select>
                                }
                                {/* <input type={"text"} className={"form-control"} value={filter.courseid} onChange={(e) => changeValue(e.target.value, "courseid")} placeholder={"e.g 1667198582"} /> */}
                            </div>  
                            
                            <div className='box-body col-md-3'>
                                <label>Attempt ID</label>
                                <input type={"text"} className={"form-control"} value={filter.attemptid} onChange={(e) => changeValue(e.target.value, "attemptid")} placeholder={"e.g 1667198582"} />
                            </div> 
                            <div className='box-body col-md-3'>
                                <label>Result Generated</label>
                                <select className='form-control' defaultValue={filter.result} onChange={(e) => changeValue(e.target.value, "result")}>
                                    <option value={""}>All</option>
                                    <option value={"Null"}>Not Generated</option>
                                    <option value={"NoNull"}>Generated</option>
                                </select>
                            </div>  
                            <div className='box-body col-md-3'>
                                <label>Percentage (Min)</label>
                                <input type={"text"} className={"form-control"} value={filter.marksmin} onChange={(e) => changeValue(e.target.value, "marksmin")} placeholder={"e.g 10"} />
                            </div>  
                            <div className='box-body col-md-3'>
                                <label>Percentage (Max)</label>
                                <input type={"text"} className={"form-control"} value={filter.marksmax} onChange={(e) => changeValue(e.target.value, "marksmax")} placeholder={"e.g 100"} />
                            </div>  
                            <div className='box-body col-md-3'>
                                <label>Order By</label>
                                <select className='form-control' value={filter.orderby}  onChange={(e) => changeValue(e.target.value, "orderby")} >
                                    <option value={"sort_test_start"} >Test Start</option>
                                    <option value={"sort_quiz_name"} >Quiz Name</option>
                                    <option value={"sort_result_generated"} >Result Generated</option>
                                    <option value={"sort_correct"} >Correct Answers</option>
                                    <option value={"sort_incorrect"} >Incorrect Answers</option>
                                    <option value={"sort_unattempted"} >Un-Attempted/Skipped Answers</option>
                                    <option value={"sort_timetaken"} >Time Taken</option>
                                    <option value={"sort_earned"} >Marks Obtained</option>
                                    <option value={"sort_negetive"} >Negetive Marks</option>
                                    <option value={"sort_percentage"} >Percentage Obtained</option>
                                </select>
                            </div>  
                            <div className='box-body col-md-3'>
                                <label>Order Sequence</label>
                                <select className='form-control' value={filter.order}  onChange={(e) => changeValue(e.target.value, "order")} >
                                    <option value={"ASC"} >Ascending Order</option>
                                    <option value={"DESC"} >Descending Order</option>
                                </select>
                            </div>  
                            <div className='box-body col-md-6'>
                                <label>Student (Name, Email, Contact)</label>
                                <input type={"text"} className={"form-control"} value={filter.userdata} onChange={(e) => changeValue(e.target.value, "userdata")} placeholder={"e.g Escholar..."} />
                            </div>  
                            <div className='box-footer col-md-12'>
                                <input type={"submit"} value="Apply Filter" className='btn btn-primary btn-flat btn-block' />
                            </div>  
                        </div>
                        
                    </form>
                    </div>
                </div>
            }
                <div className={ attemptid === null ? 'col-md-12' : 'col-md-4'}>
                    <br />
                    {
                        showgraph &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Graphical Representation
                                    </h3>
                                    <button className='btn btn-flat btn-primary pull-right' onClick={() => {
                                        setShowgraph(false)
                                    } } title={"Hide Graphical Representation"} >
                                        <i className='fa fa-times' ></i>
                                    </button>
                                </div>
                                <div className='box-body' style={{position:"relative"}} >
                                    <div className='hideChartLogo'></div>
                                    <div id="graph" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                    }
                    {
                        generatingResult &&   
                        <div className='box no-border'>
                            <div className={"alert alert-" + (generatingResultIndex === "Done" ? "success" : "warning") + " alert-dismissible"}>
                                <h4><i className="icon fa fa-warning"></i> Generating Results!</h4>
                                {
                                    (generatingResultIndex === "Done" ? "All Results Generated Successfully Please REFRESH your Page" : generatingResultIndex)
                                }
                                <br />
                                <strong>Error Attempts: </strong> {errString} <br />
                                <strong>
                                    Do not close or refresh the tab while Bulk result is generating.
                                </strong>
                            </div>
                        </div>
                    }
                    <div className='box no-border'>

                        <div className='box-header'>
                            {
                                attemptid !== null &&
                                <>
                                    <button className='btn btn-flat' onClick={() => setAttemptid(null) } title={"Back"} >
                                        <i className='fa fa-arrow-left'></i>
                                    </button> &nbsp;
                                </>
                            }
                            <h3 className='box-title'>
                                Test Attempts ({total})
                            </h3>
                            
                            
                            
                            {
                                <button className={'btn btn-flat btn-' + (!allowingReatt ? "danger" : "warning" ) + ' pull-right'} onClick={() => allowReattempt() } title={"Allow Reattempt"} >
                                    { !allowingReatt ? "Allow Re-Attempt?" : "Allowing Reattempt..." }
                                </button>
                            }
                            
                            {
                                !generatingResult &&
                                <button className='btn btn-flat btn-success pull-right' style={{marginRight:"10px"}} onClick={() => generateResult() } title={"Generate Bulk Result"} >
                                    Generate Result
                                </button>
                            }

                            {
                                !showgraph &&
                                <button className='btn btn-flat btn-primary pull-right mar-r-5' style={{marginRight:"10px"}} onClick={() => {
                                    setShowgraph(true)
                                    setTimeout(function(){
                                        window.ambarchart("graph", graphData, "mylable")
                                    }, 500)

                                } } title={"Show Graphical Representation"} >
                                    Show Graph
                                </button>
                            }
                            {
                                !downloading ?
                                <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest()} >
                                    Download
                                </button>
                                :
                                <button className='btn btn-warning btn-flat pull-right' >
                                    Preparing...
                                </button>
                            }
                        </div>
                        {
                             loading ? 
                             <>
                                 <br /><br /><br />
                                 <Loading />
                                 <br /><br /><br />
                             </>
                             :
                        <div className='box-body' style={{overflow:"auto"}}>
                            <table id="contenttable" className="table file_manager_table">
                                <thead>
                                    <tr>
                                        <th>
                                            <input type={"checkbox"} id={"markAll"} onChange={() => markAll()}/>
                                        </th>
                                        <th>S.No</th>
                                        <th>Attempt Date</th>
                                        <th>Attempt ID</th>
                                        {
                                            attemptid === null &&
                                            <>
                                                <th>Student ID</th>
                                                <th>Student Info</th>
                                                <th>Quiz Name</th>
                                                <th>Purchase ID</th>
                                                <th>Quiz ID</th>
                                                <th>Course ID</th>
                                                <th className='text-center'>Result</th>
                                                <th>Marks</th>
                                                <th>(-ve) Marks</th>
                                                <th>Correct</th>
                                                <th>Incorrect</th>
                                                <th>Skipped</th>
                                                <th>Time Taken (Mins)</th>
                                                <th>Re-Attempt Allowed</th>
                                            </>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        typeof list[segment] !== "undefined" &&
                                        list[segment].length > 0 &&
                                        list[segment].map((item, index) => {
                                            var userdata = (item.userdata !== null) ? JSON.parse(item.userdata) : null
                                            var reatt = (item.discarded !== null) ? JSON.parse(item.discarded) : null
                                            var quiz_data = JSON.parse(item.quiz_data)
                                            var result = (item.result !== null && item.result !== "") ? JSON.parse(item.result) : null
                                            moment.locale('en');
                                            var ct = item.created_at;
                                            return(
                                                    <tr key={"attemp"+index} style={{backgroundColor:attemptid === item.attemptid ? "rgba(0,0,0,0.05)" : "transparent"}} >
                                                        <td>
                                                            {
                                                                // item.result === null &&
                                                                <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.attemptid}/>
                                                            }
                                                        </td>
                                                        <td>
                                                        {(index+1)}
                                                        </td>
                                                        <td>
                                                            {moment(ct).format("D-M-Y hh:mm:ss A")}
                                                        </td>
                                                        <td>
                                                            <strong>
                                                                <span style={{display:"block", color:"#3c8dbc", cursor:"pointer"}}  onClick={() => {
                                                                    setLoading(true)
                                                                    setAttemptid(item.attemptid)
                                                                    setTimeout(function(){
                                                                        setLoading(false)
                                                                    }, 100)
                                                                }} >
                                                                    {item.attemptid}
                                                                </span>
                                                            </strong>
                                                        </td>
                                                        
                                                {
                                                    attemptid === null &&
                                                    <>
                                                        <td>
                                                            {item.userid}
                                                        </td>
                                                        <td>
                                                            {
                                                                userdata !== null &&
                                                                <>
                                                                    <strong>{userdata.name}</strong><br />  
                                                                    <span className='cursor-pointer' style={{whiteSpace:"nowrap"}} title={'Make Mail On '+userdata.email} onClick={() => window.location.href="mailto:"+userdata.email} >
                                                                        <i className='fa fa-envelope'></i> &nbsp;
                                                                        {userdata.email}<br />
                                                                    </span>
                                                                    <span className='cursor-pointer' style={{whiteSpace:"nowrap"}} title={'Make Call On '+userdata.contact} onClick={() => window.location.href="tel:"+userdata.contact} >
                                                                        <i className='fa fa-phone'></i> &nbsp;
                                                                        {userdata.contact}<br />
                                                                    </span>
                                                                </>
                                                            }
                                                        </td>
                                                        <td  >
                                                            <strong style={{whiteSpace:"nowrap"}} >
                                                                {quiz_data.quiztitle}
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {item.purchase_id}
                                                        </td>
                                                        <td>
                                                            {item.quizid}
                                                        </td>
                                                        <td>
                                                            {item.course_id}
                                                        </td>
                                                        <td className='text-center' >
                                                            {
                                                                item.result === null ?
                                                                    <span className='badge bg-red' style={{display:"inline"}} >Not Generated</span>
                                                                :
                                                                    <span className='badge bg-green' style={{display:"inline"}}>Generated</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                result !== null &&
                                                                <>
                                                                    ({result.result.earned}/{result.result.outof})
                                                                    <sup> <strong>{item.percentage}%</strong></sup>
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                result !== null &&
                                                                <>
                                                                    {result.result.negetive}
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                result !== null &&
                                                                <>
                                                                    {result.correct}
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                result !== null &&
                                                                <>
                                                                    {result.incorrect}
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                result !== null &&
                                                                <>
                                                                    {result.unattempted}
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                result !== null &&
                                                                <>
                                                                    {Math.round((result.timetaken/60) * 100) / 100}M
                                                                </>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                reatt !== null &&
                                                                <a href={'/admin/staff?id='+reatt.id} target='_BLANK'>
                                                                    Allowed by <b>{reatt.name}</b> on <b>{reatt.date}</b>
                                                                </a>
                                                            }
                                                        </td>
                                                </>
                                        }
                                                    </tr>
                                                
                                                )
                                            })
                                        }
                                </tbody>
                            </table>
                            
                            <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                {
                                    list.length > 0 && list.length <= 10 &&
                                    list.map((item, index) => {
                                        const active = index === segment ? " btn-primary" : ""
                                        return(
                                            <button key={"paginate" + index} className={'btn btn-flat'+active} style={{marginLeft:"5px"}} onClick={() => 
                                            {
                                                setSegment(index)
                                                // document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }
                                            } >
                                                {index+1}
                                            </button>
                                        )
                                    })
                                }
                                {
                                    list.length > 10 &&
                                    <div style={{width:"200px", float:"right"}}>
                                        <label>Last Page: {list.length}</label>
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                            setSegment(e.target.value - 1)
                                            // document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} />
                                    </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </>
            
            {
                !loading && attemptid !== null &&
                <div className='col-md-8'>
                    { <Answersheet attid={attemptid}  /> }
                </div>
            }
        </div>
    </>
  )
}
